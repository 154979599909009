import React, { useState } from "react";
import NavbarAdminMex from '../components/navbar';
import { Card, Row, Col, Upload, message, Button } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import axios from 'axios';

const ModalTransportadoras = () => {
  const [estados, setEstados] = useState({
    interrapidisimo: 'Inactivo',
    servientrega: 'Inactivo',
    tcc: 'Inactivo',
    //coordinadora: 'Inactivo'
  });

  const [servientregaFile, setServientregaFile] = useState(null);

  const transportadoras = [
    //{ nombre: 'Coordinadora', imagen: '/Images/coordi.png', ruta: '/rastreo_automatico_coordinadora' },
    { nombre: 'Interrapidisimo', imagen: '/Images/inter.png', ruta: '/rastreo_automatico_interrapidisimo' },
    { nombre: 'Servientrega', imagen: '/Images/servi.png', ruta: '/rastreo_automatico_servientrega' },
    { nombre: 'TCC', imagen: '/Images/tcc.svg', ruta: '/rastreo_automatico_tcc' }
  ];

  const handleFileUpload = (info) => {
    if (info.file.status === 'done') {
      setServientregaFile(info.file.originFileObj);
      message.success(`El archivo ${info.file.name} se cargó correctamente`);
    } else if (info.file.status === 'error') {
      message.error(`Error al cargar el archivo ${info.file.name}`);
    }
  };

  const ejecutarValorEstado = async () => {
    console.log("Ejecutando valor de estado");
    const baseUrl = 'https://integracion.99envios.app/api';

    const newEstados = { ...estados };

    for (const transportadora of transportadoras) {
      try {
        let response;
        if (transportadora.nombre === 'Servientrega') {
          if (!servientregaFile) {
            message.error('Por favor, sube un archivo para Servientrega primero');
            continue;
          }
          const formData = new FormData();
          formData.append('file', servientregaFile);
          response = await axios.post(`${baseUrl}${transportadora.ruta}`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          });
        } else {
          response = await axios.post(`${baseUrl}${transportadora.ruta}`, {
          });
        }
        
        if (response.data && response.data.estado) {
          newEstados[transportadora.nombre.toLowerCase()] = response.data.estado;
        }
        
        console.log(`Respuesta para ${transportadora.nombre}:`, response.data);
        message.success(`Llamada a la API exitosa para ${transportadora.nombre}`);
      } catch (error) {
        console.error(`Error al llamar a la API para ${transportadora.nombre}:`, error);
        message.error(`Error en la llamada a la API para ${transportadora.nombre}`);
      }
    }

    setEstados(newEstados);

    message.info("Estados actualizados para todas las transportadoras");
  };

  return (
    <>
      <NavbarAdminMex title="Modal Transportadoras" />
      <Card style={{ marginTop: '20px', marginLeft: '265px', marginRight: '20px', borderRadius: '8px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)' }}>
        <Row gutter={[16, 16]}>
          {transportadoras.map((transportadora) => (
            <Col span={6} key={transportadora.nombre}>
              <Card
                title={transportadora.nombre}
                style={{ textAlign: 'center' }}
                cover={<img alt={transportadora.nombre} src={transportadora.imagen} style={{ width: '150px', height: '150px', margin: '0 auto', objectFit: 'contain' }} />}
              >
                <p>Estado: {estados[transportadora.nombre.toLowerCase()]}</p>
                {transportadora.nombre === 'Servientrega' && (
                  <Upload
                    accept=".xlsx, .xls"
                    onChange={handleFileUpload}
                    beforeUpload={() => false}
                  >
                    <Button icon={<UploadOutlined />} style={{ backgroundColor: '#8c8cff', color: 'white' }}>
                      Cargar Excel
                    </Button>
                  </Upload>
                )}
              </Card>
            </Col>
          ))}
        </Row>
        <Row justify="center" style={{ marginTop: '20px' }}>
          <Button
            type="primary"
            onClick={ejecutarValorEstado}
            style={{ backgroundColor: '#8c8cff', color: 'white', border: 'none', padding: '8px 16px', borderRadius: '4px', cursor: 'pointer', height: '44px' }}
          >
            Ejecutar valor de estado
          </Button>
        </Row>
      </Card>
    </>
  );
};

export default ModalTransportadoras;