import React, { useState, useEffect } from 'react';
import axios from 'axios';
import NavbarMex from '../components/navbarMex';
import './DashboardMex.css';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

const Dashboard = () => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [dashboardData, setDashboardData] = useState(null);
  const [idsucursal, setIdsucursal] = useState('');

  const usuario_id = localStorage.getItem('usuario_id');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSucursalId = async () => {
      try {
        const response = await axios.get(`https://99envios.app/api/online/sucursal-codigo-sucursal/${usuario_id}`);
        setIdsucursal(response.data);
      } catch (error) {
        console.error('Error al obtener el ID de la sucursal', error);
      }
    };

    if (usuario_id) {
      fetchSucursalId();
    }
  }, [usuario_id]);

  useEffect(() => {
    if (idsucursal) {
      fetchData();
    }
  }, [idsucursal, startDate, endDate]);

  const fetchData = async () => {
    const desde = startDate || moment().subtract(1, 'year').format('YYYY-MM-DD');
    const hasta = endDate || moment().format('YYYY-MM-DD');

    try {
      const response = await axios.get(`https://99envios.online/api/home/${idsucursal}`, {
        params: {
          fecha_desde: desde,
          fecha_hasta: hasta,
        },
        headers: {
          'Accept': 'application/json'
        }
      });
      setDashboardData(response.data);
    } catch (error) {
      console.error('Error al obtener los datos de la API', error);
    }
  };

  const totalEnvios = dashboardData?.data.total_envios_365 || 0;
  const totalEntregado = dashboardData?.data.estados_envios_cartera
    .filter((estado) => estado.estado_del_envio.includes('Entrega exitosa') || estado.estado_del_envio.includes('Entregada'))
    .reduce((total, estado) => total + estado.total_envios, 0) || 0;
  const devoluciones = dashboardData?.data.estados_envios_cartera
    .filter((estado) => estado.estado_del_envio.includes('Anulado'))
    .reduce((total, estado) => total + estado.total_envios, 0) || 0;
  
  const estadoEnvios = dashboardData?.data.estados_envios_cartera || [];
  const destinosUsados = dashboardData?.data.destinos_mas_usados || [];
  const devolucionesFrecuentes = dashboardData?.data.resultado_en_movimiento || [];
  const novedadesDiarias = dashboardData?.datalist.novedades_diarias || [];
  const enviosCompleto = dashboardData?.datalist.ordenes_recientes || [];
  const carteraCompleta = dashboardData?.datalist.cartera_recientes || [];
  const consolidadoConsignaciones = dashboardData?.datalist.consolidado_cartera || [];

  return (
    <>
      <NavbarMex title="Dashboard" />
      <div className="dashboard-containerM">
        <div className="date-picker-containerM">
          <div className="date-picker-row">
            <div className="date-picker">
              <label>Fecha inicio:</label>
              <input
                type="date"
                id="startDate"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </div>
            <div className="date-picker">
              <label>Fecha de corte:</label>
              <input
                type="date"
                id="endDate"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </div>
          </div>
          <div className="button-container">
            <button onClick={fetchData} className="search-button">Buscar Resultados</button>
          </div>
        </div>
        {dashboardData ? (
          <div className="cards-container">
            {/* Tarjetas principales */}
            <div className="row">
              <div className="cardDM">
                <div className="icon-circle local-shipping-icon">
                  <span className="material-icons-sharp">local_shipping</span>
                </div>
                <h2>Total Envios (365 días)</h2>
                <p>{totalEnvios}</p>
              </div>
              <div className="cardDM">
                <div className="icon-circle check-circle-icon">
                  <span className="material-icons-sharp">trending_up</span>
                </div>
                <h2>Total Entregado</h2>
                <p>{totalEntregado}</p>
                <div className="rate-container">
                  <span className="material-icons success-icon">check_circle</span>
                  <p className="rate">Tasa de Entrega</p>
                </div>
                <p className="success" style={{ color: "#4caf50" }}>
                  {dashboardData?.data.porcentajeFormateado || '0.00%'}
                </p>
              </div>
              <div className="cardDM">
                <div className="icon-circle report-icon">
                  <span className="material-icons-sharp">repartition</span>
                </div>
                <h2>Devoluciones</h2>
                <p>{devoluciones}</p>
                <div className="rate-container">
                  <span className="material-icons error-icon">error</span>
                  <p className="rate">Tasa de Devolución</p>
                </div>
                <p className="success" style={{ color: "#F44336" }}>
                  {dashboardData?.data.porcentajeFormateadoDevoluciones || '0.00%'}
                </p>
              </div>
            </div>

            {/* Nuevas tarjetas */}
            <div className="row">

              {/* Tarjeta: Estado envíos */}
              <div className="cardDM">
              <div className="icon-circle local-shipping-icon">
                  <span className="material-icons-sharp">stacked_line_chart</span>
                </div>
                <h2>Estado Envios</h2>
                <ul>
                  {estadoEnvios.map((estado, index) => (
                    <li key={index}>
                      {estado.estado_del_envio}: {estado.total_envios}
                    </li>
                  ))}
                </ul>
              </div>

              {/* Tarjeta: Destinos más usados */}
              <div className="cardDM">
                <div className="icon-circle check-circle-icon">
                  <span className="material-icons-sharp">scale</span>
                </div>
                <h2>Destinos Más Usados</h2>
                <ul>
                  {destinosUsados.map((destino, index) => (
                    <li key={index}>
                      {destino.ciudad_destino}: {destino.total_envios}
                    </li>
                  ))}
                </ul>
              </div>

              {/* Tarjeta: Devoluciones frecuentes */}
              <div className="cardDM">
                <div className="icon-circle report-icon">
                  <span className="material-icons-sharp">warning</span>
                </div>
                <h2>Devoluciones Frecuentes</h2>
                <ul>
                  {devolucionesFrecuentes.map((movimiento, index) => (
                    <li key={index}>
                      Tel: {movimiento.telefono_destinatario}, Oc.: {movimiento.num_ocurrencias}
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            {/* Tabla de Novedades Diarias */}
            <div className="dashboardMex-container">
              <h2>Novedades Diarias</h2>
              <table className="dashboardMex-table">
                <thead>
                  <tr>
                    <th>Número de Guía</th>
                    <th>Transportadora</th>
                    <th>Fecha de Actualización</th>
                    <th>Novedad</th>
                    <th>Fecha limite respuesta</th>
                  </tr>
                </thead>
                <tbody>
                  {novedadesDiarias.map((novedad, index) => (
                    <tr key={index}>
                      <td>{novedad.Numero_de_guía}</td>
                      <td>{novedad.Transportadora}</td>
                      <td>{novedad.Fecha_de_actualización}</td>
                      <td>{novedad.Novedad}</td>
                      <td>{novedad.Fecha_limite_de_respuesta}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <button
                className="dashboardMex-button"
                onClick={() => navigate('/novedades')}
              >
                Gestionar Novedades
              </button>
            </div>

            {/* Tabla de Envíos Completo */}
            <div className="dashboardMex-container">
              <h2>Envíos Completos</h2>
              <table className="dashboardMex-table">
                <thead>
                  <tr>
                    <th>Número de Guía</th>
                    <th>Fecha de envio</th>
                    <th>Valor comercial</th>
                    <th>Ciudad</th>
                    <th>Producto</th>
                  </tr>
                </thead>
                <tbody>
                  {enviosCompleto.map((envios, index) => (
                    <tr key={index}>
                      <td>{envios.numero_de_guia}</td>
                      <td>{envios.fecha_envio}</td>
                      <td>{envios.valor_comercial}</td>
                      <td>{envios.ciudad_destino}</td>
                      <td>{envios.Producto}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <button
                className="dashboardMex-button"
                onClick={() => navigate('/envios-completos')}
              >
                Ver más
              </button>
            </div>
            
            {/* Tabla de Cartera Completa */}
            <div className="dashboardMex-container">
              <h2>Cartera Completa</h2>
              <table className="dashboardMex-table">
                <thead>
                  <tr>
                    <th>Número de Guía</th>
                    <th>Valor Comercial</th>
                    <th>Valor Flete</th>
                    <th>Valor Consignado</th>
                    <th>Estado</th>
                  </tr>
                </thead>
                <tbody>
                  {carteraCompleta.map((cartera, index) => (
                    <tr key={index}>
                      <td>{cartera.numero_de_guia}</td>
                      <td>{cartera.valor_comercial}</td>
                      <td>{cartera.valor_flete}</td>
                      <td>{cartera.valor_consignado}</td>
                      <td>{cartera.estado_del_envio}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <button
                className="dashboardMex-button"
                onClick={() => navigate('/cartera-completa')}
              >
                Ver más
              </button>
            </div>

            {/* Tabla de Consolidado Consignaciones */}
            <div className="dashboardMex-container">
              <h2>Consolidado Consignaciones</h2>
              <table className="dashboardMex-table">
                <thead>
                  <tr>
                    <th>Fecha de Consignación</th>
                    <th>Valor Consignado</th>
                  </tr>
                </thead>
                <tbody>
                  {consolidadoConsignaciones.map((consolidadoC, index) => (
                    <tr key={index}>
                      <td>{consolidadoC.fecha_de_consignacion}</td>
                      <td>{consolidadoC.total_por_dia}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

          </div>
        ) : (
          <p>Cargando datos...</p>
        )}
      </div>
    </>
  );
};

export default Dashboard;