import React, { useState } from 'react';
import NavbarMex from '../components/navbarMex';
import './TrackingComponent.css';

const TrackingComponent = () => {
  const [trackingNumber, setTrackingNumber] = useState('');
  const [carrier, setCarrier] = useState('interrapidisimo');
  const [trackingInfo, setTrackingInfo] = useState(null);
  const [error, setError] = useState(null);

  const handleInputChange = (event) => {
    setTrackingNumber(event.target.value);
  };

  const handleCarrierChange = (event) => {
    setCarrier(event.target.value);
  };

  const handleSearch = async () => {
    const requestBody = {
      guia: trackingNumber,
      transportadora: {
        pais: 'colombia',
        nombre: carrier
      },
      origenCreacion: 1,
    };

    try {
      const codigo_sucursal = localStorage.getItem('codigoSucursal');
      console.log("codigo sucursal",codigo_sucursal);
      const response = await fetch(`https://integration.99envios.app/api/sucursal/rastreo/${codigo_sucursal}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestBody)
      });

      if (!response.ok) {
        throw new Error('Error en la búsqueda de la guía');
      }

      const data = await response.json();
      setTrackingInfo(data);
      setError(null);
    } catch (error) {
      setError(error.message);
      setTrackingInfo(null);
    }
  };

  return (
    <>
      <NavbarMex title="Buscar guía" />
      <div className="tracking-container">
        <div className="search-bar">
          <input
            type="text"
            value={trackingNumber}
            onChange={handleInputChange}
            placeholder="Ingrese guía"
            className="search-input"
          />
          <select value={carrier} onChange={handleCarrierChange} className="carrier-select">
            <option value="interrapidisimo">Interrapidisimo</option>
            <option value="servientrega">Servientrega</option>
            <option value="coordinadora">Coordinadora</option>
            <option value="tcc">TCC</option>
          </select>
          <button onClick={handleSearch} className="search-button">
            <span className="material-icons">search</span>
          </button>
        </div>
        <div className="tracking-info">
          <img src="Images/finger.png" alt="Sigue tu envío" className="tracking-image" />
          <h2>Sigue tu envío</h2>
          <p>Coloca tu número de guía en la parte superior para ver el estado del envío</p>
          {error && <p className="error-message">{error}</p>}
          {trackingInfo && (
            <div className="tracking-results">
              <p><strong>Origen:</strong> {trackingInfo.origen}</p>
              <p><strong>Destino:</strong> {trackingInfo.destino}</p>
              <h3>Estados:</h3>
              <ul>
                {Object.keys(trackingInfo.estados).map((key) => (
                  <li key={key}>
                    <p><strong>{trackingInfo.estados[key].nombre}</strong></p>
                    <p>{new Date(trackingInfo.estados[key].fecha).toLocaleString()}</p>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default TrackingComponent;
