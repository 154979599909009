import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { message, Modal, Input, Button } from 'antd';
import axios from 'axios';
import Navbar from '../../../components/navbar';
import Footer from '../../../components/footer';
import Head from '../../../components/headerConductor';

function Pedidos() {
    const { pedidoId } = useParams();
    const id = pedidoId;
    const navigate = useNavigate();
    const [orderInfo, setOrderInfo] = useState(null);
    const [email, setEmail] = useState('');
    const [productDetails, setProductDetails] = useState({
        imageUrl: '',
        size: '',
        color: '',
        name: ''
    });
    const [news, setNews] = useState('');

    useEffect(() => {
        const email = localStorage.getItem('email');
        setEmail(email);
    }, []);

    const fetchOrderInfo = useCallback(async () => {
        try {
            const response = await axios.get(`https://api.99envios.app/api/pedidos/${pedidoId}`);
            console.log('GET Response:', response.data); // Log the response of the GET request
            setOrderInfo(response.data);

            const detallesProducto = response.data.detalles_producto.split('|');
            const imageUrl = detallesProducto[0];
            const size = detallesProducto[2];
            const color = detallesProducto[3];
            const name = detallesProducto[4];

            setProductDetails({
                imageUrl,
                size,
                color,
                name
            });

        } catch (error) {
            console.error('Error fetching order info:', error);
        }
    }, [pedidoId]);

    useEffect(() => {
        if (pedidoId) {
            fetchOrderInfo();
        }
    }, [pedidoId, fetchOrderInfo]);

    const handleCall = (phoneNumber) => {
        window.location.href = `tel:${phoneNumber}`;
    };

    const handleSearchAddress = (address) => {
        if (address) {
            window.open(`https://www.google.com/maps/search/${encodeURIComponent(address)}`, '_blank');
        }
    };

    const unassignOrder = useCallback(async () => {
        try {
            const response = await axios.put(`https://api.99envios.app/api/pedidos/${pedidoId}/actualizar-a-null`);
            console.log('PUT Response:', response); // Log the response of the PUT request
            if (response.status === 200) {
                message.success('Pedido desasignado correctamente');
                navigate('/conductor?refresh=true');
            } else {
                throw new Error('Failed to update the order status');
            }
        } catch (error) {
            console.error('Error marking the order as returned:', error);
            alert('No se pudo marcar el pedido como devuelto');
        }
    }, [pedidoId]);

    const handleChangeStatus = async (id) => {
        Modal.confirm({
            title: '¿Estás seguro de que quieres cambiar el estado del pedido?',
            content: 'Esta acción cambiará el estado del pedido a "En proceso" y no puede deshacerse.',
            onOk: async () => {
                const url = `https://api.99envios.app/api/pedidos/${id}/en-proceso`;
                try {
                    const response = await axios.put(url);
                    if (response.status === 200) {
                        message.success('Pedido actualizado correctamente');
                        navigate('/conductor/ruta-salir-entregar?refresh=true');
                    }
                } catch (error) {
                    console.error('Error al actualizar el estado del pedido:', error);
                    message.error('Error al actualizar el estado del pedido.');
                }
            }
        });
    };

    const handleNewsButtonClick = () => {
        navigate(`/conductor/novedades/${pedidoId}`);
    };

    const handleWhatsApp = (phoneNumber) => {
        window.open(`https://wa.me/${phoneNumber}`, '_blank');
    };

    const handleNewsSubmit = async () => {
        try {
            const response = await axios.post(`https://api.99envios.app/api/pedidos/${pedidoId}/novedades`, { novedad: news });
            if (response.status === 200) {
                message.success('Novedad enviada correctamente');
                setNews('');
            } else {
                throw new Error('Failed to send news');
            }
        } catch (error) {
            console.error('Error al enviar la novedad:', error);
            message.error('Error al enviar la novedad.');
        }
    };

    if (!orderInfo) {
        return <div>Cargando información del pedido...</div>;
    }

    const total = orderInfo && orderInfo.costo_envio != null && orderInfo.valor_producto != null && orderInfo.FEE99 != null 
    ? (parseFloat(orderInfo.costo_envio) + parseFloat(orderInfo.valor_producto) + parseFloat(orderInfo.FEE99)) 
    : null;

    const statesToHideButtons = ['Entregado', 'Devuelto'];

    return (
        <div>
            <Navbar title={"Pedidos"} />
            <div className="order-summary">
                <Head title={`Pedido #${orderInfo?.ID_pedido || 'Cargando...'}`} />
                <div className="order-info">
                    <h2>Información</h2>
                    <p>Fecha: {orderInfo?.fecha_pedido || 'Cargando...'}</p>
                    <p>Fecha entrega: {orderInfo?.fecha_entrega_repartidor || 'No entregado'}</p>
                    <p>Estado: {orderInfo?.estado_pedido || 'Cargando...'}</p>
                    <p>Total: {total !== null ? total.toLocaleString('es-CO', { style: 'currency', currency: 'COP' }) : 'Cargando...'}</p>
                </div>

                <div className="shipping-info">
                    <h2>Dirección de Envío</h2>
                    <p>Dirección de entrega: {orderInfo?.direccion_entrega || 'Cargando...'}</p>
                    <p>Ciudad: {orderInfo?.ciudad || 'Cargando...'}</p>
                    <p>País: {orderInfo?.pais || 'Cargando'}</p>
                    <p>Código de ciudad: {orderInfo?.codigo_ciudad || 'Cargando...'}</p>
                    <p>Teléfono: {orderInfo?.cliente.telefono || 'Cargando...'}</p>
                    <div className="button-group">
                        <button onClick={() => handleSearchAddress(orderInfo?.direccion_entrega)} className="map-button">Mapa</button>
                        <button onClick={() => handleWhatsApp(orderInfo?.cliente.telefono)} className="whatsapp-button">WhatsApp</button>
                    </div>
                </div>

                <div className="pickup-info">
                    <h2>Dirección de Recogida</h2>
                    <p>Id cliente: {orderInfo.id_cliente}</p>
                    <p>Dirección de recogida: {orderInfo.direccion_recogida}</p>
                    <p>Ciudad: {orderInfo.ciudad}</p>
                </div>

                <div className="product-list">
                    <div className="product-details">
                        <h2>Detalles del Producto</h2>
                        <p><strong>Nombre:</strong> {productDetails.name || 'No disponible'}</p>
                        <p><strong>Talla:</strong> {productDetails.size || 'No disponible'}</p>
                        <p><strong>Color:</strong> {productDetails.color || 'No disponible'}</p>
                        <p><strong>Valor:</strong> {orderInfo?.valor_producto ? `${parseFloat(orderInfo.valor_producto).toLocaleString('es-CO', { style: 'currency', currency: 'COP' })}` : '$0'}</p>
                    </div>
                </div>

                <div className="delivery-person">
                    <h2>Repartidor</h2>
                    <p>Correo: {email || 'Cargando...'}</p>
                </div>

                <div className="news-section">
                    <h2>Novedades</h2>
                    <Input.TextArea 
                        value={news}
                        onChange={(e) => setNews(e.target.value)}
                        rows={4}
                        placeholder="Escribe una novedad..."
                    />
                    <Button type="primary" onClick={handleNewsSubmit} style={{ marginTop: '10px' }}>
                        Enviar Novedad
                    </Button>
                </div>
            </div>
            <Footer />
            <style jsx>{`
                .order-summary {
                    max-width: 800px;
                    margin: 0 auto;
                    padding: 20px;
                    background: #f9f9f9;
                    border-radius: 8px;
                    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
                }

                .order-info, .shipping-info, .pickup-info, .product-details, .delivery-person, .news-section {
                    margin-bottom: 20px;
                    padding: 20px;
                    background: white;
                    border-radius: 8px;
                    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
                }

                .order-info h2, .shipping-info h2, .pickup-info h2, .product-details h2, .delivery-person h2, .news-section h2 {
                    margin-bottom: 10px;
                    font-size: 1.2em;
                    color: #333;
                }

                .button-group {
                    display: flex;
                    justify-content: space-between;
                }

                .map-button, .whatsapp-button {
                    flex: 1;
                    padding: 10px 20px;
                    margin: 5px;
                    font-size: 16px;
                    border: none;
                    border-radius: 5px;
                    cursor: pointer;
                    transition: background-color 0.3s ease;
                }

                .map-button {
                    background-color: #007bff;
                    color: white;
                }

                .map-button:hover {
                    background-color: #0056b3;
                }

                .whatsapp-button {
                    background-color: #25D366;
                    color: white;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .whatsapp-button:hover {
                    background-color: #1DA851;
                }

                .whatsapp-button::before {
                    content: '';
                    background-image: url('https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg');
                    background-size: cover;
                    width: 20px;
                    height: 20px;
                    margin-right: 8px;
                }

                .news-section {
                    display: flex;
                    flex-direction: column;
                }
            `}</style>
        </div>
    );
}

export default Pedidos;
