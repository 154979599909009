import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';  // Importa useNavigate para la redirección
import NavbarMex from '../components/navbarMex';
import { utils, writeFile } from 'xlsx';
import './cartera.css';

const TableComponentC = () => {
  const [data, setData] = useState([]);
  const [search, setSearch] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const usuario_id = localStorage.getItem('usuario_id');
  
  const navigate = useNavigate();  // Hook para redirigir

  useEffect(() => {
    axios.get(`https://api.99envios.app/api/online/cartera_completa/${usuario_id}`)
      .then(response => setData(response.data))
      .catch(error => console.error('Error fetching data: ', error));
  }, [usuario_id]);

  const handleSearch = (event) => {
    setSearch(event.target.value);
    setCurrentPage(1); // Reset to first page on new search
  };

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const sortedData = React.useMemo(() => {
    let sortableData = [...data];
    if (sortConfig.key !== '') {
      sortableData.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableData;
  }, [data, sortConfig]);

  const filteredData = sortedData.filter(item =>
    item.nombre_destinatario.toLowerCase().includes(search.toLowerCase()) ||
    item.direccion_destinatario.toLowerCase().includes(search.toLowerCase()) ||
    item.telefono_destinatario.toLowerCase().includes(search.toLowerCase()) ||
    item.ciudad_destino.toLowerCase().includes(search.toLowerCase()) ||
    item.estado_del_envio.toLowerCase().includes(search.toLowerCase()) ||
    item.nombre_sucursal.toLowerCase().includes(search.toLowerCase()) ||
    item.valor_comercial.toString().toLowerCase().includes(search.toLowerCase()) ||
    item.valor_flete.toString().toLowerCase().includes(search.toLowerCase()) ||
    item.valor_consignado.toString().toLowerCase().includes(search.toLowerCase()) ||
    item.fecha_consignacion.toLowerCase().includes(search.toLowerCase())
  );

  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const currentEntries = filteredData.slice(indexOfFirstEntry, indexOfLastEntry);

  const totalPages = Math.ceil(filteredData.length / entriesPerPage);

  const exportToExcel = () => {
    const worksheet = utils.json_to_sheet(data);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, "Data");
    writeFile(workbook, "data.xlsx");
  };

  return (
    <>
      <NavbarMex title="Cartera Completa" />
      <div className="table-containerCA" style={{maxWidth: "85%" , marginTop:'2%' , boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)'}}>
        {/* Botón para redirigir a 'Históricos Cartera' */}
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
          <button onClick={() => navigate('/historico-cartera')} className="historicos-button">
            Históricos Cartera
          </button>
        </div>

        <div className="table-controls">
          <label htmlFor="entries">Mostrar</label>
          <select
            id="entries"
            name="entries"
            value={entriesPerPage}
            onChange={(e) => setEntriesPerPage(parseInt(e.target.value))}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
          <input
            type="text"
            placeholder="Buscar"
            value={search}
            onChange={handleSearch}
            className="search-input"
          />
          <button onClick={exportToExcel} className="export-button">Exportar a Excel</button>
        </div>
        <div className="table-wrapper">
          <table className="custom-table">
            <thead>
              <tr>
                <th onClick={() => handleSort('codigo_sucursal')}>Código Sucursal</th>
                <th onClick={() => handleSort('origen')}>Origen</th>
                <th onClick={() => handleSort('valor_comercial')}>Valor Comercial</th>
                <th onClick={() => handleSort('valor_flete')}>Valor Flete</th>
                <th onClick={() => handleSort('valor_consignado')}>Valor Consignado</th>
                <th onClick={() => handleSort('fecha_consignacion')}>Fecha de Consignación</th>
                <th onClick={() => handleSort('estado_del_envio')}>Estado de Envío</th>
                <th onClick={() => handleSort('nombre_sucursal')}>Sucursal</th>
                <th onClick={() => handleSort('nombre_destinatario')}>Destinatario</th>
                <th onClick={() => handleSort('direccion_destinatario')}>Dirección</th>
                <th onClick={() => handleSort('telefono_destinatario')}>Teléfono</th>
                <th onClick={() => handleSort('ciudad_destino')}>Ciudad</th>
              </tr>
            </thead>
            <tbody>
              {currentEntries.map((item, index) => (
                <tr key={index}>
                  <td>{item.codigo_sucursal}</td>
                  <td>{item.origen}</td>
                  <td>{item.valor_comercial}</td>
                  <td>{item.valor_flete}</td>
                  <td>{item.valor_consignado}</td>
                  <td>{item.fecha_consignacion}</td>
                  <td>{item.estado_del_envio}</td>
                  <td>{item.nombre_sucursal}</td>
                  <td>{item.nombre_destinatario}</td>
                  <td>{item.direccion_destinatario}</td>
                  <td>{item.telefono_destinatario}</td>
                  <td>{item.ciudad_destino}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="pagination">
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index}
              className={`page-button ${currentPage === index + 1 ? 'active' : ''}`}
              onClick={() => setCurrentPage(index + 1)}
            >
              {index + 1}
            </button>
          ))}
        </div>
      </div>
    </>
  );
};

export default TableComponentC;
