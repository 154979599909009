import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Modal, Form, Input, InputNumber, Select, message } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import NavbarAdminMex from '../components/navbar';
import './configNegociacion.css';

const { Option } = Select;
const { confirm } = Modal;

const API_URL = 'https://integration.99envios.app/api/negociaciones';

const ConfigNegociacion = () => {
  const [data, setData] = useState([]);
  const [search, setSearch] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [modalVisible, setModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [editingId, setEditingId] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(API_URL);
      setData(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
      message.error('Error al cargar los datos');
    }
  };

  const handleSearch = (event) => {
    setSearch(event.target.value);
    setCurrentPage(1);
  };

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const sortedData = React.useMemo(() => {
    let sortableData = [...data];
    if (sortConfig.key !== '') {
      sortableData.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableData;
  }, [data, sortConfig]);

  const filteredData = sortedData.filter(item =>
    Object.values(item).some(val => 
      val.toString().toLowerCase().includes(search.toLowerCase())
    )
  );

  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const currentEntries = filteredData.slice(indexOfFirstEntry, indexOfLastEntry);

  const totalPages = Math.ceil(filteredData.length / entriesPerPage);

  const handleEdit = (id) => {
    const itemToEdit = data.find(item => item.id === id);
    form.setFieldsValue(itemToEdit);
    setEditingId(id);
    setModalVisible(true);
  };

  const handleAdd = () => {
    form.resetFields();
    setEditingId(null);
    setModalVisible(true);
  };

  const handleModalOk = async () => {
    try {
      const values = await form.validateFields();
      if (editingId) {
        await handleSave(editingId, values);
      } else {
        await handleAddNew(values);
      }
      setModalVisible(false);
      form.resetFields();
    } catch (error) {
      console.error("Validation failed:", error);
    }
  };

  const handleSave = async (id, values) => {
    try {
      await axios.put(`${API_URL}/${id}`, values);
      fetchData();
      setEditingId(null);
      message.success('Negociación actualizada con éxito');
    } catch (error) {
      console.error('Error updating data:', error);
      message.error('Error al actualizar la negociación');
    }
  };

  const handleAddNew = async (values) => {
    try {
      await axios.post(API_URL, values);
      fetchData();
      message.success('Nueva negociación agregada con éxito');
    } catch (error) {
      console.error('Error adding data:', error);
      message.error('Error al agregar nueva negociación');
    }
  };

  const showDeleteConfirm = (id) => {
    confirm({
      title: '¿Está seguro de eliminar esta negociación?',
      icon: <ExclamationCircleOutlined />,
      content: 'Esta acción no se puede deshacer.',
      okText: 'Eliminar',
      okType: 'danger',
      cancelText: 'Cancelar',
      className: 'configuracion-negociacion-delete-confirm',
      onOk() {
        handleDelete(id);
      },
    });
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${API_URL}/${id}`);
      fetchData();
      message.success('Negociación eliminada con éxito');
    } catch (error) {
      console.error('Error deleting data:', error);
      message.error('Error al eliminar la negociación');
    }
  };

  return (
    <>
      <NavbarAdminMex title="Configuración Negociación"/>
      <div className="configuracion-negociacion-table-container">
        <div className="configuracion-negociacion-table-controls">
          <label htmlFor="entries">Mostrar</label>
          <select
            id="entries"
            name="entries"
            value={entriesPerPage}
            onChange={(e) => setEntriesPerPage(parseInt(e.target.value))}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
          <input
            type="text"
            placeholder="Buscar"
            value={search}
            onChange={handleSearch}
            className="configuracion-negociacion-search-input"
          />
        </div>
        <button onClick={handleAdd} className="configuracion-negociacion-add-button">Agregar</button>
        <div className="configuracion-negociacion-table-wrapper">
          <table className="configuracion-negociacion-custom-table">
            <thead>
              <tr>
                <th onClick={() => handleSort('id_sucursal')}>ID Sucursal</th>
                <th onClick={() => handleSort('id_transportadora')}>ID Transportadora</th>
                <th onClick={() => handleSort('comision')}>Comisión</th>
                <th onClick={() => handleSort('comision_contrapago')}>Comisión Contrapago</th>
                <th onClick={() => handleSort('sobreflete')}>Sobreflete</th>
                <th onClick={() => handleSort('minima_sobreflete')}>Mínima Sobreflete</th>
                <th onClick={() => handleSort('comision_interna')}>Comisión Interna</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {currentEntries.map((item) => (
                <tr key={item.id}>
                  <td>{item.id_sucursal}</td>
                  <td>{item.id_transportadora}</td>
                  <td>{item.comision}</td>
                  <td>{item.comision_contrapago}</td>
                  <td>{item.sobreflete}</td>
                  <td>{item.minima_sobreflete}</td>
                  <td>{item.comision_interna}</td>
                  <td>
                    <button onClick={() => handleEdit(item.id)} className='configuracion-negociacion-edit-button'>Editar</button>
                    <button onClick={() => showDeleteConfirm(item.id)} className='configuracion-negociacion-delete-button'>Eliminar</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="configuracion-negociacion-pagination">
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index}
              className={`configuracion-negociacion-page-button ${currentPage === index + 1 ? 'active' : ''}`}
              onClick={() => setCurrentPage(index + 1)}
            >
              {index + 1}
            </button>
          ))}
        </div>
      </div>

      <Modal
        title={editingId ? "Editar Negociación" : "Agregar Negociación"}
        visible={modalVisible}
        onOk={handleModalOk}
        onCancel={() => setModalVisible(false)}
        okText={editingId ? "Guardar" : "Agregar"}
        cancelText="Cancelar"
        className="configuracion-negociacion-modal"
        okButtonProps={{ className: 'configuracion-negociacion-modal-button configuracion-negociacion-modal-button-primary' }}
        cancelButtonProps={{ className: 'configuracion-negociacion-modal-button configuracion-negociacion-modal-button-default' }}
      >
        <Form form={form} layout="vertical" className="configuracion-negociacion-form">
          <Form.Item name="id_sucursal" label="ID Sucursal">
            <InputNumber className="configuracion-negociacion-input configuracion-negociacion-input-number" />
          </Form.Item>
          <Form.Item name="id_transportadora" label="ID Transportadora">
            <InputNumber className="configuracion-negociacion-input configuracion-negociacion-input-number" />
          </Form.Item>
          <Form.Item name="comision" label="Comisión">
            <Input className="configuracion-negociacion-input" />
          </Form.Item>
          <Form.Item name="comision_contrapago" label="Comisión Contrapago">
            <Input className="configuracion-negociacion-input" />
          </Form.Item>
          <Form.Item name="sobreflete" label="Sobreflete">
            <Input className="configuracion-negociacion-input" />
          </Form.Item>
          <Form.Item name="minima_sobreflete" label="Mínima Sobreflete">
            <Input className="configuracion-negociacion-input" />
          </Form.Item>
          <Form.Item name="comision_interna" label="Comisión Interna">
            <Input className="configuracion-negociacion-input" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default ConfigNegociacion;