import React, { useState, useEffect } from 'react';
import axios from 'axios';
import NavbarAdminMex from '../components/navbar';
import './dashboardAdmin.css';
import moment from 'moment';

const DashboardAdminNew = () => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [dashboardData, setDashboardData] = useState(null);

  const fetchData = async () => {
    const usuario_id = localStorage.getItem('usuario_id');
    if (!usuario_id) {
      console.error('usuario_id no encontrado en localStorage');
      return;
    }

    const desde = startDate || moment().subtract(1, 'year').format('YYYY-MM-DD');
    const hasta = endDate || moment().format('YYYY-MM-DD');

    try {
      const response = await axios.get(`https://api.99envios.app/api/panel-sucursales/${usuario_id}`, {
        params: {
          tipo_fecha: 'personalizado',
          fecha_desde: desde,
          fecha_hasta: hasta,
        },
      });
      setDashboardData(response.data);
    } catch (error) {
      console.error('Error al obtener los datos de la API', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [startDate, endDate]);

  return (
    <>
      <NavbarAdminMex title={"Dashboard"}/>
      <div className="dashboard-container">
        <div className="date-picker-container">
          <div className="date-picker">
            <label>Fecha inicio:</label>
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </div>
          <div className="date-picker">
            <label>Fecha de corte:</label>
            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </div>
  
        </div>
        {dashboardData ? (
          <div className="cards-container">
            <div className="row">
              <div className="cardD">
                <div className="icon-circleA local-shipping-icon">
                  <span className="material-icons-sharp"> people </span>
                </div>
                <h2>Usuarios TFL</h2>
                <p>{dashboardData.estadisticas_pedidos.total}</p>
              </div>
              <div className="cardD">
                <div className="icon-circleA check-circle-icon">
                  <span className="material-icons-sharp"> balance </span>
                </div>
                <h2>Usuarios PAY</h2>
                <p>{dashboardData.estadisticas_pedidos.Entregado}</p>
                <div className="rate-containerA">
                  <span className="material-icons success-icon"> thumb_up </span>
                  <p className="rate">Tasa de Entrega</p>
                </div>
                <p className="success" style={{ color: "#4caf50" }}>
                  {(
                    (dashboardData.estadisticas_pedidos.Entregado / (dashboardData.estadisticas_pedidos.total - dashboardData.estadisticas_pedidos.Devuelto)) * 100
                  ).toFixed(2)}%
                </p>
              </div>
              <div className="cardD">
                <div className="icon-circleA report-icon">
                  <span className="material-icons-sharp">  thumb_up  </span>
                </div>
                <h2>Envios Mes</h2>
                <p>{dashboardData.estadisticas_pedidos.Devuelto}</p>
                <div className="rate-containerA">
                  <span className="material-icons error-icon"> reply_all </span>
                  <p className="rate">Tasa de Devolución</p>
                </div>
                <p className="error" style={{ color: "#f44336" }}>
                  {(
                    (dashboardData.estadisticas_pedidos.Devuelto / dashboardData.estadisticas_pedidos.total) * 100
                  ).toFixed(2)}%
                </p>
              </div>
            </div>
            <div className="row">
              <div className="cardD">
                <div className="icon-circleA track-changes-icon">
                  <span className="material-icons-sharp"> thumb_down_alt </span>
                </div>
                <h2>Devoluciones Mes</h2>
                <p>Entrega exitosa: {dashboardData.estadisticas_pedidos.Entregado}</p>
                <p>Entregada: {dashboardData.estadisticas_pedidos.Entregado}</p>
                <p>Creado: {dashboardData.estadisticas_pedidos.Creado}</p>
                <p>Centro acopio: {dashboardData.estadisticas_pedidos['Centro de acopio']}</p>
                <p>Transito nacional: {dashboardData.estadisticas_pedidos['Transito nacional']}</p>
              </div>
              <div className="cardD">
                <div className="icon-circleA public-icon">
                  <span className="material-icons-sharp"> reply_all </span>
                </div>
                <h2>Devoluciones por ciudad</h2>
                {dashboardData.destinos_comunes.map((destino, index) => (
                  <p key={index}>{destino.ciudad}: {destino.cantidad}</p>
                ))}
              </div>
            </div>
          </div>
        ) : (
          <p>Cargando datos...</p>
        )}
      </div>
    </>
  );
}

export default DashboardAdminNew;
