import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './Estilos/entregaFallida.css';
import Navbar from '../../../components/navbar';
import Footer from '../../../components/footer';
import Head from '../../../components/headerConductor';
import Carga from '../../../components/carga';
import { DatePicker } from 'antd';

const { RangePicker } = DatePicker;

function EntregaFallida() {
    const [orders, setOrders] = useState([]);
    const [filteredOrders, setFilteredOrders] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    let navigate = useNavigate();

    useEffect(() => {
        fetchOrders();
    }, []);

    const fetchOrders = async () => {
        setIsLoading(true);
        const id_usuario = localStorage.getItem('usuario_id');
        const url = `https://api.99envios.app/api/reporte-pedidos/Devuelto/${id_usuario}`;
        try {
            const response = await axios.get(url);
            setOrders(response.data);
            setFilteredOrders(response.data);  // Inicializa con todos los pedidos
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching orders:', error);
            setIsLoading(false);
        }
    };

    const handleRangeChange = (dates) => {
        if (dates) {
            const [start, end] = dates;
            const filtered = orders.filter(order => {
                const date = new Date(order.fecha_pedido.split('T')[0]);
                return date >= start && date <= end;
            });
            setFilteredOrders(filtered);
        } else {
            setFilteredOrders(orders); // Si no hay fechas seleccionadas, muestra todos los pedidos
        }
    };

    function handleDetailsClick(orderId) {
        navigate(`/conductor/pedidos/${orderId}`);
    }

    return (
        <div>
            <Navbar title={"Entrega Fallida"}/>
            <div className="order-summary">
                <Head title={"Entrega Fallida"} />
                <div className="fecha-pedidos">
                    <h2>Filtrar por rango de fechas:</h2>
                    <RangePicker
                        onChange={handleRangeChange}
                        format="YYYY-MM-DD"
                    />
                </div>
                {isLoading && <Carga />}
                <div className="lista-pedidos">
                    {filteredOrders.map((order) => (
                        <div key={order.ID_pedido} className="card shipping-info">
                            <h2>Pedido #{order.ID_pedido}</h2>
                            <p>Cliente: {order.nombre_cliente}</p>
                            <p>Fecha pedido: {order.fecha_pedido.split('T')[0]}</p>
                            <button className="details-button" onClick={() => handleDetailsClick(order.ID_pedido)}>Detalles</button>
                        </div>
                    ))}
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default EntregaFallida;
