import React from 'react';
import axios from 'axios';
import { useEffect, useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { message } from 'antd';
import Swal from 'sweetalert2';
import './Estilos/pago.css'; // Make sure to import the CSS file
import Navbar from '../../../components/navbar';
import Footer from '../../../components/footer';
import Head from '../../../components/headerConductor';

function PagoEfectivo() {
    const [paymentProofImage, setPaymentProofImage] = useState(null);
    let navigate = useNavigate(); // Hook para usar la navegación
    let pedidoId = useParams(); // Hook para capturar el ID del pedido desde la URL
    const [orderInfo, setOrderInfo] = useState(null);
    const [efectivo, setEfectivo] = useState(null);
    let id = pedidoId.id;

    const fetchOrderInfo = useCallback(async () => {
        try {
            console.log('Fetching order info for:', pedidoId);
            let idpedidi = pedidoId.id;
            console.log('idpedidi:', idpedidi);
            const url = `https://api.99envios.app/api/pedidos/${idpedidi}`;
            const response = await axios.get(url);
            console.log('GET Response:', response.data);
            setOrderInfo(response.data);
        } catch (error) {
            console.error('Error fetching order info:', error);
        }
    }, [pedidoId]);

    useEffect(() => {
        if (pedidoId) {
            fetchOrderInfo();
        }
    }, [pedidoId, fetchOrderInfo]);

    const handlePaymentProofImageChange = (e) => {
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0];
            const fileType = file.type.split('/')[0];
            if (fileType !== 'image') {
                message.error('Solo se permiten archivos de imagen.');
                return;
            }
            setPaymentProofImage(file);
        }
    };

    //Enviar foto a la api
    const sendPaymentProofImage = async () => {
        try {
            setEfectivo(orderInfo.valor_producto);
            console.log('orderInfo.valor_producto', orderInfo.valor_producto);
            const formData = new FormData();
            formData.append('foto_comprobante_efectivo', paymentProofImage);
            formData.append('ID_pedido', id); // Corregido el nombre del campo
            formData.append('estado_pago', 'Entregado');
            formData.append('pago_efectivo', orderInfo.valor_producto);

            console.log('Sending payment proof image:', formData);
            const response = await axios.post(`https://api.99envios.app/api/pago_efectivo`, formData);
            if (response.status === 200) {
                message.success('Imagen de comprobante de pago enviada correctamente');
            }
        } catch (error) {
            message.error('Error al enviar la imagen del comprobante de pago');
            console.error('Error sending payment proof image:', error);
        }
    };

    const markAsDelivered = async () => {
        try {
            const response = await axios.put(`https://api.99envios.app/api/pedidos/${id}/actualizar-a-entregado`);
            if (response.status === 200) {
                message.success('Pedido entregado correctamente');
                fetchOrderInfo(); // Refresh order details
            }
        } catch (error) {
            console.error('Error updating order to delivered:', error);
        }
    };

    const handleDetailsClick = () => {
        console.log(paymentProofImage);
        if (!paymentProofImage) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Por favor, selecciona una imagen antes de enviar.',
            });
        } else {
            try {
                markAsDelivered();
                sendPaymentProofImage();
                navigate('/conductor/entrega-check');
            } catch (error) {
                console.error('Error updating order to delivered:', error);
            }
        }
    };

    if (!orderInfo) {
        return <div>Cargando información del pedido...</div>;
    }

    const total = orderInfo && orderInfo.costo_envio != null && orderInfo.valor_producto != null && orderInfo.FEE99 != null
        ? (parseFloat(orderInfo.costo_envio) + parseFloat(orderInfo.valor_producto) + parseFloat(orderInfo.FEE99))
        : null;

    return (
        <div>
            <Navbar title={"Pago"} />
            <div>
                <div className='head'>
                    <Head title={"Pago Efectivo"} />
                </div>
                <div className="contenedor">
                    <div className="information">
                        <p>Fecha: {orderInfo.fecha_pedido} </p>
                        <p>Estado: {orderInfo.estado_pedido}</p>
                        <p className="net-total"><span>Total a cobrar:</span> {total !== null ? total.toLocaleString('es-CO', { style: 'currency', currency: 'COP' }) : 'Cargando...'}</p>
                    </div>

                    <div className="section">
                        <h3>Comprobante de entrega</h3>
                        <p>Anexa una captura o fotografía de la transacción aprobada del pago realizado</p>
                        <input type="file" onChange={handlePaymentProofImageChange} accept="image/*" />
                        {paymentProofImage && (
                            <div>
                                <p>Vista previa de la imagen:</p>
                                <img src={URL.createObjectURL(paymentProofImage)} alt="Vista previa" className="image-preview" />
                            </div>
                        )}
                    </div>

                    <button onClick={handleDetailsClick} className="submit-button" disabled={!paymentProofImage}>
                        Enviar
                    </button>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default PagoEfectivo;
