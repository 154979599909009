import React from 'react';
import Navbar from '../../../components/navbar';
import Footer from '../../../components/footer';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import confetti from 'canvas-confetti';
import './Estilos/entregaCheck.css'; 


const EntregaCheck = () => {

    let navigate = useNavigate(); 

    function handleDetailsClick() {
        
        navigate('/conductor');
    }
  // Confetti animation
    const runConfetti = () => {
      confetti({
          particleCount: 150,
          spread: 70,
          origin: { y: 0.6 }
      });
  };

  useEffect(() => {
      runConfetti();
  }, []);

  return (
    <>
        <Navbar title={"Entrega Exitosa"} />
        <div className="success-animation-container">
            <div className="checkmark-circle">
                <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                    <circle className="checkmark-circle" cx="26" cy="26" r="25" fill="none"/>
                    <path className="checkmark-check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
                </svg>
            </div>
            <h1 className='titulosE'>Entrega Exitosa</h1>
            <h2 className='SubTituloFinal'>¡Gracias por tu trabajo!</h2>
            <button className="btn btn-primary" onClick={handleDetailsClick}>Volver al tablero</button>
        </div>
        <Footer />
    </>
);
};

export default EntregaCheck;