import React from 'react';
import NavbarMex from '../components/navbarMex';
import './Tutoriales.css';

const videos = [
  { src: "https://www.youtube.com/embed/OoIAsbi4zeo", title: "Cómo funciona la plataforma de 99 envíos" },
  { src: "https://www.youtube.com/embed/e0HnapzPP-0", title: "Generar guías 99envios.com" },
  { src: "https://www.youtube.com/embed/RYCXzyzWlYc", title: "Recolección 99envios.com" },
  { src: "https://www.youtube.com/embed/aCROllMTH3s", title: "Novedades 99envios.com" },
  { src: "https://www.youtube.com/embed/SuS6-6Rsw8g", title: "Envios y cartera 99envios.com" },
  { src: "https://www.youtube.com/embed/6zCJZuZy5uI", title: "Retiros 99envios.com" },
  { src: "https://www.youtube.com/embed/O6AKV5FM0nI", title: "MODULO PQR 99envios.com" },
  { src: "https://www.youtube.com/embed/kTJW_rBgE6o", title: "¿Cómo te inscribes? 99envios.com" },
  { src: "https://www.youtube.com/embed/wc_Q37mE8XM", title: "Tutorial subir y enviar paquetes Bogotá a Bogotá" },
  { src: "https://www.youtube.com/embed/23Fq1pLTj5w", title: "GUIAS MASIVAS 99 ENVIOS" },
  { src: "https://www.youtube.com/embed/290mET2ZW6E", title: "Error 403 99envios.com" }
];

const Tutoriales = () => {
  return (
    <>
    <NavbarMex title={"Tutoriales"}/>
    <div className="tutoriales-container">
    <h2>Tutoriales</h2>
      <div className="videos-grid">
      {videos.map((video, index) => (
          <div key={index} className="video-item">
            <iframe
              src={video.src}
              title={video.title}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            ></iframe>
            <div className="video-title">{video.title}</div>
          </div>
        ))}
      </div>
    </div>
    </>
  );
};

export default Tutoriales;
