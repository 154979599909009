import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Input, Dropdown, Menu, Button, Select } from 'antd';
import { DownOutlined, SearchOutlined } from '@ant-design/icons';
import NavbarDropshipping from '../navbarDropshipping/navbarDropshipping';
import './misPedidos.css';

const { Option } = Select;

const ProductTablePedidos = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [filters, setFilters] = useState({
    estadoOrden: '',
    transportadora: '',
    estadoEnvio: '',
  });
  const usuario_id = localStorage.getItem('usuario_id');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`https://api.99envios.app/api/listar_pedidos/${usuario_id}`);
        const formattedData = response.data.map((item, index) => ({
          key: index,
          id: index + 1,
          nombre_producto: item.nombre_producto,
          fecha_estado: item.fecha_estado,
          estado_envio: item.estado_envio,
          transportadora: item.transportadora,
          valor_proveedor: item.precio_real,
          cantidad: item.cantidad,
          valor_total: item.valor_producto * item.cantidad, // Asumiendo que 'valor_producto' es el valor por unidad
          // valor_envio: item.precio_envio,
          valor_utilidad: item.utilidad, // Placeholder ya que no está presente en la respuesta de la API
        }));
        setData(formattedData);
        setFilteredData(formattedData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [usuario_id]);

  const columns = [
    {
      title: '',
      dataIndex: 'checkbox',
      key: 'checkbox',
      render: () => <input type="checkbox" />,
    },
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Nombre del producto',
      dataIndex: 'nombre_producto',
      key: 'nombre_producto',
    },
    {
      title: 'Fecha de estado',
      dataIndex: 'fecha_estado',
      key: 'fecha_estado',
    },
    {
      title: 'Estado del Envío',
      dataIndex: 'estado_envio',
      key: 'estado_envio',
    },
    {
      title: 'Transportadora',
      dataIndex: 'transportadora',
      key: 'transportadora',
    },
    {
      title: 'Valor de Proveedor',
      dataIndex: 'valor_proveedor',
      key: 'valor_proveedor',
      render: (value) => `$${value.toLocaleString()}`,
    },
    {
      title: 'Cantidad',
      dataIndex: 'cantidad',
      key: 'cantidad',
    },
    {
      title: 'Valor Total',
      dataIndex: 'valor_total',
      key: 'valor_total',
      render: (value) => `$${value.toLocaleString()}`,
    },
    // {
    //   title: 'Valor de Envío',
    //   dataIndex: 'valor_envio',
    //   key: 'valor_envio',
    //   render: (value) => `$${value.toLocaleString()}`,
    // },
    {
      title: 'Utilidad',
      dataIndex: 'valor_utilidad',
      key: 'valor_utilidad',
      render: (value) => `$${value.toLocaleString()}`,
    },
  ];

  const handleSearch = (value) => {
    setSearchText(value);
    const filtered = data.filter(
      (item) =>
        item.nombre_producto.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredData(filtered);
  };

  const handleFilterChange = (filterName, value) => {
    setFilters({ ...filters, [filterName]: value });
  };

  useEffect(() => {
    let result = data;
    if (searchText) {
      result = result.filter(
        (item) =>
          item.nombre_producto.toLowerCase().includes(searchText.toLowerCase())
      );
    }
    if (filters.estadoOrden) {
      result = result.filter((item) => item.estado_orden === parseInt(filters.estadoOrden));
    }
    if (filters.transportadora) {
      result = result.filter((item) => item.transportadora.toLowerCase() === filters.transportadora.toLowerCase());
    }
    if (filters.estadoEnvio) {
      result = result.filter((item) => item.estado_envio.toLowerCase() === filters.estadoEnvio.toLowerCase());
    }
    setFilteredData(result);
  }, [searchText, filters, data]);

  const menu = (
    <Menu>
      <Menu.Item key="1">Descargar</Menu.Item>
     
    </Menu>
  );

  return (
    <>
      <NavbarDropshipping title="Órdenes y Envíos Bodega" />
      <div className='misPedidos'>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '16px' }}>
          <Dropdown overlay={menu}>
            <Button>
              Acciones <DownOutlined />
            </Button>
          </Dropdown>
          <Input
            placeholder="Buscar"
            prefix={<SearchOutlined />}
            style={{ width: 200 }}
            onChange={(e) => handleSearch(e.target.value)}
          />
        </div>
        <div style={{ marginBottom: '16px' }}>
          <Select
            style={{ width: 200, marginRight: '8px' }}
            placeholder="Filtrar por Transportadora"
            onChange={(value) => handleFilterChange('transportadora', value)}
          >
            <Option value="">Todas</Option>
            <Option value="interrapidisimo">Interrapidisimo</Option>
            <Option value="coordinadora">Coordinadora</Option>
            <Option value="servientrega">Servientrega</Option>
            <Option value="envia">Envia</Option>
          </Select>
          <Select
            style={{ width: 200 }}
            placeholder="Filtrar por Estado de Envío"
            onChange={(value) => handleFilterChange('estadoEnvio', value)}
          >
            <Option value="">Todos</Option>
            <Option value="creado">Creado</Option>
            <Option value="en_transito">En tránsito</Option>
            <Option value="entregado">Entregado</Option>
          </Select>
        </div>
        <div className="misPedidos-tabla">
          <Table
            columns={columns}
            dataSource={filteredData}
            pagination={{
              pageSize: 10,
              showSizeChanger: false,
              showQuickJumper: false,
            }}
            locale={{ emptyText: 'No hay datos disponibles' }}
          />
        </div>
      </div>
    </>
  );
};

export default ProductTablePedidos;
