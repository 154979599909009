import React, { useState, useEffect } from 'react';
import NavbarAdminMex from '../components/navbar';
import { Table, Button, Input, Typography, message } from "antd";
import { SearchOutlined } from '@ant-design/icons';
import { utils, writeFile } from 'xlsx';
import axios from 'axios';
import './pagos.css';

const Pagos = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get('https://99envios.app/api/online/transaccion');
      setData(response.data.map(item => ({ ...item, key: item.id })));
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      message.error('Failed to fetch data');
      setLoading(false);
    }
  };

  const exportToExcel = () => {
    const worksheet = utils.json_to_sheet(data);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, "Data");
    writeFile(workbook, "pagos_data.xlsx");
  };

  const getColumnSearchProps = (title, dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div className="transaccion-pagos-filter-dropdown">
        <div className="transaccion-pagos-filter-input-container">
          <Input
            placeholder={`Buscar ${title}`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={confirm}
            className="transaccion-pagos-filter-input"
          />
          <Button
            type="primary"
            onClick={confirm}
            icon={<SearchOutlined />}
            size="small"
            className="transaccion-pagos-filter-search-button"
          />
        </div>
        <Button
          onClick={clearFilters}
          size="small"
          className="transaccion-pagos-filter-reset-button"
        >
          Resetear
        </Button>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#3F51B5' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
  });
  
  const handleUpdateStatus = async (id) => {
    try {
      setLoading(true);
      const response = await axios.put(`https://99envios.app/api/online/transaccion/${id}`);
      
      if (response.status === 200) {
        message.success(`Estado actualizado con éxito para el ID: ${id}`);
        await fetchData();
      } else {
        message.error(`Error al actualizar el estado para el ID: ${id}`);
      }
    } catch (error) {
      console.error('Error al actualizar el estado:', error);
      message.error(`Error al actualizar el estado para el ID: ${id}`);
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      title: "Registro",
      dataIndex: "id",
      key: "id",
      sorter: (a, b) => a.id - b.id,
      ...getColumnSearchProps("ID", "id"),
    },
    {
      title: "Código Sucursal",
      dataIndex: "id_sucursal",
      key: "id_sucursal",
      sorter: (a, b) => a.id_sucursal - b.id_sucursal,
      ...getColumnSearchProps("ID Sucursal", "id_sucursal"),
    },
    {
      title: "Nombre",
      dataIndex: "nombre",
      key: "nombre",
      sorter: (a, b) => a.nombre.localeCompare(b.nombre),
      ...getColumnSearchProps("Nombre", "nombre"),
    },
    {
      title: "Tipo de Identificación",
      dataIndex: "tipo_identificacion",
      key: "tipo_identificacion",
      ...getColumnSearchProps("Tipo de Identificación", "tipo_identificacion"),
    },
    {
      title: "Número de Identificación",
      dataIndex: "numero_identificacion",
      key: "numero_identificacion",
      ...getColumnSearchProps("Número de Identificación", "numero_identificacion"),
    },
    {
      title: "Tipo de Transacción",
      dataIndex: "tipo_transaccion",
      key: "tipo_transaccion",
      ...getColumnSearchProps("Tipo de Transacción", "tipo_transaccion"),
    },
    {
      title: "Tipo de Cuenta",
      dataIndex: "tipo_cuenta",
      key: "tipo_cuenta",
      ...getColumnSearchProps("Tipo de Cuenta", "tipo_cuenta"),
    },
    {
      title: "Nombre del Banco",
      dataIndex: "nombre_banco",
      key: "nombre_banco",
      sorter: (a, b) => a.nombre_banco.localeCompare(b.nombre_banco),
      ...getColumnSearchProps("Nombre del Banco", "nombre_banco"),
    },
    {
      title: "Código del Banco",
      dataIndex: "codigo_banco",
      key: "codigo_banco",
      ...getColumnSearchProps("Código del Banco", "codigo_banco"),
    },
    {
      title: "Número de Cuenta",
      dataIndex: "numero_cuenta",
      key: "numero_cuenta",
      ...getColumnSearchProps("Número de Cuenta", "numero_cuenta"),
    },
    {
      title: "Fecha",
      dataIndex: "Fecha",
      key: "Fecha",
      sorter: (a, b) => new Date(a.Fecha) - new Date(b.Fecha),
      ...getColumnSearchProps("Fecha", "Fecha"),
    },
    {
      title: "Monto",
      dataIndex: "Monto",
      key: "Monto",
      sorter: (a, b) => (a.Monto || 0) - (b.Monto || 0),
      render: (text) => text ? `$${text.toFixed(2)}` : 'N/A',
    },
    {
      title: "Estado",
      dataIndex: "estado",
      key: "estado",
      sorter: (a, b) => a.estado.localeCompare(b.estado),
      ...getColumnSearchProps("Estado", "estado"),
    },
    {
      title: "Fecha de Pago",
      dataIndex: "fecha_pago",
      key: "fecha_pago",
      sorter: (a, b) => new Date(a.fecha_pago || 0) - new Date(b.fecha_pago || 0),
      render: (text) => text || 'N/A',
    },
    {
      title: "Actualizar Estado",
      key: "actualizar_estado",
      render: (_, record) => (
        <Button onClick={() => handleUpdateStatus(record.id)} loading={loading}>
          Actualizar
        </Button>
      ),
    },
  ];
  
  return (
    <>
      <NavbarAdminMex title={"Pagos"} />
      <div className="transaccion-pagos-container">
        <Typography.Title level={2} className="transaccion-pagos-title">
          Pagos
        </Typography.Title>
        <Button 
          onClick={exportToExcel} 
          className="transaccion-pagos-button transaccion-pagos-export-button"
        >
          Exportar a Excel
        </Button>
        <Typography.Title level={3} className="transaccion-pagos-subtitle">
          Tabla Pagos
        </Typography.Title>
        <Table
          columns={columns}
          dataSource={data}
          loading={loading}
          pagination={{
            pageSize: 10,
            position: ["bottomCenter"],
          }}
          locale={{
            sortTitle: "Ordenar",
            triggerAsc: "Orden ascendente",
            triggerDesc: "Orden descendente",
            cancelSort: "Cancelar orden",
          }}
          scroll={{ x: 'max-content' }}
          className="transaccion-pagos-table"
        />
      </div>
    </>
  );
};

export default Pagos;