import React from "react";
import NavbarAdminMex from '../components/navbar';
import { Form, Input, Button, Card, Radio, InputNumber } from 'antd';

const Modal = () => {
  const [form] = Form.useForm();

  const onFinish = (values) => {
    console.log('Valores del formulario:', values);
  };

  return (
    <>
      <NavbarAdminMex title={"Modal"}/>
      <Card style={{ width: '60%', marginLeft: '20%', marginTop: '2%', borderRadius: '8px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)'}}>
        <Form
          form={form}
          name="estado_modal"
          onFinish={onFinish}
          layout="vertical"
        >
          <Form.Item name="estado" label="Estado del Modal">
            <Radio.Group>
              <Radio value="activo">Activo</Radio>
              <Radio value="inactivo">Inactivo</Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item name="dias_actividad" label="Días de actividad:">
            <InputNumber min={0} />
          </Form.Item>

          <Form.Item
            name="mensaje"
            label="Mensaje"
          >
            <Input.TextArea />
          </Form.Item>

          <Form.Item style={{ textAlign: 'center' }}>
            <Button type="primary" htmlType="submit" style={{ backgroundColor: '#8c8cff' }}>
              Enviar
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </>
  );
};

export default Modal;