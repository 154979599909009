import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { message } from 'antd';
import './NavbarMex.css';

const NavbarMex = ({ title }) => {
  const [isMenuOpen, setMenuOpen] = useState(true);
  const [isSubMenuOpen, setSubMenuOpen] = useState(false);
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    const storedId = localStorage.getItem('usuario_id');
    if (storedId) {
      setUserId(storedId);
    }
  }, []);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  const toggleSubMenu = () => {
    setSubMenuOpen(!isSubMenuOpen);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('usuario_id');
    localStorage.removeItem('rol_id');
    localStorage.removeItem('email');
    window.location.href = '/';
    message.success('Sesión cerrada exitosamente');
  };

  return (
    <>
      <div className="navbar-top">
        <div className={`hamburger-menu ${isMenuOpen ? 'open' : ''}`} onClick={toggleMenu}>
          <div className="bar1"></div>
          <div className="bar2"></div>
          <div className="bar3"></div>
        </div>
        <div className="navbar-tittle">
          <p>{title}</p>
        </div>
        <div className="user-info">
          <p>Hola, Enviador Estrella</p>
          <p className="panel-link">99Panel</p>
          <div className="user-avatar">
            <img src="/Images/avatar-repartidor.jpg" alt="Avatar" />
          </div>
        </div>
      </div>
      <div className={`navbar-container ${isMenuOpen ? 'show' : 'hide'}`}>
        <div className="close-btn" onClick={toggleMenu}>X</div>
        <div className="navbar-title">
          <img src="/Images/download.png" alt="Download" className="logo" />
        </div>
        <div className="navbar-menu">
          <ul>
            <li>
              <NavLink to="/dashboardMex" activeClassName="active">
                <span className="material-icons">dashboard</span>Dashboard
              </NavLink>
            </li>
            <li>
              <NavLink to={`/guiaForm/${userId}`} activeClassName="active">
                <span className="material-icons">description</span>Guías Unitarias
              </NavLink>
            </li>
            <li>
              <NavLink to={`/guias-masivo/${userId}`} activeClassName="active">
                <span className="material-icons">library_books</span>Guías Masivo (BETA 10 máximo)
              </NavLink>
            </li>
            <li>
              <NavLink to={`/masivo-final/${userId}`} activeClassName="active">
                <span className="material-icons">dynamic_feed</span>Guías Masivo Final
              </NavLink>
            </li>
            <li>
              <NavLink to="/buscar-guia" activeClassName="active">
                <span className="material-icons">search</span>Rastrear Guía
              </NavLink>
            </li>
            <li>
              <NavLink to="/novedades" activeClassName="active">
                <span className="material-icons">insert_chart_outlined</span>Novedades
              </NavLink>
            </li>
            <li>
              <NavLink to="/envios-completos" activeClassName="active">
                <span className="material-icons">local_shipping</span>Envios completos
              </NavLink>
            </li>
            {/* Historicos envios completos */}
            {/*
           <li>
              <NavLink to="/historico-envios" activeClassName="active">
                <span className="material-icons">history</span>Historico Envios completos
              </NavLink>
            </li>
            */}
            <li>
              <NavLink to="/cartera-completa" activeClassName="active">
                <span className="material-icons">account_balance_wallet</span>Cartera completa
              </NavLink>
            </li>
            {/* Historico cartera completa */}
            {/*
            <li>
              <NavLink to="/historico-cartera" activeClassName="active">
                <span className="material-icons">history</span>Historico Cartera completa
              </NavLink>
            </li>
            */}
            {/*
            <li>
              <NavLink to="/retiros" activeClassName="active">
                <span className="material-icons">pending_actions</span>Retiros
              </NavLink>
            </li>
            */}
            <li>
              <NavLink to="/auto-pqr" activeClassName="active">
                <span className="material-icons">account_circle</span>Auto PQR
              </NavLink>
            </li>
            <li>
              <NavLink to="https://99envios.com/recargas/" target='_blank' activeClassName="active">
                <span className="material-icons">account_balance_wallet</span>Recargas
              </NavLink>
            </li>
            <li>
              <NavLink to="/tutoriales" activeClassName="active">
                <span className="material-icons">insert_chart_outlined</span>Tutoriales
              </NavLink>
            </li>
            <li>
              <NavLink to="/" onClick={handleLogout} activeClassName="active">
                <span className="material-icons">exit_to_app</span>Cerrar sesión
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}

export default NavbarMex;