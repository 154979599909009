import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Modal, Button, Form, Input, Select } from 'antd';
import './TableComponent.css';
import NavbarMex from '../components/navbarMex';

const { Option } = Select;

const TableComponent = () => {
  const [data, setData] = useState([]);
  const [search, setSearch] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [isModalVisible, setIsModalVisible] = useState(false); // Estado para el modal
  const [form] = Form.useForm(); // Formulario de antd
  const user_id = localStorage.getItem('usuario_id');

  useEffect(() => {
    axios.get(`https://99envios.online/api/novedades-externas-temporal/${user_id}`)
      .then(response => {
        const novedades = response.data.novedades_diarias.map(novedad => ({
          numero_guia: novedad.Numero_de_guía,
          transportadora: novedad.Transportadora,
          fecha_actualizacion: novedad.Fecha_de_actualización,
          novedad: novedad.Novedad,
          producto: novedad.Producto,
          destinatario: novedad.Nombre_destinatario
        }));
        setData(novedades);
      })
      .catch(error => console.error('Error fetching data: ', error));
  }, []);

  const handleSearch = (event) => {
    setSearch(event.target.value);
    setCurrentPage(1);
  };

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const sortedData = React.useMemo(() => {
    let sortableData = [...data];
    if (sortConfig.key !== '') {
      sortableData.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableData;
  }, [data, sortConfig]);

  const filteredData = sortedData.filter(item =>
    item.numero_guia.toLowerCase().includes(search.toLowerCase()) ||
    item.transportadora.toLowerCase().includes(search.toLowerCase()) ||
    item.fecha_actualizacion.toLowerCase().includes(search.toLowerCase()) ||
    item.novedad.toLowerCase().includes(search.toLowerCase()) ||
    item.producto.toLowerCase().includes(search.toLowerCase()) ||
    item.destinatario.toLowerCase().includes(search.toLowerCase())
  );

  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const currentEntries = filteredData.slice(indexOfFirstEntry, indexOfLastEntry);

  const totalPages = Math.ceil(filteredData.length / entriesPerPage);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleOk = () => {
    form.validateFields().then(values => {
      console.log('Form values:', values);
      // Aquí puedes manejar el envío del formulario
      setIsModalVisible(false);
    }).catch(info => {
      console.log('Validate Failed:', info);
    });
  };

  return (
    <>
      <NavbarMex title="Novedades" />
      <Button
        type="primary"
        onClick={showModal}
        style={{
          marginTop: "20px",
          marginLeft: "20%",
          backgroundColor: "#7C89F3",
          color: "white",
          border: "none",
          borderRadius: "8px",
          padding: "10px 20px",
          fontSize: "16px",
          cursor: "pointer",
          transition: "background-color 0.3s ease",
          height: "60px",
          textAlign: "center",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        onMouseEnter={(e) => e.currentTarget.style.backgroundColor = "#6C7BE4"}
        onMouseLeave={(e) => e.currentTarget.style.backgroundColor = "#7C89F3"}
      >
        Gestionar Novedad
      </Button>
      <div className="table-containerNO" style={{ marginLeft: "20%" }}>
        <div className="table-controls">
          <label htmlFor="entries">Show</label>
          <select
            id="entries"
            name="entries"
            value={entriesPerPage}
            onChange={(e) => setEntriesPerPage(parseInt(e.target.value))}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
          <input
            type="text"
            placeholder="Search"
            value={search}
            onChange={handleSearch}
            className="search-input"
          />
        </div>
        <table className="custom-table">
          <thead>
            <tr>
              <th onClick={() => handleSort('numero_guia')}>Numero de guía</th>
              <th onClick={() => handleSort('transportadora')}>Transportadora</th>
              <th onClick={() => handleSort('fecha_actualizacion')}>Fecha actualización</th>
              <th onClick={() => handleSort('novedad')}>Novedad</th>
              <th onClick={() => handleSort('producto')}>Producto</th>
              <th onClick={() => handleSort('destinatario')}>Destinatario</th>
            </tr>
          </thead>
          <tbody>
            {currentEntries.map((item, index) => (
              <tr key={index}>
                <td>{item.numero_guia}</td>
                <td>{item.transportadora}</td>
                <td>{item.fecha_actualizacion}</td>
                <td>{item.novedad}</td>
                <td>{item.producto}</td>
                <td>{item.destinatario}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="pagination">
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index}
              className={`page-button ${currentPage === index + 1 ? 'active' : ''}`}
              onClick={() => setCurrentPage(index + 1)}
            >
              {index + 1}
            </button>
          ))}
        </div>
      </div>

      <Modal
        title={<strong>Formulario de Trámite</strong>}
        visible={isModalVisible}
        footer={null}
        onCancel={() => setIsModalVisible(false)}
        closable={true}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            label="Número de guía"
            name="numero_guia"
            rules={[{ required: true, message: 'Por favor ingrese el número de guía' }]}
          >
            <Input placeholder="Haz clic y utiliza Ctrl + V para pegar el número de guía" />
          </Form.Item>
          <Form.Item
            label="Indique la acción a realizar"
            name="accion"
            rules={[{ required: true, message: 'Por favor seleccione una opción' }]}
          >
            <Select placeholder="Seleccione una opción">
              <Option value="nuevo_intento_entrega">Nuevo intento de entrega</Option>
              <Option value="devolver">Devolver</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Observaciones adicionales"
            name="observaciones"
          >
            <Input.TextArea rows={4} />
          </Form.Item>
          
          {/* Botón de "Procesar" personalizado */}
          <Button
            type="primary"
            onClick={handleOk}
            style={{
              backgroundColor: "#7C89F3",
              color: "white",
              border: "none",
              borderRadius: "8px",
              padding: "10px 20px",
              fontSize: "16px",
              cursor: "pointer",
              transition: "background-color 0.3s ease",
              height: "40px", // Ajusta la altura del botón
              width: "auto", // Ajusta el ancho automáticamente según el contenido
              textAlign: "center",
              display: "flex",
              alignItems: "center", // Centra el contenido verticalmente
              justifyContent: "center", // Centra el contenido horizontalmente
              margin: "0 auto", // Centra el botón horizontalmente dentro del contenedor
            }}
            onMouseEnter={(e) => e.currentTarget.style.backgroundColor = "#6C7BE4"}
            onMouseLeave={(e) => e.currentTarget.style.backgroundColor = "#7C89F3"}
          >
            Procesar
          </Button>
        </Form>
      </Modal>
    </>
  );
};

export default TableComponent;
