import React, { useState, useEffect } from 'react';
import { Modal, Form, Input, InputNumber, Select, Checkbox } from 'antd';
import NavbarAdminMex from '../components/navbar';
import './configTransportadora.css';

const { Option } = Select;

const API_BASE_URL = 'https://integration.99envios.app/api/configuracion_transportadoras';

const ConfigTransportadora = () => {
  const [data, setData] = useState([]);
  const [search, setSearch] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [modalVisible, setModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [editingId, setEditingId] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}`);
      if (!response.ok) throw new Error('Network response was not ok');
      const result = await response.json();
      console.log('API Response:', result);
      const sanitizedData = result.map(item => ({
        ...item,
        transportadora: item.transportadora || { nombre: '' }
      }));
      setData(sanitizedData);
    } catch (error) {
      console.error("Failed to fetch data:", error);
    }
  };

  const handleSearch = (event) => {
    setSearch(event.target.value);
    setCurrentPage(1);
  };

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const sortedData = React.useMemo(() => {
    let sortableData = [...data];
    if (sortConfig.key !== '') {
      sortableData.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableData;
  }, [data, sortConfig]);

  const filteredData = sortedData.filter(item =>
    Object.values(item).some(val => 
      val && val.toString().toLowerCase().includes(search.toLowerCase())
    )
  );

  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const currentEntries = filteredData.slice(indexOfFirstEntry, indexOfLastEntry);

  const totalPages = Math.ceil(filteredData.length / entriesPerPage);

  const handleEdit = (id) => {
    const itemToEdit = data.find(item => item.id === id);
    form.setFieldsValue({
      ...itemToEdit,
      nombre_transportadora: itemToEdit.transportadora.nombre
    });
    setEditingId(id);
    setModalVisible(true);
  };

  const handleAdd = () => {
    form.resetFields();
    setEditingId(null);
    setModalVisible(true);
  };

  const handleModalOk = async () => {
    try {
      const values = await form.validateFields();
      if (editingId) {
        await handleSave(editingId, values);
      } else {
        await handleAddNew(values);
      }
      setModalVisible(false);
      form.resetFields();
    } catch (error) {
      console.error("Validation failed:", error);
    }
  };

  const handleSave = async (id, values) => {
    try {
      const response = await fetch(`${API_BASE_URL}/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...values,
          transportadora: { nombre: values.nombre_transportadora }
        }),
      });
      if (!response.ok) throw new Error('Network response was not ok');
      await fetchData();
    } catch (error) {
      console.error("Failed to update item:", error);
    }
  };

  const handleAddNew = async (values) => {
    try {
      const response = await fetch(`${API_BASE_URL}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...values,
          transportadora: { nombre: values.nombre_transportadora }
        }),
      });
      if (!response.ok) throw new Error('Network response was not ok');
      await fetchData();
    } catch (error) {
      console.error("Failed to add new entry:", error);
    }
  };

  return (
    <>
      <NavbarAdminMex title="Configuración Trasportadora"/>
      <div className="configuracion-transportadora-table-container">
        <div className="configuracion-transportadora-table-controls">
          <label htmlFor="entries">Mostrar</label>
          <select
            id="entries"
            name="entries"
            value={entriesPerPage}
            onChange={(e) => setEntriesPerPage(parseInt(e.target.value))}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
          <input
            type="text"
            placeholder="Buscar"
            value={search}
            onChange={handleSearch}
            className="configuracion-transportadora-search-input"
          />
        </div>
        <button onClick={handleAdd} className="configuracion-transportadora-add-button">Agregar</button>
        <div className="configuracion-transportadora-table-wrapper">
          <table className="configuracion-transportadora-custom-table">
            <thead>
              <tr>
                <th onClick={() => handleSort('id_transportadoras')}>ID Transportadora</th>
                <th onClick={() => handleSort('valor_kilo_adicional')}>Valor Kilo Adicional</th>
                <th onClick={() => handleSort('activo')}>Activo</th>
                <th onClick={() => handleSort('ambiente')}>Ambiente</th>
                <th onClick={() => handleSort('comision')}>Comisión</th>
                <th onClick={() => handleSort('comision_contrapago')}>Comisión Contrapago</th>
                <th onClick={() => handleSort('sobreflete')}>Sobreflete</th>
                <th onClick={() => handleSort('minima_sobreflete')}>Mínima Sobreflete</th>
                <th onClick={() => handleSort('minima_sobreflete2')}>Mínima Sobreflete 2</th>
                <th onClick={() => handleSort('minima_contrapago')}>Mínima Contrapago</th>
                <th onClick={() => handleSort('transportadora.nombre')}>Nombre Transportadora</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {currentEntries.map((item) => (
                <tr key={item.id}>
                  <td>{item.id_transportadoras || 'N/A'}</td>
                  <td>{item.valor_kilo_adicional || 0}</td>
                  <td>{item.activo === 1 ? 'Sí' : 'No'}</td>
                  <td>{item.ambiente || 'N/A'}</td>
                  <td>{item.comision || '0.00'}</td>
                  <td>{item.comision_contrapago || '0.00'}</td>
                  <td>{item.sobreflete || '0.00'}</td>
                  <td>{item.minima_sobreflete || '0.00'}</td>
                  <td>{item.minima_sobreflete2 || '0.00'}</td>
                  <td>{item.minima_contrapago || 0}</td>
                  <td>{(item.transportadora && item.transportadora.nombre) || 'N/A'}</td>
                  <td>
                    <button onClick={() => handleEdit(item.id)} className='configuracion-transportadora-edit-button'>Editar</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="configuracion-transportadora-pagination">
          {Array.from({ length: totalPages }, (_, i) => (
            <button 
              className={`configuracion-transportadora-page-button ${currentPage === i + 1 ? 'active' : ''}`}
              key={i}
              onClick={() => setCurrentPage(i + 1)}
            >
              {i + 1}
            </button>
          ))}
        </div>
      </div>

      <Modal
        title={editingId ? "Editar Transportadora" : "Agregar Transportadora"}
        visible={modalVisible}
        onOk={handleModalOk}
        onCancel={() => setModalVisible(false)}
        okText={editingId ? "Guardar" : "Agregar"}
        cancelText="Cancelar"
        className="configuracion-transportadora-modal"
        okButtonProps={{ className: 'configuracion-transportadora-modal-button configuracion-transportadora-modal-button-primary' }}
        cancelButtonProps={{ className: 'configuracion-transportadora-modal-button configuracion-transportadora-modal-button-default' }}
      >
        <Form form={form} layout="vertical" className="configuracion-transportadora-form">
          <Form.Item name="id_transportadoras" label="ID Transportadora">
            <Select className="configuracion-transportadora-select">
              <Option value="1">1</Option>
              <Option value="2">2</Option>
              <Option value="3">3</Option>
              <Option value="4">4</Option>
            </Select>
          </Form.Item>
          <Form.Item name="valor_kilo_adicional" label="Valor Kilo Adicional">
            <InputNumber className="configuracion-transportadora-input configuracion-transportadora-input-number" />
          </Form.Item>
          <Form.Item name="activo" valuePropName="checked">
            <Checkbox className="configuracion-transportadora-checkbox">Activo</Checkbox>
          </Form.Item>
          <Form.Item name="ambiente" label="Ambiente">
            <Select className="configuracion-transportadora-select">
              <Option value="produccion">Producción</Option>
              <Option value="pruebas">Pruebas</Option>
            </Select>
          </Form.Item>
          <Form.Item name="comision" label="Comisión">
            <Input className="configuracion-transportadora-input" />
          </Form.Item>
          <Form.Item name="comision_contrapago" label="Comisión Contrapago">
            <Input className="configuracion-transportadora-input" />
          </Form.Item>
          <Form.Item name="sobreflete" label="Sobreflete">
            <Input className="configuracion-transportadora-input" />
          </Form.Item>
          <Form.Item name="minima_sobreflete" label="Mínima Sobreflete">
            <Input className="configuracion-transportadora-input" />
          </Form.Item>
          <Form.Item name="minima_sobreflete2" label="Mínima Sobreflete 2">
            <Input className="configuracion-transportadora-input" />
          </Form.Item>
          <Form.Item name="minima_contrapago" label="Mínima Contrapago">
            <InputNumber className="configuracion-transportadora-input configuracion-transportadora-input-number" />
          </Form.Item>
          <Form.Item name="nombre_transportadora" label="Nombre Transportadora">
            <Select className="configuracion-transportadora-select">
              <Option value="interrapidisimo">Interrapidisimo</Option>
              <Option value="servientrega">Servientrega</Option>
              <Option value="tcc">TCC</Option>
              <Option value="coordinadora">Coordinadora</Option>
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default ConfigTransportadora;