import React from 'react';
import axios from 'axios';
import Navbar from '../../components/navbarOut';
import Footer from '../../components/footer';
import { Form, Input, Button, message, Card } from 'antd';
import 'antd/dist/antd.css'; // Import Ant Design stylesheet

const FormularioUsuario = () => {
  const [form] = Form.useForm();

  const cardStyle = {
    width: '350px',
    borderRadius: '20px',
    overflow: 'hidden',
    boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2)',
    backgroundColor: '#333333  ',
    color: '#95a8cf',
    padding: '20px',
  };

  const buttonStyle = {
    width: '100%',
    backgroundColor: '#687BD4',
    borderColor: '#687BD4',
    color: 'white',
    height: '40px',
    fontSize: '16px',
  };

  const inputStyle = {
    marginBottom: '20px',
    color: 'black',
  };

  const handleSubmit = async (values) => {
    try {
      const usuarioData = {
        nombre_usuario: values.nombre_usuario,
        email: values.email,
        password: values.password,
        password_confirmation: values.confirm_password,
        id_rol: 4,
        informacion_contacto: 3155886
             };
      
      

      const response = await axios.post('https://api.99envios.app/api/auth/register', usuarioData);
      message.success('Usuario creado con éxito!');
      console.log('Usuario creado con éxito:', response);
      form.resetFields();
    } catch (error) {
      if (error.response) {
        // La solicitud fue hecha y el servidor respondió con un código de estado
        // que cae fuera del rango de 2xx
        console.error('Error data:', error.response.data);
        console.error('Error status:', error.response.status);
        console.error('Error headers:', error.response.headers);
        message.error(`Error al enviar los datos: ${error.response.data.message}`);
      } else if (error.request) {
        // La solicitud fue hecha pero no se recibió respuesta
        console.error('Error request:', error.request);
      } else {
        // Algo sucedió en la configuración de la solicitud que causó un Error
        console.error('Error', error.message);
      }
    }
  }

  return (
    <div>
      <Navbar /> {/* Aquí se coloca el Navbar */}
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: '#ECF5F4',
      }}>
        <Card style={cardStyle}>
          <Form
            form={form}
            name="registro"
            onFinish={handleSubmit}
            layout="vertical"
          >
            <h2 style={{ color: 'white', textAlign: 'center', marginBottom: '24px' }}>Registro</h2>

            <Form.Item
              name="nombre_usuario"
              rules={[{ required: true, message: 'Por favor ingresa tu nombre!' }]}
            >
              <Input placeholder="Nombre" className="dark-placeholder" style={inputStyle} />
            </Form.Item>

            <Form.Item
              name="email"
              rules={[{ required: true, message: 'Por favor ingresa tu correo!' }]}
            >
              <Input placeholder="Correo" className="dark-placeholder" style={inputStyle} />
            </Form.Item>

            <Form.Item
              name="password"
              rules={[{ required: true, message: 'Por favor ingresa tu contraseña!' }]}
            >
              <Input.Password placeholder="Contraseña" className="dark-placeholder" style={inputStyle} />
            </Form.Item>
            <Form.Item
              name="confirm_password"
              rules={[
                { required: true, message: 'Por favor confirma tu contraseña!' },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('Las contraseñas que ingresaste no coinciden!'));
                  },
                }),
              ]}
            >
              <Input.Password placeholder="Confirma tu contraseña" className="dark-placeholder" style={inputStyle} />
            </Form.Item>


            <Form.Item>
              <Button type="primary" htmlType="submit" className="dark-placeholder" style={buttonStyle}>
                Registro
              </Button>
            </Form.Item>

            <Form.Item>
              <Button type="default" htmlType="button" style={{ ...buttonStyle, backgroundColor: 'transparent', color: '#687BD4', borderColor: '#687BD4' }}>
                Login
              </Button>
            </Form.Item>

            <div style={{ textAlign: 'center', color: 'white' }}>
              <a href="#" style={{ color: '#687BD4' }}>Olvidaste tu Contraseña click aquí</a>
            </div>
          </Form>
        </Card>
      </div>
      <Footer /> {/* Aquí se coloca el Footer */}
    </div>

  );
};

export default FormularioUsuario;
