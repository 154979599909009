import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { message, Modal } from 'antd';
import axios from 'axios';
import Navbar from '../../../components/navbar';
import Footer from '../../../components/footer';
import Head from '../../../components/headerConductor';

function Pedidos() {
    const { pedidoId } = useParams();
    const id = pedidoId;
    const navigate = useNavigate();
    const [orderInfo, setOrderInfo] = useState(null);
    const [email, setEmail] = useState('');
    const [productDetails, setProductDetails] = useState({
        imageUrl: '',
        size: '',
        color: '',
        name: ''
    });

    useEffect(() => {
        const email = localStorage.getItem('email');
        setEmail(email);
    }, []);

    const fetchOrderInfo = useCallback(async () => {
        try {
            const response = await axios.get(`https://api.99envios.app/api/pedidos/${pedidoId}`);
            console.log('GET Response:', response.data); // Log the response of the GET request
            setOrderInfo(response.data);

            const detallesProducto = response.data.detalles_producto.split('|');
            const imageUrl = detallesProducto[0];
            const size = detallesProducto[2];
            const color = detallesProducto[3];
            const name = detallesProducto[4];

            setProductDetails({
                imageUrl,
                size,
                color,
                name
            });

        } catch (error) {
            console.error('Error fetching order info:', error);
        }
    }, [pedidoId]);

    useEffect(() => {
        if (pedidoId) {
            fetchOrderInfo();
        }
    }, [pedidoId, fetchOrderInfo]);

    const handleCall = (phoneNumber) => {
        window.location.href = `tel:${phoneNumber}`;
    };

    const handleSearchAddress = (address) => {
        if (address) {
            window.open(`https://www.google.com/maps/search/${encodeURIComponent(address)}`, '_blank');
        }
    };

    const unassignOrder = useCallback(async () => {
        try {
            const response = await axios.put(`https://api.99envios.app/api/pedidos/${pedidoId}/actualizar-a-null`);
            console.log('PUT Response:', response); // Log the response of the PUT request
            if (response.status === 200) {
                message.success('Pedido desasignado correctamente');
                navigate('/conductor?refresh=true');
            } else {
                throw new Error('Failed to update the order status');
            }
        } catch (error) {
            console.error('Error marking the order as returned:', error);
            alert('No se pudo marcar el pedido como devuelto');
        }
    }, [pedidoId]);

    const handleChangeStatus = async (id) => {
        Modal.confirm({
            title: '¿Estás seguro de que quieres cambiar el estado del pedido?',
            content: 'Esta acción cambiará el estado del pedido a "En proceso" y no puede deshacerse.',
            onOk: async () => {
                const url = `https://api.99envios.app/api/pedidos/${id}/en-proceso`;
                try {
                    const response = await axios.put(url);
                    if (response.status === 200) {
                        message.success('Pedido actualizado correctamente');
                        navigate('/conductor/ruta-salir-entregar?refresh=true');
                    }
                } catch (error) {
                    console.error('Error al actualizar el estado del pedido:', error);
                    message.error('Error al actualizar el estado del pedido.');
                }
            }
        });
    };

    const handleNewsButtonClick = () => {
        navigate(`/conductor/novedades/${pedidoId}`);
    };

    if (!orderInfo) {
        return <div>Cargando información del pedido...</div>;
    }

    const total = orderInfo && orderInfo.costo_envio != null && orderInfo.valor_producto != null && orderInfo.FEE99 != null 
    ? (parseFloat(orderInfo.costo_envio) + parseFloat(orderInfo.valor_producto) + parseFloat(orderInfo.FEE99)) 
    : null;

    const statesToHideButtons = ['Entregado', 'Devuelto'];

    const handleWhatsApp = (phoneNumber) => {
        window.open(`https://wa.me/${phoneNumber}`, '_blank');
    };

    return (
        <div>
            <Navbar title={"Pedidos"} />
            <div className="order-summary">
                <Head title={`Pedido #${orderInfo?.ID_pedido || 'Cargando...'}`} />
                <div className="card order-info">
                    <h2>Información</h2>
                    <p>Fecha: {orderInfo?.fecha_pedido || 'Cargando...'}</p>
                    <p>Fecha entrega: {orderInfo?.fecha_entrega_repartidor || 'No entregado'}</p>
                    <p>Estado: {orderInfo?.estado_pedido || 'Cargando...'}</p>
                    <p>Total: {total !== null ? total.toLocaleString('es-CO', { style: 'currency', currency: 'COP' }) : 'Cargando...'}</p>
                </div>

                <div className="card shipping-info">
                    <h2>Dirección de Envio</h2>
                    <p>Direccion de entrega: {orderInfo?.direccion_entrega || 'Cargando...'}</p>
                    <p>Ciudad: {orderInfo?.ciudad || 'Cargando...'}</p>
                    <p>País: {orderInfo?.pais || 'Cargando'}</p>
                    <p>Codigo de ciudad: {orderInfo?.codigo_ciudad || 'Cargando...'} </p>
                    <p>Telefono: {orderInfo?.cliente.telefono || 'Cargando...'}</p>
                    <button onClick={() => handleWhatsApp(orderInfo?.cliente.telefono)} className="map-button">WhatsApp</button>
                    <button onClick={() => handleSearchAddress(orderInfo?.direccion_entrega)} className="map-button">Mapa</button>
                </div>

                <div className="card shipping-info">
                    <h2>Dirección de Recogida</h2>
                    <p>Id cliente: {orderInfo.id_cliente}</p>
                    <p>Dirección de revogida:{orderInfo.direccion_recogida}</p>
                    <p>Ciudad:{orderInfo.ciudad}</p>
                </div>

                <div className="card product-list">
                    <div className="card product-details" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>
                        <h2 style={{ width: '100%', textAlign: 'center', marginBottom: '20px' }}>Detalles del Producto</h2>
                        <div style={{ textAlign: 'left', width: '100%', marginBottom: '20px' }}>
                            <p><strong>Nombre:</strong> {productDetails.name || 'No disponible'}</p>
                            <p><strong>Talla:</strong> {productDetails.size || 'No disponible'}</p>
                            <p><strong>Color:</strong> {productDetails.color || 'No disponible'}</p>
                            <p><strong>Valor:</strong> {orderInfo?.valor_producto ? `${parseFloat(orderInfo.valor_producto).toLocaleString('es-CO', { style: 'currency', currency: 'COP' })}` : '$0'}</p>
                        </div>
                    </div>
                </div>

                <div className="card delivery-person">
                    <h2>Repartidor</h2>
                    <p>Correo: {email || 'Cargando...'}</p>
                </div>
                {orderInfo && !statesToHideButtons.includes(orderInfo.estado_pedido) && (
                    <div className="button-container">
                        <button className="button unassign-button" onClick={unassignOrder}>Desasignar</button>
                        <button className="button news-button" onClick={handleNewsButtonClick}>
                            Novedades
                        </button>
                        <button className="button deliver-button" onClick={() => handleChangeStatus(id)}>
                            Salir a entregar
                        </button>
                        
                    </div>
                )}
            </div>
            <Footer />
        </div>
    );
}

export default Pedidos;
