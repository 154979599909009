import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import { Row, Col, Card, DatePicker, Button, Statistic, Table } from 'antd';
import { FaCheckCircle, FaTimes } from 'react-icons/fa';
import {
  ArrowUpOutlined,
  EnvironmentOutlined,
  SyncOutlined,
  CheckCircleOutlined,
  AlertOutlined,
  CarOutlined,
} from '@ant-design/icons';
import 'antd/dist/antd.css';
import Navbar from '../../components/navbar';
import Footer from '../../components/footer';


const Dashboard = () => {

  const columns = [
    {
      title: 'ID pedido',
      dataIndex: 'ID_pedido',
      key: 'id',
    },
    {
      title: 'Fecha pedido',
      dataIndex: 'fecha_pedido',
      key: 'fecha',
      render: (text) => <span>{moment(text).format('DD/MM/YYYY')}</span>,
    },
  ];

  const datePickerColStyle = {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '8px',
  };

  const datePickerStyle = {
    width: '100%',
    maxWidth: '250px',
  };

  // const buttonStyle = {
  //   marginTop: '16px',
  //   maxWidth: '300px',
  //   width: '100%',
  //   backgroundColor: '#687BD4',
  //   color: 'white',
  //   border: '1px solid transparent',
  //   borderRadius: '7px',
  //   padding: '10px 15px',
  //   fontSize: '14px',
  //   fontWeight: '500',
  //   display: 'flex',
  //   justifyContent: 'center',
  //   alignItems: 'center',
  //   boxShadow: '0 2px 0 rgba(0, 0, 0, 0.045)',
  //   cursor: 'pointer',
  //   transition: 'all 0.3s ease',
  //   outline: 'none',
  //   height: '40px',
  // };

  const containerStyle = {
    padding: '10px',
    maxWidth: '600px',
    margin: 'auto',
    textAlign: 'center',
  };

  const cardStyle = {
    borderRadius: '40px',
    overflow: 'hidden',
    marginTop: '16px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '400PX',

  };

  const ant = {
   backgroundColor: 'rgb(241, 249, 251)'
}


  const cardBodyStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  };

  const iconAboveTitleStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '4px',
    fontSize: '1em',
  };

  const [sucursalData, setSucursalData] = useState(null);
  const [fechaDesde, setFechaDesde] = useState(null);
  const [fechaHasta, setFechaHasta] = useState(null);

  const fetchData = async () => {
    const desde = fechaDesde ? fechaDesde.format('YYYY-MM-DD') : moment().subtract(1, 'year').format('YYYY-MM-DD');
    const hasta = fechaHasta ? fechaHasta.format('YYYY-MM-DD') : moment().format('YYYY-MM-DD');
    const usuario_id = localStorage.getItem('usuario_id');
    console.log("ID de usuario:", usuario_id);

    try {
      const url = `https://api.99envios.app/api/panel-sucursales/${usuario_id}`;
      console.log("URL de la solicitud:", url);
      const response = await axios.get(url, {
        params: {
          tipo_fecha: 'personalizado',
          fecha_desde: desde,
          fecha_hasta: hasta,
        },
      });
      console.log("Datos recibidos:", response.data);
      setSucursalData(response.data);
    } catch (error) {
      console.error('Error al obtener los datos de la API', error);
      // Puedes agregar más detalles aquí, como:
      console.error("Detalle del error:", error.response ? error.response.data : "No hay más información del error");
    }
  };

  useEffect(() => {
    fetchData();
  }, [fechaDesde, fechaHasta]);




  return (

    <div>

      <Navbar title={"Tablero"} />
      <div style={{
        padding: '15px',
        background: '#ececec',
        backgroundColor: '#F1EFEF',
        borderRadius: '8px',
        minHeight: '50vh',
      }}>
        <div style={containerStyle}>

          <h1 style={{ marginBottom: '20px' }}>Tablero</h1>
          <Row gutter={[16, 32]} justify="center">
            <Col xs={24} sm={12} style={datePickerColStyle}>
              <label style={{ paddingRight: '8px', whiteSpace: 'nowrap', }}>Fecha inicio:</label>
              <DatePicker placeholder="Fecha de inicio" style={{ ...datePickerStyle, border: '1px solid green', borderRadius: '10px', }} onChange={setFechaDesde} />
            </Col>
            <Col xs={24} sm={12} style={datePickerColStyle}>
              <label style={{ paddingRight: '8px', whiteSpace: 'nowrap' }}>Fecha de corte:</label>
              <DatePicker placeholder="Fecha de corte" style={{ ...datePickerStyle, border: '1px solid red', borderRadius: '10px' }} onChange={setFechaHasta} />
            </Col>
          </Row>
          <Row justify="center" style={{ marginBottom: '24px' }}>
            {/* <Col xs={24} sm={12}>
              <Button type="primary" style={buttonStyle} onClick={fetchData}>Buscar Resultados</Button>
            </Col> */}
          </Row>
        </div>

        <Row gutter={[16, 16]} justify="center">
          <Col xs={24} sm={8} md={8} lg={6} xl={4}>
            <Card hoverable style={{ ...cardStyle, background: 'white', textAlign: 'center', height: '300PX', }}>
              <Statistic
                title={
                  <div style={{ ...iconAboveTitleStyle, justifyContent: 'center', marginBottom: '20px' }}>
                    <CarOutlined style={{ color: '#03b1fc', fontSize: '2.5em' }} />
                    <span>Total Envíos</span>
                  </div>
                }
                valueStyle={{ fontSize: '2.5em', fontWeight: 'bold', color: '#333' }} // Estilo para que coincida con los otros números
                value={sucursalData?.estadisticas_pedidos.total}
                suffix={<ArrowUpOutlined style={{ color: '#03b1fc' }} />}
                style={cardBodyStyle}
              />
            </Card>

          </Col>


          <Col xs={24} sm={8} md={8} lg={6} xl={4}>
            <Card hoverable style={{ ...cardStyle, background: 'white', textAlign: 'center', height: '300PX', }}>
              {/* Ícono y Título */}
              <div style={{ ...iconAboveTitleStyle, justifyContent: 'center', marginBottom: '20px' }}>
                <FaCheckCircle style={{ color: 'orange', fontSize: '2.5em' }} />
                <span style={{ fontSize: '1.2em', color: 'grey', display: 'block', marginTop: '10px' }}>Total Entregado</span>
              </div>
              {/* Valor y Sufijo */}
              <div style={{ fontSize: '2.5em', fontWeight: 'bold', color: '#333', marginBottom: '20px' }}>
                {sucursalData ? sucursalData.estadisticas_pedidos.Entregado : 'Cargando...'} <ArrowUpOutlined style={{ color: '#faad14' }} />
              </div>
              {/* Etiqueta de Tasa de Entrega */}
              <div style={{ marginBottom: '10px' }}>
                <CheckCircleOutlined style={{ color: 'green', marginRight: '5px' }} />
                <span style={{ fontSize: '1em', color: 'grey' }}>Tasa de Entrega</span>
              </div>
              {/* Tasa de Entrega en Porcentaje */}
              <div style={{ fontSize: '2em', color: 'green', fontWeight: 'bold' }}>
                {sucursalData ? (
                  sucursalData.estadisticas_pedidos.Entregado > 0 ?
                    ((sucursalData.estadisticas_pedidos.Entregado / (sucursalData.estadisticas_pedidos.total - sucursalData.estadisticas_pedidos.Devuelto)) * 100).toFixed(2) + '%' :
                    '0 %'
                ) : (
                  'Calculando...'
                )}
              </div>
            </Card>
          </Col>




          <Col xs={24} sm={8} md={8} lg={6} xl={4}>
            <Card hoverable style={{ ...cardStyle, background: 'white', textAlign: 'center', height: '300PX', }}>
              {/* Ícono y Título */}
              <div style={{ ...iconAboveTitleStyle, justifyContent: 'center', marginBottom: '20px' }}>
                {/* Usa el ícono giratorio como en tu diseño original y ajusta el color y el tamaño */}
                <SyncOutlined style={{ color: '#f759ab', fontSize: '2.5em' }} />
                <span style={{ fontSize: '1.2em', color: 'grey', display: 'block', marginTop: '10px' }}>Devoluciones</span>
              </div>
              {/* Valor y Sufijo */}
              <div style={{ fontSize: '2.5em', fontWeight: 'bold', color: '#333', marginBottom: '20px' }}>
                {sucursalData ? sucursalData.estadisticas_pedidos.Devuelto : 'Cargando...'}
              </div>
              {/* Etiqueta de Tasa de Devolución */}
              <div style={{ marginBottom: '10px' }}>
                <FaTimes style={{ color: '#ff4d4f', marginRight: '5px' }} />
                <span style={{ fontSize: '1em', color: 'grey' }}>Tasa de Devolución</span>
              </div>
              {/* Tasa de Devolución en Porcentaje */}
              <div style={{ fontSize: '2em', color: '#ff4d4f', fontWeight: 'bold' }}>
                {sucursalData ? (
                  sucursalData.estadisticas_pedidos.Devuelto > 0 ?
                    ((sucursalData.estadisticas_pedidos.Devuelto / sucursalData.estadisticas_pedidos.total) * 100).toFixed(2) + '%' :
                    '0 %'
                ) : (
                  'Calculando...'
                )}
              </div>
            </Card>
          </Col>
        </Row>

        <div style={{ margin: '20px 0' }}></div>

        <Row gutter={[16, 16]} justify="center">
          <Col xs={24} sm={8} md={8} lg={6} xl={4}>
            <Card hoverable style={{ ...cardStyle, background: 'white', textAlign: 'center' }}>
              {/* Ícono y Título */}
              <div style={{ ...iconAboveTitleStyle, justifyContent: 'center', marginBottom: '20px' }}>
                <CheckCircleOutlined style={{ color: '#03b1fc', fontSize: '2.5em' }} />
                <span style={{ fontSize: '1.2em', color: 'grey', display: 'block', marginTop: '10px' }}>Estado Envios</span>
              </div>
              {/* Lista de estados */}
              <div style={{ fontSize: '1.2em', color: '#333', marginBottom: '20px' }}>
                <p>Entregada:  <strong>{sucursalData ? sucursalData.estadisticas_pedidos.Entregado : 'Cargando...'}</strong></p>
                <p>Devolución ratificada:  <strong>{sucursalData ? sucursalData.estadisticas_pedidos.Devuelto : 'Cargando...'}</strong></p>
                <p>En proceso: <strong>{sucursalData ? sucursalData.estadisticas_pedidos['En proceso'] : 'Cargando...'}</strong></p>
                <p>En espera:  <strong>{sucursalData ? sucursalData.estadisticas_pedidos['En espera'] : 'Cargando...'}</strong></p>

              </div>
              {/* <a style={{ fontSize: '1em', color: '#1890ff' }}>Ver más</a> */}
            </Card>
          </Col>

          <Col xs={24} sm={8} md={8} lg={6} xl={4}>
            <Card hoverable style={{ ...cardStyle, background: 'white', textAlign: 'center' }}>
              {/* Ícono y Título */}
              <div style={{ ...iconAboveTitleStyle, justifyContent: 'center', marginBottom: '20px' }}>
                <EnvironmentOutlined style={{ color: 'orange', fontSize: '2.5em' }} />
                <span style={{ fontSize: '1.2em', color: 'grey', display: 'block', marginTop: '10px' }}>Destinos Más Usados</span>
              </div>
              {/* Lista de destinos */}
              <div style={{ fontSize: '1.2em', color: '#333', marginBottom: '20px' }}>
                {sucursalData ? (
                  sucursalData.destinos_comunes.length > 0 ? (
                    sucursalData.destinos_comunes.map((destino, index) => (
                      <p key={index}>{destino.ciudad}: {destino.cantidad}</p>

                    ))
                  ) : (
                    <p>No hay destinos registrados</p>
                  )
                ) : (
                  'Cargando destinos...'
                )}
              </div>

              {/* <a style={{ fontSize: '1em', color: 'orange' }}>Ver más</a> */}
            </Card>
          </Col>

          <Col xs={24} sm={8} md={8} lg={6} xl={4}>
            <Card hoverable style={{ ...cardStyle, background: 'white ' }}>
              <div style={{ ...iconAboveTitleStyle, justifyContent: 'center', marginBottom: '20px' }}>
                <AlertOutlined style={{ color: '#ff4d4f', fontSize: '2.5em' }} />
                <span style={{ fontSize: '1.2em', color: 'grey', display: 'block', marginTop: '10px' }}>No asignado</span>
              </div>
              <Table
                columns={columns}
                dataSource={sucursalData?.registros_No_Asignados.registros}
                pagination={false}
                size="small"
                
                style={{ margin: '0 5px', border: 'none' }}
              />
            </Card>


          </Col>
          {/* <Col span={6} style={{ padding: '15px' }}>
          <Menu
            style={{ width: '100%' }}
            defaultSelectedKeys={['1']}
            mode="inline"
          >
            <Menu.Item key="1">Pedidos</Menu.Item>
            <Menu.Item key="2">Crear Pedido</Menu.Item>
            <Menu.Item key="3">Crear Conductor</Menu.Item>
            <Menu.Item key="4">Reportes</Menu.Item>
            
          </Menu>
        </Col> */}
        </Row>

      </div>
      <Footer />
    </div>
  );
};

export default Dashboard;