import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { message } from 'antd';
import './navbarAdminMex.css';

const NavbarAdminMex = ({ title }) => {
  const [isMenuOpen, setMenuOpen] = useState(true);
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    const storedId = localStorage.getItem('usuario_id');
    if (storedId) {
      setUserId(storedId);
    }
  }, []);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('usuario_id');
    localStorage.removeItem('rol_id');
    localStorage.removeItem('email');
    window.location.href = '/';
    message.success('Sesión cerrada exitosamente');
  };

  return (
    <>
      <div className="navbar-top">
        <div className={`hamburger-menu ${isMenuOpen ? 'open' : ''}`} onClick={toggleMenu}>
          <div className="bar1"></div>
          <div className="bar2"></div>
          <div className="bar3"></div>
        </div>
        < div className="navbar-logo">
          <p style={{ marginLeft: "250px" }}>{title}</p>
        </div>
        <div className="user-info">
          <p>Hola, Enviador Estrella</p>
          <p className="panel-link">99Panel</p>
          <div className="user-avatar">
            <img src="path_to_avatar_image" alt="Avatar" />
          </div>
        </div>
      </div>
      <div className={`navbar-container ${isMenuOpen ? 'show' : 'hide'}`}>
        <div className="close-btn" onClick={toggleMenu}>X</div>
        <div className="navbar-title">
          <img src="/Images/download.png" alt="Download" className="logo" />
        </div>
        <div className="navbar-menu">
          <ul>
          <li>
            <NavLink exact to="/dashboardAdmin" activeClassName="active">
              <span className="material-icons">dashboard</span>Dashboard
            </NavLink>
          </li>
          <li>
            <NavLink to="/modal" activeClassName="active">
              <span className="material-icons">open_in_new</span>Modal
            </NavLink>
          </li>
          {/*
          <li>
            <NavLink to="/newuser" activeClassName="active">
              <span className="material-icons">person_add</span>Nuevo user
            </NavLink>
          </li>
          */}
          <li>
            <NavLink to="/newadmin" activeClassName="active">
              <span className="material-icons">add_moderator</span>Nuevo admin
            </NavLink>
          </li>
          <li>
            <NavLink to="/recoleccion" activeClassName="active">
              <span className="material-icons">local_shipping</span>Recolección
            </NavLink>
            </li>
          <li>
            <NavLink to="/nuevo-colaborador" activeClassName="active">
              <span className="material-icons">add_business</span>Nuevo colaborador
            </NavLink>
          </li>
          <li>
            <NavLink to="/sucursalVendedor" activeClassName="active">
              <span className="material-icons">account_tree</span>Sucursal-vendedor
            </NavLink>
          </li>
          <li>
            <NavLink to="/config-transportadora" activeClassName="active">
              <span className="material-icons">local_shipping</span>Configuración Transportadora
            </NavLink>
          </li>
          <li>
            <NavLink to="/tablaInterrapidisimo" activeClassName="active">
              <span className="material-icons">table_chart</span>Tabla Interrapidisimo
            </NavLink>
          </li>
          <li>
            <NavLink to="/config-negociacion" activeClassName="active">
              <span className="material-icons">local_shipping</span>Configuración Negociación
            </NavLink>
          </li>
          {/*
          <li>
            <NavLink to="/crear-sucursal" activeClassName="active">
              <span className="material-icons">domain_add</span>Sucursales-nuevas
            </NavLink>
          </li>
          */}
          <li>
            <NavLink to="/adminpqr" activeClassName="active">
              <span className="material-icons">question_answer</span>PQR
            </NavLink>
          </li>
          <li>
            <NavLink to="/adminpqrfinalizado" activeClassName="active">
              <span className="material-icons">task_alt</span>PQR Finalizados
            </NavLink>
          </li>
          <li>
            <NavLink to="/pagos" activeClassName="active">
              <span className="material-icons">payments</span>Pagos
            </NavLink>
          </li>
          {/*
          <li>
            <NavLink to="/tutoriales" activeClassName="active">
              <span className="material-icons">local_shipping</span>Envios y Devoluciones
            </NavLink>
          </li>
          */}
          <li>
            <NavLink to="/" activeClassName="active">
              <span className="material-icons" onClick={handleLogout}>exit_to_app</span>Logout
            </NavLink>
          </li>
          </ul>
        </div>
      </div>
    </>
  );
}

export default NavbarAdminMex;
