import React, { useCallback, useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { message } from 'antd';
import './Estilos/iniciarEntrega.css';
import Navbar from '../../../components/navbar';
import Footer from '../../../components/footer';
import Head from '../../../components/headerConductor';

function InciarEntrega() {
    const email = localStorage.getItem('email');
    const [orderInfo, setOrderInfo] = useState(null);
    let navigate = useNavigate(); // Hook para usar la navegación
    let pedidoId = useParams(); // Hook para capturar el ID del pedido desde la URL
    
   
    const fetchOrderInfo = useCallback(async () => {
        try {
            console.log('Fetching order info for:', pedidoId);
            let idpedidi = pedidoId.id;
            console.log('idpedidi:', idpedidi);
            const url = `https://api.99envios.app/api/pedidos/${idpedidi}`;
            const response = await axios.get(url);
            console.log('GET Response:', response.data);
            setOrderInfo(response.data);
        } catch (error) {
            console.error('Error fetching order info:', error);
        }
    }, [pedidoId]);

    useEffect(() => {
        if (pedidoId) {
            fetchOrderInfo();
        }
    }, [pedidoId, fetchOrderInfo]);
    
    if (!orderInfo) {
        return <div>Cargando información del pedido...</div>;
    }     const handleCall = (phoneNumber) => {
        window.location.href = `tel:${phoneNumber}`;
    };

    const handleSearchAddress = (address) => {
        if (address) {
            window.open(`https://www.google.com/maps/search/${encodeURIComponent(address)}`, '_blank');
        }
    };
    const cancelOrder = async () => {
        try {
            let idpedidi = pedidoId.id;
            const response = await axios.put(`https://api.99envios.app/api/pedidos/${idpedidi}/actualizar-a-devuelto`);
            if (response.status === 200) {
                fetchOrderInfo(); // Refresh order details
                message.success('Pedido cancelado correctamente');
                navigate('/conductor/entrega-fail?refresh=true');
            }
        } catch (error) {
            console.error('Error canceling order:', error);
        }
    }
    const handleStartDelivery = () => {
        let idpedidi = pedidoId.id;
        if(idpedidi) {
            message.success('Iniciando entrega...');
            navigate(`/conductor/proceso-pago/${idpedidi}?refresh=true`);
            console.log('Iniciando entrega para el pedido:', idpedidi);
        } else {
            console.error("Información del pedido no disponible.");
        }
    };
        
    const total = orderInfo && orderInfo.costo_envio != null && orderInfo.valor_producto != null && orderInfo.FEE99 != null 
    ? (parseFloat(orderInfo.costo_envio) + parseFloat(orderInfo.valor_producto) + parseFloat(orderInfo.FEE99)) 
    : null;

    return (
        <div>
            <Navbar title={"Iniciar Entrega"} />

            <div className="order-summary">
                <Head title={`Pedido #${pedidoId.id}`} />
                {/* Order Information */}
                <div className="card order-info">
                    <h2>Información</h2>
                    <p>Fecha: {orderInfo.fecha_pedido || 'No disponible'}</p>
                    <p>Estado: {orderInfo.estado_pedido || 'No disponible'}</p>
                    {/* <p>Método de pago: {orderInfo.metodo_pago || 'No disponible'}</p> */}
                    <p>Total: {total !== null ? total.toLocaleString('es-CO', { style: 'currency', currency: 'COP' }) : 'Cargando...'}</p>

                </div>

                {/* Shipping Information */}
                <div className="card shipping-info">
                    <h2>Dirección de Envio</h2>
                    <p>Direccion de entrega: {orderInfo?.direccion_entrega || 'Cargando...'}</p>
                    <p>Ciudad: {orderInfo?.ciudad || 'Cargando...'}</p>
                    <p>País: {orderInfo?.pais || 'Cargando'}</p>
                    {/* <p>Codigo de ciudad: {orderInfo?.codigo_ciudad || 'Cargando...'} </p> */}
                    <button onClick={() => handleSearchAddress(orderInfo?.direccion_entrega)} className="map-button">Mapa</button>
                    {/* <button onClick={() => handleCall("31854522456")} className="navigate-button">Llamar</button> */}
                </div>


                {/* dirección de recogida */}
                <div className="card shipping-info">
                    <h2>Dirección de Recogida</h2>
                    <p>Id cliente: {orderInfo.id_cliente}</p>
                    <p>Dirección de revogida:{orderInfo.direccion_recogida}</p>
                    <p>Ciudad:{orderInfo.ciudad}</p>
                    {/* <p>{orderInfo.dropin[0].phone}</p> */}

                    {/* Agrega los botones aquí, aplicando las clases de estilo */}
                    <button onClick={() => handleSearchAddress(orderInfo?.direccion_entrega)} className="map-button">Mapa</button>
                    {/* <button onClick={() => handleCall("31854522456")} className="navigate-button">Llamar</button> */}
                </div>


                <div className="card product-list">
                    <h2>Detalles del Producto</h2>
                        <p>Descripción: {orderInfo?.detalles_producto || 'No disponible'}</p>
                        <p>Valor: {orderInfo?.valor_producto ? `${parseFloat(orderInfo.valor_producto).toLocaleString('es-CO', { style: 'currency', currency: 'COP' })}` : '$0'}</p>
                    
                </div>

                <div className="card total-summary">
                    {/* <p><span>Descuento:</span> <span>-{orderInfo.discount}</span></p>
                    <p><span>Total:</span> <span>{orderInfo.total}</span></p> */}
                    <p className="green-text"><span>Costo envio:</span> <span>+{orderInfo.costo_envio}</span></p>
                    <p className="blue-text"><span>Valor producto:</span> <span>+{orderInfo.valor_producto}</span></p>
                    <p className="green-text"><span>Fee:</span> <span>+{orderInfo.FEE99}</span></p>
                    <p className="net-total"><span>Total Neto:</span> {total !== null ? total.toLocaleString('es-CO', { style: 'currency', currency: 'COP' }) : 'Cargando...'}</p>
                   
                </div>



                {/* Delivery Person Info */}
                <div className=" card delivery-person">
                    <h2>Repartidor</h2>
                    <p>Correo: {email || 'Cargando...'}</p>
                </div>
                <div className="delivery-person-butto">
                    <button onClick={cancelOrder} className="button cancel-button">No entregado</button> 
                    <button onClick={handleStartDelivery} className="button deliver-button">Entregar</button>
                </div>

            </div>
            <Footer />
        </div>
    );
}

export default InciarEntrega;