import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './Estilos/entregado.css'; // Make sure the path is correct
import Navbar from '../../../components/navbar';
import Footer from '../../../components/footer';
import Head from '../../../components/headerConductor';
import Carga from '../../../components/carga';
import { DatePicker } from 'antd';
import moment from 'moment';
import { debounce } from 'lodash'; // Ensure lodash is installed

const { RangePicker } = DatePicker;

function Entregado() {
    const [pedidos, setPedidos] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedDates, setSelectedDates] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        fetchPedidos(); // Fetch all pedidos initially
    }, []);

    const fetchPedidos = async (dates = null) => {
        setIsLoading(true);
        const id_usuario = localStorage.getItem('usuario_id');
        const url = `https://api.99envios.app/api/reporte-pedidos/Entregado/${id_usuario}`;
        try {
            const response = await axios.get(url);
            let filteredData = response.data;

            if (dates && dates.length === 2) {
                const [startDate, endDate] = dates;
                filteredData = filteredData.filter(pedido => {
                    const deliveryDate = moment(pedido.fecha_entrega_repartidor);
                    return deliveryDate.isBetween(startDate, endDate, undefined, '[]'); // Inclusive filtering
                });
            }

            setPedidos(filteredData);
        } catch (error) {
            console.error('Error fetching orders:', error);
        }
        setIsLoading(false);
    };

    // Debouncing the date change function
    const handleDateChange = debounce((dates) => {
        setSelectedDates(dates);
        if (dates && dates.length === 2) {
            fetchPedidos(dates);
        } else {
            fetchPedidos(); // Fetch all if dates are cleared
        }
    }, 500); // 500 milliseconds debounce time

    return (
        <div>
            <Navbar title="Entregado" />
            <div className="entregado-container">
                <Head title="Entregados" />
                <div className="fecha-pedidos">
                    <h2>Filtrar por rango de fechas:</h2>
                    <RangePicker
                        value={selectedDates}
                        onChange={handleDateChange}
                        format="YYYY-MM-DD"
                    />
                </div>
                {isLoading && <Carga />}
                <div className="resumen-pedidos">
                    <div className="summary-item">
                        <span>Pedidos</span>
                        <span>{pedidos.length}</span>
                    </div>
                    <div className="summary-item">
                        <span>Comisión</span>
                        <span>{pedidos.reduce((acc, pedido) => acc + parseFloat(pedido.costo_envio || 0), 0).toLocaleString('es-CO', { style: 'currency', currency: 'COP' })}</span>
                    </div>
                </div>
                <div className="lista-pedidos">
                    {pedidos.map((pedido) => (
                        <div key={pedido.ID_pedido} className="pedido">
                            <div className="pedido-info">
                                <h4>Pedido #{pedido.ID_pedido}</h4>
                                <p>Cliente: {pedido.nombre_cliente}</p>
                                <p>Costo de Envío: {parseFloat(pedido.costo_envio).toLocaleString('es-CO', { style: 'currency', currency: 'COP' })}</p>
                                <p>Fecha de Entrega: {moment(pedido.fecha_entrega_repartidor).format('YYYY-MM-DD')}</p>
                            </div>
                            <button className="detalle-pedido-btn" onClick={() => navigate(`/conductor/pedidos/${pedido.ID_pedido}`)}>Detalles del pedido</button>
                        </div>
                    ))}
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Entregado;
