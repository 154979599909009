import React from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useEffect, useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import './Estilos/procesoPago.css';
import Navbar from '../../../components/navbar';
import Footer from '../../../components/footer';
import Head from '../../../components/headerConductor';

function ProcesoPago() {

    let navigate = useNavigate(); // Hook para usar la navegación
    let pedidoId = useParams(); // Hook para capturar el ID del pedido desde la URL
    const [orderInfo, setOrderInfo] = useState(null);
    let id = pedidoId.id;
    function handleDetailsClickMixto() {
        // Redirige al usuario a la ruta '/order-details'
        navigate(`/conductor/pago-mixto/${id}`);
    }

    function handleDetailsClickEfectivo() {
        // Redirige al usuario a la ruta '/order-details'
        navigate(`/conductor/pago-efectivo/${id}` );
    }

    function handleDetailsClickDigital() {
        // Redirige al usuario a la ruta '/order-details'
        navigate(`/conductor/pago-digital/${id}`);
    }


    const fetchOrderInfo = useCallback(async () => {
        try {
            console.log('Fetching order info for:', pedidoId);
            let idpedidi = pedidoId.id;
            console.log('idpedidi:', idpedidi);
            const url = `https://api.99envios.app/api/pedidos/${idpedidi}`;
            const response = await axios.get(url);
            console.log('GET Response:', response.data);
            setOrderInfo(response.data);
        } catch (error) {
            console.error('Error fetching order info:', error);
        }
    }, [pedidoId]);

    useEffect(() => {
        if (pedidoId) {
            fetchOrderInfo();
        }
    }, [pedidoId, fetchOrderInfo]);

    if (!orderInfo) {
        return <div>Cargando información del pedido...</div>;
    }

    const total = orderInfo && orderInfo.costo_envio != null && orderInfo.valor_producto != null && orderInfo.FEE99 != null 
    ? (parseFloat(orderInfo.costo_envio) + parseFloat(orderInfo.valor_producto) + parseFloat(orderInfo.FEE99)) 
    : null;
    
    return (
        <div>
            <Navbar title={"Proceso de pago"}/>
            <div className='head'>
                <Head  title={`Proceso de pago #${pedidoId.id}`} />
            </div>
            <div className="contenedores">
                <div className="information">
                    <p>Fecha: {orderInfo.fecha_pedido || 'No disponible'}</p>
                    <p>Estado: {orderInfo.estado_pedido || 'No disponible'}</p>
                    {/* <p className="green-text"><span>Costo envio:</span> <span>+{orderInfo.costo_envio}</span></p>
                    <p className="blue-text"><span>Valor producto:</span> <span>+{orderInfo.valor_producto}</span></p>
                    <p className="green-text"><span>Fee:</span> <span>+{orderInfo.FEE99}</span></p> */}
                    <p className="net-total"><span>Total a cobrar:</span> {total !== null ? total.toLocaleString('es-CO', { style: 'currency', currency: 'COP' }) : 'Cargando...'}</p>
                   
                </div>
                <div className="buttons-container">
                    <button onClick={handleDetailsClickEfectivo} className="custom-button">Pago Efectivo Total</button>
                    <button onClick={handleDetailsClickDigital} className="custom-button">Pago digital Total</button>
                    <button onClick={handleDetailsClickMixto} className="custom-button">Pago Mixto</button>
                </div>
            </div>

            <Footer />
        </div>
    );
}

export default ProcesoPago;