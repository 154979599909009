import React, { useEffect, useState } from 'react'
import { Form, Input, Button, Select, Typography, message, Modal, Radio, Spin } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import axios from 'axios';
import './SendClient.css';

const { Option } = Select;
const { Text } = Typography;

const SendClientPage = (props) => {
  const [form] = Form.useForm();
  const [costo, setCosto] = useState(null);
  const [precioProveedor, setPrecioProveedor] = useState(null);
  const [transportadora, setTransportadora] = useState(0);
  const [selectedImage, setSelectedImage] = useState(null);
  const [inputValue, setInputValue] = useState(1);
  const [selectedVariation, setSelectedVariation] = useState(null);
  const [loading, setLoading] = useState(false); // Estado para controlar la rueda de carga
  const [subtotal, setSubtotal] = useState(0);
  const [idsucursal, setIdsucursal] = useState('');

  const usuario_id = localStorage.getItem('usuario_id');

  useEffect(() => {
    const fetchSucursalId = async () => {
      try {
        const response = await axios.get(`https://99envios.app/api/online/sucursal-codigo-sucursal/${usuario_id}`);
        setIdsucursal(response.data);
      } catch (error) {
        console.error('Error al obtener el ID de la sucursal', error);
      }
    };
  
    if (usuario_id) {
      fetchSucursalId();
    }
  }, [usuario_id]);

  const [cotizaciones, setCotizaciones] = useState({
    coordinadora: { mensaje: '', valor: 0, valorContrapago: 0, comisionInterna: 0 },
    interrapidisimo: { mensaje: '', valor: 0, valorContrapago: 0, comisionInterna: 0 },
    servientrega: { mensaje: '', valor: 0, valorContrapago: 0, comisionInterna: 0 },
    tcc: { mensaje: '', valor: 0, valorContrapago: 0, comisionInterna: 0 }
  });
  const [formValues, setFormValues] = useState({
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    address: '',
    city: '',
    notes: '',
    payment: '',
    product: '',
    quantity: 1,
    salePrice: 0,
    store: '',
    aplicaContrapago: true  // Nuevo campo para AplicaContrapago
  });
  const [producto, setProducto] = useState({
    id_producto: '',
    id_sucursal: '',
    id_categoria: '',
    precio_proveedor: '',
    precio_sugerido: '',
    cantidad_disponible: '',
    created_at: '',
    descripcion_producto: '',
    estado_variacion: '',
    imagenes: [],
    informacion_adicional: '',
    nombre_producto: '',
    peso_producto: '',
    sku: '',
    updated_at: '',
    variaciones: []
  });

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [orderResponse, setOrderResponse] = useState(null);

  const cotizacionData = {
    destino: {
      nombre: "",
      codigo: formValues.city,
    },
    origen: {
      nombre: "",
      codigo: "",
    },
    IdTipoEntrega: 1,
    IdServicio: 1,
    peso: producto.peso_producto,
    largo: 1,
    ancho: 1,
    alto: 1,
    fecha: "28-06-2024",
    AplicaContrapago: formValues.aplicaContrapago,  // Actualizado para usar el valor del formulario
    valorDeclarado: 15000,
  };

  const images = [
    '/Images/coordi.png',
    '/Images/inter.png',
    '/Images/servi.png',
    '/Images/tcc.svg'
  ];

  const envios = [
    'coordinadora',
    'interrapidisimo',
    'servientrega',
    'tcc'
  ];

  const handleChange = value => {
    if (value < 1 || value > producto.cantidad_disponible) {
      message.error(`La cantidad debe estar entre 1 y ${producto.cantidad_disponible}`);
    }
    setInputValue(value);
    form.setFieldsValue({ quantity: value });
  };

  function formatCurrency(value, locale = 'es-CO', currency = 'COP') {
    return new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }).format(value);
  }

  useEffect(() => {
    const fetchProducts = async () => {
      setLoading(true);
      const id = localStorage.getItem('usuario_id');
      const url = `https://api.99envios.app/api/inventarios`;
      try {
        const { data } = await axios.get(url);
        const foundProduct = data.find(prod => prod.id_producto === props.prodID);
        setProducto(foundProduct);
        setCosto(foundProduct['precio_sugerido']);
        setPrecioProveedor(foundProduct['precio_proveedor']);
        form.setFieldsValue({ 
          salePrice: foundProduct['precio_sugerido'], 
          product: foundProduct.nombre_producto,
          notes: foundProduct.descripcion_producto });
      } catch (error) {
        console.error('Error al cargar productos:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  const onValuesChangePedido = (changedValues, allValues) => {
    if (changedValues.salePrice || changedValues.quantity) {
      const newSalePrice = Number(changedValues.salePrice || allValues.salePrice);
      const newQuantity = Number(changedValues.quantity || allValues.quantity);
      const newSubtotal = newSalePrice * newQuantity;
      setSubtotal(newSubtotal);
      setFormValues({ ...allValues, salePrice: newSalePrice, quantity: newQuantity });
      handleAutomaticRequote(newSubtotal, allValues);
    } else {
      setFormValues(allValues);
    }
    calculateTotal(allValues);
  };

  const handleAutomaticRequote = async (newSubtotal, currentFormValues) => {
    setLoading(true);
    try {
      const pesoTotal = producto.peso_producto * Number(currentFormValues.quantity);

      const cotizacionData = {
        destino: {
          nombre: "",
          codigo: currentFormValues.city,
        },
        origen: {
          nombre: "",
          codigo: "",
        },
        IdTipoEntrega: 1,
        IdServicio: 1,
        peso: pesoTotal,
        largo: 1,
        ancho: 1,
        alto: 1,
        fecha: "28-06-2024",
        AplicaContrapago: currentFormValues.aplicaContrapago,
        valorDeclarado: newSubtotal,
      };

      console.log('Datos para la nueva cotización:', cotizacionData);

      const response = await axios.post(
        `https://integration.99envios.app/api/sucursal/cotizar/${idsucursal}`,
        cotizacionData
      );
      console.log('Respuesta de la API (requote):', response.data);
      setCotizaciones(response.data);
    } catch (error) {
      console.error('Error al generar la nueva cotización:', error);
    } finally {
      setLoading(false);
    }
  };
  
  const calculateTotal = (values) => {
    const subtotal = values.salePrice * values.quantity;
    const shippingCost = transportadora; // Este es el costo de envío seleccionado
    const supplierCost = producto.precio_proveedor * values.quantity;
    const total = subtotal - supplierCost - shippingCost;
    return total;
  };

  const handleImageClick = (index) => {
    setSelectedImage(index);
    const cotizacion = cotizaciones[envios[index]];
    const comisionInterna = cotizacion.comision_interna || cotizacion.valor_interna || 0;
    const totalCotizacion = cotizacion.valor + cotizacion.valor_contrapago + comisionInterna + cotizacion.sobreflete;

    setTransportadora(totalCotizacion);
    form.setFieldsValue({ total: calculateTotal({ ...formValues, salePrice: formValues.salePrice, quantity: inputValue }) });
  };

  const handleCotizarClick = async () => {
    setLoading(true);
    try {
      // Mostrar valores antes de la validación
      console.log('Valores antes de la validación:', form.getFieldsValue());

      // Convertir valores a números antes de validar
      form.setFieldsValue({
        salePrice: Number(formValues.salePrice),
        quantity: Number(formValues.quantity),
      });

      // Mostrar valores después de convertir
      console.log('Valores después de la conversión:', form.getFieldsValue());

      // Validar todos los campos antes de cotizar
      await form.validateFields();

      // Calcular el subtotal con valores numéricos
      const subtotal = Number(formValues.salePrice) * Number(formValues.quantity);
      console.log('Subtotal calculado:', subtotal);

      const pesoTotal = producto.peso_producto * Number(formValues.quantity);

      // Actualizar cotizacionData con el valor del subtotal
      const cotizacionData = {
        destino: {
          nombre: "",
          codigo: formValues.city,
        },
        origen: {
          nombre: "",
          codigo: "",
        },
        IdTipoEntrega: 1,
        IdServicio: 1,
        peso: pesoTotal,
        largo: 1,
        ancho: 1,
        alto: 1,
        fecha: "28-06-2024",
        AplicaContrapago: formValues.aplicaContrapago, // Usar el valor del formulario
        valorDeclarado: subtotal, // Usar el valor del subtotal
      };

      // Mostrar datos que se enviarán en la petición
      console.log('Datos para la cotización:', cotizacionData);

      // Intentar la petición POST a la API
      const response = await axios.post(
        `https://integration.99envios.app/api/sucursal/cotizar/${idsucursal}`,
        cotizacionData
      );
      console.log('Respuesta de la API:', response.data);
      setCotizaciones(response.data);
      message.success('Cotización generada con éxito');
    } catch (error) {
      // Mostrar mensaje específico si la validación es la que falla
      if (error.errorFields) {
        message.error('Error de validación: revise los campos del formulario.');
        console.error('Error de validación:', error.errorFields);
        console.log('Campos que fallaron la validación:', error.errorFields);
      } else {
        message.error('Error al generar la cotización');
        console.error('Error al generar la cotización:', error);
      }
    } finally {
      setLoading(false);
    }
  };
  
  const handleConfirmarClick = async () => {
    setLoading(true);
    try {
      const totalGanar = calculateTotal(formValues);
  
      if (totalGanar < 0) {
        message.error('El monto a ganar es negativo. No puedes confirmar y enviar el pedido.');
        return;
      }
  
      await form.validateFields();
  
      const orderData = {
        IdProducto: producto.id_producto,
        IdVariacion: selectedVariation || '',
        Cantidad: formValues.quantity,
        AplicaContrapago: formValues.aplicaContrapago,  // Actualizado para usar el valor del formulario
        valorDeclarado: formValues.salePrice,
        Destinatario: {
          nombre: formValues.firstName,
          primerApellido: formValues.lastName.split(' ')[0] || '',
          segundoApellido: formValues.lastName.split(' ')[1] || '',
          telefono: formValues.phone,
          direccion: formValues.address,
          idLocalidad: formValues.city,
          correo: formValues.email
        },
        Observaciones: formValues.notes,
        transportadora: {
          pais: 'colombia',
          nombre: envios[selectedImage] || ''
        },
        origenCreacion: 1,
      };
  
      const id = localStorage.getItem('usuario_id');
      const token = localStorage.getItem('token'); // Obtener el token del localStorage
  
      // Configurar los encabezados
      const config = {
        headers: {
          'Authorization': token ? `Bearer ${token}` : '',  // Incluir el token si existe
          'Content-Type': 'application/json'
        }
      };
  
      // Realizar la solicitud POST con los datos de la orden y la configuración de encabezados
      const response = await axios.post(`https://99envios.app/api/orden_save/${id}`, orderData, config);
  
      if (response.status === 200) {
        setOrderResponse(response.data);
        setIsModalVisible(true);
        message.success('Pedido confirmado y enviado con éxito');
      } else {
        throw new Error('Error al enviar el pedido');
      }
    } catch (error) {
      message.error('Error al enviar el pedido');
    } finally {
      setLoading(false);
    }
  };
  

  const handleVariationChange = (value) => {
    setSelectedVariation(value);
    const selectedVar = producto.variaciones.find(variation => variation.id === value);
    setInputValue(1);  // Reinicia la cantidad a 1 al cambiar de variación
    form.setFieldsValue({ quantity: 1, salePrice: producto.precio_sugerido });
    message.info(`Seleccionaste la variación con ID: ${value}, Talla: ${selectedVar.talla}, Color: ${selectedVar.color}`);
  };

  const handleModalOk = () => {
    setIsModalVisible(false);
    setOrderResponse(null); // Reseteamos la respuesta al cerrar el modal
  };

  return (
    <Spin spinning={loading}>
    <div className="enviosDrop">
      <div className="formularioEnvio">
        <Form form={form} layout="vertical" onValuesChange={onValuesChangePedido}>
          <Form.Item
            name="firstName"
            label="Nombre(s) destinatario"
            rules={[{ required: true, message: 'Por favor ingrese el nombre del destinatario' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="lastName"
            label="Apellido(s) destinatario"
            rules={[{ required: true, message: 'Por favor ingrese el apellido del destinatario' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="phone"
            label="Teléfono destinatario"
            rules={[{ required: true, message: 'Por favor ingrese el teléfono del destinatario' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="email"
            label="Email destinatario (Opcional)"
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="address"
            label="Dirección destinatario"
            rules={[{ required: true, message: 'Por favor ingrese la dirección del destinatario' }]}
          >
            <Input.TextArea />
          </Form.Item>

          <Form.Item
            name="city"
            label="Ciudad destinatario"
            rules={[{ required: true, message: 'Seleccione la ciudad del destinatario' }]}
          >
            <Select
              showSearch
              placeholder="Seleccione una ciudad"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
            >
              <option value="05001000">MEDELLIN - ANTIOQUIA</option>
              <option value="05001001">PALMITAS - MEDELLIN - ANTIOQUIA</option>
              <option value="05001004">SANTA ELENA - MEDELLIN - ANTIOQUIA</option>
              <option value="05001009">ALTAVISTA - MEDELLIN - ANTIOQUIA</option>
              <option value="05001010">AGUAS FRIAS - MEDELLIN - ANTIOQUIA</option>
              <option value="05001013">SAN JOSE DEL MANZANILLO - MEDELLIN - ANTIOQUIA</option>
              <option value="05001014">BARRO BLANCO - MEDELLIN - ANTIOQUIA</option>
              <option value="05001015">EL CERRO - MEDELLIN - ANTIOQUIA</option>
              <option value="05001017">EL PATIO - MEDELLIN - ANTIOQUIA</option>
              <option value="05001018">EL PLACER - MEDELLIN - ANTIOQUIA</option>
              <option value="05001019">EL PLAN - MEDELLIN - ANTIOQUIA</option>
              <option value="05001022">LA ALDEA - MEDELLIN - ANTIOQUIA</option>
              <option value="05001023">LA CUCHILLA - MEDELLIN - ANTIOQUIA</option>
              <option value="05001025">LA PALMA - MEDELLIN - ANTIOQUIA</option>
              <option value="05001027">LAS PLAYAS - MEDELLIN - ANTIOQUIA</option>
              <option value="05001029">PIEDRA GORDA - MEDELLIN - ANTIOQUIA</option>
              <option value="05001031">POTRERITO - MEDELLIN - ANTIOQUIA</option>
              <option value="05001032">TRAVESIAS - MEDELLIN - ANTIOQUIA</option>
              <option value="05001033">URQUITA - MEDELLIN - ANTIOQUIA</option>
              <option value="05001035">BOQUERON - MEDELLIN - ANTIOQUIA</option>
              <option value="05001039">EL LLANO 1 - MEDELLIN - ANTIOQUIA</option>
              <option value="05001040">EL LLANO - MEDELLIN - ANTIOQUIA</option>
              <option value="05001048">LA VERDE - MEDELLIN - ANTIOQUIA</option>
              <option value="05001052">MATASANO - MEDELLIN - ANTIOQUIA</option>
              <option value="05001054">MATASANO 2 - MEDELLIN - ANTIOQUIA</option>
              <option value="05001055">MAZO - MEDELLIN - ANTIOQUIA</option>
              <option value="05001057">MEDIA LUNA - MEDELLIN - ANTIOQUIA</option>
              <option value="05001066">LAS CAMELIAS - MEDELLIN - ANTIOQUIA</option>
              <option value="05002000">ABEJORRAL - ANTIOQUIA</option>
              <option value="05002002">PANTANILLO - ABEJORRAL - ANTIOQUIA</option>
              <option value="05002004">NARANJAL - LAS FONDAS - ABEJORRAL - ANTIOQUIA</option>
              <option value="05002016">LAS CANOAS - ABEJORRAL - ANTIOQUIA</option>
              <option value="05004000">ABRIAQUI - ANTIOQUIA</option>
              <option value="05004003">POTREROS SECTOR 1 - ABRIAQUI - ANTIOQUIA</option>
              <option value="05021000">ALEJANDRIA - ANTIOQUIA</option>
              <option value="05030000">AMAGA - ANTIOQUIA</option>
              <option value="05030001">CAMILO C - AMAGA - ANTIOQUIA</option>
              <option value="05030003">LA CLARITA - AMAGA - ANTIOQUIA</option>
              <option value="05030004">LA FERREIRA - AMAGA - ANTIOQUIA</option>
              <option value="05030005">LA GUALI - AMAGA - ANTIOQUIA</option>
              <option value="05030006">PIEDECUESTA - AMAGA - ANTIOQUIA</option>
              <option value="05030011">MINAS - AMAGA - ANTIOQUIA</option>
              <option value="05030012">CAMILO C - ALTO DE LA VIRGEN - AMAGA - ANTIOQUIA</option>
              <option value="05030013">PIEDECUESTA - MANI DE LAS CASAS - AMAGA - ANTIOQUIA</option>
              <option value="05031000">AMALFI - ANTIOQUIA</option>
              <option value="05031004">PORTACHUELO - AMALFI - ANTIOQUIA</option>
              <option value="05034000">ANDES - ANTIOQUIA</option>
              <option value="05034001">BUENOS AIRES - ANDES - ANTIOQUIA</option>
              <option value="05034003">SAN JOSE - ANDES - ANTIOQUIA</option>
              <option value="05034006">SANTA RITA - ANDES - ANTIOQUIA</option>
              <option value="05034007">TAPARTO - ANDES - ANTIOQUIA</option>
              <option value="05034012">SANTA INES - ANDES - ANTIOQUIA</option>
              <option value="05034014">SAN BARTOLO - ANDES - ANTIOQUIA</option>
              <option value="05034015">LA CHAPARRALA - LA UNION - ANDES - ANTIOQUIA</option>
              <option value="05036000">ANGELOPOLIS - ANTIOQUIA</option>
              <option value="05036001">LA ESTACION - ANGELOPOLIS - ANTIOQUIA</option>
              <option value="05036006">SANTA RITA - ANGELOPOLIS - ANTIOQUIA</option>
              <option value="05036011">CIENAGUITA - ANGELOPOLIS - ANTIOQUIA</option>
              <option value="05038000">ANGOSTURA - ANTIOQUIA</option>
              <option value="05038008">LLANOS DE CUIBA - ANGOSTURA - ANTIOQUIA</option>
              <option value="05040000">ANORI - ANTIOQUIA</option>
              <option value="05040002">LIBERIA - ANORI - ANTIOQUIA</option>
              <option value="05042000">SANTA FE DE ANTIOQUIA - ANTIOQUIA</option>
              <option value="05042002">LAURELES - SANTA FE DE ANTIOQUIA - ANTIOQUIA</option>
              <option value="05042006">EL PESCADO - SANTA FE DE ANTIOQUIA - ANTIOQUIA</option>
              <option value="05042007">SABANAS - SANTA FE DE ANTIOQUIA - ANTIOQUIA</option>
              <option value="05042008">KILOMETRO 2 - SANTA FE DE ANTIOQUIA - ANTIOQUIA</option>
              <option value="05042009">PASO REAL - SANTA FE DE ANTIOQUIA - ANTIOQUIA</option>
              <option value="05044000">ANZA - ANTIOQUIA</option>
              <option value="05044001">GUINTAR - ANZA - ANTIOQUIA</option>
              <option value="05044005">LA CEJITA - ANZA - ANTIOQUIA</option>
              <option value="05044006">LA HIGUINA - ANZA - ANTIOQUIA</option>
              <option value="05045000">APARTADO - ANTIOQUIA</option>
              <option value="05045001">SAN JOSE DE APARTADO - APARTADO - ANTIOQUIA</option>
              <option value="05045002">CHURIDO - APARTADO - ANTIOQUIA</option>
              <option value="05045003">ZUNGO CARRETERA - APARTADO - ANTIOQUIA</option>
              <option value="05045009">EL REPOSO - APARTADO - ANTIOQUIA</option>
              <option value="05045010">BAJO DEL OSO - APARTADO - ANTIOQUIA</option>
              <option value="05045011">EL SALVADOR - APARTADO - ANTIOQUIA</option>
              <option value="05045014">PUERTO GIRON - APARTADO - ANTIOQUIA</option>
              <option value="05045015">LOMA VERDE - APARTADO - ANTIOQUIA</option>
              <option value="05045016">SAN PABLO - APARTADO - ANTIOQUIA</option>
              <option value="05051000">ARBOLETES - ANTIOQUIA</option>
              <option value="05051001">BUENOS AIRES - ARBOLETES - ANTIOQUIA</option>
              <option value="05051003">EL CARMELO - ARBOLETES - ANTIOQUIA</option>
              <option value="05051005">LAS NARANJITAS - ARBOLETES - ANTIOQUIA</option>
              <option value="05051008">EL YESO - ARBOLETES - ANTIOQUIA</option>
              <option value="05051009">LA TRINIDAD - ARBOLETES - ANTIOQUIA</option>
              <option value="05051010">LAS PLATAS (SANTAFE) - ARBOLETES - ANTIOQUIA</option>
              <option value="05051011">LA CANDELARIA - ARBOLETES - ANTIOQUIA</option>
              <option value="05051014">EL GUADUAL - ARBOLETES - ANTIOQUIA</option>
              <option value="05055000">ARGELIA - ANTIOQUIA</option>
              <option value="05059000">ARMENIA - ANTIOQUIA</option>
              <option value="05059001">LA HERRADURA - ARMENIA - ANTIOQUIA</option>
              <option value="05059003">EL SOCORRO - ARMENIA - ANTIOQUIA</option>
              <option value="05059006">FILO SECO - ARMENIA - ANTIOQUIA</option>
              <option value="05059009">PALMICHAL - ARMENIA - ANTIOQUIA</option>
              <option value="05079000">BARBOSA - ANTIOQUIA</option>
              <option value="05079001">HATILLO - BARBOSA - ANTIOQUIA</option>
              <option value="05079005">PLATANITO - BARBOSA - ANTIOQUIA</option>
              <option value="05079008">ISAZA - BARBOSA - ANTIOQUIA</option>
              <option value="05079014">POPALITO - BARBOSA - ANTIOQUIA</option>
              <option value="05079016">YARUMITO - BARBOSA - ANTIOQUIA</option>
              <option value="05079017">TABLAZO - HATILLO - BARBOSA - ANTIOQUIA</option>
              <option value="05079018">EL PARAISO - BARBOSA - ANTIOQUIA</option>
              <option value="05079019">EL SALADITO - BARBOSA - ANTIOQUIA</option>
              <option value="05079020">LOMITA 1 - BARBOSA - ANTIOQUIA</option>
              <option value="05079021">LOMITA 2 - BARBOSA - ANTIOQUIA</option>
              <option value="05079022">LA PRIMAVERA - BARBOSA - ANTIOQUIA</option>
              <option value="05079023">TAMBORCITO - BARBOSA - ANTIOQUIA</option>
              <option value="05086000">BELMIRA - ANTIOQUIA</option>
              <option value="05086001">LABORES - BELMIRA - ANTIOQUIA</option>
              <option value="05086003">RIO ARRIBA - BELMIRA - ANTIOQUIA</option>
              <option value="05088000">BELLO - ANTIOQUIA</option>
              <option value="05088008">POTRERITO - BELLO - ANTIOQUIA</option>
              <option value="05088013">SAN FELIX - BELLO - ANTIOQUIA</option>
              <option value="05088018">EL PINAR - BELLO - ANTIOQUIA</option>
              <option value="05088020">EL ALBERGUE - BELLO - ANTIOQUIA</option>
              <option value="05088022">LA CHINA - BELLO - ANTIOQUIA</option>
              <option value="05088023">LA UNION - BELLO - ANTIOQUIA</option>
              <option value="05091000">BETANIA - ANTIOQUIA</option>
              <option value="05091003">SAN LUIS - BETANIA - ANTIOQUIA</option>
              <option value="05093000">BETULIA - ANTIOQUIA</option>
              <option value="05093001">ALTAMIRA - BETULIA - ANTIOQUIA</option>
              <option value="05101000">CIUDAD BOLIVAR - ANTIOQUIA</option>
              <option value="05101002">SAN BERNARDO DE LOS FARALLONES - CIUDAD BOLIVAR - ANTIOQUIA</option>
              <option value="05101006">ALFONSO LOPEZ (SAN GREGORIO) - CIUDAD BOLIVAR - ANTIOQUIA</option>
              <option value="05101008">VILLA ALEGRIA - CIUDAD BOLIVAR - ANTIOQUIA</option>
              <option value="05101010">EL CABRERO - BOLIVAR ARRIBA - CIUDAD BOLIVAR - ANTIOQUIA</option>
              <option value="05107000">BRICEÑO - ANTIOQUIA</option>
              <option value="05107001">BERLIN (PUEBLO NUEVO) - BRICEÑO - ANTIOQUIA</option>
              <option value="05107002">EL ROBLAL - BRICEÑO - ANTIOQUIA</option>
              <option value="05107003">LAS AURAS - BRICEÑO - ANTIOQUIA</option>
              <option value="05107004">TRAVESIAS - BRICEÑO - ANTIOQUIA</option>
              <option value="05113000">BURITICA - ANTIOQUIA</option>
              <option value="05113001">EL NARANJO - BURITICA - ANTIOQUIA</option>
              <option value="05113002">GUARCO - BURITICA - ANTIOQUIA</option>
              <option value="05113003">TABACAL - BURITICA - ANTIOQUIA</option>
              <option value="05113004">LLANOS DE URARCO - BURITICA - ANTIOQUIA</option>
              <option value="05113005">LA ANGELINA - BURITICA - ANTIOQUIA</option>
              <option value="05113008">LA MARIELA - BURITICA - ANTIOQUIA</option>
              <option value="05120000">CACERES - ANTIOQUIA</option>
              <option value="05120002">EL JARDIN (TAMANA) - CACERES - ANTIOQUIA</option>
              <option value="05120003">GUARUMO - CACERES - ANTIOQUIA</option>
              <option value="05120004">MANIZALES - CACERES - ANTIOQUIA</option>
              <option value="05120006">PUERTO BELGICA - CACERES - ANTIOQUIA</option>
              <option value="05120010">PIAMONTE - CACERES - ANTIOQUIA</option>
              <option value="05120012">RIO MAN - CACERES - ANTIOQUIA</option>
              <option value="05120013">LAS PAMPAS - CACERES - ANTIOQUIA</option>
              <option value="05120014">NICARAGUA - CACERES - ANTIOQUIA</option>
              <option value="05120015">PUERTO SANTO - CACERES - ANTIOQUIA</option>
              <option value="05125000">CAICEDO - ANTIOQUIA</option>
              <option value="05129000">CALDAS - ANTIOQUIA</option>
              <option value="05129001">EL CAÑO - CALDAS - ANTIOQUIA</option>
              <option value="05129002">LA RAYA - CALDAS - ANTIOQUIA</option>
              <option value="05129004">LA MIEL - CALDAS - ANTIOQUIA</option>
              <option value="05129005">LA CORRALITA - CALDAS - ANTIOQUIA</option>
              <option value="05129006">LA PRIMAVERA SANTA CRUZ - CALDAS - ANTIOQUIA</option>
              <option value="05129007">EL RAIZAL - CALDAS - ANTIOQUIA</option>
              <option value="05129008">LA CLARA - CALDAS - ANTIOQUIA</option>
              <option value="05129009">LA QUIEBRA - CALDAS - ANTIOQUIA</option>
              <option value="05129010">LA SALADA PARTE BAJA - CALDAS - ANTIOQUIA</option>
              <option value="05129011">LA TOLVA - CALDAS - ANTIOQUIA</option>
              <option value="05129012">LA VALERIA - CALDAS - ANTIOQUIA</option>
              <option value="05129013">LA AGUACATALA - CALDAS - ANTIOQUIA</option>
              <option value="05129015">LA CHUSCALA - CALDAS - ANTIOQUIA</option>
              <option value="05129016">SALINAS - CALDAS - ANTIOQUIA</option>
              <option value="05134000">CAMPAMENTO - ANTIOQUIA</option>
              <option value="05134001">LA CHIQUITA - CAMPAMENTO - ANTIOQUIA</option>
              <option value="05134002">LA SOLITA - CAMPAMENTO - ANTIOQUIA</option>
              <option value="05134003">LLANADAS - CAMPAMENTO - ANTIOQUIA</option>
              <option value="05138000">CAÑASGORDAS - ANTIOQUIA</option>
              <option value="05138001">BUENOS AIRES - PARTE ALTA - CAÑASGORDAS - ANTIOQUIA</option>
              <option value="05138002">CESTILLAL - CAÑASGORDAS - ANTIOQUIA</option>
              <option value="05138003">JUNTAS DE URAMITA - CAÑASGORDAS - ANTIOQUIA</option>
              <option value="05138005">SAN PASCUAL - CAÑASGORDAS - ANTIOQUIA</option>
              <option value="05138009">VILLA VICTORIA - CAÑASGORDAS - ANTIOQUIA</option>
              <option value="05142000">CARACOLI - ANTIOQUIA</option>
              <option value="05145000">CARAMANTA - ANTIOQUIA</option>
              <option value="05145001">ALEGRIAS - CARAMANTA - ANTIOQUIA</option>
              <option value="05145002">SUCRE - CARAMANTA - ANTIOQUIA</option>
              <option value="05147000">CAREPA - ANTIOQUIA</option>
              <option value="05147003">PIEDRAS BLANCAS - CAREPA - ANTIOQUIA</option>
              <option value="05147004">ZUNGO EMBARCADERO - PUEBLO NUEVO - CAREPA - ANTIOQUIA</option>
              <option value="05147005">ZUNGO EMBARCADERO - 11 DE NOVIEMBRE - CAREPA - ANTIOQUIA</option>
              <option value="05147006">CASA VERDE - CAREPA - ANTIOQUIA</option>
              <option value="05147007">EL ENCANTO - CAREPA - ANTIOQUIA</option>
              <option value="05147008">ZUNGO EMBARCADERO - 28 DE OCTUBRE - CAREPA - ANTIOQUIA</option>
              <option value="05147009">BELENCITO - CAREPA - ANTIOQUIA</option>
              <option value="05147010">BOSQUES DE LOS ALMENDROS - CAREPA - ANTIOQUIA</option>
              <option value="05147011">CAREPITA CANALUNO - CAREPA - ANTIOQUIA</option>
              <option value="05147012">SACRAMENTO LA LUCHA - CAREPA - ANTIOQUIA</option>
              <option value="05147013">LOS NARANJALES - CAREPA - ANTIOQUIA</option>
              <option value="05147014">VIJAGUAL - CAREPA - ANTIOQUIA</option>
              <option value="05148000">EL CARMEN DE VIBORAL - ANTIOQUIA</option>
              <option value="05148003">AGUAS CLARAS - EL CARMEN DE VIBORAL - ANTIOQUIA</option>
              <option value="05148005">LA CHAPA - EL CARMEN DE VIBORAL - ANTIOQUIA</option>
              <option value="05148006">CAMPO ALEGRE - EL CARMEN DE VIBORAL - ANTIOQUIA</option>
              <option value="05148008">LA AURORA - LAS BRISAS - EL CARMEN DE VIBORAL - ANTIOQUIA</option>
              <option value="05150000">CAROLINA DEL PRINCIPE - CAROLINA - ANTIOQUIA</option>
              <option value="05154000">CAUCASIA - ANTIOQUIA</option>
              <option value="05154003">CUTURU - CAUCASIA - ANTIOQUIA</option>
              <option value="05154006">MARGENTO - CAUCASIA - ANTIOQUIA</option>
              <option value="05154008">PALANCA - CAUCASIA - ANTIOQUIA</option>
              <option value="05154009">PALOMAR - CAUCASIA - ANTIOQUIA</option>
              <option value="05154012">SANTA ROSITA - CAUCASIA - ANTIOQUIA</option>
              <option value="05154020">PUERTO TRIANA - CAUCASIA - ANTIOQUIA</option>
              <option value="05154022">LA ILUSION - CAUCASIA - ANTIOQUIA</option>
              <option value="05154023">CACERI - CAUCASIA - ANTIOQUIA</option>
              <option value="05154024">EL PANDO - CAUCASIA - ANTIOQUIA</option>
              <option value="05154026">EL CHINO - CAUCASIA - ANTIOQUIA</option>
              <option value="05154027">LA ESMERALDA - CAUCASIA - ANTIOQUIA</option>
              <option value="05154031">VILLA DEL SOCORRO - CAUCASIA - ANTIOQUIA</option>
              <option value="05154032">CASERIO CONJUNTO CAÑA FISTULA - CAUCASIA - ANTIOQUIA</option>
              <option value="05154033">PUEBLO NUEVO - CAUCASIA - ANTIOQUIA</option>
              <option value="05172000">CHIGORODO - ANTIOQUIA</option>
              <option value="05172003">BARRANQUILLITA - CHIGORODO - ANTIOQUIA</option>
              <option value="05172007">GUAPA CARRETERAS - CHIGORODO - ANTIOQUIA</option>
              <option value="05172013">JURADO - CHIGORODO - ANTIOQUIA</option>
              <option value="05172014">CAMPITAS - CHIGORODO - ANTIOQUIA</option>
              <option value="05172015">CHAMPITA SECTOR LA GRANJA - CHIGORODO - ANTIOQUIA</option>
              <option value="05190000">CISNEROS - ANTIOQUIA</option>
              <option value="05197000">COCORNA - ANTIOQUIA</option>
              <option value="05197005">LA PIÑUELA - COCORNA - ANTIOQUIA</option>
              <option value="05197013">EL MOLINO - COCORNA - ANTIOQUIA</option>
              <option value="05206000">CONCEPCION - ANTIOQUIA</option>
              <option value="05209000">CONCORDIA - ANTIOQUIA</option>
              <option value="05209001">EL SOCORRO - CONCORDIA - ANTIOQUIA</option>
              <option value="05209006">EL GOLPE - CONCORDIA - ANTIOQUIA</option>
              <option value="05209007">SALAZAR - CONCORDIA - ANTIOQUIA</option>
              <option value="05212000">COPACABANA - ANTIOQUIA</option>
              <option value="05212005">EL SALADO - COPACABANA - ANTIOQUIA</option>
              <option value="05212008">CABUYAL - COPACABANA - ANTIOQUIA</option>
              <option value="05212017">EL LLANO - COPACABANA - ANTIOQUIA</option>
              <option value="05234000">DABEIBA - ANTIOQUIA</option>
              <option value="05234004">SAN JOSE DE URAMA - DABEIBA - ANTIOQUIA</option>
              <option value="05234008">ARMENIA - CAMPARRUSIA - DABEIBA - ANTIOQUIA</option>
              <option value="05234010">LAS CRUCES DE URAMA - DABEIBA - ANTIOQUIA</option>
              <option value="05234012">CHIMIADO LLANO GRANDE - DABEIBA - ANTIOQUIA</option>
              <option value="05234014">EL BOTON - DABEIBA - ANTIOQUIA</option>
              <option value="05234017">BETANIA PUENTE NUEVO - DABEIBA - ANTIOQUIA</option>
              <option value="05234018">CARA COLON - DABEIBA - ANTIOQUIA</option>
              <option value="05234019">LA BALSITA - DABEIBA - ANTIOQUIA</option>
              <option value="05237000">DONMATIAS - ANTIOQUIA</option>
              <option value="05237001">BELLAVISTA - DONMATIAS - ANTIOQUIA</option>
              <option value="05237003">ARENALES - DONMATIAS - ANTIOQUIA</option>
              <option value="05237004">MONTERA - DONMATIAS - ANTIOQUIA</option>
              <option value="05237005">PRADERA - DONMATIAS - ANTIOQUIA</option>
              <option value="05240000">EBEJICO - ANTIOQUIA</option>
              <option value="05240001">BRASIL - EBEJICO - ANTIOQUIA</option>
              <option value="05240003">SEVILLA - EBEJICO - ANTIOQUIA</option>
              <option value="05240008">EL ZARZAL - EBEJICO - ANTIOQUIA</option>
              <option value="05240011">LA CLARA - EBEJICO - ANTIOQUIA</option>
              <option value="05240012">FATIMA - EBEJICO - ANTIOQUIA</option>
              <option value="05250000">EL BAGRE - ANTIOQUIA</option>
              <option value="05250002">PUERTO CLAVER - EL BAGRE - ANTIOQUIA</option>
              <option value="05250004">PUERTO LOPEZ - EL BAGRE - ANTIOQUIA</option>
              <option value="05250005">EL REAL - EL BAGRE - ANTIOQUIA</option>
              <option value="05250006">LA CORONA - EL BAGRE - ANTIOQUIA</option>
              <option value="05250007">LAS NEGRITAS - EL BAGRE - ANTIOQUIA</option>
              <option value="05250008">LAS SARDINAS EL PUENTE - EL BAGRE - ANTIOQUIA</option>
              <option value="05250009">SANTA BARBARA - EL BAGRE - ANTIOQUIA</option>
              <option value="05250010">MUQUI - EL BAGRE - ANTIOQUIA</option>
              <option value="05250011">BORRACHERA - EL BAGRE - ANTIOQUIA</option>
              <option value="05250012">CAÑO CLARO - EL BAGRE - ANTIOQUIA</option>
              <option value="05250013">LOS ALMENDROS - EL BAGRE - ANTIOQUIA</option>
              <option value="05264000">ENTRERRIOS - ANTIOQUIA</option>
              <option value="05266000">ENVIGADO - ANTIOQUIA</option>
              <option value="05266001">LAS PALMAS - ENVIGADO - ANTIOQUIA</option>
              <option value="05266005">EL CRISTO - ENVIGADO - ANTIOQUIA</option>
              <option value="05266006">EL CHINGUI 2 - ENVIGADO - ANTIOQUIA</option>
              <option value="05266007">LA ULTIMA COPA - ENVIGADO - ANTIOQUIA</option>
              <option value="05266008">PARCELACION LA ACUARELA - ENVIGADO - ANTIOQUIA</option>
              <option value="05266009">PARCELACION ALAMOS DEL ESCOBERO - ENVIGADO - ANTIOQUIA</option>
              <option value="05266010">PARCELACION ALDEA DE PALMA VERDE - ENVIGADO - ANTIOQUIA</option>
              <option value="05266011">PARCELACION CONDOMINIO CAMPESTRE SERRANIA - ENVIGADO - ANTIOQUIA</option>
              <option value="05266012">PARCELACION CASAS BELLO MONTE - ENVIGADO - ANTIOQUIA</option>
              <option value="05266013">PARCELACION FIORE CASAS DE CAMPO - ENVIGADO - ANTIOQUIA</option>
              <option value="05266014">PARCELACION CONJUNTO RESIDENCIAL BELLA TIERRA - ENVIGADO - ANTIOQUIA</option>
              <option value="05266015">PARCELACION ENCENILLOS - ENVIGADO - ANTIOQUIA</option>
              <option value="05266016">PARCELACION ESCOBERO - ENVIGADO - ANTIOQUIA</option>
              <option value="05266017">PARCELACION HACIENDA ARRAYANES - ENVIGADO - ANTIOQUIA</option>
              <option value="05266018">PARCELACION LAS PALMITAS - ENVIGADO - ANTIOQUIA</option>
              <option value="05266019">PARCELACION LEMONT - ENVIGADO - ANTIOQUIA</option>
              <option value="05266020">PARCELACION PRADO LARGO - ENVIGADO - ANTIOQUIA</option>
              <option value="05266021">PARCELACION SAN SEBASTIAN - ENVIGADO - ANTIOQUIA</option>
              <option value="05266023">PARCELACION URBANIZACION PAPIROS - ENVIGADO - ANTIOQUIA</option>
              <option value="05266024">PARCELACION VERANDA - ENVIGADO - ANTIOQUIA</option>
              <option value="05266025">PARCELACION VILLAS DE LA CANDELARIA - ENVIGADO - ANTIOQUIA</option>
              <option value="05282000">FREDONIA - ANTIOQUIA</option>
              <option value="05282002">LOS PALOMOS - FREDONIA - ANTIOQUIA</option>
              <option value="05282003">MINAS - FREDONIA - ANTIOQUIA</option>
              <option value="05282004">PUENTE IGLESIAS - FREDONIA - ANTIOQUIA</option>
              <option value="05282005">MARSELLA - FREDONIA - ANTIOQUIA</option>
              <option value="05282008">EL ZANCUDO - FREDONIA - ANTIOQUIA</option>
              <option value="05284000">FRONTINO - ANTIOQUIA</option>
              <option value="05284001">CARAUTA - FRONTINO - ANTIOQUIA</option>
              <option value="05284004">MURRI - LA BLANQUITA - FRONTINO - ANTIOQUIA</option>
              <option value="05284005">MUSINGA - TABLADITO - FRONTINO - ANTIOQUIA</option>
              <option value="05284006">NUTIBARA - FRONTINO - ANTIOQUIA</option>
              <option value="05284007">PONTON - FRONTINO - ANTIOQUIA</option>
              <option value="05284010">SAN LAZARO - FRONTINO - ANTIOQUIA</option>
              <option value="05306000">GIRALDO - ANTIOQUIA</option>
              <option value="05306001">MANGLAR - GIRALDO - ANTIOQUIA</option>
              <option value="05308000">GIRARDOTA - ANTIOQUIA</option>
              <option value="05308002">SAN ANDRES - GIRARDOTA - ANTIOQUIA</option>
              <option value="05308004">LA PALMA - GIRARDOTA - ANTIOQUIA</option>
              <option value="05308009">CABILDO - GIRARDOTA - ANTIOQUIA</option>
              <option value="05308010">LAS CUCHILLAS - GIRARDOTA - ANTIOQUIA</option>
              <option value="05308011">JAMUNDI - ESCUELAS - GIRARDOTA - ANTIOQUIA</option>
              <option value="05308012">JUAN COJO - GIRARDOTA - ANTIOQUIA</option>
              <option value="05308013">LA CALLE - GIRARDOTA - ANTIOQUIA</option>
              <option value="05308014">SAN ESTEBAN - GIRARDOTA - ANTIOQUIA</option>
              <option value="05308015">EL PARAISO - GIRARDOTA - ANTIOQUIA</option>
              <option value="05308016">JAMUNDI - RIELES - GIRARDOTA - ANTIOQUIA</option>
              <option value="05308017">LOMA DE LOS OCHOA - GIRARDOTA - ANTIOQUIA</option>
              <option value="05310000">GOMEZ PLATA - ANTIOQUIA</option>
              <option value="05310001">EL SALTO - GOMEZ PLATA - ANTIOQUIA</option>
              <option value="05310002">SAN MATIAS - GOMEZ PLATA - ANTIOQUIA</option>
              <option value="05310004">VEGA DE BOTERO - GOMEZ PLATA - ANTIOQUIA</option>
              <option value="05313000">GRANADA - ANTIOQUIA</option>
              <option value="05313001">SANTA ANA - GRANADA - ANTIOQUIA</option>
              <option value="05313004">LOS MEDIOS - GRANADA - ANTIOQUIA</option>
              <option value="05315000">GUADALUPE - ANTIOQUIA</option>
              <option value="05315002">GUANTEROS - GUADALUPE - ANTIOQUIA</option>
              <option value="05315007">GUADALUPE IV - GUADALUPE - ANTIOQUIA</option>
              <option value="05315008">BARRIO NUEVO - GUADALUPE - ANTIOQUIA</option>
              <option value="05315009">EL MACHETE - GUADALUPE - ANTIOQUIA</option>
              <option value="05318000">GUARNE - ANTIOQUIA</option>
              <option value="05318006">CHAPARRAL - GUARNE - ANTIOQUIA</option>
              <option value="05318007">SAN IGNACIO - GUARNE - ANTIOQUIA</option>
              <option value="05321000">GUATAPE - ANTIOQUIA</option>
              <option value="05321001">EL ROBLE - GUATAPE - ANTIOQUIA</option>
              <option value="05347000">HELICONIA - ANTIOQUIA</option>
              <option value="05347001">ALTO DEL CORRAL - HELICONIA - ANTIOQUIA</option>
              <option value="05347002">PUEBLITO - HELICONIA - ANTIOQUIA</option>
              <option value="05347003">LLANOS DE SAN JOSE - HELICONIA - ANTIOQUIA</option>
              <option value="05347009">GUAMAL - HELICONIA - ANTIOQUIA</option>
              <option value="05353000">HISPANIA - ANTIOQUIA</option>
              <option value="05360000">ITAGÜI - ANTIOQUIA</option>
              <option value="05360001">LOS GOMEZ - ITAGÜI - ANTIOQUIA</option>
              <option value="05360003">EL AJIZAL - ITAGÜI - ANTIOQUIA</option>
              <option value="05360006">EL PEDREGAL - ITAGÜI - ANTIOQUIA</option>
              <option value="05360012">EL PORVENIR - ITAGÜI - ANTIOQUIA</option>
              <option value="05360013">EL PROGRESO - ITAGÜI - ANTIOQUIA</option>
              <option value="05360014">LA MARIA - ITAGÜI - ANTIOQUIA</option>
              <option value="05361000">ITUANGO - ANTIOQUIA</option>
              <option value="05361002">EL ARO - BUILOPOLIS - ITUANGO - ANTIOQUIA</option>
              <option value="05361003">LA GRANJA - ITUANGO - ANTIOQUIA</option>
              <option value="05361004">PASCUITA - ITUANGO - ANTIOQUIA</option>
              <option value="05361005">SANTA ANA - ITUANGO - ANTIOQUIA</option>
              <option value="05361006">SANTA LUCIA - ITUANGO - ANTIOQUIA</option>
              <option value="05361007">SANTA RITA - ITUANGO - ANTIOQUIA</option>
              <option value="05361018">PIO X - ITUANGO - ANTIOQUIA</option>
              <option value="05364000">JARDIN - ANTIOQUIA</option>
              <option value="05364001">CRISTIANIA - JARDIN - ANTIOQUIA</option>
              <option value="05364002">LA ARBOLEDA - LAS MACANAS - JARDIN - ANTIOQUIA</option>
              <option value="05364006">QUEBRADA BONITA - JARDIN - ANTIOQUIA</option>
              <option value="05368000">JERICO - ANTIOQUIA</option>
              <option value="05368005">GUACAMAYAL - JERICO - ANTIOQUIA</option>
              <option value="05368007">LOS PATIOS - JERICO - ANTIOQUIA</option>
              <option value="05376000">LA CEJA - ANTIOQUIA</option>
              <option value="05376002">EL TAMBO - LA CEJA - ANTIOQUIA</option>
              <option value="05376003">SAN JOSE - LA CEJA - ANTIOQUIA</option>
              <option value="05376005">SAN NICOLAS - LA CEJA - ANTIOQUIA</option>
              <option value="05376006">SAN JUDAS - LA CEJA - ANTIOQUIA</option>
              <option value="05376008">TOLEDO - LA CEJA - ANTIOQUIA</option>
              <option value="05380000">LA ESTRELLA - ANTIOQUIA</option>
              <option value="05380007">LA TABLACITA - LA ESTRELLA - ANTIOQUIA</option>
              <option value="05380008">SAGRADA FAMILIA - LA ESTRELLA - ANTIOQUIA</option>
              <option value="05380009">SAN JOSE - MELEGUINDO - LA ESTRELLA - ANTIOQUIA</option>
              <option value="05380010">LA RAYA - LA ESTRELLA - ANTIOQUIA</option>
              <option value="05380011">SAN ISIDRO - LA ESTRELLA - ANTIOQUIA</option>
              <option value="05380012">TARAPACA - LA ESTRELLA - ANTIOQUIA</option>
              <option value="05380013">SAN MIGUEL - LA ESTRELLA - ANTIOQUIA</option>
              <option value="05380019">LA BERMEJALA - LA ESTRELLA - ANTIOQUIA</option>
              <option value="05380020">PAN DE AZUCAR - LA ESTRELLA - ANTIOQUIA</option>
              <option value="05380021">PEÑAS BLANCAS - LA ESTRELLA - ANTIOQUIA</option>
              <option value="05390000">LA PINTADA - ANTIOQUIA</option>
              <option value="05390001">LA BOCANA - LA PINTADA - ANTIOQUIA</option>
              <option value="05400000">LA UNION - ANTIOQUIA</option>
              <option value="05400001">MESOPOTAMIA - LA UNION - ANTIOQUIA</option>
              <option value="05400004">LA CONCHA - LA UNION - ANTIOQUIA</option>
              <option value="05411000">LIBORINA - ANTIOQUIA</option>
              <option value="05411001">EL CARMEN - LA VENTA - LIBORINA - ANTIOQUIA</option>
              <option value="05411002">LA HONDA - LIBORINA - ANTIOQUIA</option>
              <option value="05411003">LA MERCED (PLAYON) - LIBORINA - ANTIOQUIA</option>
              <option value="05411004">SAN DIEGO (PLACITA) - LIBORINA - ANTIOQUIA</option>
              <option value="05411005">CURITI - LIBORINA - ANTIOQUIA</option>
              <option value="05411007">CRISTOBAL - LIBORINA - ANTIOQUIA</option>
              <option value="05411012">PORVENIR - LIBORINA - ANTIOQUIA</option>
              <option value="05411013">PROVINCIAL - LIBORINA - ANTIOQUIA</option>
              <option value="05425000">MACEO - ANTIOQUIA</option>
              <option value="05425001">LA SUSANA - MACEO - ANTIOQUIA</option>
              <option value="05425003">LA FLORESTA - MACEO - ANTIOQUIA</option>
              <option value="05425005">SAN JOSE DEL NUS (JOSE DE NUESTRA SEÑORA) - MACEO - ANTIOQUIA</option>
              <option value="05440000">MARINILLA - ANTIOQUIA</option>
              <option value="05467000">MONTEBELLO - ANTIOQUIA</option>
              <option value="05467001">SABALETAS - MONTEBELLO - ANTIOQUIA</option>
              <option value="05467006">LA GRANJA - MONTEBELLO - ANTIOQUIA</option>
              <option value="05467008">PIEDRA GALANA - MONTEBELLO - ANTIOQUIA</option>
              <option value="05475000">MURINDO - ANTIOQUIA</option>
              <option value="05475001">OPOGADO - MURINDO - ANTIOQUIA</option>
              <option value="05475005">JEDEGA - MURINDO - ANTIOQUIA</option>
              <option value="05475006">TADIA - MURINDO - ANTIOQUIA</option>
              <option value="05475007">BEBARAMEÑO - MURINDO - ANTIOQUIA</option>
              <option value="05480000">MUTATA - ANTIOQUIA</option>
              <option value="05480001">BEJUQUILLO - MUTATA - ANTIOQUIA</option>
              <option value="05480002">PAVARANDOCITO - MUTATA - ANTIOQUIA</option>
              <option value="05480003">VILLA ARTEAGA - MUTATA - ANTIOQUIA</option>
              <option value="05480004">PAVARANDO GRANDE - MUTATA - ANTIOQUIA</option>
              <option value="05480006">CAUCHERAS - MUTATA - ANTIOQUIA</option>
              <option value="05483000">NARIÑO - ANTIOQUIA</option>
              <option value="05483001">PUERTO VENUS - NARIÑO - ANTIOQUIA</option>
              <option value="05490000">NECOCLI - ANTIOQUIA</option>
              <option value="05490001">EL TOTUMO - NECOCLI - ANTIOQUIA</option>
              <option value="05490002">MULATOS - NECOCLI - ANTIOQUIA</option>
              <option value="05490003">PUEBLO NUEVO - NECOCLI - ANTIOQUIA</option>
              <option value="05490004">ZAPATA - NECOCLI - ANTIOQUIA</option>
              <option value="05490005">CARIBIA - NECOCLI - ANTIOQUIA</option>
              <option value="05490006">VEREDA CASA BLANCA - NECOCLI - ANTIOQUIA</option>
              <option value="05490007">VEREDA EL BOBAL - NECOCLI - ANTIOQUIA</option>
              <option value="05490008">LAS CHANGAS - NECOCLI - ANTIOQUIA</option>
              <option value="05490009">EL MELLITO - NECOCLI - ANTIOQUIA</option>
              <option value="05490010">BRISAS DEL RIO - NECOCLI - ANTIOQUIA</option>
              <option value="05490011">CARLOS ARRIBA - NECOCLI - ANTIOQUIA</option>
              <option value="05490012">EL VOLAO - NECOCLI - ANTIOQUIA</option>
              <option value="05490013">LA COMARCA - NECOCLI - ANTIOQUIA</option>
              <option value="05490014">LOMA DE PIEDRA - NECOCLI - ANTIOQUIA</option>
              <option value="05490015">MELLO VILLAVICENCIO - NECOCLI - ANTIOQUIA</option>
              <option value="05490016">TULAPITA - NECOCLI - ANTIOQUIA</option>
              <option value="05490018">VALE PAVA - NECOCLI - ANTIOQUIA</option>
              <option value="05495000">NECHI - ANTIOQUIA</option>
              <option value="05495001">BIJAGUAL - NECHI - ANTIOQUIA</option>
              <option value="05495002">COLORADO - NECHI - ANTIOQUIA</option>
              <option value="05495003">LA CONCHA - NECHI - ANTIOQUIA</option>
              <option value="05495004">LAS FLORES - NECHI - ANTIOQUIA</option>
              <option value="05495005">CARGUEROS - NECHI - ANTIOQUIA</option>
              <option value="05501000">OLAYA - ANTIOQUIA</option>
              <option value="05501001">LLANADAS - OLAYA - ANTIOQUIA</option>
              <option value="05501002">SUCRE - OLAYA - ANTIOQUIA</option>
              <option value="05501004">QUEBRADA SECA - OLAYA - ANTIOQUIA</option>
              <option value="05541000">PEÑOL - ANTIOQUIA</option>
              <option value="05543000">PEQUE - ANTIOQUIA</option>
              <option value="05543003">LOS LLANOS - PEQUE - ANTIOQUIA</option>
              <option value="05576000">PUEBLORRICO - ANTIOQUIA</option>
              <option value="05579000">PUERTO BERRIO - ANTIOQUIA</option>
              <option value="05579001">PUERTO MURILLO - PUERTO BERRIO - ANTIOQUIA</option>
              <option value="05579002">VIRGINIAS - PUERTO BERRIO - ANTIOQUIA</option>
              <option value="05579003">CABAÑAS - PUERTO BERRIO - ANTIOQUIA</option>
              <option value="05579004">EL BRASIL - PUERTO BERRIO - ANTIOQUIA</option>
              <option value="05579005">LA CRISTALINA - PUERTO BERRIO - ANTIOQUIA</option>
              <option value="05579009">MALENA - PUERTO BERRIO - ANTIOQUIA</option>
              <option value="05579010">CALERA - PUERTO BERRIO - ANTIOQUIA</option>
              <option value="05579011">BODEGAS - PUERTO BERRIO - ANTIOQUIA</option>
              <option value="05579012">DORADO - CALAMAR - PUERTO BERRIO - ANTIOQUIA</option>
              <option value="05579013">LA CARLOTA - PUERTO BERRIO - ANTIOQUIA</option>
              <option value="05579014">MINAS DEL VAPOR - PUERTO BERRIO - ANTIOQUIA</option>
              <option value="05579015">SANTA MARTINA - PUERTO BERRIO - ANTIOQUIA</option>
              <option value="05585000">PUERTO NARE - ANTIOQUIA</option>
              <option value="05585001">ARABIA - PUERTO NARE - ANTIOQUIA</option>
              <option value="05585002">LOS DELIRIOS - PUERTO NARE - ANTIOQUIA</option>
              <option value="05585003">LA SIERRA - PUERTO NARE - ANTIOQUIA</option>
              <option value="05585004">LA UNION - PUERTO NARE - ANTIOQUIA</option>
              <option value="05585006">LA PESCA - PUERTO NARE - ANTIOQUIA</option>
              <option value="05585008">LAS ANGELITAS - PUERTO NARE - ANTIOQUIA</option>
              <option value="05585009">LA CLARA - PUERTO NARE - ANTIOQUIA</option>
              <option value="05585010">EL PRODIGIO - PUERTO NARE - ANTIOQUIA</option>
              <option value="05591000">PUERTO TRIUNFO - ANTIOQUIA</option>
              <option value="05591002">PUERTO PERALES NUEVO - PUERTO TRIUNFO - ANTIOQUIA</option>
              <option value="05591003">ESTACION COCORNA - PUERTO TRIUNFO - ANTIOQUIA</option>
              <option value="05591004">DORADAL - PUERTO TRIUNFO - ANTIOQUIA</option>
              <option value="05591005">LA MERCEDES - PUERTO TRIUNFO - ANTIOQUIA</option>
              <option value="05591007">ESTACION PITA - PUERTO TRIUNFO - ANTIOQUIA</option>
              <option value="05591008">LA FLORIDA - PUERTO TRIUNFO - ANTIOQUIA</option>
              <option value="05591009">SANTIAGO BERRIO - PUERTO TRIUNFO - ANTIOQUIA</option>
              <option value="05591011">TRES RANCHOS - PUERTO TRIUNFO - ANTIOQUIA</option>
              <option value="05604000">REMEDIOS - ANTIOQUIA</option>
              <option value="05604001">LA CRUZADA - REMEDIOS - ANTIOQUIA</option>
              <option value="05604003">SANTA ISABEL - REMEDIOS - ANTIOQUIA</option>
              <option value="05604005">OTU - REMEDIOS - ANTIOQUIA</option>
              <option value="05604007">CAÑAVERAL - REMEDIOS - ANTIOQUIA</option>
              <option value="05604008">MARTANA - REMEDIOS - ANTIOQUIA</option>
              <option value="05604009">RIO BAGRE - REMEDIOS - ANTIOQUIA</option>
              <option value="05604010">CAMPO VIJAO - REMEDIOS - ANTIOQUIA</option>
              <option value="05607000">RETIRO - ANTIOQUIA</option>
              <option value="05607003">ALTO DE CARRIZALES - RETIRO - ANTIOQUIA</option>
              <option value="05607004">DON DIEGO - RETIRO - ANTIOQUIA</option>
              <option value="05607005">EL CHUSCAL LA CAMPANITA - RETIRO - ANTIOQUIA</option>
              <option value="05607007">LOS SALADOS - RETIRO - ANTIOQUIA</option>
              <option value="05607009">EL PORTENTO - RETIRO - ANTIOQUIA</option>
              <option value="05607013">CARRIZALES LA BORRASCOSA - RETIRO - ANTIOQUIA</option>
              <option value="05615000">RIONEGRO - ANTIOQUIA</option>
              <option value="05615002">EL TABLAZO - RIONEGRO - ANTIOQUIA</option>
              <option value="05615009">CABECERAS DE LLANO GRANDE - RIONEGRO - ANTIOQUIA</option>
              <option value="05615013">PONTEZUELA - RIONEGRO - ANTIOQUIA</option>
              <option value="05615014">ALTO BONITO - RIONEGRO - ANTIOQUIA</option>
              <option value="05615017">LA MOSCA - RIONEGRO - ANTIOQUIA</option>
              <option value="05615025">BARRO BLANCO - RIONEGRO - ANTIOQUIA</option>
              <option value="05615026">CONDOMINIO CAMPESTRE LAGO GRANDE - RIONEGRO - ANTIOQUIA</option>
              <option value="05615027">CONDOMINIO EL REMANSO - RIONEGRO - ANTIOQUIA</option>
              <option value="05615028">CONDOMINIO SIERRAS DE MAYORI - RIONEGRO - ANTIOQUIA</option>
              <option value="05615029">CONDOMINIO VILLAS DE LLANO GRANDE - RIONEGRO - ANTIOQUIA</option>
              <option value="05615030">GALICIA PARTE ALTA - RIONEGRO - ANTIOQUIA</option>
              <option value="05615031">GALICIA PARTE BAJA - RIONEGRO - ANTIOQUIA</option>
              <option value="05615032">JAMAICA PARCELACION CAMPESTRE - RIONEGRO - ANTIOQUIA</option>
              <option value="05615033">PARCELACION AGUA LUNA DE ORIENTE - RIONEGRO - ANTIOQUIA</option>
              <option value="05615034">PARCELACION ANDALUCIA - RIONEGRO - ANTIOQUIA</option>
              <option value="05615035">PARCELACION CAMELOT - RIONEGRO - ANTIOQUIA</option>
              <option value="05615036">PARCELACION COCUYO - RIONEGRO - ANTIOQUIA</option>
              <option value="05615037">PARCELACION COLINAS DE PAIMADO - RIONEGRO - ANTIOQUIA</option>
              <option value="05615039">PARCELACION LA QUERENCIA - RIONEGRO - ANTIOQUIA</option>
              <option value="05615043">PARCELACION SANTA MARIA DEL LLANO - RIONEGRO - ANTIOQUIA</option>
              <option value="05615045">PARCELACION TORRE MOLINOS - RIONEGRO - ANTIOQUIA</option>
              <option value="05615046">PARCELACION TOSCANA - RIONEGRO - ANTIOQUIA</option>
              <option value="05615047">PARCELACION VEGAS DE GUADALCANAL - RIONEGRO - ANTIOQUIA</option>
              <option value="05628000">SABANALARGA - ANTIOQUIA</option>
              <option value="05628001">EL JUNCO - SABANALARGA - ANTIOQUIA</option>
              <option value="05628002">EL ORO - SABANALARGA - ANTIOQUIA</option>
              <option value="05628004">EL SOCORRO - SABANALARGA - ANTIOQUIA</option>
              <option value="05628007">MEMBRILLAL - SABANALARGA - ANTIOQUIA</option>
              <option value="05631000">SABANETA - ANTIOQUIA</option>
              <option value="05631001">MARIA AUXILIADORA - SABANETA - ANTIOQUIA</option>
              <option value="05631002">CAÑAVERALEJO - SABANETA - ANTIOQUIA</option>
              <option value="05631006">SAN ISIDRO - SABANETA - ANTIOQUIA</option>
              <option value="05631007">LA INMACULADA - SABANETA - ANTIOQUIA</option>
              <option value="05631009">LA DOCTORA - SABANETA - ANTIOQUIA</option>
              <option value="05631010">LAS LOMITAS - SABANETA - ANTIOQUIA</option>
              <option value="05631013">LOMA DE LOS HENAO - SABANETA - ANTIOQUIA</option>
              <option value="05642000">SALGAR - ANTIOQUIA</option>
              <option value="05642001">EL CONCILIO - SALGAR - ANTIOQUIA</option>
              <option value="05642002">LA CAMARA - SALGAR - ANTIOQUIA</option>
              <option value="05642003">LA MARGARITA - SALGAR - ANTIOQUIA</option>
              <option value="05642010">PEÑALISA - SALGAR - ANTIOQUIA</option>
              <option value="05647000">SAN ANDRES DE CUERQUIA - ANTIOQUIA</option>
              <option value="05649000">SAN CARLOS - ANTIOQUIA</option>
              <option value="05649001">EL JORDAN - SAN CARLOS - ANTIOQUIA</option>
              <option value="05649002">SAMANA - SAN CARLOS - ANTIOQUIA</option>
              <option value="05649005">PUERTO GARZA - SAN CARLOS - ANTIOQUIA</option>
              <option value="05649012">DOS QUEBRADAS - SAN CARLOS - ANTIOQUIA</option>
              <option value="05649013">LA HONDITA - SAN CARLOS - ANTIOQUIA</option>
              <option value="05649014">VALLEJUELO - SAN CARLOS - ANTIOQUIA</option>
              <option value="05652000">SAN FRANCISCO - ANTIOQUIA</option>
              <option value="05652001">AQUITANIA - SAN FRANCISCO - ANTIOQUIA</option>
              <option value="05652008">PAILANIA - SAN FRANCISCO - ANTIOQUIA</option>
              <option value="05652010">RIO CLARO - SAN FRANCISCO - ANTIOQUIA</option>
              <option value="05656000">SAN JERONIMO - ANTIOQUIA</option>
              <option value="05658000">SAN JOSE DE LA MONTAÑA - ANTIOQUIA</option>
              <option value="05659000">SAN JUAN DE URABA - ANTIOQUIA</option>
              <option value="05659001">DAMAQUIEL - SAN JUAN DE URABA - ANTIOQUIA</option>
              <option value="05659002">SAN JUANCITO - SAN JUAN DE URABA - ANTIOQUIA</option>
              <option value="05659003">UVEROS - SAN JUAN DE URABA - ANTIOQUIA</option>
              <option value="05659004">SIETE VUELTAS - SAN JUAN DE URABA - ANTIOQUIA</option>
              <option value="05659005">SAN NICOLAS DEL RIO - SAN JUAN DE URABA - ANTIOQUIA</option>
              <option value="05659006">BALSILLA - SAN JUAN DE URABA - ANTIOQUIA</option>
              <option value="05660000">SAN LUIS - ANTIOQUIA</option>
              <option value="05660001">EL SILENCIO PERLA VERDE - SAN LUIS - ANTIOQUIA</option>
              <option value="05660007">BUENOS AIRES - SAN LUIS - ANTIOQUIA</option>
              <option value="05660011">MONTELORO (LA JOSEFINA) - SAN LUIS - ANTIOQUIA</option>
              <option value="05660012">LA TEBAIDA - SAN LUIS - ANTIOQUIA</option>
              <option value="05660013">SOPETRAN - SAN LUIS - ANTIOQUIA</option>
              <option value="05660014">EL SILENCIO - EL VENTIADERO - SAN LUIS - ANTIOQUIA</option>
              <option value="05664000">SAN PEDRO DE LOS MILAGROS - ANTIOQUIA</option>
              <option value="05664005">OVEJAS - SAN PEDRO DE LOS MILAGROS - ANTIOQUIA</option>
              <option value="05665000">SAN PEDRO DE URABA - ANTIOQUIA</option>
              <option value="05665002">SANTA CATALINA - SAN PEDRO DE URABA - ANTIOQUIA</option>
              <option value="05665003">ARENAS MONAS - SAN PEDRO DE URABA - ANTIOQUIA</option>
              <option value="05665004">ZAPINDONGA - SAN PEDRO DE URABA - ANTIOQUIA</option>
              <option value="05665008">EL TOMATE - SAN PEDRO DE URABA - ANTIOQUIA</option>
              <option value="05667000">SAN RAFAEL - ANTIOQUIA</option>
              <option value="05670000">SAN ROQUE - ANTIOQUIA</option>
              <option value="05670001">CRISTALES - SAN ROQUE - ANTIOQUIA</option>
              <option value="05670002">PROVIDENCIA - SAN ROQUE - ANTIOQUIA</option>
              <option value="05670003">SAN JOSE DEL NUS - SAN ROQUE - ANTIOQUIA</option>
              <option value="05674000">SAN VICENTE - SAN VICENTE FERRER - ANTIOQUIA</option>
              <option value="05674001">CORRIENTES - SAN VICENTE FERRER - ANTIOQUIA</option>
              <option value="05679000">SANTA BARBARA - ANTIOQUIA</option>
              <option value="05679001">DAMASCO - SANTA BARBARA - ANTIOQUIA</option>
              <option value="05679004">VERSALLES - SANTA BARBARA - ANTIOQUIA</option>
              <option value="05679009">YARUMALITO - SANTA BARBARA - ANTIOQUIA</option>
              <option value="05679010">LA LIBORIANA - SANTA BARBARA - ANTIOQUIA</option>
              <option value="05679011">ZARCITOS PARTE ALTA - SANTA BARBARA - ANTIOQUIA</option>
              <option value="05686000">SANTA ROSA DE OSOS - ANTIOQUIA</option>
              <option value="05686001">ARAGON - SANTA ROSA DE OSOS - ANTIOQUIA</option>
              <option value="05686003">HOYORRICO - SANTA ROSA DE OSOS - ANTIOQUIA</option>
              <option value="05686004">SAN ISIDRO - SANTA ROSA DE OSOS - ANTIOQUIA</option>
              <option value="05686006">SAN PABLO - SANTA ROSA DE OSOS - ANTIOQUIA</option>
              <option value="05686008">RIO GRANDE - SANTA ROSA DE OSOS - ANTIOQUIA</option>
              <option value="05690000">SANTO DOMINGO - ANTIOQUIA</option>
              <option value="05690001">BOTERO - SANTO DOMINGO - ANTIOQUIA</option>
              <option value="05690003">PORCECITO - SANTO DOMINGO - ANTIOQUIA</option>
              <option value="05690004">SANTIAGO - SANTO DOMINGO - ANTIOQUIA</option>
              <option value="05690005">VERSALLES - SANTO DOMINGO - ANTIOQUIA</option>
              <option value="05697000">EL SANTUARIO - ANTIOQUIA</option>
              <option value="05736000">SEGOVIA - ANTIOQUIA</option>
              <option value="05736001">FRAGUAS - SEGOVIA - ANTIOQUIA</option>
              <option value="05736002">PUERTO CALAVERA - SEGOVIA - ANTIOQUIA</option>
              <option value="05736003">CAMPO ALEGRE - SEGOVIA - ANTIOQUIA</option>
              <option value="05736006">EL CHISPERO - SEGOVIA - ANTIOQUIA</option>
              <option value="05756000">SONSON - ANTIOQUIA</option>
              <option value="05756001">ALTO DE SABANAS - SONSON - ANTIOQUIA</option>
              <option value="05756005">SAN MIGUEL - SONSON - ANTIOQUIA</option>
              <option value="05756030">LA DANTA - SONSON - ANTIOQUIA</option>
              <option value="05756033">JERUSALEN - SONSON - ANTIOQUIA</option>
              <option value="05756034">EL ALTO DEL POLLO - SONSON - ANTIOQUIA</option>
              <option value="05761000">SOPETRAN - ANTIOQUIA</option>
              <option value="05761001">CORDOBA - SOPETRAN - ANTIOQUIA</option>
              <option value="05761003">HORIZONTES - SOPETRAN - ANTIOQUIA</option>
              <option value="05761005">SAN NICOLAS - SOPETRAN - ANTIOQUIA</option>
              <option value="05761011">LA MIRANDA - SOPETRAN - ANTIOQUIA</option>
              <option value="05761012">SANTA BARBARA - SOPETRAN - ANTIOQUIA</option>
              <option value="05789000">TAMESIS - ANTIOQUIA</option>
              <option value="05789001">PALERMO - TAMESIS - ANTIOQUIA</option>
              <option value="05789002">SAN PABLO - TAMESIS - ANTIOQUIA</option>
              <option value="05790000">TARAZA - ANTIOQUIA</option>
              <option value="05790001">BARRO BLANCO - TARAZA - ANTIOQUIA</option>
              <option value="05790002">EL DOCE - TARAZA - ANTIOQUIA</option>
              <option value="05790003">PUERTO ANTIOQUIA - TARAZA - ANTIOQUIA</option>
              <option value="05790004">LA CAUCANA - TARAZA - ANTIOQUIA</option>
              <option value="05790005">EL GUAIMARO - TARAZA - ANTIOQUIA</option>
              <option value="05790006">PIEDRAS - TARAZA - ANTIOQUIA</option>
              <option value="05792000">TARSO - ANTIOQUIA</option>
              <option value="05792003">TOCA MOCHO - TARSO - ANTIOQUIA</option>
              <option value="05792006">EL CEDRON - TARSO - ANTIOQUIA</option>
              <option value="05809000">TITIRIBI - ANTIOQUIA</option>
              <option value="05809001">LA MESETA - TITIRIBI - ANTIOQUIA</option>
              <option value="05809002">ALBANIA - TITIRIBI - ANTIOQUIA</option>
              <option value="05809003">OTRAMINA - TITIRIBI - ANTIOQUIA</option>
              <option value="05809004">SITIO VIEJO - TITIRIBI - ANTIOQUIA</option>
              <option value="05809014">PORVENIR - TITIRIBI - ANTIOQUIA</option>
              <option value="05809016">PUERTO ESCONDIDO - TITIRIBI - ANTIOQUIA</option>
              <option value="05809018">VOLCAN - TITIRIBI - ANTIOQUIA</option>
              <option value="05819000">TOLEDO - ANTIOQUIA</option>
              <option value="05819001">BUENAVISTA - TOLEDO - ANTIOQUIA</option>
              <option value="05819002">EL VALLE - TOLEDO - ANTIOQUIA</option>
              <option value="05819005">EL BRUGO - TOLEDO - ANTIOQUIA</option>
              <option value="05837000">TURBO, DISTRITO PORTUARIO, LOGISTICO, INDUSTRIAL, TURISTICO Y COMERCIAL - TURBO - ANTIOQUIA</option>
              <option value="05837001">CURRULAO - TURBO - ANTIOQUIA</option>
              <option value="05837002">NUEVA COLONIA - TURBO - ANTIOQUIA</option>
              <option value="05837003">EL TRES - TURBO - ANTIOQUIA</option>
              <option value="05837005">SAN VICENTE DEL CONGO - TURBO - ANTIOQUIA</option>
              <option value="05837006">TIE - TURBO - ANTIOQUIA</option>
              <option value="05837007">LOMAS AISLADAS - TURBO - ANTIOQUIA</option>
              <option value="05837008">RIO GRANDE - TURBO - ANTIOQUIA</option>
              <option value="05837009">BOCAS DEL RIO ATRATO - TURBO - ANTIOQUIA</option>
              <option value="05837010">EL DOS - TURBO - ANTIOQUIA</option>
              <option value="05837012">PUEBLO BELLO - TURBO - ANTIOQUIA</option>
              <option value="05837013">SAN JOSE DE MULATOS - TURBO - ANTIOQUIA</option>
              <option value="05837014">PUERTO RICO - TURBO - ANTIOQUIA</option>
              <option value="05837018">NUEVO ANTIOQUIA - TURBO - ANTIOQUIA</option>
              <option value="05837020">ALTO DE MULATOS - TURBO - ANTIOQUIA</option>
              <option value="05837023">CASANOVA - TURBO - ANTIOQUIA</option>
              <option value="05837024">LAS GARZAS - TURBO - ANTIOQUIA</option>
              <option value="05837025">VILLA MARIA - TURBO - ANTIOQUIA</option>
              <option value="05837026">CODELSA - TURBO - ANTIOQUIA</option>
              <option value="05837027">EL PORVENIR - TURBO - ANTIOQUIA</option>
              <option value="05837028">NUEVA GRANADA - TURBO - ANTIOQUIA</option>
              <option value="05837029">PUNTA DE PIEDRA - TURBO - ANTIOQUIA</option>
              <option value="05837030">AMSTERCOL I - TURBO - ANTIOQUIA</option>
              <option value="05837031">AMSTERCOL II - TURBO - ANTIOQUIA</option>
              <option value="05837032">CIRILO - TURBO - ANTIOQUIA</option>
              <option value="05837033">CONGO ARRIBA - TURBO - ANTIOQUIA</option>
              <option value="05837034">EL UNO - TURBO - ANTIOQUIA</option>
              <option value="05837035">GUADUALITO - TURBO - ANTIOQUIA</option>
              <option value="05837036">LAS BABILLAS - TURBO - ANTIOQUIA</option>
              <option value="05837037">LOS ENAMORADOS - TURBO - ANTIOQUIA</option>
              <option value="05837038">MAKENCAL - TURBO - ANTIOQUIA</option>
              <option value="05837039">PIEDRECITAS - TURBO - ANTIOQUIA</option>
              <option value="05837040">SANTIAGO DE URABA - TURBO - ANTIOQUIA</option>
              <option value="05837041">SIETE DE AGOSTO - TURBO - ANTIOQUIA</option>
              <option value="05837042">SINAI - TURBO - ANTIOQUIA</option>
              <option value="05837043">EL ROTO - TURBO - ANTIOQUIA</option>
              <option value="05842000">URAMITA - ANTIOQUIA</option>
              <option value="05842003">EL PITAL - URAMITA - ANTIOQUIA</option>
              <option value="05842004">EL MADERO - URAMITA - ANTIOQUIA</option>
              <option value="05842005">LIMON CHUPADERO - URAMITA - ANTIOQUIA</option>
              <option value="05847000">URRAO - ANTIOQUIA</option>
              <option value="05847003">LA ENCARNACION - URRAO - ANTIOQUIA</option>
              <option value="05847017">SAN JOSE - URRAO - ANTIOQUIA</option>
              <option value="05854000">VALDIVIA - ANTIOQUIA</option>
              <option value="05854002">PUERTO VALDIVIA - VALDIVIA - ANTIOQUIA</option>
              <option value="05854003">RAUDAL VIEJO - VALDIVIA - ANTIOQUIA</option>
              <option value="05854010">PUERTO RAUDAL - VALDIVIA - ANTIOQUIA</option>
              <option value="05856000">VALPARAISO - ANTIOQUIA</option>
              <option value="05858000">VEGACHI - ANTIOQUIA</option>
              <option value="05858001">EL TIGRE - VEGACHI - ANTIOQUIA</option>
              <option value="05858003">EL CINCO - VEGACHI - ANTIOQUIA</option>
              <option value="05861000">VENECIA - ANTIOQUIA</option>
              <option value="05861002">BOLOMBOLO - VENECIA - ANTIOQUIA</option>
              <option value="05861006">PALENQUE - VENECIA - ANTIOQUIA</option>
              <option value="05861008">LA AMALIA - VENECIA - ANTIOQUIA</option>
              <option value="05873000">VIGIA DEL FUERTE - ANTIOQUIA</option>
              <option value="05873001">SAN ANTONIO DE PADUA - VIGIA DEL FUERTE - ANTIOQUIA</option>
              <option value="05873002">VEGAEZ - VIGIA DEL FUERTE - ANTIOQUIA</option>
              <option value="05873004">SAN ALEJANDRO - VIGIA DEL FUERTE - ANTIOQUIA</option>
              <option value="05873005">SAN MIGUEL - VIGIA DEL FUERTE - ANTIOQUIA</option>
              <option value="05873006">PUERTO ANTIOQUIA - VIGIA DEL FUERTE - ANTIOQUIA</option>
              <option value="05873007">BUCHADO - VIGIA DEL FUERTE - ANTIOQUIA</option>
              <option value="05873009">PALO BLANCO - VIGIA DEL FUERTE - ANTIOQUIA</option>
              <option value="05873010">BAJO MURRI - VIGIA DEL FUERTE - ANTIOQUIA</option>
              <option value="05873011">EL ARENAL - VIGIA DEL FUERTE - ANTIOQUIA</option>
              <option value="05873012">GUADUALITO - VIGIA DEL FUERTE - ANTIOQUIA</option>
              <option value="05873013">LOMA MURRY - VIGIA DEL FUERTE - ANTIOQUIA</option>
              <option value="05873014">SAN MARTIN - VIGIA DEL FUERTE - ANTIOQUIA</option>
              <option value="05873015">SANTA MARIA - VIGIA DEL FUERTE - ANTIOQUIA</option>
              <option value="05885000">YALI - ANTIOQUIA</option>
              <option value="05885027">VILLA ANITA - YALI - ANTIOQUIA</option>
              <option value="05887000">YARUMAL - ANTIOQUIA</option>
              <option value="05887003">CEDEÑO - YARUMAL - ANTIOQUIA</option>
              <option value="05887004">EL CEDRO - YARUMAL - ANTIOQUIA</option>
              <option value="05887006">OCHALI - YARUMAL - ANTIOQUIA</option>
              <option value="05887007">LLANOS DE CUIVA - YARUMAL - ANTIOQUIA</option>
              <option value="05887009">EL PUEBLITO - YARUMAL - ANTIOQUIA</option>
              <option value="05887021">LA LOMA - YARUMAL - ANTIOQUIA</option>
              <option value="05887022">MINA VIEJA - YARUMAL - ANTIOQUIA</option>
              <option value="05890000">YOLOMBO - ANTIOQUIA</option>
              <option value="05890001">LA FLORESTA - YOLOMBO - ANTIOQUIA</option>
              <option value="05890004">EL RUBI - YOLOMBO - ANTIOQUIA</option>
              <option value="05890009">VILLANUEVA - YOLOMBO - ANTIOQUIA</option>
              <option value="05893000">YONDO - ANTIOQUIA</option>
              <option value="05893001">CIENAGA DE BARBACOA - LA PUNTA - YONDO - ANTIOQUIA</option>
              <option value="05893002">SAN LUIS BELTRAN - YONDO - ANTIOQUIA</option>
              <option value="05893003">SAN MIGUEL DEL TIGRE - YONDO - ANTIOQUIA</option>
              <option value="05893005">BOCAS DE SAN FRANCISCO - YONDO - ANTIOQUIA</option>
              <option value="05893008">EL BAGRE - YONDO - ANTIOQUIA</option>
              <option value="05893009">BOCAS DE BARBACOAS - YONDO - ANTIOQUIA</option>
              <option value="05893014">PUERTO LOS MANGOS - YONDO - ANTIOQUIA</option>
              <option value="05893015">PUERTO MATILDE - YONDO - ANTIOQUIA</option>
              <option value="05893017">PUERTO TOMAS - YONDO - ANTIOQUIA</option>
              <option value="05893018">PUERTO CASABE - YONDO - ANTIOQUIA</option>
              <option value="05893019">LA CONDOR - YONDO - ANTIOQUIA</option>
              <option value="05893021">CHORRO DE LAGRIMAS - YONDO - ANTIOQUIA</option>
              <option value="05895000">ZARAGOZA - ANTIOQUIA</option>
              <option value="05895003">BUENOS AIRES - ZARAGOZA - ANTIOQUIA</option>
              <option value="05895004">PATO - ZARAGOZA - ANTIOQUIA</option>
              <option value="05895008">VEGAS DE SEGOVIA - ZARAGOZA - ANTIOQUIA</option>
              <option value="05895009">EL CENIZO - ZARAGOZA - ANTIOQUIA</option>
              <option value="05895010">EL CRISTO - ZARAGOZA - ANTIOQUIA</option>
              <option value="05895011">LA CALIENTE - ZARAGOZA - ANTIOQUIA</option>
              <option value="08001000">BARRANQUILLA, DISTRITO ESPECIAL, INDUSTRIAL Y PORTUARIO - BARRANQUILLA - ATLANTICO</option>
              <option value="08078000">BARANOA - ATLANTICO</option>
              <option value="08078001">CAMPECHE - BARANOA - ATLANTICO</option>
              <option value="08078002">PITAL - BARANOA - ATLANTICO</option>
              <option value="08078003">SIBARCO - BARANOA - ATLANTICO</option>
              <option value="08137000">CAMPO DE LA CRUZ - ATLANTICO</option>
              <option value="08137001">BOHORQUEZ - CAMPO DE LA CRUZ - ATLANTICO</option>
              <option value="08141000">CANDELARIA - ATLANTICO</option>
              <option value="08141001">SAN JOSE DEL CARRETAL - CANDELARIA - ATLANTICO</option>
              <option value="08141002">BUENAVENTURA DE LEÑA - CANDELARIA - ATLANTICO</option>
              <option value="08296000">GALAPA - ATLANTICO</option>
              <option value="08296001">PALUATO - GALAPA - ATLANTICO</option>
              <option value="08372000">JUAN DE ACOSTA - ATLANTICO</option>
              <option value="08372001">BOCATOCINO - JUAN DE ACOSTA - ATLANTICO</option>
              <option value="08372002">CHORRERA - JUAN DE ACOSTA - ATLANTICO</option>
              <option value="08372003">SAN JOSE DE SACO - JUAN DE ACOSTA - ATLANTICO</option>
              <option value="08372004">SANTA VERONICA - JUAN DE ACOSTA - ATLANTICO</option>
              <option value="08372007">URBANIZACION PUNTA CANGREJO - JUAN DE ACOSTA - ATLANTICO</option>
              <option value="08421000">LURUACO - ATLANTICO</option>
              <option value="08421001">ARROYO DE PIEDRA - LURUACO - ATLANTICO</option>
              <option value="08421002">PALMAR DE CANDELARIA - LURUACO - ATLANTICO</option>
              <option value="08421003">LOS PENDALES - LURUACO - ATLANTICO</option>
              <option value="08421004">SAN JUAN DE TOCAGUA - LURUACO - ATLANTICO</option>
              <option value="08421005">SANTA CRUZ - LURUACO - ATLANTICO</option>
              <option value="08421006">LOS LIMITES - LURUACO - ATLANTICO</option>
              <option value="08421007">LA PUNTICA - LURUACO - ATLANTICO</option>
              <option value="08421012">BARRIGON - LURUACO - ATLANTICO</option>
              <option value="08421013">SOCAVON - LURUACO - ATLANTICO</option>
              <option value="08433000">MALAMBO - ATLANTICO</option>
              <option value="08433001">CARACOLI - MALAMBO - ATLANTICO</option>
              <option value="08433004">LA AGUADA - MALAMBO - ATLANTICO</option>
              <option value="08433005">PITALITO - MALAMBO - ATLANTICO</option>
              <option value="08436000">MANATI - ATLANTICO</option>
              <option value="08436001">EL PORVENIR (LAS COMPUERTAS) - MANATI - ATLANTICO</option>
              <option value="08436002">VILLA JUANA - MANATI - ATLANTICO</option>
              <option value="08520000">PALMAR DE VARELA - ATLANTICO</option>
              <option value="08520001">BURRUSCOS - PALMAR DE VARELA - ATLANTICO</option>
              <option value="08549000">PIOJO - ATLANTICO</option>
              <option value="08549001">AGUAS VIVAS - PIOJO - ATLANTICO</option>
              <option value="08549002">EL CERRITO - PIOJO - ATLANTICO</option>
              <option value="08549003">HIBACHARO - PIOJO - ATLANTICO</option>
              <option value="08558000">POLONUEVO - ATLANTICO</option>
              <option value="08558001">PITAL DEL CARLIN (PITALITO) - POLONUEVO - ATLANTICO</option>
              <option value="08560000">PONEDERA - ATLANTICO</option>
              <option value="08560001">LA RETIRADA - PONEDERA - ATLANTICO</option>
              <option value="08560002">MARTILLO - PONEDERA - ATLANTICO</option>
              <option value="08560003">PUERTO GIRALDO - PONEDERA - ATLANTICO</option>
              <option value="08560004">SANTA RITA - PONEDERA - ATLANTICO</option>
              <option value="08573000">PUERTO COLOMBIA - ATLANTICO</option>
              <option value="08573002">SALGAR - PUERTO COLOMBIA - ATLANTICO</option>
              <option value="08573003">SABANILLA (MONTE CARMELO) - PUERTO COLOMBIA - ATLANTICO</option>
              <option value="08606000">REPELON - ATLANTICO</option>
              <option value="08606001">ARROYO NEGRO - REPELON - ATLANTICO</option>
              <option value="08606002">CIEN PESOS - REPELON - ATLANTICO</option>
              <option value="08606003">LAS TABLAS - REPELON - ATLANTICO</option>
              <option value="08606004">ROTINET - REPELON - ATLANTICO</option>
              <option value="08606005">VILLA ROSA - REPELON - ATLANTICO</option>
              <option value="08606009">PITA - REPELON - ATLANTICO</option>
              <option value="08634000">SABANAGRANDE - ATLANTICO</option>
              <option value="08638000">SABANALARGA - ATLANTICO</option>
              <option value="08638001">AGUADA DE PABLO - SABANALARGA - ATLANTICO</option>
              <option value="08638002">CASCAJAL - SABANALARGA - ATLANTICO</option>
              <option value="08638003">COLOMBIA - SABANALARGA - ATLANTICO</option>
              <option value="08638004">ISABEL LOPEZ - SABANALARGA - ATLANTICO</option>
              <option value="08638005">LA PEÑA - SABANALARGA - ATLANTICO</option>
              <option value="08638006">MOLINERO - SABANALARGA - ATLANTICO</option>
              <option value="08638007">MIRADOR - SABANALARGA - ATLANTICO</option>
              <option value="08638008">GALLEGO - SABANALARGA - ATLANTICO</option>
              <option value="08638010">PATILLA - SABANALARGA - ATLANTICO</option>
              <option value="08675000">SANTA LUCIA - ATLANTICO</option>
              <option value="08675001">ALGODONAL - SANTA LUCIA - ATLANTICO</option>
              <option value="08685000">SANTO TOMAS - ATLANTICO</option>
              <option value="08758000">SOLEDAD - ATLANTICO</option>
              <option value="08770000">SUAN - ATLANTICO</option>
              <option value="08832000">TUBARA - ATLANTICO</option>
              <option value="08832001">CUATRO BOCAS - TUBARA - ATLANTICO</option>
              <option value="08832002">EL MORRO - TUBARA - ATLANTICO</option>
              <option value="08832003">GUAIMARAL - TUBARA - ATLANTICO</option>
              <option value="08832004">JUARUCO - TUBARA - ATLANTICO</option>
              <option value="08832007">CORRAL DE SAN LUIS - TUBARA - ATLANTICO</option>
              <option value="08832010">PLAYA MENDOZA - TUBARA - ATLANTICO</option>
              <option value="08832011">PLAYAS DE EDRIMAN - TUBARA - ATLANTICO</option>
              <option value="08832012">VILLAS DE PALMARITO - TUBARA - ATLANTICO</option>
              <option value="08832013">NUEVA ESPERANZA - TUBARA - ATLANTICO</option>
              <option value="08849000">USIACURI - ATLANTICO</option>
              <option value="11001000">BOGOTA, DISTRITO CAPITAL - BOGOTA, D.C. - BOGOTA, D.C.</option>
              <option value="11001002">NAZARETH - BOGOTA, D.C. - BOGOTA, D.C.</option>
              <option value="11001007">PASQUILLA - BOGOTA, D.C. - BOGOTA, D.C.</option>
              <option value="11001008">SAN JUAN - BOGOTA, D.C. - BOGOTA, D.C.</option>
              <option value="11001009">BETANIA - BOGOTA, D.C. - BOGOTA, D.C.</option>
              <option value="11001010">LA UNION - BOGOTA, D.C. - BOGOTA, D.C.</option>
              <option value="11001011">MOCHUELO ALTO - BOGOTA, D.C. - BOGOTA, D.C.</option>
              <option value="11001012">CHORRILLOS - BOGOTA, D.C. - BOGOTA, D.C.</option>
              <option value="11001013">EL DESTINO - BOGOTA, D.C. - BOGOTA, D.C.</option>
              <option value="11001014">NUEVA GRANADA - BOGOTA, D.C. - BOGOTA, D.C.</option>
              <option value="11001015">QUIBA BAJO - BOGOTA, D.C. - BOGOTA, D.C.</option>
              <option value="13001000">CARTAGENA DE INDIAS, DISTRITO TURISTICO Y CULTURAL - CARTAGENA DE INDIAS - BOLIVAR</option>
              <option value="13001001">ARROYO DE PIEDRA - CARTAGENA DE INDIAS - BOLIVAR</option>
              <option value="13001002">ARROYO GRANDE - CARTAGENA DE INDIAS - BOLIVAR</option>
              <option value="13001003">BARU - CARTAGENA DE INDIAS - BOLIVAR</option>
              <option value="13001004">BAYUNCA - CARTAGENA DE INDIAS - BOLIVAR</option>
              <option value="13001005">BOCACHICA - CARTAGENA DE INDIAS - BOLIVAR</option>
              <option value="13001006">CAÑO DEL ORO - CARTAGENA DE INDIAS - BOLIVAR</option>
              <option value="13001007">ISLA FUERTE - CARTAGENA DE INDIAS - BOLIVAR</option>
              <option value="13001008">LA BOQUILLA - CARTAGENA DE INDIAS - BOLIVAR</option>
              <option value="13001009">PASACABALLOS - CARTAGENA DE INDIAS - BOLIVAR</option>
              <option value="13001010">PUNTA CANOA - CARTAGENA DE INDIAS - BOLIVAR</option>
              <option value="13001012">SANTA ANA - CARTAGENA DE INDIAS - BOLIVAR</option>
              <option value="13001013">TIERRA BOMBA - CARTAGENA DE INDIAS - BOLIVAR</option>
              <option value="13001014">PUNTA ARENA - CARTAGENA DE INDIAS - BOLIVAR</option>
              <option value="13001015">ARARCA - CARTAGENA DE INDIAS - BOLIVAR</option>
              <option value="13001016">LETICIA - CARTAGENA DE INDIAS - BOLIVAR</option>
              <option value="13001017">SANTA CRUZ DEL ISLOTE (ARCHIPIELAGO DE SAN BERNARDO) - CARTAGENA DE INDIAS - BOLIVAR</option>
              <option value="13001018">EL RECREO - CARTAGENA DE INDIAS - BOLIVAR</option>
              <option value="13001019">PUERTO REY - CARTAGENA DE INDIAS - BOLIVAR</option>
              <option value="13001020">PONTEZUELA - CARTAGENA DE INDIAS - BOLIVAR</option>
              <option value="13001026">ARROYO DE LAS CANOAS - CARTAGENA DE INDIAS - BOLIVAR</option>
              <option value="13001027">EL PUEBLITO - CARTAGENA DE INDIAS - BOLIVAR</option>
              <option value="13001028">LAS EUROPAS - CARTAGENA DE INDIAS - BOLIVAR</option>
              <option value="13001029">MANZANILLO DEL MAR - CARTAGENA DE INDIAS - BOLIVAR</option>
              <option value="13001030">TIERRA BAJA - CARTAGENA DE INDIAS - BOLIVAR</option>
              <option value="13001033">MEMBRILLAL - CARTAGENA DE INDIAS - BOLIVAR</option>
              <option value="13001034">BARCELONA DE INDIAS - CARTAGENA DE INDIAS - BOLIVAR</option>
              <option value="13001035">CARTAGENA LAGUNA CLUB - CARTAGENA DE INDIAS - BOLIVAR</option>
              <option value="13001036">CASAS DEL MAR - CARTAGENA DE INDIAS - BOLIVAR</option>
              <option value="13001037">MUCURA - CARTAGENA DE INDIAS - BOLIVAR</option>
              <option value="13001038">PUERTO BELLO - CARTAGENA DE INDIAS - BOLIVAR</option>
              <option value="13006000">ACHI - BOLIVAR</option>
              <option value="13006002">BOYACA - ACHI - BOLIVAR</option>
              <option value="13006003">BUENAVISTA - ACHI - BOLIVAR</option>
              <option value="13006005">ALGARROBO - ACHI - BOLIVAR</option>
              <option value="13006007">GUACAMAYO - ACHI - BOLIVAR</option>
              <option value="13006011">PLAYA ALTA - ACHI - BOLIVAR</option>
              <option value="13006015">TACUYA ALTA - ACHI - BOLIVAR</option>
              <option value="13006017">TRES CRUCES - ACHI - BOLIVAR</option>
              <option value="13006019">PAYANDE - ACHI - BOLIVAR</option>
              <option value="13006020">RIO NUEVO - ACHI - BOLIVAR</option>
              <option value="13006021">BUENOS AIRES - ACHI - BOLIVAR</option>
              <option value="13006022">PUERTO ISABEL - ACHI - BOLIVAR</option>
              <option value="13006030">CENTRO ALEGRE - ACHI - BOLIVAR</option>
              <option value="13006033">PUERTO VENECIA - ACHI - BOLIVAR</option>
              <option value="13006035">SANTA LUCIA - ACHI - BOLIVAR</option>
              <option value="13006039">LOS NISPEROS - ACHI - BOLIVAR</option>
              <option value="13006041">PARAISO SINCERIN - ACHI - BOLIVAR</option>
              <option value="13006042">PROVIDENCIA - ACHI - BOLIVAR</option>
              <option value="13030000">ALTOS DEL ROSARIO - BOLIVAR</option>
              <option value="13030001">EL RUBIO - ALTOS DEL ROSARIO - BOLIVAR</option>
              <option value="13030002">LA PACHA - ALTOS DEL ROSARIO - BOLIVAR</option>
              <option value="13030003">SAN ISIDRO - ALTOS DEL ROSARIO - BOLIVAR</option>
              <option value="13030004">SANTA LUCIA - ALTOS DEL ROSARIO - BOLIVAR</option>
              <option value="13030005">SAN ISIDRO 2 - ALTOS DEL ROSARIO - BOLIVAR</option>
              <option value="13042000">ARENAL - BOLIVAR</option>
              <option value="13042001">BUENAVISTA - ARENAL - BOLIVAR</option>
              <option value="13042002">CARNIZALA - ARENAL - BOLIVAR</option>
              <option value="13042003">SAN RAFAEL - ARENAL - BOLIVAR</option>
              <option value="13042007">SANTO DOMINGO - ARENAL - BOLIVAR</option>
              <option value="13052000">ARJONA - BOLIVAR</option>
              <option value="13052001">PUERTO BADEL (CAÑO SALADO) - ARJONA - BOLIVAR</option>
              <option value="13052002">GAMBOTE - ARJONA - BOLIVAR</option>
              <option value="13052003">ROCHA - ARJONA - BOLIVAR</option>
              <option value="13052004">SINCERIN - ARJONA - BOLIVAR</option>
              <option value="13052005">SAN RAFAEL DE LA CRUZ - ARJONA - BOLIVAR</option>
              <option value="13052008">EL REMANSO - ARJONA - BOLIVAR</option>
              <option value="13062000">ARROYOHONDO - BOLIVAR</option>
              <option value="13062001">MACHADO - ARROYOHONDO - BOLIVAR</option>
              <option value="13062002">MONROY - ARROYOHONDO - BOLIVAR</option>
              <option value="13062003">PILON - ARROYOHONDO - BOLIVAR</option>
              <option value="13062004">SATO - ARROYOHONDO - BOLIVAR</option>
              <option value="13062006">SAN FRANCISCO (SOLABANDA) - ARROYOHONDO - BOLIVAR</option>
              <option value="13074000">BARRANCO DE LOBA - BOLIVAR</option>
              <option value="13074003">RIONUEVO - BARRANCO DE LOBA - BOLIVAR</option>
              <option value="13074004">SAN ANTONIO - BARRANCO DE LOBA - BOLIVAR</option>
              <option value="13074005">LOS CERRITOS - BARRANCO DE LOBA - BOLIVAR</option>
              <option value="13074006">LAS DELICIAS - BARRANCO DE LOBA - BOLIVAR</option>
              <option value="13140000">CALAMAR - BOLIVAR</option>
              <option value="13140002">BARRANCA NUEVA - CALAMAR - BOLIVAR</option>
              <option value="13140003">BARRANCA VIEJA - CALAMAR - BOLIVAR</option>
              <option value="13140004">HATO VIEJO - CALAMAR - BOLIVAR</option>
              <option value="13140009">YUCAL - CALAMAR - BOLIVAR</option>
              <option value="13140011">EL PROGRESO - CALAMAR - BOLIVAR</option>
              <option value="13160000">CANTAGALLO - BOLIVAR</option>
              <option value="13160001">SAN LORENZO - CANTAGALLO - BOLIVAR</option>
              <option value="13160002">BRISAS DE BOLIVAR - CANTAGALLO - BOLIVAR</option>
              <option value="13160011">LA VICTORIA - CANTAGALLO - BOLIVAR</option>
              <option value="13160015">LOS PATICOS - CANTAGALLO - BOLIVAR</option>
              <option value="13160017">NO HAY COMO DIOS - CANTAGALLO - BOLIVAR</option>
              <option value="13160019">SINZONA - CANTAGALLO - BOLIVAR</option>
              <option value="13160021">BUENOS AIRES - CANTAGALLO - BOLIVAR</option>
              <option value="13160022">LA PEÑA - CANTAGALLO - BOLIVAR</option>
              <option value="13160023">PATICO BAJO - CANTAGALLO - BOLIVAR</option>
              <option value="13160024">CUATRO BOCAS - CANTAGALLO - BOLIVAR</option>
              <option value="13188000">CICUCO - BOLIVAR</option>
              <option value="13188001">CAMPO SERENO - CICUCO - BOLIVAR</option>
              <option value="13188002">LA PEÑA - CICUCO - BOLIVAR</option>
              <option value="13188003">SAN FRANCISCO DE LOBA - CICUCO - BOLIVAR</option>
              <option value="13188005">PUEBLO NUEVO - CICUCO - BOLIVAR</option>
              <option value="13188006">BODEGA - CICUCO - BOLIVAR</option>
              <option value="13212000">CORDOBA - BOLIVAR</option>
              <option value="13212001">GUAIMARAL - CORDOBA - BOLIVAR</option>
              <option value="13212003">LA MONTAÑA DE ALONSO (MARTIN ALONSO) - CORDOBA - BOLIVAR</option>
              <option value="13212005">SAN ANDRES - CORDOBA - BOLIVAR</option>
              <option value="13212006">SINCELEJITO - CORDOBA - BOLIVAR</option>
              <option value="13212007">TACAMOCHITO - CORDOBA - BOLIVAR</option>
              <option value="13212008">TACAMOCHO - CORDOBA - BOLIVAR</option>
              <option value="13212009">SANTA LUCIA - CORDOBA - BOLIVAR</option>
              <option value="13212010">LA SIERRA - CORDOBA - BOLIVAR</option>
              <option value="13212011">LAS MARIAS - CORDOBA - BOLIVAR</option>
              <option value="13212012">PUEBLO NUEVO - CORDOBA - BOLIVAR</option>
              <option value="13212013">SANAHUARE - CORDOBA - BOLIVAR</option>
              <option value="13212014">SOCORRO 1 - CORDOBA - BOLIVAR</option>
              <option value="13212015">BELLAVISTA - CORDOBA - BOLIVAR</option>
              <option value="13212016">LAS LOMITAS - CORDOBA - BOLIVAR</option>
              <option value="13212017">CALIFORNIA - CORDOBA - BOLIVAR</option>
              <option value="13222000">CLEMENCIA - BOLIVAR</option>
              <option value="13222001">LAS CARAS - CLEMENCIA - BOLIVAR</option>
              <option value="13222002">EL PEÑIQUE - CLEMENCIA - BOLIVAR</option>
              <option value="13244000">EL CARMEN DE BOLIVAR - BOLIVAR</option>
              <option value="13244001">BAJO GRANDE - EL CARMEN DE BOLIVAR - BOLIVAR</option>
              <option value="13244002">CARACOLI GRANDE - EL CARMEN DE BOLIVAR - BOLIVAR</option>
              <option value="13244003">EL SALADO - EL CARMEN DE BOLIVAR - BOLIVAR</option>
              <option value="13244004">JESUS DEL MONTE - EL CARMEN DE BOLIVAR - BOLIVAR</option>
              <option value="13244005">MACAYEPOS - EL CARMEN DE BOLIVAR - BOLIVAR</option>
              <option value="13244006">SAN CARLOS - EL CARMEN DE BOLIVAR - BOLIVAR</option>
              <option value="13244007">SAN ISIDRO - EL CARMEN DE BOLIVAR - BOLIVAR</option>
              <option value="13244008">HATO NUEVO - EL CARMEN DE BOLIVAR - BOLIVAR</option>
              <option value="13244011">EL RAIZAL - EL CARMEN DE BOLIVAR - BOLIVAR</option>
              <option value="13244014">SANTA LUCIA - EL CARMEN DE BOLIVAR - BOLIVAR</option>
              <option value="13244017">SANTO DOMINGO DE MEZA - EL CARMEN DE BOLIVAR - BOLIVAR</option>
              <option value="13244018">EL HOBO - EL CARMEN DE BOLIVAR - BOLIVAR</option>
              <option value="13244019">ARROYO ARENA - EL CARMEN DE BOLIVAR - BOLIVAR</option>
              <option value="13244020">LAZARO - EL CARMEN DE BOLIVAR - BOLIVAR</option>
              <option value="13244021">PADULA - EL CARMEN DE BOLIVAR - BOLIVAR</option>
              <option value="13244022">VERDUN - EL CARMEN DE BOLIVAR - BOLIVAR</option>
              <option value="13248000">EL GUAMO - BOLIVAR</option>
              <option value="13248001">LA ENEA - EL GUAMO - BOLIVAR</option>
              <option value="13248002">SAN JOSE DE LATA - EL GUAMO - BOLIVAR</option>
              <option value="13248003">NERVITI - EL GUAMO - BOLIVAR</option>
              <option value="13248004">ROBLES - EL GUAMO - BOLIVAR</option>
              <option value="13248005">TASAJERA - EL GUAMO - BOLIVAR</option>
              <option value="13268000">EL PEÑON - BOLIVAR</option>
              <option value="13268003">BUENOS AIRES - EL PEÑON - BOLIVAR</option>
              <option value="13268004">CASTAÑAL - EL PEÑON - BOLIVAR</option>
              <option value="13268006">LA CHAPETONA - EL PEÑON - BOLIVAR</option>
              <option value="13268008">JAPON - EL PEÑON - BOLIVAR</option>
              <option value="13268013">LA HUMAREDA - EL PEÑON - BOLIVAR</option>
              <option value="13268017">PEÑONCITO - EL PEÑON - BOLIVAR</option>
              <option value="13300000">HATILLO DE LOBA - BOLIVAR</option>
              <option value="13300001">EL POZON - HATILLO DE LOBA - BOLIVAR</option>
              <option value="13300002">JUANA SANCHEZ - HATILLO DE LOBA - BOLIVAR</option>
              <option value="13300003">LA RIBONA - HATILLO DE LOBA - BOLIVAR</option>
              <option value="13300004">LA VICTORIA - HATILLO DE LOBA - BOLIVAR</option>
              <option value="13300005">PUEBLO NUEVO - HATILLO DE LOBA - BOLIVAR</option>
              <option value="13300006">SAN MIGUEL - HATILLO DE LOBA - BOLIVAR</option>
              <option value="13300007">CERRO DE LAS AGUADAS - HATILLO DE LOBA - BOLIVAR</option>
              <option value="13300008">LAS BRISAS - HATILLO DE LOBA - BOLIVAR</option>
              <option value="13300009">GUALI - HATILLO DE LOBA - BOLIVAR</option>
              <option value="13300010">LAS PALMAS - HATILLO DE LOBA - BOLIVAR</option>
              <option value="13430000">MAGANGUE - BOLIVAR</option>
              <option value="13430001">BARBOSA - MAGANGUE - BOLIVAR</option>
              <option value="13430002">BARRANCA DE YUCA - MAGANGUE - BOLIVAR</option>
              <option value="13430003">BETANIA - MAGANGUE - BOLIVAR</option>
              <option value="13430004">BOCA DE SAN ANTONIO - MAGANGUE - BOLIVAR</option>
              <option value="13430006">CASCAJAL - MAGANGUE - BOLIVAR</option>
              <option value="13430007">CEIBAL - MAGANGUE - BOLIVAR</option>
              <option value="13430008">COYONGAL - MAGANGUE - BOLIVAR</option>
              <option value="13430009">EL RETIRO - MAGANGUE - BOLIVAR</option>
              <option value="13430010">GUAZO - MAGANGUE - BOLIVAR</option>
              <option value="13430011">HENEQUEN - MAGANGUE - BOLIVAR</option>
              <option value="13430013">JUAN ARIAS - MAGANGUE - BOLIVAR</option>
              <option value="13430014">LA PASCUALA - MAGANGUE - BOLIVAR</option>
              <option value="13430015">LA VENTURA - MAGANGUE - BOLIVAR</option>
              <option value="13430016">LAS BRISAS - MAGANGUE - BOLIVAR</option>
              <option value="13430017">MADRID - MAGANGUE - BOLIVAR</option>
              <option value="13430018">PALMARITO - MAGANGUE - BOLIVAR</option>
              <option value="13430019">PANSEGÜITA - MAGANGUE - BOLIVAR</option>
              <option value="13430020">PIÑALITO - MAGANGUE - BOLIVAR</option>
              <option value="13430021">SAN RAFAEL DE CORTINA - MAGANGUE - BOLIVAR</option>
              <option value="13430022">SAN JOSE DE LAS MARTAS - MAGANGUE - BOLIVAR</option>
              <option value="13430023">SAN SEBASTIAN DE BUENAVISTA - MAGANGUE - BOLIVAR</option>
              <option value="13430024">SANTA FE - MAGANGUE - BOLIVAR</option>
              <option value="13430025">SANTA LUCIA - MAGANGUE - BOLIVAR</option>
              <option value="13430026">SANTA MONICA - MAGANGUE - BOLIVAR</option>
              <option value="13430027">SANTA PABLA - MAGANGUE - BOLIVAR</option>
              <option value="13430028">SITIO NUEVO - MAGANGUE - BOLIVAR</option>
              <option value="13430029">PUERTO KENNEDY - MAGANGUE - BOLIVAR</option>
              <option value="13430030">TACALOA - MAGANGUE - BOLIVAR</option>
              <option value="13430031">TACASALUMA - MAGANGUE - BOLIVAR</option>
              <option value="13430032">TOLU - MAGANGUE - BOLIVAR</option>
              <option value="13430036">PLAYA DE LAS FLORES - MAGANGUE - BOLIVAR</option>
              <option value="13430038">EL CUATRO - MAGANGUE - BOLIVAR</option>
              <option value="13430039">BOCA DE GUAMAL - MAGANGUE - BOLIVAR</option>
              <option value="13430040">TRES PUNTAS - MAGANGUE - BOLIVAR</option>
              <option value="13430041">EMAUS - MAGANGUE - BOLIVAR</option>
              <option value="13430047">PUERTO NARIÑO - MAGANGUE - BOLIVAR</option>
              <option value="13430048">PUNTA DE CARTAGENA - MAGANGUE - BOLIVAR</option>
              <option value="13430049">ROMA - MAGANGUE - BOLIVAR</option>
              <option value="13430051">SAN ANTOÑITO - MAGANGUE - BOLIVAR</option>
              <option value="13430052">SANTA COITA - MAGANGUE - BOLIVAR</option>
              <option value="13430053">FLORENCIA - MAGANGUE - BOLIVAR</option>
              <option value="13433000">MAHATES - BOLIVAR</option>
              <option value="13433001">EVITAR - MAHATES - BOLIVAR</option>
              <option value="13433002">GAMERO - MAHATES - BOLIVAR</option>
              <option value="13433003">MALAGANA - MAHATES - BOLIVAR</option>
              <option value="13433004">SAN BASILIO DE PALENQUE - MAHATES - BOLIVAR</option>
              <option value="13433005">SAN JOAQUIN - MAHATES - BOLIVAR</option>
              <option value="13433009">MANDINGA - MAHATES - BOLIVAR</option>
              <option value="13433010">CRUZ DEL VIZO - MAHATES - BOLIVAR</option>
              <option value="13433011">LA MANGA - MAHATES - BOLIVAR</option>
              <option value="13440000">MARGARITA - BOLIVAR</option>
              <option value="13440001">BOTON DE LEIVA - MARGARITA - BOLIVAR</option>
              <option value="13440002">CANTERA - MARGARITA - BOLIVAR</option>
              <option value="13440003">CAUSADO - MARGARITA - BOLIVAR</option>
              <option value="13440004">CHILLOA - MARGARITA - BOLIVAR</option>
              <option value="13440005">DOÑA JUANA - MARGARITA - BOLIVAR</option>
              <option value="13440007">MAMONCITO - MARGARITA - BOLIVAR</option>
              <option value="13440008">SANDOVAL - MARGARITA - BOLIVAR</option>
              <option value="13440010">SAN JOSE DE LOS TRAPICHES - MARGARITA - BOLIVAR</option>
              <option value="13440011">COROCITO - MARGARITA - BOLIVAR</option>
              <option value="13440012">GUATAQUITA - MARGARITA - BOLIVAR</option>
              <option value="13440014">CAÑO MONO - MARGARITA - BOLIVAR</option>
              <option value="13440017">LA MONTAÑA - MARGARITA - BOLIVAR</option>
              <option value="13442000">MARIA LA BAJA - BOLIVAR</option>
              <option value="13442001">CORREA - MARIA LA BAJA - BOLIVAR</option>
              <option value="13442002">EL NISPERO - MARIA LA BAJA - BOLIVAR</option>
              <option value="13442003">FLAMENCO - MARIA LA BAJA - BOLIVAR</option>
              <option value="13442004">MANPUJAN - MARIA LA BAJA - BOLIVAR</option>
              <option value="13442005">ÑANGUMA - MARIA LA BAJA - BOLIVAR</option>
              <option value="13442006">RETIRO NUEVO - MARIA LA BAJA - BOLIVAR</option>
              <option value="13442007">SAN JOSE DEL PLAYON - MARIA LA BAJA - BOLIVAR</option>
              <option value="13442008">SAN PABLO - MARIA LA BAJA - BOLIVAR</option>
              <option value="13442009">EL MAJAGUA - MARIA LA BAJA - BOLIVAR</option>
              <option value="13442011">LOS BELLOS - MARIA LA BAJA - BOLIVAR</option>
              <option value="13442012">MATUYA - MARIA LA BAJA - BOLIVAR</option>
              <option value="13442014">COLU - MARIA LA BAJA - BOLIVAR</option>
              <option value="13442015">EL FLORIDO - MARIA LA BAJA - BOLIVAR</option>
              <option value="13442016">NUEVO RETEN - MARIA LA BAJA - BOLIVAR</option>
              <option value="13442017">ARROYO GRANDE - MARIA LA BAJA - BOLIVAR</option>
              <option value="13442019">NUEVA ESPERANZA - MARIA LA BAJA - BOLIVAR</option>
              <option value="13442020">PUEBLO NUEVO - MARIA LA BAJA - BOLIVAR</option>
              <option value="13442022">PRIMERO DE JULIO - MARIA LA BAJA - BOLIVAR</option>
              <option value="13442023">EL SENA - MARIA LA BAJA - BOLIVAR</option>
              <option value="13442024">LA CURVA - MARIA LA BAJA - BOLIVAR</option>
              <option value="13442025">LA PISTA - MARIA LA BAJA - BOLIVAR</option>
              <option value="13442026">MARQUEZ - MARIA LA BAJA - BOLIVAR</option>
              <option value="13442027">MUNGUIA - MARIA LA BAJA - BOLIVAR</option>
              <option value="13442030">CEDRITO - MARIA LA BAJA - BOLIVAR</option>
              <option value="13442031">EL GUAMO - MARIA LA BAJA - BOLIVAR</option>
              <option value="13442034">GUARISMO - MARIA LA BAJA - BOLIVAR</option>
              <option value="13442035">LA SUPREMA - MARIA LA BAJA - BOLIVAR</option>
              <option value="13442040">NUEVO PORVENIR - MARIA LA BAJA - BOLIVAR</option>
              <option value="13442043">SUCESION - MARIA LA BAJA - BOLIVAR</option>
              <option value="13442044">TOMA RAZON - MARIA LA BAJA - BOLIVAR</option>
              <option value="13442045">EL PUEBLITO - MARIA LA BAJA - BOLIVAR</option>
              <option value="13458000">MONTECRISTO - BOLIVAR</option>
              <option value="13458001">BETANIA - MONTECRISTO - BOLIVAR</option>
              <option value="13458002">LA DORADA - MONTECRISTO - BOLIVAR</option>
              <option value="13458003">PARAISO - MONTECRISTO - BOLIVAR</option>
              <option value="13458004">PUEBLO LINDO - MONTECRISTO - BOLIVAR</option>
              <option value="13458005">PUEBLO NUEVO - REGENCIA - MONTECRISTO - BOLIVAR</option>
              <option value="13458006">PUERTO ESPAÑA - MONTECRISTO - BOLIVAR</option>
              <option value="13458007">PLATANAL - MONTECRISTO - BOLIVAR</option>
              <option value="13458008">SAN AGUSTIN - MONTECRISTO - BOLIVAR</option>
              <option value="13458012">VILLA URIBE - MONTECRISTO - BOLIVAR</option>
              <option value="13468000">SANTA CRUZ DE MOMPOX, DISTRITO ESPECIAL, TURISTICO, CULTURAL E HISTORICO - SANTA CRUZ DE MOMPOX - BOLIVAR</option>
              <option value="13468001">CALDERA - SANTA CRUZ DE MOMPOX - BOLIVAR</option>
              <option value="13468002">CANDELARIA - SANTA CRUZ DE MOMPOX - BOLIVAR</option>
              <option value="13468008">GUAIMARAL - SANTA CRUZ DE MOMPOX - BOLIVAR</option>
              <option value="13468009">GUATACA - SANTA CRUZ DE MOMPOX - BOLIVAR</option>
              <option value="13468010">LA JAGUA - SANTA CRUZ DE MOMPOX - BOLIVAR</option>
              <option value="13468011">LA LOBATA - SANTA CRUZ DE MOMPOX - BOLIVAR</option>
              <option value="13468013">LA RINCONADA - SANTA CRUZ DE MOMPOX - BOLIVAR</option>
              <option value="13468014">LAS BOQUILLAS - SANTA CRUZ DE MOMPOX - BOLIVAR</option>
              <option value="13468015">LOMA DE SIMON - SANTA CRUZ DE MOMPOX - BOLIVAR</option>
              <option value="13468016">LOS PIÑONES - SANTA CRUZ DE MOMPOX - BOLIVAR</option>
              <option value="13468020">SAN IGNACIO - SANTA CRUZ DE MOMPOX - BOLIVAR</option>
              <option value="13468022">SAN NICOLAS - SANTA CRUZ DE MOMPOX - BOLIVAR</option>
              <option value="13468023">SANTA CRUZ - SANTA CRUZ DE MOMPOX - BOLIVAR</option>
              <option value="13468024">SANTA ROSA - SANTA CRUZ DE MOMPOX - BOLIVAR</option>
              <option value="13468025">SANTA TERESITA - SANTA CRUZ DE MOMPOX - BOLIVAR</option>
              <option value="13468028">ANCON - SANTA CRUZ DE MOMPOX - BOLIVAR</option>
              <option value="13468030">LA TRAVESIA - SANTA CRUZ DE MOMPOX - BOLIVAR</option>
              <option value="13468031">PUEBLO NUEVO - SANTA CRUZ DE MOMPOX - BOLIVAR</option>
              <option value="13468033">BOMBA - SANTA CRUZ DE MOMPOX - BOLIVAR</option>
              <option value="13468036">EL ROSARIO - SANTA CRUZ DE MOMPOX - BOLIVAR</option>
              <option value="13468038">SANTA ELENA - SANTA CRUZ DE MOMPOX - BOLIVAR</option>
              <option value="13468039">SAN LUIS - SANTA CRUZ DE MOMPOX - BOLIVAR</option>
              <option value="13468040">VILLA NUEVA - SANTA CRUZ DE MOMPOX - BOLIVAR</option>
              <option value="13473000">MORALES - BOLIVAR</option>
              <option value="13473002">BODEGA CENTRAL - MORALES - BOLIVAR</option>
              <option value="13473003">EL DIQUE - MORALES - BOLIVAR</option>
              <option value="13473004">LAS PAILAS - MORALES - BOLIVAR</option>
              <option value="13473012">BOCA DE LA HONDA - MORALES - BOLIVAR</option>
              <option value="13473013">MICOAHUMADO - MORALES - BOLIVAR</option>
              <option value="13473014">PAREDES DE ORORIA - MORALES - BOLIVAR</option>
              <option value="13473015">EL CORCOVADO - MORALES - BOLIVAR</option>
              <option value="13473016">LA ESMERALDA - MORALES - BOLIVAR</option>
              <option value="13473017">LA PALMA - MORALES - BOLIVAR</option>
              <option value="13473019">BOCA DE LA CIENAGA - MORALES - BOLIVAR</option>
              <option value="13490000">NOROSI - BOLIVAR</option>
              <option value="13490001">BUENA SEÑA - NOROSI - BOLIVAR</option>
              <option value="13490002">CASA DE BARRO - NOROSI - BOLIVAR</option>
              <option value="13490003">LAS NIEVES - NOROSI - BOLIVAR</option>
              <option value="13490004">MINA BRISA - NOROSI - BOLIVAR</option>
              <option value="13490005">MINA ESTRELLA - NOROSI - BOLIVAR</option>
              <option value="13490006">OLIVARES - NOROSI - BOLIVAR</option>
              <option value="13490007">SANTA ELENA - NOROSI - BOLIVAR</option>
              <option value="13490008">VILLA ARIZA - NOROSI - BOLIVAR</option>
              <option value="13549000">PINILLOS - BOLIVAR</option>
              <option value="13549001">ARMENIA - PINILLOS - BOLIVAR</option>
              <option value="13549004">LA RUFINA - PINILLOS - BOLIVAR</option>
              <option value="13549005">LA UNION - PINILLOS - BOLIVAR</option>
              <option value="13549007">LAS FLORES - PINILLOS - BOLIVAR</option>
              <option value="13549009">MANTEQUERA - PINILLOS - BOLIVAR</option>
              <option value="13549010">PALENQUITO - PINILLOS - BOLIVAR</option>
              <option value="13549011">PALOMINO - PINILLOS - BOLIVAR</option>
              <option value="13549012">PUERTO LOPEZ - PINILLOS - BOLIVAR</option>
              <option value="13549014">SANTA COA - PINILLOS - BOLIVAR</option>
              <option value="13549015">SANTA ROSA - PINILLOS - BOLIVAR</option>
              <option value="13549018">RUFINA NUEVA - PINILLOS - BOLIVAR</option>
              <option value="13549024">LA VICTORIA - PINILLOS - BOLIVAR</option>
              <option value="13549025">LOS LIMONES - PINILLOS - BOLIVAR</option>
              <option value="13549032">TAPOA - PINILLOS - BOLIVAR</option>
              <option value="13549034">LA UNION CABECERA - PINILLOS - BOLIVAR</option>
              <option value="13580000">REGIDOR - BOLIVAR</option>
              <option value="13580001">PIÑAL - REGIDOR - BOLIVAR</option>
              <option value="13580003">LOS CAIMANES - REGIDOR - BOLIVAR</option>
              <option value="13580004">SAN ANTONIO - REGIDOR - BOLIVAR</option>
              <option value="13580005">SAN CAYETANO - REGIDOR - BOLIVAR</option>
              <option value="13580006">SANTA LUCIA - REGIDOR - BOLIVAR</option>
              <option value="13580007">SANTA TERESA - REGIDOR - BOLIVAR</option>
              <option value="13600000">RIO VIEJO - BOLIVAR</option>
              <option value="13600007">CAIMITAL - RIO VIEJO - BOLIVAR</option>
              <option value="13600009">COBADILLO - RIO VIEJO - BOLIVAR</option>
              <option value="13600010">HATILLO - RIO VIEJO - BOLIVAR</option>
              <option value="13600011">MACEDONIA - RIO VIEJO - BOLIVAR</option>
              <option value="13600014">SIERPETUERTA - RIO VIEJO - BOLIVAR</option>
              <option value="13620000">SAN CRISTOBAL - BOLIVAR</option>
              <option value="13620001">HIGUERETAL - SAN CRISTOBAL - BOLIVAR</option>
              <option value="13620002">LAS CRUCES - SAN CRISTOBAL - BOLIVAR</option>
              <option value="13647000">SAN ESTANISLAO DE KOSTKA - SAN ESTANISLAO - BOLIVAR</option>
              <option value="13647002">LAS PIEDRAS - SAN ESTANISLAO - BOLIVAR</option>
              <option value="13650000">SAN FERNANDO - BOLIVAR</option>
              <option value="13650001">GUASIMAL - SAN FERNANDO - BOLIVAR</option>
              <option value="13650002">MENCHIQUEJO - SAN FERNANDO - BOLIVAR</option>
              <option value="13650004">PUNTA DE HORNOS - SAN FERNANDO - BOLIVAR</option>
              <option value="13650005">SANTA ROSA - SAN FERNANDO - BOLIVAR</option>
              <option value="13650006">EL PALMAR - SAN FERNANDO - BOLIVAR</option>
              <option value="13650008">EL PORVENIR - SAN FERNANDO - BOLIVAR</option>
              <option value="13650009">CUATRO BOCAS - SAN FERNANDO - BOLIVAR</option>
              <option value="13650010">EL CONTADERO - SAN FERNANDO - BOLIVAR</option>
              <option value="13650013">LA GUADUA - SAN FERNANDO - BOLIVAR</option>
              <option value="13650014">LAS CUEVAS - SAN FERNANDO - BOLIVAR</option>
              <option value="13650015">PAMPANILLO - SAN FERNANDO - BOLIVAR</option>
              <option value="13654000">SAN JACINTO - BOLIVAR</option>
              <option value="13654001">ARENAS - SAN JACINTO - BOLIVAR</option>
              <option value="13654002">BAJO GRANDE - SAN JACINTO - BOLIVAR</option>
              <option value="13654003">LAS PALMAS - SAN JACINTO - BOLIVAR</option>
              <option value="13654005">SAN CRISTOBAL - SAN JACINTO - BOLIVAR</option>
              <option value="13654006">LAS CHARQUITAS - SAN JACINTO - BOLIVAR</option>
              <option value="13654007">PARAISO - SAN JACINTO - BOLIVAR</option>
              <option value="13654008">LAS MERCEDES - SAN JACINTO - BOLIVAR</option>
              <option value="13655000">SAN JACINTO DEL CAUCA - BOLIVAR</option>
              <option value="13655001">TENCHE - SAN JACINTO DEL CAUCA - BOLIVAR</option>
              <option value="13655003">CAIMITAL - SAN JACINTO DEL CAUCA - BOLIVAR</option>
              <option value="13655004">LA RAYA - SAN JACINTO DEL CAUCA - BOLIVAR</option>
              <option value="13655005">GALINDO - SAN JACINTO DEL CAUCA - BOLIVAR</option>
              <option value="13655006">MEJICO - SAN JACINTO DEL CAUCA - BOLIVAR</option>
              <option value="13655007">ASTILLEROS - SAN JACINTO DEL CAUCA - BOLIVAR</option>
              <option value="13657000">SAN JUAN NEPOMUCENO - BOLIVAR</option>
              <option value="13657001">CORRALITO - SAN JUAN NEPOMUCENO - BOLIVAR</option>
              <option value="13657002">LA HAYA - SAN JUAN NEPOMUCENO - BOLIVAR</option>
              <option value="13657003">SAN JOSE DEL PEÑON (LAS PORQUERAS) - SAN JUAN NEPOMUCENO - BOLIVAR</option>
              <option value="13657004">SAN AGUSTIN - SAN JUAN NEPOMUCENO - BOLIVAR</option>
              <option value="13657005">SAN CAYETANO - SAN JUAN NEPOMUCENO - BOLIVAR</option>
              <option value="13657006">SAN PEDRO CONSOLADO - SAN JUAN NEPOMUCENO - BOLIVAR</option>
              <option value="13667000">SAN MARTIN DE LOBA - BOLIVAR</option>
              <option value="13667002">CHIMI - SAN MARTIN DE LOBA - BOLIVAR</option>
              <option value="13667009">PAPAYAL - SAN MARTIN DE LOBA - BOLIVAR</option>
              <option value="13667010">LAS PLAYITAS - SAN MARTIN DE LOBA - BOLIVAR</option>
              <option value="13667014">PUEBLO NUEVO CERRO DE JULIO - SAN MARTIN DE LOBA - BOLIVAR</option>
              <option value="13667015">EL JOBO - SAN MARTIN DE LOBA - BOLIVAR</option>
              <option value="13667016">EL VARAL - SAN MARTIN DE LOBA - BOLIVAR</option>
              <option value="13667017">LOS PUEBLOS - SAN MARTIN DE LOBA - BOLIVAR</option>
              <option value="13670000">SAN PABLO - BOLIVAR</option>
              <option value="13670002">CANALETAL - SAN PABLO - BOLIVAR</option>
              <option value="13670003">SANTO DOMINGO - SAN PABLO - BOLIVAR</option>
              <option value="13670004">EL CARMEN - SAN PABLO - BOLIVAR</option>
              <option value="13670005">EL SOCORRO - SAN PABLO - BOLIVAR</option>
              <option value="13670007">POZO AZUL - SAN PABLO - BOLIVAR</option>
              <option value="13670009">CAÑABRAVAL - SAN PABLO - BOLIVAR</option>
              <option value="13670010">AGUA SUCIA - SAN PABLO - BOLIVAR</option>
              <option value="13670011">CERRO AZUL - SAN PABLO - BOLIVAR</option>
              <option value="13670012">VALLECITO - SAN PABLO - BOLIVAR</option>
              <option value="13670013">VILLA NUEVA - SAN PABLO - BOLIVAR</option>
              <option value="13670014">LA VIRGENCITA - SAN PABLO - BOLIVAR</option>
              <option value="13670017">EL ROSARIO - SAN PABLO - BOLIVAR</option>
              <option value="13670018">LA FRIA - SAN PABLO - BOLIVAR</option>
              <option value="13670019">LA UNION - SAN PABLO - BOLIVAR</option>
              <option value="13670020">LOS CAGUISES - SAN PABLO - BOLIVAR</option>
              <option value="13670022">LA YE - SAN PABLO - BOLIVAR</option>
              <option value="13673000">SANTA CATALINA - BOLIVAR</option>
              <option value="13673003">GALERAZAMBA - SANTA CATALINA - BOLIVAR</option>
              <option value="13673005">LOMA DE ARENA - SANTA CATALINA - BOLIVAR</option>
              <option value="13673006">PUEBLO NUEVO - SANTA CATALINA - BOLIVAR</option>
              <option value="13673007">COLORADO - SANTA CATALINA - BOLIVAR</option>
              <option value="13673009">EL HOBO - SANTA CATALINA - BOLIVAR</option>
              <option value="13683000">SANTA ROSA DE LIMA - SANTA ROSA - BOLIVAR</option>
              <option value="13688000">SANTA ROSA DEL SUR - BOLIVAR</option>
              <option value="13688002">BUENAVISTA - SANTA ROSA DEL SUR - BOLIVAR</option>
              <option value="13688005">FATIMA - SANTA ROSA DEL SUR - BOLIVAR</option>
              <option value="13688007">CANELOS - SANTA ROSA DEL SUR - BOLIVAR</option>
              <option value="13688009">SAN JOSE - SANTA ROSA DEL SUR - BOLIVAR</option>
              <option value="13688011">SAN LUCAS - SANTA ROSA DEL SUR - BOLIVAR</option>
              <option value="13688013">VILLA FLOR - SANTA ROSA DEL SUR - BOLIVAR</option>
              <option value="13688015">SAN FRANCISCO - SANTA ROSA DEL SUR - BOLIVAR</option>
              <option value="13688016">SAN ISIDRO - SANTA ROSA DEL SUR - BOLIVAR</option>
              <option value="13688020">SAN BENITO - SANTA ROSA DEL SUR - BOLIVAR</option>
              <option value="13688021">SANTA LUCIA - SANTA ROSA DEL SUR - BOLIVAR</option>
              <option value="13744000">SIMITI - BOLIVAR</option>
              <option value="13744001">CAMPO PALLARES - SIMITI - BOLIVAR</option>
              <option value="13744006">VERACRUZ - SIMITI - BOLIVAR</option>
              <option value="13744007">SAN BLAS - SIMITI - BOLIVAR</option>
              <option value="13744008">SAN LUIS - SIMITI - BOLIVAR</option>
              <option value="13744010">LAS BRISAS - SIMITI - BOLIVAR</option>
              <option value="13744011">MONTERREY - SIMITI - BOLIVAR</option>
              <option value="13744013">ANIMAS ALTAS - SIMITI - BOLIVAR</option>
              <option value="13744014">ANIMAS BAJAS - SIMITI - BOLIVAR</option>
              <option value="13744015">DIAMANTE - SIMITI - BOLIVAR</option>
              <option value="13744016">GARZAL - SIMITI - BOLIVAR</option>
              <option value="13744017">PARAISO - SIMITI - BOLIVAR</option>
              <option value="13744019">SAN JOAQUIN - SIMITI - BOLIVAR</option>
              <option value="13744021">LAS ACEITUNAS - SIMITI - BOLIVAR</option>
              <option value="13744022">EL PIÑAL - SIMITI - BOLIVAR</option>
              <option value="13744023">LAS PALMERAS - SIMITI - BOLIVAR</option>
              <option value="13744024">PATA PELA - SIMITI - BOLIVAR</option>
              <option value="13744025">SABANA DE SAN LUIS - SIMITI - BOLIVAR</option>
              <option value="13760000">SOPLAVIENTO - BOLIVAR</option>
              <option value="13780000">TALAIGUA NUEVO - BOLIVAR</option>
              <option value="13780005">CAÑOHONDO - TALAIGUA NUEVO - BOLIVAR</option>
              <option value="13780009">PORVENIR - TALAIGUA NUEVO - BOLIVAR</option>
              <option value="13780011">VESUBIO - TALAIGUA NUEVO - BOLIVAR</option>
              <option value="13780017">PATICO - TALAIGUA NUEVO - BOLIVAR</option>
              <option value="13780023">TALAIGUA VIEJO - TALAIGUA NUEVO - BOLIVAR</option>
              <option value="13780024">LADERA DE SAN MARTIN - TALAIGUA NUEVO - BOLIVAR</option>
              <option value="13780025">PEÑON DE DURAN - TALAIGUA NUEVO - BOLIVAR</option>
              <option value="13780026">LOS MANGOS - TALAIGUA NUEVO - BOLIVAR</option>
              <option value="13780027">TUPE - TALAIGUA NUEVO - BOLIVAR</option>
              <option value="13810000">PUERTO RICO - TIQUISIO - BOLIVAR</option>
              <option value="13810002">BOCAS DE SOLIS - TIQUISIO - BOLIVAR</option>
              <option value="13810003">COLORADO - TIQUISIO - BOLIVAR</option>
              <option value="13810004">DOS BOCAS - TIQUISIO - BOLIVAR</option>
              <option value="13810005">EL SUDAN - TIQUISIO - BOLIVAR</option>
              <option value="13810006">LA VENTURA - TIQUISIO - BOLIVAR</option>
              <option value="13810008">PUERTO COCA - TIQUISIO - BOLIVAR</option>
              <option value="13810009">QUEBRADA DEL MEDIO - TIQUISIO - BOLIVAR</option>
              <option value="13810010">SABANAS DEL FIRME - TIQUISIO - BOLIVAR</option>
              <option value="13810011">TIQUISIO NUEVO - TIQUISIO - BOLIVAR</option>
              <option value="13810012">PUERTO GAITAN - TIQUISIO - BOLIVAR</option>
              <option value="13836000">TURBACO - BOLIVAR</option>
              <option value="13836001">CAÑAVERAL - TURBACO - BOLIVAR</option>
              <option value="13836002">SAN JOSE DE CHIQUITO - TURBACO - BOLIVAR</option>
              <option value="13836006">PUEBLO NUEVO - TURBACO - BOLIVAR</option>
              <option value="13836007">URBANIZACION VILLA DE CALATRAVA - TURBACO - BOLIVAR</option>
              <option value="13836008">URBANIZACION CAMPESTRE - TURBACO - BOLIVAR</option>
              <option value="13836009">URBANIZACION CATALINA - TURBACO - BOLIVAR</option>
              <option value="13836010">URBANIZACION ZAPOTE - TURBACO - BOLIVAR</option>
              <option value="13836011">CONDOMINIO HACIENDA - TURBACO - BOLIVAR</option>
              <option value="13838000">TURBANA - BOLIVAR</option>
              <option value="13838001">BALLESTAS - TURBANA - BOLIVAR</option>
              <option value="13838002">LOMAS DE MATUNILLA - TURBANA - BOLIVAR</option>
              <option value="13873000">VILLANUEVA - BOLIVAR</option>
              <option value="13873001">ZIPACOA - VILLANUEVA - BOLIVAR</option>
              <option value="13873002">ALGARROBO - VILLANUEVA - BOLIVAR</option>
              <option value="13894000">ZAMBRANO - BOLIVAR</option>
              <option value="13894002">CAPACA - ZAMBRANO - BOLIVAR</option>
              <option value="15001000">TUNJA - BOYACA</option>
              <option value="15022000">ALMEIDA - BOYACA</option>
              <option value="15047000">AQUITANIA - BOYACA</option>
              <option value="15047004">SAN JUAN DE MOMBITA - AQUITANIA - BOYACA</option>
              <option value="15047007">TOQUILLA - AQUITANIA - BOYACA</option>
              <option value="15047010">DAITO - AQUITANIA - BOYACA</option>
              <option value="15047012">PRIMAVERA - AQUITANIA - BOYACA</option>
              <option value="15047014">PEREZ - AQUITANIA - BOYACA</option>
              <option value="15051000">ARCABUCO - BOYACA</option>
              <option value="15087000">BELEN - BOYACA</option>
              <option value="15090000">BERBEO - BOYACA</option>
              <option value="15092000">BETEITIVA - BOYACA</option>
              <option value="15092001">OTENGA - BETEITIVA - BOYACA</option>
              <option value="15097000">BOAVITA - BOYACA</option>
              <option value="15104000">BOYACA - BOYACA</option>
              <option value="15106000">BRICEÑO - BOYACA</option>
              <option value="15109000">BUENAVISTA - BOYACA</option>
              <option value="15114000">BUSBANZA - BOYACA</option>
              <option value="15131000">CALDAS - BOYACA</option>
              <option value="15131001">NARIÑO - CALDAS - BOYACA</option>
              <option value="15135000">CAMPOHERMOSO - BOYACA</option>
              <option value="15135002">VISTAHERMOSA - CAMPOHERMOSO - BOYACA</option>
              <option value="15135003">LOS CEDROS - CAMPOHERMOSO - BOYACA</option>
              <option value="15162000">CERINZA - BOYACA</option>
              <option value="15172000">CHINAVITA - BOYACA</option>
              <option value="15176000">CHIQUINQUIRA - BOYACA</option>
              <option value="15180000">CHISCAS - BOYACA</option>
              <option value="15180005">MERCEDES - CHISCAS - BOYACA</option>
              <option value="15183000">CHITA - BOYACA</option>
              <option value="15185000">CHITARAQUE - BOYACA</option>
              <option value="15187000">CHIVATA - BOYACA</option>
              <option value="15189000">CIENEGA - BOYACA</option>
              <option value="15204000">COMBITA - BOYACA</option>
              <option value="15204001">EL BARNE - COMBITA - BOYACA</option>
              <option value="15204006">SAN ONOFRE - COMBITA - BOYACA</option>
              <option value="15212000">COPER - BOYACA</option>
              <option value="15215000">CORRALES - BOYACA</option>
              <option value="15218000">COVARACHIA - BOYACA</option>
              <option value="15223000">CUBARA - BOYACA</option>
              <option value="15223005">EL GUAMO - CUBARA - BOYACA</option>
              <option value="15223011">GIBRALTAR - CUBARA - BOYACA</option>
              <option value="15223012">PUENTE DE BOJABA - CUBARA - BOYACA</option>
              <option value="15224000">CUCAITA - BOYACA</option>
              <option value="15226000">CUITIVA - BOYACA</option>
              <option value="15226001">LLANO DE ALARCON - CUITIVA - BOYACA</option>
              <option value="15232000">SAN PEDRO DE IGUAQUE - CHIQUIZA - BOYACA</option>
              <option value="15232001">CHIQUIZA - BOYACA</option>
              <option value="15236000">CHIVOR - BOYACA</option>
              <option value="15238000">DUITAMA - BOYACA</option>
              <option value="15238008">SAN LORENZO ABAJO - DUITAMA - BOYACA</option>
              <option value="15238009">SAN ANTONIO NORTE - DUITAMA - BOYACA</option>
              <option value="15238011">LA TRINIDAD - DUITAMA - BOYACA</option>
              <option value="15238012">CIUDADELA INDUSTRIAL - DUITAMA - BOYACA</option>
              <option value="15238013">SANTA CLARA - DUITAMA - BOYACA</option>
              <option value="15238014">TOCOGUA - DUITAMA - BOYACA</option>
              <option value="15238015">PUEBLITO BOYACENSE - DUITAMA - BOYACA</option>
              <option value="15244000">EL COCUY - BOYACA</option>
              <option value="15248000">EL ESPINO - BOYACA</option>
              <option value="15272000">FIRAVITOBA - BOYACA</option>
              <option value="15276000">FLORESTA - BOYACA</option>
              <option value="15276001">TOBASIA - FLORESTA - BOYACA</option>
              <option value="15293000">GACHANTIVA - BOYACA</option>
              <option value="15296000">GAMEZA - BOYACA</option>
              <option value="15299000">GARAGOA - BOYACA</option>
              <option value="15317000">GUACAMAYAS - BOYACA</option>
              <option value="15322000">GUATEQUE - BOYACA</option>
              <option value="15325000">GUAYATA - BOYACA</option>
              <option value="15332000">GÜICAN DE LA SIERRA - BOYACA</option>
              <option value="15362000">IZA - BOYACA</option>
              <option value="15367000">JENESANO - BOYACA</option>
              <option value="15368000">JERICO - BOYACA</option>
              <option value="15368001">CHEVA - JERICO - BOYACA</option>
              <option value="15377000">LABRANZAGRANDE - BOYACA</option>
              <option value="15380000">LA CAPILLA - BOYACA</option>
              <option value="15401000">LA VICTORIA - BOYACA</option>
              <option value="15403000">LA UVITA - BOYACA</option>
              <option value="15403001">CUSAGÜI - LA UVITA - BOYACA</option>
              <option value="15407000">VILLA DE LEYVA - BOYACA</option>
              <option value="15407003">EL ROBLE - VILLA DE LEYVA - BOYACA</option>
              <option value="15425000">MACANAL - BOYACA</option>
              <option value="15425004">SAN PEDRO DE MUCEÑO - MACANAL - BOYACA</option>
              <option value="15442000">MARIPI - BOYACA</option>
              <option value="15442001">SANTA ROSA - MARIPI - BOYACA</option>
              <option value="15442002">ZULIA - MARIPI - BOYACA</option>
              <option value="15442008">GUARUMAL - MARIPI - BOYACA</option>
              <option value="15455000">MIRAFLORES - BOYACA</option>
              <option value="15464000">MONGUA - BOYACA</option>
              <option value="15466000">MONGUI - BOYACA</option>
              <option value="15469000">MONIQUIRA - BOYACA</option>
              <option value="15469007">LOS CAYENOS - MONIQUIRA - BOYACA</option>
              <option value="15476000">MOTAVITA - BOYACA</option>
              <option value="15480000">MUZO - BOYACA</option>
              <option value="15491000">NOBSA - BOYACA</option>
              <option value="15491001">BELENCITO - NOBSA - BOYACA</option>
              <option value="15491002">CHAMEZA MAYOR - NOBSA - BOYACA</option>
              <option value="15491003">DICHO - NOBSA - BOYACA</option>
              <option value="15491004">PUNTA LARGA - NOBSA - BOYACA</option>
              <option value="15491005">UCUENGA - NOBSA - BOYACA</option>
              <option value="15491006">CALERAS - NOBSA - BOYACA</option>
              <option value="15491007">NAZARETH - NOBSA - BOYACA</option>
              <option value="15491009">CHAMEZA MENOR - NOBSA - BOYACA</option>
              <option value="15491010">GUAQUIRA - NOBSA - BOYACA</option>
              <option value="15491012">SANTANA - NOBSA - BOYACA</option>
              <option value="15494000">NUEVO COLON - BOYACA</option>
              <option value="15500000">OICATA - BOYACA</option>
              <option value="15507000">OTANCHE - BOYACA</option>
              <option value="15507001">BETANIA - OTANCHE - BOYACA</option>
              <option value="15507002">BUENAVISTA - OTANCHE - BOYACA</option>
              <option value="15507004">PIZARRA - OTANCHE - BOYACA</option>
              <option value="15507009">SAN JOSE DE NAZARETH - OTANCHE - BOYACA</option>
              <option value="15507010">BUENOS AIRES - OTANCHE - BOYACA</option>
              <option value="15511000">PACHAVITA - BOYACA</option>
              <option value="15514000">PAEZ - BOYACA</option>
              <option value="15514001">LA URURIA - PAEZ - BOYACA</option>
              <option value="15514002">SIRASI - PAEZ - BOYACA</option>
              <option value="15516000">PAIPA - BOYACA</option>
              <option value="15516001">PALERMO - PAIPA - BOYACA</option>
              <option value="15516005">PANTANO DE VARGAS - PAIPA - BOYACA</option>
              <option value="15518000">PAJARITO - BOYACA</option>
              <option value="15518001">CORINTO - PAJARITO - BOYACA</option>
              <option value="15518002">CURISI - PAJARITO - BOYACA</option>
              <option value="15522000">PANQUEBA - BOYACA</option>
              <option value="15531000">PAUNA - BOYACA</option>
              <option value="15533000">PAYA - BOYACA</option>
              <option value="15533001">MORCOTE - PAYA - BOYACA</option>
              <option value="15537000">PAZ DE RIO - BOYACA</option>
              <option value="15537001">PAZ VIEJA - PAZ DE RIO - BOYACA</option>
              <option value="15542000">PESCA - BOYACA</option>
              <option value="15550000">PISBA - BOYACA</option>
              <option value="15572000">PUERTO BOYACA - BOYACA</option>
              <option value="15572001">GUANEGRO - PUERTO BOYACA - BOYACA</option>
              <option value="15572005">PUERTO GUTIERREZ - PUERTO BOYACA - BOYACA</option>
              <option value="15572006">CRUCE PALAGUA - PUERTO BOYACA - BOYACA</option>
              <option value="15572007">PUERTO SERVIEZ - PUERTO BOYACA - BOYACA</option>
              <option value="15572008">EL PESCADO - PUERTO BOYACA - BOYACA</option>
              <option value="15572009">KILOMETRO DOS Y MEDIO - PUERTO BOYACA - BOYACA</option>
              <option value="15572010">KILOMETRO 25 - PUERTO BOYACA - BOYACA</option>
              <option value="15572011">EL MARFIL - PUERTO BOYACA - BOYACA</option>
              <option value="15572012">PUERTO PINZON - PUERTO BOYACA - BOYACA</option>
              <option value="15572013">PUERTO ROMERO - PUERTO BOYACA - BOYACA</option>
              <option value="15572017">CRUCE EL CHAPARRO - PUERTO BOYACA - BOYACA</option>
              <option value="15572018">EL ERMITAÑO - PUERTO BOYACA - BOYACA</option>
              <option value="15572019">EL OKAL - PUERTO BOYACA - BOYACA</option>
              <option value="15572020">EL TRIQUE - PUERTO BOYACA - BOYACA</option>
              <option value="15572021">KILOMETRO 11 - PUERTO BOYACA - BOYACA</option>
              <option value="15572023">MUELLE VELASQUEZ - PUERTO BOYACA - BOYACA</option>
              <option value="15572024">PUERTO NIÑO - PUERTO BOYACA - BOYACA</option>
              <option value="15572026">KILOMETRO UNO Y MEDIO - PUERTO BOYACA - BOYACA</option>
              <option value="15572027">LA CEIBA - PUERTO BOYACA - BOYACA</option>
              <option value="15572028">PALAGUA SEGUNDO SECTOR - PUERTO BOYACA - BOYACA</option>
              <option value="15580000">QUIPAMA - BOYACA</option>
              <option value="15580001">CORMAL - QUIPAMA - BOYACA</option>
              <option value="15580002">EL PARQUE - QUIPAMA - BOYACA</option>
              <option value="15580003">HUMBO - QUIPAMA - BOYACA</option>
              <option value="15580005">EL MANGO (LA YE) - QUIPAMA - BOYACA</option>
              <option value="15599000">RAMIRIQUI - BOYACA</option>
              <option value="15599001">GUAYABAL (FATIMA) - RAMIRIQUI - BOYACA</option>
              <option value="15599004">EL ESCOBAL - RAMIRIQUI - BOYACA</option>
              <option value="15599005">SAN ANTONIO - RAMIRIQUI - BOYACA</option>
              <option value="15599006">VILLA TOSCANA - RAMIRIQUI - BOYACA</option>
              <option value="15600000">RAQUIRA - BOYACA</option>
              <option value="15600002">LA CANDELARIA - RAQUIRA - BOYACA</option>
              <option value="15621000">RONDON - BOYACA</option>
              <option value="15621001">RANCHOGRANDE - RONDON - BOYACA</option>
              <option value="15632000">SABOYA - BOYACA</option>
              <option value="15632001">GARAVITO - SABOYA - BOYACA</option>
              <option value="15638000">SACHICA - BOYACA</option>
              <option value="15646000">SAMACA - BOYACA</option>
              <option value="15646001">LA CUMBRE - SAMACA - BOYACA</option>
              <option value="15646002">LA FABRICA - SAMACA - BOYACA</option>
              <option value="15660000">SAN EDUARDO - BOYACA</option>
              <option value="15664000">SAN JOSE DE PARE - BOYACA</option>
              <option value="15667000">SAN LUIS DE GACENO - BOYACA</option>
              <option value="15667001">SANTA TERESA - SAN LUIS DE GACENO - BOYACA</option>
              <option value="15667002">GUAMAL - SAN LUIS DE GACENO - BOYACA</option>
              <option value="15667003">HORIZONTES - SAN LUIS DE GACENO - BOYACA</option>
              <option value="15667004">LA MESA DEL GUAVIO - SAN LUIS DE GACENO - BOYACA</option>
              <option value="15667005">SAN CARLOS DEL GUAVIO - SAN LUIS DE GACENO - BOYACA</option>
              <option value="15667006">LA FRONTERA (CORREDOR VIAL) - SAN LUIS DE GACENO - BOYACA</option>
              <option value="15673000">SAN MATEO - BOYACA</option>
              <option value="15676000">SAN MIGUEL DE SEMA - BOYACA</option>
              <option value="15681000">SAN PABLO DE BORBUR - BOYACA</option>
              <option value="15681005">SANTA BARBARA - SAN PABLO DE BORBUR - BOYACA</option>
              <option value="15681006">SAN MARTIN - SAN PABLO DE BORBUR - BOYACA</option>
              <option value="15681007">COSCUEZ - SAN PABLO DE BORBUR - BOYACA</option>
              <option value="15686000">SANTANA - BOYACA</option>
              <option value="15686002">CASABLANCA - SANTANA - BOYACA</option>
              <option value="15686003">MATEGUADUA - SANTANA - BOYACA</option>
              <option value="15690000">SANTA MARIA - BOYACA</option>
              <option value="15693000">SANTA ROSA DE VITERBO - BOYACA</option>
              <option value="15693002">EL IMPERIO - SANTA ROSA DE VITERBO - BOYACA</option>
              <option value="15696000">SANTA SOFIA - BOYACA</option>
              <option value="15720000">SATIVANORTE - BOYACA</option>
              <option value="15720001">SATIVA VIEJO - SATIVANORTE - BOYACA</option>
              <option value="15723000">SATIVASUR - BOYACA</option>
              <option value="15740000">SIACHOQUE - BOYACA</option>
              <option value="15753000">SOATA - BOYACA</option>
              <option value="15755000">SOCOTA - BOYACA</option>
              <option value="15755004">LOS PINOS - SOCOTA - BOYACA</option>
              <option value="15757000">SOCHA - BOYACA</option>
              <option value="15757001">SANTA TERESA - SOCHA - BOYACA</option>
              <option value="15757003">SOCHA VIEJO - SOCHA - BOYACA</option>
              <option value="15759000">SOGAMOSO - BOYACA</option>
              <option value="15759001">MORCA - SOGAMOSO - BOYACA</option>
              <option value="15759003">VANEGAS - SOGAMOSO - BOYACA</option>
              <option value="15759005">EL CRUCERO - SOGAMOSO - BOYACA</option>
              <option value="15759006">ALCAPARRAL - SOGAMOSO - BOYACA</option>
              <option value="15759007">MILAGRO Y PLAYITA - SOGAMOSO - BOYACA</option>
              <option value="15761000">SOMONDOCO - BOYACA</option>
              <option value="15762000">SORA - BOYACA</option>
              <option value="15763000">SOTAQUIRA - BOYACA</option>
              <option value="15763002">BOSIGAS - SOTAQUIRA - BOYACA</option>
              <option value="15763003">CARREÑO - SOTAQUIRA - BOYACA</option>
              <option value="15764000">SORACA - BOYACA</option>
              <option value="15774000">SUSACON - BOYACA</option>
              <option value="15776000">SUTAMARCHAN - BOYACA</option>
              <option value="15778000">SUTATENZA - BOYACA</option>
              <option value="15790000">TASCO - BOYACA</option>
              <option value="15790001">LIBERTADORES - TASCO - BOYACA</option>
              <option value="15790002">LA CHAPA - TASCO - BOYACA</option>
              <option value="15790003">EL CASTILLO - TASCO - BOYACA</option>
              <option value="15798000">TENZA - BOYACA</option>
              <option value="15804000">TIBANA - BOYACA</option>
              <option value="15806000">TIBASOSA - BOYACA</option>
              <option value="15806002">EL PARAISO - TIBASOSA - BOYACA</option>
              <option value="15806003">SANTA TERESA - TIBASOSA - BOYACA</option>
              <option value="15808000">TINJACA - BOYACA</option>
              <option value="15810000">TIPACOQUE - BOYACA</option>
              <option value="15810005">JEQUE - TIPACOQUE - BOYACA</option>
              <option value="15814000">TOCA - BOYACA</option>
              <option value="15816000">TOGÜI - BOYACA</option>
              <option value="15820000">TOPAGA - BOYACA</option>
              <option value="15820001">BADO CASTRO - TOPAGA - BOYACA</option>
              <option value="15822000">TOTA - BOYACA</option>
              <option value="15832000">TUNUNGUA - BOYACA</option>
              <option value="15835000">TURMEQUE - BOYACA</option>
              <option value="15837000">TUTA - BOYACA</option>
              <option value="15839000">TUTAZA - BOYACA</option>
              <option value="15839004">LA CAPILLA - TUTAZA - BOYACA</option>
              <option value="15842000">UMBITA - BOYACA</option>
              <option value="15861000">VENTAQUEMADA - BOYACA</option>
              <option value="15861001">PARROQUIA VIEJA - VENTAQUEMADA - BOYACA</option>
              <option value="15861002">CASA VERDE - VENTAQUEMADA - BOYACA</option>
              <option value="15861005">MONTOYA - VENTAQUEMADA - BOYACA</option>
              <option value="15861006">ESTANCIA GRANDE - VENTAQUEMADA - BOYACA</option>
              <option value="15861007">PUENTE BOYACA - VENTAQUEMADA - BOYACA</option>
              <option value="15861008">TIERRA NEGRA - VENTAQUEMADA - BOYACA</option>
              <option value="15861009">EL CARPI - VENTAQUEMADA - BOYACA</option>
              <option value="15861010">EL MANZANO - VENTAQUEMADA - BOYACA</option>
              <option value="15879000">VIRACACHA - BOYACA</option>
              <option value="15897000">ZETAQUIRA - BOYACA</option>
              <option value="17001000">MANIZALES - CALDAS</option>
              <option value="17001001">ALTO DE LISBOA - MANIZALES - CALDAS</option>
              <option value="17001002">KILOMETRO 41 - COLOMBIA - MANIZALES - CALDAS</option>
              <option value="17001003">BAJO TABLAZO - MANIZALES - CALDAS</option>
              <option value="17001004">LA CABAÑA - MANIZALES - CALDAS</option>
              <option value="17001005">LA CUCHILLA DEL SALADO - MANIZALES - CALDAS</option>
              <option value="17001008">LAS PAVAS - MANIZALES - CALDAS</option>
              <option value="17001009">SAN PEREGRINO - MANIZALES - CALDAS</option>
              <option value="17001010">ALTO TABLAZO - MANIZALES - CALDAS</option>
              <option value="17001011">ALTO DEL NARANJO - MANIZALES - CALDAS</option>
              <option value="17001012">EL ARENILLO - MANIZALES - CALDAS</option>
              <option value="17001015">LA AURORA - MANIZALES - CALDAS</option>
              <option value="17001022">ALTO BONITO - MANIZALES - CALDAS</option>
              <option value="17001023">MINA RICA - MANIZALES - CALDAS</option>
              <option value="17001024">LA GARRUCHA - MANIZALES - CALDAS</option>
              <option value="17001029">AGUA BONITA - MANIZALES - CALDAS</option>
              <option value="17001034">EL AGUILA - MANIZALES - CALDAS</option>
              <option value="17001043">BUENA VISTA - MANIZALES - CALDAS</option>
              <option value="17001044">CONDOMINIO PORTAL DE LOS CEREZOS - MANIZALES - CALDAS</option>
              <option value="17001045">CONDOMINIO RESERVA DE LOS ALAMOS - MANIZALES - CALDAS</option>
              <option value="17001046">CONDOMINIO SAN BERNARDO DEL VIENTO - MANIZALES - CALDAS</option>
              <option value="17001047">EL NARANJO - MANIZALES - CALDAS</option>
              <option value="17013000">AGUADAS - CALDAS</option>
              <option value="17013001">ARMA - AGUADAS - CALDAS</option>
              <option value="17013004">LA MERMITA - AGUADAS - CALDAS</option>
              <option value="17013010">EDEN - AGUADAS - CALDAS</option>
              <option value="17013011">SAN NICOLAS - AGUADAS - CALDAS</option>
              <option value="17013012">ALTO DE PITO - AGUADAS - CALDAS</option>
              <option value="17013014">ALTO DE LA MONTAÑA - AGUADAS - CALDAS</option>
              <option value="17013015">BOCAS - AGUADAS - CALDAS</option>
              <option value="17013016">VIBORAL - AGUADAS - CALDAS</option>
              <option value="17013017">PORE - AGUADAS - CALDAS</option>
              <option value="17042000">ANSERMA - CALDAS</option>
              <option value="17042004">MARAPRA - ANSERMA - CALDAS</option>
              <option value="17042007">SAN PEDRO - ANSERMA - CALDAS</option>
              <option value="17050000">ARANZAZU - CALDAS</option>
              <option value="17050006">SAN RAFAEL - ARANZAZU - CALDAS</option>
              <option value="17050012">LA HONDA - ARANZAZU - CALDAS</option>
              <option value="17088000">BELALCAZAR - CALDAS</option>
              <option value="17088001">EL MADROÑO - BELALCAZAR - CALDAS</option>
              <option value="17088002">LA HABANA - BELALCAZAR - CALDAS</option>
              <option value="17088003">SAN ISIDRO - BELALCAZAR - CALDAS</option>
              <option value="17088008">ASENTAMIENTO INDIGENA TOTUMAL - BELALCAZAR - CALDAS</option>
              <option value="17174000">CHINCHINA - CALDAS</option>
              <option value="17174001">EL TREBOL - CHINCHINA - CALDAS</option>
              <option value="17174002">LA FLORESTA - CHINCHINA - CALDAS</option>
              <option value="17174006">ALTO DE LA MINA - CHINCHINA - CALDAS</option>
              <option value="17174007">LA QUIEBRA DEL NARANJAL - CHINCHINA - CALDAS</option>
              <option value="17174011">LA ESTRELLA - CHINCHINA - CALDAS</option>
              <option value="17174012">EL REPOSO - CHINCHINA - CALDAS</option>
              <option value="17174015">SAN ANDRES - CHINCHINA - CALDAS</option>
              <option value="17272000">FILADELFIA - CALDAS</option>
              <option value="17272002">EL VERSO - FILADELFIA - CALDAS</option>
              <option value="17272003">LA PAILA - FILADELFIA - CALDAS</option>
              <option value="17272005">SAMARIA - FILADELFIA - CALDAS</option>
              <option value="17272007">SAN LUIS - FILADELFIA - CALDAS</option>
              <option value="17272008">BALMORAL - FILADELFIA - CALDAS</option>
              <option value="17272009">LA MARINA - FILADELFIA - CALDAS</option>
              <option value="17380000">LA DORADA - CALDAS</option>
              <option value="17380001">BUENAVISTA - LA DORADA - CALDAS</option>
              <option value="17380002">GUARINOCITO - LA DORADA - CALDAS</option>
              <option value="17380003">PURNIO - LA DORADA - CALDAS</option>
              <option value="17380004">LA ATARRAYA - LA DORADA - CALDAS</option>
              <option value="17380007">CAMELIAS - LA DORADA - CALDAS</option>
              <option value="17380008">DOÑA JUANA - LA DORADA - CALDAS</option>
              <option value="17380009">HORIZONTE - LA DORADA - CALDAS</option>
              <option value="17380010">LA AGUSTINA - LA DORADA - CALDAS</option>
              <option value="17380012">LA HABANA - LA DORADA - CALDAS</option>
              <option value="17380015">PROSOCIAL LA HUMAREDA - LA DORADA - CALDAS</option>
              <option value="17388000">LA MERCED - CALDAS</option>
              <option value="17388001">EL LIMON - LA MERCED - CALDAS</option>
              <option value="17388002">LA FELISA - LA MERCED - CALDAS</option>
              <option value="17388007">LLANADAS - LA MERCED - CALDAS</option>
              <option value="17388008">SAN JOSE - LA MERCED - CALDAS</option>
              <option value="17388009">EL TAMBOR - LA MERCED - CALDAS</option>
              <option value="17433000">MANZANARES - CALDAS</option>
              <option value="17433001">AGUABONITA - MANZANARES - CALDAS</option>
              <option value="17433003">LA CEIBA - MANZANARES - CALDAS</option>
              <option value="17433004">LAS MARGARITAS - MANZANARES - CALDAS</option>
              <option value="17433005">LOS PLANES - MANZANARES - CALDAS</option>
              <option value="17442000">MARMATO - CALDAS</option>
              <option value="17442001">CABRAS - MARMATO - CALDAS</option>
              <option value="17442002">EL LLANO - MARMATO - CALDAS</option>
              <option value="17442003">SAN JUAN - MARMATO - CALDAS</option>
              <option value="17442004">LA MIEL - MARMATO - CALDAS</option>
              <option value="17442005">LA CUCHILLA - MARMATO - CALDAS</option>
              <option value="17442008">JIMENEZ ALTO - MARMATO - CALDAS</option>
              <option value="17442009">JIMENEZ BAJO - MARMATO - CALDAS</option>
              <option value="17442012">LOAIZA - MARMATO - CALDAS</option>
              <option value="17442013">EL GUAYABITO - MARMATO - CALDAS</option>
              <option value="17442014">LA PORTADA - MARMATO - CALDAS</option>
              <option value="17442015">LA QUEBRADA - MARMATO - CALDAS</option>
              <option value="17444000">MARQUETALIA - CALDAS</option>
              <option value="17444001">SANTA ELENA - MARQUETALIA - CALDAS</option>
              <option value="17446000">MARULANDA - CALDAS</option>
              <option value="17446001">MONTEBONITO - MARULANDA - CALDAS</option>
              <option value="17486000">NEIRA - CALDAS</option>
              <option value="17486004">PUEBLO RICO - NEIRA - CALDAS</option>
              <option value="17486005">TAPIAS - NEIRA - CALDAS</option>
              <option value="17486009">BARRIO MEDELLIN - NEIRA - CALDAS</option>
              <option value="17486010">LA ISLA - NEIRA - CALDAS</option>
              <option value="17486011">AGROVILLA - NEIRA - CALDAS</option>
              <option value="17486012">SAN JOSE - NEIRA - CALDAS</option>
              <option value="17486013">JUNTAS - NEIRA - CALDAS</option>
              <option value="17495000">NORCASIA - CALDAS</option>
              <option value="17495002">LA QUIEBRA - NORCASIA - CALDAS</option>
              <option value="17495003">MONTEBELLO - NORCASIA - CALDAS</option>
              <option value="17495004">MOSCOVITA - NORCASIA - CALDAS</option>
              <option value="17513000">PACORA - CALDAS</option>
              <option value="17513001">CASTILLA - PACORA - CALDAS</option>
              <option value="17513002">LAS COLES - PACORA - CALDAS</option>
              <option value="17513003">SAN BARTOLOME - PACORA - CALDAS</option>
              <option value="17513008">LOMA HERMOSA - PACORA - CALDAS</option>
              <option value="17513011">PALMA ALTA - PACORA - CALDAS</option>
              <option value="17513012">BUENOS AIRES - PACORA - CALDAS</option>
              <option value="17513013">EL MORRO - PACORA - CALDAS</option>
              <option value="17513016">LOS CAMBULOS - PACORA - CALDAS</option>
              <option value="17524000">PALESTINA - CALDAS</option>
              <option value="17524001">ARAUCA - PALESTINA - CALDAS</option>
              <option value="17524002">EL JARDIN (REPOSO) - PALESTINA - CALDAS</option>
              <option value="17524003">LA PLATA - PALESTINA - CALDAS</option>
              <option value="17524005">CARTAGENA - PALESTINA - CALDAS</option>
              <option value="17524009">LA BASTILLA - PALESTINA - CALDAS</option>
              <option value="17541000">PENSILVANIA - CALDAS</option>
              <option value="17541001">ARBOLEDA - PENSILVANIA - CALDAS</option>
              <option value="17541002">BOLIVIA - PENSILVANIA - CALDAS</option>
              <option value="17541004">EL HIGUERON - PENSILVANIA - CALDAS</option>
              <option value="17541006">LA LINDA - PENSILVANIA - CALDAS</option>
              <option value="17541008">LA RIOJA - PENSILVANIA - CALDAS</option>
              <option value="17541009">PUEBLONUEVO - PENSILVANIA - CALDAS</option>
              <option value="17541011">SAN DANIEL - PENSILVANIA - CALDAS</option>
              <option value="17541015">LA SOLEDAD ALTA - PENSILVANIA - CALDAS</option>
              <option value="17541016">AGUABONITA - PENSILVANIA - CALDAS</option>
              <option value="17614000">RIOSUCIO - CALDAS</option>
              <option value="17614001">BONAFONT - RIOSUCIO - CALDAS</option>
              <option value="17614003">EL SALADO - RIOSUCIO - CALDAS</option>
              <option value="17614004">FLORENCIA - RIOSUCIO - CALDAS</option>
              <option value="17614005">QUIEBRALOMO - RIOSUCIO - CALDAS</option>
              <option value="17614006">SAN LORENZO - RIOSUCIO - CALDAS</option>
              <option value="17614008">IBERIA - RIOSUCIO - CALDAS</option>
              <option value="17614010">SIPIRRA - RIOSUCIO - CALDAS</option>
              <option value="17614014">SAN JERONIMO - RIOSUCIO - CALDAS</option>
              <option value="17614017">PUEBLO VIEJO - RIOSUCIO - CALDAS</option>
              <option value="17614021">LAS ESTANCIAS - RIOSUCIO - CALDAS</option>
              <option value="17614022">LA PLAYA - IMURRA - RIOSUCIO - CALDAS</option>
              <option value="17614023">TUMBABARRETO - RIOSUCIO - CALDAS</option>
              <option value="17614024">AGUAS CLARAS - RIOSUCIO - CALDAS</option>
              <option value="17616000">RISARALDA - CALDAS</option>
              <option value="17616004">QUIEBRA SANTA BARBARA - RISARALDA - CALDAS</option>
              <option value="17616008">QUIEBRA VARILLAS - RISARALDA - CALDAS</option>
              <option value="17616011">CALLE LARGA - RISARALDA - CALDAS</option>
              <option value="17653000">SALAMINA - CALDAS</option>
              <option value="17653004">VEREDA LA UNION - SALAMINA - CALDAS</option>
              <option value="17653007">SAN FELIX - SALAMINA - CALDAS</option>
              <option value="17653023">LA LOMA - SALAMINA - CALDAS</option>
              <option value="17662000">SAMANA - CALDAS</option>
              <option value="17662001">BERLIN - SAMANA - CALDAS</option>
              <option value="17662003">FLORENCIA - SAMANA - CALDAS</option>
              <option value="17662004">ENCIMADAS - SAMANA - CALDAS</option>
              <option value="17662005">LOS POMOS - SAMANA - CALDAS</option>
              <option value="17662007">SAN DIEGO - SAMANA - CALDAS</option>
              <option value="17662008">RANCHOLARGO - SAMANA - CALDAS</option>
              <option value="17662017">PUEBLO NUEVO - SAMANA - CALDAS</option>
              <option value="17662018">DULCE NOMBRE - SAMANA - CALDAS</option>
              <option value="17665000">SAN JOSE - CALDAS</option>
              <option value="17665002">PRIMAVERA ALTA - SAN JOSE - CALDAS</option>
              <option value="17665004">CONDOMINIOS VALLES DE ACAPULCO Y LOS SEIS Y PUNTO - SAN JOSE - CALDAS</option>
              <option value="17777000">SUPIA - CALDAS</option>
              <option value="17777003">LA QUINTA - SUPIA - CALDAS</option>
              <option value="17777005">HOJAS ANCHAS - SUPIA - CALDAS</option>
              <option value="17777008">GUAMAL - SUPIA - CALDAS</option>
              <option value="17777010">PUERTO NUEVO - SUPIA - CALDAS</option>
              <option value="17777011">PALMA SOLA - SUPIA - CALDAS</option>
              <option value="17867000">VICTORIA - CALDAS</option>
              <option value="17867001">CAÑAVERAL - VICTORIA - CALDAS</option>
              <option value="17867003">ISAZA - VICTORIA - CALDAS</option>
              <option value="17867004">LA PRADERA - VICTORIA - CALDAS</option>
              <option value="17867005">EL LLANO - VICTORIA - CALDAS</option>
              <option value="17867007">LA FE - VICTORIA - CALDAS</option>
              <option value="17867008">SAN MATEO - VICTORIA - CALDAS</option>
              <option value="17867009">VILLA ESPERANZA - VICTORIA - CALDAS</option>
              <option value="17873000">VILLAMARIA - CALDAS</option>
              <option value="17873001">ALTO DE LA CRUZ - LOS CUERVOS - VILLAMARIA - CALDAS</option>
              <option value="17873003">LLANITOS - VILLAMARIA - CALDAS</option>
              <option value="17873004">RIOCLARO - VILLAMARIA - CALDAS</option>
              <option value="17873006">SAN JULIAN - VILLAMARIA - CALDAS</option>
              <option value="17873007">MIRAFLORES - VILLAMARIA - CALDAS</option>
              <option value="17873008">ALTO VILLARAZO - VILLAMARIA - CALDAS</option>
              <option value="17873010">GALLINAZO - VILLAMARIA - CALDAS</option>
              <option value="17873011">LA NUEVA PRIMAVERA - VILLAMARIA - CALDAS</option>
              <option value="17873013">BELLAVISTA - VILLAMARIA - CALDAS</option>
              <option value="17873014">LA FLORESTA - VILLAMARIA - CALDAS</option>
              <option value="17873016">COROZAL - VILLAMARIA - CALDAS</option>
              <option value="17873017">PARTIDAS - VILLAMARIA - CALDAS</option>
              <option value="17873018">GRANJA AGRICOLA LA PAZ - VILLAMARIA - CALDAS</option>
              <option value="17873019">NUEVO RIO CLARO - VILLAMARIA - CALDAS</option>
              <option value="17877000">VITERBO - CALDAS</option>
              <option value="18001000">FLORENCIA - CAQUETA</option>
              <option value="18001004">SAN ANTONIO DE ATENAS - FLORENCIA - CAQUETA</option>
              <option value="18001005">SANTANA LAS HERMOSAS - FLORENCIA - CAQUETA</option>
              <option value="18001006">LA ESPERANZA - FLORENCIA - CAQUETA</option>
              <option value="18001007">NORCACIA - FLORENCIA - CAQUETA</option>
              <option value="18001008">VENECIA - FLORENCIA - CAQUETA</option>
              <option value="18001009">EL PARA - FLORENCIA - CAQUETA</option>
              <option value="18001011">CARAÑO - FLORENCIA - CAQUETA</option>
              <option value="18001018">CAPITOLIO - FLORENCIA - CAQUETA</option>
              <option value="18001024">PUERTO ARANGO - FLORENCIA - CAQUETA</option>
              <option value="18001025">SEBASTOPOL - FLORENCIA - CAQUETA</option>
              <option value="18029000">ALBANIA - CAQUETA</option>
              <option value="18029003">DORADO - ALBANIA - CAQUETA</option>
              <option value="18029004">VERSALLES - ALBANIA - CAQUETA</option>
              <option value="18029005">EL PARAISO - ALBANIA - CAQUETA</option>
              <option value="18094000">BELEN DE LOS ANDAQUIES - CAQUETA</option>
              <option value="18094001">EL PORTAL LA MONO - BELEN DE LOS ANDAQUIES - CAQUETA</option>
              <option value="18094003">PUERTO TORRES - BELEN DE LOS ANDAQUIES - CAQUETA</option>
              <option value="18094005">PUEBLO NUEVO LOS ANGELES - BELEN DE LOS ANDAQUIES - CAQUETA</option>
              <option value="18094008">ALETONES - BELEN DE LOS ANDAQUIES - CAQUETA</option>
              <option value="18094009">SAN ANTONIO DE PADUA - BELEN DE LOS ANDAQUIES - CAQUETA</option>
              <option value="18150000">CARTAGENA DEL CHAIRA - CAQUETA</option>
              <option value="18150001">REMOLINO DEL CAGUAN - CARTAGENA DEL CHAIRA - CAQUETA</option>
              <option value="18150002">SANTA FE DEL CAGUAN - CARTAGENA DEL CHAIRA - CAQUETA</option>
              <option value="18150003">MONSERRATE - CARTAGENA DEL CHAIRA - CAQUETA</option>
              <option value="18150004">PEÑAS COLORADAS - CARTAGENA DEL CHAIRA - CAQUETA</option>
              <option value="18150005">EL GUAMO - CARTAGENA DEL CHAIRA - CAQUETA</option>
              <option value="18150006">PUERTO CAMELIA - CARTAGENA DEL CHAIRA - CAQUETA</option>
              <option value="18150007">SANTO DOMINGO - CARTAGENA DEL CHAIRA - CAQUETA</option>
              <option value="18150008">LOS CRISTALES - CARTAGENA DEL CHAIRA - CAQUETA</option>
              <option value="18150009">RISARALDA - CARTAGENA DEL CHAIRA - CAQUETA</option>
              <option value="18150011">CUMARALES - CARTAGENA DEL CHAIRA - CAQUETA</option>
              <option value="18150012">EL CAFE - CARTAGENA DEL CHAIRA - CAQUETA</option>
              <option value="18150013">NAPOLES (PUERTO NAPOLES) - CARTAGENA DEL CHAIRA - CAQUETA</option>
              <option value="18150014">PEÑAS BLANCAS - CARTAGENA DEL CHAIRA - CAQUETA</option>
              <option value="18150015">LAS ANIMAS - CARTAGENA DEL CHAIRA - CAQUETA</option>
              <option value="18205000">CURILLO - CAQUETA</option>
              <option value="18205001">SALAMINA - CURILLO - CAQUETA</option>
              <option value="18205002">NOVIA PUERTO VALDIVIA - CURILLO - CAQUETA</option>
              <option value="18205003">PALIZADAS - CURILLO - CAQUETA</option>
              <option value="18247000">EL DONCELLO - CAQUETA</option>
              <option value="18247001">MAGUARE - EL DONCELLO - CAQUETA</option>
              <option value="18247002">PUERTO MANRIQUE - EL DONCELLO - CAQUETA</option>
              <option value="18247004">PUERTO HUNGRIA - EL DONCELLO - CAQUETA</option>
              <option value="18247005">RIONEGRO - EL DONCELLO - CAQUETA</option>
              <option value="18256000">EL PAUJIL - CAQUETA</option>
              <option value="18256001">VERSALLES - EL PAUJIL - CAQUETA</option>
              <option value="18256002">BOLIVIA - EL PAUJIL - CAQUETA</option>
              <option value="18410000">LA MONTAÑITA - CAQUETA</option>
              <option value="18410001">SANTUARIO - LA MONTAÑITA - CAQUETA</option>
              <option value="18410002">LA UNION PENEYA - LA MONTAÑITA - CAQUETA</option>
              <option value="18410005">EL TRIUNFO - LA MONTAÑITA - CAQUETA</option>
              <option value="18410006">MATEGUADUA - LA MONTAÑITA - CAQUETA</option>
              <option value="18410007">SAN ISIDRO - LA MONTAÑITA - CAQUETA</option>
              <option value="18410008">MIRAMAR - LA MONTAÑITA - CAQUETA</option>
              <option value="18410009">PUERTO BRASILIA - LA MONTAÑITA - CAQUETA</option>
              <option value="18410010">PUERTO GAITAN - LA MONTAÑITA - CAQUETA</option>
              <option value="18410012">REINA BAJA - LA MONTAÑITA - CAQUETA</option>
              <option value="18410013">PALMERAS - LA MONTAÑITA - CAQUETA</option>
              <option value="18410014">EL BERLIN - LA MONTAÑITA - CAQUETA</option>
              <option value="18460000">MILAN - CAQUETA</option>
              <option value="18460001">SAN ANTONIO DE GETUCHA - MILAN - CAQUETA</option>
              <option value="18460003">MATICURU - GRANARIO - MILAN - CAQUETA</option>
              <option value="18460004">LA RASTRA - MILAN - CAQUETA</option>
              <option value="18460005">REMOLINOS DE ARICUNTI - MILAN - CAQUETA</option>
              <option value="18460008">AGUA BLANCA - MILAN - CAQUETA</option>
              <option value="18460010">AGUA NEGRA - MILAN - CAQUETA</option>
              <option value="18479000">MORELIA - CAQUETA</option>
              <option value="18592000">PUERTO RICO - CAQUETA</option>
              <option value="18592003">LUSITANIA - PUERTO RICO - CAQUETA</option>
              <option value="18592004">SANTANA RAMOS - PUERTO RICO - CAQUETA</option>
              <option value="18592005">LA ESMERALDA - PUERTO RICO - CAQUETA</option>
              <option value="18592006">LA AGUILILLA - PUERTO RICO - CAQUETA</option>
              <option value="18610000">SAN JOSE DEL FRAGUA - CAQUETA</option>
              <option value="18610001">FRAGUITA - SAN JOSE DEL FRAGUA - CAQUETA</option>
              <option value="18610002">YURAYACO - SAN JOSE DEL FRAGUA - CAQUETA</option>
              <option value="18610003">SABALETA - SAN JOSE DEL FRAGUA - CAQUETA</option>
              <option value="18753000">SAN VICENTE DEL CAGUAN - CAQUETA</option>
              <option value="18753002">GUACAMAYAS - SAN VICENTE DEL CAGUAN - CAQUETA</option>
              <option value="18753003">BALSILLAS - SAN VICENTE DEL CAGUAN - CAQUETA</option>
              <option value="18753004">CAMPO HERMOSO - SAN VICENTE DEL CAGUAN - CAQUETA</option>
              <option value="18753007">TRES ESQUINAS - SAN VICENTE DEL CAGUAN - CAQUETA</option>
              <option value="18753009">PUERTO BETANIA - SAN VICENTE DEL CAGUAN - CAQUETA</option>
              <option value="18753010">GIBRALTAR - SAN VICENTE DEL CAGUAN - CAQUETA</option>
              <option value="18753011">LOS POZOS - SAN VICENTE DEL CAGUAN - CAQUETA</option>
              <option value="18753012">SANTA ROSA - SAN VICENTE DEL CAGUAN - CAQUETA</option>
              <option value="18753013">TRONCALES - SAN VICENTE DEL CAGUAN - CAQUETA</option>
              <option value="18753015">GUAYABAL - SAN VICENTE DEL CAGUAN - CAQUETA</option>
              <option value="18753023">LA TUNIA - SAN VICENTE DEL CAGUAN - CAQUETA</option>
              <option value="18753029">LA CAMPANA - SAN VICENTE DEL CAGUAN - CAQUETA</option>
              <option value="18753031">LOS ANDES - SAN VICENTE DEL CAGUAN - CAQUETA</option>
              <option value="18753032">PUERTO AMOR - SAN VICENTE DEL CAGUAN - CAQUETA</option>
              <option value="18753033">VILLA LOBOS - SAN VICENTE DEL CAGUAN - CAQUETA</option>
              <option value="18753034">VILLA CARMONA - SAN VICENTE DEL CAGUAN - CAQUETA</option>
              <option value="18756000">SOLANO - CAQUETA</option>
              <option value="18756001">ARARACUARA - SOLANO - CAQUETA</option>
              <option value="18756004">EL DANUBIO - CAMPO ALEGRE - SOLANO - CAQUETA</option>
              <option value="18756005">PEÑAS BLANCAS - SOLANO - CAQUETA</option>
              <option value="18756006">CUEMANI - SOLANO - CAQUETA</option>
              <option value="18756007">MONONGUETE - SOLANO - CAQUETA</option>
              <option value="18756008">PUERTO TEJADA - SOLANO - CAQUETA</option>
              <option value="18756011">PUERTO LAS MERCEDES - SOLANO - CAQUETA</option>
              <option value="18785000">SOLITA - CAQUETA</option>
              <option value="18785001">KILOMETRO 28 (LA ARGELIA) - SOLITA - CAQUETA</option>
              <option value="18785002">KILOMETRO 30 (CAMPO LEJANO) - SOLITA - CAQUETA</option>
              <option value="18785005">UNION SINCELEJO - SOLITA - CAQUETA</option>
              <option value="18860000">VALPARAISO - CAQUETA</option>
              <option value="18860001">SANTIAGO DE LA SELVA - VALPARAISO - CAQUETA</option>
              <option value="18860002">KILOMETRO 18 - VALPARAISO - CAQUETA</option>
              <option value="18860005">PLAYA RICA - VALPARAISO - CAQUETA</option>
              <option value="19001000">POPAYAN - CAUCA</option>
              <option value="19001001">CAJETE - POPAYAN - CAUCA</option>
              <option value="19001002">CALIBIO - POPAYAN - CAUCA</option>
              <option value="19001007">JULUMITO - POPAYAN - CAUCA</option>
              <option value="19001008">LA REJOYA - POPAYAN - CAUCA</option>
              <option value="19001013">PUEBLILLO - POPAYAN - CAUCA</option>
              <option value="19001014">PUELENJE - POPAYAN - CAUCA</option>
              <option value="19001019">SANTA ROSA - POPAYAN - CAUCA</option>
              <option value="19001025">POBLAZON - POPAYAN - CAUCA</option>
              <option value="19001026">SAMUEL SILVERIO - POPAYAN - CAUCA</option>
              <option value="19001027">CRUCERO DE PUELENJE - POPAYAN - CAUCA</option>
              <option value="19001028">EL SALVADOR - POPAYAN - CAUCA</option>
              <option value="19001029">EL TUNEL - POPAYAN - CAUCA</option>
              <option value="19001030">JULUMITO ALTO - POPAYAN - CAUCA</option>
              <option value="19001031">LA CABUYERA - POPAYAN - CAUCA</option>
              <option value="19001032">LA PLAYA - POPAYAN - CAUCA</option>
              <option value="19001033">LAME - POPAYAN - CAUCA</option>
              <option value="19001034">RIO BLANCO - POPAYAN - CAUCA</option>
              <option value="19001035">VEREDA DE TORRES - POPAYAN - CAUCA</option>
              <option value="19001037">LA ESPERANZA (JARDINES DE PAZ) - POPAYAN - CAUCA</option>
              <option value="19001038">LA FORTALEZA - POPAYAN - CAUCA</option>
              <option value="19001039">PARCELACION ATARDECERES DE LA PRADERA - POPAYAN - CAUCA</option>
              <option value="19001040">LOS LLANOS - POPAYAN - CAUCA</option>
              <option value="19001041">LAS PALMAS - POPAYAN - CAUCA</option>
              <option value="19022000">ALMAGUER - CAUCA</option>
              <option value="19022001">CAQUIONA - ALMAGUER - CAUCA</option>
              <option value="19022003">TABLON - ALMAGUER - CAUCA</option>
              <option value="19022004">LLACUANAS - ALMAGUER - CAUCA</option>
              <option value="19022013">SAN JORGE HERRADURA - ALMAGUER - CAUCA</option>
              <option value="19022014">LA HONDA - ALMAGUER - CAUCA</option>
              <option value="19050000">ARGELIA - CAUCA</option>
              <option value="19050001">EL MANGO - ARGELIA - CAUCA</option>
              <option value="19050002">LA BELLEZA - ARGELIA - CAUCA</option>
              <option value="19050005">EL DIVISO - ARGELIA - CAUCA</option>
              <option value="19050006">EL PLATEADO - ARGELIA - CAUCA</option>
              <option value="19050007">SINAI - ARGELIA - CAUCA</option>
              <option value="19050016">PUERTO RICO - ARGELIA - CAUCA</option>
              <option value="19050017">SAN JUAN GUADUA - ARGELIA - CAUCA</option>
              <option value="19075000">BALBOA - CAUCA</option>
              <option value="19075001">LA PLANADA - BALBOA - CAUCA</option>
              <option value="19075002">OLAYA - BALBOA - CAUCA</option>
              <option value="19075003">SAN ALFONSO - BALBOA - CAUCA</option>
              <option value="19075005">LA BERMEJA - BALBOA - CAUCA</option>
              <option value="19075006">PURETO - BALBOA - CAUCA</option>
              <option value="19075008">LA LOMITA - BALBOA - CAUCA</option>
              <option value="19075009">EL VIJAL - BALBOA - CAUCA</option>
              <option value="19075011">PARAISO - BALBOA - CAUCA</option>
              <option value="19100000">BOLIVAR - CAUCA</option>
              <option value="19100001">CAPELLANIAS - BOLIVAR - CAUCA</option>
              <option value="19100005">EL CARMEN - BOLIVAR - CAUCA</option>
              <option value="19100006">EL RODEO - BOLIVAR - CAUCA</option>
              <option value="19100007">GUACHICONO - BOLIVAR - CAUCA</option>
              <option value="19100013">LERMA - BOLIVAR - CAUCA</option>
              <option value="19100015">LOS MILAGROS - BOLIVAR - CAUCA</option>
              <option value="19100018">MELCHOR - BOLIVAR - CAUCA</option>
              <option value="19100020">SAN JUAN - BOLIVAR - CAUCA</option>
              <option value="19100021">SAN LORENZO - BOLIVAR - CAUCA</option>
              <option value="19100041">LA CARBONERA - BOLIVAR - CAUCA</option>
              <option value="19100042">EL MORRO - BOLIVAR - CAUCA</option>
              <option value="19110000">BUENOS AIRES - CAUCA</option>
              <option value="19110006">EL PORVENIR - BUENOS AIRES - CAUCA</option>
              <option value="19110007">HONDURAS - BUENOS AIRES - CAUCA</option>
              <option value="19110008">LA BALSA - BUENOS AIRES - CAUCA</option>
              <option value="19110012">TIMBA - BUENOS AIRES - CAUCA</option>
              <option value="19110013">EL CERAL - BUENOS AIRES - CAUCA</option>
              <option value="19110016">SAN FRANCISCO - BUENOS AIRES - CAUCA</option>
              <option value="19110025">PALOBLANCO - BUENOS AIRES - CAUCA</option>
              <option value="19110029">LA ESPERANZA - BUENOS AIRES - CAUCA</option>
              <option value="19110030">MUNCHIQUE - BUENOS AIRES - CAUCA</option>
              <option value="19130000">CAJIBIO - CAUCA</option>
              <option value="19130004">EL CARMELO - CAJIBIO - CAUCA</option>
              <option value="19130005">EL ROSARIO - CAJIBIO - CAUCA</option>
              <option value="19130006">LA CAPILLA - CAJIBIO - CAUCA</option>
              <option value="19130007">LA PEDREGOSA - CAJIBIO - CAUCA</option>
              <option value="19130008">LA VENTA - CAJIBIO - CAUCA</option>
              <option value="19130009">SANTA TERESA DE CASAS BAJAS - CAJIBIO - CAUCA</option>
              <option value="19130010">ORTEGA - CAJIBIO - CAUCA</option>
              <option value="19130017">EL CAIRO - CAJIBIO - CAUCA</option>
              <option value="19130018">EL COFRE - CAJIBIO - CAUCA</option>
              <option value="19130019">ISLA DEL PONTON - CAJIBIO - CAUCA</option>
              <option value="19130020">LA LAGUNA DINDE - CAJIBIO - CAUCA</option>
              <option value="19130021">RESGUARDO INDIGENA DEL GUAYABAL CXAYUGE FXIW CXAB - CAJIBIO - CAUCA</option>
              <option value="19130022">URBANIZACION LAS MARGARITAS - CAJIBIO - CAUCA</option>
              <option value="19137000">CALDONO - CAUCA</option>
              <option value="19137001">CERRO ALTO - CALDONO - CAUCA</option>
              <option value="19137002">EL PITAL - CALDONO - CAUCA</option>
              <option value="19137004">PESCADOR - CALDONO - CAUCA</option>
              <option value="19137007">PUEBLO NUEVO - CALDONO - CAUCA</option>
              <option value="19137008">SIBERIA - CALDONO - CAUCA</option>
              <option value="19137013">CRUCERO DE PESCADOR - CALDONO - CAUCA</option>
              <option value="19142000">CALOTO - CAUCA</option>
              <option value="19142004">EL PALO - CALOTO - CAUCA</option>
              <option value="19142007">HUASANO - CALOTO - CAUCA</option>
              <option value="19142011">QUINTERO - CALOTO - CAUCA</option>
              <option value="19142015">LA ARROBLEDA - CALOTO - CAUCA</option>
              <option value="19142030">CRUCERO DE GUALI - CALOTO - CAUCA</option>
              <option value="19142031">HUELLAS - CALOTO - CAUCA</option>
              <option value="19142032">ALTO EL PALO - CALOTO - CAUCA</option>
              <option value="19142034">BODEGA ARRIBA - CALOTO - CAUCA</option>
              <option value="19142038">EL NILO - CALOTO - CAUCA</option>
              <option value="19142039">EL GUASIMO - CALOTO - CAUCA</option>
              <option value="19142045">TOEZ - CALOTO - CAUCA</option>
              <option value="19142050">LOPEZ ADENTRO - CALOTO - CAUCA</option>
              <option value="19142051">MORALES - CALOTO - CAUCA</option>
              <option value="19142056">PILAMO - CALOTO - CAUCA</option>
              <option value="19212000">CORINTO - CAUCA</option>
              <option value="19212001">EL JAGUAL - CORINTO - CAUCA</option>
              <option value="19212004">MEDIA NARANJA - CORINTO - CAUCA</option>
              <option value="19212005">RIONEGRO - CORINTO - CAUCA</option>
              <option value="19212008">SAN RAFAEL - CORINTO - CAUCA</option>
              <option value="19212009">EL BARRANCO - CORINTO - CAUCA</option>
              <option value="19212010">QUEBRADITAS - CORINTO - CAUCA</option>
              <option value="19256000">EL TAMBO - CAUCA</option>
              <option value="19256001">ALTO DEL REY - EL TAMBO - CAUCA</option>
              <option value="19256004">CUATRO ESQUINAS - EL TAMBO - CAUCA</option>
              <option value="19256005">CHAPA - EL TAMBO - CAUCA</option>
              <option value="19256007">EL PLACER - EL TAMBO - CAUCA</option>
              <option value="19256009">EL ZARZAL - EL TAMBO - CAUCA</option>
              <option value="19256012">HUISITO - EL TAMBO - CAUCA</option>
              <option value="19256013">LA ALIANZA - EL TAMBO - CAUCA</option>
              <option value="19256014">LA PAZ - EL TAMBO - CAUCA</option>
              <option value="19256015">LOS ANAYES - EL TAMBO - CAUCA</option>
              <option value="19256016">LOS ANDES - EL TAMBO - CAUCA</option>
              <option value="19256019">PANDIGUANDO - EL TAMBO - CAUCA</option>
              <option value="19256020">PIAGUA - EL TAMBO - CAUCA</option>
              <option value="19256022">QUILCACE - EL TAMBO - CAUCA</option>
              <option value="19256025">SAN JOAQUIN - EL TAMBO - CAUCA</option>
              <option value="19256027">SEGUENGUE - EL TAMBO - CAUCA</option>
              <option value="19256028">URIBE - EL TAMBO - CAUCA</option>
              <option value="19256029">FONDAS - EL TAMBO - CAUCA</option>
              <option value="19256031">BUENA VISTA - EL TAMBO - CAUCA</option>
              <option value="19256032">LAS BOTAS - EL TAMBO - CAUCA</option>
              <option value="19256033">CABUYAL - EL TAMBO - CAUCA</option>
              <option value="19256034">EL CRUCERO DEL PUEBLO - EL TAMBO - CAUCA</option>
              <option value="19256036">PLAYA RICA - EL TAMBO - CAUCA</option>
              <option value="19256058">AIRES DE OCCIDENTE - EL TAMBO - CAUCA</option>
              <option value="19256059">EL CRUCERO DE PANDIGUANDO - EL TAMBO - CAUCA</option>
              <option value="19256060">EL RECUERDO - EL TAMBO - CAUCA</option>
              <option value="19256061">LA CHICUEÑA - EL TAMBO - CAUCA</option>
              <option value="19256062">PUENTE DEL RIO TIMBIO - EL TAMBO - CAUCA</option>
              <option value="19290000">FLORENCIA - CAUCA</option>
              <option value="19290001">EL ROSARIO - FLORENCIA - CAUCA</option>
              <option value="19290002">MARSELLA - FLORENCIA - CAUCA</option>
              <option value="19300000">GUACHENE - CAUCA</option>
              <option value="19300004">BARRAGAN - GUACHENE - CAUCA</option>
              <option value="19300005">CAMPOALEGRE - GUACHENE - CAUCA</option>
              <option value="19300006">CAPONERA 1 - GUACHENE - CAUCA</option>
              <option value="19300007">CAPONERA SECTOR PALO BLANCO - GUACHENE - CAUCA</option>
              <option value="19300008">CIENAGA HONDA - GUACHENE - CAUCA</option>
              <option value="19300009">GUABAL - GUACHENE - CAUCA</option>
              <option value="19300010">GUABAL 1 - GUACHENE - CAUCA</option>
              <option value="19300011">GUABAL 2 - GUACHENE - CAUCA</option>
              <option value="19300012">LA CABAÑA - GUACHENE - CAUCA</option>
              <option value="19300013">LA CABAÑITA - GUACHENE - CAUCA</option>
              <option value="19300014">LA DOMINGA - GUACHENE - CAUCA</option>
              <option value="19300015">LLANO DE TAULA ALTO - GUACHENE - CAUCA</option>
              <option value="19300016">LLANO DE TAULA BAJO - GUACHENE - CAUCA</option>
              <option value="19300017">MINGO - GUACHENE - CAUCA</option>
              <option value="19300018">OBANDO - GUACHENE - CAUCA</option>
              <option value="19300019">OBANDO SECTOR LA ESPERANZA - GUACHENE - CAUCA</option>
              <option value="19300020">SABANETA - GUACHENE - CAUCA</option>
              <option value="19300021">SAN ANTONIO - GUACHENE - CAUCA</option>
              <option value="19300022">SAN JACINTO - GUACHENE - CAUCA</option>
              <option value="19300023">SAN JOSE - GUACHENE - CAUCA</option>
              <option value="19318000">GUAPI - CAUCA</option>
              <option value="19318002">BENJAMIN HERRERA (SAN VICENTE) - GUAPI - CAUCA</option>
              <option value="19318003">CALLELARGA - GUAPI - CAUCA</option>
              <option value="19318005">EL CARMELO - GUAPI - CAUCA</option>
              <option value="19318008">LIMONES - GUAPI - CAUCA</option>
              <option value="19318011">EL ROSARIO - GUAPI - CAUCA</option>
              <option value="19318012">SAN AGUSTIN - GUAPI - CAUCA</option>
              <option value="19318013">SAN ANTONIO DE GUAJUI - GUAPI - CAUCA</option>
              <option value="19318015">URIBE URIBE (EL NARANJO) - GUAPI - CAUCA</option>
              <option value="19318024">QUIROGA - GUAPI - CAUCA</option>
              <option value="19318025">CHUARE - GUAPI - CAUCA</option>
              <option value="19318026">SAN JOSE DE GUARE - GUAPI - CAUCA</option>
              <option value="19318027">BELEN - GUAPI - CAUCA</option>
              <option value="19318028">CAIMITO - GUAPI - CAUCA</option>
              <option value="19318029">SANTA ANA - GUAPI - CAUCA</option>
              <option value="19355000">INZA - CAUCA</option>
              <option value="19355001">CALDERAS - INZA - CAUCA</option>
              <option value="19355002">PEDREGAL - INZA - CAUCA</option>
              <option value="19355003">PUERTO VALENCIA - INZA - CAUCA</option>
              <option value="19355004">SAN ANDRES - INZA - CAUCA</option>
              <option value="19355008">TUMBICHUCUE - INZA - CAUCA</option>
              <option value="19355009">TURMINA - INZA - CAUCA</option>
              <option value="19355016">LA MILAGROSA - INZA - CAUCA</option>
              <option value="19355017">YAQUIVA - INZA - CAUCA</option>
              <option value="19364000">JAMBALO - CAUCA</option>
              <option value="19392000">LA SIERRA - CAUCA</option>
              <option value="19392001">LA DEPRESION - LA SIERRA - CAUCA</option>
              <option value="19392005">LA CUCHILLA - LA SIERRA - CAUCA</option>
              <option value="19392006">LA CUCHILLA ALTA - LA SIERRA - CAUCA</option>
              <option value="19397000">LA VEGA - CAUCA</option>
              <option value="19397001">ALTAMIRA - LA VEGA - CAUCA</option>
              <option value="19397002">ARBELA - LA VEGA - CAUCA</option>
              <option value="19397004">EL PALMAR - LA VEGA - CAUCA</option>
              <option value="19397005">GUACHICONO - LA VEGA - CAUCA</option>
              <option value="19397006">LOS UVOS - LA VEGA - CAUCA</option>
              <option value="19397007">PANCITARA - LA VEGA - CAUCA</option>
              <option value="19397009">SAN MIGUEL - LA VEGA - CAUCA</option>
              <option value="19397010">SANTA BARBARA - LA VEGA - CAUCA</option>
              <option value="19397011">SANTA JUANA - LA VEGA - CAUCA</option>
              <option value="19397012">ALBANIA - LA VEGA - CAUCA</option>
              <option value="19397018">BARBILLAS - LA VEGA - CAUCA</option>
              <option value="19397019">SANTA RITA - LA VEGA - CAUCA</option>
              <option value="19418000">LOPEZ - LOPEZ DE MICAY - CAUCA</option>
              <option value="19418009">NOANAMITO - LOPEZ DE MICAY - CAUCA</option>
              <option value="19418010">PLAYA GRANDE - LOPEZ DE MICAY - CAUCA</option>
              <option value="19418012">SAN ANTONIO DE CHUARE - LOPEZ DE MICAY - CAUCA</option>
              <option value="19418018">TAPARAL - LOPEZ DE MICAY - CAUCA</option>
              <option value="19418019">ZARAGOZA - LOPEZ DE MICAY - CAUCA</option>
              <option value="19418024">BETANIA - LOPEZ DE MICAY - CAUCA</option>
              <option value="19418032">SAN ANTONIO DE GURUMENDY - LOPEZ DE MICAY - CAUCA</option>
              <option value="19418035">SANTA CRUZ DE SIGUI - LOPEZ DE MICAY - CAUCA</option>
              <option value="19418036">CABECITAS - LOPEZ DE MICAY - CAUCA</option>
              <option value="19418038">ISLA DE GALLO - LOPEZ DE MICAY - CAUCA</option>
              <option value="19418039">JUAN COBO - LOPEZ DE MICAY - CAUCA</option>
              <option value="19450000">MERCADERES - CAUCA</option>
              <option value="19450002">ARBOLEDAS - MERCADERES - CAUCA</option>
              <option value="19450003">EL PILON - MERCADERES - CAUCA</option>
              <option value="19450004">ESMERALDAS - MERCADERES - CAUCA</option>
              <option value="19450006">SAN JOAQUIN - MERCADERES - CAUCA</option>
              <option value="19450007">SAN JUANITO - MERCADERES - CAUCA</option>
              <option value="19450010">CURACAS - MERCADERES - CAUCA</option>
              <option value="19450012">LA DESPENSA - MERCADERES - CAUCA</option>
              <option value="19450014">SOMBRERILLOS - MERCADERES - CAUCA</option>
              <option value="19450015">EL BADO - MERCADERES - CAUCA</option>
              <option value="19450016">TABLONCITO - MERCADERES - CAUCA</option>
              <option value="19450019">MOJARRAS - MERCADERES - CAUCA</option>
              <option value="19450020">LOS LLANOS - MERCADERES - CAUCA</option>
              <option value="19450023">BUENOS AIRES - MERCADERES - CAUCA</option>
              <option value="19450024">EL CANGREJO - MERCADERES - CAUCA</option>
              <option value="19450025">EL COCAL - MERCADERES - CAUCA</option>
              <option value="19450026">ESPERANZAS DE MAYO - MERCADERES - CAUCA</option>
              <option value="19450027">PUEBLO NUEVO - MERCADERES - CAUCA</option>
              <option value="19455000">MIRANDA - CAUCA</option>
              <option value="19455005">ORTIGAL - MIRANDA - CAUCA</option>
              <option value="19455007">SANTA ANA - MIRANDA - CAUCA</option>
              <option value="19455008">TIERRADURA - MIRANDA - CAUCA</option>
              <option value="19455009">TULIPAN - MIRANDA - CAUCA</option>
              <option value="19455010">GUATEMALA - MIRANDA - CAUCA</option>
              <option value="19455011">SAN ANDRES - MIRANDA - CAUCA</option>
              <option value="19455013">LA LINDOSA - MIRANDA - CAUCA</option>
              <option value="19473000">MORALES - CAUCA</option>
              <option value="19473002">CARPINTERO - MORALES - CAUCA</option>
              <option value="19473009">SAN ISIDRO - MORALES - CAUCA</option>
              <option value="19473012">SANTA ROSA - MORALES - CAUCA</option>
              <option value="19473014">LA ESTACION - MORALES - CAUCA</option>
              <option value="19473017">EL ROSARIO - MORALES - CAUCA</option>
              <option value="19513000">PADILLA - CAUCA</option>
              <option value="19513001">YARUMALES - PADILLA - CAUCA</option>
              <option value="19513003">LA PAILA - PADILLA - CAUCA</option>
              <option value="19513004">EL CHAMIZO - PADILLA - CAUCA</option>
              <option value="19513007">LOS ROBLES - PADILLA - CAUCA</option>
              <option value="19513008">CUERNAVACA - PADILLA - CAUCA</option>
              <option value="19517000">BELALCAZAR - PAEZ - CAUCA</option>
              <option value="19517002">AVIRAMA - PAEZ - CAUCA</option>
              <option value="19517003">COHETANDO - PAEZ - CAUCA</option>
              <option value="19517007">ITAIBE - PAEZ - CAUCA</option>
              <option value="19517012">RICAURTE - PAEZ - CAUCA</option>
              <option value="19517013">RIOCHIQUITO - PAEZ - CAUCA</option>
              <option value="19517014">SAN LUIS (POTRERILLO) - PAEZ - CAUCA</option>
              <option value="19517015">TALAGA - PAEZ - CAUCA</option>
              <option value="19517016">TOEZ - PAEZ - CAUCA</option>
              <option value="19517017">LA MESA DE TOGOIMA - PAEZ - CAUCA</option>
              <option value="19517029">MINUTO DE DIOS - PAEZ - CAUCA</option>
              <option value="19517030">COQUIYO - PAEZ - CAUCA</option>
              <option value="19517032">EL RODEO - PAEZ - CAUCA</option>
              <option value="19517033">GUADUALEJO - PAEZ - CAUCA</option>
              <option value="19517034">GUAPIO - PAEZ - CAUCA</option>
              <option value="19517035">GUAQUIYO - PAEZ - CAUCA</option>
              <option value="19517037">LA MARIA - PAEZ - CAUCA</option>
              <option value="19517039">MESA DE CALOTO - PAEZ - CAUCA</option>
              <option value="19517040">MESA DE TALAGA - PAEZ - CAUCA</option>
              <option value="19517043">VICANENGA - PAEZ - CAUCA</option>
              <option value="19517044">LA MESA DE AVIRAMA - PAEZ - CAUCA</option>
              <option value="19517045">LA MESA DE BELALCAZAR - PAEZ - CAUCA</option>
              <option value="19517046">SANTA ROSA - PAEZ - CAUCA</option>
              <option value="19532000">EL BORDO - PATIA - CAUCA</option>
              <option value="19532001">BRISAS - PATIA - CAUCA</option>
              <option value="19532003">DON ALONSO - PATIA - CAUCA</option>
              <option value="19532004">GALINDEZ - PATIA - CAUCA</option>
              <option value="19532005">LA FONDA - PATIA - CAUCA</option>
              <option value="19532006">LA MESA - PATIA - CAUCA</option>
              <option value="19532008">PATIA - CAUCA</option>
              <option value="19532009">PIEDRASENTADA - PATIA - CAUCA</option>
              <option value="19532010">PAN DE AZUCAR - PATIA - CAUCA</option>
              <option value="19532012">SAJANDI - PATIA - CAUCA</option>
              <option value="19532013">EL ESTRECHO - PATIA - CAUCA</option>
              <option value="19532014">EL HOYO - PATIA - CAUCA</option>
              <option value="19532025">SANTA CRUZ - PATIA - CAUCA</option>
              <option value="19532032">PALO MOCHO - PATIA - CAUCA</option>
              <option value="19533000">PIAMONTE - CAUCA</option>
              <option value="19533002">EL REMANSO - PIAMONTE - CAUCA</option>
              <option value="19533003">MIRAFLOR - PIAMONTE - CAUCA</option>
              <option value="19533004">YAPURA - PIAMONTE - CAUCA</option>
              <option value="19533005">LAS PALMERAS 1 - PIAMONTE - CAUCA</option>
              <option value="19533006">LAS PALMERAS 2 - PIAMONTE - CAUCA</option>
              <option value="19533007">NAPOLES - PIAMONTE - CAUCA</option>
              <option value="19548000">PIENDAMO - PIENDAMO - TUNIA - CAUCA</option>
              <option value="19548001">TUNIA - PIENDAMO - TUNIA - CAUCA</option>
              <option value="19573000">PUERTO TEJADA - CAUCA</option>
              <option value="19573001">BOCAS DEL PALO - PUERTO TEJADA - CAUCA</option>
              <option value="19573002">LAS BRISAS - PUERTO TEJADA - CAUCA</option>
              <option value="19573003">SAN CARLOS - PUERTO TEJADA - CAUCA</option>
              <option value="19573004">ZANJON RICO - PUERTO TEJADA - CAUCA</option>
              <option value="19573006">VUELTA LARGA - PUERTO TEJADA - CAUCA</option>
              <option value="19573008">LOS BANCOS - PUERTO TEJADA - CAUCA</option>
              <option value="19573009">GUENGUE - PUERTO TEJADA - CAUCA</option>
              <option value="19573010">CIUDAD SUR - PUERTO TEJADA - CAUCA</option>
              <option value="19585000">COCONUCO - PURACE - CAUCA</option>
              <option value="19585004">PURACE - CAUCA</option>
              <option value="19585007">SANTA LETICIA - PURACE - CAUCA</option>
              <option value="19585008">JUAN TAMA - PURACE - CAUCA</option>
              <option value="19585009">PALETARA - PURACE - CAUCA</option>
              <option value="19585010">CHAPIO - PURACE - CAUCA</option>
              <option value="19622000">ROSAS - CAUCA</option>
              <option value="19622002">PARRAGA - ROSAS - CAUCA</option>
              <option value="19622007">CEFIRO - ROSAS - CAUCA</option>
              <option value="19622011">SAUCE - ROSAS - CAUCA</option>
              <option value="19693000">SAN SEBASTIAN - CAUCA</option>
              <option value="19693001">EL ROSAL - SAN SEBASTIAN - CAUCA</option>
              <option value="19693004">SANTIAGO - SAN SEBASTIAN - CAUCA</option>
              <option value="19693005">VALENCIA - SAN SEBASTIAN - CAUCA</option>
              <option value="19693006">VENECIA - SAN SEBASTIAN - CAUCA</option>
              <option value="19698000">SANTANDER DE QUILICHAO - CAUCA</option>
              <option value="19698001">EL PALMAR - SANTANDER DE QUILICHAO - CAUCA</option>
              <option value="19698002">EL TURCO - SANTANDER DE QUILICHAO - CAUCA</option>
              <option value="19698004">LA ARROBLEDA - SANTANDER DE QUILICHAO - CAUCA</option>
              <option value="19698007">MONDOMO - SANTANDER DE QUILICHAO - CAUCA</option>
              <option value="19698008">PARAMILLO 1 - SANTANDER DE QUILICHAO - CAUCA</option>
              <option value="19698009">SAN RAFAEL - SANTANDER DE QUILICHAO - CAUCA</option>
              <option value="19698010">TRES QUEBRADAS - SANTANDER DE QUILICHAO - CAUCA</option>
              <option value="19698013">SAN ANTONIO - SANTANDER DE QUILICHAO - CAUCA</option>
              <option value="19698014">SAN PEDRO - SANTANDER DE QUILICHAO - CAUCA</option>
              <option value="19698017">DOMINGUILLO - SANTANDER DE QUILICHAO - CAUCA</option>
              <option value="19698018">EL CRUCERO - SANTANDER DE QUILICHAO - CAUCA</option>
              <option value="19698020">QUINAMAYO - SANTANDER DE QUILICHAO - CAUCA</option>
              <option value="19698022">LLANO DE ALEGRIAS - SANTANDER DE QUILICHAO - CAUCA</option>
              <option value="19698023">CABECERA DOMINGUILLO - SANTANDER DE QUILICHAO - CAUCA</option>
              <option value="19698024">CAMBALACHE - SANTANDER DE QUILICHAO - CAUCA</option>
              <option value="19698025">EL BROCHE - SANTANDER DE QUILICHAO - CAUCA</option>
              <option value="19698026">EL LLANITO - SANTANDER DE QUILICHAO - CAUCA</option>
              <option value="19698027">EL TAJO - SANTANDER DE QUILICHAO - CAUCA</option>
              <option value="19698028">LA AGUSTINA - SANTANDER DE QUILICHAO - CAUCA</option>
              <option value="19698029">LA CAPILLA - SANTANDER DE QUILICHAO - CAUCA</option>
              <option value="19698030">LA CHAPA - SANTANDER DE QUILICHAO - CAUCA</option>
              <option value="19698031">LA PALOMERA - SANTANDER DE QUILICHAO - CAUCA</option>
              <option value="19698032">LA QUEBRADA - SANTANDER DE QUILICHAO - CAUCA</option>
              <option value="19698033">LOMITAS ABAJO - SANTANDER DE QUILICHAO - CAUCA</option>
              <option value="19698034">LOMITAS ARRIBA - SANTANDER DE QUILICHAO - CAUCA</option>
              <option value="19698035">LOURDES - SANTANDER DE QUILICHAO - CAUCA</option>
              <option value="19698036">MANDIVA - SANTANDER DE QUILICHAO - CAUCA</option>
              <option value="19698037">SAN JOSE - SANTANDER DE QUILICHAO - CAUCA</option>
              <option value="19698042">VILACHI - SANTANDER DE QUILICHAO - CAUCA</option>
              <option value="19698043">BELLAVISTA - SANTANDER DE QUILICHAO - CAUCA</option>
              <option value="19698044">PARAMILLO 2 - SANTANDER DE QUILICHAO - CAUCA</option>
              <option value="19701000">SANTA ROSA - CAUCA</option>
              <option value="19701001">DESCANSE - SANTA ROSA - CAUCA</option>
              <option value="19701002">EL CARMELO - SANTA ROSA - CAUCA</option>
              <option value="19701006">SAN JUAN DE VILLALOBOS - SANTA ROSA - CAUCA</option>
              <option value="19743000">SILVIA - CAUCA</option>
              <option value="19743002">PITAYO - SILVIA - CAUCA</option>
              <option value="19743003">QUICHAYA - SILVIA - CAUCA</option>
              <option value="19743005">USENDA - SILVIA - CAUCA</option>
              <option value="19760000">PAISPAMBA - SOTARA - PAISPAMBA - CAUCA</option>
              <option value="19760001">CHAPA - SOTARA - PAISPAMBA - CAUCA</option>
              <option value="19760007">RIO BLANCO - SOTARA - PAISPAMBA - CAUCA</option>
              <option value="19760012">LAS VEGAS - SOTARA - PAISPAMBA - CAUCA</option>
              <option value="19780000">SUAREZ - CAUCA</option>
              <option value="19780007">LA TOMA - SUAREZ - CAUCA</option>
              <option value="19780008">LA BETULIA - SUAREZ - CAUCA</option>
              <option value="19780011">ALTAMIRA - SUAREZ - CAUCA</option>
              <option value="19785000">SUCRE - CAUCA</option>
              <option value="19785001">EL PARAISO - SUCRE - CAUCA</option>
              <option value="19785008">LA CEJA - SUCRE - CAUCA</option>
              <option value="19807000">TIMBIO - CAUCA</option>
              <option value="19807007">CRUCES - TIMBIO - CAUCA</option>
              <option value="19807009">ALTO SAN JOSE - TIMBIO - CAUCA</option>
              <option value="19807016">LAS HUACAS - TIMBIO - CAUCA</option>
              <option value="19809000">TIMBIQUI - CAUCA</option>
              <option value="19809001">BUBUEY - TIMBIQUI - CAUCA</option>
              <option value="19809002">CAMARONES - TIMBIQUI - CAUCA</option>
              <option value="19809003">COTEJE - TIMBIQUI - CAUCA</option>
              <option value="19809006">SAN BERNARDO - TIMBIQUI - CAUCA</option>
              <option value="19809007">SAN JOSE - TIMBIQUI - CAUCA</option>
              <option value="19809008">SANTA MARIA - TIMBIQUI - CAUCA</option>
              <option value="19809009">SANTA ROSA DE SAIJA - TIMBIQUI - CAUCA</option>
              <option value="19809010">CHETE - TIMBIQUI - CAUCA</option>
              <option value="19809011">BOCA DE PATIA - TIMBIQUI - CAUCA</option>
              <option value="19809012">EL CHARCO - TIMBIQUI - CAUCA</option>
              <option value="19809013">EL REALITO - TIMBIQUI - CAUCA</option>
              <option value="19809018">CUPI - TIMBIQUI - CAUCA</option>
              <option value="19809020">SAN MIGUEL - TIMBIQUI - CAUCA</option>
              <option value="19809021">COROZAL - TIMBIQUI - CAUCA</option>
              <option value="19809022">CABECITAL - TIMBIQUI - CAUCA</option>
              <option value="19809023">PUERTO SAIJA - TIMBIQUI - CAUCA</option>
              <option value="19809024">ANGOSTURA - TIMBIQUI - CAUCA</option>
              <option value="19809025">GUANGUI - TIMBIQUI - CAUCA</option>
              <option value="19809026">LOS BRASOS - TIMBIQUI - CAUCA</option>
              <option value="19809027">PIZARE - TIMBIQUI - CAUCA</option>
              <option value="19821000">TORIBIO - CAUCA</option>
              <option value="19821005">SAN FRANCISCO - TORIBIO - CAUCA</option>
              <option value="19821007">TACUEYO - TORIBIO - CAUCA</option>
              <option value="19821009">CALOTO NUEVO - TORIBIO - CAUCA</option>
              <option value="19821010">EL HUILA - TORIBIO - CAUCA</option>
              <option value="19824000">TOTORO - CAUCA</option>
              <option value="19824002">GABRIEL LOPEZ - TOTORO - CAUCA</option>
              <option value="19824004">PANIQUITA - TOTORO - CAUCA</option>
              <option value="19845000">VILLA RICA - CAUCA</option>
              <option value="19845005">JUAN IGNACIO - VILLA RICA - CAUCA</option>
              <option value="19845006">PRIMAVERA - VILLA RICA - CAUCA</option>
              <option value="19845007">PERICO NEGRO - VILLA RICA - CAUCA</option>
              <option value="20001000">VALLEDUPAR - CESAR</option>
              <option value="20001001">AGUAS BLANCAS - VALLEDUPAR - CESAR</option>
              <option value="20001002">ATANQUEZ - VALLEDUPAR - CESAR</option>
              <option value="20001003">BADILLO - VALLEDUPAR - CESAR</option>
              <option value="20001005">CARACOLI - VALLEDUPAR - CESAR</option>
              <option value="20001006">CHEMESQUEMENA - VALLEDUPAR - CESAR</option>
              <option value="20001007">GUATAPURI - VALLEDUPAR - CESAR</option>
              <option value="20001009">GUACOCHE - VALLEDUPAR - CESAR</option>
              <option value="20001010">GUAYMARAL - VALLEDUPAR - CESAR</option>
              <option value="20001011">LA MINA - VALLEDUPAR - CESAR</option>
              <option value="20001012">LOS VENADOS - VALLEDUPAR - CESAR</option>
              <option value="20001013">MARIANGOLA - VALLEDUPAR - CESAR</option>
              <option value="20001014">PATILLAL - VALLEDUPAR - CESAR</option>
              <option value="20001018">VALENCIA DE JESUS - VALLEDUPAR - CESAR</option>
              <option value="20001019">CAMPERUCHO - VALLEDUPAR - CESAR</option>
              <option value="20001022">GUACOCHITO - VALLEDUPAR - CESAR</option>
              <option value="20001024">LOS CALABAZOS - VALLEDUPAR - CESAR</option>
              <option value="20001025">LOS CORAZONES - VALLEDUPAR - CESAR</option>
              <option value="20001026">LOS HATICOS I - VALLEDUPAR - CESAR</option>
              <option value="20001027">LA MESA - AZUCAR BUENA - VALLEDUPAR - CESAR</option>
              <option value="20001028">RAICES - VALLEDUPAR - CESAR</option>
              <option value="20001030">RANCHO DE GOYA - VALLEDUPAR - CESAR</option>
              <option value="20001031">RIO SECO - VALLEDUPAR - CESAR</option>
              <option value="20001032">LA VEGA ARRIBA - VALLEDUPAR - CESAR</option>
              <option value="20001034">VILLA GERMANIA - VALLEDUPAR - CESAR</option>
              <option value="20001036">EL JABO - VALLEDUPAR - CESAR</option>
              <option value="20001037">EL ALTO DE LA VUELTA - VALLEDUPAR - CESAR</option>
              <option value="20001038">HATICOS II - VALLEDUPAR - CESAR</option>
              <option value="20001039">EL PERRO - VALLEDUPAR - CESAR</option>
              <option value="20001040">LAS MERCEDES - VALLEDUPAR - CESAR</option>
              <option value="20001041">SABANA DE CRESPO - VALLEDUPAR - CESAR</option>
              <option value="20001042">LAS CASITAS - VALLEDUPAR - CESAR</option>
              <option value="20001044">MARUAMAQUE - VALLEDUPAR - CESAR</option>
              <option value="20001045">PONTON - VALLEDUPAR - CESAR</option>
              <option value="20001047">EL MOJAO - VALLEDUPAR - CESAR</option>
              <option value="20001048">RAMALITO - VALLEDUPAR - CESAR</option>
              <option value="20001051">VILLA RUEDA - VALLEDUPAR - CESAR</option>
              <option value="20011000">AGUACHICA - CESAR</option>
              <option value="20011001">BARRANCALEBRIJA - AGUACHICA - CESAR</option>
              <option value="20011006">LOMA DE CORREDOR - AGUACHICA - CESAR</option>
              <option value="20011009">PUERTO PATIÑO - AGUACHICA - CESAR</option>
              <option value="20011010">BUTURAMA - AGUACHICA - CESAR</option>
              <option value="20011011">NOREAN - AGUACHICA - CESAR</option>
              <option value="20011012">SANTA LUCIA - AGUACHICA - CESAR</option>
              <option value="20011014">VILLA DE SAN ANDRES - AGUACHICA - CESAR</option>
              <option value="20011025">EL JUNCAL - AGUACHICA - CESAR</option>
              <option value="20011026">LA CAMPANA - AGUACHICA - CESAR</option>
              <option value="20011029">LA YE - AGUACHICA - CESAR</option>
              <option value="20013000">AGUSTIN CODAZZI - CESAR</option>
              <option value="20013002">CASACARA - AGUSTIN CODAZZI - CESAR</option>
              <option value="20013003">LLERASCA - AGUSTIN CODAZZI - CESAR</option>
              <option value="20013006">PUNTA ARRECHA - AGUSTIN CODAZZI - CESAR</option>
              <option value="20013007">SAN RAMON - AGUSTIN CODAZZI - CESAR</option>
              <option value="20032000">ASTREA - CESAR</option>
              <option value="20032001">ARJONA - ASTREA - CESAR</option>
              <option value="20032003">EL YUCAL - ASTREA - CESAR</option>
              <option value="20032005">SANTA CECILIA - ASTREA - CESAR</option>
              <option value="20032006">EL HEBRON - ASTREA - CESAR</option>
              <option value="20032007">EL JOBO - ASTREA - CESAR</option>
              <option value="20032008">LA Y - ASTREA - CESAR</option>
              <option value="20032009">MONTECRISTO - ASTREA - CESAR</option>
              <option value="20032010">NUEVA COLOMBIA - ASTREA - CESAR</option>
              <option value="20045000">BECERRIL - CESAR</option>
              <option value="20045001">ESTADOS UNIDOS - BECERRIL - CESAR</option>
              <option value="20045004">LA GUAJIRITA - BECERRIL - CESAR</option>
              <option value="20060000">BOSCONIA - CESAR</option>
              <option value="20060004">LOMA COLORADA - BOSCONIA - CESAR</option>
              <option value="20060010">PUERTO LAJAS - BOSCONIA - CESAR</option>
              <option value="20175000">CHIMICHAGUA - CESAR</option>
              <option value="20175004">CANDELARIA - CHIMICHAGUA - CESAR</option>
              <option value="20175005">EL GUAMO - CHIMICHAGUA - CESAR</option>
              <option value="20175008">LAS FLORES - CHIMICHAGUA - CESAR</option>
              <option value="20175009">LAS VEGAS - CHIMICHAGUA - CESAR</option>
              <option value="20175010">MANDINGUILLA - CHIMICHAGUA - CESAR</option>
              <option value="20175011">SALOA - CHIMICHAGUA - CESAR</option>
              <option value="20175013">SEMPEGUA - CHIMICHAGUA - CESAR</option>
              <option value="20175014">SOLEDAD - CHIMICHAGUA - CESAR</option>
              <option value="20175016">LA MATA - CHIMICHAGUA - CESAR</option>
              <option value="20175017">EL CANAL - CHIMICHAGUA - CESAR</option>
              <option value="20175018">SANTO DOMINGO - CHIMICHAGUA - CESAR</option>
              <option value="20175020">PLATA PERDIDA - CHIMICHAGUA - CESAR</option>
              <option value="20175021">SABANAS DE JUAN MARCOS - CHIMICHAGUA - CESAR</option>
              <option value="20175022">HIGO AMARILLO - CHIMICHAGUA - CESAR</option>
              <option value="20175023">BETEL - CHIMICHAGUA - CESAR</option>
              <option value="20175024">BUENOS AIRES - CHIMICHAGUA - CESAR</option>
              <option value="20175025">CUATRO ESQUINAS - CHIMICHAGUA - CESAR</option>
              <option value="20175029">PIEDRAS BLANCAS - CHIMICHAGUA - CESAR</option>
              <option value="20175030">PUEBLITO - CHIMICHAGUA - CESAR</option>
              <option value="20175031">ULTIMO CASO - CHIMICHAGUA - CESAR</option>
              <option value="20175032">ZAPATI - CHIMICHAGUA - CESAR</option>
              <option value="20175033">CABECERA - CHIMICHAGUA - CESAR</option>
              <option value="20175034">CORRALITO - CHIMICHAGUA - CESAR</option>
              <option value="20175035">DIOS ME VE - CHIMICHAGUA - CESAR</option>
              <option value="20175036">EL PROGRESO - CHIMICHAGUA - CESAR</option>
              <option value="20175037">LA INVERNA - CHIMICHAGUA - CESAR</option>
              <option value="20175038">LA SABANA DEL TREBOL - CHIMICHAGUA - CESAR</option>
              <option value="20175039">LA UNION - CHIMICHAGUA - CESAR</option>
              <option value="20175040">MATA DE GUILLIN - CHIMICHAGUA - CESAR</option>
              <option value="20175041">NUEVA VICTORIA - CHIMICHAGUA - CESAR</option>
              <option value="20178000">CHIRIGUANA - CESAR</option>
              <option value="20178006">POPONTE - CHIRIGUANA - CESAR</option>
              <option value="20178008">RINCON HONDO - CHIRIGUANA - CESAR</option>
              <option value="20178014">LA AURORA - CHIRIGUANA - CESAR</option>
              <option value="20178015">ESTACION CHIRIGUANA - CHIRIGUANA - CESAR</option>
              <option value="20178016">LA SIERRA - CHIRIGUANA - CESAR</option>
              <option value="20178017">AGUA FRIA - CHIRIGUANA - CESAR</option>
              <option value="20178018">EL CRUCE DE LA SIERRA - CHIRIGUANA - CESAR</option>
              <option value="20178019">ARENAS BLANCAS - CHIRIGUANA - CESAR</option>
              <option value="20178021">CERRAJONES - CHIRIGUANA - CESAR</option>
              <option value="20228000">CURUMANI - CESAR</option>
              <option value="20228001">SABANAGRANDE - CURUMANI - CESAR</option>
              <option value="20228002">SAN ROQUE - CURUMANI - CESAR</option>
              <option value="20228003">SAN SEBASTIAN - CURUMANI - CESAR</option>
              <option value="20228004">SANTA ISABEL - CURUMANI - CESAR</option>
              <option value="20228005">CHAMPAN - CURUMANI - CESAR</option>
              <option value="20228007">GUAIMARAL - CURUMANI - CESAR</option>
              <option value="20228008">BARRIO ACOSTA - CURUMANI - CESAR</option>
              <option value="20228009">HOJANCHA - CURUMANI - CESAR</option>
              <option value="20228011">EL MAMEY - CURUMANI - CESAR</option>
              <option value="20228012">CHINELA - CURUMANI - CESAR</option>
              <option value="20228014">NUEVO HORIZONTE - CURUMANI - CESAR</option>
              <option value="20238000">EL COPEY - CESAR</option>
              <option value="20238002">CARACOLICITO - EL COPEY - CESAR</option>
              <option value="20238003">CHIMILA - EL COPEY - CESAR</option>
              <option value="20238004">SAN FRANCISCO DE ASIS - EL COPEY - CESAR</option>
              <option value="20250000">EL PASO - CESAR</option>
              <option value="20250001">EL VALLITO - EL PASO - CESAR</option>
              <option value="20250002">LA LOMA - EL PASO - CESAR</option>
              <option value="20250003">POTRERILLO - EL PASO - CESAR</option>
              <option value="20250004">CUATRO VIENTOS - EL PASO - CESAR</option>
              <option value="20250006">EL CARMEN - EL PASO - CESAR</option>
              <option value="20295000">GAMARRA - CESAR</option>
              <option value="20295001">LA ESTACION - GAMARRA - CESAR</option>
              <option value="20295002">EL CONTENTO - GAMARRA - CESAR</option>
              <option value="20295004">PALENQUILLO - GAMARRA - CESAR</option>
              <option value="20295005">PUERTO MOSQUITO - GAMARRA - CESAR</option>
              <option value="20295006">PUERTO VIEJO - GAMARRA - CESAR</option>
              <option value="20295007">CASCAJAL - GAMARRA - CESAR</option>
              <option value="20310000">GONZALEZ - CESAR</option>
              <option value="20310002">BURBURA - GONZALEZ - CESAR</option>
              <option value="20310003">CULEBRITA - GONZALEZ - CESAR</option>
              <option value="20310006">MONTERA - GONZALEZ - CESAR</option>
              <option value="20310007">SAN ISIDRO - GONZALEZ - CESAR</option>
              <option value="20383000">LA GLORIA - CESAR</option>
              <option value="20383001">AYACUCHO - LA GLORIA - CESAR</option>
              <option value="20383002">CAROLINA - LA GLORIA - CESAR</option>
              <option value="20383003">MOLINA - LA GLORIA - CESAR</option>
              <option value="20383005">SIMAÑA - LA GLORIA - CESAR</option>
              <option value="20383006">BESOTE - LA GLORIA - CESAR</option>
              <option value="20383010">LA MATA - LA GLORIA - CESAR</option>
              <option value="20383011">ESTACION FERROCARRIL - LA GLORIA - CESAR</option>
              <option value="20383012">LAS PUNTAS - LA GLORIA - CESAR</option>
              <option value="20400000">LA JAGUA DE IBIRICO - CESAR</option>
              <option value="20400001">LAS PALMITAS - LA JAGUA DE IBIRICO - CESAR</option>
              <option value="20400002">LA VICTORIA DE SAN ISIDRO - LA JAGUA DE IBIRICO - CESAR</option>
              <option value="20400003">BOQUERON - LA JAGUA DE IBIRICO - CESAR</option>
              <option value="20443000">MANAURE BALCON DEL CESAR - CESAR</option>
              <option value="20517000">PAILITAS - CESAR</option>
              <option value="20517001">LA FLORESTA - PAILITAS - CESAR</option>
              <option value="20517002">RIVERA - PAILITAS - CESAR</option>
              <option value="20517004">PALESTINA - PAILITAS - CESAR</option>
              <option value="20517006">EL BURRO - PAILITAS - CESAR</option>
              <option value="20550000">PELAYA - CESAR</option>
              <option value="20550001">COSTILLA - PELAYA - CESAR</option>
              <option value="20550012">SAN BERNARDO - PELAYA - CESAR</option>
              <option value="20570000">PUEBLO BELLO - CESAR</option>
              <option value="20570001">LA CAJA - PUEBLO BELLO - CESAR</option>
              <option value="20570002">LAS MINAS DE IRACAL - PUEBLO BELLO - CESAR</option>
              <option value="20570003">NUEVO COLON - PUEBLO BELLO - CESAR</option>
              <option value="20570004">NABUSIMAKE - PUEBLO BELLO - CESAR</option>
              <option value="20570005">PALMARITO - PUEBLO BELLO - CESAR</option>
              <option value="20614000">RIO DE ORO - CESAR</option>
              <option value="20614001">EL MARQUEZ - RIO DE ORO - CESAR</option>
              <option value="20614004">LOS ANGELES - RIO DE ORO - CESAR</option>
              <option value="20614006">MONTECITOS - RIO DE ORO - CESAR</option>
              <option value="20614010">PUERTO NUEVO - RIO DE ORO - CESAR</option>
              <option value="20614012">MORRISON - RIO DE ORO - CESAR</option>
              <option value="20621000">ROBLES - LA PAZ - CESAR</option>
              <option value="20621001">LOS ENCANTOS - LA PAZ - CESAR</option>
              <option value="20621006">SAN JOSE DEL ORIENTE - LA PAZ - CESAR</option>
              <option value="20621011">VARAS BLANCAS - LA PAZ - CESAR</option>
              <option value="20621012">SAN JOSE DE ORIENTE - BETANIA - LA PAZ - CESAR</option>
              <option value="20621013">MINGUILLO - LA PAZ - CESAR</option>
              <option value="20621015">RABO LARGO - LA PAZ - CESAR</option>
              <option value="20621016">SABANA ALTA - LA PAZ - CESAR</option>
              <option value="20710000">SAN ALBERTO - CESAR</option>
              <option value="20710001">LA LLANA - SAN ALBERTO - CESAR</option>
              <option value="20710002">LA PALMA - SAN ALBERTO - CESAR</option>
              <option value="20710005">LIBANO - SAN ALBERTO - CESAR</option>
              <option value="20710008">PUERTO CARREÑO - SAN ALBERTO - CESAR</option>
              <option value="20750000">SAN DIEGO - CESAR</option>
              <option value="20750001">LOS TUPES - SAN DIEGO - CESAR</option>
              <option value="20750002">MEDIA LUNA - SAN DIEGO - CESAR</option>
              <option value="20750006">EL RINCON - SAN DIEGO - CESAR</option>
              <option value="20750007">LAS PITILLAS - SAN DIEGO - CESAR</option>
              <option value="20750009">LOS BRASILES - SAN DIEGO - CESAR</option>
              <option value="20750011">TOCAIMO - SAN DIEGO - CESAR</option>
              <option value="20750012">NUEVAS FLORES - SAN DIEGO - CESAR</option>
              <option value="20770000">SAN MARTIN - CESAR</option>
              <option value="20770001">AGUAS BLANCAS - SAN MARTIN - CESAR</option>
              <option value="20770003">MINAS - SAN MARTIN - CESAR</option>
              <option value="20770004">PUERTO OCULTO - SAN MARTIN - CESAR</option>
              <option value="20770005">SAN JOSE DE LAS AMERICAS - SAN MARTIN - CESAR</option>
              <option value="20770006">CANDELIA - SAN MARTIN - CESAR</option>
              <option value="20770007">TERRAPLEN - SAN MARTIN - CESAR</option>
              <option value="20770008">LA CURVA - SAN MARTIN - CESAR</option>
              <option value="20770009">LA BANCA TORCOROMA - SAN MARTIN - CESAR</option>
              <option value="20770010">CUATRO BOCAS - SAN MARTIN - CESAR</option>
              <option value="20770011">LOS BAGRES - SAN MARTIN - CESAR</option>
              <option value="20770012">PITA LIMON - SAN MARTIN - CESAR</option>
              <option value="20770016">CAMPO AMALIA - SAN MARTIN - CESAR</option>
              <option value="20787000">TAMALAMEQUE - CESAR</option>
              <option value="20787001">PALESTINA LA NUEVA - TAMALAMEQUE - CESAR</option>
              <option value="20787002">LA BOCA - TAMALAMEQUE - CESAR</option>
              <option value="20787005">ZAPATOSA - TAMALAMEQUE - CESAR</option>
              <option value="20787007">ANTEQUERA - TAMALAMEQUE - CESAR</option>
              <option value="20787009">LAS PALMAS - TAMALAMEQUE - CESAR</option>
              <option value="20787011">LAS BRISAS - TAMALAMEQUE - CESAR</option>
              <option value="20787012">PASACORRIENDO - TAMALAMEQUE - CESAR</option>
              <option value="20787013">PUEBLO NUEVO - TAMALAMEQUE - CESAR</option>
              <option value="20787014">MUNDO ALREVEZ - TAMALAMEQUE - CESAR</option>
              <option value="20787015">EL DOCE - TAMALAMEQUE - CESAR</option>
              <option value="20787016">SITIO NUEVO - TAMALAMEQUE - CESAR</option>
              <option value="20787017">TOTUMITO - TAMALAMEQUE - CESAR</option>
              <option value="20787018">MATA DE BARRO - TAMALAMEQUE - CESAR</option>
              <option value="23001000">MONTERIA - CORDOBA</option>
              <option value="23001001">BUENOS AIRES - MONTERIA - CORDOBA</option>
              <option value="23001002">PALOTAL - MONTERIA - CORDOBA</option>
              <option value="23001003">EL CERRITO - MONTERIA - CORDOBA</option>
              <option value="23001004">EL SABANAL - MONTERIA - CORDOBA</option>
              <option value="23001005">GUASIMAL - MONTERIA - CORDOBA</option>
              <option value="23001006">JARAQUIEL - MONTERIA - CORDOBA</option>
              <option value="23001007">LA MANTA - MONTERIA - CORDOBA</option>
              <option value="23001008">LAS PALOMAS - MONTERIA - CORDOBA</option>
              <option value="23001009">LETICIA - EL TRONCO - MONTERIA - CORDOBA</option>
              <option value="23001010">LOMA VERDE - MONTERIA - CORDOBA</option>
              <option value="23001011">LOS GARZONES - MONTERIA - CORDOBA</option>
              <option value="23001012">NUEVO PARAISO - MONTERIA - CORDOBA</option>
              <option value="23001013">NUEVA LUCIA - MONTERIA - CORDOBA</option>
              <option value="23001014">PATIO BONITO - MONTERIA - CORDOBA</option>
              <option value="23001015">SAN ISIDRO - MONTERIA - CORDOBA</option>
              <option value="23001016">PUEBLO BUHO - MONTERIA - CORDOBA</option>
              <option value="23001017">SAN ANTERITO - MONTERIA - CORDOBA</option>
              <option value="23001018">SANTA CLARA - MONTERIA - CORDOBA</option>
              <option value="23001019">SANTA ISABEL - MONTERIA - CORDOBA</option>
              <option value="23001020">SANTA LUCIA - MONTERIA - CORDOBA</option>
              <option value="23001021">TRES PALMAS - MONTERIA - CORDOBA</option>
              <option value="23001022">TRES PIEDRAS - MONTERIA - CORDOBA</option>
              <option value="23001028">EL BARSAL - MONTERIA - CORDOBA</option>
              <option value="23001029">NUEVA ESPERANZA - MONTERIA - CORDOBA</option>
              <option value="23001030">EL COCUELO - MONTERIA - CORDOBA</option>
              <option value="23001031">MARTINICA - MONTERIA - CORDOBA</option>
              <option value="23001032">GUATEQUE - MONTERIA - CORDOBA</option>
              <option value="23001033">TENERIFE - MONTERIA - CORDOBA</option>
              <option value="23001034">LA VICTORIA - MONTERIA - CORDOBA</option>
              <option value="23001035">MORINDO CENTRAL - MONTERIA - CORDOBA</option>
              <option value="23001036">BOCA DE LA CEIBA - MONTERIA - CORDOBA</option>
              <option value="23001037">BROQUELITO - MONTERIA - CORDOBA</option>
              <option value="23001038">EL LIMON - MONTERIA - CORDOBA</option>
              <option value="23001039">EL QUINCE - MONTERIA - CORDOBA</option>
              <option value="23001040">EL VIDRIAL - MONTERIA - CORDOBA</option>
              <option value="23001041">ENSENADA DE LA HAMACA - MONTERIA - CORDOBA</option>
              <option value="23001042">GALILEA - MONTERIA - CORDOBA</option>
              <option value="23001043">LA ESPERANZA - MONTERIA - CORDOBA</option>
              <option value="23001044">LA FLORIDA - MONTERIA - CORDOBA</option>
              <option value="23001045">MAQUENCAL - MONTERIA - CORDOBA</option>
              <option value="23001046">MARACAYO - MONTERIA - CORDOBA</option>
              <option value="23001047">MATAMOROS - MONTERIA - CORDOBA</option>
              <option value="23001048">MOCHILAS - MONTERIA - CORDOBA</option>
              <option value="23001049">NUEVOS HORIZONTES - MONTERIA - CORDOBA</option>
              <option value="23001050">PALMITO PICAO - MONTERIA - CORDOBA</option>
              <option value="23001051">PEREIRA - MONTERIA - CORDOBA</option>
              <option value="23001052">VILLAVICENCIO - MONTERIA - CORDOBA</option>
              <option value="23001053">YA LA LLEVA - MONTERIA - CORDOBA</option>
              <option value="23001054">AGUA VIVAS - MONTERIA - CORDOBA</option>
              <option value="23001055">ARENAL - MONTERIA - CORDOBA</option>
              <option value="23001062">EL TAPAO - MONTERIA - CORDOBA</option>
              <option value="23001063">LA LUCHA - MONTERIA - CORDOBA</option>
              <option value="23001064">LA POZA - MONTERIA - CORDOBA</option>
              <option value="23001065">LOS CEDROS - MONTERIA - CORDOBA</option>
              <option value="23001066">LOS PANTANOS - MONTERIA - CORDOBA</option>
              <option value="23001068">EL DOCE - MONTERIA - CORDOBA</option>
              <option value="23001069">PUEBLO SECO - MONTERIA - CORDOBA</option>
              <option value="23001070">SAN FRANCISCO - MONTERIA - CORDOBA</option>
              <option value="23001072">EL FLORAL - MONTERIA - CORDOBA</option>
              <option value="23001073">MEDELLIN - SAPO - MONTERIA - CORDOBA</option>
              <option value="23001074">SAN JERONIMO (GOLERO) - MONTERIA - CORDOBA</option>
              <option value="23068000">AYAPEL - CORDOBA</option>
              <option value="23068001">ALFONSO LOPEZ - AYAPEL - CORDOBA</option>
              <option value="23068003">CECILIA - AYAPEL - CORDOBA</option>
              <option value="23068004">EL CEDRO - AYAPEL - CORDOBA</option>
              <option value="23068006">NARIÑO - AYAPEL - CORDOBA</option>
              <option value="23068007">PALOTAL - AYAPEL - CORDOBA</option>
              <option value="23068009">SINCELEJITO - AYAPEL - CORDOBA</option>
              <option value="23068012">MARRALU - AYAPEL - CORDOBA</option>
              <option value="23068014">PUEBLO NUEVO - POPALES - AYAPEL - CORDOBA</option>
              <option value="23068015">LAS DELICIAS - AYAPEL - CORDOBA</option>
              <option value="23079000">BUENAVISTA - CORDOBA</option>
              <option value="23079001">TIERRA SANTA - BUENAVISTA - CORDOBA</option>
              <option value="23079002">VILLA FATIMA - BUENAVISTA - CORDOBA</option>
              <option value="23079003">BELEN - BUENAVISTA - CORDOBA</option>
              <option value="23079004">NUEVA ESTACION - BUENAVISTA - CORDOBA</option>
              <option value="23079005">PUERTO CORDOBA - BUENAVISTA - CORDOBA</option>
              <option value="23079010">MEJOR ESQUINA - BUENAVISTA - CORDOBA</option>
              <option value="23079012">EL VIAJANO - BUENAVISTA - CORDOBA</option>
              <option value="23079014">VERACRUZ - BUENAVISTA - CORDOBA</option>
              <option value="23079015">SANTA CLARA - BUENAVISTA - CORDOBA</option>
              <option value="23079016">SANTA FE DEL ARCIAL 1 - BUENAVISTA - CORDOBA</option>
              <option value="23079017">COYONPO - BUENAVISTA - CORDOBA</option>
              <option value="23079019">LAS MARIAS - BUENAVISTA - CORDOBA</option>
              <option value="23079020">SANTA FE DE ARCIAL 2 - BUENAVISTA - CORDOBA</option>
              <option value="23090000">CANALETE - CORDOBA</option>
              <option value="23090001">EL LIMON - CANALETE - CORDOBA</option>
              <option value="23090002">POPAYAN - CANALETE - CORDOBA</option>
              <option value="23090007">CADILLO - CANALETE - CORDOBA</option>
              <option value="23090013">EL GUINEO - CANALETE - CORDOBA</option>
              <option value="23090020">TIERRADENTRO - CANALETE - CORDOBA</option>
              <option value="23090021">QUEBRADA DE URANGO - CANALETE - CORDOBA</option>
              <option value="23090022">BUENOS AIRES - LAS PAVAS - CANALETE - CORDOBA</option>
              <option value="23090023">EL TOMATE - CANALETE - CORDOBA</option>
              <option value="23162000">CERETE - CORDOBA</option>
              <option value="23162001">MARTINEZ - CERETE - CORDOBA</option>
              <option value="23162002">MATEO GOMEZ - CERETE - CORDOBA</option>
              <option value="23162003">RABOLARGO - CERETE - CORDOBA</option>
              <option value="23162004">SEVERA - CERETE - CORDOBA</option>
              <option value="23162005">CUERO CURTIDO - CERETE - CORDOBA</option>
              <option value="23162006">RETIRO DE LOS INDIOS - CERETE - CORDOBA</option>
              <option value="23162010">EL CHORRILLO - CERETE - CORDOBA</option>
              <option value="23162012">SAN ANTONIO - CERETE - CORDOBA</option>
              <option value="23162013">EL CEDRO - CERETE - CORDOBA</option>
              <option value="23162014">ZARZALITO - CERETE - CORDOBA</option>
              <option value="23162016">MANGUELITO - CERETE - CORDOBA</option>
              <option value="23162018">EL QUEMADO - CERETE - CORDOBA</option>
              <option value="23162023">LA ESMERALDA - CERETE - CORDOBA</option>
              <option value="23162025">BUENAVISTA DEL RETIRO - CERETE - CORDOBA</option>
              <option value="23162026">BUENAVISTA EL QUEMADO - CERETE - CORDOBA</option>
              <option value="23162027">EL CARMEN - CERETE - CORDOBA</option>
              <option value="23162032">RUSIA - CERETE - CORDOBA</option>
              <option value="23162033">CAROLINA - CERETE - CORDOBA</option>
              <option value="23168000">CHIMA - CORDOBA</option>
              <option value="23168001">ARACHE - CHIMA - CORDOBA</option>
              <option value="23168002">CAMPO BELLO - CHIMA - CORDOBA</option>
              <option value="23168003">CAROLINA - CHIMA - CORDOBA</option>
              <option value="23168004">COROZALITO - CHIMA - CORDOBA</option>
              <option value="23168005">PUNTA VERDE - CHIMA - CORDOBA</option>
              <option value="23168006">SITIO VIEJO - CHIMA - CORDOBA</option>
              <option value="23168008">SABANACOSTA - CHIMA - CORDOBA</option>
              <option value="23168017">PIMENTAL SECTOR BURRO MUERTO - CHIMA - CORDOBA</option>
              <option value="23182000">CHINU - CORDOBA</option>
              <option value="23182001">AGUAS VIVAS - CHINU - CORDOBA</option>
              <option value="23182002">CACAOTAL - CHINU - CORDOBA</option>
              <option value="23182003">CARBONERO - CHINU - CORDOBA</option>
              <option value="23182005">HEREDIA - CHINU - CORDOBA</option>
              <option value="23182006">LOS ANGELES - CHINU - CORDOBA</option>
              <option value="23182007">NUEVO ORIENTE - CHINU - CORDOBA</option>
              <option value="23182008">SAN MATEO - CHINU - CORDOBA</option>
              <option value="23182009">SAN RAFAEL - CHINU - CORDOBA</option>
              <option value="23182011">SANTA FE - CHINU - CORDOBA</option>
              <option value="23182012">SANTA ROSA - CHINU - CORDOBA</option>
              <option value="23182013">FLECHAS SEVILLA - CHINU - CORDOBA</option>
              <option value="23182014">TIERRA GRATA - CHINU - CORDOBA</option>
              <option value="23182015">FLECHAS SABANAS - CHINU - CORDOBA</option>
              <option value="23182016">GARBADO - CHINU - CORDOBA</option>
              <option value="23182017">LA PANAMA - CHINU - CORDOBA</option>
              <option value="23182018">LA PILONA - CHINU - CORDOBA</option>
              <option value="23182019">RETIRO DE LOS PEREZ - CHINU - CORDOBA</option>
              <option value="23182020">ANDALUCIA - CHINU - CORDOBA</option>
              <option value="23182021">LOS ALGARROBOS - CHINU - CORDOBA</option>
              <option value="23182022">EL TIGRE - CHINU - CORDOBA</option>
              <option value="23182024">VILLA FATIMA - CHINU - CORDOBA</option>
              <option value="23182027">EL DESEO - CHINU - CORDOBA</option>
              <option value="23182031">PARAISO - CHINU - CORDOBA</option>
              <option value="23182033">LAS JARABAS - CHINU - CORDOBA</option>
              <option value="23182035">PAJONAL - CHINU - CORDOBA</option>
              <option value="23182036">PISA BONITO - CHINU - CORDOBA</option>
              <option value="23182041">LOMAS DE PIEDRA - CHINU - CORDOBA</option>
              <option value="23182043">BAJO DE PIEDRA - CHINU - CORDOBA</option>
              <option value="23189000">CIENAGA DE ORO - CORDOBA</option>
              <option value="23189001">BERASTEGUI - CIENAGA DE ORO - CORDOBA</option>
              <option value="23189003">LAGUNETA - CIENAGA DE ORO - CORDOBA</option>
              <option value="23189004">LOS MIMBRES - CIENAGA DE ORO - CORDOBA</option>
              <option value="23189005">PUNTA DE YAÑEZ - CIENAGA DE ORO - CORDOBA</option>
              <option value="23189007">PUERTO DE LA CRUZ - CIENAGA DE ORO - CORDOBA</option>
              <option value="23189008">MALAGANA - CIENAGA DE ORO - CORDOBA</option>
              <option value="23189013">SUAREZ - CIENAGA DE ORO - CORDOBA</option>
              <option value="23189016">EL SALADO - CIENAGA DE ORO - CORDOBA</option>
              <option value="23189018">LAS PIEDRAS - CIENAGA DE ORO - CORDOBA</option>
              <option value="23189019">PIJIGUAYAL - CIENAGA DE ORO - CORDOBA</option>
              <option value="23189021">SANTIAGO POBRE - CIENAGA DE ORO - CORDOBA</option>
              <option value="23189023">SAN ANTONIO DEL TACHIRA - CIENAGA DE ORO - CORDOBA</option>
              <option value="23189024">ROSA VIEJA - CIENAGA DE ORO - CORDOBA</option>
              <option value="23189025">LAS PALMITAS - CIENAGA DE ORO - CORDOBA</option>
              <option value="23189028">LAS BALSAS - CIENAGA DE ORO - CORDOBA</option>
              <option value="23189029">EGIPTO - CIENAGA DE ORO - CORDOBA</option>
              <option value="23189030">BARRO PRIETO - CIENAGA DE ORO - CORDOBA</option>
              <option value="23189034">LA DRAGA - CIENAGA DE ORO - CORDOBA</option>
              <option value="23189044">LA ESPERANZA - CIENAGA DE ORO - CORDOBA</option>
              <option value="23189045">SANTIAGUITO - CIENAGA DE ORO - CORDOBA</option>
              <option value="23300000">COTORRA - CORDOBA</option>
              <option value="23300003">LOS CEDROS - COTORRA - CORDOBA</option>
              <option value="23300006">PASO DE LAS FLORES - COTORRA - CORDOBA</option>
              <option value="23300007">VILLA NUEVA - COTORRA - CORDOBA</option>
              <option value="23300008">ABROJAL - COTORRA - CORDOBA</option>
              <option value="23300010">EL BINDE - COTORRA - CORDOBA</option>
              <option value="23300011">CAIMAN - COTORRA - CORDOBA</option>
              <option value="23300015">LAS AREPAS - COTORRA - CORDOBA</option>
              <option value="23300016">TREMENTINO - COTORRA - CORDOBA</option>
              <option value="23300020">MORALITO - COTORRA - CORDOBA</option>
              <option value="23300021">SAN JOSE - COTORRA - CORDOBA</option>
              <option value="23300026">PUEBLO NUEVO - COTORRA - CORDOBA</option>
              <option value="23300027">SAN PABLO - COTORRA - CORDOBA</option>
              <option value="23350000">LA APARTADA - CORDOBA</option>
              <option value="23350003">LA BALSA - LA APARTADA - CORDOBA</option>
              <option value="23350007">SITIO NUEVO - LA APARTADA - CORDOBA</option>
              <option value="23350015">CAMPO ALEGRE - LA APARTADA - CORDOBA</option>
              <option value="23350017">PUERTO CORDOBA - LA APARTADA - CORDOBA</option>
              <option value="23417000">SANTA CRUZ DE LORICA - LORICA - CORDOBA</option>
              <option value="23417002">EL CARITO - LORICA - CORDOBA</option>
              <option value="23417003">LA DOCTRINA - LORICA - CORDOBA</option>
              <option value="23417004">LAS FLORES - LORICA - CORDOBA</option>
              <option value="23417005">LOS GOMEZ - LORICA - CORDOBA</option>
              <option value="23417006">LOS MONOS - LORICA - CORDOBA</option>
              <option value="23417007">NARIÑO - LORICA - CORDOBA</option>
              <option value="23417008">PALO DE AGUA - LORICA - CORDOBA</option>
              <option value="23417009">SAN SEBASTIAN - LORICA - CORDOBA</option>
              <option value="23417010">TIERRALTA - LORICA - CORDOBA</option>
              <option value="23417012">EL LAZO - LORICA - CORDOBA</option>
              <option value="23417014">CAMPO ALEGRE - LORICA - CORDOBA</option>
              <option value="23417017">EL CAMPANO DE LOS INDIOS - LORICA - CORDOBA</option>
              <option value="23417018">COTOCA ARRIBA - LORICA - CORDOBA</option>
              <option value="23417019">EL RODEO - LORICA - CORDOBA</option>
              <option value="23417021">REMOLINO - LORICA - CORDOBA</option>
              <option value="23417022">VILLA CONCEPCION - LORICA - CORDOBA</option>
              <option value="23417023">MATA DE CAÑA - LORICA - CORDOBA</option>
              <option value="23417024">CASTILLERAL - LORICA - CORDOBA</option>
              <option value="23417025">COTOCA ABAJO - LORICA - CORDOBA</option>
              <option value="23417027">SAN NICOLAS DE BARI - LORICA - CORDOBA</option>
              <option value="23417029">LA SUBIDA - LORICA - CORDOBA</option>
              <option value="23417030">EL PLAYON - LORICA - CORDOBA</option>
              <option value="23417031">LA PEINADA - LORICA - CORDOBA</option>
              <option value="23417034">SANTA LUCIA - LORICA - CORDOBA</option>
              <option value="23417036">LA PALMA - LORICA - CORDOBA</option>
              <option value="23417037">LOS MORALES - LORICA - CORDOBA</option>
              <option value="23417039">EL GUANABANO - LORICA - CORDOBA</option>
              <option value="23417042">JUAN DE DIOS GARI - LORICA - CORDOBA</option>
              <option value="23419000">LOS CORDOBAS - CORDOBA</option>
              <option value="23419002">EL EBANO - LOS CORDOBAS - CORDOBA</option>
              <option value="23419004">PUERTO REY - LOS CORDOBAS - CORDOBA</option>
              <option value="23419005">SANTA ROSA LA CAÑA - LOS CORDOBAS - CORDOBA</option>
              <option value="23419010">BUENAVISTA - LOS CORDOBAS - CORDOBA</option>
              <option value="23419011">LA SALADA - LOS CORDOBAS - CORDOBA</option>
              <option value="23419012">MORINDO SANTANA - LOS CORDOBAS - CORDOBA</option>
              <option value="23419013">JALISCO - LOS CORDOBAS - CORDOBA</option>
              <option value="23419019">EL GUAIMARO - LOS CORDOBAS - CORDOBA</option>
              <option value="23419020">LA APONDERANCIA - LOS CORDOBAS - CORDOBA</option>
              <option value="23419025">LOS ESQUIMALES - LOS CORDOBAS - CORDOBA</option>
              <option value="23419026">MINUTO DE DIOS - LOS CORDOBAS - CORDOBA</option>
              <option value="23419030">NUEVO NARIÑO - LOS CORDOBAS - CORDOBA</option>
              <option value="23464000">MOMIL - CORDOBA</option>
              <option value="23464001">SABANETA - MOMIL - CORDOBA</option>
              <option value="23464002">SACANA - MOMIL - CORDOBA</option>
              <option value="23464003">TREMENTINO - MOMIL - CORDOBA</option>
              <option value="23464005">PUEBLECITO - MOMIL - CORDOBA</option>
              <option value="23464006">GUAYMARAL - MOMIL - CORDOBA</option>
              <option value="23464007">BETULIA - MOMIL - CORDOBA</option>
              <option value="23466000">MONTELIBANO - CORDOBA</option>
              <option value="23466001">EL ANCLAR - MONTELIBANO - CORDOBA</option>
              <option value="23466005">SAN FRANCISCO DEL RAYO - MONTELIBANO - CORDOBA</option>
              <option value="23466006">TIERRADENTRO - MONTELIBANO - CORDOBA</option>
              <option value="23466008">PICA PICA NUEVO - MONTELIBANO - CORDOBA</option>
              <option value="23466021">PUERTO ANCHICA - MONTELIBANO - CORDOBA</option>
              <option value="23466023">CORDOBA - MONTELIBANO - CORDOBA</option>
              <option value="23466028">EL PALMAR - MONTELIBANO - CORDOBA</option>
              <option value="23466031">LAS MARGARITAS - MONTELIBANO - CORDOBA</option>
              <option value="23466033">PUERTO NUEVO - MONTELIBANO - CORDOBA</option>
              <option value="23466037">VILLA CARMINIA - MONTELIBANO - CORDOBA</option>
              <option value="23500000">MOÑITOS - CORDOBA</option>
              <option value="23500001">RIO CEDRO - MOÑITOS - CORDOBA</option>
              <option value="23500002">SANTANDER DE LA CRUZ - MOÑITOS - CORDOBA</option>
              <option value="23500003">LA UNION - MOÑITOS - CORDOBA</option>
              <option value="23500004">BAJO DEL LIMON - MOÑITOS - CORDOBA</option>
              <option value="23500005">BELLA COHITA - MOÑITOS - CORDOBA</option>
              <option value="23500006">BROQUELES - MOÑITOS - CORDOBA</option>
              <option value="23500010">LA RADA - MOÑITOS - CORDOBA</option>
              <option value="23500011">LAS MUJERES - MOÑITOS - CORDOBA</option>
              <option value="23500016">NARANJAL - MOÑITOS - CORDOBA</option>
              <option value="23500023">PERPETUO SOCORRO - MOÑITOS - CORDOBA</option>
              <option value="23500024">PUEBLITO - MOÑITOS - CORDOBA</option>
              <option value="23500029">SAN ANTERITO - MOÑITOS - CORDOBA</option>
              <option value="23555000">PLANETA RICA - CORDOBA</option>
              <option value="23555001">ARENOSO - PLANETA RICA - CORDOBA</option>
              <option value="23555002">CAMPO BELLO - PLANETA RICA - CORDOBA</option>
              <option value="23555003">CAROLINA - PLANETA RICA - CORDOBA</option>
              <option value="23555004">SANTANA (CENTRO ALEGRE) - PLANETA RICA - CORDOBA</option>
              <option value="23555005">EL ALMENDRO - PLANETA RICA - CORDOBA</option>
              <option value="23555006">MARAÑONAL - PLANETA RICA - CORDOBA</option>
              <option value="23555007">PLAZA BONITA - PLANETA RICA - CORDOBA</option>
              <option value="23555008">PROVIDENCIA - PLANETA RICA - CORDOBA</option>
              <option value="23555010">MEDIO RANCHO - PLANETA RICA - CORDOBA</option>
              <option value="23555011">PAMPLONA - PLANETA RICA - CORDOBA</option>
              <option value="23555012">EL REPARO - PLANETA RICA - CORDOBA</option>
              <option value="23555013">LOS CERROS - PLANETA RICA - CORDOBA</option>
              <option value="23555015">LAS PELONAS - PLANETA RICA - CORDOBA</option>
              <option value="23555019">NUEVO PARAISO - PLANETA RICA - CORDOBA</option>
              <option value="23555020">SANTA ROSA - PLANETA RICA - CORDOBA</option>
              <option value="23555024">ARROYO ARENA - PLANETA RICA - CORDOBA</option>
              <option value="23555030">EL GUAYABO - PLANETA RICA - CORDOBA</option>
              <option value="23555035">LOMA DE PIEDRA - PLANETA RICA - CORDOBA</option>
              <option value="23555044">PUNTA VERDE - PLANETA RICA - CORDOBA</option>
              <option value="23555047">SANTA ANA - PLANETA RICA - CORDOBA</option>
              <option value="23570000">PUEBLO NUEVO - CORDOBA</option>
              <option value="23570002">SAN JOSE DE CINTURA - PUEBLO NUEVO - CORDOBA</option>
              <option value="23570003">CORCOVAO - PUEBLO NUEVO - CORDOBA</option>
              <option value="23570004">EL VARAL - PUEBLO NUEVO - CORDOBA</option>
              <option value="23570005">EL POBLADO - PUEBLO NUEVO - CORDOBA</option>
              <option value="23570006">LA GRANJITA - PUEBLO NUEVO - CORDOBA</option>
              <option value="23570007">LOS LIMONES - PUEBLO NUEVO - CORDOBA</option>
              <option value="23570008">PUERTO SANTO - PUEBLO NUEVO - CORDOBA</option>
              <option value="23570009">LA MAGDALENA - PUEBLO NUEVO - CORDOBA</option>
              <option value="23570011">PALMIRA - PUEBLO NUEVO - CORDOBA</option>
              <option value="23570013">NEIVA - PUEBLO NUEVO - CORDOBA</option>
              <option value="23570014">ARROYO DE ARENAS - PUEBLO NUEVO - CORDOBA</option>
              <option value="23570016">EL CONTENTO - PUEBLO NUEVO - CORDOBA</option>
              <option value="23570017">PRIMAVERA - PUEBLO NUEVO - CORDOBA</option>
              <option value="23570018">BETANIA - PUEBLO NUEVO - CORDOBA</option>
              <option value="23570020">EL CAMPANO - PUEBLO NUEVO - CORDOBA</option>
              <option value="23570024">NUEVA ESPERANZA - PUEBLO NUEVO - CORDOBA</option>
              <option value="23570026">CAFE PISAO - PUEBLO NUEVO - CORDOBA</option>
              <option value="23570028">LOMA DE PIEDRA - PUEBLO NUEVO - CORDOBA</option>
              <option value="23570029">APARTADA DE BETULIA - PUEBLO NUEVO - CORDOBA</option>
              <option value="23570030">EL CORRAL - PUEBLO NUEVO - CORDOBA</option>
              <option value="23570031">EL CHIPAL - PUEBLO NUEVO - CORDOBA</option>
              <option value="23570032">EL DESEO - PUEBLO NUEVO - CORDOBA</option>
              <option value="23570033">EL TOCHE - PUEBLO NUEVO - CORDOBA</option>
              <option value="23570034">VILLA ESPERANZA - PUEBLO NUEVO - CORDOBA</option>
              <option value="23574000">PUERTO ESCONDIDO - CORDOBA</option>
              <option value="23574001">CRISTO REY - PUERTO ESCONDIDO - CORDOBA</option>
              <option value="23574002">EL PANTANO - PUERTO ESCONDIDO - CORDOBA</option>
              <option value="23574003">SAN JOSE DE CANALETE - PUERTO ESCONDIDO - CORDOBA</option>
              <option value="23574004">VILLA ESTER - PUERTO ESCONDIDO - CORDOBA</option>
              <option value="23574005">ARIZAL - PUERTO ESCONDIDO - CORDOBA</option>
              <option value="23574006">SAN LUIS - PUERTO ESCONDIDO - CORDOBA</option>
              <option value="23574009">LAS MUJERES - PUERTO ESCONDIDO - CORDOBA</option>
              <option value="23574011">EL SILENCIO - PUERTO ESCONDIDO - CORDOBA</option>
              <option value="23574012">SAN MIGUEL - PUERTO ESCONDIDO - CORDOBA</option>
              <option value="23574014">SANTA ISABEL - PUERTO ESCONDIDO - CORDOBA</option>
              <option value="23580000">PUERTO LIBERTADOR - CORDOBA</option>
              <option value="23580001">LA RICA - PUERTO LIBERTADOR - CORDOBA</option>
              <option value="23580002">PICA PICA VIEJO - PUERTO LIBERTADOR - CORDOBA</option>
              <option value="23580003">VILLANUEVA - PUERTO LIBERTADOR - CORDOBA</option>
              <option value="23580004">JUAN JOSE - PUERTO LIBERTADOR - CORDOBA</option>
              <option value="23580006">BUENOS AIRES - PUERTO LIBERTADOR - CORDOBA</option>
              <option value="23580009">SANTA FE DE LAS CLARAS - PUERTO LIBERTADOR - CORDOBA</option>
              <option value="23580010">SAN JUAN - PUERTO LIBERTADOR - CORDOBA</option>
              <option value="23580011">PUERTO BELEN - PUERTO LIBERTADOR - CORDOBA</option>
              <option value="23580012">EL BRILLANTE - PUERTO LIBERTADOR - CORDOBA</option>
              <option value="23580014">PUERTO CAREPA - PUERTO LIBERTADOR - CORDOBA</option>
              <option value="23580015">TORNO ROJO - PUERTO LIBERTADOR - CORDOBA</option>
              <option value="23580016">CENTRO AMERICA - PUERTO LIBERTADOR - CORDOBA</option>
              <option value="23580017">COROSALITO - PUERTO LIBERTADOR - CORDOBA</option>
              <option value="23580018">NUEVA ESPERANZA - PUERTO LIBERTADOR - CORDOBA</option>
              <option value="23580019">SIETE DE AGOSTO - PUERTO LIBERTADOR - CORDOBA</option>
              <option value="23580020">VILLA ESPERANZA - PUERTO LIBERTADOR - CORDOBA</option>
              <option value="23586000">PURISIMA DE LA CONCEPCION - CORDOBA</option>
              <option value="23586001">ASERRADERO - PURISIMA DE LA CONCEPCION - CORDOBA</option>
              <option value="23586002">EL HUESO - PURISIMA DE LA CONCEPCION - CORDOBA</option>
              <option value="23586003">LOS CORRALES - PURISIMA DE LA CONCEPCION - CORDOBA</option>
              <option value="23586004">SAN PEDRO DE ARROYO HONDO - PURISIMA DE LA CONCEPCION - CORDOBA</option>
              <option value="23586005">ARENAL - PURISIMA DE LA CONCEPCION - CORDOBA</option>
              <option value="23586006">COMEJEN - PURISIMA DE LA CONCEPCION - CORDOBA</option>
              <option value="23586007">CERROPETRONA - PURISIMA DE LA CONCEPCION - CORDOBA</option>
              <option value="23660000">SAHAGUN - CORDOBA</option>
              <option value="23660001">ARENAS DEL NORTE - SAHAGUN - CORDOBA</option>
              <option value="23660002">BAJO GRANDE - SAHAGUN - CORDOBA</option>
              <option value="23660003">CATALINA - SAHAGUN - CORDOBA</option>
              <option value="23660004">COLOMBOY - SAHAGUN - CORDOBA</option>
              <option value="23660005">EL CRUCERO - SAHAGUN - CORDOBA</option>
              <option value="23660006">EL VIAJANO - SAHAGUN - CORDOBA</option>
              <option value="23660007">LLANADAS - SAHAGUN - CORDOBA</option>
              <option value="23660008">LA YE - SAHAGUN - CORDOBA</option>
              <option value="23660009">MORROCOY - SAHAGUN - CORDOBA</option>
              <option value="23660010">RODANIA - SAHAGUN - CORDOBA</option>
              <option value="23660011">SALITRAL - SAHAGUN - CORDOBA</option>
              <option value="23660012">SAN ANTONIO - SAHAGUN - CORDOBA</option>
              <option value="23660013">SANTIAGO ABAJO - SAHAGUN - CORDOBA</option>
              <option value="23660014">SABANETA - SAHAGUN - CORDOBA</option>
              <option value="23660015">AGUAS VIVAS - SAHAGUN - CORDOBA</option>
              <option value="23660016">LAS BOCAS - SAHAGUN - CORDOBA</option>
              <option value="23660017">PISA FLORES - SAHAGUN - CORDOBA</option>
              <option value="23660020">EL ROBLE - SAHAGUN - CORDOBA</option>
              <option value="23660022">EL OLIVO - SAHAGUN - CORDOBA</option>
              <option value="23660023">BRUSELAS - SAHAGUN - CORDOBA</option>
              <option value="23660024">LOS BARRILES - SAHAGUN - CORDOBA</option>
              <option value="23660025">EL REMOLINO - SAHAGUN - CORDOBA</option>
              <option value="23660028">GUAIMARITO - SAHAGUN - CORDOBA</option>
              <option value="23660029">TREMENTINO - SAHAGUN - CORDOBA</option>
              <option value="23660030">GUAIMARO - SAHAGUN - CORDOBA</option>
              <option value="23660031">LA BALSA - SAHAGUN - CORDOBA</option>
              <option value="23660032">LAS AGUADITAS - SAHAGUN - CORDOBA</option>
              <option value="23660034">SAN ANDRESITO - SAHAGUN - CORDOBA</option>
              <option value="23660037">DIVIDIVI - SAHAGUN - CORDOBA</option>
              <option value="23660040">SALGUERITO - SAHAGUN - CORDOBA</option>
              <option value="23660047">KILOMETRO 32 - SAHAGUN - CORDOBA</option>
              <option value="23660048">KILOMETRO 34 - SAHAGUN - CORDOBA</option>
              <option value="23660054">LA MUSICA - SAHAGUN - CORDOBA</option>
              <option value="23660061">SAN FRANCISCO - SAHAGUN - CORDOBA</option>
              <option value="23660064">LA QUEBRADA - SAHAGUN - CORDOBA</option>
              <option value="23660066">LOS CHIBOLOS - SAHAGUN - CORDOBA</option>
              <option value="23660067">NUEVA ESPERANZA - SAHAGUN - CORDOBA</option>
              <option value="23660068">SABANA DE LA FUENTE - SAHAGUN - CORDOBA</option>
              <option value="23670000">SAN ANDRES DE SOTAVENTO - CORDOBA</option>
              <option value="23670002">CALLE LARGA - SAN ANDRES DE SOTAVENTO - CORDOBA</option>
              <option value="23670003">EL BANCO - SAN ANDRES DE SOTAVENTO - CORDOBA</option>
              <option value="23670005">LOS CARRETOS - SAN ANDRES DE SOTAVENTO - CORDOBA</option>
              <option value="23670009">PUEBLECITO SUR - SAN ANDRES DE SOTAVENTO - CORDOBA</option>
              <option value="23670013">PLAZA BONITA - SAN ANDRES DE SOTAVENTO - CORDOBA</option>
              <option value="23670014">LAS CASITAS - SAN ANDRES DE SOTAVENTO - CORDOBA</option>
              <option value="23670015">LOS CASTILLOS - SAN ANDRES DE SOTAVENTO - CORDOBA</option>
              <option value="23670021">EL CONTENTO - SAN ANDRES DE SOTAVENTO - CORDOBA</option>
              <option value="23670023">JEJEN - SAN ANDRES DE SOTAVENTO - CORDOBA</option>
              <option value="23670025">CRUZ DE GUAYABO - SAN ANDRES DE SOTAVENTO - CORDOBA</option>
              <option value="23670026">EL HOYAL - SAN ANDRES DE SOTAVENTO - CORDOBA</option>
              <option value="23670028">BERLIN - SAN ANDRES DE SOTAVENTO - CORDOBA</option>
              <option value="23670029">GARDENIA - SAN ANDRES DE SOTAVENTO - CORDOBA</option>
              <option value="23670030">PATIO BONITO NORTE - SAN ANDRES DE SOTAVENTO - CORDOBA</option>
              <option value="23670031">PATIO BONITO SUR - SAN ANDRES DE SOTAVENTO - CORDOBA</option>
              <option value="23670032">SAN GREGORIO - SAN ANDRES DE SOTAVENTO - CORDOBA</option>
              <option value="23672000">SAN ANTERO - CORDOBA</option>
              <option value="23672001">EL PORVENIR - SAN ANTERO - CORDOBA</option>
              <option value="23672003">NUEVO AGRADO - SAN ANTERO - CORDOBA</option>
              <option value="23672004">SANTA ROSA DEL BALSAMO - SAN ANTERO - CORDOBA</option>
              <option value="23672005">TIJERETAS - SAN ANTERO - CORDOBA</option>
              <option value="23672006">BIJAITO - SAN ANTERO - CORDOBA</option>
              <option value="23672009">CALAO - SAN ANTERO - CORDOBA</option>
              <option value="23672011">CISPATA - SAN ANTERO - CORDOBA</option>
              <option value="23672012">EL NARANJO - SAN ANTERO - CORDOBA</option>
              <option value="23672013">EL PROGRESO - SAN ANTERO - CORDOBA</option>
              <option value="23672015">GRAU - SAN ANTERO - CORDOBA</option>
              <option value="23672016">LA BONGUITA - SAN ANTERO - CORDOBA</option>
              <option value="23672017">LA PARRILLA - SAN ANTERO - CORDOBA</option>
              <option value="23672019">LAS NUBES - SAN ANTERO - CORDOBA</option>
              <option value="23672020">LETICIA - SAN ANTERO - CORDOBA</option>
              <option value="23672021">PLAYA BLANCA - SAN ANTERO - CORDOBA</option>
              <option value="23672022">PUNTA BOLIVAR - SAN ANTERO - CORDOBA</option>
              <option value="23672023">SAN JOSE - SAN ANTERO - CORDOBA</option>
              <option value="23672024">SANTA CRUZ - SAN ANTERO - CORDOBA</option>
              <option value="23672025">BERNARDO ESCOBAR - SAN ANTERO - CORDOBA</option>
              <option value="23672026">MIRIAM PARDO - SAN ANTERO - CORDOBA</option>
              <option value="23672027">SAN MARTIN 1 - SAN ANTERO - CORDOBA</option>
              <option value="23672028">SAN MARTIN 2 - SAN ANTERO - CORDOBA</option>
              <option value="23672029">NARANJO 1 - SAN ANTERO - CORDOBA</option>
              <option value="23675000">SAN BERNARDO DEL VIENTO - CORDOBA</option>
              <option value="23675001">JOSE MANUEL DE ALTAMIRA - SAN BERNARDO DEL VIENTO - CORDOBA</option>
              <option value="23675003">PASO NUEVO - SAN BERNARDO DEL VIENTO - CORDOBA</option>
              <option value="23675005">PLAYAS DEL VIENTO - SAN BERNARDO DEL VIENTO - CORDOBA</option>
              <option value="23675007">TREMENTINO - SAN BERNARDO DEL VIENTO - CORDOBA</option>
              <option value="23675009">SAN BLAS DE JUNIN - SAN BERNARDO DEL VIENTO - CORDOBA</option>
              <option value="23675012">CHIQUI - SAN BERNARDO DEL VIENTO - CORDOBA</option>
              <option value="23675013">PAJONAL - SAN BERNARDO DEL VIENTO - CORDOBA</option>
              <option value="23675014">SAN JOSE DE LAS CAÑAS - SAN BERNARDO DEL VIENTO - CORDOBA</option>
              <option value="23675015">MIRAMAR - SAN BERNARDO DEL VIENTO - CORDOBA</option>
              <option value="23675018">CAMINO REAL - SAN BERNARDO DEL VIENTO - CORDOBA</option>
              <option value="23675019">EL CASTILLO - SAN BERNARDO DEL VIENTO - CORDOBA</option>
              <option value="23675021">TINAJONES - SAN BERNARDO DEL VIENTO - CORDOBA</option>
              <option value="23675023">EL DARIEN - SAN BERNARDO DEL VIENTO - CORDOBA</option>
              <option value="23675024">SANTA INES DE MONTERO - SAN BERNARDO DEL VIENTO - CORDOBA</option>
              <option value="23678000">SAN CARLOS - CORDOBA</option>
              <option value="23678001">EL CAMPANO - SAN CARLOS - CORDOBA</option>
              <option value="23678002">CARRIZAL - SAN CARLOS - CORDOBA</option>
              <option value="23678003">GUACHARACAL - SAN CARLOS - CORDOBA</option>
              <option value="23678004">SANTA ROSA - SAN CARLOS - CORDOBA</option>
              <option value="23678005">REMEDIO POBRE - SAN CARLOS - CORDOBA</option>
              <option value="23678006">CABUYA - SAN CARLOS - CORDOBA</option>
              <option value="23678008">CALLEMAR - SAN CARLOS - CORDOBA</option>
              <option value="23678009">CIENAGUITA - SAN CARLOS - CORDOBA</option>
              <option value="23678010">EL HATO - SAN CARLOS - CORDOBA</option>
              <option value="23678011">SAN MIGUEL - SAN CARLOS - CORDOBA</option>
              <option value="23678012">EL CHARCO - SAN CARLOS - CORDOBA</option>
              <option value="23678016">CALLE LARGA - SAN CARLOS - CORDOBA</option>
              <option value="23678018">LAS TINAS - SAN CARLOS - CORDOBA</option>
              <option value="23678019">LOS CAÑOS - SAN CARLOS - CORDOBA</option>
              <option value="23682000">SAN JOSE DE URE - CORDOBA</option>
              <option value="23682002">BOCAS DE URE - SAN JOSE DE URE - CORDOBA</option>
              <option value="23682003">BRAZO IZQUIERDO - SAN JOSE DE URE - CORDOBA</option>
              <option value="23682004">PUEBLO FLECHAS - SAN JOSE DE URE - CORDOBA</option>
              <option value="23682005">LA DORADA - SAN JOSE DE URE - CORDOBA</option>
              <option value="23682006">VERSALLES - SAN JOSE DE URE - CORDOBA</option>
              <option value="23682007">VIERA ABAJO - SAN JOSE DE URE - CORDOBA</option>
              <option value="23682009">PUERTO COLOMBIA - SAN JOSE DE URE - CORDOBA</option>
              <option value="23686000">SAN PELAYO - CORDOBA</option>
              <option value="23686001">BUENOS AIRES - SAN PELAYO - CORDOBA</option>
              <option value="23686002">CARRILLO - SAN PELAYO - CORDOBA</option>
              <option value="23686003">LA MADERA - SAN PELAYO - CORDOBA</option>
              <option value="23686004">LAS GUAMAS - SAN PELAYO - CORDOBA</option>
              <option value="23686005">SABANA NUEVA - SAN PELAYO - CORDOBA</option>
              <option value="23686006">SAN ISIDRO - SAN PELAYO - CORDOBA</option>
              <option value="23686007">VALPARAISO - SAN PELAYO - CORDOBA</option>
              <option value="23686008">PUERTO NUEVO - SAN PELAYO - CORDOBA</option>
              <option value="23686009">PELAYITO - SAN PELAYO - CORDOBA</option>
              <option value="23686011">LAS LAURAS - SAN PELAYO - CORDOBA</option>
              <option value="23686012">EL BONGO - SAN PELAYO - CORDOBA</option>
              <option value="23686018">EL CHIQUI - SAN PELAYO - CORDOBA</option>
              <option value="23686020">RETIRO - SAN PELAYO - CORDOBA</option>
              <option value="23686021">EL OBLIGADO - SAN PELAYO - CORDOBA</option>
              <option value="23686022">BONGAS MELLAS - SAN PELAYO - CORDOBA</option>
              <option value="23686025">COROCITO - SAN PELAYO - CORDOBA</option>
              <option value="23686027">EL BALSAMO - SAN PELAYO - CORDOBA</option>
              <option value="23686029">EL COROZO - SAN PELAYO - CORDOBA</option>
              <option value="23686034">MORROCOY - SAN PELAYO - CORDOBA</option>
              <option value="23686036">PROVIDENCIA - SAN PELAYO - CORDOBA</option>
              <option value="23807000">TIERRALTA - CORDOBA</option>
              <option value="23807002">CARAMELO - TIERRALTA - CORDOBA</option>
              <option value="23807004">MANTAGORDAL - TIERRALTA - CORDOBA</option>
              <option value="23807005">NUEVA GRANADA - TIERRALTA - CORDOBA</option>
              <option value="23807006">EL SAIZA - TIERRALTA - CORDOBA</option>
              <option value="23807007">SANTA FE RALITO - TIERRALTA - CORDOBA</option>
              <option value="23807008">SEVERINERA - TIERRALTA - CORDOBA</option>
              <option value="23807010">VOLADOR - TIERRALTA - CORDOBA</option>
              <option value="23807017">FRASQUILLO NUEVO - TIERRALTA - CORDOBA</option>
              <option value="23807019">CARRIZOLA - TIERRALTA - CORDOBA</option>
              <option value="23807020">EL AGUILA - BATATA - TIERRALTA - CORDOBA</option>
              <option value="23807026">LOS MORALES - TIERRALTA - CORDOBA</option>
              <option value="23807027">SANTA MARTA - TIERRALTA - CORDOBA</option>
              <option value="23807028">VILLA PROVIDENCIA - TIERRALTA - CORDOBA</option>
              <option value="23807029">CRUCITO - TIERRALTA - CORDOBA</option>
              <option value="23807032">PUEBLO CEDRO - TIERRALTA - CORDOBA</option>
              <option value="23807040">CAMPO BELLO - TIERRALTA - CORDOBA</option>
              <option value="23807041">LAS DELICIAS - TIERRALTA - CORDOBA</option>
              <option value="23807042">SAN RAFAEL - TIERRALTA - CORDOBA</option>
              <option value="23807043">EL ROSARIO - TIERRALTA - CORDOBA</option>
              <option value="23807044">VIRGILIO VARGAS - TIERRALTA - CORDOBA</option>
              <option value="23807045">NUEVA ESPERANZA - TIERRALTA - CORDOBA</option>
              <option value="23815000">TUCHIN - CORDOBA</option>
              <option value="23815001">BARBACOAS - TUCHIN - CORDOBA</option>
              <option value="23815002">CRUZ CHIQUITA - TUCHIN - CORDOBA</option>
              <option value="23815004">FLECHAS - TUCHIN - CORDOBA</option>
              <option value="23815006">MOLINA - TUCHIN - CORDOBA</option>
              <option value="23815007">NUEVA ESTRELLA - TUCHIN - CORDOBA</option>
              <option value="23815009">SAN JUAN DE LA CRUZ - TUCHIN - CORDOBA</option>
              <option value="23815010">VIDALES - TUCHIN - CORDOBA</option>
              <option value="23815012">ANDES - TUCHIN - CORDOBA</option>
              <option value="23815013">BELEN - TUCHIN - CORDOBA</option>
              <option value="23815014">BELLA VISTA - TUCHIN - CORDOBA</option>
              <option value="23815015">BOMBA - TUCHIN - CORDOBA</option>
              <option value="23815016">CARRETAL - TUCHIN - CORDOBA</option>
              <option value="23815017">EL CARIÑITO - TUCHIN - CORDOBA</option>
              <option value="23815018">EL CARMEN - TUCHIN - CORDOBA</option>
              <option value="23815019">EL CHUZO - TUCHIN - CORDOBA</option>
              <option value="23815020">EL PORVENIR - TUCHIN - CORDOBA</option>
              <option value="23815021">EL ROBLE - TUCHIN - CORDOBA</option>
              <option value="23815022">GUAYACANES - TUCHIN - CORDOBA</option>
              <option value="23815023">LOVERAN - TUCHIN - CORDOBA</option>
              <option value="23815024">NUEVA ESPERANZA - TUCHIN - CORDOBA</option>
              <option value="23815025">NUEVA VIDA - TUCHIN - CORDOBA</option>
              <option value="23815026">SABANA NUEVA - TUCHIN - CORDOBA</option>
              <option value="23815027">SABANAL - TUCHIN - CORDOBA</option>
              <option value="23815028">SANTA CLARA - TUCHIN - CORDOBA</option>
              <option value="23815029">SANTANDER - TUCHIN - CORDOBA</option>
              <option value="23815030">TOLIMA - TUCHIN - CORDOBA</option>
              <option value="23815031">VILLANUEVA - TUCHIN - CORDOBA</option>
              <option value="23815032">EL BARZAL - TUCHIN - CORDOBA</option>
              <option value="23815033">LA GRANJA - TUCHIN - CORDOBA</option>
              <option value="23815034">SAN MARTIN - TUCHIN - CORDOBA</option>
              <option value="23815035">SANTA CRUZ - TUCHIN - CORDOBA</option>
              <option value="23855000">VALENCIA - CORDOBA</option>
              <option value="23855001">RIO NUEVO - VALENCIA - CORDOBA</option>
              <option value="23855003">VILLANUEVA - VALENCIA - CORDOBA</option>
              <option value="23855006">MATA DE MAIZ - VALENCIA - CORDOBA</option>
              <option value="23855009">EL REPOSO - VALENCIA - CORDOBA</option>
              <option value="23855014">MIELES ABAJO - VALENCIA - CORDOBA</option>
              <option value="23855015">SANTO DOMINGO - VALENCIA - CORDOBA</option>
              <option value="23855016">MANZANARES - VALENCIA - CORDOBA</option>
              <option value="23855017">SAN RAFAEL - VALENCIA - CORDOBA</option>
              <option value="23855019">GUADUAL CENTRAL - VALENCIA - CORDOBA</option>
              <option value="23855020">JERICO - VALENCIA - CORDOBA</option>
              <option value="23855021">LA LIBERTAD - VALENCIA - CORDOBA</option>
              <option value="23855023">LAS NUBES - VALENCIA - CORDOBA</option>
              <option value="23855024">CALLEJAS - VALENCIA - CORDOBA</option>
              <option value="25001000">AGUA DE DIOS - CUNDINAMARCA</option>
              <option value="25019000">ALBAN - CUNDINAMARCA</option>
              <option value="25019001">CHIMBE (DANUBIO) - ALBAN - CUNDINAMARCA</option>
              <option value="25019002">PANTANILLO - ALBAN - CUNDINAMARCA</option>
              <option value="25019003">LA MARIA - ALBAN - CUNDINAMARCA</option>
              <option value="25035000">ANAPOIMA - CUNDINAMARCA</option>
              <option value="25035001">LA PAZ - ANAPOIMA - CUNDINAMARCA</option>
              <option value="25035002">SAN ANTONIO DE ANAPOIMA - ANAPOIMA - CUNDINAMARCA</option>
              <option value="25035003">PATIO BONITO - ANAPOIMA - CUNDINAMARCA</option>
              <option value="25040000">ANOLAIMA - CUNDINAMARCA</option>
              <option value="25040002">LA FLORIDA - ANOLAIMA - CUNDINAMARCA</option>
              <option value="25040003">REVENTONES - ANOLAIMA - CUNDINAMARCA</option>
              <option value="25040006">CORRALEJAS - ANOLAIMA - CUNDINAMARCA</option>
              <option value="25053000">ARBELAEZ - CUNDINAMARCA</option>
              <option value="25053003">TISINCE - ARBELAEZ - CUNDINAMARCA</option>
              <option value="25086000">BELTRAN - CUNDINAMARCA</option>
              <option value="25086001">PAQUILO - BELTRAN - CUNDINAMARCA</option>
              <option value="25086002">LA POPA - BELTRAN - CUNDINAMARCA</option>
              <option value="25086003">PUERTO GRAMALOTAL - BELTRAN - CUNDINAMARCA</option>
              <option value="25095000">BITUIMA - CUNDINAMARCA</option>
              <option value="25095002">BOQUERON DE ILO - BITUIMA - CUNDINAMARCA</option>
              <option value="25095003">LA SIERRA - BITUIMA - CUNDINAMARCA</option>
              <option value="25099000">BOJACA - CUNDINAMARCA</option>
              <option value="25099002">SANTA BARBARA - BOJACA - CUNDINAMARCA</option>
              <option value="25120000">CABRERA - CUNDINAMARCA</option>
              <option value="25123000">CACHIPAY - CUNDINAMARCA</option>
              <option value="25123001">PEÑA NEGRA - CACHIPAY - CUNDINAMARCA</option>
              <option value="25123003">URBANIZACION TIERRA DE ENSUEÑO - CACHIPAY - CUNDINAMARCA</option>
              <option value="25126000">CAJICA - CUNDINAMARCA</option>
              <option value="25126003">RINCON SANTO - CAJICA - CUNDINAMARCA</option>
              <option value="25126004">RIO GRANDE - CAJICA - CUNDINAMARCA</option>
              <option value="25126005">CANELON - CAJICA - CUNDINAMARCA</option>
              <option value="25126006">LOS SERENEOS - CAJICA - CUNDINAMARCA</option>
              <option value="25126007">LOS PASOS - CAJICA - CUNDINAMARCA</option>
              <option value="25126008">LA FLORIDA - CAJICA - CUNDINAMARCA</option>
              <option value="25126009">CALAHORRA - CAJICA - CUNDINAMARCA</option>
              <option value="25126010">AGUANICA - CAJICA - CUNDINAMARCA</option>
              <option value="25126011">LA PALMA - CAJICA - CUNDINAMARCA</option>
              <option value="25126013">LA ESPERANZA - CAJICA - CUNDINAMARCA</option>
              <option value="25126014">CAMINO LOS VARGAS - CAJICA - CUNDINAMARCA</option>
              <option value="25126015">LOS LEON - CAJICA - CUNDINAMARCA</option>
              <option value="25126016">PRADO - CAJICA - CUNDINAMARCA</option>
              <option value="25126017">PABLO HERRERA - CAJICA - CUNDINAMARCA</option>
              <option value="25126018">SANTA INES - CAJICA - CUNDINAMARCA</option>
              <option value="25126019">BOSQUE MADERO - CAJICA - CUNDINAMARCA</option>
              <option value="25126020">QUINTAS DEL MOLINO - CAJICA - CUNDINAMARCA</option>
              <option value="25126021">VERDE VIVO - CAJICA - CUNDINAMARCA</option>
              <option value="25126022">VILLA DE LOS PINOS - CAJICA - CUNDINAMARCA</option>
              <option value="25148000">CAPARRAPI - CUNDINAMARCA</option>
              <option value="25148001">CAMBRAS - CAPARRAPI - CUNDINAMARCA</option>
              <option value="25148003">EL DINDAL - CAPARRAPI - CUNDINAMARCA</option>
              <option value="25148005">SAN PEDRO - CAPARRAPI - CUNDINAMARCA</option>
              <option value="25148006">TATI - CAPARRAPI - CUNDINAMARCA</option>
              <option value="25148007">CORDOBA - CAPARRAPI - CUNDINAMARCA</option>
              <option value="25148009">SAN CARLOS - CAPARRAPI - CUNDINAMARCA</option>
              <option value="25148010">CAMBULO - CAPARRAPI - CUNDINAMARCA</option>
              <option value="25148012">SAN PABLO - CAPARRAPI - CUNDINAMARCA</option>
              <option value="25148016">SAN RAMON ALTO - CAPARRAPI - CUNDINAMARCA</option>
              <option value="25151000">CAQUEZA - CUNDINAMARCA</option>
              <option value="25154000">CARMEN DE CARUPA - CUNDINAMARCA</option>
              <option value="25168000">CHAGUANI - CUNDINAMARCA</option>
              <option value="25175000">CHIA - CUNDINAMARCA</option>
              <option value="25175002">SINDAMANOY I - CHIA - CUNDINAMARCA</option>
              <option value="25175003">CUATRO ESQUINAS - CHIA - CUNDINAMARCA</option>
              <option value="25175005">CERCA DE PIEDRA - CHIA - CUNDINAMARCA</option>
              <option value="25175006">RINCON DE FAGUA - CHIA - CUNDINAMARCA</option>
              <option value="25175010">CHIQUILINDA - CHIA - CUNDINAMARCA</option>
              <option value="25175013">LA PAZ - CHIA - CUNDINAMARCA</option>
              <option value="25175019">EL ESPEJO - CHIA - CUNDINAMARCA</option>
              <option value="25175020">PUEBLO FUERTE - CHIA - CUNDINAMARCA</option>
              <option value="25175021">PUENTE CACIQUE - CHIA - CUNDINAMARCA</option>
              <option value="25175023">SANTA BARBARA - CHIA - CUNDINAMARCA</option>
              <option value="25175025">VILLA JULIANA - CHIA - CUNDINAMARCA</option>
              <option value="25175026">ENCENILLOS DE SINDAMANOY - CHIA - CUNDINAMARCA</option>
              <option value="25178000">CHIPAQUE - CUNDINAMARCA</option>
              <option value="25178012">LLANO DE CHIPAQUE - CHIPAQUE - CUNDINAMARCA</option>
              <option value="25178013">ABASTICOS - CHIPAQUE - CUNDINAMARCA</option>
              <option value="25181000">CHOACHI - CUNDINAMARCA</option>
              <option value="25181003">POTRERO GRANDE - CHOACHI - CUNDINAMARCA</option>
              <option value="25183000">CHOCONTA - CUNDINAMARCA</option>
              <option value="25200000">COGUA - CUNDINAMARCA</option>
              <option value="25200002">RODAMONTAL - COGUA - CUNDINAMARCA</option>
              <option value="25200004">EL MORTIÑO - COGUA - CUNDINAMARCA</option>
              <option value="25200005">LA PLAZUELA - COGUA - CUNDINAMARCA</option>
              <option value="25200006">LA CHAPA - COGUA - CUNDINAMARCA</option>
              <option value="25200008">EL CASCAJAL - COGUA - CUNDINAMARCA</option>
              <option value="25200009">EL DURAZNO - COGUA - CUNDINAMARCA</option>
              <option value="25200010">EL OLIVO - COGUA - CUNDINAMARCA</option>
              <option value="25200012">SAN ANTONIO - COGUA - CUNDINAMARCA</option>
              <option value="25200013">EL ATICO - SECTOR ALVAREZ - COGUA - CUNDINAMARCA</option>
              <option value="25200014">RINCON SANTO - SECTOR ZAMORA - COGUA - CUNDINAMARCA</option>
              <option value="25214000">COTA - CUNDINAMARCA</option>
              <option value="25224000">CUCUNUBA - CUNDINAMARCA</option>
              <option value="25245000">EL COLEGIO - CUNDINAMARCA</option>
              <option value="25245001">EL TRIUNFO - EL COLEGIO - CUNDINAMARCA</option>
              <option value="25245002">LA VICTORIA - EL COLEGIO - CUNDINAMARCA</option>
              <option value="25245003">PRADILLA - EL COLEGIO - CUNDINAMARCA</option>
              <option value="25245004">LA PAZ - EL COLEGIO - CUNDINAMARCA</option>
              <option value="25258000">EL PEÑON - CUNDINAMARCA</option>
              <option value="25258001">GUAYABAL DE TOLEDO - EL PEÑON - CUNDINAMARCA</option>
              <option value="25258002">TALAUTA - EL PEÑON - CUNDINAMARCA</option>
              <option value="25260000">EL ROSAL - CUNDINAMARCA</option>
              <option value="25260003">CRUZ VERDE - EL ROSAL - CUNDINAMARCA</option>
              <option value="25260004">PUENTE EL ROSAL - EL ROSAL - CUNDINAMARCA</option>
              <option value="25260005">SAN ANTONIO - EL ROSAL - CUNDINAMARCA</option>
              <option value="25269000">FACATATIVA - CUNDINAMARCA</option>
              <option value="25269001">SAN RAFAEL BAJO - FACATATIVA - CUNDINAMARCA</option>
              <option value="25269007">LOS ANDES - FACATATIVA - CUNDINAMARCA</option>
              <option value="25269008">LA YERBABUENA - FACATATIVA - CUNDINAMARCA</option>
              <option value="25269009">ALTO DE CORDOBA - FACATATIVA - CUNDINAMARCA</option>
              <option value="25269010">LOS ARRAYANES - FACATATIVA - CUNDINAMARCA</option>
              <option value="25269012">LOS MANZANOS - FACATATIVA - CUNDINAMARCA</option>
              <option value="25269013">PASO ANCHO - FACATATIVA - CUNDINAMARCA</option>
              <option value="25269014">PUEBLO VIEJO - FACATATIVA - CUNDINAMARCA</option>
              <option value="25269017">SANTA MARTHA - LA ESPERANZA - FACATATIVA - CUNDINAMARCA</option>
              <option value="25269019">SAGRADO CORAZON - FACATATIVA - CUNDINAMARCA</option>
              <option value="25269020">VILLA MYRIAM - FACATATIVA - CUNDINAMARCA</option>
              <option value="25269021">LOS ROBLES - FACATATIVA - CUNDINAMARCA</option>
              <option value="25269022">SAN ISIDRO - FACATATIVA - CUNDINAMARCA</option>
              <option value="25269023">TIERRA GRATA ALTA - FACATATIVA - CUNDINAMARCA</option>
              <option value="25269024">TIERRA GRATA (EL CRUCE) - FACATATIVA - CUNDINAMARCA</option>
              <option value="25269035">SAN JOSE - FACATATIVA - CUNDINAMARCA</option>
              <option value="25279000">FOMEQUE - CUNDINAMARCA</option>
              <option value="25279001">LA UNION - FOMEQUE - CUNDINAMARCA</option>
              <option value="25281000">FOSCA - CUNDINAMARCA</option>
              <option value="25281002">SANAME - FOSCA - CUNDINAMARCA</option>
              <option value="25286000">FUNZA - CUNDINAMARCA</option>
              <option value="25286006">EL COCLI - FUNZA - CUNDINAMARCA</option>
              <option value="25286007">EL PAPAYO - FUNZA - CUNDINAMARCA</option>
              <option value="25286008">SAN ANTONIO LOS PINOS - FUNZA - CUNDINAMARCA</option>
              <option value="25286009">TIENDA NUEVA - FUNZA - CUNDINAMARCA</option>
              <option value="25288000">FUQUENE - CUNDINAMARCA</option>
              <option value="25288001">CAPELLANIA - FUQUENE - CUNDINAMARCA</option>
              <option value="25288003">NUEVO FUQUENE - FUQUENE - CUNDINAMARCA</option>
              <option value="25290000">FUSAGASUGA - CUNDINAMARCA</option>
              <option value="25290001">LA AGUADITA - FUSAGASUGA - CUNDINAMARCA</option>
              <option value="25290012">EL TRIUNFO BOQUERON - FUSAGASUGA - CUNDINAMARCA</option>
              <option value="25290014">LA CASCADA - FUSAGASUGA - CUNDINAMARCA</option>
              <option value="25290015">RIO BLANCO - LOS PUENTES - FUSAGASUGA - CUNDINAMARCA</option>
              <option value="25290016">CHINAUTA - FUSAGASUGA - CUNDINAMARCA</option>
              <option value="25290017">LAS PIRAMIDES - FUSAGASUGA - CUNDINAMARCA</option>
              <option value="25293000">GACHALA - CUNDINAMARCA</option>
              <option value="25293003">SANTA RITA DEL RIO NEGRO - GACHALA - CUNDINAMARCA</option>
              <option value="25293006">PALOMAS - GACHALA - CUNDINAMARCA</option>
              <option value="25295000">GACHANCIPA - CUNDINAMARCA</option>
              <option value="25295005">EL ROBLE SUR - GACHANCIPA - CUNDINAMARCA</option>
              <option value="25297000">GACHETA - CUNDINAMARCA</option>
              <option value="25299000">GAMA - CUNDINAMARCA</option>
              <option value="25299001">SAN ROQUE - GAMA - CUNDINAMARCA</option>
              <option value="25307000">GIRARDOT - CUNDINAMARCA</option>
              <option value="25307004">BERLIN - GIRARDOT - CUNDINAMARCA</option>
              <option value="25307005">BARZALOSA - GIRARDOT - CUNDINAMARCA</option>
              <option value="25307006">PIAMONTE - GIRARDOT - CUNDINAMARCA</option>
              <option value="25312000">GRANADA - CUNDINAMARCA</option>
              <option value="25312007">LA VEINTIDOS - GRANADA - CUNDINAMARCA</option>
              <option value="25312013">SAN RAIMUNDO - GRANADA - CUNDINAMARCA</option>
              <option value="25317000">GUACHETA - CUNDINAMARCA</option>
              <option value="25320000">GUADUAS - CUNDINAMARCA</option>
              <option value="25320001">GUADUERO - GUADUAS - CUNDINAMARCA</option>
              <option value="25320002">LA PAZ DE CALAMOIMA - GUADUAS - CUNDINAMARCA</option>
              <option value="25320003">PUERTO BOGOTA - GUADUAS - CUNDINAMARCA</option>
              <option value="25320008">ALTO DEL TRIGO - GUADUAS - CUNDINAMARCA</option>
              <option value="25320009">LA CABAÑA - GUADUAS - CUNDINAMARCA</option>
              <option value="25322000">GUASCA - CUNDINAMARCA</option>
              <option value="25322002">LA CABRERITA - GUASCA - CUNDINAMARCA</option>
              <option value="25322004">GAMBOA (EL PLACER) - GUASCA - CUNDINAMARCA</option>
              <option value="25324000">GUATAQUI - CUNDINAMARCA</option>
              <option value="25324001">EL PORVENIR - GUATAQUI - CUNDINAMARCA</option>
              <option value="25324002">LAS ISLAS - GUATAQUI - CUNDINAMARCA</option>
              <option value="25326000">GUATAVITA - CUNDINAMARCA</option>
              <option value="25328000">GUAYABAL DE SIQUIMA - CUNDINAMARCA</option>
              <option value="25328001">ALTO DEL TRIGO - GUAYABAL DE SIQUIMA - CUNDINAMARCA</option>
              <option value="25335000">GUAYABETAL - CUNDINAMARCA</option>
              <option value="25335002">MONTERREDONDO - GUAYABETAL - CUNDINAMARCA</option>
              <option value="25335003">LAS MESAS - GUAYABETAL - CUNDINAMARCA</option>
              <option value="25335004">LIMONCITOS - GUAYABETAL - CUNDINAMARCA</option>
              <option value="25335005">SAN ANTONIO - GUAYABETAL - CUNDINAMARCA</option>
              <option value="25335006">SAN MIGUEL - GUAYABETAL - CUNDINAMARCA</option>
              <option value="25335008">LAS MESETAS - GUAYABETAL - CUNDINAMARCA</option>
              <option value="25339000">GUTIERREZ - CUNDINAMARCA</option>
              <option value="25339001">PASCOTE - GUTIERREZ - CUNDINAMARCA</option>
              <option value="25339002">SAN ANTONIO - GUTIERREZ - CUNDINAMARCA</option>
              <option value="25368000">JERUSALEN - CUNDINAMARCA</option>
              <option value="25372000">JUNIN - CUNDINAMARCA</option>
              <option value="25372001">CLARAVAL - JUNIN - CUNDINAMARCA</option>
              <option value="25372002">CHUSCALES - JUNIN - CUNDINAMARCA</option>
              <option value="25372004">SUEVA - JUNIN - CUNDINAMARCA</option>
              <option value="25372006">PUENTE LISIO - JUNIN - CUNDINAMARCA</option>
              <option value="25372007">RAMAL - JUNIN - CUNDINAMARCA</option>
              <option value="25372008">SAN FRANCISCO - JUNIN - CUNDINAMARCA</option>
              <option value="25377000">LA CALERA - CUNDINAMARCA</option>
              <option value="25377002">MUNDONUEVO - LA CALERA - CUNDINAMARCA</option>
              <option value="25377003">EL SALITRE - LA CALERA - CUNDINAMARCA</option>
              <option value="25377008">TREINTA Y SEIS - LA CALERA - CUNDINAMARCA</option>
              <option value="25377010">ALTAMAR - LA CALERA - CUNDINAMARCA</option>
              <option value="25377011">EL MANZANO - LA CALERA - CUNDINAMARCA</option>
              <option value="25377012">LA AURORA ALTA - LA CALERA - CUNDINAMARCA</option>
              <option value="25377013">LA CAPILLA - LA CALERA - CUNDINAMARCA</option>
              <option value="25377014">MARQUEZ - LA CALERA - CUNDINAMARCA</option>
              <option value="25377015">SAN CAYETANO - LA CALERA - CUNDINAMARCA</option>
              <option value="25377016">SAN JOSE DEL TRIUNFO - LA CALERA - CUNDINAMARCA</option>
              <option value="25386000">LA MESA - CUNDINAMARCA</option>
              <option value="25386001">LA ESPERANZA - LA MESA - CUNDINAMARCA</option>
              <option value="25386002">SAN JAVIER - LA MESA - CUNDINAMARCA</option>
              <option value="25386003">SAN JOAQUIN - LA MESA - CUNDINAMARCA</option>
              <option value="25394000">LA PALMA - CUNDINAMARCA</option>
              <option value="25398000">LA PEÑA - CUNDINAMARCA</option>
              <option value="25402000">LA VEGA - CUNDINAMARCA</option>
              <option value="25402002">EL VINO - LA VEGA - CUNDINAMARCA</option>
              <option value="25407000">LENGUAZAQUE - CUNDINAMARCA</option>
              <option value="25426000">MACHETA - CUNDINAMARCA</option>
              <option value="25430000">MADRID - CUNDINAMARCA</option>
              <option value="25430001">LA CUESTA - MADRID - CUNDINAMARCA</option>
              <option value="25430003">EL CORZO - MADRID - CUNDINAMARCA</option>
              <option value="25430004">PUENTE DE PIEDRA - MADRID - CUNDINAMARCA</option>
              <option value="25430005">CHAUTA - MADRID - CUNDINAMARCA</option>
              <option value="25430006">MOYANO - MADRID - CUNDINAMARCA</option>
              <option value="25436000">MANTA - CUNDINAMARCA</option>
              <option value="25438000">MEDINA - CUNDINAMARCA</option>
              <option value="25438003">SAN PEDRO DE GUAJARAY - MEDINA - CUNDINAMARCA</option>
              <option value="25438004">SANTA TERESITA - MEDINA - CUNDINAMARCA</option>
              <option value="25438005">MESA DE LOS REYES - MEDINA - CUNDINAMARCA</option>
              <option value="25438006">LOS ALPES - MEDINA - CUNDINAMARCA</option>
              <option value="25438010">LA ESMERALDA - MEDINA - CUNDINAMARCA</option>
              <option value="25438011">GAZADUJE - MEDINA - CUNDINAMARCA</option>
              <option value="25473000">MOSQUERA - CUNDINAMARCA</option>
              <option value="25473004">LOS PUENTES - MOSQUERA - CUNDINAMARCA</option>
              <option value="25473007">PARCELAS - MOSQUERA - CUNDINAMARCA</option>
              <option value="25473008">PENCAL - MOSQUERA - CUNDINAMARCA</option>
              <option value="25473009">QUINTAS DE SERREZUELA - MOSQUERA - CUNDINAMARCA</option>
              <option value="25483000">NARIÑO - CUNDINAMARCA</option>
              <option value="25486000">NEMOCON - CUNDINAMARCA</option>
              <option value="25486001">PATIO BONITO - NEMOCON - CUNDINAMARCA</option>
              <option value="25486002">EL ORATORIO - NEMOCON - CUNDINAMARCA</option>
              <option value="25486003">LA PUERTA - NEMOCON - CUNDINAMARCA</option>
              <option value="25486005">CAMACHO - NEMOCON - CUNDINAMARCA</option>
              <option value="25486006">EL PLAN - NEMOCON - CUNDINAMARCA</option>
              <option value="25488000">NILO - CUNDINAMARCA</option>
              <option value="25488001">LA ESMERALDA - NILO - CUNDINAMARCA</option>
              <option value="25488002">PUEBLO NUEVO - NILO - CUNDINAMARCA</option>
              <option value="25488003">EL REDIL - NILO - CUNDINAMARCA</option>
              <option value="25489000">NIMAIMA - CUNDINAMARCA</option>
              <option value="25489001">TOBIA - NIMAIMA - CUNDINAMARCA</option>
              <option value="25491000">NOCAIMA - CUNDINAMARCA</option>
              <option value="25491001">TOBIA CHICA - NOCAIMA - CUNDINAMARCA</option>
              <option value="25506000">VENECIA - CUNDINAMARCA</option>
              <option value="25506001">APOSENTOS - VENECIA - CUNDINAMARCA</option>
              <option value="25506005">EL TREBOL - VENECIA - CUNDINAMARCA</option>
              <option value="25513000">PACHO - CUNDINAMARCA</option>
              <option value="25513001">PASUNCHA - PACHO - CUNDINAMARCA</option>
              <option value="25518000">PAIME - CUNDINAMARCA</option>
              <option value="25518001">CUATRO CAMINOS - PAIME - CUNDINAMARCA</option>
              <option value="25518002">TUDELA - PAIME - CUNDINAMARCA</option>
              <option value="25518004">VENECIA - PAIME - CUNDINAMARCA</option>
              <option value="25524000">PANDI - CUNDINAMARCA</option>
              <option value="25530000">PARATEBUENO - CUNDINAMARCA</option>
              <option value="25530001">MAYA - PARATEBUENO - CUNDINAMARCA</option>
              <option value="25530002">SANTA CECILIA - PARATEBUENO - CUNDINAMARCA</option>
              <option value="25530003">EL ENGAÑO - PARATEBUENO - CUNDINAMARCA</option>
              <option value="25530006">EL JAPON - PARATEBUENO - CUNDINAMARCA</option>
              <option value="25530007">CABULLARITO - PARATEBUENO - CUNDINAMARCA</option>
              <option value="25535000">PASCA - CUNDINAMARCA</option>
              <option value="25535002">GUCHIPAS - PASCA - CUNDINAMARCA</option>
              <option value="25572000">PUERTO SALGAR - CUNDINAMARCA</option>
              <option value="25572001">COLORADOS - PUERTO SALGAR - CUNDINAMARCA</option>
              <option value="25572003">PUERTO LIBRE - PUERTO SALGAR - CUNDINAMARCA</option>
              <option value="25572005">MORRO COLORADO - PUERTO SALGAR - CUNDINAMARCA</option>
              <option value="25580000">PULI - CUNDINAMARCA</option>
              <option value="25580002">PALESTINA - PULI - CUNDINAMARCA</option>
              <option value="25592000">QUEBRADANEGRA - CUNDINAMARCA</option>
              <option value="25592001">LA MAGDALENA - QUEBRADANEGRA - CUNDINAMARCA</option>
              <option value="25592003">TOBIA - LA MILAGROSA - QUEBRADANEGRA - CUNDINAMARCA</option>
              <option value="25594000">QUETAME - CUNDINAMARCA</option>
              <option value="25594002">PUENTE QUETAME - QUETAME - CUNDINAMARCA</option>
              <option value="25594003">GUACAPATE - QUETAME - CUNDINAMARCA</option>
              <option value="25596000">QUIPILE - CUNDINAMARCA</option>
              <option value="25596001">LA SIERRA - QUIPILE - CUNDINAMARCA</option>
              <option value="25596002">LA VIRGEN - QUIPILE - CUNDINAMARCA</option>
              <option value="25596003">SANTA MARTA - QUIPILE - CUNDINAMARCA</option>
              <option value="25596004">LA BOTICA - QUIPILE - CUNDINAMARCA</option>
              <option value="25599000">APULO - CUNDINAMARCA</option>
              <option value="25612000">RICAURTE - CUNDINAMARCA</option>
              <option value="25612001">MANUEL SUR - RICAURTE - CUNDINAMARCA</option>
              <option value="25612002">EL PASO - RICAURTE - CUNDINAMARCA</option>
              <option value="25612003">EL PORTAL - RICAURTE - CUNDINAMARCA</option>
              <option value="25612004">LAS VARAS - RICAURTE - CUNDINAMARCA</option>
              <option value="25612006">SAN MARCOS POBLADO - RICAURTE - CUNDINAMARCA</option>
              <option value="25612007">SAN MARTIN - RICAURTE - CUNDINAMARCA</option>
              <option value="25645000">SAN ANTONIO DEL TEQUENDAMA - CUNDINAMARCA</option>
              <option value="25645001">SANTANDERCITO - SAN ANTONIO DEL TEQUENDAMA - CUNDINAMARCA</option>
              <option value="25645011">PUEBLO NUEVO - SAN ANTONIO DEL TEQUENDAMA - CUNDINAMARCA</option>
              <option value="25645016">BELLAVISTA - SAN ANTONIO DEL TEQUENDAMA - CUNDINAMARCA</option>
              <option value="25645017">PRADILLA - SAN ANTONIO DEL TEQUENDAMA - CUNDINAMARCA</option>
              <option value="25645018">LOS NARANJOS - SAN ANTONIO DEL TEQUENDAMA - CUNDINAMARCA</option>
              <option value="25645019">VILLA PRADILLA - SAN ANTONIO DEL TEQUENDAMA - CUNDINAMARCA</option>
              <option value="25645020">VILLA SHYN (CASAS MOVILES) - SAN ANTONIO DEL TEQUENDAMA - CUNDINAMARCA</option>
              <option value="25649000">SAN BERNARDO - CUNDINAMARCA</option>
              <option value="25649003">PORTONES - SAN BERNARDO - CUNDINAMARCA</option>
              <option value="25653000">PUEBLO NUEVO - SAN CAYETANO - CUNDINAMARCA</option>
              <option value="25653001">CAMANCHA - SAN CAYETANO - CUNDINAMARCA</option>
              <option value="25653002">CUIBUCO - SAN CAYETANO - CUNDINAMARCA</option>
              <option value="25653003">LAS MERCEDES - SAN CAYETANO - CUNDINAMARCA</option>
              <option value="25653005">ALBERGUE - SAN CAYETANO - CUNDINAMARCA</option>
              <option value="25658000">SAN FRANCISCO - CUNDINAMARCA</option>
              <option value="25662000">SAN JUAN DE RIOSECO - CUNDINAMARCA</option>
              <option value="25662001">CAMBAO - SAN JUAN DE RIOSECO - CUNDINAMARCA</option>
              <option value="25662002">SAN NICOLAS - SAN JUAN DE RIOSECO - CUNDINAMARCA</option>
              <option value="25718000">SASAIMA - CUNDINAMARCA</option>
              <option value="25718001">SANTA INES - SASAIMA - CUNDINAMARCA</option>
              <option value="25718002">SANTA CRUZ - SASAIMA - CUNDINAMARCA</option>
              <option value="25736000">SESQUILE - CUNDINAMARCA</option>
              <option value="25736002">LA PLAYA - SESQUILE - CUNDINAMARCA</option>
              <option value="25736003">BOITIVA SAN ROQUE - SESQUILE - CUNDINAMARCA</option>
              <option value="25736004">SIATOYA - SESQUILE - CUNDINAMARCA</option>
              <option value="25740000">SIBATE - CUNDINAMARCA</option>
              <option value="25740004">SAN BENITO CENTRO - SIBATE - CUNDINAMARCA</option>
              <option value="25740005">CHACUA CENTRO - SIBATE - CUNDINAMARCA</option>
              <option value="25740007">PERICO SECTOR LA HONDA - SIBATE - CUNDINAMARCA</option>
              <option value="25740008">PERICO SECTOR LA MACARENA - SIBATE - CUNDINAMARCA</option>
              <option value="25740010">SAN FORTUNATO SECTOR LOS ZORROS - SIBATE - CUNDINAMARCA</option>
              <option value="25740011">SAN MIGUEL - SIBATE - CUNDINAMARCA</option>
              <option value="25740012">LA UNION SECTOR LA UNION - SIBATE - CUNDINAMARCA</option>
              <option value="25740013">LA UNION SECTOR PIE DE ALTO - SIBATE - CUNDINAMARCA</option>
              <option value="25740014">SAN BENITO SECTOR JAZMIN - SIBATE - CUNDINAMARCA</option>
              <option value="25743000">SILVANIA - CUNDINAMARCA</option>
              <option value="25743002">AZAFRANAL - SILVANIA - CUNDINAMARCA</option>
              <option value="25743005">SUBIA - SILVANIA - CUNDINAMARCA</option>
              <option value="25743006">AGUA BONITA - SILVANIA - CUNDINAMARCA</option>
              <option value="25745000">SIMIJACA - CUNDINAMARCA</option>
              <option value="25745003">EL RETEN - SIMIJACA - CUNDINAMARCA</option>
              <option value="25745005">SANTA LUCIA - SIMIJACA - CUNDINAMARCA</option>
              <option value="25754000">SOACHA - CUNDINAMARCA</option>
              <option value="25754001">CHARQUITO - SOACHA - CUNDINAMARCA</option>
              <option value="25754011">CHACUA CABRERA - SOACHA - CUNDINAMARCA</option>
              <option value="25758000">SOPO - CUNDINAMARCA</option>
              <option value="25758008">HATOGRANDE - SOPO - CUNDINAMARCA</option>
              <option value="25758009">GRATAMIRA - SOPO - CUNDINAMARCA</option>
              <option value="25758010">MERCENARIO - SOPO - CUNDINAMARCA</option>
              <option value="25758011">LA DIANA - SOPO - CUNDINAMARCA</option>
              <option value="25758012">PUEBLO VIEJO SECTOR NIÑO - SOPO - CUNDINAMARCA</option>
              <option value="25769000">SUBACHOQUE - CUNDINAMARCA</option>
              <option value="25769002">LA PRADERA - SUBACHOQUE - CUNDINAMARCA</option>
              <option value="25769003">GALDAMEZ - SUBACHOQUE - CUNDINAMARCA</option>
              <option value="25769006">CASCAJAL - SUBACHOQUE - CUNDINAMARCA</option>
              <option value="25769007">LLANITOS - SUBACHOQUE - CUNDINAMARCA</option>
              <option value="25772000">SUESCA - CUNDINAMARCA</option>
              <option value="25772001">HATO GRANDE - SUESCA - CUNDINAMARCA</option>
              <option value="25772002">SANTA ROSITA - SUESCA - CUNDINAMARCA</option>
              <option value="25772004">CACICAZGO - SUESCA - CUNDINAMARCA</option>
              <option value="25777000">SUPATA - CUNDINAMARCA</option>
              <option value="25777001">LA MAGOLA - SUPATA - CUNDINAMARCA</option>
              <option value="25779000">SUSA - CUNDINAMARCA</option>
              <option value="25781000">SUTATAUSA - CUNDINAMARCA</option>
              <option value="25781002">LAS PEÑAS - SUTATAUSA - CUNDINAMARCA</option>
              <option value="25781003">CHIRCAL DE SANTA BARBARA - SUTATAUSA - CUNDINAMARCA</option>
              <option value="25781004">LA QUINTA - SUTATAUSA - CUNDINAMARCA</option>
              <option value="25785000">TABIO - CUNDINAMARCA</option>
              <option value="25785001">CARRON - TABIO - CUNDINAMARCA</option>
              <option value="25785002">EL PENCIL - TABIO - CUNDINAMARCA</option>
              <option value="25785003">PARCELACION TERMALES - TABIO - CUNDINAMARCA</option>
              <option value="25785005">CHICU - TABIO - CUNDINAMARCA</option>
              <option value="25785007">EL BOTE - TABIO - CUNDINAMARCA</option>
              <option value="25785010">TERPEL - TABIO - CUNDINAMARCA</option>
              <option value="25793000">TAUSA - CUNDINAMARCA</option>
              <option value="25793003">BOQUERON - TAUSA - CUNDINAMARCA</option>
              <option value="25793005">DIVINO NIÑO - TAUSA - CUNDINAMARCA</option>
              <option value="25797000">TENA - CUNDINAMARCA</option>
              <option value="25797001">LA GRAN VIA - TENA - CUNDINAMARCA</option>
              <option value="25799000">TENJO - CUNDINAMARCA</option>
              <option value="25799001">LA PUNTA - TENJO - CUNDINAMARCA</option>
              <option value="25799007">PAN DE AZUCAR - TENJO - CUNDINAMARCA</option>
              <option value="25799008">EL PALMAR - TENJO - CUNDINAMARCA</option>
              <option value="25799009">GRATAMIRA - TENJO - CUNDINAMARCA</option>
              <option value="25799010">BARRIO LOS CATADI - TENJO - CUNDINAMARCA</option>
              <option value="25799011">CASCAJERA - TENJO - CUNDINAMARCA</option>
              <option value="25799012">LOS PINOS - TENJO - CUNDINAMARCA</option>
              <option value="25799014">JUAICA - TENJO - CUNDINAMARCA</option>
              <option value="25799015">ZOQUE - TENJO - CUNDINAMARCA</option>
              <option value="25805000">TIBACUY - CUNDINAMARCA</option>
              <option value="25805001">BATEAS - TIBACUY - CUNDINAMARCA</option>
              <option value="25805002">CUMACA - TIBACUY - CUNDINAMARCA</option>
              <option value="25807000">TIBIRITA - CUNDINAMARCA</option>
              <option value="25815000">TOCAIMA - CUNDINAMARCA</option>
              <option value="25815001">PUBENZA - TOCAIMA - CUNDINAMARCA</option>
              <option value="25815003">LA SALADA - TOCAIMA - CUNDINAMARCA</option>
              <option value="25815007">LA COLORADA - TOCAIMA - CUNDINAMARCA</option>
              <option value="25817000">TOCANCIPA - CUNDINAMARCA</option>
              <option value="25817001">CHAUTA - TOCANCIPA - CUNDINAMARCA</option>
              <option value="25817002">DULCINEA - TOCANCIPA - CUNDINAMARCA</option>
              <option value="25817003">PELPAK - TOCANCIPA - CUNDINAMARCA</option>
              <option value="25817004">SAN JAVIER - TOCANCIPA - CUNDINAMARCA</option>
              <option value="25817005">TOLIMA - MILENION - TOCANCIPA - CUNDINAMARCA</option>
              <option value="25817006">LA FUENTE - TOCANCIPA - CUNDINAMARCA</option>
              <option value="25817007">CETINA - TOCANCIPA - CUNDINAMARCA</option>
              <option value="25817008">ANTONIA SANTOS - TOCANCIPA - CUNDINAMARCA</option>
              <option value="25817012">CHICALA - TOCANCIPA - CUNDINAMARCA</option>
              <option value="25817013">LAS QUINTAS - TOCANCIPA - CUNDINAMARCA</option>
              <option value="25817015">COLPAPEL - TOCANCIPA - CUNDINAMARCA</option>
              <option value="25817017">SAN VICTORINO - TOCANCIPA - CUNDINAMARCA</option>
              <option value="25817018">CAMINOS DE SIE - TOCANCIPA - CUNDINAMARCA</option>
              <option value="25817019">CHICO NORTE - TOCANCIPA - CUNDINAMARCA</option>
              <option value="25817020">BUENOS AIRES - TOCANCIPA - CUNDINAMARCA</option>
              <option value="25817021">EL PORVENIR - TOCANCIPA - CUNDINAMARCA</option>
              <option value="25817022">EL DIVINO NIÑO - TOCANCIPA - CUNDINAMARCA</option>
              <option value="25817023">LOS MANZANOS - TOCANCIPA - CUNDINAMARCA</option>
              <option value="25823000">TOPAIPI - CUNDINAMARCA</option>
              <option value="25823001">SAN ANTONIO DE AGUILERA - TOPAIPI - CUNDINAMARCA</option>
              <option value="25823002">EL NARANJAL - TOPAIPI - CUNDINAMARCA</option>
              <option value="25839000">UBALA - CUNDINAMARCA</option>
              <option value="25839002">LAGUNA AZUL - UBALA - CUNDINAMARCA</option>
              <option value="25839003">MAMBITA - UBALA - CUNDINAMARCA</option>
              <option value="25839004">SAN PEDRO DE JAGUA - UBALA - CUNDINAMARCA</option>
              <option value="25839005">SANTA ROSA - UBALA - CUNDINAMARCA</option>
              <option value="25839006">LA PLAYA - UBALA - CUNDINAMARCA</option>
              <option value="25839010">SANTA BARBARA - UBALA - CUNDINAMARCA</option>
              <option value="25841000">UBAQUE - CUNDINAMARCA</option>
              <option value="25843000">VILLA DE SAN DIEGO DE UBATE - CUNDINAMARCA</option>
              <option value="25843001">GUATANCUY - VILLA DE SAN DIEGO DE UBATE - CUNDINAMARCA</option>
              <option value="25843005">VOLCAN BAJO - VILLA DE SAN DIEGO DE UBATE - CUNDINAMARCA</option>
              <option value="25843007">SAN LUIS - VILLA DE SAN DIEGO DE UBATE - CUNDINAMARCA</option>
              <option value="25843009">PALOGORDO - VILLA DE SAN DIEGO DE UBATE - CUNDINAMARCA</option>
              <option value="25843010">CENTRO DEL LLANO - VILLA DE SAN DIEGO DE UBATE - CUNDINAMARCA</option>
              <option value="25843011">TAUSAVITA BAJO - VILLA DE SAN DIEGO DE UBATE - CUNDINAMARCA</option>
              <option value="25843014">TAUSAVITA ALTO - VILLA DE SAN DIEGO DE UBATE - CUNDINAMARCA</option>
              <option value="25845000">UNE - CUNDINAMARCA</option>
              <option value="25845002">TIMASITA - UNE - CUNDINAMARCA</option>
              <option value="25851000">UTICA - CUNDINAMARCA</option>
              <option value="25862000">VERGARA - CUNDINAMARCA</option>
              <option value="25862002">GUACAMAYAS - VERGARA - CUNDINAMARCA</option>
              <option value="25862004">VILLA OLARTE - VERGARA - CUNDINAMARCA</option>
              <option value="25862005">CERINZA - VERGARA - CUNDINAMARCA</option>
              <option value="25862006">CORCEGA - VERGARA - CUNDINAMARCA</option>
              <option value="25867000">VIANI - CUNDINAMARCA</option>
              <option value="25867001">ALTO EL ROSARIO - VIANI - CUNDINAMARCA</option>
              <option value="25871000">VILLAGOMEZ - CUNDINAMARCA</option>
              <option value="25873000">VILLAPINZON - CUNDINAMARCA</option>
              <option value="25873001">SOATAMA - VILLAPINZON - CUNDINAMARCA</option>
              <option value="25875000">VILLETA - CUNDINAMARCA</option>
              <option value="25875001">BAGAZAL - VILLETA - CUNDINAMARCA</option>
              <option value="25875004">EL PUENTE - VILLETA - CUNDINAMARCA</option>
              <option value="25878000">VIOTA - CUNDINAMARCA</option>
              <option value="25878002">SAN GABRIEL - VIOTA - CUNDINAMARCA</option>
              <option value="25878003">EL PIÑAL - VIOTA - CUNDINAMARCA</option>
              <option value="25878004">LIBERIA - VIOTA - CUNDINAMARCA</option>
              <option value="25885000">YACOPI - CUNDINAMARCA</option>
              <option value="25885002">GUADUALITO - YACOPI - CUNDINAMARCA</option>
              <option value="25885004">IBAMA - YACOPI - CUNDINAMARCA</option>
              <option value="25885006">LLANO MATEO - YACOPI - CUNDINAMARCA</option>
              <option value="25885008">TERAN - YACOPI - CUNDINAMARCA</option>
              <option value="25885009">APOSENTOS - YACOPI - CUNDINAMARCA</option>
              <option value="25885018">PATEVACA - YACOPI - CUNDINAMARCA</option>
              <option value="25885021">EL CASTILLO - YACOPI - CUNDINAMARCA</option>
              <option value="25898000">ZIPACON - CUNDINAMARCA</option>
              <option value="25898001">EL OCASO - ZIPACON - CUNDINAMARCA</option>
              <option value="25898004">LA CAPILLA - ZIPACON - CUNDINAMARCA</option>
              <option value="25898005">LA ESTACION - ZIPACON - CUNDINAMARCA</option>
              <option value="25898006">CARTAGENA - ZIPACON - CUNDINAMARCA</option>
              <option value="25898007">LA CABAÑA - ZIPACON - CUNDINAMARCA</option>
              <option value="25899000">ZIPAQUIRA - CUNDINAMARCA</option>
              <option value="25899001">LA GRANJA - ZIPAQUIRA - CUNDINAMARCA</option>
              <option value="25899002">BARANDILLAS - ZIPAQUIRA - CUNDINAMARCA</option>
              <option value="25899005">RIO FRIO - ZIPAQUIRA - CUNDINAMARCA</option>
              <option value="25899006">PASOANCHO - ZIPAQUIRA - CUNDINAMARCA</option>
              <option value="25899007">SAN JORGE PALO BAJO - ZIPAQUIRA - CUNDINAMARCA</option>
              <option value="25899008">SAN JORGE PALO ALTO - ZIPAQUIRA - CUNDINAMARCA</option>
              <option value="25899009">ALTO DEL AGUILA - ZIPAQUIRA - CUNDINAMARCA</option>
              <option value="25899010">APOSENTOS ALTOS - ZIPAQUIRA - CUNDINAMARCA</option>
              <option value="25899011">BOLIVAR 83 - ZIPAQUIRA - CUNDINAMARCA</option>
              <option value="25899012">BOSQUES DE SILECIA - ZIPAQUIRA - CUNDINAMARCA</option>
              <option value="25899014">EL EMPALIZADO - ZIPAQUIRA - CUNDINAMARCA</option>
              <option value="25899015">EL RUDAL - ZIPAQUIRA - CUNDINAMARCA</option>
              <option value="25899017">LOTEO LA PAZ - BOMBA TERPEL - LOTEO SUSAGUA - ZIPAQUIRA - CUNDINAMARCA</option>
              <option value="25899019">LOTEO SANTA ISABEL - ZIPAQUIRA - CUNDINAMARCA</option>
              <option value="25899020">PORTACHUELO - ZIPAQUIRA - CUNDINAMARCA</option>
              <option value="25899025">SAN MIGUEL - ZIPAQUIRA - CUNDINAMARCA</option>
              <option value="25899026">SANTIAGO PEREZ - ZIPAQUIRA - CUNDINAMARCA</option>
              <option value="25899028">LA MARIELA - ZIPAQUIRA - CUNDINAMARCA</option>
              <option value="25899029">EL CODITO - ZIPAQUIRA - CUNDINAMARCA</option>
              <option value="25899030">EL KIOSKO LA GRANJA - ZIPAQUIRA - CUNDINAMARCA</option>
              <option value="25899031">LA ESCUELA - ZIPAQUIRA - CUNDINAMARCA</option>
              <option value="25899034">ARGELIA - ZIPAQUIRA - CUNDINAMARCA</option>
              <option value="25899035">ARGELIA II - ZIPAQUIRA - CUNDINAMARCA</option>
              <option value="25899036">ARGELIA III - ZIPAQUIRA - CUNDINAMARCA</option>
              <option value="25899037">MALAGON - ZIPAQUIRA - CUNDINAMARCA</option>
              <option value="27001000">SAN FRANCISCO DE QUIBDO - QUIBDO - CHOCO</option>
              <option value="27001008">BOCA DE TANANDO - QUIBDO - CHOCO</option>
              <option value="27001011">CALAHORRA - QUIBDO - CHOCO</option>
              <option value="27001013">CAMPOBONITO - QUIBDO - CHOCO</option>
              <option value="27001015">GUARANDO - QUIBDO - CHOCO</option>
              <option value="27001016">GUAYABAL - QUIBDO - CHOCO</option>
              <option value="27001017">LA TROJE - QUIBDO - CHOCO</option>
              <option value="27001018">LAS MERCEDES - QUIBDO - CHOCO</option>
              <option value="27001020">SAN RAFAEL DE NEGUA - QUIBDO - CHOCO</option>
              <option value="27001024">SAN FRANCISCO DE ICHO - QUIBDO - CHOCO</option>
              <option value="27001029">TAGACHI - QUIBDO - CHOCO</option>
              <option value="27001032">TUTUNENDO - QUIBDO - CHOCO</option>
              <option value="27001035">GUADALUPE - QUIBDO - CHOCO</option>
              <option value="27001038">SANCENO - QUIBDO - CHOCO</option>
              <option value="27001044">BOCA DE NAURITA - QUIBDO - CHOCO</option>
              <option value="27001047">EL FUERTE - QUIBDO - CHOCO</option>
              <option value="27001048">SAN ANTONIO DE ICHO - QUIBDO - CHOCO</option>
              <option value="27001052">BOCA DE NEMOTA (NEMOTA) - QUIBDO - CHOCO</option>
              <option value="27001054">PACURITA (CABI) - QUIBDO - CHOCO</option>
              <option value="27001060">VILLA DEL ROSARIO - QUIBDO - CHOCO</option>
              <option value="27001061">WINANDO - QUIBDO - CHOCO</option>
              <option value="27001063">BARRANCO - QUIBDO - CHOCO</option>
              <option value="27001066">SAN JOAQUIN - QUIBDO - CHOCO</option>
              <option value="27001067">EL 21 - QUIBDO - CHOCO</option>
              <option value="27006000">ACANDI - CHOCO</option>
              <option value="27006003">CAPURGANA - ACANDI - CHOCO</option>
              <option value="27006005">LA CALETA - ACANDI - CHOCO</option>
              <option value="27006007">SAN FRANCISCO - ACANDI - CHOCO</option>
              <option value="27006008">SAN MIGUEL - ACANDI - CHOCO</option>
              <option value="27006009">CHIGANDI - ACANDI - CHOCO</option>
              <option value="27006010">SAPZURRO - ACANDI - CHOCO</option>
              <option value="27006016">PEÑALOSA - ACANDI - CHOCO</option>
              <option value="27025000">PIE DE PATO - ALTO BAUDO - CHOCO</option>
              <option value="27025003">APARTADO - ALTO BAUDO - CHOCO</option>
              <option value="27025004">CHACHAJO - ALTO BAUDO - CHOCO</option>
              <option value="27025006">NAUCAS - ALTO BAUDO - CHOCO</option>
              <option value="27025007">SAN FRANCISCO DE CUGUCHO - ALTO BAUDO - CHOCO</option>
              <option value="27025008">SANTA CATALINA DE CATRU - ALTO BAUDO - CHOCO</option>
              <option value="27025011">BATATAL - ALTO BAUDO - CHOCO</option>
              <option value="27025013">CHIGORODO - ALTO BAUDO - CHOCO</option>
              <option value="27025014">EL SALTO (BELLA LUZ) - ALTO BAUDO - CHOCO</option>
              <option value="27025016">DOMINICO - ALTO BAUDO - CHOCO</option>
              <option value="27025017">GEANDO - ALTO BAUDO - CHOCO</option>
              <option value="27025019">LA DIVISA - ALTO BAUDO - CHOCO</option>
              <option value="27025020">LA FELICIA - ALTO BAUDO - CHOCO</option>
              <option value="27025021">LA LOMA - ALTO BAUDO - CHOCO</option>
              <option value="27025022">PAVARANDO (PUREZA) - ALTO BAUDO - CHOCO</option>
              <option value="27025023">LAS DELICIAS - ALTO BAUDO - CHOCO</option>
              <option value="27025024">MOJAUDO - ALTO BAUDO - CHOCO</option>
              <option value="27025027">PUESTO INDIO - ALTO BAUDO - CHOCO</option>
              <option value="27025030">AMPARRAIDA (SANTA RITA) - ALTO BAUDO - CHOCO</option>
              <option value="27025031">PUERTO MARTINEZ - ALTO BAUDO - CHOCO</option>
              <option value="27025033">MIACORA - ALTO BAUDO - CHOCO</option>
              <option value="27025035">PUERTO ALEGRE - ALTO BAUDO - CHOCO</option>
              <option value="27025036">PUERTO CORDOBA URUDO - ALTO BAUDO - CHOCO</option>
              <option value="27025037">PUERTO ECHEVERRY - ALTO BAUDO - CHOCO</option>
              <option value="27025038">PUERTO LIBIA - ALTO BAUDO - CHOCO</option>
              <option value="27025040">BOCA DE LEON - ALTO BAUDO - CHOCO</option>
              <option value="27025041">NUEVO PLATANARES - ALTO BAUDO - CHOCO</option>
              <option value="27050000">YUTO - ATRATO - CHOCO</option>
              <option value="27050001">ARENAL - ATRATO - CHOCO</option>
              <option value="27050002">DOÑA JOSEFA - ATRATO - CHOCO</option>
              <option value="27050003">SAMURINDO - ATRATO - CHOCO</option>
              <option value="27050005">MOTOLDO - ATRATO - CHOCO</option>
              <option value="27050006">SAN JOSE DE PURRE - ATRATO - CHOCO</option>
              <option value="27050007">SAN MARTIN DE PURRE - ATRATO - CHOCO</option>
              <option value="27050008">LA MOLANA - ATRATO - CHOCO</option>
              <option value="27050009">PUENTE DE TANANDO - ATRATO - CHOCO</option>
              <option value="27050010">PUENTE DE PAIMADO - ATRATO - CHOCO</option>
              <option value="27050013">LA TOMA - ATRATO - CHOCO</option>
              <option value="27050017">REAL DE TANANDO (2DO) - ATRATO - CHOCO</option>
              <option value="27050018">VARIANTE DE MOTOLDO - ATRATO - CHOCO</option>
              <option value="27073000">BAGADO - CHOCO</option>
              <option value="27073004">ENGRIVADO - BAGADO - CHOCO</option>
              <option value="27073005">LA SIERRA - BAGADO - CHOCO</option>
              <option value="27073006">PIEDRA HONDA - BAGADO - CHOCO</option>
              <option value="27073009">EL SALTO - BAGADO - CHOCO</option>
              <option value="27073010">PLAYA BONITA - BAGADO - CHOCO</option>
              <option value="27073011">VIVICORA - BAGADO - CHOCO</option>
              <option value="27073012">PESCADITO - BAGADO - CHOCO</option>
              <option value="27073013">CUAJANDO - BAGADO - CHOCO</option>
              <option value="27073014">MUCHICHI - BAGADO - CHOCO</option>
              <option value="27073015">OCHOA - BAGADO - CHOCO</option>
              <option value="27075000">CIUDAD MUTIS - BAHIA SOLANO - CHOCO</option>
              <option value="27075002">EL VALLE - BAHIA SOLANO - CHOCO</option>
              <option value="27075003">HUACA - BAHIA SOLANO - CHOCO</option>
              <option value="27075004">HUINA - BAHIA SOLANO - CHOCO</option>
              <option value="27075006">MECANA - BAHIA SOLANO - CHOCO</option>
              <option value="27075007">NABUGA - BAHIA SOLANO - CHOCO</option>
              <option value="27075009">PLAYITA POTE - BAHIA SOLANO - CHOCO</option>
              <option value="27077000">PIZARRO - BAJO BAUDO - CHOCO</option>
              <option value="27077002">BELEN DE DOCAMPODO - BAJO BAUDO - CHOCO</option>
              <option value="27077005">CUEVITA - BAJO BAUDO - CHOCO</option>
              <option value="27077006">DOTENEDO - BAJO BAUDO - CHOCO</option>
              <option value="27077007">HIJUA - BAJO BAUDO - CHOCO</option>
              <option value="27077008">ORPUA - BAJO BAUDO - CHOCO</option>
              <option value="27077009">PAVASA - BAJO BAUDO - CHOCO</option>
              <option value="27077011">PILIZA - BAJO BAUDO - CHOCO</option>
              <option value="27077012">PLAYITA - BAJO BAUDO - CHOCO</option>
              <option value="27077014">PUNTA PURRICHA - BAJO BAUDO - CHOCO</option>
              <option value="27077016">SIVIRU - BAJO BAUDO - CHOCO</option>
              <option value="27077019">VIRUDO - BAJO BAUDO - CHOCO</option>
              <option value="27077024">VILLA MARIA - BAJO BAUDO - CHOCO</option>
              <option value="27077026">GUINEAL - BAJO BAUDO - CHOCO</option>
              <option value="27077029">USARAGA - BAJO BAUDO - CHOCO</option>
              <option value="27077032">PUERTO ABADIA - BAJO BAUDO - CHOCO</option>
              <option value="27077034">TOCASINA - DUBASA - BAJO BAUDO - CHOCO</option>
              <option value="27077035">UNION MISARA - BAJO BAUDO - CHOCO</option>
              <option value="27077036">BELLA VISTA - BAJO BAUDO - CHOCO</option>
              <option value="27099000">BELLAVISTA - BOJAYA - CHOCO</option>
              <option value="27099001">ALFONSO LOPEZ - BOJAYA - CHOCO</option>
              <option value="27099002">LA LOMA DE BOJAYA - BOJAYA - CHOCO</option>
              <option value="27099003">ISLA DE LOS PALACIOS - BOJAYA - CHOCO</option>
              <option value="27099004">LA BOBA - BOJAYA - CHOCO</option>
              <option value="27099005">NAPIPI - BOJAYA - CHOCO</option>
              <option value="27099006">BOCA DE OPOGADO - BOJAYA - CHOCO</option>
              <option value="27099008">PUERTO CONTO - BOJAYA - CHOCO</option>
              <option value="27099009">SAN JOSE - BOJAYA - CHOCO</option>
              <option value="27099011">VERACRUZ - BOJAYA - CHOCO</option>
              <option value="27099012">POGUE - BOJAYA - CHOCO</option>
              <option value="27099013">MESOPOTAMIA - BOJAYA - CHOCO</option>
              <option value="27099015">EL TIGRE - BOJAYA - CHOCO</option>
              <option value="27099021">CORAZON DE JESUS - BOJAYA - CHOCO</option>
              <option value="27099029">PICHICORA - BOJAYA - CHOCO</option>
              <option value="27099030">PIEDRA CANDELA - BOJAYA - CHOCO</option>
              <option value="27135000">MANAGRU - EL CANTON DEL SAN PABLO - CHOCO</option>
              <option value="27135001">BOCA DE RASPADURA - EL CANTON DEL SAN PABLO - CHOCO</option>
              <option value="27135003">PUERTO PERVEL - EL CANTON DEL SAN PABLO - CHOCO</option>
              <option value="27135004">TARIDO - EL CANTON DEL SAN PABLO - CHOCO</option>
              <option value="27135005">GUAPANDO - EL CANTON DEL SAN PABLO - CHOCO</option>
              <option value="27135007">LA ISLA - EL CANTON DEL SAN PABLO - CHOCO</option>
              <option value="27150000">CURBARADO - CARMEN DEL DARIEN - CHOCO</option>
              <option value="27150002">DOMINGODO - CARMEN DEL DARIEN - CHOCO</option>
              <option value="27150003">LA GRANDE - CARMEN DEL DARIEN - CHOCO</option>
              <option value="27150004">PUERTO LLERAS - CARMEN DEL DARIEN - CHOCO</option>
              <option value="27150006">VIGIA DE CURBADO - CARMEN DEL DARIEN - CHOCO</option>
              <option value="27150007">VILLA NUEVA DE MONTAÑO - CARMEN DEL DARIEN - CHOCO</option>
              <option value="27150012">CHICAO - CARMEN DEL DARIEN - CHOCO</option>
              <option value="27150014">LA MADRE - CARMEN DEL DARIEN - CHOCO</option>
              <option value="27160000">CERTEGUI - CHOCO</option>
              <option value="27160002">PARECITO - CERTEGUI - CHOCO</option>
              <option value="27160006">MEMERA - CERTEGUI - CHOCO</option>
              <option value="27160007">OGODO - CERTEGUI - CHOCO</option>
              <option value="27160008">LA VUELTA - CERTEGUI - CHOCO</option>
              <option value="27160009">LAS HAMACAS - CERTEGUI - CHOCO</option>
              <option value="27160010">SAN JORGE - CERTEGUI - CHOCO</option>
              <option value="27160011">NIPORDU - CERTEGUI - CHOCO</option>
              <option value="27205000">CONDOTO - CHOCO</option>
              <option value="27205007">OPOGODO - CONDOTO - CHOCO</option>
              <option value="27205008">SANTA ANA - CONDOTO - CHOCO</option>
              <option value="27205014">LA PLANTA - CONDOTO - CHOCO</option>
              <option value="27205015">ILARIA - CONDOTO - CHOCO</option>
              <option value="27205016">CONSUELO ANDRAPEDA - CONDOTO - CHOCO</option>
              <option value="27205017">EL PASO - CONDOTO - CHOCO</option>
              <option value="27245000">EL CARMEN DE ATRATO - CHOCO</option>
              <option value="27245004">LA MANSA - EL CARMEN DE ATRATO - CHOCO</option>
              <option value="27245005">EL PORVENIR - EL CARMEN DE ATRATO - CHOCO</option>
              <option value="27245006">EL SIETE - EL CARMEN DE ATRATO - CHOCO</option>
              <option value="27245008">EL 18 - EL CARMEN DE ATRATO - CHOCO</option>
              <option value="27245010">LA PAZ - EL CARMEN DE ATRATO - CHOCO</option>
              <option value="27250000">SANTA GENOVEVA DE DOCORDO - EL LITORAL DEL SAN JUAN - CHOCO</option>
              <option value="27250001">COPOMA - EL LITORAL DEL SAN JUAN - CHOCO</option>
              <option value="27250002">CUCURRUPI - EL LITORAL DEL SAN JUAN - CHOCO</option>
              <option value="27250003">QUEBRADA DE TOGOROMA - EL LITORAL DEL SAN JUAN - CHOCO</option>
              <option value="27250005">PICHIMA - EL LITORAL DEL SAN JUAN - CHOCO</option>
              <option value="27250006">PALESTINA - EL LITORAL DEL SAN JUAN - CHOCO</option>
              <option value="27250010">EL COCO - EL LITORAL DEL SAN JUAN - CHOCO</option>
              <option value="27250012">QUICHARO - EL LITORAL DEL SAN JUAN - CHOCO</option>
              <option value="27250017">LAS PEÑITAS - EL LITORAL DEL SAN JUAN - CHOCO</option>
              <option value="27250018">LOS PEREA - EL LITORAL DEL SAN JUAN - CHOCO</option>
              <option value="27250019">MUNGUIDO - EL LITORAL DEL SAN JUAN - CHOCO</option>
              <option value="27250020">CHAPPIEN - EL LITORAL DEL SAN JUAN - CHOCO</option>
              <option value="27250021">BURUJON - EL LITORAL DEL SAN JUAN - CHOCO</option>
              <option value="27250022">PANGALITA - EL LITORAL DEL SAN JUAN - CHOCO</option>
              <option value="27250026">PUERTO MURILLO - EL LITORAL DEL SAN JUAN - CHOCO</option>
              <option value="27250029">PANGALA - EL LITORAL DEL SAN JUAN - CHOCO</option>
              <option value="27250030">SAN JOSE - EL LITORAL DEL SAN JUAN - CHOCO</option>
              <option value="27250031">TAPARAL - EL LITORAL DEL SAN JUAN - CHOCO</option>
              <option value="27250032">TAPARALITO - EL LITORAL DEL SAN JUAN - CHOCO</option>
              <option value="27250034">TORDO - EL LITORAL DEL SAN JUAN - CHOCO</option>
              <option value="27250039">UNION VALSALITO - EL LITORAL DEL SAN JUAN - CHOCO</option>
              <option value="27250040">BARRIOS UNIDOS - EL LITORAL DEL SAN JUAN - CHOCO</option>
              <option value="27250041">CABECERA - EL LITORAL DEL SAN JUAN - CHOCO</option>
              <option value="27250042">LAS DELICIAS - EL LITORAL DEL SAN JUAN - CHOCO</option>
              <option value="27250043">UNION GUAIMIA - EL LITORAL DEL SAN JUAN - CHOCO</option>
              <option value="27361000">ISTMINA - CHOCO</option>
              <option value="27361004">BASURU - ISTMINA - CHOCO</option>
              <option value="27361009">PRIMAVERA - ISTMINA - CHOCO</option>
              <option value="27361029">SAN ANTONIO - ISTMINA - CHOCO</option>
              <option value="27361032">SURUCO SANTA MONICA - ISTMINA - CHOCO</option>
              <option value="27361037">CARMELITA - ISTMINA - CHOCO</option>
              <option value="27361040">GUINIGUINI - ISTMINA - CHOCO</option>
              <option value="27361041">JUANA MARCELA - ISTMINA - CHOCO</option>
              <option value="27361042">PAITO - ISTMINA - CHOCO</option>
              <option value="27361043">PLAYA GRANDE - ISTMINA - CHOCO</option>
              <option value="27361044">CHIGORODO (PUERTO SALAZAR) - ISTMINA - CHOCO</option>
              <option value="27361049">PRIMERA MOJARRA - ISTMINA - CHOCO</option>
              <option value="27372000">JURADO - CHOCO</option>
              <option value="27372004">PUNTA ARDITA - JURADO - CHOCO</option>
              <option value="27372007">PUNTA PIÑA - JURADO - CHOCO</option>
              <option value="27372009">CUPICA - JURADO - CHOCO</option>
              <option value="27413000">LLORO - CHOCO</option>
              <option value="27413007">BORAUDO - LLORO - CHOCO</option>
              <option value="27413011">VILLA CLARET - LLORO - CHOCO</option>
              <option value="27413013">BOCA DE CAPA - LLORO - CHOCO</option>
              <option value="27413014">BOCAS DE TUMUTUMBUDO - LLORO - CHOCO</option>
              <option value="27413015">CANCHIDO - LLORO - CHOCO</option>
              <option value="27413016">LA PLAYA - LLORO - CHOCO</option>
              <option value="27413017">PLAYA ALTA - LLORO - CHOCO</option>
              <option value="27413018">PUERTO MORENO - LLORO - CHOCO</option>
              <option value="27425000">BETE - MEDIO ATRATO - CHOCO</option>
              <option value="27425001">BOCA DE AME - MEDIO ATRATO - CHOCO</option>
              <option value="27425002">BOCA DE BEBARA - MEDIO ATRATO - CHOCO</option>
              <option value="27425003">CAMPOALEGRE - MEDIO ATRATO - CHOCO</option>
              <option value="27425004">EL LLANO DE BEBARA - MEDIO ATRATO - CHOCO</option>
              <option value="27425005">EL LLANO DE BEBARAMA - MEDIO ATRATO - CHOCO</option>
              <option value="27425006">SAN ANTONIO DEL BUEY (CAMPO SANTO) - MEDIO ATRATO - CHOCO</option>
              <option value="27425007">SAN JOSE DE BUEY - MEDIO ATRATO - CHOCO</option>
              <option value="27425008">SAN ROQUE - MEDIO ATRATO - CHOCO</option>
              <option value="27425009">TANGÜI - MEDIO ATRATO - CHOCO</option>
              <option value="27425010">AGUA CLARA - MEDIO ATRATO - CHOCO</option>
              <option value="27425011">BAUDO GRANDE - MEDIO ATRATO - CHOCO</option>
              <option value="27425012">MEDIO BETE - MEDIO ATRATO - CHOCO</option>
              <option value="27425013">PUERTO SALAZAR - MEDIO ATRATO - CHOCO</option>
              <option value="27425014">PUNE - MEDIO ATRATO - CHOCO</option>
              <option value="27430000">PUERTO MELUK - MEDIO BAUDO - CHOCO</option>
              <option value="27430002">ARENAL - MEDIO BAUDO - CHOCO</option>
              <option value="27430003">BOCA DE BAUDOCITO - MEDIO BAUDO - CHOCO</option>
              <option value="27430006">CURUNDO LA BANCA - MEDIO BAUDO - CHOCO</option>
              <option value="27430009">PIE DE PEPE - MEDIO BAUDO - CHOCO</option>
              <option value="27430010">PUERTO ADAN - MEDIO BAUDO - CHOCO</option>
              <option value="27430013">SAN MIGUEL BAUDOCITO - MEDIO BAUDO - CHOCO</option>
              <option value="27430023">QUERA - MEDIO BAUDO - CHOCO</option>
              <option value="27430024">BOCA DE PEPE - MEDIO BAUDO - CHOCO</option>
              <option value="27430026">UNION MISARA - MEDIO BAUDO - CHOCO</option>
              <option value="27430028">BERRECUY - MEDIO BAUDO - CHOCO</option>
              <option value="27430029">BOCA DE CURUNDO - MEDIO BAUDO - CHOCO</option>
              <option value="27430030">CURUNDO LA LOMA - MEDIO BAUDO - CHOCO</option>
              <option value="27430032">PUERTO LIBIA - MEDIO BAUDO - CHOCO</option>
              <option value="27450000">ANDAGOYA - MEDIO SAN JUAN - CHOCO</option>
              <option value="27450001">BEBEDO - MEDIO SAN JUAN - CHOCO</option>
              <option value="27450007">LA RANCHA - MEDIO SAN JUAN - CHOCO</option>
              <option value="27450008">NOANAMA - MEDIO SAN JUAN - CHOCO</option>
              <option value="27450013">FUJIADO - MEDIO SAN JUAN - CHOCO</option>
              <option value="27450015">LA UNION - MEDIO SAN JUAN - CHOCO</option>
              <option value="27450017">PUERTO MURILLO - MEDIO SAN JUAN - CHOCO</option>
              <option value="27450020">CHAMBACU - MEDIO SAN JUAN - CHOCO</option>
              <option value="27491000">NOVITA - CHOCO</option>
              <option value="27491001">EL CAJON - NOVITA - CHOCO</option>
              <option value="27491002">EL TIGRE - NOVITA - CHOCO</option>
              <option value="27491006">SAN LORENZO - NOVITA - CHOCO</option>
              <option value="27491007">SESEGO - NOVITA - CHOCO</option>
              <option value="27491010">EL TAMBITO - NOVITA - CHOCO</option>
              <option value="27491014">SANTA ROSA - NOVITA - CHOCO</option>
              <option value="27491015">LA PUENTE - NOVITA - CHOCO</option>
              <option value="27491016">PINDAZA - NOVITA - CHOCO</option>
              <option value="27495000">NUQUI - CHOCO</option>
              <option value="27495001">ARUSI - NUQUI - CHOCO</option>
              <option value="27495002">COQUI - NUQUI - CHOCO</option>
              <option value="27495003">JURUBIRA - NUQUI - CHOCO</option>
              <option value="27495004">PANGUI - NUQUI - CHOCO</option>
              <option value="27495005">TRIBUGA - NUQUI - CHOCO</option>
              <option value="27495007">PARTADO - NUQUI - CHOCO</option>
              <option value="27495008">JOVI - NUQUI - CHOCO</option>
              <option value="27495010">TERMALES - NUQUI - CHOCO</option>
              <option value="27495011">BOCA DE JAGUA - NUQUI - CHOCO</option>
              <option value="27495012">PUERTO INDIO - NUQUI - CHOCO</option>
              <option value="27495013">VILLA NUEVA - NUQUI - CHOCO</option>
              <option value="27580000">SANTA RITA - RIO IRO - CHOCO</option>
              <option value="27580004">ENCHARCAZON - RIO IRO - CHOCO</option>
              <option value="27580005">SAN JOSE DE VIRO VIRO - RIO IRO - CHOCO</option>
              <option value="27580006">SANTA BARBARA - RIO IRO - CHOCO</option>
              <option value="27580008">EL BUEY - RIO IRO - CHOCO</option>
              <option value="27600000">PAIMADO - RIO QUITO - CHOCO</option>
              <option value="27600001">BOCA DE APARTADO - RIO QUITO - CHOCO</option>
              <option value="27600003">SAN ISIDRO - RIO QUITO - CHOCO</option>
              <option value="27600004">VILLA CONTO - RIO QUITO - CHOCO</option>
              <option value="27600006">CHIGUARANDO ALTO - RIO QUITO - CHOCO</option>
              <option value="27600007">CHIVIGUIDO - RIO QUITO - CHOCO</option>
              <option value="27600010">LA SOLEDAD - RIO QUITO - CHOCO</option>
              <option value="27600011">LOMA DE LOS GAMBOA - RIO QUITO - CHOCO</option>
              <option value="27615000">RIOSUCIO - CHOCO</option>
              <option value="27615006">LA HONDA - RIOSUCIO - CHOCO</option>
              <option value="27615022">PERANCHITO - RIOSUCIO - CHOCO</option>
              <option value="27615023">BELEN DE BAJIRA - RIOSUCIO - CHOCO</option>
              <option value="27615027">PUENTE AMERICA - CACARICA - RIOSUCIO - CHOCO</option>
              <option value="27615030">PEDEGUITA - RIOSUCIO - CHOCO</option>
              <option value="27615032">BRASITO - RIOSUCIO - CHOCO</option>
              <option value="27615033">BLANQUISET - RIOSUCIO - CHOCO</option>
              <option value="27615037">MACONDO - RIOSUCIO - CHOCO</option>
              <option value="27615038">NUEVO ORIENTE - RIOSUCIO - CHOCO</option>
              <option value="27615039">PLAYA ROJA - RIOSUCIO - CHOCO</option>
              <option value="27615045">7 DE AGOSTO - RIOSUCIO - CHOCO</option>
              <option value="27615046">LA PUNTA - RIOSUCIO - CHOCO</option>
              <option value="27615047">SANTA MARIA - RIOSUCIO - CHOCO</option>
              <option value="27615048">BRISAS - RIOSUCIO - CHOCO</option>
              <option value="27615049">CHINTADO MEDIO - RIOSUCIO - CHOCO</option>
              <option value="27660000">SAN JOSE DEL PALMAR - CHOCO</option>
              <option value="27660003">SAN PEDRO INGARA - SAN JOSE DEL PALMAR - CHOCO</option>
              <option value="27660006">LA ITALIA - SAN JOSE DEL PALMAR - CHOCO</option>
              <option value="27660016">JUNTAS DE TAMANA - SAN JOSE DEL PALMAR - CHOCO</option>
              <option value="27745000">SIPI - CHOCO</option>
              <option value="27745001">CAÑAVERAL - SIPI - CHOCO</option>
              <option value="27745003">SAN AGUSTIN - SIPI - CHOCO</option>
              <option value="27745009">TANANDO - SIPI - CHOCO</option>
              <option value="27745010">BUENAS BRISAS - SIPI - CHOCO</option>
              <option value="27745012">LOMA DE CHUPEY - SIPI - CHOCO</option>
              <option value="27745013">MARQUEZA - SIPI - CHOCO</option>
              <option value="27745014">SANTA ROSA - SIPI - CHOCO</option>
              <option value="27745015">TEATINO - SIPI - CHOCO</option>
              <option value="27787000">TADO - CHOCO</option>
              <option value="27787002">CARMELO - TADO - CHOCO</option>
              <option value="27787004">TAPON - TADO - CHOCO</option>
              <option value="27787005">GUARATO - TADO - CHOCO</option>
              <option value="27787009">MUMBU - TADO - CHOCO</option>
              <option value="27787010">PLAYA DE ORO - TADO - CHOCO</option>
              <option value="27787015">CORCOBADO - TADO - CHOCO</option>
              <option value="27787016">MANUNGARA - TADO - CHOCO</option>
              <option value="27787021">TABOR - TADO - CHOCO</option>
              <option value="27787022">ANGOSTURA - TADO - CHOCO</option>
              <option value="27787026">GINGARABA - TADO - CHOCO</option>
              <option value="27800000">UNGUIA - CHOCO</option>
              <option value="27800001">BALBOA - UNGUIA - CHOCO</option>
              <option value="27800002">GILGAL - UNGUIA - CHOCO</option>
              <option value="27800003">SANTA MARIA DEL DARIEN - UNGUIA - CHOCO</option>
              <option value="27800004">TANELA - UNGUIA - CHOCO</option>
              <option value="27800005">TITUMATE - UNGUIA - CHOCO</option>
              <option value="27800007">BETECITO - UNGUIA - CHOCO</option>
              <option value="27800008">MARRIAGA - UNGUIA - CHOCO</option>
              <option value="27800011">EL PUERTO - UNGUIA - CHOCO</option>
              <option value="27800015">ARQUIA - UNGUIA - CHOCO</option>
              <option value="27810000">ANIMAS - UNION PANAMERICANA - CHOCO</option>
              <option value="27810001">EL PLAN DE RASPADURA - UNION PANAMERICANA - CHOCO</option>
              <option value="27810002">LA YE - UNION PANAMERICANA - CHOCO</option>
              <option value="27810003">SAN RAFAEL DEL DOS - UNION PANAMERICANA - CHOCO</option>
              <option value="27810004">SAN PABLO ADENTRO - UNION PANAMERICANA - CHOCO</option>
              <option value="41001000">NEIVA - HUILA</option>
              <option value="41001001">CAGUAN - NEIVA - HUILA</option>
              <option value="41001002">CHAPINERO - NEIVA - HUILA</option>
              <option value="41001003">FORTALECILLAS - NEIVA - HUILA</option>
              <option value="41001004">GUACIRCO - NEIVA - HUILA</option>
              <option value="41001007">PALACIOS - NEIVA - HUILA</option>
              <option value="41001008">PEÑAS BLANCAS - NEIVA - HUILA</option>
              <option value="41001011">SAN LUIS - NEIVA - HUILA</option>
              <option value="41001012">VEGALARGA - NEIVA - HUILA</option>
              <option value="41001013">EL TRIUNFO - NEIVA - HUILA</option>
              <option value="41001014">SAN FRANCISCO - NEIVA - HUILA</option>
              <option value="41001016">EL COLEGIO - NEIVA - HUILA</option>
              <option value="41001017">SAN ANTONIO DE ANACONIA - NEIVA - HUILA</option>
              <option value="41001018">AIPECITO - NEIVA - HUILA</option>
              <option value="41001022">EL VENADO - NEIVA - HUILA</option>
              <option value="41001024">PIEDRA MARCADA - NEIVA - HUILA</option>
              <option value="41001029">CEDRALITO - NEIVA - HUILA</option>
              <option value="41001030">LA MATA - NEIVA - HUILA</option>
              <option value="41001031">PRADERA - NEIVA - HUILA</option>
              <option value="41001032">CEDRAL - NEIVA - HUILA</option>
              <option value="41001033">LA JULIA - NEIVA - HUILA</option>
              <option value="41001034">SAN JORGE - NEIVA - HUILA</option>
              <option value="41001036">MOSCOVIA - NEIVA - HUILA</option>
              <option value="41006000">ACEVEDO - HUILA</option>
              <option value="41006001">SAN ADOLFO - ACEVEDO - HUILA</option>
              <option value="41006003">PUEBLO VIEJO - ACEVEDO - HUILA</option>
              <option value="41006005">SAN MARCOS - ACEVEDO - HUILA</option>
              <option value="41006011">EL CARMEN - ACEVEDO - HUILA</option>
              <option value="41013000">AGRADO - HUILA</option>
              <option value="41013001">LA CAÑADA - AGRADO - HUILA</option>
              <option value="41013003">SAN JOSE DE BELEN - AGRADO - HUILA</option>
              <option value="41016000">AIPE - HUILA</option>
              <option value="41016001">PRAGA - AIPE - HUILA</option>
              <option value="41016002">SANTA RITA - AIPE - HUILA</option>
              <option value="41016003">EL PATA - AIPE - HUILA</option>
              <option value="41016004">CRUCE DE GUACIRCO - AIPE - HUILA</option>
              <option value="41016005">LA CEJA - MESITAS - AIPE - HUILA</option>
              <option value="41020000">ALGECIRAS - HUILA</option>
              <option value="41020001">EL PARAISO VIEJO - ALGECIRAS - HUILA</option>
              <option value="41020002">LA ARCADIA - ALGECIRAS - HUILA</option>
              <option value="41020003">EL TORO - ALGECIRAS - HUILA</option>
              <option value="41020006">EL PARAISO NUEVO - ALGECIRAS - HUILA</option>
              <option value="41026000">ALTAMIRA - HUILA</option>
              <option value="41026004">LLANO DE LA VIRGEN - ALTAMIRA - HUILA</option>
              <option value="41026005">PUENTE - ALTAMIRA - HUILA</option>
              <option value="41078000">BARAYA - HUILA</option>
              <option value="41132000">CAMPOALEGRE - HUILA</option>
              <option value="41132001">LA VEGA - CAMPOALEGRE - HUILA</option>
              <option value="41132002">OTAS - CAMPOALEGRE - HUILA</option>
              <option value="41132003">BAJO PIRAVANTE - CAMPOALEGRE - HUILA</option>
              <option value="41132004">RIO NEIVA - CAMPOALEGRE - HUILA</option>
              <option value="41132005">LA ESPERANZA - CAMPOALEGRE - HUILA</option>
              <option value="41132006">LOS ROSALES - CAMPOALEGRE - HUILA</option>
              <option value="41206000">COLOMBIA - HUILA</option>
              <option value="41206002">SANTANA - COLOMBIA - HUILA</option>
              <option value="41206005">SAN MARCOS - COLOMBIA - HUILA</option>
              <option value="41244000">ELIAS - HUILA</option>
              <option value="41244001">EL VISO - ELIAS - HUILA</option>
              <option value="41244002">ORITOGUAZ - ELIAS - HUILA</option>
              <option value="41298000">GARZON - HUILA</option>
              <option value="41298001">EL RECREO - GARZON - HUILA</option>
              <option value="41298002">LA JAGUA - GARZON - HUILA</option>
              <option value="41298003">SAN ANTONIO DEL PESCADO - GARZON - HUILA</option>
              <option value="41298004">ZULUAGA - GARZON - HUILA</option>
              <option value="41298005">EL PARAISO - GARZON - HUILA</option>
              <option value="41298007">PROVIDENCIA - GARZON - HUILA</option>
              <option value="41298008">EL MESON - GARZON - HUILA</option>
              <option value="41298012">PLAZUELA - GARZON - HUILA</option>
              <option value="41298013">CAGUANCITO - GARZON - HUILA</option>
              <option value="41298014">EL DESCANSO - GARZON - HUILA</option>
              <option value="41298015">MAJO - GARZON - HUILA</option>
              <option value="41298016">SAN GERARDO - GARZON - HUILA</option>
              <option value="41298017">SANTA MARTA - GARZON - HUILA</option>
              <option value="41298019">JAGUALITO - GARZON - HUILA</option>
              <option value="41298020">LA CABAÑA - GARZON - HUILA</option>
              <option value="41298021">SAN LUIS - GARZON - HUILA</option>
              <option value="41306000">GIGANTE - HUILA</option>
              <option value="41306001">LA CHIQUITA - GIGANTE - HUILA</option>
              <option value="41306002">LA GRAN VIA - GIGANTE - HUILA</option>
              <option value="41306003">POTRERILLOS - GIGANTE - HUILA</option>
              <option value="41306004">RIOLORO - GIGANTE - HUILA</option>
              <option value="41306006">EL MESON - GIGANTE - HUILA</option>
              <option value="41306007">PUEBLO NUEVO - GIGANTE - HUILA</option>
              <option value="41306009">VUELTAS ARRIBA - GIGANTE - HUILA</option>
              <option value="41306010">SILVANIA - GIGANTE - HUILA</option>
              <option value="41306011">TRES ESQUINAS - GIGANTE - HUILA</option>
              <option value="41306012">EL JARDIN - GIGANTE - HUILA</option>
              <option value="41306013">LA GRAN VIA EL PORVENIR - GIGANTE - HUILA</option>
              <option value="41306014">EL RECREO - GIGANTE - HUILA</option>
              <option value="41306015">LA BODEGA - GIGANTE - HUILA</option>
              <option value="41306016">LA VEGA - GIGANTE - HUILA</option>
              <option value="41319000">GUADALUPE - HUILA</option>
              <option value="41319001">RESINA - GUADALUPE - HUILA</option>
              <option value="41319002">MIRAFLORES - GUADALUPE - HUILA</option>
              <option value="41319003">LOS CAUCHOS - GUADALUPE - HUILA</option>
              <option value="41319004">POTRERILLOS - GUADALUPE - HUILA</option>
              <option value="41319005">CACHIMBAL - GUADALUPE - HUILA</option>
              <option value="41319006">SAN JOSE - GUADALUPE - HUILA</option>
              <option value="41319007">SARTENEJAL - GUADALUPE - HUILA</option>
              <option value="41349000">HOBO - HUILA</option>
              <option value="41357000">IQUIRA - HUILA</option>
              <option value="41357003">RIO NEGRO - IQUIRA - HUILA</option>
              <option value="41357004">VALENCIA LA PAZ - IQUIRA - HUILA</option>
              <option value="41357005">SAN LUIS - IQUIRA - HUILA</option>
              <option value="41357006">SAN MIGUEL - IQUIRA - HUILA</option>
              <option value="41359000">SAN JOSE DE ISNOS - ISNOS - HUILA</option>
              <option value="41359003">EL SALTO DE BORDONES - ISNOS - HUILA</option>
              <option value="41359006">BAJO JUNIN - ISNOS - HUILA</option>
              <option value="41359007">BUENOS AIRES - ISNOS - HUILA</option>
              <option value="41359008">CIENAGA GRANDE - ISNOS - HUILA</option>
              <option value="41378000">LA ARGENTINA - HUILA</option>
              <option value="41378001">BUENOS AIRES - LA ARGENTINA - HUILA</option>
              <option value="41378002">EL PENSIL - LA ARGENTINA - HUILA</option>
              <option value="41396000">LA PLATA - HUILA</option>
              <option value="41396001">BELEN - LA PLATA - HUILA</option>
              <option value="41396002">MONSERRATE - LA PLATA - HUILA</option>
              <option value="41396004">SAN ANDRES - LA PLATA - HUILA</option>
              <option value="41396005">VILLA LOSADA - LA PLATA - HUILA</option>
              <option value="41396006">SAN VICENTE - LA PLATA - HUILA</option>
              <option value="41396009">GALLEGO - LA PLATA - HUILA</option>
              <option value="41483000">NATAGA - HUILA</option>
              <option value="41483001">PATIO BONITO - NATAGA - HUILA</option>
              <option value="41483002">LLANO BUCO - NATAGA - HUILA</option>
              <option value="41483003">YARUMAL - NATAGA - HUILA</option>
              <option value="41503000">OPORAPA - HUILA</option>
              <option value="41503001">SAN ROQUE - OPORAPA - HUILA</option>
              <option value="41503002">EL CARMEN - OPORAPA - HUILA</option>
              <option value="41503003">SAN CIRO - OPORAPA - HUILA</option>
              <option value="41503004">PARAGUAY - OPORAPA - HUILA</option>
              <option value="41518000">PAICOL - HUILA</option>
              <option value="41518001">LA REFORMA - PAICOL - HUILA</option>
              <option value="41518002">LAS LAJITAS - PAICOL - HUILA</option>
              <option value="41524000">PALERMO - HUILA</option>
              <option value="41524001">BETANIA - PALERMO - HUILA</option>
              <option value="41524004">OSPINA PEREZ - PALERMO - HUILA</option>
              <option value="41524005">SAN JUAN - PALERMO - HUILA</option>
              <option value="41524006">EL JUNCAL - PALERMO - HUILA</option>
              <option value="41524009">AMBORCO - PALERMO - HUILA</option>
              <option value="41530000">PALESTINA - HUILA</option>
              <option value="41548000">PITAL - HUILA</option>
              <option value="41548001">EL SOCORRO - PITAL - HUILA</option>
              <option value="41548002">MINAS - PITAL - HUILA</option>
              <option value="41551000">PITALITO - HUILA</option>
              <option value="41551001">BRUSELAS - PITALITO - HUILA</option>
              <option value="41551002">GUACACAYO - PITALITO - HUILA</option>
              <option value="41551003">LA LAGUNA - PITALITO - HUILA</option>
              <option value="41551005">REGUEROS - PITALITO - HUILA</option>
              <option value="41551006">CHILLURCO (VILLAS DEL NORTE) - PITALITO - HUILA</option>
              <option value="41551008">CRIOLLO - PITALITO - HUILA</option>
              <option value="41551009">CHARGUAYACO - PITALITO - HUILA</option>
              <option value="41551010">PALMARITO - PITALITO - HUILA</option>
              <option value="41551015">LOS ARRAYANES - PITALITO - HUILA</option>
              <option value="41615000">RIVERA - HUILA</option>
              <option value="41615001">LA ULLOA - RIVERA - HUILA</option>
              <option value="41615002">RIVERITA - RIVERA - HUILA</option>
              <option value="41615006">RIO FRIO - RIVERA - HUILA</option>
              <option value="41615007">EL GUADUAL - RIVERA - HUILA</option>
              <option value="41660000">SALADOBLANCO - HUILA</option>
              <option value="41660001">LA CABAÑA - SALADOBLANCO - HUILA</option>
              <option value="41660007">MORELIA - SALADOBLANCO - HUILA</option>
              <option value="41668000">SAN AGUSTIN - HUILA</option>
              <option value="41668001">ALTO DEL OBISPO - SAN AGUSTIN - HUILA</option>
              <option value="41668002">OBANDO - SAN AGUSTIN - HUILA</option>
              <option value="41668003">VILLA FATIMA - SAN AGUSTIN - HUILA</option>
              <option value="41668004">PUERTO QUINCHANA - SAN AGUSTIN - HUILA</option>
              <option value="41668006">EL PALMAR - SAN AGUSTIN - HUILA</option>
              <option value="41668007">PRADERA - SAN AGUSTIN - HUILA</option>
              <option value="41668008">LOS CAUCHOS - SAN AGUSTIN - HUILA</option>
              <option value="41668009">EL ROSARIO - SAN AGUSTIN - HUILA</option>
              <option value="41676000">SANTA MARIA - HUILA</option>
              <option value="41676001">SAN JOAQUIN - SANTA MARIA - HUILA</option>
              <option value="41770000">SUAZA - HUILA</option>
              <option value="41770001">GALLARDO - SUAZA - HUILA</option>
              <option value="41770002">GUAYABAL - SUAZA - HUILA</option>
              <option value="41770011">CRUCE ACEVEDO - SUAZA - HUILA</option>
              <option value="41770012">SAN JOSE - SUAZA - HUILA</option>
              <option value="41791000">TARQUI - HUILA</option>
              <option value="41791001">EL VERGEL - TARQUI - HUILA</option>
              <option value="41791002">MAITO - TARQUI - HUILA</option>
              <option value="41791003">QUITURO - TARQUI - HUILA</option>
              <option value="41797000">TESALIA - HUILA</option>
              <option value="41797001">PACARNI - TESALIA - HUILA</option>
              <option value="41799000">TELLO - HUILA</option>
              <option value="41799001">ANACLETO GARCIA - TELLO - HUILA</option>
              <option value="41799002">SIERRA DEL GRAMAL - TELLO - HUILA</option>
              <option value="41799003">SAN ANDRES TELLO - TELLO - HUILA</option>
              <option value="41799004">SIERRA DE LA CAÑADA - TELLO - HUILA</option>
              <option value="41801000">TERUEL - HUILA</option>
              <option value="41807000">TIMANA - HUILA</option>
              <option value="41807001">NARANJAL - TIMANA - HUILA</option>
              <option value="41807004">SAN ANTONIO - TIMANA - HUILA</option>
              <option value="41807005">MONTAÑITA - TIMANA - HUILA</option>
              <option value="41807006">QUINCHE - TIMANA - HUILA</option>
              <option value="41807007">COSANZA - TIMANA - HUILA</option>
              <option value="41807009">SAN ISIDRO - TIMANA - HUILA</option>
              <option value="41807010">AGUAS CLARAS - TIMANA - HUILA</option>
              <option value="41807011">ALTO NARANJAL - TIMANA - HUILA</option>
              <option value="41807013">PANTANOS - TIMANA - HUILA</option>
              <option value="41807014">SANTA BARBARA - TIMANA - HUILA</option>
              <option value="41872000">VILLAVIEJA - HUILA</option>
              <option value="41872001">POTOSI - VILLAVIEJA - HUILA</option>
              <option value="41872002">SAN ALFONSO - VILLAVIEJA - HUILA</option>
              <option value="41872003">HATO NUEVO - VILLAVIEJA - HUILA</option>
              <option value="41872004">POLONIA - VILLAVIEJA - HUILA</option>
              <option value="41872005">LA VICTORIA - VILLAVIEJA - HUILA</option>
              <option value="41885000">YAGUARA - HUILA</option>
              <option value="44001000">RIOHACHA, DISTRITO ESPECIAL, TURISTICO Y CULTURAL - RIOHACHA - LA GUAJIRA</option>
              <option value="44001001">ARROYO ARENA - RIOHACHA - LA GUAJIRA</option>
              <option value="44001002">BARBACOA - RIOHACHA - LA GUAJIRA</option>
              <option value="44001003">CAMARONES - RIOHACHA - LA GUAJIRA</option>
              <option value="44001004">CASCAJALITO - RIOHACHA - LA GUAJIRA</option>
              <option value="44001005">COTOPRIX - RIOHACHA - LA GUAJIRA</option>
              <option value="44001008">GALAN - RIOHACHA - LA GUAJIRA</option>
              <option value="44001011">MATITAS - RIOHACHA - LA GUAJIRA</option>
              <option value="44001012">MONGUI - RIOHACHA - LA GUAJIRA</option>
              <option value="44001016">TOMARRAZON (TREINTA) - RIOHACHA - LA GUAJIRA</option>
              <option value="44001017">VILLA MARTIN (MACHO VALLO) - RIOHACHA - LA GUAJIRA</option>
              <option value="44001018">LAS PALMAS - RIOHACHA - LA GUAJIRA</option>
              <option value="44001020">CHOLES - RIOHACHA - LA GUAJIRA</option>
              <option value="44001021">COMEJENES - RIOHACHA - LA GUAJIRA</option>
              <option value="44001022">EL ABRA - RIOHACHA - LA GUAJIRA</option>
              <option value="44001023">LAS CASITAS - RIOHACHA - LA GUAJIRA</option>
              <option value="44001024">LOS MORENEROS - RIOHACHA - LA GUAJIRA</option>
              <option value="44001025">PELECHUA - RIOHACHA - LA GUAJIRA</option>
              <option value="44001026">PERICO - RIOHACHA - LA GUAJIRA</option>
              <option value="44001027">TIGRERA - RIOHACHA - LA GUAJIRA</option>
              <option value="44001028">ANAIME - RIOHACHA - LA GUAJIRA</option>
              <option value="44001031">CERRILLO - RIOHACHA - LA GUAJIRA</option>
              <option value="44001032">CUCURUMANA - RIOHACHA - LA GUAJIRA</option>
              <option value="44001033">EBANAL - RIOHACHA - LA GUAJIRA</option>
              <option value="44001035">JUAN Y MEDIO - RIOHACHA - LA GUAJIRA</option>
              <option value="44001036">LA ARENA - RIOHACHA - LA GUAJIRA</option>
              <option value="44001040">PUENTE BOMBA - RIOHACHA - LA GUAJIRA</option>
              <option value="44001041">EL CARMEN - RIOHACHA - LA GUAJIRA</option>
              <option value="44001042">LA COMPAÑIA - RIOHACHA - LA GUAJIRA</option>
              <option value="44001043">PUERTO COLOMBIA - RIOHACHA - LA GUAJIRA</option>
              <option value="44001044">VILLA COMPI - RIOHACHA - LA GUAJIRA</option>
              <option value="44035000">ALBANIA - LA GUAJIRA</option>
              <option value="44035002">WARE WAREN - ALBANIA - LA GUAJIRA</option>
              <option value="44035003">LOS REMEDIOS - ALBANIA - LA GUAJIRA</option>
              <option value="44035004">LOS RANCHOS - ALBANIA - LA GUAJIRA</option>
              <option value="44035005">PITURUMANA - ALBANIA - LA GUAJIRA</option>
              <option value="44035006">PORCIOSA - ALBANIA - LA GUAJIRA</option>
              <option value="44078000">BARRANCAS - LA GUAJIRA</option>
              <option value="44078001">CARRETALITO - BARRANCAS - LA GUAJIRA</option>
              <option value="44078006">PAPAYAL - BARRANCAS - LA GUAJIRA</option>
              <option value="44078007">ROCHE - BARRANCAS - LA GUAJIRA</option>
              <option value="44078008">SAN PEDRO - BARRANCAS - LA GUAJIRA</option>
              <option value="44078009">GUAYACANAL - BARRANCAS - LA GUAJIRA</option>
              <option value="44078011">POZO HONDO - BARRANCAS - LA GUAJIRA</option>
              <option value="44078013">NUEVO OREGANAL - BARRANCAS - LA GUAJIRA</option>
              <option value="44078014">PATILLA - BARRANCAS - LA GUAJIRA</option>
              <option value="44078015">CHANCLETA - BARRANCAS - LA GUAJIRA</option>
              <option value="44078016">LAS CASITAS - BARRANCAS - LA GUAJIRA</option>
              <option value="44090000">DIBULLA - LA GUAJIRA</option>
              <option value="44090001">LA PUNTA DE LOS REMEDIOS - DIBULLA - LA GUAJIRA</option>
              <option value="44090002">LAS FLORES - DIBULLA - LA GUAJIRA</option>
              <option value="44090003">MINGUEO - DIBULLA - LA GUAJIRA</option>
              <option value="44090004">PALOMINO - DIBULLA - LA GUAJIRA</option>
              <option value="44090005">CAMPANA NUEVO - DIBULLA - LA GUAJIRA</option>
              <option value="44090006">RIO ANCHO - DIBULLA - LA GUAJIRA</option>
              <option value="44090007">CASA DE ALUMINIO - DIBULLA - LA GUAJIRA</option>
              <option value="44090008">RIO JEREZ - DIBULLA - LA GUAJIRA</option>
              <option value="44090010">SANTA RITA DE LA SIERRA - DIBULLA - LA GUAJIRA</option>
              <option value="44098000">DISTRACCION - LA GUAJIRA</option>
              <option value="44098001">BUENAVISTA - DISTRACCION - LA GUAJIRA</option>
              <option value="44098002">CHORRERAS - DISTRACCION - LA GUAJIRA</option>
              <option value="44098003">CAIMITO (RESGUARDO) - DISTRACCION - LA GUAJIRA</option>
              <option value="44098005">LA DUDA - DISTRACCION - LA GUAJIRA</option>
              <option value="44098007">LA CEIBA (RESGUARDO) - DISTRACCION - LA GUAJIRA</option>
              <option value="44098008">LOS HORNITOS - DISTRACCION - LA GUAJIRA</option>
              <option value="44098011">POTRERITO - DISTRACCION - LA GUAJIRA</option>
              <option value="44098012">PULGAR - DISTRACCION - LA GUAJIRA</option>
              <option value="44110000">EL MOLINO - LA GUAJIRA</option>
              <option value="44279000">FONSECA - LA GUAJIRA</option>
              <option value="44279002">CONEJO - FONSECA - LA GUAJIRA</option>
              <option value="44279005">EL HATICO - FONSECA - LA GUAJIRA</option>
              <option value="44279006">SITIONUEVO - FONSECA - LA GUAJIRA</option>
              <option value="44279007">CARDONAL - FONSECA - LA GUAJIRA</option>
              <option value="44279008">BANGAÑITAS - FONSECA - LA GUAJIRA</option>
              <option value="44279011">EL CONFUSO - FONSECA - LA GUAJIRA</option>
              <option value="44279013">LOS ALTOS - FONSECA - LA GUAJIRA</option>
              <option value="44279014">QUEBRACHAL - FONSECA - LA GUAJIRA</option>
              <option value="44279015">POTRERITO - FONSECA - LA GUAJIRA</option>
              <option value="44279016">GUAMACHAL - FONSECA - LA GUAJIRA</option>
              <option value="44279022">LA LAGUNA - FONSECA - LA GUAJIRA</option>
              <option value="44279023">LOS TORQUITOS - FONSECA - LA GUAJIRA</option>
              <option value="44378000">HATONUEVO - LA GUAJIRA</option>
              <option value="44378004">EL POZO - HATONUEVO - LA GUAJIRA</option>
              <option value="44378005">GUAIMARITO - HATONUEVO - LA GUAJIRA</option>
              <option value="44420000">LA JAGUA DEL PILAR - LA GUAJIRA</option>
              <option value="44420001">EL PLAN - LA JAGUA DEL PILAR - LA GUAJIRA</option>
              <option value="44430000">MAICAO - LA GUAJIRA</option>
              <option value="44430002">CARRAIPIA - MAICAO - LA GUAJIRA</option>
              <option value="44430005">LA PAZ - MAICAO - LA GUAJIRA</option>
              <option value="44430006">LA MAJAYURA - MAICAO - LA GUAJIRA</option>
              <option value="44430007">PARAGUACHON - MAICAO - LA GUAJIRA</option>
              <option value="44430012">EL LIMONCITO - MAICAO - LA GUAJIRA</option>
              <option value="44430014">GARRAPATERO - MAICAO - LA GUAJIRA</option>
              <option value="44430015">MAKU - MAICAO - LA GUAJIRA</option>
              <option value="44430016">SANTA CRUZ - MAICAO - LA GUAJIRA</option>
              <option value="44430017">SANTA ROSA - MAICAO - LA GUAJIRA</option>
              <option value="44430018">DIVINO NIÑO - MAICAO - LA GUAJIRA</option>
              <option value="44430019">LA ESPERANZA - MAICAO - LA GUAJIRA</option>
              <option value="44430020">MONTE LARA - MAICAO - LA GUAJIRA</option>
              <option value="44560000">MANAURE - LA GUAJIRA</option>
              <option value="44560001">AREMASAHIN - MANAURE - LA GUAJIRA</option>
              <option value="44560002">MUSICHI - MANAURE - LA GUAJIRA</option>
              <option value="44560003">EL PAJARO - MANAURE - LA GUAJIRA</option>
              <option value="44560004">SAN ANTONIO - MANAURE - LA GUAJIRA</option>
              <option value="44560006">SHIRURIA - MANAURE - LA GUAJIRA</option>
              <option value="44560007">MAYAPO - MANAURE - LA GUAJIRA</option>
              <option value="44560008">MANZANA - MANAURE - LA GUAJIRA</option>
              <option value="44560009">LA GLORIA - MANAURE - LA GUAJIRA</option>
              <option value="44560010">LA PAZ - MANAURE - LA GUAJIRA</option>
              <option value="44560011">AIMARAL - MANAURE - LA GUAJIRA</option>
              <option value="44560012">ARROYO LIMON - MANAURE - LA GUAJIRA</option>
              <option value="44560013">POROMANA - MANAURE - LA GUAJIRA</option>
              <option value="44650000">SAN JUAN DEL CESAR - LA GUAJIRA</option>
              <option value="44650001">CAÑAVERALES - SAN JUAN DEL CESAR - LA GUAJIRA</option>
              <option value="44650002">CARACOLI - SAN JUAN DEL CESAR - LA GUAJIRA</option>
              <option value="44650003">CORRAL DE PIEDRA - SAN JUAN DEL CESAR - LA GUAJIRA</option>
              <option value="44650004">EL HATICO DE LOS INDIOS - SAN JUAN DEL CESAR - LA GUAJIRA</option>
              <option value="44650005">EL TABLAZO - SAN JUAN DEL CESAR - LA GUAJIRA</option>
              <option value="44650006">EL TOTUMO - SAN JUAN DEL CESAR - LA GUAJIRA</option>
              <option value="44650007">GUAYACANAL - SAN JUAN DEL CESAR - LA GUAJIRA</option>
              <option value="44650008">LA JUNTA - SAN JUAN DEL CESAR - LA GUAJIRA</option>
              <option value="44650009">LA PEÑA - SAN JUAN DEL CESAR - LA GUAJIRA</option>
              <option value="44650010">LA SIERRITA - SAN JUAN DEL CESAR - LA GUAJIRA</option>
              <option value="44650011">LOS HATICOS - SAN JUAN DEL CESAR - LA GUAJIRA</option>
              <option value="44650012">LOS PONDORES - SAN JUAN DEL CESAR - LA GUAJIRA</option>
              <option value="44650013">ZAMBRANO - SAN JUAN DEL CESAR - LA GUAJIRA</option>
              <option value="44650014">CORRALEJAS - SAN JUAN DEL CESAR - LA GUAJIRA</option>
              <option value="44650016">PONDORITOS - SAN JUAN DEL CESAR - LA GUAJIRA</option>
              <option value="44650017">VILLA DEL RIO - SAN JUAN DEL CESAR - LA GUAJIRA</option>
              <option value="44650018">LAGUNITA - SAN JUAN DEL CESAR - LA GUAJIRA</option>
              <option value="44650019">LOS POZOS - SAN JUAN DEL CESAR - LA GUAJIRA</option>
              <option value="44650021">CURAZAO - SAN JUAN DEL CESAR - LA GUAJIRA</option>
              <option value="44650022">BOCA DEL MONTE - SAN JUAN DEL CESAR - LA GUAJIRA</option>
              <option value="44650023">LOS CARDONES - SAN JUAN DEL CESAR - LA GUAJIRA</option>
              <option value="44650024">EL PLACER - SAN JUAN DEL CESAR - LA GUAJIRA</option>
              <option value="44650025">GUAMACHAL - SAN JUAN DEL CESAR - LA GUAJIRA</option>
              <option value="44650026">LOS TUNALES - SAN JUAN DEL CESAR - LA GUAJIRA</option>
              <option value="44650027">VERACRUZ - SAN JUAN DEL CESAR - LA GUAJIRA</option>
              <option value="44847000">URIBIA - LA GUAJIRA</option>
              <option value="44847003">CABO DE LA VELA - URIBIA - LA GUAJIRA</option>
              <option value="44847004">CARRIZAL - URIBIA - LA GUAJIRA</option>
              <option value="44847007">EL CARDON - URIBIA - LA GUAJIRA</option>
              <option value="44847012">NAZARETH - URIBIA - LA GUAJIRA</option>
              <option value="44847013">PUERTO ESTRELLA - URIBIA - LA GUAJIRA</option>
              <option value="44847027">LECHIMANA - URIBIA - LA GUAJIRA</option>
              <option value="44847028">MEDIA LUNA - URIBIA - LA GUAJIRA</option>
              <option value="44847029">PARAISO - URIBIA - LA GUAJIRA</option>
              <option value="44847031">PUERTO NUEVO - URIBIA - LA GUAJIRA</option>
              <option value="44847032">SANTA ANA - URIBIA - LA GUAJIRA</option>
              <option value="44847033">SANTA FE DE SIAPANA - URIBIA - LA GUAJIRA</option>
              <option value="44847034">VILLA FATIMA - URIBIA - LA GUAJIRA</option>
              <option value="44847035">WARPANA - URIBIA - LA GUAJIRA</option>
              <option value="44847036">WARRUTAMANA - URIBIA - LA GUAJIRA</option>
              <option value="44847037">WOSOSOPO - URIBIA - LA GUAJIRA</option>
              <option value="44847038">YORIJARU - URIBIA - LA GUAJIRA</option>
              <option value="44855000">URUMITA - LA GUAJIRA</option>
              <option value="44874000">VILLANUEVA - LA GUAJIRA</option>
              <option value="47001000">SANTA MARTA, DISTRITO TURISTICO, CULTURAL E HISTORICO - SANTA MARTA - MAGDALENA</option>
              <option value="47001001">BONDA - SANTA MARTA - MAGDALENA</option>
              <option value="47001002">CALABAZO - SANTA MARTA - MAGDALENA</option>
              <option value="47001003">DON DIEGO - SANTA MARTA - MAGDALENA</option>
              <option value="47001006">GUACHACA - SANTA MARTA - MAGDALENA</option>
              <option value="47001009">MINCA - SANTA MARTA - MAGDALENA</option>
              <option value="47001010">TAGANGA - SANTA MARTA - MAGDALENA</option>
              <option value="47001011">BURITACA - SANTA MARTA - MAGDALENA</option>
              <option value="47001012">LA QUININA - SANTA MARTA - MAGDALENA</option>
              <option value="47001013">TIGRERA - SANTA MARTA - MAGDALENA</option>
              <option value="47001022">CABAÑAS DE BURITACA - SANTA MARTA - MAGDALENA</option>
              <option value="47001023">CAÑAVERAL (AGUA FRIA) - SANTA MARTA - MAGDALENA</option>
              <option value="47001025">CURVALITO - SANTA MARTA - MAGDALENA</option>
              <option value="47001026">GUACOCHE (LA LLANTA) - SANTA MARTA - MAGDALENA</option>
              <option value="47001027">MARKETALIA (PALOMINO) - SANTA MARTA - MAGDALENA</option>
              <option value="47001028">PAZ DEL CARIBE - SANTA MARTA - MAGDALENA</option>
              <option value="47001029">PERICO AGUAO - SANTA MARTA - MAGDALENA</option>
              <option value="47001032">LA REVUELTA - SANTA MARTA - MAGDALENA</option>
              <option value="47001034">EL TROMPITO - SANTA MARTA - MAGDALENA</option>
              <option value="47001035">LA AGUACATERA - SANTA MARTA - MAGDALENA</option>
              <option value="47001036">MACHETE PELAO - SANTA MARTA - MAGDALENA</option>
              <option value="47001037">NUEVO MEJICO - SANTA MARTA - MAGDALENA</option>
              <option value="47001038">VALLE DE GAIRA - SANTA MARTA - MAGDALENA</option>
              <option value="47001039">LINDEROS - SANTA MARTA - MAGDALENA</option>
              <option value="47001040">LOS COCOS - SANTA MARTA - MAGDALENA</option>
              <option value="47001041">MENDIHUACA - SANTA MARTA - MAGDALENA</option>
              <option value="47001042">QUEBRADA VALENCIA - SANTA MARTA - MAGDALENA</option>
              <option value="47001043">SAN TROPEL - SANTA MARTA - MAGDALENA</option>
              <option value="47001044">LOS NARANJOS - SANTA MARTA - MAGDALENA</option>
              <option value="47001045">NUEVO HORIZONTE (SAN RAFAEL) - SANTA MARTA - MAGDALENA</option>
              <option value="47030000">ALGARROBO - MAGDALENA</option>
              <option value="47030001">BELLA VISTA - ALGARROBO - MAGDALENA</option>
              <option value="47030002">ESTACION DEL FERROCARRIL - ALGARROBO - MAGDALENA</option>
              <option value="47030003">ESTACION LLERAS - ALGARROBO - MAGDALENA</option>
              <option value="47030004">LOMA DEL BALSAMO - ALGARROBO - MAGDALENA</option>
              <option value="47030006">RIOMAR - ALGARROBO - MAGDALENA</option>
              <option value="47053000">ARACATACA - MAGDALENA</option>
              <option value="47053001">BUENOS AIRES - ARACATACA - MAGDALENA</option>
              <option value="47053011">CAUCA - ARACATACA - MAGDALENA</option>
              <option value="47053013">SAMPUES - ARACATACA - MAGDALENA</option>
              <option value="47053016">EL TIGRE - ARACATACA - MAGDALENA</option>
              <option value="47053017">GUNMAKU - ARACATACA - MAGDALENA</option>
              <option value="47053018">RIO DE PIEDRA II - ARACATACA - MAGDALENA</option>
              <option value="47058000">EL DIFICIL - ARIGUANI - MAGDALENA</option>
              <option value="47058001">ALEJANDRIA - ARIGUANI - MAGDALENA</option>
              <option value="47058003">PUEBLO NUEVO - ARIGUANI - MAGDALENA</option>
              <option value="47058005">SAN JOSE DE ARIGUANI - ARIGUANI - MAGDALENA</option>
              <option value="47058008">VADELCO - ARIGUANI - MAGDALENA</option>
              <option value="47058009">CARMEN DE ARIGUANI - ARIGUANI - MAGDALENA</option>
              <option value="47161000">CERRO DE SAN ANTONIO - MAGDALENA</option>
              <option value="47161002">CANDELARIA (CAIMAN) - CERRO DE SAN ANTONIO - MAGDALENA</option>
              <option value="47161003">CONCEPCION (COCO) - CERRO DE SAN ANTONIO - MAGDALENA</option>
              <option value="47161005">JESUS DEL MONTE (MICO) - CERRO DE SAN ANTONIO - MAGDALENA</option>
              <option value="47161006">PUERTO NIÑO (CHARANGA) - CERRO DE SAN ANTONIO - MAGDALENA</option>
              <option value="47170000">CHIVOLO - MAGDALENA</option>
              <option value="47170001">LA CHINA - CHIVOLO - MAGDALENA</option>
              <option value="47170002">PUEBLO NUEVO - CHIVOLO - MAGDALENA</option>
              <option value="47170003">LA ESTRELLA - CHIVOLO - MAGDALENA</option>
              <option value="47170004">LA POLA - CHIVOLO - MAGDALENA</option>
              <option value="47170005">PLAN - CHIVOLO - MAGDALENA</option>
              <option value="47189000">CIENAGA - MAGDALENA</option>
              <option value="47189004">SAN PEDRO DE LA SIERRA - CIENAGA - MAGDALENA</option>
              <option value="47189006">SEVILLANO - CIENAGA - MAGDALENA</option>
              <option value="47189018">PALMOR - CIENAGA - MAGDALENA</option>
              <option value="47189022">CORDOBITA - CIENAGA - MAGDALENA</option>
              <option value="47189023">SIBERIA - CIENAGA - MAGDALENA</option>
              <option value="47189024">LA ISABEL - CIENAGA - MAGDALENA</option>
              <option value="47189025">MAYA - CIENAGA - MAGDALENA</option>
              <option value="47189026">SAN JAVIER - CIENAGA - MAGDALENA</option>
              <option value="47205000">CONCORDIA - MAGDALENA</option>
              <option value="47205001">BALSAMO - CONCORDIA - MAGDALENA</option>
              <option value="47205002">BELLAVISTA - CONCORDIA - MAGDALENA</option>
              <option value="47205003">ROSARIO DEL CHENGUE - CONCORDIA - MAGDALENA</option>
              <option value="47245000">EL BANCO - MAGDALENA</option>
              <option value="47245001">AGUAESTRADA - EL BANCO - MAGDALENA</option>
              <option value="47245002">ALGARROBAL - EL BANCO - MAGDALENA</option>
              <option value="47245003">EL BARRANCO DE CHILLOA - EL BANCO - MAGDALENA</option>
              <option value="47245004">LOS NEGRITOS - EL BANCO - MAGDALENA</option>
              <option value="47245005">BELEN - EL BANCO - MAGDALENA</option>
              <option value="47245006">CAÑO DE PALMA - EL BANCO - MAGDALENA</option>
              <option value="47245007">EL CERRITO - EL BANCO - MAGDALENA</option>
              <option value="47245008">EL TREBOL - EL BANCO - MAGDALENA</option>
              <option value="47245010">MENCHIQUEJO - EL BANCO - MAGDALENA</option>
              <option value="47245011">HATILLO DE LA SABANA - EL BANCO - MAGDALENA</option>
              <option value="47245012">SAN JOSE - EL BANCO - MAGDALENA</option>
              <option value="47245013">SAN ROQUE - EL BANCO - MAGDALENA</option>
              <option value="47245014">TAMALAMEQUITO - EL BANCO - MAGDALENA</option>
              <option value="47245016">SAN FELIPE Y SAN EDUARDO - EL BANCO - MAGDALENA</option>
              <option value="47245018">GUACAMAYAL - EL BANCO - MAGDALENA</option>
              <option value="47245019">MALPICA - EL BANCO - MAGDALENA</option>
              <option value="47245020">GARZON - EL BANCO - MAGDALENA</option>
              <option value="47245022">ISLITAS - EL BANCO - MAGDALENA</option>
              <option value="47245025">BOTILLERO - EL BANCO - MAGDALENA</option>
              <option value="47245028">PUEBLO NUEVO - EL BANCO - MAGDALENA</option>
              <option value="47245032">MATA DE CAÑA - EL BANCO - MAGDALENA</option>
              <option value="47245033">EL CEDRO - EL BANCO - MAGDALENA</option>
              <option value="47258000">EL PIÑON - MAGDALENA</option>
              <option value="47258001">CAMPO ALEGRE - EL PIÑON - MAGDALENA</option>
              <option value="47258002">CANTAGALLAR - EL PIÑON - MAGDALENA</option>
              <option value="47258003">CARRETO - EL PIÑON - MAGDALENA</option>
              <option value="47258004">PLAYON DE OROZCO - EL PIÑON - MAGDALENA</option>
              <option value="47258005">SABANAS - EL PIÑON - MAGDALENA</option>
              <option value="47258006">SAN BASILIO - EL PIÑON - MAGDALENA</option>
              <option value="47258007">TIO GOLLO - EL PIÑON - MAGDALENA</option>
              <option value="47258009">VERANILLO - EL PIÑON - MAGDALENA</option>
              <option value="47258010">LOS PATOS - EL PIÑON - MAGDALENA</option>
              <option value="47258011">VASQUEZ - EL PIÑON - MAGDALENA</option>
              <option value="47258012">LAS PALMAS - EL PIÑON - MAGDALENA</option>
              <option value="47258013">LAS PAVITAS - EL PIÑON - MAGDALENA</option>
              <option value="47268000">EL RETEN - MAGDALENA</option>
              <option value="47268001">SAN SEBASTIAN DEL BONGO - EL RETEN - MAGDALENA</option>
              <option value="47268002">LA COLOMBIA - EL RETEN - MAGDALENA</option>
              <option value="47268003">LAS FLORES - EL RETEN - MAGDALENA</option>
              <option value="47268006">LA POLVORITA - EL RETEN - MAGDALENA</option>
              <option value="47268007">PARATE BIEN (EL PLEITO) - EL RETEN - MAGDALENA</option>
              <option value="47268008">SAN JOSE DE HONDURAS - EL RETEN - MAGDALENA</option>
              <option value="47268009">LAS CABAÑITAS - EL RETEN - MAGDALENA</option>
              <option value="47268010">SALITRE - EL RETEN - MAGDALENA</option>
              <option value="47288000">FUNDACION - MAGDALENA</option>
              <option value="47288003">DOÑA MARIA - FUNDACION - MAGDALENA</option>
              <option value="47288004">SANTA ROSA - FUNDACION - MAGDALENA</option>
              <option value="47288013">SANTA CLARA - FUNDACION - MAGDALENA</option>
              <option value="47288014">EL CINCUENTA - FUNDACION - MAGDALENA</option>
              <option value="47288015">EL CABRERO - FUNDACION - MAGDALENA</option>
              <option value="47288016">LA CRISTALINA - FUNDACION - MAGDALENA</option>
              <option value="47288017">SACRAMENTO - FUNDACION - MAGDALENA</option>
              <option value="47318000">GUAMAL - MAGDALENA</option>
              <option value="47318001">CASA DE TABLA - GUAMAL - MAGDALENA</option>
              <option value="47318002">GUAIMARAL - GUAMAL - MAGDALENA</option>
              <option value="47318003">HATO VIEJO - GUAMAL - MAGDALENA</option>
              <option value="47318004">PEDREGOSA - GUAMAL - MAGDALENA</option>
              <option value="47318005">LOS ANDES - GUAMAL - MAGDALENA</option>
              <option value="47318006">MURILLO - GUAMAL - MAGDALENA</option>
              <option value="47318008">RICAURTE - GUAMAL - MAGDALENA</option>
              <option value="47318009">SALVADORA - GUAMAL - MAGDALENA</option>
              <option value="47318010">HURQUIJO - GUAMAL - MAGDALENA</option>
              <option value="47318011">PLAYAS BLANCAS - GUAMAL - MAGDALENA</option>
              <option value="47318012">SITIO NUEVO - GUAMAL - MAGDALENA</option>
              <option value="47318013">CARRETERO - GUAMAL - MAGDALENA</option>
              <option value="47318014">BELLAVISTA - GUAMAL - MAGDALENA</option>
              <option value="47318016">SANTA TERESITA - GUAMAL - MAGDALENA</option>
              <option value="47318017">SAN PEDRO - GUAMAL - MAGDALENA</option>
              <option value="47318018">LAS FLORES - GUAMAL - MAGDALENA</option>
              <option value="47318019">SAN ANTONIO - GUAMAL - MAGDALENA</option>
              <option value="47318020">LA CEIBA - GUAMAL - MAGDALENA</option>
              <option value="47318023">SAN ISIDRO - GUAMAL - MAGDALENA</option>
              <option value="47318024">VILLA NUEVA - GUAMAL - MAGDALENA</option>
              <option value="47318025">EL VEINTIOCHO - GUAMAL - MAGDALENA</option>
              <option value="47460000">GRANADA - NUEVA GRANADA - MAGDALENA</option>
              <option value="47460001">EL BAJO - NUEVA GRANADA - MAGDALENA</option>
              <option value="47460002">LA GLORIA - NUEVA GRANADA - MAGDALENA</option>
              <option value="47460003">LAS TINAS - NUEVA GRANADA - MAGDALENA</option>
              <option value="47460004">LOS ANDES - NUEVA GRANADA - MAGDALENA</option>
              <option value="47460006">SAN JOSE DE BALLESTERO - NUEVA GRANADA - MAGDALENA</option>
              <option value="47460007">EL CORRAL - NUEVA GRANADA - MAGDALENA</option>
              <option value="47460008">EL PALMAR (EL CHUZO) - NUEVA GRANADA - MAGDALENA</option>
              <option value="47541000">PEDRAZA - MAGDALENA</option>
              <option value="47541001">BAHIA HONDA - PEDRAZA - MAGDALENA</option>
              <option value="47541003">BOMBA - PEDRAZA - MAGDALENA</option>
              <option value="47541007">GUAQUIRI - PEDRAZA - MAGDALENA</option>
              <option value="47541008">HEREDIA - PEDRAZA - MAGDALENA</option>
              <option value="47545000">PIJIÑO - PIJIÑO DEL CARMEN - MAGDALENA</option>
              <option value="47545001">CABRERA - PIJIÑO DEL CARMEN - MAGDALENA</option>
              <option value="47545002">FILADELFIA - PIJIÑO DEL CARMEN - MAGDALENA</option>
              <option value="47545003">SAN JOSE DE PREVENCION - PIJIÑO DEL CARMEN - MAGDALENA</option>
              <option value="47545004">CASA BLANCA - PIJIÑO DEL CARMEN - MAGDALENA</option>
              <option value="47545005">LA LUCHA - PIJIÑO DEL CARMEN - MAGDALENA</option>
              <option value="47545007">EL DIVIDIVI - PIJIÑO DEL CARMEN - MAGDALENA</option>
              <option value="47545008">EL BRILLANTE - PIJIÑO DEL CARMEN - MAGDALENA</option>
              <option value="47551000">PIVIJAY - MAGDALENA</option>
              <option value="47551001">LA AVIANCA - PIVIJAY - MAGDALENA</option>
              <option value="47551002">CARABALLO - PIVIJAY - MAGDALENA</option>
              <option value="47551003">CHINOBLAS - PIVIJAY - MAGDALENA</option>
              <option value="47551005">SAN JOSE DE LA MONTAÑA (GARRAPATA) - PIVIJAY - MAGDALENA</option>
              <option value="47551006">LAS CANOAS - PIVIJAY - MAGDALENA</option>
              <option value="47551007">LAS PIEDRAS - PIVIJAY - MAGDALENA</option>
              <option value="47551008">MEDIALUNA - PIVIJAY - MAGDALENA</option>
              <option value="47551010">CARMEN DEL MAGDALENA (PARACO) - PIVIJAY - MAGDALENA</option>
              <option value="47551011">PARAISO - PIVIJAY - MAGDALENA</option>
              <option value="47551012">PIÑUELAS - PIVIJAY - MAGDALENA</option>
              <option value="47551013">PLACITAS - PIVIJAY - MAGDALENA</option>
              <option value="47551017">LA RETIRADA - PIVIJAY - MAGDALENA</option>
              <option value="47555000">PLATO - MAGDALENA</option>
              <option value="47555001">APURE - PLATO - MAGDALENA</option>
              <option value="47555002">CARMEN DEL MAGDALENA - PLATO - MAGDALENA</option>
              <option value="47555005">ZARATE - PLATO - MAGDALENA</option>
              <option value="47555006">AGUAS VIVAS - PLATO - MAGDALENA</option>
              <option value="47555007">CIENEGUETA - PLATO - MAGDALENA</option>
              <option value="47555008">CERRO GRANDE - PLATO - MAGDALENA</option>
              <option value="47555011">SAN JOSE DEL PURGATORIO - PLATO - MAGDALENA</option>
              <option value="47555015">DISCIPLINA - PLATO - MAGDALENA</option>
              <option value="47555017">SAN ANTONIO DEL RIO - PLATO - MAGDALENA</option>
              <option value="47555018">BUENA VISTA - PLATO - MAGDALENA</option>
              <option value="47555020">LOS POZOS - PLATO - MAGDALENA</option>
              <option value="47555021">CINCO Y SEIS - PLATO - MAGDALENA</option>
              <option value="47570000">PUEBLOVIEJO - MAGDALENA</option>
              <option value="47570002">ISLA DEL ROSARIO - PUEBLOVIEJO - MAGDALENA</option>
              <option value="47570003">PALMIRA - PUEBLOVIEJO - MAGDALENA</option>
              <option value="47570004">TASAJERA - PUEBLOVIEJO - MAGDALENA</option>
              <option value="47570005">TIERRA NUEVA - PUEBLOVIEJO - MAGDALENA</option>
              <option value="47570007">EL TRIUNFO - PUEBLOVIEJO - MAGDALENA</option>
              <option value="47570009">NUEVA FRONTERA - PUEBLOVIEJO - MAGDALENA</option>
              <option value="47570010">SAN JUAN DE PALOS PRIETOS (LA MONTAÑA) - PUEBLOVIEJO - MAGDALENA</option>
              <option value="47605000">REMOLINO - MAGDALENA</option>
              <option value="47605002">CORRAL VIEJO - REMOLINO - MAGDALENA</option>
              <option value="47605003">EL DIVIDIVI - REMOLINO - MAGDALENA</option>
              <option value="47605004">SAN RAFAEL DE BUENAVISTA - REMOLINO - MAGDALENA</option>
              <option value="47605005">SANTA RITA - REMOLINO - MAGDALENA</option>
              <option value="47605006">EL SALAO - REMOLINO - MAGDALENA</option>
              <option value="47605007">MARTINETE - REMOLINO - MAGDALENA</option>
              <option value="47605008">LAS CASITAS - REMOLINO - MAGDALENA</option>
              <option value="47660000">SAN ANGEL - SABANAS DE SAN ANGEL - MAGDALENA</option>
              <option value="47660002">CASA DE TABLA - SABANAS DE SAN ANGEL - MAGDALENA</option>
              <option value="47660003">CESPEDES - SABANAS DE SAN ANGEL - MAGDALENA</option>
              <option value="47660005">FLORES DE MARIA - SABANAS DE SAN ANGEL - MAGDALENA</option>
              <option value="47660007">LA HORQUETA - SABANAS DE SAN ANGEL - MAGDALENA</option>
              <option value="47660009">SAN ROQUE - SABANAS DE SAN ANGEL - MAGDALENA</option>
              <option value="47660010">EL MANANTIAL - SABANAS DE SAN ANGEL - MAGDALENA</option>
              <option value="47660011">PUEBLITO DE LOS BARRIOS - SABANAS DE SAN ANGEL - MAGDALENA</option>
              <option value="47660013">ESTACION VILLA - SABANAS DE SAN ANGEL - MAGDALENA</option>
              <option value="47660014">MONTERRUBIO - SABANAS DE SAN ANGEL - MAGDALENA</option>
              <option value="47675000">SALAMINA - MAGDALENA</option>
              <option value="47675001">GUAIMARO - SALAMINA - MAGDALENA</option>
              <option value="47675005">EL SALAO - SALAMINA - MAGDALENA</option>
              <option value="47675006">LA LOMA - SALAMINA - MAGDALENA</option>
              <option value="47675007">LA LOMITA - SALAMINA - MAGDALENA</option>
              <option value="47692000">SAN SEBASTIAN DE BUENAVISTA - MAGDALENA</option>
              <option value="47692001">BUENAVISTA - SAN SEBASTIAN DE BUENAVISTA - MAGDALENA</option>
              <option value="47692002">EL COCO - SAN SEBASTIAN DE BUENAVISTA - MAGDALENA</option>
              <option value="47692003">LA PACHA - SAN SEBASTIAN DE BUENAVISTA - MAGDALENA</option>
              <option value="47692004">LAS MARGARITAS - SAN SEBASTIAN DE BUENAVISTA - MAGDALENA</option>
              <option value="47692005">LOS GALVIS - SAN SEBASTIAN DE BUENAVISTA - MAGDALENA</option>
              <option value="47692006">MARIA ANTONIA - SAN SEBASTIAN DE BUENAVISTA - MAGDALENA</option>
              <option value="47692007">SAN RAFAEL - SAN SEBASTIAN DE BUENAVISTA - MAGDALENA</option>
              <option value="47692008">SANTA ROSA - SAN SEBASTIAN DE BUENAVISTA - MAGDALENA</option>
              <option value="47692009">TRONCOSITO - SAN SEBASTIAN DE BUENAVISTA - MAGDALENA</option>
              <option value="47692010">TRONCOSO - SAN SEBASTIAN DE BUENAVISTA - MAGDALENA</option>
              <option value="47692011">VENERO - SAN SEBASTIAN DE BUENAVISTA - MAGDALENA</option>
              <option value="47692013">EL SEIS - SAN SEBASTIAN DE BUENAVISTA - MAGDALENA</option>
              <option value="47692018">SAN VALENTIN - SAN SEBASTIAN DE BUENAVISTA - MAGDALENA</option>
              <option value="47692019">PUEBLO NUEVO - SAN SEBASTIAN DE BUENAVISTA - MAGDALENA</option>
              <option value="47703000">SAN ZENON - MAGDALENA</option>
              <option value="47703001">ANGOSTURA - SAN ZENON - MAGDALENA</option>
              <option value="47703002">BERMEJAL - SAN ZENON - MAGDALENA</option>
              <option value="47703003">EL PALOMAR - SAN ZENON - MAGDALENA</option>
              <option value="47703004">JANEIRO - SAN ZENON - MAGDALENA</option>
              <option value="47703005">LA MONTAÑA - SAN ZENON - MAGDALENA</option>
              <option value="47703006">PEÑONCITO - SAN ZENON - MAGDALENA</option>
              <option value="47703007">SANTA TERESA - SAN ZENON - MAGDALENA</option>
              <option value="47703008">GUINEA - SAN ZENON - MAGDALENA</option>
              <option value="47703009">EL HORNO - SAN ZENON - MAGDALENA</option>
              <option value="47703010">PUERTO ARTURO - SAN ZENON - MAGDALENA</option>
              <option value="47707000">SANTA ANA - MAGDALENA</option>
              <option value="47707001">BARRO BLANCO - SANTA ANA - MAGDALENA</option>
              <option value="47707006">SAN FERNANDO - SANTA ANA - MAGDALENA</option>
              <option value="47707009">JARABA - SANTA ANA - MAGDALENA</option>
              <option value="47707011">SANTA ROSA - SANTA ANA - MAGDALENA</option>
              <option value="47720000">SANTA BARBARA DE PINTO - MAGDALENA</option>
              <option value="47720001">CUNDINAMARCA - SANTA BARBARA DE PINTO - MAGDALENA</option>
              <option value="47720002">SAN PEDRO - SANTA BARBARA DE PINTO - MAGDALENA</option>
              <option value="47720003">VELADERO - SANTA BARBARA DE PINTO - MAGDALENA</option>
              <option value="47720004">CARRETAL - SANTA BARBARA DE PINTO - MAGDALENA</option>
              <option value="47720005">CIENAGUETA - SANTA BARBARA DE PINTO - MAGDALENA</option>
              <option value="47745000">SITIONUEVO - MAGDALENA</option>
              <option value="47745001">BUENAVISTA - SITIONUEVO - MAGDALENA</option>
              <option value="47745002">NUEVA VENECIA - SITIONUEVO - MAGDALENA</option>
              <option value="47745003">PALERMO - SITIONUEVO - MAGDALENA</option>
              <option value="47745006">SAN ANTONIO - SITIONUEVO - MAGDALENA</option>
              <option value="47798000">TENERIFE - MAGDALENA</option>
              <option value="47798004">REAL DEL OBISPO - TENERIFE - MAGDALENA</option>
              <option value="47798005">SAN LUIS - TENERIFE - MAGDALENA</option>
              <option value="47798007">EL JUNCAL - TENERIFE - MAGDALENA</option>
              <option value="47798008">SANTA INES - TENERIFE - MAGDALENA</option>
              <option value="47960000">PUNTA DE PIEDRAS - ZAPAYAN - MAGDALENA</option>
              <option value="47960001">CAÑO DE AGUAS - ZAPAYAN - MAGDALENA</option>
              <option value="47960002">CAPUCHO - ZAPAYAN - MAGDALENA</option>
              <option value="47960003">PIEDRAS DE MOLER - ZAPAYAN - MAGDALENA</option>
              <option value="47960004">PIEDRAS PINTADAS - ZAPAYAN - MAGDALENA</option>
              <option value="47960005">LOS CERRITOS - ZAPAYAN - MAGDALENA</option>
              <option value="47960006">EL BONGO - ZAPAYAN - MAGDALENA</option>
              <option value="47980000">PRADO - SEVILLA - ZONA BANANERA - MAGDALENA</option>
              <option value="47980001">GUACAMAYAL - ZONA BANANERA - MAGDALENA</option>
              <option value="47980002">GUAMACHITO - ZONA BANANERA - MAGDALENA</option>
              <option value="47980003">LA GRAN VIA - ZONA BANANERA - MAGDALENA</option>
              <option value="47980004">ORIHUECA - ZONA BANANERA - MAGDALENA</option>
              <option value="47980005">PALOMAR - ZONA BANANERA - MAGDALENA</option>
              <option value="47980006">RIO FRIO - ZONA BANANERA - MAGDALENA</option>
              <option value="47980007">SANTA ROSALIA - ZONA BANANERA - MAGDALENA</option>
              <option value="47980009">SOPLADOR - ZONA BANANERA - MAGDALENA</option>
              <option value="47980010">TUCURINCA - ZONA BANANERA - MAGDALENA</option>
              <option value="47980011">VARELA - ZONA BANANERA - MAGDALENA</option>
              <option value="47980012">ZAWADY - ZONA BANANERA - MAGDALENA</option>
              <option value="47980013">ESTACION SEVILLA - ZONA BANANERA - MAGDALENA</option>
              <option value="47980014">LA CANDELARIA - ZONA BANANERA - MAGDALENA</option>
              <option value="47980015">SAN JOSE DE KENNEDY - ZONA BANANERA - MAGDALENA</option>
              <option value="47980016">CAÑO MOCHO - ZONA BANANERA - MAGDALENA</option>
              <option value="47980017">EL MAMON - ZONA BANANERA - MAGDALENA</option>
              <option value="47980018">AGUSTINA - ZONA BANANERA - MAGDALENA</option>
              <option value="47980019">CARITAL - ZONA BANANERA - MAGDALENA</option>
              <option value="47980020">CASABLANCA - ZONA BANANERA - MAGDALENA</option>
              <option value="47980021">CIUDAD PERDIDA - ZONA BANANERA - MAGDALENA</option>
              <option value="47980022">EL REPOSO - ZONA BANANERA - MAGDALENA</option>
              <option value="47980023">IBERIA - ZONA BANANERA - MAGDALENA</option>
              <option value="47980024">MONTERIA - ZONA BANANERA - MAGDALENA</option>
              <option value="47980025">PATUCA - ZONA BANANERA - MAGDALENA</option>
              <option value="47980026">PAULINA - ZONA BANANERA - MAGDALENA</option>
              <option value="47980027">PILOTO - ZONA BANANERA - MAGDALENA</option>
              <option value="47980028">SALON CONCEPCION - ZONA BANANERA - MAGDALENA</option>
              <option value="50001000">VILLAVICENCIO - META</option>
              <option value="50001001">CONCEPCION - VILLAVICENCIO - META</option>
              <option value="50001002">RINCON DE POMPEYA - VILLAVICENCIO - META</option>
              <option value="50001003">SANTA ROSA DE RIO NEGRO - VILLAVICENCIO - META</option>
              <option value="50001004">BUENAVISTA - VILLAVICENCIO - META</option>
              <option value="50001005">COCUY - VILLAVICENCIO - META</option>
              <option value="50001007">SERVITA - VILLAVICENCIO - META</option>
              <option value="50001013">PIPIRAL - VILLAVICENCIO - META</option>
              <option value="50001014">SAN LUIS DE OCOA - VILLAVICENCIO - META</option>
              <option value="50001015">ALTO POMPEYA - VILLAVICENCIO - META</option>
              <option value="50001016">CECILIA - VILLAVICENCIO - META</option>
              <option value="50001017">LA NOHORA - VILLAVICENCIO - META</option>
              <option value="50001019">APIAY - VILLAVICENCIO - META</option>
              <option value="50001020">BARCELONA - VILLAVICENCIO - META</option>
              <option value="50001021">ARGENTINA - VILLAVICENCIO - META</option>
              <option value="50001023">BELLA SUIZA - VILLAVICENCIO - META</option>
              <option value="50001024">CONDOMINIO DE LOS ODONTOLOGOS - VILLAVICENCIO - META</option>
              <option value="50001026">LLANERITA - VILLAVICENCIO - META</option>
              <option value="50001027">NATURALIA - VILLAVICENCIO - META</option>
              <option value="50006000">ACACIAS - META</option>
              <option value="50006001">DINAMARCA - ACACIAS - META</option>
              <option value="50006003">SAN ISIDRO DE CHICHIMENE - ACACIAS - META</option>
              <option value="50006006">CONDOMINIO LA BONANZA - ACACIAS - META</option>
              <option value="50006007">LA CECILITA - ACACIAS - META</option>
              <option value="50006008">QUEBRADITAS - ACACIAS - META</option>
              <option value="50006009">SANTA ROSA - ACACIAS - META</option>
              <option value="50006010">EL DIAMANTE - ACACIAS - META</option>
              <option value="50110000">BARRANCA DE UPIA - META</option>
              <option value="50110001">SAN IGNACIO - BARRANCA DE UPIA - META</option>
              <option value="50124000">CABUYARO - META</option>
              <option value="50124002">GUAYABAL DE UPIA - CABUYARO - META</option>
              <option value="50124003">VISO DE UPIA - CABUYARO - META</option>
              <option value="50124004">LOS MANGOS - CABUYARO - META</option>
              <option value="50150000">CASTILLA LA NUEVA - META</option>
              <option value="50150001">SAN LORENZO - CASTILLA LA NUEVA - META</option>
              <option value="50150002">PUEBLO VIEJO - CASTILLA LA NUEVA - META</option>
              <option value="50150003">EL TORO - CASTILLA LA NUEVA - META</option>
              <option value="50150004">LAS VIOLETAS - CASTILLA LA NUEVA - META</option>
              <option value="50150005">CASA BLANCA - CASTILLA LA NUEVA - META</option>
              <option value="50223000">CUBARRAL - META</option>
              <option value="50223003">PUERTO ARIARI - CUBARRAL - META</option>
              <option value="50226000">CUMARAL - META</option>
              <option value="50226002">GUACAVIA - CUMARAL - META</option>
              <option value="50226004">SAN NICOLAS - CUMARAL - META</option>
              <option value="50226005">VERACRUZ - CUMARAL - META</option>
              <option value="50226010">PRESENTADO - CUMARAL - META</option>
              <option value="50245000">EL CALVARIO - META</option>
              <option value="50245001">MONTFORT - EL CALVARIO - META</option>
              <option value="50245002">SAN FRANCISCO - EL CALVARIO - META</option>
              <option value="50251000">EL CASTILLO - META</option>
              <option value="50251001">MEDELLIN DEL ARIARI - EL CASTILLO - META</option>
              <option value="50251003">MIRAVALLES - EL CASTILLO - META</option>
              <option value="50251004">PUERTO ESPERANZA - EL CASTILLO - META</option>
              <option value="50270000">EL DORADO - META</option>
              <option value="50270001">PUEBLO SANCHEZ - EL DORADO - META</option>
              <option value="50270002">SAN ISIDRO - EL DORADO - META</option>
              <option value="50287000">FUENTE DE ORO - META</option>
              <option value="50287001">PUERTO ALJURE - FUENTE DE ORO - META</option>
              <option value="50287002">PUERTO LIMON - FUENTE DE ORO - META</option>
              <option value="50287003">PUERTO SANTANDER - FUENTE DE ORO - META</option>
              <option value="50287004">UNION DEL ARIARI - FUENTE DE ORO - META</option>
              <option value="50287005">LA COOPERATIVA - FUENTE DE ORO - META</option>
              <option value="50287006">CAÑO BLANCO - FUENTE DE ORO - META</option>
              <option value="50287007">PUERTO NUEVO - FUENTE DE ORO - META</option>
              <option value="50287008">BARRANCO COLORADO CAÑO VENADO - FUENTE DE ORO - META</option>
              <option value="50313000">GRANADA - META</option>
              <option value="50313001">CANAGUARO - GRANADA - META</option>
              <option value="50313002">DOS QUEBRADAS - GRANADA - META</option>
              <option value="50313004">LA PLAYA - GRANADA - META</option>
              <option value="50313005">PUERTO CALDAS - GRANADA - META</option>
              <option value="50313006">AGUAS CLARAS - GRANADA - META</option>
              <option value="50313007">PUNTA BRAVA - GRANADA - META</option>
              <option value="50318000">GUAMAL - META</option>
              <option value="50318001">HUMADEA - GUAMAL - META</option>
              <option value="50325000">MAPIRIPAN - META</option>
              <option value="50325001">PUERTO ALVIRA - MAPIRIPAN - META</option>
              <option value="50325002">MIELON - MAPIRIPAN - META</option>
              <option value="50325004">ANZUELO - MAPIRIPAN - META</option>
              <option value="50325005">GUACAMAYAS - MAPIRIPAN - META</option>
              <option value="50325006">LA COOPERATIVA - MAPIRIPAN - META</option>
              <option value="50325007">PUERTO SIARE - MAPIRIPAN - META</option>
              <option value="50325009">EL SILENCIO - MAPIRIPAN - META</option>
              <option value="50325010">LA JUNGLA - MAPIRIPAN - META</option>
              <option value="50325011">RINCON DEL INDIO - MAPIRIPAN - META</option>
              <option value="50330000">MESETAS - META</option>
              <option value="50330002">JARDIN DE LAS PEÑAS - MESETAS - META</option>
              <option value="50330005">ORIENTE - MESETAS - META</option>
              <option value="50330007">LA ARGENTINA - MESETAS - META</option>
              <option value="50330009">PUERTO NARIÑO - MESETAS - META</option>
              <option value="50330010">SAN ISIDRO - MESETAS - META</option>
              <option value="50350000">LA MACARENA - META</option>
              <option value="50350001">SAN FRANCISCO DE LA SOMBRA - LA MACARENA - META</option>
              <option value="50350003">SAN JUAN DEL LOSADA - LA MACARENA - META</option>
              <option value="50350007">LA CRISTALINA - LA MACARENA - META</option>
              <option value="50350008">EL RUBI - LA MACARENA - META</option>
              <option value="50350010">EL VERGEL - LA MACARENA - META</option>
              <option value="50350012">LAS DELICIAS - LA MACARENA - META</option>
              <option value="50350013">LAURELES - LA MACARENA - META</option>
              <option value="50350014">PLAYA RICA - LA MACARENA - META</option>
              <option value="50350015">PUERTO LOZADA - LA MACARENA - META</option>
              <option value="50370000">URIBE - META</option>
              <option value="50370001">LA JULIA - URIBE - META</option>
              <option value="50370002">EL DIVISO - URIBE - META</option>
              <option value="50400000">LEJANIAS - META</option>
              <option value="50400001">CACAYAL - LEJANIAS - META</option>
              <option value="50400002">ANGOSTURAS DEL GUAPE - LEJANIAS - META</option>
              <option value="50450000">PUERTO CONCORDIA - META</option>
              <option value="50450001">EL PORORIO - PUERTO CONCORDIA - META</option>
              <option value="50450002">LINDENAI - PUERTO CONCORDIA - META</option>
              <option value="50450003">SAN FERNANDO - PUERTO CONCORDIA - META</option>
              <option value="50568000">PUERTO GAITAN - META</option>
              <option value="50568001">DOMO PLANAS - PUERTO GAITAN - META</option>
              <option value="50568002">SAN PEDRO DE ARIMENA - PUERTO GAITAN - META</option>
              <option value="50568004">SAN MIGUEL - PUERTO GAITAN - META</option>
              <option value="50568005">EL PORVENIR - PUERTO GAITAN - META</option>
              <option value="50568006">PUERTO TRUJILLO - PUERTO GAITAN - META</option>
              <option value="50568007">PUENTE ARIMENA - PUERTO GAITAN - META</option>
              <option value="50568008">ALTO TILLAVA - PUERTO GAITAN - META</option>
              <option value="50568010">LA CRISTALINA - PUERTO GAITAN - META</option>
              <option value="50568012">MURUJUY - PUERTO GAITAN - META</option>
              <option value="50573000">PUERTO LOPEZ - META</option>
              <option value="50573001">LA BALSA - PUERTO LOPEZ - META</option>
              <option value="50573003">PACHAQUIARO - PUERTO LOPEZ - META</option>
              <option value="50573004">ALTAMIRA - PUERTO LOPEZ - META</option>
              <option value="50573006">PUERTO GUADALUPE - PUERTO LOPEZ - META</option>
              <option value="50573007">PUERTO PORFIA - PUERTO LOPEZ - META</option>
              <option value="50573008">REMOLINO - PUERTO LOPEZ - META</option>
              <option value="50573010">BOCAS DEL GUAYURIBA - PUERTO LOPEZ - META</option>
              <option value="50573011">GUICHIRAL - PUERTO LOPEZ - META</option>
              <option value="50573012">CHAVIVA - PUERTO LOPEZ - META</option>
              <option value="50573013">EL TIGRE - PUERTO LOPEZ - META</option>
              <option value="50573015">PUEBLO NUEVO - GETSEMANI - PUERTO LOPEZ - META</option>
              <option value="50577000">PUERTO LLERAS - META</option>
              <option value="50577003">CASIBARE - PUERTO LLERAS - META</option>
              <option value="50577004">CAÑO RAYADO - PUERTO LLERAS - META</option>
              <option value="50577005">VILLA LA PAZ - PUERTO LLERAS - META</option>
              <option value="50577006">TIERRA GRATA - PUERTO LLERAS - META</option>
              <option value="50577007">LA UNION - PUERTO LLERAS - META</option>
              <option value="50577008">VILLA PALMERAS - PUERTO LLERAS - META</option>
              <option value="50590000">PUERTO RICO - META</option>
              <option value="50590003">LA LINDOSA - PUERTO RICO - META</option>
              <option value="50590004">BARRANCO COLORADO - PUERTO RICO - META</option>
              <option value="50590005">PUERTO TOLEDO - PUERTO RICO - META</option>
              <option value="50590006">CHARCO DANTO - PUERTO RICO - META</option>
              <option value="50590007">LA TIGRA - PUERTO RICO - META</option>
              <option value="50590008">PUERTO CHISPAS - PUERTO RICO - META</option>
              <option value="50606000">RESTREPO - META</option>
              <option value="50680000">SAN CARLOS DE GUAROA - META</option>
              <option value="50680001">PAJURE - SAN CARLOS DE GUAROA - META</option>
              <option value="50680002">SURIMENA - SAN CARLOS DE GUAROA - META</option>
              <option value="50680003">LA PALMERA - SAN CARLOS DE GUAROA - META</option>
              <option value="50683000">SAN JUAN DE ARAMA - META</option>
              <option value="50683001">EL VERGEL - SAN JUAN DE ARAMA - META</option>
              <option value="50683005">MESA FERNANDEZ - SAN JUAN DE ARAMA - META</option>
              <option value="50683010">CAMPO ALEGRE - SAN JUAN DE ARAMA - META</option>
              <option value="50683011">CERRITO - SAN JUAN DE ARAMA - META</option>
              <option value="50683012">MIRAFLOREZ - SAN JUAN DE ARAMA - META</option>
              <option value="50683013">PEÑAS BLANCAS - SAN JUAN DE ARAMA - META</option>
              <option value="50683015">BELLA VISTA - SAN JUAN DE ARAMA - META</option>
              <option value="50686000">SAN JUANITO - META</option>
              <option value="50686003">SAN JOSE - SAN JUANITO - META</option>
              <option value="50689000">SAN MARTIN - META</option>
              <option value="50689001">EL MEREY - SAN MARTIN - META</option>
              <option value="50689006">EL PARAISO MEJOR VIVIR - SAN MARTIN - META</option>
              <option value="50711000">VISTAHERMOSA - META</option>
              <option value="50711002">PIÑALITO - VISTAHERMOSA - META</option>
              <option value="50711003">MARACAIBO - VISTAHERMOSA - META</option>
              <option value="50711004">CAÑO AMARILLO - VISTAHERMOSA - META</option>
              <option value="50711005">PUERTO LUCAS MARGEN IZQUIERDO - VISTAHERMOSA - META</option>
              <option value="50711006">PUERTO LUCAS MARGEN DERECHO - VISTAHERMOSA - META</option>
              <option value="50711008">PUERTO ESPERANZA MARGEN IZQUIERDO - VISTAHERMOSA - META</option>
              <option value="50711012">COSTA RICA - VISTAHERMOSA - META</option>
              <option value="50711014">PALESTINA - VISTAHERMOSA - META</option>
              <option value="50711016">SANTO DOMINGO - VISTAHERMOSA - META</option>
              <option value="50711017">TRES ESQUINAS - VISTAHERMOSA - META</option>
              <option value="50711018">ALBANIA - VISTAHERMOSA - META</option>
              <option value="50711021">LA REFORMA - VISTAHERMOSA - META</option>
              <option value="50711022">PALMERAS - VISTAHERMOSA - META</option>
              <option value="52001000">SAN JUAN DE PASTO - PASTO - NARIÑO</option>
              <option value="52001001">CATAMBUCO - PASTO - NARIÑO</option>
              <option value="52001003">EL ENCANO - PASTO - NARIÑO</option>
              <option value="52001004">GENOY - PASTO - NARIÑO</option>
              <option value="52001005">LA LAGUNA - PASTO - NARIÑO</option>
              <option value="52001007">OBONUCO - PASTO - NARIÑO</option>
              <option value="52001008">SANTA BARBARA - PASTO - NARIÑO</option>
              <option value="52001009">JONGOVITO - PASTO - NARIÑO</option>
              <option value="52001010">GUALMATAN - PASTO - NARIÑO</option>
              <option value="52001012">MAPACHICO - ATICANCE - PASTO - NARIÑO</option>
              <option value="52001013">EL SOCORRO CIMARRON - PASTO - NARIÑO</option>
              <option value="52001016">MOTILON - PASTO - NARIÑO</option>
              <option value="52001019">CEROTAL - PASTO - NARIÑO</option>
              <option value="52001021">LA VICTORIA - PASTO - NARIÑO</option>
              <option value="52001024">SAN JOSE - PASTO - NARIÑO</option>
              <option value="52001025">EL PUERTO - PASTO - NARIÑO</option>
              <option value="52001027">CABRERA - PASTO - NARIÑO</option>
              <option value="52001029">DOLORES - PASTO - NARIÑO</option>
              <option value="52001030">BUESAQUILLO - PASTO - NARIÑO</option>
              <option value="52001033">CUJACAL - PASTO - NARIÑO</option>
              <option value="52001036">TESCUAL - PASTO - NARIÑO</option>
              <option value="52001039">ANGANOY - PASTO - NARIÑO</option>
              <option value="52001042">DAZA - PASTO - NARIÑO</option>
              <option value="52001051">CUBIJAN BAJO - PASTO - NARIÑO</option>
              <option value="52001052">SAN FERNANDO - PASTO - NARIÑO</option>
              <option value="52001053">MOCONDINO - PASTO - NARIÑO</option>
              <option value="52001055">CANCHALA - PASTO - NARIÑO</option>
              <option value="52001056">LOS ANGELES - PASTO - NARIÑO</option>
              <option value="52001058">EL ROSARIO - PASTO - NARIÑO</option>
              <option value="52001059">JAMONDINO - PASTO - NARIÑO</option>
              <option value="52001063">BOTANILLA - PASTO - NARIÑO</option>
              <option value="52001064">CHARGUAYACO - PASTO - NARIÑO</option>
              <option value="52001065">CRUZ DE AMARILLO - PASTO - NARIÑO</option>
              <option value="52001066">EL CAMPANERO - PASTO - NARIÑO</option>
              <option value="52001068">JURADO - PASTO - NARIÑO</option>
              <option value="52001071">LA MERCED - PASTO - NARIÑO</option>
              <option value="52001072">LAS ENCINAS - PASTO - NARIÑO</option>
              <option value="52001073">MAPACHICO ALTO - PASTO - NARIÑO</option>
              <option value="52001074">MAPACHICO SAN JOSE - PASTO - NARIÑO</option>
              <option value="52001076">SAN FRANCISCO - PASTO - NARIÑO</option>
              <option value="52001078">SAN JUAN DE ANGANOY - PASTO - NARIÑO</option>
              <option value="52001079">SANTA LUCIA - PASTO - NARIÑO</option>
              <option value="52001080">VILLA MARIA - PASTO - NARIÑO</option>
              <option value="52001086">GUALMATAN ALTO - PASTO - NARIÑO</option>
              <option value="52001087">LA CALDERA - PASTO - NARIÑO</option>
              <option value="52001089">PUERRES - PASTO - NARIÑO</option>
              <option value="52019000">SAN JOSE - ALBAN - NARIÑO</option>
              <option value="52019004">CAMPOBELLO - ALBAN - NARIÑO</option>
              <option value="52019009">CARMELO ASENTAMIENTO 1 - ALBAN - NARIÑO</option>
              <option value="52019013">FATIMA - ALBAN - NARIÑO</option>
              <option value="52022000">ALDANA - NARIÑO</option>
              <option value="52022001">PAMBA ROSA - ALDANA - NARIÑO</option>
              <option value="52022003">SAN LUIS - ALDANA - NARIÑO</option>
              <option value="52036000">ANCUYA - NARIÑO</option>
              <option value="52036017">INDO SANTA ROSA - ANCUYA - NARIÑO</option>
              <option value="52051000">BERRUECOS - ARBOLEDA - NARIÑO</option>
              <option value="52051005">EL EMPATE - ARBOLEDA - NARIÑO</option>
              <option value="52051007">ROSA FLORIDA SUR - SECTOR LA CAPILLA - ARBOLEDA - NARIÑO</option>
              <option value="52051012">ROSAFLORIDA NORTE - ARBOLEDA - NARIÑO</option>
              <option value="52051015">EL PEDREGAL - ARBOLEDA - NARIÑO</option>
              <option value="52079000">BARBACOAS - NARIÑO</option>
              <option value="52079001">ALTAQUER - BARBACOAS - NARIÑO</option>
              <option value="52079003">CHALCHAL - BARBACOAS - NARIÑO</option>
              <option value="52079005">DIAGUILLO - BARBACOAS - NARIÑO</option>
              <option value="52079006">JUNIN - BARBACOAS - NARIÑO</option>
              <option value="52079009">LOS BRAZOS - BARBACOAS - NARIÑO</option>
              <option value="52079011">MONGON - BARBACOAS - NARIÑO</option>
              <option value="52079013">PAMBANA - BARBACOAS - NARIÑO</option>
              <option value="52079014">SUCRE GUINULTE - BARBACOAS - NARIÑO</option>
              <option value="52079018">SAN MIGUEL NAMBI - BARBACOAS - NARIÑO</option>
              <option value="52079020">TERAIMBE - BARBACOAS - NARIÑO</option>
              <option value="52079022">SAN JUAN PALACIO - BARBACOAS - NARIÑO</option>
              <option value="52079023">ÑAMBI - BARBACOAS - NARIÑO</option>
              <option value="52079024">CARGAZON - BARBACOAS - NARIÑO</option>
              <option value="52079025">CASCAJERO - BARBACOAS - NARIÑO</option>
              <option value="52079026">EL DIVISO - BARBACOAS - NARIÑO</option>
              <option value="52079027">LA PLAYA - BARBACOAS - NARIÑO</option>
              <option value="52079028">PAUNDE - BARBACOAS - NARIÑO</option>
              <option value="52079029">SALI - BARBACOAS - NARIÑO</option>
              <option value="52079030">YALARE - BARBACOAS - NARIÑO</option>
              <option value="52079035">PALO SECO - BARBACOAS - NARIÑO</option>
              <option value="52083000">BELEN - NARIÑO</option>
              <option value="52083001">SANTA ROSA - BELEN - NARIÑO</option>
              <option value="52110000">BUESACO - NARIÑO</option>
              <option value="52110001">PALASINOY - BUESACO - NARIÑO</option>
              <option value="52110002">ROSAL DEL MONTE - BUESACO - NARIÑO</option>
              <option value="52110003">SAN ANTONIO - BUESACO - NARIÑO</option>
              <option value="52110004">SAN IGNACIO - BUESACO - NARIÑO</option>
              <option value="52110005">SANTAFE - BUESACO - NARIÑO</option>
              <option value="52110006">SANTAMARIA - BUESACO - NARIÑO</option>
              <option value="52110007">VILLAMORENO - BUESACO - NARIÑO</option>
              <option value="52110008">VERACRUZ - BUESACO - NARIÑO</option>
              <option value="52110009">ALTACLARA - BUESACO - NARIÑO</option>
              <option value="52110022">JUANAMBU - BUESACO - NARIÑO</option>
              <option value="52110030">SAN MIGUEL SANTAFE - BUESACO - NARIÑO</option>
              <option value="52203000">GENOVA - COLON - NARIÑO</option>
              <option value="52203001">GUAITARILLA - COLON - NARIÑO</option>
              <option value="52203002">LA PLATA - COLON - NARIÑO</option>
              <option value="52203004">VILLANUEVA - COLON - NARIÑO</option>
              <option value="52207000">CONSACA - NARIÑO</option>
              <option value="52207004">BOMBONA - CONSACA - NARIÑO</option>
              <option value="52207013">EL HATILLO - CONSACA - NARIÑO</option>
              <option value="52207023">RUMIPAMBA - CONSACA - NARIÑO</option>
              <option value="52210000">CONTADERO - NARIÑO</option>
              <option value="52210001">ALDEA DE MARIA - CONTADERO - NARIÑO</option>
              <option value="52210002">LA JOSEFINA - CONTADERO - NARIÑO</option>
              <option value="52215000">CORDOBA - NARIÑO</option>
              <option value="52215001">LOS ARRAYANES - CORDOBA - NARIÑO</option>
              <option value="52215004">SANTANDER - CORDOBA - NARIÑO</option>
              <option value="52215005">PUEBLO BAJO - CORDOBA - NARIÑO</option>
              <option value="52224000">CARLOSAMA - CUASPUD CARLOSAMA - NARIÑO</option>
              <option value="52224001">MACAS - CUASPUD CARLOSAMA - NARIÑO</option>
              <option value="52227000">CUMBAL - NARIÑO</option>
              <option value="52227001">CHILES - CUMBAL - NARIÑO</option>
              <option value="52227004">PANAN - CUMBAL - NARIÑO</option>
              <option value="52227006">NAZATE - CUMBAL - NARIÑO</option>
              <option value="52227009">EL CHOTA - CUMBAL - NARIÑO</option>
              <option value="52227010">LA POMA - CUMBAL - NARIÑO</option>
              <option value="52233000">CUMBITARA - NARIÑO</option>
              <option value="52233001">DAMASCO - CUMBITARA - NARIÑO</option>
              <option value="52233002">EL DESIERTO - CUMBITARA - NARIÑO</option>
              <option value="52233003">PISANDA - CUMBITARA - NARIÑO</option>
              <option value="52233004">SIDON - CUMBITARA - NARIÑO</option>
              <option value="52233007">LA ESPERANZA - CUMBITARA - NARIÑO</option>
              <option value="52233008">SANTA ROSA - CUMBITARA - NARIÑO</option>
              <option value="52233011">SANTA ANA - CUMBITARA - NARIÑO</option>
              <option value="52233012">CAMPO BELLO - CUMBITARA - NARIÑO</option>
              <option value="52240000">CHACHAGÜI - NARIÑO</option>
              <option value="52240001">ARIZONA - CHACHAGÜI - NARIÑO</option>
              <option value="52240002">AGRARIO - CHACHAGÜI - NARIÑO</option>
              <option value="52240003">CAÑO ALTO - CHACHAGÜI - NARIÑO</option>
              <option value="52240004">CAÑO BAJO - CHACHAGÜI - NARIÑO</option>
              <option value="52240005">CHORRILLO - CHACHAGÜI - NARIÑO</option>
              <option value="52240006">GUAIRABAMBA - CHACHAGÜI - NARIÑO</option>
              <option value="52240008">LA LOMA - CHACHAGÜI - NARIÑO</option>
              <option value="52240011">SANTA MONICA - CHACHAGÜI - NARIÑO</option>
              <option value="52240012">COCHA CANO - CHACHAGÜI - NARIÑO</option>
              <option value="52240013">PEDREGAL - CHACHAGÜI - NARIÑO</option>
              <option value="52250000">EL CHARCO - NARIÑO</option>
              <option value="52250003">SAN PEDRO - EL CHARCO - NARIÑO</option>
              <option value="52250022">EL CUIL - EL CHARCO - NARIÑO</option>
              <option value="52250023">BAZAN - EL CHARCO - NARIÑO</option>
              <option value="52254000">EL PEÑOL - NARIÑO</option>
              <option value="52254001">LAS COCHAS - EL PEÑOL - NARIÑO</option>
              <option value="52254002">SAN FRANCISCO - EL PEÑOL - NARIÑO</option>
              <option value="52254003">PEÑOL VIEJO - EL PEÑOL - NARIÑO</option>
              <option value="52254004">SAN FRANCISCO BAJO - EL PEÑOL - NARIÑO</option>
              <option value="52256000">EL ROSARIO - NARIÑO</option>
              <option value="52256003">LA ESMERALDA - EL ROSARIO - NARIÑO</option>
              <option value="52256004">LA SIERRA - EL ROSARIO - NARIÑO</option>
              <option value="52256008">EL VADO - EL ROSARIO - NARIÑO</option>
              <option value="52256012">MARTIN PEREZ - EL ROSARIO - NARIÑO</option>
              <option value="52256024">EL RINCON - EL ROSARIO - NARIÑO</option>
              <option value="52256025">EL SUSPIRO - EL ROSARIO - NARIÑO</option>
              <option value="52258000">EL TABLON DE GOMEZ - NARIÑO</option>
              <option value="52258001">APONTE - EL TABLON DE GOMEZ - NARIÑO</option>
              <option value="52258002">LA CUEVA - EL TABLON DE GOMEZ - NARIÑO</option>
              <option value="52258003">LAS MESAS - EL TABLON DE GOMEZ - NARIÑO</option>
              <option value="52258005">LA VICTORIA - EL TABLON DE GOMEZ - NARIÑO</option>
              <option value="52258015">SAN RAFAEL - EL TABLON DE GOMEZ - NARIÑO</option>
              <option value="52260000">EL TAMBO - NARIÑO</option>
              <option value="52287000">FUNES - NARIÑO</option>
              <option value="52287001">CHAPAL - FUNES - NARIÑO</option>
              <option value="52317000">GUACHUCAL - NARIÑO</option>
              <option value="52317001">COLIMBA - GUACHUCAL - NARIÑO</option>
              <option value="52317002">EL CONSUELO DE CHILLANQUER - GUACHUCAL - NARIÑO</option>
              <option value="52317003">SAN DIEGO DE MUELLAMUES - GUACHUCAL - NARIÑO</option>
              <option value="52317004">SAN JOSE DE CHILLANQUER - GUACHUCAL - NARIÑO</option>
              <option value="52317005">LA VICTORIA - GUACHUCAL - NARIÑO</option>
              <option value="52317007">ARVELA - GUACHUCAL - NARIÑO</option>
              <option value="52317008">QUETAMBUD - GUACHUCAL - NARIÑO</option>
              <option value="52320000">GUAITARILLA - NARIÑO</option>
              <option value="52320016">EL ROSAL - GUAITARILLA - NARIÑO</option>
              <option value="52323000">GUALMATAN - NARIÑO</option>
              <option value="52323001">CUATIS - GUALMATAN - NARIÑO</option>
              <option value="52352000">ILES - NARIÑO</option>
              <option value="52352003">SAN FRANCISCO - ILES - NARIÑO</option>
              <option value="52352006">EL CAPULI - ILES - NARIÑO</option>
              <option value="52352009">EL PORVENIR - ILES - NARIÑO</option>
              <option value="52352010">EL PORVENIR 1 - ILES - NARIÑO</option>
              <option value="52354000">IMUES - NARIÑO</option>
              <option value="52354002">PILCUAN LA RECTA - IMUES - NARIÑO</option>
              <option value="52354004">PILCUAN VIEJO - IMUES - NARIÑO</option>
              <option value="52354007">EL PEDREGAL - IMUES - NARIÑO</option>
              <option value="52354012">SANTA ANA - IMUES - NARIÑO</option>
              <option value="52356000">IPIALES - NARIÑO</option>
              <option value="52356001">LA VICTORIA - IPIALES - NARIÑO</option>
              <option value="52356002">LAS LAJAS - IPIALES - NARIÑO</option>
              <option value="52356003">SAN JUAN - IPIALES - NARIÑO</option>
              <option value="52356004">YARAMAL - IPIALES - NARIÑO</option>
              <option value="52356005">LOMAS DE SURAS - IPIALES - NARIÑO</option>
              <option value="52356008">ZAGUARAN - IPIALES - NARIÑO</option>
              <option value="52356009">LAS CRUCES - IPIALES - NARIÑO</option>
              <option value="52356011">EL PLACER - IPIALES - NARIÑO</option>
              <option value="52356012">LOS CHILCOS - IPIALES - NARIÑO</option>
              <option value="52356013">YANALA - IPIALES - NARIÑO</option>
              <option value="52356014">JARDINES DE SUCUMBIOS - IPIALES - NARIÑO</option>
              <option value="52378000">LA CRUZ - NARIÑO</option>
              <option value="52378005">SAN GERARDO - LA CRUZ - NARIÑO</option>
              <option value="52378009">TAJUMBINA - LA CRUZ - NARIÑO</option>
              <option value="52378013">CABUYALES - LA CRUZ - NARIÑO</option>
              <option value="52378017">LA ESTANCIA - LA CRUZ - NARIÑO</option>
              <option value="52381000">LA FLORIDA - NARIÑO</option>
              <option value="52381001">MATITUY - LA FLORIDA - NARIÑO</option>
              <option value="52381002">ROBLES - LA FLORIDA - NARIÑO</option>
              <option value="52381003">TUNJA LA GRANDE - LA FLORIDA - NARIÑO</option>
              <option value="52381008">EL RODEO - LA FLORIDA - NARIÑO</option>
              <option value="52381011">ACHUPAYAS - LA FLORIDA - NARIÑO</option>
              <option value="52381012">PANCHINDO - LA FLORIDA - NARIÑO</option>
              <option value="52385000">LA LLANADA - NARIÑO</option>
              <option value="52385001">VERGEL - LA LLANADA - NARIÑO</option>
              <option value="52385015">BOLIVAR - LA LLANADA - NARIÑO</option>
              <option value="52390000">LA TOLA - NARIÑO</option>
              <option value="52390003">VIGIA DE LA MAR - LA TOLA - NARIÑO</option>
              <option value="52390007">PIOJA - LA TOLA - NARIÑO</option>
              <option value="52390008">PANGAMOSA - LA TOLA - NARIÑO</option>
              <option value="52390009">MULATOS - LA TOLA - NARIÑO</option>
              <option value="52390012">NERETE - LA TOLA - NARIÑO</option>
              <option value="52390013">AMARALES - LA TOLA - NARIÑO</option>
              <option value="52390014">BAJO PALOMINO - LA TOLA - NARIÑO</option>
              <option value="52390015">PUEBLITO - LA TOLA - NARIÑO</option>
              <option value="52390016">SAN PABLO MAR - LA TOLA - NARIÑO</option>
              <option value="52399000">LA UNION - NARIÑO</option>
              <option value="52399001">SANTANDER - LA UNION - NARIÑO</option>
              <option value="52399003">LA CALDERA - LA UNION - NARIÑO</option>
              <option value="52399012">LA PLAYA - LA UNION - NARIÑO</option>
              <option value="52399018">OLIVOS - LA UNION - NARIÑO</option>
              <option value="52399029">LA BETULIA - LA UNION - NARIÑO</option>
              <option value="52399030">QUIROZ ALTO - LA UNION - NARIÑO</option>
              <option value="52399035">EL MANGO - LA UNION - NARIÑO</option>
              <option value="52399036">LA BETULIA BAJO - LA UNION - NARIÑO</option>
              <option value="52405000">LEIVA - NARIÑO</option>
              <option value="52405001">EL PALMAR - LEIVA - NARIÑO</option>
              <option value="52405002">LAS DELICIAS - LEIVA - NARIÑO</option>
              <option value="52405006">PUERTO NUEVO - LEIVA - NARIÑO</option>
              <option value="52405007">SANTA LUCIA - LEIVA - NARIÑO</option>
              <option value="52405008">LA FLORIDA - LEIVA - NARIÑO</option>
              <option value="52405009">EL TABLON - LEIVA - NARIÑO</option>
              <option value="52405014">VILLA BAJA - LEIVA - NARIÑO</option>
              <option value="52411000">LINARES - NARIÑO</option>
              <option value="52411001">SAN FRANCISCO - LINARES - NARIÑO</option>
              <option value="52411002">TABILES - LINARES - NARIÑO</option>
              <option value="52411003">TAMBILLO BRAVOS - LINARES - NARIÑO</option>
              <option value="52411004">BELLA FLORIDA - LINARES - NARIÑO</option>
              <option value="52411005">ARBOLEDAS - LINARES - NARIÑO</option>
              <option value="52411006">BELLA VISTA - LINARES - NARIÑO</option>
              <option value="52418000">SOTOMAYOR - LOS ANDES - NARIÑO</option>
              <option value="52427000">PAYAN - MAGÜI - NARIÑO</option>
              <option value="52427003">NANSALBID - MAGÜI - NARIÑO</option>
              <option value="52427005">GUILPI PIRAGUA - MAGÜI - NARIÑO</option>
              <option value="52427006">RICAURTE - MAGÜI - NARIÑO</option>
              <option value="52427015">BRISAS DE HAMBURGO - MAGÜI - NARIÑO</option>
              <option value="52427016">BELLA VISTA - MAGÜI - NARIÑO</option>
              <option value="52427017">CAMPO ALEGRE - MAGÜI - NARIÑO</option>
              <option value="52435000">PIEDRANCHA - MALLAMA - NARIÑO</option>
              <option value="52435002">CHUCUNES - MALLAMA - NARIÑO</option>
              <option value="52435008">SAN MIGUEL - MALLAMA - NARIÑO</option>
              <option value="52435009">EL CARMELO - MALLAMA - NARIÑO</option>
              <option value="52435010">EL ARCO - MALLAMA - NARIÑO</option>
              <option value="52435011">EL ARENAL - MALLAMA - NARIÑO</option>
              <option value="52473000">MOSQUERA - NARIÑO</option>
              <option value="52473001">COCALITO JIMENEZ (GABRIEL TURBAY) - MOSQUERA - NARIÑO</option>
              <option value="52473004">COCAL DE LOS PAYANES - MOSQUERA - NARIÑO</option>
              <option value="52473006">FIRME CIFUENTES - MOSQUERA - NARIÑO</option>
              <option value="52473007">BOCAS DE GUANDIPA - MOSQUERA - NARIÑO</option>
              <option value="52473008">PUEBLO NUEVO - MOSQUERA - NARIÑO</option>
              <option value="52473009">EL GARCERO - MOSQUERA - NARIÑO</option>
              <option value="52473010">EL BAJITO DE ECHANDIA - MOSQUERA - NARIÑO</option>
              <option value="52473014">PAMPA CHAPILA - MOSQUERA - NARIÑO</option>
              <option value="52473015">PLAYA NUEVA - MOSQUERA - NARIÑO</option>
              <option value="52473018">EL TORTUGO - MOSQUERA - NARIÑO</option>
              <option value="52473026">EL CANTIL - MOSQUERA - NARIÑO</option>
              <option value="52473027">EL NARANJO - MOSQUERA - NARIÑO</option>
              <option value="52473033">PAMPA QUIÑONES - MOSQUERA - NARIÑO</option>
              <option value="52480000">NARIÑO - NARIÑO</option>
              <option value="52490000">BOCAS DE SATINGA - OLAYA HERRERA - NARIÑO</option>
              <option value="52490003">EL CARMEN - OLAYA HERRERA - NARIÑO</option>
              <option value="52490008">SAN JOSE CALABAZAL - OLAYA HERRERA - NARIÑO</option>
              <option value="52490011">ALTO ZAPANQUE - OLAYA HERRERA - NARIÑO</option>
              <option value="52490012">BAJO ZAPANQUE - OLAYA HERRERA - NARIÑO</option>
              <option value="52490013">LA TOLITA - OLAYA HERRERA - NARIÑO</option>
              <option value="52490014">ZAPOTAL - OLAYA HERRERA - NARIÑO</option>
              <option value="52490015">BOCA DE VIBORA - OLAYA HERRERA - NARIÑO</option>
              <option value="52490016">EL NATO - OLAYA HERRERA - NARIÑO</option>
              <option value="52490017">CAROLINA - OLAYA HERRERA - NARIÑO</option>
              <option value="52490018">LAS PALMAS - OLAYA HERRERA - NARIÑO</option>
              <option value="52490019">SAMARITANO - OLAYA HERRERA - NARIÑO</option>
              <option value="52490020">SANTAMARIA - OLAYA HERRERA - NARIÑO</option>
              <option value="52506000">OSPINA - NARIÑO</option>
              <option value="52506002">SAN ISIDRO - OSPINA - NARIÑO</option>
              <option value="52506005">CUNCHILA O MORENO - OSPINA - NARIÑO</option>
              <option value="52520000">SALAHONDA - FRANCISCO PIZARRO - NARIÑO</option>
              <option value="52520007">BOCAS DE CURAY - FRANCISCO PIZARRO - NARIÑO</option>
              <option value="52520011">LUIS AVELINO PEREZ - FRANCISCO PIZARRO - NARIÑO</option>
              <option value="52520012">LA PLAYA - FRANCISCO PIZARRO - NARIÑO</option>
              <option value="52520015">OLIVO CURAY - FRANCISCO PIZARRO - NARIÑO</option>
              <option value="52520016">SANDER CURAY - FRANCISCO PIZARRO - NARIÑO</option>
              <option value="52520017">SOLEDAD CURAY I - FRANCISCO PIZARRO - NARIÑO</option>
              <option value="52520018">SOLEDAD CURAY II - FRANCISCO PIZARRO - NARIÑO</option>
              <option value="52540000">POLICARPA - NARIÑO</option>
              <option value="52540001">ALTAMIRA - POLICARPA - NARIÑO</option>
              <option value="52540002">MADRIGAL - POLICARPA - NARIÑO</option>
              <option value="52540003">SAN ROQUE (BUENAVISTA) - POLICARPA - NARIÑO</option>
              <option value="52540004">SANCHEZ - POLICARPA - NARIÑO</option>
              <option value="52540005">EL EJIDO - POLICARPA - NARIÑO</option>
              <option value="52540006">SANTA CRUZ - POLICARPA - NARIÑO</option>
              <option value="52540007">RESTREPO - POLICARPA - NARIÑO</option>
              <option value="52540008">EL CERRO - POLICARPA - NARIÑO</option>
              <option value="52540009">LA VEGA - POLICARPA - NARIÑO</option>
              <option value="52540010">SAN PABLO - POLICARPA - NARIÑO</option>
              <option value="52560000">POTOSI - NARIÑO</option>
              <option value="52560001">CARDENAS - POTOSI - NARIÑO</option>
              <option value="52560002">BAJO SINAI - POTOSI - NARIÑO</option>
              <option value="52560006">SAN PEDRO - POTOSI - NARIÑO</option>
              <option value="52565000">PROVIDENCIA - NARIÑO</option>
              <option value="52565001">GUADRAHUMA - PROVIDENCIA - NARIÑO</option>
              <option value="52573000">PUERRES - NARIÑO</option>
              <option value="52573002">MONOPAMBA - PUERRES - NARIÑO</option>
              <option value="52573003">SAN MATEO - PUERRES - NARIÑO</option>
              <option value="52573005">MAICIRA - PUERRES - NARIÑO</option>
              <option value="52573006">EL LLANO - PUERRES - NARIÑO</option>
              <option value="52573010">SAN MIGUEL - PUERRES - NARIÑO</option>
              <option value="52573013">YANALE - PUERRES - NARIÑO</option>
              <option value="52573014">LOS ALIZALES - PUERRES - NARIÑO</option>
              <option value="52585000">PUPIALES - NARIÑO</option>
              <option value="52585004">JOSE MARIA HERNANDEZ - PUPIALES - NARIÑO</option>
              <option value="52612000">RICAURTE - NARIÑO</option>
              <option value="52612003">OSPINA PEREZ - RICAURTE - NARIÑO</option>
              <option value="52612004">SAN ISIDRO - RICAURTE - NARIÑO</option>
              <option value="52612008">CHAMBU - RICAURTE - NARIÑO</option>
              <option value="52612011">SAN FRANCISCO - RICAURTE - NARIÑO</option>
              <option value="52612012">VILLA NUEVA - RICAURTE - NARIÑO</option>
              <option value="52612013">PALMAR - RICAURTE - NARIÑO</option>
              <option value="52621000">SAN JOSE - ROBERTO PAYAN - NARIÑO</option>
              <option value="52621009">LAS LAJAS PUMBI - ROBERTO PAYAN - NARIÑO</option>
              <option value="52621016">SAN ANTONIO - BOCA TELEMBI - ROBERTO PAYAN - NARIÑO</option>
              <option value="52621028">LAS MERCEDES - CHIMBUZA - ROBERTO PAYAN - NARIÑO</option>
              <option value="52678000">SAMANIEGO - NARIÑO</option>
              <option value="52678007">TANAMA - SAMANIEGO - NARIÑO</option>
              <option value="52678012">CHUGULDI - SAMANIEGO - NARIÑO</option>
              <option value="52678013">TURUPAMBA - SAMANIEGO - NARIÑO</option>
              <option value="52678015">PUERCHAG - SAMANIEGO - NARIÑO</option>
              <option value="52678017">CARTAGENA - SAMANIEGO - NARIÑO</option>
              <option value="52678024">LA MESA - SAMANIEGO - NARIÑO</option>
              <option value="52678025">OBANDO - SAMANIEGO - NARIÑO</option>
              <option value="52678029">BONETE - SAMANIEGO - NARIÑO</option>
              <option value="52678030">MIRADOR DE SARACONCHO - SAMANIEGO - NARIÑO</option>
              <option value="52683000">SANDONA - NARIÑO</option>
              <option value="52683001">BOLIVAR - SANDONA - NARIÑO</option>
              <option value="52683002">EL INGENIO - SANDONA - NARIÑO</option>
              <option value="52683003">SAN BERNARDO - SANDONA - NARIÑO</option>
              <option value="52683004">SANTA BARBARA - SANDONA - NARIÑO</option>
              <option value="52683005">SANTA ROSA - SANDONA - NARIÑO</option>
              <option value="52683007">ROMA CHAVEZ - SANDONA - NARIÑO</option>
              <option value="52683009">PARAGUAY - SANDONA - NARIÑO</option>
              <option value="52683010">EL VERGEL - SANDONA - NARIÑO</option>
              <option value="52683011">SAN GABRIEL - SANDONA - NARIÑO</option>
              <option value="52683012">SAN MIGUEL - SANDONA - NARIÑO</option>
              <option value="52683014">ALTAMIRA CRUZ DE ARADA - SANDONA - NARIÑO</option>
              <option value="52683016">CHAVEZ - SANDONA - NARIÑO</option>
              <option value="52683017">TAMBILLO - SANDONA - NARIÑO</option>
              <option value="52683018">LA LOMA - SANDONA - NARIÑO</option>
              <option value="52683022">SAN FERNANDO - SANDONA - NARIÑO</option>
              <option value="52683023">SAN FRANCISCO ALTO - SANDONA - NARIÑO</option>
              <option value="52683027">URBANIZACION VILLA CAFELINA - SANDONA - NARIÑO</option>
              <option value="52685000">SAN BERNARDO - NARIÑO</option>
              <option value="52685001">LA VEGA - SAN BERNARDO - NARIÑO</option>
              <option value="52685002">PUEBLO VIEJO - SAN BERNARDO - NARIÑO</option>
              <option value="52687000">SAN LORENZO - NARIÑO</option>
              <option value="52687001">EL CARMEN - SAN LORENZO - NARIÑO</option>
              <option value="52687002">SANTA CECILIA - SAN LORENZO - NARIÑO</option>
              <option value="52687003">SANTA MARTHA - SAN LORENZO - NARIÑO</option>
              <option value="52687005">SAN VICENTE - SAN LORENZO - NARIÑO</option>
              <option value="52687008">EL CHEPE - SAN LORENZO - NARIÑO</option>
              <option value="52693000">SAN PABLO - NARIÑO</option>
              <option value="52693002">BRICEÑO - SAN PABLO - NARIÑO</option>
              <option value="52693004">LA CAÑADA - SAN PABLO - NARIÑO</option>
              <option value="52693017">CHILCAL ALTO - SAN PABLO - NARIÑO</option>
              <option value="52694000">SAN PEDRO DE CARTAGO - NARIÑO</option>
              <option value="52694001">LA COMUNIDAD - SAN PEDRO DE CARTAGO - NARIÑO</option>
              <option value="52696000">ISCUANDE - SANTA BARBARA - NARIÑO</option>
              <option value="52696009">PALOMINO - SANTA BARBARA - NARIÑO</option>
              <option value="52696012">CUERBAL - SANTA BARBARA - NARIÑO</option>
              <option value="52696013">JUANCHILLO - SANTA BARBARA - NARIÑO</option>
              <option value="52696014">LA ENSENADA - SANTA BARBARA - NARIÑO</option>
              <option value="52696015">CHICO PEREZ - SANTA BARBARA - NARIÑO</option>
              <option value="52696017">SANTA RITA - SANTA BARBARA - NARIÑO</option>
              <option value="52696018">BOCA DE CHANZARA - SANTA BARBARA - NARIÑO</option>
              <option value="52696019">LAS VARAS - SANTA BARBARA - NARIÑO</option>
              <option value="52696020">QUIGUPI - SANTA BARBARA - NARIÑO</option>
              <option value="52696021">RODEA - SANTA BARBARA - NARIÑO</option>
              <option value="52696022">SECADERO SEGUIHONDA - SANTA BARBARA - NARIÑO</option>
              <option value="52696023">SOLEDAD PUEBLITO - SANTA BARBARA - NARIÑO</option>
              <option value="52699000">GUACHAVES - SANTACRUZ - NARIÑO</option>
              <option value="52699001">BALALAIKA - SANTACRUZ - NARIÑO</option>
              <option value="52699004">MANCHAG - SANTACRUZ - NARIÑO</option>
              <option value="52720000">SAPUYES - NARIÑO</option>
              <option value="52720001">EL ESPINO - SAPUYES - NARIÑO</option>
              <option value="52720002">URIBE - SAPUYES - NARIÑO</option>
              <option value="52786000">TAMINANGO - NARIÑO</option>
              <option value="52786002">EL TABLON - TAMINANGO - NARIÑO</option>
              <option value="52786003">CURIACO - TAMINANGO - NARIÑO</option>
              <option value="52786006">ALTO DE DIEGO - TAMINANGO - NARIÑO</option>
              <option value="52786007">EL MANZANO - TAMINANGO - NARIÑO</option>
              <option value="52786014">PARAMO - TAMINANGO - NARIÑO</option>
              <option value="52786018">LA GRANADA - TAMINANGO - NARIÑO</option>
              <option value="52786019">EL REMOLINO - TAMINANGO - NARIÑO</option>
              <option value="52786021">GUAYACANAL - TAMINANGO - NARIÑO</option>
              <option value="52786022">SAN ISIDRO - TAMINANGO - NARIÑO</option>
              <option value="52786023">EL DIVISO - TAMINANGO - NARIÑO</option>
              <option value="52786024">VIENTO LIBRE - TAMINANGO - NARIÑO</option>
              <option value="52786025">PANOYA - TAMINANGO - NARIÑO</option>
              <option value="52788000">TANGUA - NARIÑO</option>
              <option value="52788001">SANTANDER - TANGUA - NARIÑO</option>
              <option value="52835000">SAN ANDRES DE TUMACO, DISTRITO ESPECIAL, INDUSTRIAL, PORTUARIO, BIODIVERSO Y ECOTURISTICO - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835009">CAUNAPI - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835010">COLORADO - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835011">DESCOLGADERO - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835012">CHAJAL - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835016">PITAL - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835017">ESPRIELLA - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835020">BARRO COLORADO - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835021">SAN JOSE DEL GUAYABO - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835030">GUAYACANA - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835031">LLORENTE - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835036">PALAMBI - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835037">IMBILI MIRASPALMAS - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835040">EL PROGRESO SANTO DOMINGO - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835042">SAN LUIS ROBLES - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835047">SALISVI - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835050">VILLA SAN JUAN - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835051">SAN ANTONIO - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835058">TEHERAN - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835059">URIBE URIBE (CHILVI) - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835063">EL BAJITO - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835065">EL PINDE - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835068">CALETA VIENTO LIBRE - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835069">CEIBITO - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835071">EL CARMEN KM 36 - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835075">BOCANA NUEVA - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835077">CHILVICITO - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835083">LA SIRENA - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835085">PALAY - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835087">PULGANDE - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835088">RETOÑO - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835091">SANTA ROSA - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835092">ALTO AGUA CLARA - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835093">IMBILPI DEL CARMEN - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835099">INGUAPI DEL CARMEN - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835100">SANTA MARIA ROSARIO - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835101">LA BARCA - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835102">EL COCO - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835104">ALBANIA - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835107">BAJO JAGUA - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835108">BRISAS DEL ACUEDUCTO - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835109">CACAGUAL - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835111">CORRIENTE GRANDE - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835114">GUABAL - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835115">GUACHAL - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835116">GUALTAL - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835118">EL RETORNO - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835119">JUAN DOMINGO - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835120">KILOMETRO 28 - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835121">KILOMETRO 35 - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835123">KILOMETRO 58 - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835125">LA CHORRERA - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835127">PIÑUELA RIO MIRA - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835129">LA VEGA - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835130">MAJAGUA - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835133">PACORA - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835134">PINDALES - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835136">PUEBLO NUEVO - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835140">TAMBILLO - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835141">TANGAREAL CARRETERA - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835144">VUELTA CANDELILLA - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835146">FIRME DE LOS COIMES - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835148">TABLON DULCE LA PAMPA - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835149">LAS MERCEDES - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835150">BELLAVISTA - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835151">VAQUERIA COLOMBIA GRANDE - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835152">INGUAPI EL GUADUAL - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835153">CONGAL - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835154">BAJO GUABAL - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835155">PEÑA COLORADA - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835156">BUCHELY - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835157">CAJAPI - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835158">VARIANTE - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835159">DOS QUEBRADAS - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835160">CANDELILLA - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835161">PIÑAL SALADO - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835162">CHONTAL - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835163">IMBILI - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835164">SAN PEDRO DEL VINO - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835166">SAN SEBASTIAN - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835167">BOCA DE TULMO - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835168">SAGUMBITA - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835174">LA BRAVA RIO CAUNAPI - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835177">ACHOTAL - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835178">AGUACATE - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835179">ALTO BUENOS AIRES - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835180">ALTO JAGUA (RIO MIRA) - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835181">ALTO SANTO DOMINGO - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835182">ALTO VILLARICA - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835183">BAJO BUENOS AIRES (TABLON SALADO) - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835184">BOCAGRANDE - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835185">BUCHELY 2 - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835186">CAJAPI DEL MIRA - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835187">CANDELILLAS DE LA MAR - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835188">CHIMBUZAL - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835190">GUACHIRI - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835191">GUADUAL - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835193">INDA ZABALETA - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835194">INGUAPI DEL CARMEN 2 - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835196">INGUAPI LA CHIRICANA - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835197">ISLA GRANDE - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835198">LA BALSA - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835200">LA CONCHA (TABLON SALADO) - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835201">13 DE MAYO - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835202">LAS BRISAS - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835203">NERETE (RIO MIRA) - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835204">NUEVA REFORMA - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835205">NUEVO PUERTO NIDIA - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835207">PARAISO - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835208">PIÑAL DULCE - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835209">PITAL (CHIMBUZAL) - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835210">PORVENIR - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835211">PUEBLO NUEVO (RIO MIRA) - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835212">PUEBLO NUEVO (TABLON SALADO) - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835213">RESTREPO - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835214">SAN AGUSTIN - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835216">SAN JUAN PUEBLO NUEVO - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835217">SAN JUAN (RIO MIRA) - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835218">SAN PABLO - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835219">SAN VICENTE (LAS VARAS) - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835221">SEIS DE AGOSTO - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835224">TANGAREAL DEL MIRA - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835226">VIGUARAL - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835227">VUELTA CANDELILLAS 2 - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835228">VUELTA DEL CARMEN - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835229">VUELTA LARGA (RIO GUANAPI) - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835230">ZAPOTAL - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835231">CRISTO REY - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835232">KILOMETRO 63 - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835233">KILOMETRO 75 LA INVASION - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835234">LA VIÑA - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52835235">VAQUERIA LA TORRE - SAN ANDRES DE TUMACO - NARIÑO</option>
              <option value="52838000">TUQUERRES - NARIÑO</option>
              <option value="52838001">ALBAN - TUQUERRES - NARIÑO</option>
              <option value="52838002">CUATRO ESQUINAS - TUQUERRES - NARIÑO</option>
              <option value="52838004">PINZON - TUQUERRES - NARIÑO</option>
              <option value="52838007">SANTANDER - TUQUERRES - NARIÑO</option>
              <option value="52838009">YASCUAL - TUQUERRES - NARIÑO</option>
              <option value="52838012">LOS ARRAYANES - TUQUERRES - NARIÑO</option>
              <option value="52885000">YACUANQUER - NARIÑO</option>
              <option value="52885006">MEJIA - YACUANQUER - NARIÑO</option>
              <option value="52885007">LA AGUADA - YACUANQUER - NARIÑO</option>
              <option value="54001000">CUCUTA - NORTE DE SANTANDER</option>
              <option value="54001001">AGUA CLARA - CUCUTA - NORTE DE SANTANDER</option>
              <option value="54001002">BANCO DE ARENAS - CUCUTA - NORTE DE SANTANDER</option>
              <option value="54001003">BUENA ESPERANZA - CUCUTA - NORTE DE SANTANDER</option>
              <option value="54001007">PATILLALES - CUCUTA - NORTE DE SANTANDER</option>
              <option value="54001011">PUERTO VILLAMIZAR - CUCUTA - NORTE DE SANTANDER</option>
              <option value="54001015">RICAURTE - CUCUTA - NORTE DE SANTANDER</option>
              <option value="54001017">SAN FAUSTINO - CUCUTA - NORTE DE SANTANDER</option>
              <option value="54001018">SAN PEDRO - CUCUTA - NORTE DE SANTANDER</option>
              <option value="54001025">ARRAYAN - CUCUTA - NORTE DE SANTANDER</option>
              <option value="54001028">ALTO VIENTO - CUCUTA - NORTE DE SANTANDER</option>
              <option value="54001029">EL PRADO - CUCUTA - NORTE DE SANTANDER</option>
              <option value="54001030">PORTICO - CUCUTA - NORTE DE SANTANDER</option>
              <option value="54001033">LA JARRA - CUCUTA - NORTE DE SANTANDER</option>
              <option value="54001036">PALMARITO - CUCUTA - NORTE DE SANTANDER</option>
              <option value="54001038">PUERTO LEON - CUCUTA - NORTE DE SANTANDER</option>
              <option value="54001039">PUERTO NUEVO - CUCUTA - NORTE DE SANTANDER</option>
              <option value="54001041">GUARAMITO - CUCUTA - NORTE DE SANTANDER</option>
              <option value="54001042">LA FLORESTA - CUCUTA - NORTE DE SANTANDER</option>
              <option value="54001043">LA PUNTA - CUCUTA - NORTE DE SANTANDER</option>
              <option value="54001044">VIIGILANCIA - CUCUTA - NORTE DE SANTANDER</option>
              <option value="54001045">PUERTO LLERAS - CUCUTA - NORTE DE SANTANDER</option>
              <option value="54001046">SANTA CECILIA - CUCUTA - NORTE DE SANTANDER</option>
              <option value="54001047">CARMEN DE TONCHALA - CUCUTA - NORTE DE SANTANDER</option>
              <option value="54001049">ORIPAYA - CUCUTA - NORTE DE SANTANDER</option>
              <option value="54001050">LAS VACAS - CUCUTA - NORTE DE SANTANDER</option>
              <option value="54001053">BELLA VISTA - CUCUTA - NORTE DE SANTANDER</option>
              <option value="54001054">EL PLOMO - CUCUTA - NORTE DE SANTANDER</option>
              <option value="54001055">EL SUSPIRO - CUCUTA - NORTE DE SANTANDER</option>
              <option value="54001056">LA SABANA - CUCUTA - NORTE DE SANTANDER</option>
              <option value="54001057">NUEVO MADRID - CUCUTA - NORTE DE SANTANDER</option>
              <option value="54001058">SAN AGUSTIN DE LOS POZOS - CUCUTA - NORTE DE SANTANDER</option>
              <option value="54001061">AGUA BLANCA - CUCUTA - NORTE DE SANTANDER</option>
              <option value="54001067">LONDRES - CUCUTA - NORTE DE SANTANDER</option>
              <option value="54001068">BANCO DE ARENAS 2 - CUCUTA - NORTE DE SANTANDER</option>
              <option value="54003000">ABREGO - NORTE DE SANTANDER</option>
              <option value="54003002">CASITAS - ABREGO - NORTE DE SANTANDER</option>
              <option value="54051000">ARBOLEDAS - NORTE DE SANTANDER</option>
              <option value="54051002">CASTRO - ARBOLEDAS - NORTE DE SANTANDER</option>
              <option value="54051005">VILLA SUCRE - ARBOLEDAS - NORTE DE SANTANDER</option>
              <option value="54099000">BOCHALEMA - NORTE DE SANTANDER</option>
              <option value="54099002">LA DONJUANA - BOCHALEMA - NORTE DE SANTANDER</option>
              <option value="54099005">LA ESMERALDA - BOCHALEMA - NORTE DE SANTANDER</option>
              <option value="54109000">BUCARASICA - NORTE DE SANTANDER</option>
              <option value="54109002">LA CURVA - BUCARASICA - NORTE DE SANTANDER</option>
              <option value="54109003">LA SANJUANA - BUCARASICA - NORTE DE SANTANDER</option>
              <option value="54125000">CACOTA - NORTE DE SANTANDER</option>
              <option value="54128000">CACHIRA - NORTE DE SANTANDER</option>
              <option value="54128001">LA CARRERA - CACHIRA - NORTE DE SANTANDER</option>
              <option value="54128003">LA VEGA - CACHIRA - NORTE DE SANTANDER</option>
              <option value="54128011">LOS MANGOS - CACHIRA - NORTE DE SANTANDER</option>
              <option value="54128015">SAN JOSE DEL LLANO - CACHIRA - NORTE DE SANTANDER</option>
              <option value="54172000">CHINACOTA - NORTE DE SANTANDER</option>
              <option value="54172001">LA NUEVA DONJUANA - CHINACOTA - NORTE DE SANTANDER</option>
              <option value="54172005">EL NUEVO DIAMANTE - CHINACOTA - NORTE DE SANTANDER</option>
              <option value="54172006">CHITACOMAR - CHINACOTA - NORTE DE SANTANDER</option>
              <option value="54172007">RECTA LOS ALAMOS - CHINACOTA - NORTE DE SANTANDER</option>
              <option value="54172008">TENERIA - CHINACOTA - NORTE DE SANTANDER</option>
              <option value="54174000">CHITAGA - NORTE DE SANTANDER</option>
              <option value="54174002">SAN LUIS DE CHUCARIMA - CHITAGA - NORTE DE SANTANDER</option>
              <option value="54174005">LLANO GRANDE - CHITAGA - NORTE DE SANTANDER</option>
              <option value="54174006">PRESIDENTE - CHITAGA - NORTE DE SANTANDER</option>
              <option value="54206000">CONVENCION - NORTE DE SANTANDER</option>
              <option value="54206002">CARTAGENITA - CONVENCION - NORTE DE SANTANDER</option>
              <option value="54206004">EL GUAMAL - CONVENCION - NORTE DE SANTANDER</option>
              <option value="54206005">LAS MERCEDES - CONVENCION - NORTE DE SANTANDER</option>
              <option value="54206011">LA LIBERTAD - CONVENCION - NORTE DE SANTANDER</option>
              <option value="54206012">LA VEGA - CONVENCION - NORTE DE SANTANDER</option>
              <option value="54206013">PIEDECUESTA - CONVENCION - NORTE DE SANTANDER</option>
              <option value="54206015">LA TRINIDAD - CONVENCION - NORTE DE SANTANDER</option>
              <option value="54206016">MIRAFLORES - CONVENCION - NORTE DE SANTANDER</option>
              <option value="54223000">CUCUTILLA - NORTE DE SANTANDER</option>
              <option value="54223002">SAN JOSE DE LA MONTAÑA - CUCUTILLA - NORTE DE SANTANDER</option>
              <option value="54223004">TIERRA GRATA - CUCUTILLA - NORTE DE SANTANDER</option>
              <option value="54239000">DURANIA - NORTE DE SANTANDER</option>
              <option value="54239002">HATOVIEJO - DURANIA - NORTE DE SANTANDER</option>
              <option value="54239003">LA CUCHILLA - DURANIA - NORTE DE SANTANDER</option>
              <option value="54239004">LA MONTUOSA - DURANIA - NORTE DE SANTANDER</option>
              <option value="54239007">LAS AGUADAS - DURANIA - NORTE DE SANTANDER</option>
              <option value="54245000">EL CARMEN - NORTE DE SANTANDER</option>
              <option value="54245006">GUAMALITO - EL CARMEN - NORTE DE SANTANDER</option>
              <option value="54250000">EL TARRA - NORTE DE SANTANDER</option>
              <option value="54250002">ORU - EL TARRA - NORTE DE SANTANDER</option>
              <option value="54250003">FILO GRINGO - EL TARRA - NORTE DE SANTANDER</option>
              <option value="54250005">EL PASO - EL TARRA - NORTE DE SANTANDER</option>
              <option value="54250007">LA CAMPANA - EL TARRA - NORTE DE SANTANDER</option>
              <option value="54261000">EL ZULIA - NORTE DE SANTANDER</option>
              <option value="54261006">LAS PIEDRAS - EL ZULIA - NORTE DE SANTANDER</option>
              <option value="54261007">ASTILLEROS LA YE - EL ZULIA - NORTE DE SANTANDER</option>
              <option value="54261008">CAMILANDIA - EL ZULIA - NORTE DE SANTANDER</option>
              <option value="54261009">CRISTO REY - EL ZULIA - NORTE DE SANTANDER</option>
              <option value="54261010">EL TABLAZO - EL ZULIA - NORTE DE SANTANDER</option>
              <option value="54261011">LAS BRISAS - EL ZULIA - NORTE DE SANTANDER</option>
              <option value="54261012">SANTA ROSA - EL ZULIA - NORTE DE SANTANDER</option>
              <option value="54261013">7 DE AGOSTO - EL ZULIA - NORTE DE SANTANDER</option>
              <option value="54313000">GRAMALOTE - NORTE DE SANTANDER</option>
              <option value="54313005">LA LOMITA - GRAMALOTE - NORTE DE SANTANDER</option>
              <option value="54313006">LA ESTRELLA - GRAMALOTE - NORTE DE SANTANDER</option>
              <option value="54313007">POMARROSO - GRAMALOTE - NORTE DE SANTANDER</option>
              <option value="54344000">HACARI - NORTE DE SANTANDER</option>
              <option value="54344003">MARACAIBO - HACARI - NORTE DE SANTANDER</option>
              <option value="54344008">SAN JOSE DEL TARRA - HACARI - NORTE DE SANTANDER</option>
              <option value="54344009">LAS JUNTAS - HACARI - NORTE DE SANTANDER</option>
              <option value="54344011">LA ESTACION O MESITAS - HACARI - NORTE DE SANTANDER</option>
              <option value="54347000">HERRAN - NORTE DE SANTANDER</option>
              <option value="54377000">LABATECA - NORTE DE SANTANDER</option>
              <option value="54385000">LA ESPERANZA - NORTE DE SANTANDER</option>
              <option value="54385001">LA PEDREGOSA - LA ESPERANZA - NORTE DE SANTANDER</option>
              <option value="54385002">LEON XIII - LA ESPERANZA - NORTE DE SANTANDER</option>
              <option value="54385003">PUEBLO NUEVO - LA ESPERANZA - NORTE DE SANTANDER</option>
              <option value="54385005">EL TROPEZON - LA ESPERANZA - NORTE DE SANTANDER</option>
              <option value="54385007">LOS CEDROS - LA ESPERANZA - NORTE DE SANTANDER</option>
              <option value="54385008">VILLAMARIA - LA ESPERANZA - NORTE DE SANTANDER</option>
              <option value="54385009">CAMPO ALEGRE - LA ESPERANZA - NORTE DE SANTANDER</option>
              <option value="54385010">LA CANCHA - LA ESPERANZA - NORTE DE SANTANDER</option>
              <option value="54398000">LA PLAYA - NORTE DE SANTANDER</option>
              <option value="54398002">ASPASICA - LA PLAYA - NORTE DE SANTANDER</option>
              <option value="54398006">LA VEGA DE SAN ANTONIO - LA PLAYA - NORTE DE SANTANDER</option>
              <option value="54405000">LOS PATIOS - NORTE DE SANTANDER</option>
              <option value="54405001">LA GARITA - LOS PATIOS - NORTE DE SANTANDER</option>
              <option value="54405003">LOS VADOS - LOS PATIOS - NORTE DE SANTANDER</option>
              <option value="54405004">AGUA LINDA - LOS PATIOS - NORTE DE SANTANDER</option>
              <option value="54405006">EL TRAPICHE - LOS PATIOS - NORTE DE SANTANDER</option>
              <option value="54405007">COROZAL VEREDA CALIFORNIA - LOS PATIOS - NORTE DE SANTANDER</option>
              <option value="54405008">LAGOS DE PALUJAN - LOS PATIOS - NORTE DE SANTANDER</option>
              <option value="54405009">RECTA COROZAL - LOS PATIOS - NORTE DE SANTANDER</option>
              <option value="54405010">VILLA KATHERINE - LOS PATIOS - NORTE DE SANTANDER</option>
              <option value="54405011">VILLAS DE COROZAL - LOS PATIOS - NORTE DE SANTANDER</option>
              <option value="54418000">LOURDES - NORTE DE SANTANDER</option>
              <option value="54480000">MUTISCUA - NORTE DE SANTANDER</option>
              <option value="54480001">LA LAGUNA - MUTISCUA - NORTE DE SANTANDER</option>
              <option value="54480003">LA CALDERA - MUTISCUA - NORTE DE SANTANDER</option>
              <option value="54498000">OCAÑA - NORTE DE SANTANDER</option>
              <option value="54498002">AGUASCLARAS - OCAÑA - NORTE DE SANTANDER</option>
              <option value="54498005">OTARE - OCAÑA - NORTE DE SANTANDER</option>
              <option value="54498006">BUENAVISTA - OCAÑA - NORTE DE SANTANDER</option>
              <option value="54498008">LA ERMITA - OCAÑA - NORTE DE SANTANDER</option>
              <option value="54498009">LA FLORESTA - OCAÑA - NORTE DE SANTANDER</option>
              <option value="54498012">PUEBLO NUEVO - OCAÑA - NORTE DE SANTANDER</option>
              <option value="54498025">ALGODONAL - OCAÑA - NORTE DE SANTANDER</option>
              <option value="54498026">CLUB ALL STAR - OCAÑA - NORTE DE SANTANDER</option>
              <option value="54518000">PAMPLONA - NORTE DE SANTANDER</option>
              <option value="54520000">PAMPLONITA - NORTE DE SANTANDER</option>
              <option value="54520001">EL DIAMANTE - PAMPLONITA - NORTE DE SANTANDER</option>
              <option value="54520002">EL TREVEJO - PAMPLONITA - NORTE DE SANTANDER</option>
              <option value="54553000">PUERTO SANTANDER - NORTE DE SANTANDER</option>
              <option value="54553001">EL DIAMANTE - PUERTO SANTANDER - NORTE DE SANTANDER</option>
              <option value="54599000">RAGONVALIA - NORTE DE SANTANDER</option>
              <option value="54599005">CALICHES - RAGONVALIA - NORTE DE SANTANDER</option>
              <option value="54660000">SALAZAR DE LAS PALMAS - SALAZAR - NORTE DE SANTANDER</option>
              <option value="54660001">EL CARMEN DE NAZARETH - SALAZAR - NORTE DE SANTANDER</option>
              <option value="54660002">LA LAGUNA - SALAZAR - NORTE DE SANTANDER</option>
              <option value="54660005">SAN JOSE DEL AVILA - SALAZAR - NORTE DE SANTANDER</option>
              <option value="54660007">EL SALADO - SALAZAR - NORTE DE SANTANDER</option>
              <option value="54670000">SAN CALIXTO - NORTE DE SANTANDER</option>
              <option value="54670004">VISTA HERMOSA - SAN CALIXTO - NORTE DE SANTANDER</option>
              <option value="54670010">PALMARITO - SAN CALIXTO - NORTE DE SANTANDER</option>
              <option value="54670016">LA QUINA - SAN CALIXTO - NORTE DE SANTANDER</option>
              <option value="54670017">LAGUNITAS - SAN CALIXTO - NORTE DE SANTANDER</option>
              <option value="54673000">SAN CAYETANO - NORTE DE SANTANDER</option>
              <option value="54673002">CORNEJO - SAN CAYETANO - NORTE DE SANTANDER</option>
              <option value="54673005">URIMACO - SAN CAYETANO - NORTE DE SANTANDER</option>
              <option value="54673008">SAN ISIDRO - SAN CAYETANO - NORTE DE SANTANDER</option>
              <option value="54680000">SANTIAGO - NORTE DE SANTANDER</option>
              <option value="54720000">SARDINATA - NORTE DE SANTANDER</option>
              <option value="54720002">EL CARMEN - SARDINATA - NORTE DE SANTANDER</option>
              <option value="54720003">LA VICTORIA - SARDINATA - NORTE DE SANTANDER</option>
              <option value="54720004">LAS MERCEDES - SARDINATA - NORTE DE SANTANDER</option>
              <option value="54720005">LUIS VERO - SARDINATA - NORTE DE SANTANDER</option>
              <option value="54720006">SAN MARTIN DE LOBA - SARDINATA - NORTE DE SANTANDER</option>
              <option value="54720007">SAN ROQUE - SARDINATA - NORTE DE SANTANDER</option>
              <option value="54743000">SILOS - NORTE DE SANTANDER</option>
              <option value="54743001">BABEGA - SILOS - NORTE DE SANTANDER</option>
              <option value="54743003">LOS RINCON - SILOS - NORTE DE SANTANDER</option>
              <option value="54743004">LA LAGUNA - SILOS - NORTE DE SANTANDER</option>
              <option value="54743006">PACHAGUAL - SILOS - NORTE DE SANTANDER</option>
              <option value="54800000">TEORAMA - NORTE DE SANTANDER</option>
              <option value="54800011">LA CECILIA - TEORAMA - NORTE DE SANTANDER</option>
              <option value="54800022">SAN PABLO - TEORAMA - NORTE DE SANTANDER</option>
              <option value="54800025">QUINCE LETRAS - TEORAMA - NORTE DE SANTANDER</option>
              <option value="54800026">EL ASERRIO - TEORAMA - NORTE DE SANTANDER</option>
              <option value="54810000">TIBU - NORTE DE SANTANDER</option>
              <option value="54810002">LA GABARRA - TIBU - NORTE DE SANTANDER</option>
              <option value="54810003">PACCELLY - TIBU - NORTE DE SANTANDER</option>
              <option value="54810006">TRES BOCAS - TIBU - NORTE DE SANTANDER</option>
              <option value="54810008">PETROLEA - TIBU - NORTE DE SANTANDER</option>
              <option value="54810009">VERSALLES - TIBU - NORTE DE SANTANDER</option>
              <option value="54810011">CAMPO GILES - TIBU - NORTE DE SANTANDER</option>
              <option value="54810013">LA LLANA - TIBU - NORTE DE SANTANDER</option>
              <option value="54810016">VETAS DE ORIENTE - TIBU - NORTE DE SANTANDER</option>
              <option value="54810017">CAMPO DOS - TIBU - NORTE DE SANTANDER</option>
              <option value="54810018">LA CUATRO - TIBU - NORTE DE SANTANDER</option>
              <option value="54820000">TOLEDO - NORTE DE SANTANDER</option>
              <option value="54820008">SAN BERNARDO DE BATA - TOLEDO - NORTE DE SANTANDER</option>
              <option value="54820015">SAMORE - TOLEDO - NORTE DE SANTANDER</option>
              <option value="54871000">VILLA CARO - NORTE DE SANTANDER</option>
              <option value="54874000">VILLA DEL ROSARIO - NORTE DE SANTANDER</option>
              <option value="54874001">JUAN FRIO - VILLA DEL ROSARIO - NORTE DE SANTANDER</option>
              <option value="54874008">PALOGORDO NORTE - VILLA DEL ROSARIO - NORTE DE SANTANDER</option>
              <option value="63001000">ARMENIA - QUINDIO</option>
              <option value="63001001">EL CAIMO - ARMENIA - QUINDIO</option>
              <option value="63001002">MURILLO - ARMENIA - QUINDIO</option>
              <option value="63001008">CASERIO SANTA HELENA - ARMENIA - QUINDIO</option>
              <option value="63001009">CONDOMINIO EL EDEN - ARMENIA - QUINDIO</option>
              <option value="63001010">CONDOMINIOS LAS VEGAS, IRAKA Y LAGOS DE IRAKA - ARMENIA - QUINDIO</option>
              <option value="63001011">CONDOMINIO PALO DE AGUA - ARMENIA - QUINDIO</option>
              <option value="63001012">CONDOMINIO PONTEVEDRA - ARMENIA - QUINDIO</option>
              <option value="63001013">CONDOMINIO SENIORS CLUB - ARMENIA - QUINDIO</option>
              <option value="63001015">NUEVO HORIZONTE - SAPERA - ARMENIA - QUINDIO</option>
              <option value="63001016">SECTOR CENEXPO - ARMENIA - QUINDIO</option>
              <option value="63111000">BUENAVISTA - QUINDIO</option>
              <option value="63111001">RIO VERDE - BUENAVISTA - QUINDIO</option>
              <option value="63130000">CALARCA - QUINDIO</option>
              <option value="63130001">BARCELONA - CALARCA - QUINDIO</option>
              <option value="63130003">LA BELLA - CALARCA - QUINDIO</option>
              <option value="63130004">LA VIRGINIA - CALARCA - QUINDIO</option>
              <option value="63130005">QUEBRADANEGRA - CALARCA - QUINDIO</option>
              <option value="63130008">LA PRADERA - CALARCA - QUINDIO</option>
              <option value="63130010">LA MARIA - CALARCA - QUINDIO</option>
              <option value="63130013">BARRAGAN - CALARCA - QUINDIO</option>
              <option value="63130015">CONDOMINIO LOS ALMENDROS - CALARCA - QUINDIO</option>
              <option value="63130016">CONDOMINIO VALLE DEL SOL - CALARCA - QUINDIO</option>
              <option value="63130018">CONDOMINIO AGUA BONITA - CALARCA - QUINDIO</option>
              <option value="63130019">CONDOMINIO LA MICAELA - CALARCA - QUINDIO</option>
              <option value="63190000">CIRCASIA - QUINDIO</option>
              <option value="63190001">LA POLA - CIRCASIA - QUINDIO</option>
              <option value="63190002">LA SIRIA - CIRCASIA - QUINDIO</option>
              <option value="63190003">PIAMONTE - CIRCASIA - QUINDIO</option>
              <option value="63190004">SANTA RITA - CIRCASIA - QUINDIO</option>
              <option value="63190006">VILLARAZO - SAN LUIS - CIRCASIA - QUINDIO</option>
              <option value="63190007">LA JULIA - CIRCASIA - QUINDIO</option>
              <option value="63190008">LA FRONTERA - CIRCASIA - QUINDIO</option>
              <option value="63190009">EL TRIUNFO - CIRCASIA - QUINDIO</option>
              <option value="63190010">EL PLANAZO - CIRCASIA - QUINDIO</option>
              <option value="63190011">LA 18 GUAYABAL - CIRCASIA - QUINDIO</option>
              <option value="63190012">URBANIZACION EL CANEY - CIRCASIA - QUINDIO</option>
              <option value="63190013">CONDOMINIO BOSQUES DE TOSCANA - CIRCASIA - QUINDIO</option>
              <option value="63190014">CONDOMINIO LA ALDEA - CIRCASIA - QUINDIO</option>
              <option value="63190016">CONDOMINIO LOS ABEDULES Y YARUMOS I - CIRCASIA - QUINDIO</option>
              <option value="63190017">CONDOMINIO LOS ROBLES - CIRCASIA - QUINDIO</option>
              <option value="63190018">CONDOMINIO LOS ROSALES - CIRCASIA - QUINDIO</option>
              <option value="63190020">CONDOMINIO QUINTAS DEL BOSQUE - CIRCASIA - QUINDIO</option>
              <option value="63190022">VILLA LIGIA - CIRCASIA - QUINDIO</option>
              <option value="63190024">LA CABAÑA - CIRCASIA - QUINDIO</option>
              <option value="63190028">CONDOMINIO MONTERREY - CIRCASIA - QUINDIO</option>
              <option value="63190030">CONDOMINIO ANGELES DEL BOSQUE - CIRCASIA - QUINDIO</option>
              <option value="63190031">CONDOMINIO CEDRO NEGRO - CIRCASIA - QUINDIO</option>
              <option value="63190032">CONDOMINIO RESERVAS DEL BOSQUE - CIRCASIA - QUINDIO</option>
              <option value="63190033">EL CONGAL - CIRCASIA - QUINDIO</option>
              <option value="63190034">LA CRISTALINA - CIRCASIA - QUINDIO</option>
              <option value="63190035">HACIENDA HORIZONTES - CIRCASIA - QUINDIO</option>
              <option value="63212000">CORDOBA - QUINDIO</option>
              <option value="63272000">FILANDIA - QUINDIO</option>
              <option value="63272002">LA INDIA - FILANDIA - QUINDIO</option>
              <option value="63302000">GENOVA - QUINDIO</option>
              <option value="63401000">LA TEBAIDA - QUINDIO</option>
              <option value="63401003">LA SILVIA - LA TEBAIDA - QUINDIO</option>
              <option value="63401004">FUNDACION AMANECER - LA TEBAIDA - QUINDIO</option>
              <option value="63401005">CONDOMINIO LA ESTACION - LA TEBAIDA - QUINDIO</option>
              <option value="63401006">CONDOMINIO MORACAWA - LA TEBAIDA - QUINDIO</option>
              <option value="63401007">MURILLO - LA TEBAIDA - QUINDIO</option>
              <option value="63470000">MONTENEGRO - QUINDIO</option>
              <option value="63470001">EL CUZCO - MONTENEGRO - QUINDIO</option>
              <option value="63470003">PUEBLO TAPADO - MONTENEGRO - QUINDIO</option>
              <option value="63470004">ONCE CASAS - MONTENEGRO - QUINDIO</option>
              <option value="63470007">EL CASTILLO - MONTENEGRO - QUINDIO</option>
              <option value="63470008">EL GIGANTE - MONTENEGRO - QUINDIO</option>
              <option value="63470009">BARAYA - MONTENEGRO - QUINDIO</option>
              <option value="63470010">LA MONTAÑA - MONTENEGRO - QUINDIO</option>
              <option value="63470013">CALLE LARGA - MONTENEGRO - QUINDIO</option>
              <option value="63470014">CONDOMINIO LA HACIENDA - MONTENEGRO - QUINDIO</option>
              <option value="63548000">PIJAO - QUINDIO</option>
              <option value="63548002">LA MARIELA - PIJAO - QUINDIO</option>
              <option value="63594000">QUIMBAYA - QUINDIO</option>
              <option value="63594002">EL LAUREL - QUIMBAYA - QUINDIO</option>
              <option value="63594003">LA ESPAÑOLA - QUIMBAYA - QUINDIO</option>
              <option value="63594005">PUEBLO RICO - QUIMBAYA - QUINDIO</option>
              <option value="63594006">PUERTO ALEJANDRIA - QUIMBAYA - QUINDIO</option>
              <option value="63594007">EL NARANJAL - QUIMBAYA - QUINDIO</option>
              <option value="63594009">CONDOMINIO CAMPESTRE INCAS PANACA - QUIMBAYA - QUINDIO</option>
              <option value="63690000">SALENTO - QUINDIO</option>
              <option value="63690001">BOQUIA - SALENTO - QUINDIO</option>
              <option value="63690005">LA EXPLANACION - SALENTO - QUINDIO</option>
              <option value="63690007">CONDOMINIO LAS COLINAS - SALENTO - QUINDIO</option>
              <option value="63690009">SAN JUAN DE CAROLINA - SALENTO - QUINDIO</option>
              <option value="63690010">SAN ANTONIO - SALENTO - QUINDIO</option>
              <option value="63690011">LOS PINOS - SALENTO - QUINDIO</option>
              <option value="66001000">PEREIRA - RISARALDA</option>
              <option value="66001001">ALTAGRACIA - PEREIRA - RISARALDA</option>
              <option value="66001002">ARABIA - PEREIRA - RISARALDA</option>
              <option value="66001003">BETULIA - PEREIRA - RISARALDA</option>
              <option value="66001004">CAIMALITO - PEREIRA - RISARALDA</option>
              <option value="66001005">EL PLACER (COMBIA) - PEREIRA - RISARALDA</option>
              <option value="66001006">LA CONVENCION - PEREIRA - RISARALDA</option>
              <option value="66001007">EL ROCIO - PEREIRA - RISARALDA</option>
              <option value="66001009">LA BELLA - PEREIRA - RISARALDA</option>
              <option value="66001010">LA FLORIDA - PEREIRA - RISARALDA</option>
              <option value="66001013">LA SELVA - PEREIRA - RISARALDA</option>
              <option value="66001015">MORELIA - PEREIRA - RISARALDA</option>
              <option value="66001016">MUNDO NUEVO - PEREIRA - RISARALDA</option>
              <option value="66001018">PUERTO CALDAS - PEREIRA - RISARALDA</option>
              <option value="66001020">SAN JOSE - PEREIRA - RISARALDA</option>
              <option value="66001021">TRIBUNAS CORCEGA - PEREIRA - RISARALDA</option>
              <option value="66001025">NUEVA SIRIA - PEREIRA - RISARALDA</option>
              <option value="66001026">SAN VICENTE - PEREIRA - RISARALDA</option>
              <option value="66001028">YARUMAL - PEREIRA - RISARALDA</option>
              <option value="66001029">LA BANANERA - PEREIRA - RISARALDA</option>
              <option value="66001030">ALTO ALEGRIAS - PEREIRA - RISARALDA</option>
              <option value="66001031">ALEGRIAS - PEREIRA - RISARALDA</option>
              <option value="66001032">PEREZ ALTO - PEREIRA - RISARALDA</option>
              <option value="66001035">HUERTAS - PEREIRA - RISARALDA</option>
              <option value="66001036">PITAL DE COMBIA - PEREIRA - RISARALDA</option>
              <option value="66001037">EL CHOCHO - PEREIRA - RISARALDA</option>
              <option value="66001039">BARRIO EL BOSQUE - PEREIRA - RISARALDA</option>
              <option value="66001040">LA CABAÑITA - PEREIRA - RISARALDA</option>
              <option value="66001041">BELMONTE BAJO - PEREIRA - RISARALDA</option>
              <option value="66001043">BETANIA - PEREIRA - RISARALDA</option>
              <option value="66001044">BRISAS DE CONDINA (LA GRAMINEA) - PEREIRA - RISARALDA</option>
              <option value="66001045">CALLE LARGA - PEREIRA - RISARALDA</option>
              <option value="66001046">CARACOL LA CURVA - PEREIRA - RISARALDA</option>
              <option value="66001047">CESTILLAL - PEREIRA - RISARALDA</option>
              <option value="66001048">EL CONTENTO - PEREIRA - RISARALDA</option>
              <option value="66001049">EL CRUCERO DE COMBIA - PEREIRA - RISARALDA</option>
              <option value="66001050">EL JAZMIN - PEREIRA - RISARALDA</option>
              <option value="66001051">EL MANZANO - PEREIRA - RISARALDA</option>
              <option value="66001052">EL PORVENIR - PEREIRA - RISARALDA</option>
              <option value="66001053">ESPERANZA GALICIA - PEREIRA - RISARALDA</option>
              <option value="66001054">ESTACION AZUFRAL - PEREIRA - RISARALDA</option>
              <option value="66001055">ESTRELLA MORRON - PEREIRA - RISARALDA</option>
              <option value="66001056">CONDINA GUACARY - PEREIRA - RISARALDA</option>
              <option value="66001057">LAGUNETA - PEREIRA - RISARALDA</option>
              <option value="66001058">LA ESTRELLA - PEREIRA - RISARALDA</option>
              <option value="66001059">NUEVO SOL - PEREIRA - RISARALDA</option>
              <option value="66001060">PLAN DE VIVIENDA LA UNION - PEREIRA - RISARALDA</option>
              <option value="66001061">PLAN DE VIVIENDA YARUMAL - PEREIRA - RISARALDA</option>
              <option value="66001062">PUEBLO NUEVO - PEREIRA - RISARALDA</option>
              <option value="66001063">SAN CARLOS - PEREIRA - RISARALDA</option>
              <option value="66001064">YARUMITO - PEREIRA - RISARALDA</option>
              <option value="66001065">PENJAMO - PEREIRA - RISARALDA</option>
              <option value="66001066">ALTO ERAZO - PEREIRA - RISARALDA</option>
              <option value="66001067">CANCELES - PEREIRA - RISARALDA</option>
              <option value="66001069">EL CONGOLO - PEREIRA - RISARALDA</option>
              <option value="66001070">EL JARDIN - PEREIRA - RISARALDA</option>
              <option value="66001071">ESTACION VILLEGAS - PEREIRA - RISARALDA</option>
              <option value="66001072">GALICIA ALTA - PEREIRA - RISARALDA</option>
              <option value="66001073">GILIPINAS - PEREIRA - RISARALDA</option>
              <option value="66001074">HERIBERTO HERRERA - PEREIRA - RISARALDA</option>
              <option value="66001075">LA CARBONERA - PEREIRA - RISARALDA</option>
              <option value="66001077">LA RENTA - PEREIRA - RISARALDA</option>
              <option value="66001078">LA SUIZA - PEREIRA - RISARALDA</option>
              <option value="66001079">LA YE - PEREIRA - RISARALDA</option>
              <option value="66001080">LIBARE - PEREIRA - RISARALDA</option>
              <option value="66001081">PEREZ BAJO - PEREIRA - RISARALDA</option>
              <option value="66001082">SAN MARINO - PEREIRA - RISARALDA</option>
              <option value="66001083">TRIBUNAS CONSOTA - PEREIRA - RISARALDA</option>
              <option value="66001084">EL JORDAN - PEREIRA - RISARALDA</option>
              <option value="66001085">SANTANDER - PEREIRA - RISARALDA</option>
              <option value="66001089">CONDOMINIO ANDALUZ - PEREIRA - RISARALDA</option>
              <option value="66001090">CONDOMINIO EL PARAISO - PEREIRA - RISARALDA</option>
              <option value="66001091">CONDOMINIO MACONDO - PEREIRA - RISARALDA</option>
              <option value="66001092">CONDOMINIO MARACAY - PEREIRA - RISARALDA</option>
              <option value="66001093">CONDOMINIO PALMAR - PEREIRA - RISARALDA</option>
              <option value="66001094">GAITAN LA PLAYA - PEREIRA - RISARALDA</option>
              <option value="66045000">APIA - RISARALDA</option>
              <option value="66045004">JORDANIA - APIA - RISARALDA</option>
              <option value="66045009">LA MARIA - APIA - RISARALDA</option>
              <option value="66075000">BALBOA - RISARALDA</option>
              <option value="66075004">TAMBORES - BALBOA - RISARALDA</option>
              <option value="66075005">SAN ANTONIO - BALBOA - RISARALDA</option>
              <option value="66075009">TRES ESQUINAS - BALBOA - RISARALDA</option>
              <option value="66088000">BELEN DE UMBRIA - RISARALDA</option>
              <option value="66088002">COLUMBIA - BELEN DE UMBRIA - RISARALDA</option>
              <option value="66088004">PUENTE UMBRIA - BELEN DE UMBRIA - RISARALDA</option>
              <option value="66088005">TAPARCAL - BELEN DE UMBRIA - RISARALDA</option>
              <option value="66088010">EL AGUACATE - BELEN DE UMBRIA - RISARALDA</option>
              <option value="66088011">EL CONGO - BELEN DE UMBRIA - RISARALDA</option>
              <option value="66170000">DOSQUEBRADAS - RISARALDA</option>
              <option value="66170001">ALTO DEL TORO - DOSQUEBRADAS - RISARALDA</option>
              <option value="66170006">LA UNION - DOSQUEBRADAS - RISARALDA</option>
              <option value="66170008">AGUAZUL - DOSQUEBRADAS - RISARALDA</option>
              <option value="66170009">BUENA VISTA - DOSQUEBRADAS - RISARALDA</option>
              <option value="66170010">COMUNEROS - DOSQUEBRADAS - RISARALDA</option>
              <option value="66170011">EL ESTANQUILLO - DOSQUEBRADAS - RISARALDA</option>
              <option value="66170014">LA ESMERALDA - DOSQUEBRADAS - RISARALDA</option>
              <option value="66170017">LA PLAYITA - DOSQUEBRADAS - RISARALDA</option>
              <option value="66170020">NARANJALES - DOSQUEBRADAS - RISARALDA</option>
              <option value="66170021">SANTANA BAJA - DOSQUEBRADAS - RISARALDA</option>
              <option value="66170022">VILLA CAROLA - DOSQUEBRADAS - RISARALDA</option>
              <option value="66170024">EL COFRE - DOSQUEBRADAS - RISARALDA</option>
              <option value="66170026">LA DIVISA PARTE ALTA - DOSQUEBRADAS - RISARALDA</option>
              <option value="66318000">GUATICA - RISARALDA</option>
              <option value="66318002">SAN CLEMENTE - GUATICA - RISARALDA</option>
              <option value="66318003">SANTA ANA - GUATICA - RISARALDA</option>
              <option value="66318006">TRAVESIAS - GUATICA - RISARALDA</option>
              <option value="66383000">LA CELIA - RISARALDA</option>
              <option value="66383001">PATIO BONITO - LA CELIA - RISARALDA</option>
              <option value="66400000">LA VIRGINIA - RISARALDA</option>
              <option value="66400001">LA PALMA - LA VIRGINIA - RISARALDA</option>
              <option value="66440000">MARSELLA - RISARALDA</option>
              <option value="66440001">ALTO CAUCA - MARSELLA - RISARALDA</option>
              <option value="66440002">BELTRAN - MARSELLA - RISARALDA</option>
              <option value="66440003">LA ARGENTINA - MARSELLA - RISARALDA</option>
              <option value="66440008">PLAN DE VIVIENDA EL RAYO - MARSELLA - RISARALDA</option>
              <option value="66440009">ESTACION PEREIRA - MARSELLA - RISARALDA</option>
              <option value="66440013">PLAN DE VIVIENDA TACURRUMBI - MARSELLA - RISARALDA</option>
              <option value="66456000">MISTRATO - RISARALDA</option>
              <option value="66456002">PUERTO DE ORO - MISTRATO - RISARALDA</option>
              <option value="66456003">SAN ANTONIO DEL CHAMI - MISTRATO - RISARALDA</option>
              <option value="66456005">ALTO PUEBLO RICO - MISTRATO - RISARALDA</option>
              <option value="66456008">MAMPAY - MISTRATO - RISARALDA</option>
              <option value="66456010">PINAR DEL RIO - MISTRATO - RISARALDA</option>
              <option value="66456011">QUEBRADA ARRIBA - MISTRATO - RISARALDA</option>
              <option value="66456012">RIO MISTRATO - MISTRATO - RISARALDA</option>
              <option value="66572000">PUEBLO RICO - RISARALDA</option>
              <option value="66572001">SANTA CECILIA - PUEBLO RICO - RISARALDA</option>
              <option value="66572002">VILLA CLARET - PUEBLO RICO - RISARALDA</option>
              <option value="66594000">QUINCHIA - RISARALDA</option>
              <option value="66594001">BATERO - QUINCHIA - RISARALDA</option>
              <option value="66594002">IRRA - QUINCHIA - RISARALDA</option>
              <option value="66594006">NARANJAL - QUINCHIA - RISARALDA</option>
              <option value="66594007">SANTA ELENA - QUINCHIA - RISARALDA</option>
              <option value="66594012">MORETA - QUINCHIA - RISARALDA</option>
              <option value="66594014">SAN JOSE - QUINCHIA - RISARALDA</option>
              <option value="66594019">VILLA RICA - QUINCHIA - RISARALDA</option>
              <option value="66682000">SANTA ROSA DE CABAL - RISARALDA</option>
              <option value="66682003">EL ESPAÑOL - SANTA ROSA DE CABAL - RISARALDA</option>
              <option value="66682006">GUACAS - SANTA ROSA DE CABAL - RISARALDA</option>
              <option value="66682008">LA CAPILLA - SANTA ROSA DE CABAL - RISARALDA</option>
              <option value="66682009">LA ESTRELLA - SANTA ROSA DE CABAL - RISARALDA</option>
              <option value="66682010">LAS MANGAS - SANTA ROSA DE CABAL - RISARALDA</option>
              <option value="66682012">SANTA BARBARA - SANTA ROSA DE CABAL - RISARALDA</option>
              <option value="66682016">GUAIMARAL - SANTA ROSA DE CABAL - RISARALDA</option>
              <option value="66682017">EL LEMBO - SANTA ROSA DE CABAL - RISARALDA</option>
              <option value="66682019">BAJO SAMARIA - SANTA ROSA DE CABAL - RISARALDA</option>
              <option value="66682020">LA FLORIDA - SANTA ROSA DE CABAL - RISARALDA</option>
              <option value="66682022">EL JAZMIN - SANTA ROSA DE CABAL - RISARALDA</option>
              <option value="66682024">LA LEONA - SANTA ROSA DE CABAL - RISARALDA</option>
              <option value="66682025">SAN JUANITO - SANTA ROSA DE CABAL - RISARALDA</option>
              <option value="66687000">SANTUARIO - RISARALDA</option>
              <option value="66687002">LA MARINA - SANTUARIO - RISARALDA</option>
              <option value="66687003">PERALONSO - SANTUARIO - RISARALDA</option>
              <option value="66687014">PLAYA RICA - SANTUARIO - RISARALDA</option>
              <option value="66687015">EL ROSAL - SANTUARIO - RISARALDA</option>
              <option value="68001000">BUCARAMANGA - SANTANDER</option>
              <option value="68001015">EL NOGAL - BUCARAMANGA - SANTANDER</option>
              <option value="68001016">EL PEDREGAL - BUCARAMANGA - SANTANDER</option>
              <option value="68001017">VIJAGUAL - BUCARAMANGA - SANTANDER</option>
              <option value="68001018">VILLA CARMELO - BUCARAMANGA - SANTANDER</option>
              <option value="68001019">VILLA LUZ - BUCARAMANGA - SANTANDER</option>
              <option value="68013000">AGUADA - SANTANDER</option>
              <option value="68020000">ALBANIA - SANTANDER</option>
              <option value="68020001">CARRETERO - ALBANIA - SANTANDER</option>
              <option value="68020002">EL HATILLO - ALBANIA - SANTANDER</option>
              <option value="68020003">LA MESA - ALBANIA - SANTANDER</option>
              <option value="68051000">ARATOCA - SANTANDER</option>
              <option value="68051001">CHIFLAS - ARATOCA - SANTANDER</option>
              <option value="68051012">EL HOYO - ARATOCA - SANTANDER</option>
              <option value="68051013">BRISAS DEL CHICAMOCHA - ARATOCA - SANTANDER</option>
              <option value="68077000">BARBOSA - SANTANDER</option>
              <option value="68077001">CITE - BARBOSA - SANTANDER</option>
              <option value="68077003">BUENAVISTA - BARBOSA - SANTANDER</option>
              <option value="68077005">CRISTALES - BARBOSA - SANTANDER</option>
              <option value="68077006">FRANCISCO DE PAULA - BARBOSA - SANTANDER</option>
              <option value="68079000">BARICHARA - SANTANDER</option>
              <option value="68079001">GUANE - BARICHARA - SANTANDER</option>
              <option value="68081000">BARRANCABERMEJA, DISTRITO ESPECIAL, PORTUARIO, BIODIVERSO, INDUSTRIAL Y TURISTICO - BARRANCABERMEJA - SANTANDER</option>
              <option value="68081001">EL CENTRO - BARRANCABERMEJA - SANTANDER</option>
              <option value="68081002">EL LLANITO - BARRANCABERMEJA - SANTANDER</option>
              <option value="68081004">MESETA SAN RAFAEL - BARRANCABERMEJA - SANTANDER</option>
              <option value="68081006">SAN RAFAEL DE CHUCURI - BARRANCABERMEJA - SANTANDER</option>
              <option value="68081009">LOS LAURELES - BARRANCABERMEJA - SANTANDER</option>
              <option value="68081010">LA FORTUNA - BARRANCABERMEJA - SANTANDER</option>
              <option value="68081012">CAMPO 16 - BARRANCABERMEJA - SANTANDER</option>
              <option value="68081013">CAMPO 23 - BARRANCABERMEJA - SANTANDER</option>
              <option value="68081015">CAMPO 6 - BARRANCABERMEJA - SANTANDER</option>
              <option value="68081016">CAMPO GALAN - BARRANCABERMEJA - SANTANDER</option>
              <option value="68081018">CIENAGA DE OPON - BARRANCABERMEJA - SANTANDER</option>
              <option value="68081020">CRETACEO - BARRANCABERMEJA - SANTANDER</option>
              <option value="68081023">GALAN BERLIN - BARRANCABERMEJA - SANTANDER</option>
              <option value="68081027">LA FOREST - BARRANCABERMEJA - SANTANDER</option>
              <option value="68081031">PROGRESO - BARRANCABERMEJA - SANTANDER</option>
              <option value="68081032">PUEBLO REGAO - BARRANCABERMEJA - SANTANDER</option>
              <option value="68081034">QUEMADERO - BARRANCABERMEJA - SANTANDER</option>
              <option value="68081036">EL PALMAR - BARRANCABERMEJA - SANTANDER</option>
              <option value="68092000">BETULIA - SANTANDER</option>
              <option value="68092008">TIENDA NUEVA - BETULIA - SANTANDER</option>
              <option value="68092010">LA PLAYA - BETULIA - SANTANDER</option>
              <option value="68092012">EL PEAJE - BETULIA - SANTANDER</option>
              <option value="68101000">BOLIVAR - SANTANDER</option>
              <option value="68101002">BERBEO - BOLIVAR - SANTANDER</option>
              <option value="68101007">FLOREZ - BOLIVAR - SANTANDER</option>
              <option value="68101010">SANTA ROSA - BOLIVAR - SANTANDER</option>
              <option value="68101012">TRAPAL - BOLIVAR - SANTANDER</option>
              <option value="68101014">LA HERMOSURA - BOLIVAR - SANTANDER</option>
              <option value="68101015">LA MELONA - BOLIVAR - SANTANDER</option>
              <option value="68101016">GALLEGOS - BOLIVAR - SANTANDER</option>
              <option value="68101025">SAN MARCOS - BOLIVAR - SANTANDER</option>
              <option value="68121000">CABRERA - SANTANDER</option>
              <option value="68132000">CALIFORNIA - SANTANDER</option>
              <option value="68132002">LA BAJA - CALIFORNIA - SANTANDER</option>
              <option value="68147000">CAPITANEJO - SANTANDER</option>
              <option value="68152000">CARCASI - SANTANDER</option>
              <option value="68152001">EL TOBAL - CARCASI - SANTANDER</option>
              <option value="68160000">CEPITA - SANTANDER</option>
              <option value="68162000">CERRITO - SANTANDER</option>
              <option value="68162007">SERVITA - CERRITO - SANTANDER</option>
              <option value="68167000">CHARALA - SANTANDER</option>
              <option value="68167003">RIACHUELO - CHARALA - SANTANDER</option>
              <option value="68167005">VIROLIN - CHARALA - SANTANDER</option>
              <option value="68169000">CHARTA - SANTANDER</option>
              <option value="68176000">CHIMA - SANTANDER</option>
              <option value="68179000">CHIPATA - SANTANDER</option>
              <option value="68179010">PUENTE GRANDE - CHIPATA - SANTANDER</option>
              <option value="68190000">CIMITARRA - SANTANDER</option>
              <option value="68190002">PUERTO ARAUJO - CIMITARRA - SANTANDER</option>
              <option value="68190003">PUERTO OLAYA - CIMITARRA - SANTANDER</option>
              <option value="68190004">ZAMBITO - CIMITARRA - SANTANDER</option>
              <option value="68190005">DOS HERMANOS - CIMITARRA - SANTANDER</option>
              <option value="68190006">SANTA ROSA - CIMITARRA - SANTANDER</option>
              <option value="68190009">LA VERDE - CIMITARRA - SANTANDER</option>
              <option value="68190010">GUAYABITO BAJO - CIMITARRA - SANTANDER</option>
              <option value="68190012">CAMPO SECO - CIMITARRA - SANTANDER</option>
              <option value="68190014">SAN FERNANDO - CIMITARRA - SANTANDER</option>
              <option value="68190018">EL ATERRADO - CIMITARRA - SANTANDER</option>
              <option value="68190019">LA CARRILERA - KM 28 - CIMITARRA - SANTANDER</option>
              <option value="68190021">PALMAS DEL GUAYABITO - CIMITARRA - SANTANDER</option>
              <option value="68190022">PRIMAVERA - CIMITARRA - SANTANDER</option>
              <option value="68190023">SAN JUAN DE LA CARRETERA - CIMITARRA - SANTANDER</option>
              <option value="68190024">SAN PEDRO DE LA PAZ - CIMITARRA - SANTANDER</option>
              <option value="68190025">CAMPO PADILLA - CIMITARRA - SANTANDER</option>
              <option value="68190028">CASCAJERO - CIMITARRA - SANTANDER</option>
              <option value="68190030">LA TRAVIATA - CIMITARRA - SANTANDER</option>
              <option value="68190032">LA YE DE LA TORRE - CIMITARRA - SANTANDER</option>
              <option value="68207000">CONCEPCION - SANTANDER</option>
              <option value="68209000">CONFINES - SANTANDER</option>
              <option value="68211000">CONTRATACION - SANTANDER</option>
              <option value="68211001">SAN PABLO - CONTRATACION - SANTANDER</option>
              <option value="68217000">COROMORO - SANTANDER</option>
              <option value="68217001">CINCELADA - COROMORO - SANTANDER</option>
              <option value="68229000">CURITI - SANTANDER</option>
              <option value="68235000">EL CARMEN DE CHUCURI - SANTANDER</option>
              <option value="68235001">ANGOSTURAS DE LOS ANDES - EL CARMEN DE CHUCURI - SANTANDER</option>
              <option value="68235004">EL CENTENARIO - EL CARMEN DE CHUCURI - SANTANDER</option>
              <option value="68235009">SANTO DOMINGO DEL RAMO - EL CARMEN DE CHUCURI - SANTANDER</option>
              <option value="68235017">LA EXPLANACION - EL CARMEN DE CHUCURI - SANTANDER</option>
              <option value="68235022">EL 27 - EL CARMEN DE CHUCURI - SANTANDER</option>
              <option value="68245000">EL GUACAMAYO - SANTANDER</option>
              <option value="68245003">SANTA RITA - EL GUACAMAYO - SANTANDER</option>
              <option value="68250000">EL PEÑON - SANTANDER</option>
              <option value="68250002">CRUCES - EL PEÑON - SANTANDER</option>
              <option value="68250003">OTOVAL - RIO BLANCO - EL PEÑON - SANTANDER</option>
              <option value="68250006">EL GODO - EL PEÑON - SANTANDER</option>
              <option value="68250007">GIRON - EL PEÑON - SANTANDER</option>
              <option value="68250009">SAN FRANCISCO - EL PEÑON - SANTANDER</option>
              <option value="68255000">EL PLAYON - SANTANDER</option>
              <option value="68255001">BARRIO NUEVO - EL PLAYON - SANTANDER</option>
              <option value="68255002">BETANIA - EL PLAYON - SANTANDER</option>
              <option value="68255007">ESTACION LAGUNA - EL PLAYON - SANTANDER</option>
              <option value="68255014">SAN PEDRO DE TIGRA - EL PLAYON - SANTANDER</option>
              <option value="68264000">ENCINO - SANTANDER</option>
              <option value="68266000">ENCISO - SANTANDER</option>
              <option value="68266002">PEÑA COLORADA - ENCISO - SANTANDER</option>
              <option value="68271000">FLORIAN - SANTANDER</option>
              <option value="68271001">LA VENTA - FLORIAN - SANTANDER</option>
              <option value="68271003">SAN ANTONIO DE LEONES - FLORIAN - SANTANDER</option>
              <option value="68276000">FLORIDABLANCA - SANTANDER</option>
              <option value="68276011">EL MORTIÑO - FLORIDABLANCA - SANTANDER</option>
              <option value="68276012">MONTEARROYO CONDOMINIO - FLORIDABLANCA - SANTANDER</option>
              <option value="68276013">RUITOQUE COUNTRY CLUB CONDOMINIO - FLORIDABLANCA - SANTANDER</option>
              <option value="68276014">VALLE DE RUITOQUE - FLORIDABLANCA - SANTANDER</option>
              <option value="68276016">VILLAS DE GUADALQUIVIR - FLORIDABLANCA - SANTANDER</option>
              <option value="68276018">ALTOS DE ORIENTE - FLORIDABLANCA - SANTANDER</option>
              <option value="68276019">BELLAVISTA - FLORIDABLANCA - SANTANDER</option>
              <option value="68276020">KM 14 - FLORIDABLANCA - SANTANDER</option>
              <option value="68276021">LA CIDRA - FLORIDABLANCA - SANTANDER</option>
              <option value="68276022">TRINITARIOS - FLORIDABLANCA - SANTANDER</option>
              <option value="68276023">CALATRAVA - FLORIDABLANCA - SANTANDER</option>
              <option value="68296000">GALAN - SANTANDER</option>
              <option value="68298000">GAMBITA - SANTANDER</option>
              <option value="68298002">LA PALMA - GAMBITA - SANTANDER</option>
              <option value="68307000">GIRON - SANTANDER</option>
              <option value="68307002">BOCAS - GIRON - SANTANDER</option>
              <option value="68307003">MARTA - GIRON - SANTANDER</option>
              <option value="68307007">ACAPULCO - GIRON - SANTANDER</option>
              <option value="68307018">CHOCOITA - GIRON - SANTANDER</option>
              <option value="68307020">EL LAGUITO - GIRON - SANTANDER</option>
              <option value="68307021">PUEBLITO VIEJO - GIRON - SANTANDER</option>
              <option value="68307022">RIO DE ORO - GIRON - SANTANDER</option>
              <option value="68318000">GUACA - SANTANDER</option>
              <option value="68318001">BARAYA - GUACA - SANTANDER</option>
              <option value="68320000">GUADALUPE - SANTANDER</option>
              <option value="68320005">EL TIRANO - GUADALUPE - SANTANDER</option>
              <option value="68322000">GUAPOTA - SANTANDER</option>
              <option value="68324000">GUAVATA - SANTANDER</option>
              <option value="68327000">GÜEPSA - SANTANDER</option>
              <option value="68344000">HATO - SANTANDER</option>
              <option value="68368000">JESUS MARIA - SANTANDER</option>
              <option value="68370000">JORDAN SUBE - JORDAN - SANTANDER</option>
              <option value="68377000">LA BELLEZA - SANTANDER</option>
              <option value="68377001">LA QUITAZ - LA BELLEZA - SANTANDER</option>
              <option value="68377003">EL RUBI - LA BELLEZA - SANTANDER</option>
              <option value="68377005">LOS VALLES - LA BELLEZA - SANTANDER</option>
              <option value="68385000">LANDAZURI - SANTANDER</option>
              <option value="68385001">BAJO JORDAN - LANDAZURI - SANTANDER</option>
              <option value="68385005">PLAN DE ARMAS - LANDAZURI - SANTANDER</option>
              <option value="68385006">SAN IGNACIO DEL OPON - LANDAZURI - SANTANDER</option>
              <option value="68385007">MIRALINDO - LANDAZURI - SANTANDER</option>
              <option value="68385008">KILOMETRO 15 - LANDAZURI - SANTANDER</option>
              <option value="68385009">LA INDIA - LANDAZURI - SANTANDER</option>
              <option value="68385014">RIO BLANCO - LANDAZURI - SANTANDER</option>
              <option value="68397000">LA PAZ - SANTANDER</option>
              <option value="68397001">EL HATO - LA PAZ - SANTANDER</option>
              <option value="68397002">LA LOMA - LA PAZ - SANTANDER</option>
              <option value="68397005">TROCHAL - LA PAZ - SANTANDER</option>
              <option value="68406000">LEBRIJA - SANTANDER</option>
              <option value="68406003">EL CONCHAL - LEBRIJA - SANTANDER</option>
              <option value="68406009">PORTUGAL - LEBRIJA - SANTANDER</option>
              <option value="68406013">URIBE URIBE - LEBRIJA - SANTANDER</option>
              <option value="68406014">VANEGAS - LEBRIJA - SANTANDER</option>
              <option value="68406026">CONDOMINIO VILLAS DE PALO NEGRO - LEBRIJA - SANTANDER</option>
              <option value="68418000">LOS SANTOS - SANTANDER</option>
              <option value="68418006">MAJADAL ALTO - LOS SANTOS - SANTANDER</option>
              <option value="68425000">MACARAVITA - SANTANDER</option>
              <option value="68425003">LA BRICHA - MACARAVITA - SANTANDER</option>
              <option value="68432000">MALAGA - SANTANDER</option>
              <option value="68432009">ASODEMA - MALAGA - SANTANDER</option>
              <option value="68444000">MATANZA - SANTANDER</option>
              <option value="68444006">SANTA CRUZ DE LA COLINA - MATANZA - SANTANDER</option>
              <option value="68464000">MOGOTES - SANTANDER</option>
              <option value="68464002">PITIGUAO - MOGOTES - SANTANDER</option>
              <option value="68464016">LOS CAUCHOS - MOGOTES - SANTANDER</option>
              <option value="68468000">MOLAGAVITA - SANTANDER</option>
              <option value="68468002">EL JUNCO - MOLAGAVITA - SANTANDER</option>
              <option value="68498000">OCAMONTE - SANTANDER</option>
              <option value="68500000">OIBA - SANTANDER</option>
              <option value="68500006">PUENTE LLANO - OIBA - SANTANDER</option>
              <option value="68502000">ONZAGA - SANTANDER</option>
              <option value="68502001">PADUA - ONZAGA - SANTANDER</option>
              <option value="68502002">SUSA - ONZAGA - SANTANDER</option>
              <option value="68502004">EL CARMEN - ONZAGA - SANTANDER</option>
              <option value="68522000">PALMAR - SANTANDER</option>
              <option value="68524000">PALMAS DEL SOCORRO - SANTANDER</option>
              <option value="68533000">PARAMO - SANTANDER</option>
              <option value="68547000">PIEDECUESTA - SANTANDER</option>
              <option value="68547003">SEVILLA - PIEDECUESTA - SANTANDER</option>
              <option value="68547007">UMPALA - PIEDECUESTA - SANTANDER</option>
              <option value="68547008">PESCADERO - PIEDECUESTA - SANTANDER</option>
              <option value="68547010">LA COLINA - PIEDECUESTA - SANTANDER</option>
              <option value="68547011">LOS CUROS - PIEDECUESTA - SANTANDER</option>
              <option value="68547016">CONDOMINIO RUITOQUE COUNTRY CLUB - PIEDECUESTA - SANTANDER</option>
              <option value="68547017">LA ESPERANZA - PIEDECUESTA - SANTANDER</option>
              <option value="68547018">BUENOS AIRES MESA RUITOQUE - PIEDECUESTA - SANTANDER</option>
              <option value="68547019">LA VEGA - PIEDECUESTA - SANTANDER</option>
              <option value="68547020">LA DIVA - PIEDECUESTA - SANTANDER</option>
              <option value="68547021">ALTAMIRA - PIEDECUESTA - SANTANDER</option>
              <option value="68547022">CAMPO CAMPIÑA - PIEDECUESTA - SANTANDER</option>
              <option value="68547023">CIUDAD TEYUNA - PIEDECUESTA - SANTANDER</option>
              <option value="68547024">EDEN - PIEDECUESTA - SANTANDER</option>
              <option value="68547025">MIRADOR DEL LAGO - PIEDECUESTA - SANTANDER</option>
              <option value="68547026">NUEVA COLOMBIA - PIEDECUESTA - SANTANDER</option>
              <option value="68547028">PARAMITO - PIEDECUESTA - SANTANDER</option>
              <option value="68549000">PINCHOTE - SANTANDER</option>
              <option value="68549003">BARRIO PORTAL DEL CONDE - PINCHOTE - SANTANDER</option>
              <option value="68572000">PUENTE NACIONAL - SANTANDER</option>
              <option value="68572002">CAPILLA - PUENTE NACIONAL - SANTANDER</option>
              <option value="68572003">PROVIDENCIA - PUENTE NACIONAL - SANTANDER</option>
              <option value="68572004">QUEBRADA NEGRA - PUENTE NACIONAL - SANTANDER</option>
              <option value="68572012">PEÑA BLANCA - PUENTE NACIONAL - SANTANDER</option>
              <option value="68573000">PUERTO PARRA - SANTANDER</option>
              <option value="68573001">CAMPO CAPOTE - PUERTO PARRA - SANTANDER</option>
              <option value="68573002">LAS MONTOYAS - PUERTO PARRA - SANTANDER</option>
              <option value="68573003">BOCAS DE CARARE O CARARE VIEJO - PUERTO PARRA - SANTANDER</option>
              <option value="68573005">EL CRUCE - PUERTO PARRA - SANTANDER</option>
              <option value="68575000">PUERTO WILCHES - SANTANDER</option>
              <option value="68575001">BADILLO - PUERTO WILCHES - SANTANDER</option>
              <option value="68575003">BOCAS ROSARIO - PUERTO WILCHES - SANTANDER</option>
              <option value="68575004">CARPINTERO - PUERTO WILCHES - SANTANDER</option>
              <option value="68575005">CHINGALE - PUERTO WILCHES - SANTANDER</option>
              <option value="68575006">EL GUAYABO - PUERTO WILCHES - SANTANDER</option>
              <option value="68575007">EL PEDRAL - PUERTO WILCHES - SANTANDER</option>
              <option value="68575011">KILOMETRO 20 - COMUNEROS - PUERTO WILCHES - SANTANDER</option>
              <option value="68575013">PATURIA - PUERTO WILCHES - SANTANDER</option>
              <option value="68575014">PRADILLA - PUERTO WILCHES - SANTANDER</option>
              <option value="68575015">PUENTE SOGAMOSO - PUERTO WILCHES - SANTANDER</option>
              <option value="68575018">VIJAGUAL - PUERTO WILCHES - SANTANDER</option>
              <option value="68575019">PUERTO CAYUMBA - PUERTO WILCHES - SANTANDER</option>
              <option value="68575022">SITIO NUEVO - PUERTO WILCHES - SANTANDER</option>
              <option value="68575023">KILOMETRO OCHO - PUERTO WILCHES - SANTANDER</option>
              <option value="68575024">SAN CLAVER - PUERTO WILCHES - SANTANDER</option>
              <option value="68575025">GARCIA CADENA - PUERTO WILCHES - SANTANDER</option>
              <option value="68575026">CAMPO ALEGRE - PUERTO WILCHES - SANTANDER</option>
              <option value="68575027">CAMPO DURO - PUERTO WILCHES - SANTANDER</option>
              <option value="68575028">CURUMITA - PUERTO WILCHES - SANTANDER</option>
              <option value="68575029">SANTA TERESA - PUERTO WILCHES - SANTANDER</option>
              <option value="68575030">TALADRO II - PUERTO WILCHES - SANTANDER</option>
              <option value="68575031">INVASION LA INDEPENDENCIA - PUERTO WILCHES - SANTANDER</option>
              <option value="68615000">RIONEGRO - SANTANDER</option>
              <option value="68615002">CUESTA RICA - RIONEGRO - SANTANDER</option>
              <option value="68615009">LA CEIBA - RIONEGRO - SANTANDER</option>
              <option value="68615011">LLANO DE PALMAS - RIONEGRO - SANTANDER</option>
              <option value="68615012">MISIJUAY - RIONEGRO - SANTANDER</option>
              <option value="68615013">PAPAYAL - RIONEGRO - SANTANDER</option>
              <option value="68615017">VEINTE DE JULIO - RIONEGRO - SANTANDER</option>
              <option value="68615027">LOS CHORROS (SAN JOSE) - RIONEGRO - SANTANDER</option>
              <option value="68615031">SAN RAFAEL - RIONEGRO - SANTANDER</option>
              <option value="68615036">EL BAMBU - RIONEGRO - SANTANDER</option>
              <option value="68655000">SABANA DE TORRES - SANTANDER</option>
              <option value="68655001">LA GOMEZ - SABANA DE TORRES - SANTANDER</option>
              <option value="68655002">SABANETA - SABANA DE TORRES - SANTANDER</option>
              <option value="68655004">PROVINCIA - SABANA DE TORRES - SANTANDER</option>
              <option value="68655006">VERACRUZ KILOMETRO 80 - SABANA DE TORRES - SANTANDER</option>
              <option value="68655007">SAN LUIS DE MAGARA - SABANA DE TORRES - SANTANDER</option>
              <option value="68655008">PAYOA CINCO - SABANA DE TORRES - SANTANDER</option>
              <option value="68655009">PUERTO SANTOS - SABANA DE TORRES - SANTANDER</option>
              <option value="68655011">CERRITO - SABANA DE TORRES - SANTANDER</option>
              <option value="68655013">KILOMETRO 36 - SABANA DE TORRES - SANTANDER</option>
              <option value="68655014">LA PAMPA - SABANA DE TORRES - SANTANDER</option>
              <option value="68655015">SAN LUIS DE RIO SUCIO - SABANA DE TORRES - SANTANDER</option>
              <option value="68669000">SAN ANDRES - SANTANDER</option>
              <option value="68669005">LAGUNA DE ORTICES - SAN ANDRES - SANTANDER</option>
              <option value="68669007">PANGOTE - SAN ANDRES - SANTANDER</option>
              <option value="68673000">SAN BENITO - SANTANDER</option>
              <option value="68673003">SAN BENITO NUEVO - SAN BENITO - SANTANDER</option>
              <option value="68673004">LA CARRERA - SAN BENITO - SANTANDER</option>
              <option value="68673005">LAS CASITAS - SAN BENITO - SANTANDER</option>
              <option value="68679000">SAN GIL - SANTANDER</option>
              <option value="68682000">SAN JOAQUIN - SANTANDER</option>
              <option value="68682001">RICAURTE - SAN JOAQUIN - SANTANDER</option>
              <option value="68684000">SAN JOSE DE MIRANDA - SANTANDER</option>
              <option value="68684009">VILLA JARDIN - SAN JOSE DE MIRANDA - SANTANDER</option>
              <option value="68686000">SAN MIGUEL - SANTANDER</option>
              <option value="68689000">SAN VICENTE DE CHUCURI - SANTANDER</option>
              <option value="68689001">ALBANIA - SAN VICENTE DE CHUCURI - SANTANDER</option>
              <option value="68689012">YARIMA - SAN VICENTE DE CHUCURI - SANTANDER</option>
              <option value="68689028">LAS ACACIAS - SAN VICENTE DE CHUCURI - SANTANDER</option>
              <option value="68705000">SANTA BARBARA - SANTANDER</option>
              <option value="68720000">SANTA HELENA DEL OPON - SANTANDER</option>
              <option value="68720001">LA ARAGUA - SANTA HELENA DEL OPON - SANTANDER</option>
              <option value="68720002">CACHIPAY - SANTA HELENA DEL OPON - SANTANDER</option>
              <option value="68720004">PLAN DE ALVAREZ - SANTA HELENA DEL OPON - SANTANDER</option>
              <option value="68720005">SAN JUAN BOSCO DE LA VERDE - SANTA HELENA DEL OPON - SANTANDER</option>
              <option value="68745000">SIMACOTA - SANTANDER</option>
              <option value="68745006">LA LLANITA - SIMACOTA - SANTANDER</option>
              <option value="68745009">LA ROCHELA - SIMACOTA - SANTANDER</option>
              <option value="68745011">EL GUAMO - SIMACOTA - SANTANDER</option>
              <option value="68745012">PUERTO NUEVO - SIMACOTA - SANTANDER</option>
              <option value="68755000">SOCORRO - SANTANDER</option>
              <option value="68755002">BERLIN - SOCORRO - SANTANDER</option>
              <option value="68770000">SUAITA - SANTANDER</option>
              <option value="68770001">OLIVAL - SUAITA - SANTANDER</option>
              <option value="68770002">SAN JOSE DE SUAITA - SUAITA - SANTANDER</option>
              <option value="68770003">VADO REAL - SUAITA - SANTANDER</option>
              <option value="68770008">TOLOTA - SUAITA - SANTANDER</option>
              <option value="68773000">SUCRE - SANTANDER</option>
              <option value="68773001">LA GRANJA - SUCRE - SANTANDER</option>
              <option value="68773002">LA PRADERA - SUCRE - SANTANDER</option>
              <option value="68773003">SABANA GRANDE - SUCRE - SANTANDER</option>
              <option value="68773014">SAN ISIDRO - SUCRE - SANTANDER</option>
              <option value="68773015">EL LIBANO - SUCRE - SANTANDER</option>
              <option value="68780000">SURATA - SANTANDER</option>
              <option value="68780001">CACHIRI - SURATA - SANTANDER</option>
              <option value="68780008">TURBAY - SURATA - SANTANDER</option>
              <option value="68820000">TONA - SANTANDER</option>
              <option value="68820001">BERLIN - TONA - SANTANDER</option>
              <option value="68820002">LA CORCOVA - TONA - SANTANDER</option>
              <option value="68820007">VILLAVERDE - TONA - SANTANDER</option>
              <option value="68855000">VALLE DE SAN JOSE - SANTANDER</option>
              <option value="68861000">VELEZ - SANTANDER</option>
              <option value="68861001">ALTO JORDAN - VELEZ - SANTANDER</option>
              <option value="68861002">GUALILO - VELEZ - SANTANDER</option>
              <option value="68861017">EL LIMON - VELEZ - SANTANDER</option>
              <option value="68861022">LOMALTA - VELEZ - SANTANDER</option>
              <option value="68861024">LOS GUAYABOS - VELEZ - SANTANDER</option>
              <option value="68861025">LA VICINIA - VELEZ - SANTANDER</option>
              <option value="68861026">PENA BLANCA - VELEZ - SANTANDER</option>
              <option value="68867000">VETAS - SANTANDER</option>
              <option value="68872000">VILLANUEVA - SANTANDER</option>
              <option value="68895000">ZAPATOCA - SANTANDER</option>
              <option value="68895001">LA FUENTE - ZAPATOCA - SANTANDER</option>
              <option value="70001000">SINCELEJO - SUCRE</option>
              <option value="70001001">BUENAVISTA - SINCELEJO - SUCRE</option>
              <option value="70001002">CRUZ DEL BEQUE - SINCELEJO - SUCRE</option>
              <option value="70001003">CHOCHO - SINCELEJO - SUCRE</option>
              <option value="70001004">CERRITO DE LA PALMA - SINCELEJO - SUCRE</option>
              <option value="70001005">LA ARENA - SINCELEJO - SUCRE</option>
              <option value="70001006">LA CHIVERA - SINCELEJO - SUCRE</option>
              <option value="70001007">LA GALLERA - SINCELEJO - SUCRE</option>
              <option value="70001009">LAGUNA FLOR - SINCELEJO - SUCRE</option>
              <option value="70001011">LAS MAJAGUAS - SINCELEJO - SUCRE</option>
              <option value="70001013">SABANAS DEL POTRERO - SINCELEJO - SUCRE</option>
              <option value="70001014">SAN ANTONIO - SINCELEJO - SUCRE</option>
              <option value="70001015">SAN MARTIN - SINCELEJO - SUCRE</option>
              <option value="70001016">SAN RAFAEL - SINCELEJO - SUCRE</option>
              <option value="70001017">BUENAVISTICA - SINCELEJO - SUCRE</option>
              <option value="70001020">POLICARPA - SINCELEJO - SUCRE</option>
              <option value="70001021">SAN JACINTO - SINCELEJO - SUCRE</option>
              <option value="70001022">SAN NICOLAS - SINCELEJO - SUCRE</option>
              <option value="70001023">VILLA ROSITA - SINCELEJO - SUCRE</option>
              <option value="70001027">CERRO DEL NARANJO - SINCELEJO - SUCRE</option>
              <option value="70110000">BUENAVISTA - SUCRE</option>
              <option value="70110004">LAS CHICHAS - BUENAVISTA - SUCRE</option>
              <option value="70110005">LOS ANONES - BUENAVISTA - SUCRE</option>
              <option value="70110006">PROVIDENCIA - BUENAVISTA - SUCRE</option>
              <option value="70110007">COSTA RICA - BUENAVISTA - SUCRE</option>
              <option value="70124000">CAIMITO - SUCRE</option>
              <option value="70124001">EL MAMON - CAIMITO - SUCRE</option>
              <option value="70124002">SIETE PALMAS - CAIMITO - SUCRE</option>
              <option value="70124004">LOS CAYITOS - CAIMITO - SUCRE</option>
              <option value="70124006">LA SOLERA - CAIMITO - SUCRE</option>
              <option value="70124007">TOFEME - CAIMITO - SUCRE</option>
              <option value="70124008">CEDEÑO - CAIMITO - SUCRE</option>
              <option value="70124009">MOLINERO - CAIMITO - SUCRE</option>
              <option value="70124010">NUEVA ESTACION - CAIMITO - SUCRE</option>
              <option value="70124011">LA MEJIA - CAIMITO - SUCRE</option>
              <option value="70124012">LAS PAVITAS - CAIMITO - SUCRE</option>
              <option value="70124013">NUEVA ESTRELLA - CAIMITO - SUCRE</option>
              <option value="70124014">NUEVA FE - CAIMITO - SUCRE</option>
              <option value="70124015">POMPUMA - CAIMITO - SUCRE</option>
              <option value="70124016">AGUILAR - CAIMITO - SUCRE</option>
              <option value="70124017">LOS OSSAS - CAIMITO - SUCRE</option>
              <option value="70124018">PUEBLO BUHO - CAIMITO - SUCRE</option>
              <option value="70124019">PUEBLO NUEVO - CAIMITO - SUCRE</option>
              <option value="70124020">PUNTA ALFEREZ - CAIMITO - SUCRE</option>
              <option value="70124021">TANGA SOLA - CAIMITO - SUCRE</option>
              <option value="70204000">RICAURTE (COLOSO) - COLOSO - SUCRE</option>
              <option value="70204002">CHINULITO - COLOSO - SUCRE</option>
              <option value="70204005">BAJO DON JUAN - COLOSO - SUCRE</option>
              <option value="70204006">EL OJITO - COLOSO - SUCRE</option>
              <option value="70204008">CALLE LARGA - COLOSO - SUCRE</option>
              <option value="70204009">CORAZA - COLOSO - SUCRE</option>
              <option value="70204010">DESBARRANCADO - COLOSO - SUCRE</option>
              <option value="70204011">EL PARAISO - COLOSO - SUCRE</option>
              <option value="70204012">LA CEIBA - COLOSO - SUCRE</option>
              <option value="70204013">LA ESTACION - COLOSO - SUCRE</option>
              <option value="70204014">MARATHON - COLOSO - SUCRE</option>
              <option value="70204015">PUEBLO NUEVO - COLOSO - SUCRE</option>
              <option value="70215000">COROZAL - SUCRE</option>
              <option value="70215001">CANTAGALLO - COROZAL - SUCRE</option>
              <option value="70215004">CHAPINERO - COROZAL - SUCRE</option>
              <option value="70215005">DON ALONSO - COROZAL - SUCRE</option>
              <option value="70215006">EL MAMON - COROZAL - SUCRE</option>
              <option value="70215009">HATO NUEVO - COROZAL - SUCRE</option>
              <option value="70215010">LAS LLANADAS - COROZAL - SUCRE</option>
              <option value="70215011">LAS TINAS - COROZAL - SUCRE</option>
              <option value="70215014">SAN JOSE DE PILETA - COROZAL - SUCRE</option>
              <option value="70215016">EL RINCON DE LAS FLORES - COROZAL - SUCRE</option>
              <option value="70215018">LAS PEÑAS - COROZAL - SUCRE</option>
              <option value="70215019">CALLE NUEVA - COROZAL - SUCRE</option>
              <option value="70215021">LAS BRUJAS - COROZAL - SUCRE</option>
              <option value="70215022">VILLA NUEVA - COROZAL - SUCRE</option>
              <option value="70215023">MILAN - COROZAL - SUCRE</option>
              <option value="70215024">PALMA SOLA - COROZAL - SUCRE</option>
              <option value="70215025">LAS PALMAS - COROZAL - SUCRE</option>
              <option value="70215026">HATO VIEJO - COROZAL - SUCRE</option>
              <option value="70221000">COVEÑAS - SUCRE</option>
              <option value="70221001">BOCA DE LA CIENAGA - COVEÑAS - SUCRE</option>
              <option value="70221002">EL REPARO - COVEÑAS - SUCRE</option>
              <option value="70221004">PUNTA SECA - COVEÑAS - SUCRE</option>
              <option value="70221005">EL MAMEY - COVEÑAS - SUCRE</option>
              <option value="70221006">BELLAVISTA - COVEÑAS - SUCRE</option>
              <option value="70230000">CHALAN - SUCRE</option>
              <option value="70230001">LA CEIBA - CHALAN - SUCRE</option>
              <option value="70230003">NUEVO MANZANARES - CHALAN - SUCRE</option>
              <option value="70230004">DESBARRANCADO - CHALAN - SUCRE</option>
              <option value="70230006">MONTEBELLO - CHALAN - SUCRE</option>
              <option value="70233000">EL ROBLE - SUCRE</option>
              <option value="70233001">CALLEJON - EL ROBLE - SUCRE</option>
              <option value="70233002">CAYO DE PALMA - EL ROBLE - SUCRE</option>
              <option value="70233003">CORNETA - EL ROBLE - SUCRE</option>
              <option value="70233004">EL SITIO - EL ROBLE - SUCRE</option>
              <option value="70233005">LAS TABLITAS - EL ROBLE - SUCRE</option>
              <option value="70233006">PALMITAL - EL ROBLE - SUCRE</option>
              <option value="70233007">PATILLAL - EL ROBLE - SUCRE</option>
              <option value="70233008">GRILLO ALEGRE - EL ROBLE - SUCRE</option>
              <option value="70233009">RANCHO DE LA CRUZ - EL ROBLE - SUCRE</option>
              <option value="70233010">SAN FRANCISCO - EL ROBLE - SUCRE</option>
              <option value="70233011">SANTA ROSA - EL ROBLE - SUCRE</option>
              <option value="70233012">TIERRA SANTA - EL ROBLE - SUCRE</option>
              <option value="70233013">VILLAVICENCIO - EL ROBLE - SUCRE</option>
              <option value="70235000">GALERAS - SUCRE</option>
              <option value="70235001">BARAYA - GALERAS - SUCRE</option>
              <option value="70235002">SAN ANDRES DE PALOMO - GALERAS - SUCRE</option>
              <option value="70235003">SAN JOSE DE RIVERA - GALERAS - SUCRE</option>
              <option value="70235007">PUEBLO NUEVO II - GALERAS - SUCRE</option>
              <option value="70235009">PUEBLO NUEVO I (JUNIN) - GALERAS - SUCRE</option>
              <option value="70235011">PUERTO FRANCO - GALERAS - SUCRE</option>
              <option value="70235012">ABRE EL OJO - GALERAS - SUCRE</option>
              <option value="70235013">MATA DE GUASIMO - GALERAS - SUCRE</option>
              <option value="70265000">GUARANDA - SUCRE</option>
              <option value="70265002">DIAZGRANADOS - GUARANDA - SUCRE</option>
              <option value="70265004">GAVALDA - GUARANDA - SUCRE</option>
              <option value="70265006">LA CONCORDIA - GUARANDA - SUCRE</option>
              <option value="70265008">PALMARITICO - GUARANDA - SUCRE</option>
              <option value="70265009">PUERTO LOPEZ - GUARANDA - SUCRE</option>
              <option value="70265010">LA CEJA - GUARANDA - SUCRE</option>
              <option value="70265011">LAS PAVAS - GUARANDA - SUCRE</option>
              <option value="70265012">NUEVA ESPERANZA - GUARANDA - SUCRE</option>
              <option value="70265013">TIERRA SANTA - GUARANDA - SUCRE</option>
              <option value="70400000">LA UNION - SUCRE</option>
              <option value="70400001">CAYO DELGADO - LA UNION - SUCRE</option>
              <option value="70400002">PAJARITO - LA UNION - SUCRE</option>
              <option value="70400004">LAS PALMITAS - LA UNION - SUCRE</option>
              <option value="70400005">SABANETA - LA UNION - SUCRE</option>
              <option value="70400006">BOCA NEGRA - LA UNION - SUCRE</option>
              <option value="70400007">CONGUITOS - LA UNION - SUCRE</option>
              <option value="70400008">LA GLORIA - LA UNION - SUCRE</option>
              <option value="70400009">VILLA FATIMA - LA UNION - SUCRE</option>
              <option value="70400010">LA CONCEPCION - LA UNION - SUCRE</option>
              <option value="70418000">LOS PALMITOS - SUCRE</option>
              <option value="70418001">EL COLEY - LOS PALMITOS - SUCRE</option>
              <option value="70418002">EL PIÑAL - LOS PALMITOS - SUCRE</option>
              <option value="70418003">PALMAS DE VINO - LOS PALMITOS - SUCRE</option>
              <option value="70418004">SABANAS DE BELTRAN - LOS PALMITOS - SUCRE</option>
              <option value="70418005">SABANAS DE PEDRO - LOS PALMITOS - SUCRE</option>
              <option value="70418006">EL HATILLO - LOS PALMITOS - SUCRE</option>
              <option value="70418010">CHARCON - LOS PALMITOS - SUCRE</option>
              <option value="70418011">PALMITO - LOS PALMITOS - SUCRE</option>
              <option value="70418012">SAN JAIME - LOS PALMITOS - SUCRE</option>
              <option value="70429000">MAJAGUAL - SUCRE</option>
              <option value="70429002">EL NARANJO - MAJAGUAL - SUCRE</option>
              <option value="70429005">LA SIERPE - MAJAGUAL - SUCRE</option>
              <option value="70429006">LAS PALMITAS - MAJAGUAL - SUCRE</option>
              <option value="70429008">PIZA - MAJAGUAL - SUCRE</option>
              <option value="70429009">PUEBLONUEVO - MAJAGUAL - SUCRE</option>
              <option value="70429010">SAN ROQUE - MAJAGUAL - SUCRE</option>
              <option value="70429011">SANTANDER - MAJAGUAL - SUCRE</option>
              <option value="70429013">ZAPATA - MAJAGUAL - SUCRE</option>
              <option value="70429014">SINCELEJITO - MAJAGUAL - SUCRE</option>
              <option value="70429016">LEON BLANCO - MAJAGUAL - SUCRE</option>
              <option value="70429023">LOS PATOS - MAJAGUAL - SUCRE</option>
              <option value="70429025">PALMARITO - MAJAGUAL - SUCRE</option>
              <option value="70429026">BOCA DE LAS MUJERES - MAJAGUAL - SUCRE</option>
              <option value="70429027">EL INDIO NUEVO - MAJAGUAL - SUCRE</option>
              <option value="70429028">EL PALOMAR - MAJAGUAL - SUCRE</option>
              <option value="70429029">RIO FRIO - MAJAGUAL - SUCRE</option>
              <option value="70429031">CORONCORO - MAJAGUAL - SUCRE</option>
              <option value="70473000">MORROA - SUCRE</option>
              <option value="70473002">EL RINCON - MORROA - SUCRE</option>
              <option value="70473003">EL YESO - MORROA - SUCRE</option>
              <option value="70473004">LAS FLORES - MORROA - SUCRE</option>
              <option value="70473005">SABANETA - MORROA - SUCRE</option>
              <option value="70473006">TUMBATORO - MORROA - SUCRE</option>
              <option value="70473009">SABANAS DE CALI - MORROA - SUCRE</option>
              <option value="70473011">BREMEN - MORROA - SUCRE</option>
              <option value="70473012">EL RECREO - MORROA - SUCRE</option>
              <option value="70473013">EL TOLIMA - MORROA - SUCRE</option>
              <option value="70473014">LA VICTORIA - MORROA - SUCRE</option>
              <option value="70473015">PICHILIN - MORROA - SUCRE</option>
              <option value="70508000">OVEJAS - SUCRE</option>
              <option value="70508001">ALMAGRA - OVEJAS - SUCRE</option>
              <option value="70508002">CANUTAL - OVEJAS - SUCRE</option>
              <option value="70508003">CANUTALITO - OVEJAS - SUCRE</option>
              <option value="70508004">CHENGUE - OVEJAS - SUCRE</option>
              <option value="70508005">DAMASCO - OVEJAS - SUCRE</option>
              <option value="70508006">DON GABRIEL - OVEJAS - SUCRE</option>
              <option value="70508007">EL FLORAL - OVEJAS - SUCRE</option>
              <option value="70508009">FLOR DEL MONTE - OVEJAS - SUCRE</option>
              <option value="70508011">LA PEÑA - OVEJAS - SUCRE</option>
              <option value="70508012">OSOS - OVEJAS - SUCRE</option>
              <option value="70508013">PIJIGUAY - OVEJAS - SUCRE</option>
              <option value="70508014">SAN RAFAEL - OVEJAS - SUCRE</option>
              <option value="70508015">SALITRAL - OVEJAS - SUCRE</option>
              <option value="70508018">SAN RAFAEL ALTO - OVEJAS - SUCRE</option>
              <option value="70508026">PEDREGAL - OVEJAS - SUCRE</option>
              <option value="70508027">SAN FRANCISCO - OVEJAS - SUCRE</option>
              <option value="70508031">ZAPATO # 2 PIJIGUAY - OVEJAS - SUCRE</option>
              <option value="70508033">BUENOS AIRES - OVEJAS - SUCRE</option>
              <option value="70508034">ALEMANIA - OVEJAS - SUCRE</option>
              <option value="70523000">PALMITO - SUCRE</option>
              <option value="70523001">ALGODONCILLO - PALMITO - SUCRE</option>
              <option value="70523003">GUAMI - PALMITO - SUCRE</option>
              <option value="70523006">CHUMPUNDUN - PALMITO - SUCRE</option>
              <option value="70523007">EL MARTILLO - PALMITO - SUCRE</option>
              <option value="70523008">EL PALMAR BRILLANTE - PALMITO - SUCRE</option>
              <option value="70523010">LOS CASTILLOS - PALMITO - SUCRE</option>
              <option value="70523011">MEDIA SOMBRA - PALMITO - SUCRE</option>
              <option value="70523012">PUEBLECITO - PALMITO - SUCRE</option>
              <option value="70523013">PUEBLO NUEVO - PALMITO - SUCRE</option>
              <option value="70523014">SAN MIGUEL - PALMITO - SUCRE</option>
              <option value="70523017">LA GRANVIA - PALMITO - SUCRE</option>
              <option value="70523018">LOS OLIVOS - PALMITO - SUCRE</option>
              <option value="70523020">LAS HUERTAS - PALMITO - SUCRE</option>
              <option value="70670000">SAMPUES - SUCRE</option>
              <option value="70670001">BOSSA NAVARRO - SAMPUES - SUCRE</option>
              <option value="70670002">CEJA DEL MANGO - SAMPUES - SUCRE</option>
              <option value="70670003">ESCOBAR ABAJO - SAMPUES - SUCRE</option>
              <option value="70670004">ESCOBAR ARRIBA - SAMPUES - SUCRE</option>
              <option value="70670007">MATEO PEREZ - SAMPUES - SUCRE</option>
              <option value="70670008">SANTA INES DE PALITO - SAMPUES - SUCRE</option>
              <option value="70670009">PIEDRAS BLANCAS - SAMPUES - SUCRE</option>
              <option value="70670010">SABANALARGA - SAMPUES - SUCRE</option>
              <option value="70670011">SAN LUIS - SAMPUES - SUCRE</option>
              <option value="70670012">SEGOVIA - SAMPUES - SUCRE</option>
              <option value="70670013">ACHIOTE ARRIBA - SAMPUES - SUCRE</option>
              <option value="70670018">MATA DE CAÑA - SAMPUES - SUCRE</option>
              <option value="70670023">ACHIOTE ABAJO - SAMPUES - SUCRE</option>
              <option value="70670024">SANTA TERESA - SAMPUES - SUCRE</option>
              <option value="70678000">SAN BENITO ABAD - SUCRE</option>
              <option value="70678002">CUIVA - SAN BENITO ABAD - SUCRE</option>
              <option value="70678003">JEGUA - SAN BENITO ABAD - SUCRE</option>
              <option value="70678004">LA CEIBA - SAN BENITO ABAD - SUCRE</option>
              <option value="70678007">PUNTA DE BLANCO - SAN BENITO ABAD - SUCRE</option>
              <option value="70678009">SAN ROQUE - SAN BENITO ABAD - SUCRE</option>
              <option value="70678010">SANTIAGO APOSTOL - SAN BENITO ABAD - SUCRE</option>
              <option value="70678011">DOÑA ANA - SAN BENITO ABAD - SUCRE</option>
              <option value="70678012">GUAYABAL - SAN BENITO ABAD - SUCRE</option>
              <option value="70678013">EL LIMON - SAN BENITO ABAD - SUCRE</option>
              <option value="70678015">LA VENTURA - SAN BENITO ABAD - SUCRE</option>
              <option value="70678019">HONDURAS - SAN BENITO ABAD - SUCRE</option>
              <option value="70678020">PUNTA NUEVA - SAN BENITO ABAD - SUCRE</option>
              <option value="70678025">CISPATACA - SAN BENITO ABAD - SUCRE</option>
              <option value="70678026">SAN ISIDRO - SAN BENITO ABAD - SUCRE</option>
              <option value="70678027">VILLA NUEVA - SAN BENITO ABAD - SUCRE</option>
              <option value="70678028">CORRAL VIEJO - LOS ANGELES - SAN BENITO ABAD - SUCRE</option>
              <option value="70678032">LA MOLINA - SAN BENITO ABAD - SUCRE</option>
              <option value="70678033">LAS CHISPAS - SAN BENITO ABAD - SUCRE</option>
              <option value="70678034">RANCHO LA TIA - SAN BENITO ABAD - SUCRE</option>
              <option value="70678035">CALLE NUEVA - SAN BENITO ABAD - SUCRE</option>
              <option value="70678036">EMPRESA COLOMBIA - SAN BENITO ABAD - SUCRE</option>
              <option value="70678037">LA PLAZA - SAN BENITO ABAD - SUCRE</option>
              <option value="70678038">LAS POZAS - SAN BENITO ABAD - SUCRE</option>
              <option value="70678039">REMOLINO - SAN BENITO ABAD - SUCRE</option>
              <option value="70702000">BETULIA - SAN JUAN DE BETULIA - SUCRE</option>
              <option value="70702001">ALBANIA - SAN JUAN DE BETULIA - SUCRE</option>
              <option value="70702004">SABANETA - SAN JUAN DE BETULIA - SUCRE</option>
              <option value="70702005">VILLA LOPEZ - SAN JUAN DE BETULIA - SUCRE</option>
              <option value="70702006">LAS CRUCES - SAN JUAN DE BETULIA - SUCRE</option>
              <option value="70702007">LOMA ALTA - SAN JUAN DE BETULIA - SUCRE</option>
              <option value="70702008">SANTO TOMAS - SAN JUAN DE BETULIA - SUCRE</option>
              <option value="70702009">EL SOCORRO - SAN JUAN DE BETULIA - SUCRE</option>
              <option value="70702010">GARRAPATERO - SAN JUAN DE BETULIA - SUCRE</option>
              <option value="70702011">LOMA DEL LATIGO - SAN JUAN DE BETULIA - SUCRE</option>
              <option value="70708000">SAN MARCOS - SUCRE</option>
              <option value="70708001">BELEN - SAN MARCOS - SUCRE</option>
              <option value="70708002">BUENAVISTA - SAN MARCOS - SUCRE</option>
              <option value="70708003">CANDELARIA - SAN MARCOS - SUCRE</option>
              <option value="70708004">CAÑO PRIETO - SAN MARCOS - SUCRE</option>
              <option value="70708005">CUENCA - SAN MARCOS - SUCRE</option>
              <option value="70708006">EL LIMON - SAN MARCOS - SUCRE</option>
              <option value="70708007">EL TABLON - SAN MARCOS - SUCRE</option>
              <option value="70708009">LAS FLORES - SAN MARCOS - SUCRE</option>
              <option value="70708010">MONTEGRANDE - SAN MARCOS - SUCRE</option>
              <option value="70708011">PALO ALTO - SAN MARCOS - SUCRE</option>
              <option value="70708012">EL PITAL - SAN MARCOS - SUCRE</option>
              <option value="70708013">SANTA INES - SAN MARCOS - SUCRE</option>
              <option value="70708014">LA QUEBRADA - SAN MARCOS - SUCRE</option>
              <option value="70708015">EL LLANO - SAN MARCOS - SUCRE</option>
              <option value="70708017">NEIVA - SAN MARCOS - SUCRE</option>
              <option value="70708018">BUENOS AIRES - SAN MARCOS - SUCRE</option>
              <option value="70708020">CASTILLERA - SAN MARCOS - SUCRE</option>
              <option value="70708021">CAYO DE LA CRUZ - SAN MARCOS - SUCRE</option>
              <option value="70708024">EL OASIS - SAN MARCOS - SUCRE</option>
              <option value="70708025">LA COSTERA - SAN MARCOS - SUCRE</option>
              <option value="70708027">RINCON GUERRANO - SAN MARCOS - SUCRE</option>
              <option value="70708028">SAN FELIPE - SAN MARCOS - SUCRE</option>
              <option value="70708029">SEHEBE - SAN MARCOS - SUCRE</option>
              <option value="70708030">CAIMITICO - SAN MARCOS - SUCRE</option>
              <option value="70708031">CAÑO CARATE - SAN MARCOS - SUCRE</option>
              <option value="70708032">CEJA LARGA - SAN MARCOS - SUCRE</option>
              <option value="70708033">EL REPARO - SAN MARCOS - SUCRE</option>
              <option value="70708034">MEDIA TAPA - SAN MARCOS - SUCRE</option>
              <option value="70708035">NUEVA ESPERANZA - SAN MARCOS - SUCRE</option>
              <option value="70708036">PAJONAL - SAN MARCOS - SUCRE</option>
              <option value="70713000">SAN ONOFRE - SUCRE</option>
              <option value="70713001">AGUACATE - SAN ONOFRE - SUCRE</option>
              <option value="70713002">BERLIN - SAN ONOFRE - SUCRE</option>
              <option value="70713003">BERRUGAS - SAN ONOFRE - SUCRE</option>
              <option value="70713005">LABARCES - SAN ONOFRE - SUCRE</option>
              <option value="70713006">LIBERTAD - SAN ONOFRE - SUCRE</option>
              <option value="70713007">PAJONAL - SAN ONOFRE - SUCRE</option>
              <option value="70713008">PALO ALTO - SAN ONOFRE - SUCRE</option>
              <option value="70713009">PLANPAREJO - SAN ONOFRE - SUCRE</option>
              <option value="70713010">RINCON DEL MAR - SAN ONOFRE - SUCRE</option>
              <option value="70713011">SABANAS DE MUCACAL - SAN ONOFRE - SUCRE</option>
              <option value="70713012">SAN ANTONIO - SAN ONOFRE - SUCRE</option>
              <option value="70713014">HIGUERON - SAN ONOFRE - SUCRE</option>
              <option value="70713015">EL CHICHO - SAN ONOFRE - SUCRE</option>
              <option value="70713016">BARRANCAS - SAN ONOFRE - SUCRE</option>
              <option value="70713017">CERRO DE LAS CASAS - SAN ONOFRE - SUCRE</option>
              <option value="70713018">PAJONALITO - SAN ONOFRE - SUCRE</option>
              <option value="70713019">EL PUEBLITO - SAN ONOFRE - SUCRE</option>
              <option value="70713020">AGUAS NEGRAS - SAN ONOFRE - SUCRE</option>
              <option value="70713021">PALACIOS - SAN ONOFRE - SUCRE</option>
              <option value="70713022">BOCACERRADA - SAN ONOFRE - SUCRE</option>
              <option value="70713023">PALMIRA - SAN ONOFRE - SUCRE</option>
              <option value="70713024">ALTOS DE JULIO - SAN ONOFRE - SUCRE</option>
              <option value="70713025">ARROYO SECO - SAN ONOFRE - SUCRE</option>
              <option value="70713026">LAS BRISAS - SAN ONOFRE - SUCRE</option>
              <option value="70713027">PISISI - SAN ONOFRE - SUCRE</option>
              <option value="70713028">SABANAS DE RINCON - SAN ONOFRE - SUCRE</option>
              <option value="70713029">SABANETICA - SAN ONOFRE - SUCRE</option>
              <option value="70713030">BUENAVENTURA - SAN ONOFRE - SUCRE</option>
              <option value="70713031">EL CAMPAMENTO - SAN ONOFRE - SUCRE</option>
              <option value="70713032">EMBOCADA H PAJONAL - SAN ONOFRE - SUCRE</option>
              <option value="70717000">SAN PEDRO - SUCRE</option>
              <option value="70717001">SAN MATEO - SAN PEDRO - SUCRE</option>
              <option value="70717002">ROVIRA - SAN PEDRO - SUCRE</option>
              <option value="70717003">NUMANCIA - SAN PEDRO - SUCRE</option>
              <option value="70717004">EL BAJO DE LA ALEGRIA - SAN PEDRO - SUCRE</option>
              <option value="70717006">CALABOZO - SAN PEDRO - SUCRE</option>
              <option value="70717007">EL CARMEN - SAN PEDRO - SUCRE</option>
              <option value="70717008">LOS CHIJETES - SAN PEDRO - SUCRE</option>
              <option value="70717009">MANIZALES - SAN PEDRO - SUCRE</option>
              <option value="70717011">SAN FRANCISCO - SAN PEDRO - SUCRE</option>
              <option value="70742000">SINCE - SAN LUIS DE SINCE - SUCRE</option>
              <option value="70742002">BAZAN - SAN LUIS DE SINCE - SUCRE</option>
              <option value="70742004">COCOROTE - SAN LUIS DE SINCE - SUCRE</option>
              <option value="70742005">GRANADA - SAN LUIS DE SINCE - SUCRE</option>
              <option value="70742008">LOS LIMONES - SAN LUIS DE SINCE - SUCRE</option>
              <option value="70742010">VALENCIA - SAN LUIS DE SINCE - SUCRE</option>
              <option value="70742011">VELEZ - SAN LUIS DE SINCE - SUCRE</option>
              <option value="70742013">LA VIVIENDA - SAN LUIS DE SINCE - SUCRE</option>
              <option value="70742014">PERENDENGUE - SAN LUIS DE SINCE - SUCRE</option>
              <option value="70742015">GALAPAGO - SAN LUIS DE SINCE - SUCRE</option>
              <option value="70742016">MORALITO - SAN LUIS DE SINCE - SUCRE</option>
              <option value="70742017">PORVENIR - SAN LUIS DE SINCE - SUCRE</option>
              <option value="70771000">SUCRE - SUCRE</option>
              <option value="70771001">ARBOLEDA - SUCRE - SUCRE</option>
              <option value="70771005">CAMPO ALEGRE - SUCRE - SUCRE</option>
              <option value="70771006">CORDOBA - SUCRE - SUCRE</option>
              <option value="70771008">EL CONGRESO - SUCRE - SUCRE</option>
              <option value="70771012">LA VENTURA - SUCRE - SUCRE</option>
              <option value="70771013">MONTERIA - SUCRE - SUCRE</option>
              <option value="70771015">NARANJAL - SUCRE - SUCRE</option>
              <option value="70771016">NARIÑO - SUCRE - SUCRE</option>
              <option value="70771017">OREJERO - SUCRE - SUCRE</option>
              <option value="70771018">SAN LUIS - SUCRE - SUCRE</option>
              <option value="70771019">TRAVESIA - SUCRE - SUCRE</option>
              <option value="70771020">HATO NUEVO - SUCRE - SUCRE</option>
              <option value="70771021">PAMPANILLA - SUCRE - SUCRE</option>
              <option value="70771024">LA PALMA - SUCRE - SUCRE</option>
              <option value="70771027">SAN MATEO - SUCRE - SUCRE</option>
              <option value="70771053">TOTUMAL - SUCRE - SUCRE</option>
              <option value="70820000">SANTIAGO DE TOLU - SUCRE</option>
              <option value="70820003">NUEVA ERA - SANTIAGO DE TOLU - SUCRE</option>
              <option value="70820007">PITA EN MEDIO - SANTIAGO DE TOLU - SUCRE</option>
              <option value="70820008">PUERTO VIEJO - SANTIAGO DE TOLU - SUCRE</option>
              <option value="70820010">PITA ABAJO - SANTIAGO DE TOLU - SUCRE</option>
              <option value="70820013">SANTA LUCIA - SANTIAGO DE TOLU - SUCRE</option>
              <option value="70823000">TOLUVIEJO - SAN JOSE DE TOLUVIEJO - SUCRE</option>
              <option value="70823001">CARACOL - SAN JOSE DE TOLUVIEJO - SUCRE</option>
              <option value="70823002">LAS PIEDRAS - SAN JOSE DE TOLUVIEJO - SUCRE</option>
              <option value="70823003">MACAJAN - SAN JOSE DE TOLUVIEJO - SUCRE</option>
              <option value="70823004">PALMIRA - SAN JOSE DE TOLUVIEJO - SUCRE</option>
              <option value="70823005">VARSOVIA - SAN JOSE DE TOLUVIEJO - SUCRE</option>
              <option value="70823006">LA PICHE - SAN JOSE DE TOLUVIEJO - SUCRE</option>
              <option value="70823007">CIENAGUITA - SAN JOSE DE TOLUVIEJO - SUCRE</option>
              <option value="70823009">MOQUEN - SAN JOSE DE TOLUVIEJO - SUCRE</option>
              <option value="70823010">GUALON - SAN JOSE DE TOLUVIEJO - SUCRE</option>
              <option value="70823011">CAÑITO - SAN JOSE DE TOLUVIEJO - SUCRE</option>
              <option value="70823012">LA SIRIA - SAN JOSE DE TOLUVIEJO - SUCRE</option>
              <option value="70823013">LA FLORESTA - SAN JOSE DE TOLUVIEJO - SUCRE</option>
              <option value="70823014">LOS ALTOS - SAN JOSE DE TOLUVIEJO - SUCRE</option>
              <option value="70823015">NUEVA ESPERANZA - SAN JOSE DE TOLUVIEJO - SUCRE</option>
              <option value="73001000">IBAGUE - TOLIMA</option>
              <option value="73001001">BUENOS AIRES - IBAGUE - TOLIMA</option>
              <option value="73001004">DANTAS - IBAGUE - TOLIMA</option>
              <option value="73001006">JUNTAS - IBAGUE - TOLIMA</option>
              <option value="73001007">LAURELES - IBAGUE - TOLIMA</option>
              <option value="73001009">SAN BERNARDO - IBAGUE - TOLIMA</option>
              <option value="73001010">SAN JUAN DE LA CHINA - IBAGUE - TOLIMA</option>
              <option value="73001011">TAPIAS - IBAGUE - TOLIMA</option>
              <option value="73001012">TOCHE - IBAGUE - TOLIMA</option>
              <option value="73001013">VILLARESTREPO - IBAGUE - TOLIMA</option>
              <option value="73001014">LLANITOS - IBAGUE - TOLIMA</option>
              <option value="73001015">EL TOTUMO - IBAGUE - TOLIMA</option>
              <option value="73001016">LLANO DEL COMBEIMA - IBAGUE - TOLIMA</option>
              <option value="73001017">CARMEN DE BULIRA - IBAGUE - TOLIMA</option>
              <option value="73001018">EL RODEO - IBAGUE - TOLIMA</option>
              <option value="73001020">COELLO - COCORA - IBAGUE - TOLIMA</option>
              <option value="73001024">SANTA TERESA - IBAGUE - TOLIMA</option>
              <option value="73001025">PASTALES VIEJO - IBAGUE - TOLIMA</option>
              <option value="73001027">PASTALES NUEVO - IBAGUE - TOLIMA</option>
              <option value="73001028">LA FLOR - IBAGUE - TOLIMA</option>
              <option value="73001030">EL CAY - IBAGUE - TOLIMA</option>
              <option value="73001032">ALTO DE GUALANDAY - IBAGUE - TOLIMA</option>
              <option value="73001034">APARCO - IBAGUE - TOLIMA</option>
              <option value="73001036">BRICEÑO - IBAGUE - TOLIMA</option>
              <option value="73001038">CHEMBE - IBAGUE - TOLIMA</option>
              <option value="73001039">CHUCUNI - IBAGUE - TOLIMA</option>
              <option value="73001047">LA HELENA - IBAGUE - TOLIMA</option>
              <option value="73001049">LA MIEL - IBAGUE - TOLIMA</option>
              <option value="73001050">LA PALMILLA - IBAGUE - TOLIMA</option>
              <option value="73001057">LOS TUNELES - IBAGUE - TOLIMA</option>
              <option value="73001058">PICO DE ORO - IBAGUE - TOLIMA</option>
              <option value="73001059">TRES ESQUINAS - IBAGUE - TOLIMA</option>
              <option value="73001065">INVASION BELLA ISLA DE LLANITOS - IBAGUE - TOLIMA</option>
              <option value="73001066">SALITRE - IBAGUE - TOLIMA</option>
              <option value="73024000">ALPUJARRA - TOLIMA</option>
              <option value="73024001">LA ARADA - ALPUJARRA - TOLIMA</option>
              <option value="73024002">EL CARMEN - ALPUJARRA - TOLIMA</option>
              <option value="73024003">AMESES - ALPUJARRA - TOLIMA</option>
              <option value="73026000">ALVARADO - TOLIMA</option>
              <option value="73026001">CALDAS VIEJO - ALVARADO - TOLIMA</option>
              <option value="73026004">RINCON CHIPALO - ALVARADO - TOLIMA</option>
              <option value="73026005">VERACRUZ - ALVARADO - TOLIMA</option>
              <option value="73026008">LA TEBAIDA - ALVARADO - TOLIMA</option>
              <option value="73026011">TOTARITO - ALVARADO - TOLIMA</option>
              <option value="73030000">AMBALEMA - TOLIMA</option>
              <option value="73030002">CHORRILLO - AMBALEMA - TOLIMA</option>
              <option value="73030004">PAJONALES - AMBALEMA - TOLIMA</option>
              <option value="73030006">LA ALDEA EL DANUBIO - AMBALEMA - TOLIMA</option>
              <option value="73030007">BOQUERON - AMBALEMA - TOLIMA</option>
              <option value="73043000">ANZOATEGUI - TOLIMA</option>
              <option value="73043001">LISBOA - ANZOATEGUI - TOLIMA</option>
              <option value="73043002">PALOMAR - ANZOATEGUI - TOLIMA</option>
              <option value="73043003">SANTA BARBARA - ANZOATEGUI - TOLIMA</option>
              <option value="73055000">GUAYABAL - ARMERO - TOLIMA</option>
              <option value="73055002">MENDEZ - ARMERO - TOLIMA</option>
              <option value="73055003">SAN PEDRO - ARMERO - TOLIMA</option>
              <option value="73055004">SAN FELIPE - ARMERO - TOLIMA</option>
              <option value="73055006">FUNDADORES - ARMERO - TOLIMA</option>
              <option value="73055007">NUEVO HORIZONTE - ARMERO - TOLIMA</option>
              <option value="73067000">ATACO - TOLIMA</option>
              <option value="73067001">CAMPOHERMOSO - ATACO - TOLIMA</option>
              <option value="73067003">CASA DE ZINC - ATACO - TOLIMA</option>
              <option value="73067005">MESA DE POLE - ATACO - TOLIMA</option>
              <option value="73067006">POLECITO - ATACO - TOLIMA</option>
              <option value="73067007">SANTIAGO PEREZ - ATACO - TOLIMA</option>
              <option value="73067012">MONTELORO - ATACO - TOLIMA</option>
              <option value="73067014">EL PAUJIL - ATACO - TOLIMA</option>
              <option value="73067016">CONDOR - ATACO - TOLIMA</option>
              <option value="73067020">EL BALSO - ATACO - TOLIMA</option>
              <option value="73067021">LA LAGUNA - ATACO - TOLIMA</option>
              <option value="73067022">LA ESTRELLA - ATACO - TOLIMA</option>
              <option value="73124000">CAJAMARCA - TOLIMA</option>
              <option value="73124001">ANAIME - CAJAMARCA - TOLIMA</option>
              <option value="73124005">EL ROSAL - CAJAMARCA - TOLIMA</option>
              <option value="73148000">CARMEN DE APICALA - TOLIMA</option>
              <option value="73152000">CASABIANCA - TOLIMA</option>
              <option value="73152002">SAN JERONIMO - CASABIANCA - TOLIMA</option>
              <option value="73168000">CHAPARRAL - TOLIMA</option>
              <option value="73168004">EL LIMON - CHAPARRAL - TOLIMA</option>
              <option value="73168005">LA MARINA - CHAPARRAL - TOLIMA</option>
              <option value="73168006">LA PROFUNDA - CHAPARRAL - TOLIMA</option>
              <option value="73168007">SAN JOSE DE LAS HERMOSAS - CHAPARRAL - TOLIMA</option>
              <option value="73200000">COELLO - TOLIMA</option>
              <option value="73200001">GUALANDAY - COELLO - TOLIMA</option>
              <option value="73200002">LA BARRIALOSA - COELLO - TOLIMA</option>
              <option value="73200003">LLANO DE LA VIRGEN - COELLO - TOLIMA</option>
              <option value="73200004">POTRERILLO - COELLO - TOLIMA</option>
              <option value="73200005">VEGA LOS PADRES - COELLO - TOLIMA</option>
              <option value="73200016">VINDI - COELLO - TOLIMA</option>
              <option value="73200017">CALABOZO - COELLO - TOLIMA</option>
              <option value="73217000">COYAIMA - TOLIMA</option>
              <option value="73217001">CASTILLA - COYAIMA - TOLIMA</option>
              <option value="73217005">TOTARCO DINDE - COYAIMA - TOLIMA</option>
              <option value="73217011">GUAYAQUIL - COYAIMA - TOLIMA</option>
              <option value="73217012">MESA DE INCA - COYAIMA - TOLIMA</option>
              <option value="73217013">SAN MIGUEL - COYAIMA - TOLIMA</option>
              <option value="73226000">CUNDAY - TOLIMA</option>
              <option value="73226001">LA AURORA - CUNDAY - TOLIMA</option>
              <option value="73226002">SAN PABLO - CUNDAY - TOLIMA</option>
              <option value="73226003">TRES ESQUINAS - CUNDAY - TOLIMA</option>
              <option value="73226004">VALENCIA - CUNDAY - TOLIMA</option>
              <option value="73226005">VARSOVIA - CUNDAY - TOLIMA</option>
              <option value="73226008">EL REVES - CUNDAY - TOLIMA</option>
              <option value="73236000">DOLORES - TOLIMA</option>
              <option value="73236004">RIONEGRO - DOLORES - TOLIMA</option>
              <option value="73236005">SAN ANDRES - DOLORES - TOLIMA</option>
              <option value="73236007">LOS LLANITOS - DOLORES - TOLIMA</option>
              <option value="73236010">LA SOLEDAD - DOLORES - TOLIMA</option>
              <option value="73236011">SAN PEDRO - DOLORES - TOLIMA</option>
              <option value="73268000">EL ESPINAL - ESPINAL - TOLIMA</option>
              <option value="73268001">CHICORAL - ESPINAL - TOLIMA</option>
              <option value="73268003">SAN FRANCISCO - ESPINAL - TOLIMA</option>
              <option value="73270000">FALAN - TOLIMA</option>
              <option value="73270001">FRIAS - FALAN - TOLIMA</option>
              <option value="73270004">PIEDECUESTA - FALAN - TOLIMA</option>
              <option value="73275000">FLANDES - TOLIMA</option>
              <option value="73275001">EL COLEGIO - FLANDES - TOLIMA</option>
              <option value="73275005">PARADERO I - FLANDES - TOLIMA</option>
              <option value="73275009">CONDOMINIO SANTA ANA Y PALMA REAL - FLANDES - TOLIMA</option>
              <option value="73275010">CONDOMINIO VILLA ESPERANZA - FLANDES - TOLIMA</option>
              <option value="73283000">FRESNO - TOLIMA</option>
              <option value="73283001">BETANIA - FRESNO - TOLIMA</option>
              <option value="73283003">EL TABLAZO - FRESNO - TOLIMA</option>
              <option value="73283004">LA AGUADITA - FRESNO - TOLIMA</option>
              <option value="73283008">SAN BERNARDO - FRESNO - TOLIMA</option>
              <option value="73283013">PARTIDAS - FRESNO - TOLIMA</option>
              <option value="73319000">GUAMO - TOLIMA</option>
              <option value="73319002">LA CHAMBA - GUAMO - TOLIMA</option>
              <option value="73319004">RINCON SANTO CENTRO - GUAMO - TOLIMA</option>
              <option value="73319005">CHIPUELO ORIENTE - GUAMO - TOLIMA</option>
              <option value="73319009">LA TROJA - GUAMO - TOLIMA</option>
              <option value="73319010">LOMA DE LUISA - GUAMO - TOLIMA</option>
              <option value="73319013">CAÑADA EL RODEO - GUAMO - TOLIMA</option>
              <option value="73319015">PUEBLO NUEVO - GUAMO - TOLIMA</option>
              <option value="73319016">CEREZUELA LAS GARZAS - GUAMO - TOLIMA</option>
              <option value="73347000">HERVEO - TOLIMA</option>
              <option value="73347001">BRASIL - HERVEO - TOLIMA</option>
              <option value="73347003">LETRAS - HERVEO - TOLIMA</option>
              <option value="73347004">MESONES - HERVEO - TOLIMA</option>
              <option value="73347005">PADUA - HERVEO - TOLIMA</option>
              <option value="73349000">HONDA - TOLIMA</option>
              <option value="73349001">PERICO - HONDA - TOLIMA</option>
              <option value="73352000">ICONONZO - TOLIMA</option>
              <option value="73352001">BALCONCITOS - ICONONZO - TOLIMA</option>
              <option value="73352002">BOQUERON - ICONONZO - TOLIMA</option>
              <option value="73352003">MUNDO NUEVO - ICONONZO - TOLIMA</option>
              <option value="73352005">PATECUINDE - ICONONZO - TOLIMA</option>
              <option value="73352007">EL TRIUNFO - ICONONZO - TOLIMA</option>
              <option value="73408000">LERIDA - TOLIMA</option>
              <option value="73408001">DELICIAS - LERIDA - TOLIMA</option>
              <option value="73408002">SAN FRANCISCO DE LA SIERRA - LERIDA - TOLIMA</option>
              <option value="73408003">PADILLA - LERIDA - TOLIMA</option>
              <option value="73408005">IGUASITOS - LERIDA - TOLIMA</option>
              <option value="73411000">LIBANO - TOLIMA</option>
              <option value="73411002">CONVENIO - LIBANO - TOLIMA</option>
              <option value="73411006">SAN FERNANDO - LIBANO - TOLIMA</option>
              <option value="73411007">SANTA TERESA - LIBANO - TOLIMA</option>
              <option value="73411008">TIERRADENTRO - LIBANO - TOLIMA</option>
              <option value="73411009">CAMPO ALEGRE - LIBANO - TOLIMA</option>
              <option value="73443000">SAN SEBASTIAN DE MARIQUITA - TOLIMA</option>
              <option value="73443001">EL HATILLO - SAN SEBASTIAN DE MARIQUITA - TOLIMA</option>
              <option value="73443002">LA CABAÑA - SAN SEBASTIAN DE MARIQUITA - TOLIMA</option>
              <option value="73443003">PITALITO - SAN SEBASTIAN DE MARIQUITA - TOLIMA</option>
              <option value="73443004">LA ALBANIA - SAN SEBASTIAN DE MARIQUITA - TOLIMA</option>
              <option value="73443006">CAMELIAS - SAN SEBASTIAN DE MARIQUITA - TOLIMA</option>
              <option value="73443008">LA PARROQUIA - SAN SEBASTIAN DE MARIQUITA - TOLIMA</option>
              <option value="73443009">LAS MARIAS - SAN SEBASTIAN DE MARIQUITA - TOLIMA</option>
              <option value="73449000">MELGAR - TOLIMA</option>
              <option value="73449001">CUALAMANA - MELGAR - TOLIMA</option>
              <option value="73449004">AGUILA - MELGAR - TOLIMA</option>
              <option value="73449006">BALCONES DEL SUMAPAZ - MELGAR - TOLIMA</option>
              <option value="73449008">EL RUBY - MELGAR - TOLIMA</option>
              <option value="73449009">LA ESTANCIA - MELGAR - TOLIMA</option>
              <option value="73449012">QUEBRADITAS 1 - MELGAR - TOLIMA</option>
              <option value="73449014">SAN JOSE DE LA COLORADA - MELGAR - TOLIMA</option>
              <option value="73449016">CUALAMANA 2 - MELGAR - TOLIMA</option>
              <option value="73449017">EL PALMAR - MELGAR - TOLIMA</option>
              <option value="73449018">PEDRO GOMEZ - MELGAR - TOLIMA</option>
              <option value="73461000">MURILLO - TOLIMA</option>
              <option value="73461001">EL BOSQUE - MURILLO - TOLIMA</option>
              <option value="73483000">NATAGAIMA - TOLIMA</option>
              <option value="73483001">LA PALMITA - NATAGAIMA - TOLIMA</option>
              <option value="73483002">VELU - NATAGAIMA - TOLIMA</option>
              <option value="73483008">RINCON ANCHIQUE - NATAGAIMA - TOLIMA</option>
              <option value="73483012">LAS BRISAS - NATAGAIMA - TOLIMA</option>
              <option value="73504000">ORTEGA - TOLIMA</option>
              <option value="73504003">GUAIPA - ORTEGA - TOLIMA</option>
              <option value="73504004">HATO DE IGLESIA - ORTEGA - TOLIMA</option>
              <option value="73504007">LA MESA DE ORTEGA - ORTEGA - TOLIMA</option>
              <option value="73504008">OLAYA HERRERA - ORTEGA - TOLIMA</option>
              <option value="73504009">EL VERGEL - ORTEGA - TOLIMA</option>
              <option value="73504017">LOS GUAYABOS - ORTEGA - TOLIMA</option>
              <option value="73520000">PALOCABILDO - TOLIMA</option>
              <option value="73520001">ASTURIAS - PALOCABILDO - TOLIMA</option>
              <option value="73520002">BUENOS AIRES - PALOCABILDO - TOLIMA</option>
              <option value="73520003">GUADUALITO - PALOCABILDO - TOLIMA</option>
              <option value="73547000">PIEDRAS - TOLIMA</option>
              <option value="73547001">CHICALA - PIEDRAS - TOLIMA</option>
              <option value="73547002">DOIMA - PIEDRAS - TOLIMA</option>
              <option value="73547003">GUATAQUISITO - PIEDRAS - TOLIMA</option>
              <option value="73547005">PARADERO CHIPALO - PIEDRAS - TOLIMA</option>
              <option value="73555000">PLANADAS - TOLIMA</option>
              <option value="73555001">BILBAO - PLANADAS - TOLIMA</option>
              <option value="73555002">GAITANIA - PLANADAS - TOLIMA</option>
              <option value="73555004">SUR DE ATA - PLANADAS - TOLIMA</option>
              <option value="73555006">BRUSELAS - PLANADAS - TOLIMA</option>
              <option value="73555007">SAN MIGUEL - PLANADAS - TOLIMA</option>
              <option value="73563000">PRADO - TOLIMA</option>
              <option value="73563001">ACO - PRADO - TOLIMA</option>
              <option value="73563004">MONTOSO - PRADO - TOLIMA</option>
              <option value="73585000">PURIFICACION - TOLIMA</option>
              <option value="73585001">CHENCHE ASOLEADO - PURIFICACION - TOLIMA</option>
              <option value="73585002">LOZANIA - PURIFICACION - TOLIMA</option>
              <option value="73585006">VILLA ESPERANZA - PURIFICACION - TOLIMA</option>
              <option value="73585007">VILLA COLOMBIA - PURIFICACION - TOLIMA</option>
              <option value="73585009">EL BAURA - PURIFICACION - TOLIMA</option>
              <option value="73585013">LA MATA - PURIFICACION - TOLIMA</option>
              <option value="73585015">BUENAVISTA - PURIFICACION - TOLIMA</option>
              <option value="73585018">CHENCHE UNO - PURIFICACION - TOLIMA</option>
              <option value="73616000">RIOBLANCO - TOLIMA</option>
              <option value="73616001">HERRERA - RIOBLANCO - TOLIMA</option>
              <option value="73616002">PUERTO SALDAÑA - RIOBLANCO - TOLIMA</option>
              <option value="73616004">PALONEGRO - RIOBLANCO - TOLIMA</option>
              <option value="73616005">GAITAN - RIOBLANCO - TOLIMA</option>
              <option value="73616006">MARACAIBO - RIOBLANCO - TOLIMA</option>
              <option value="73616009">JUNTAS - RIOBLANCO - TOLIMA</option>
              <option value="73622000">RONCESVALLES - TOLIMA</option>
              <option value="73622001">SANTA ELENA - RONCESVALLES - TOLIMA</option>
              <option value="73622002">EL CEDRO - RONCESVALLES - TOLIMA</option>
              <option value="73624000">ROVIRA - TOLIMA</option>
              <option value="73624001">EL CORAZON - ROVIRA - TOLIMA</option>
              <option value="73624003">LOS ANDES - LA BELLA - ROVIRA - TOLIMA</option>
              <option value="73624004">RIOMANSO - ROVIRA - TOLIMA</option>
              <option value="73624005">SAN PEDRO - ROVIRA - TOLIMA</option>
              <option value="73624007">GUADUALITO - ROVIRA - TOLIMA</option>
              <option value="73624008">LA FLORIDA - ROVIRA - TOLIMA</option>
              <option value="73624010">LA SELVA - ROVIRA - TOLIMA</option>
              <option value="73624012">LA LUISA - ROVIRA - TOLIMA</option>
              <option value="73671000">SALDAÑA - TOLIMA</option>
              <option value="73671001">JABALCON - SALDAÑA - TOLIMA</option>
              <option value="73671002">SANTA INES - SALDAÑA - TOLIMA</option>
              <option value="73671008">LA ESPERANZA - SALDAÑA - TOLIMA</option>
              <option value="73671009">NORMANDIA - SALDAÑA - TOLIMA</option>
              <option value="73675000">SAN ANTONIO - TOLIMA</option>
              <option value="73675001">LA FLORIDA - SAN ANTONIO - TOLIMA</option>
              <option value="73675002">PLAYARRICA - SAN ANTONIO - TOLIMA</option>
              <option value="73675004">VILLA HERMOSA - SAN ANTONIO - TOLIMA</option>
              <option value="73678000">SAN LUIS - TOLIMA</option>
              <option value="73678004">PAYANDE - SAN LUIS - TOLIMA</option>
              <option value="73686000">SANTA ISABEL - TOLIMA</option>
              <option value="73686001">COLON - SANTA ISABEL - TOLIMA</option>
              <option value="73686003">SAN RAFAEL - SANTA ISABEL - TOLIMA</option>
              <option value="73770000">SUAREZ - TOLIMA</option>
              <option value="73770001">HATO VIEJO - SUAREZ - TOLIMA</option>
              <option value="73770003">CAÑAVERALES - SUAREZ - TOLIMA</option>
              <option value="73770005">AGUA BLANCA - SUAREZ - TOLIMA</option>
              <option value="73854000">VALLE DE SAN JUAN - TOLIMA</option>
              <option value="73861000">VENADILLO - TOLIMA</option>
              <option value="73861001">JUNIN - VENADILLO - TOLIMA</option>
              <option value="73861002">LA SIERRITA - VENADILLO - TOLIMA</option>
              <option value="73861003">MALABAR - VENADILLO - TOLIMA</option>
              <option value="73861004">PALMAROSA - VENADILLO - TOLIMA</option>
              <option value="73870000">VILLAHERMOSA - TOLIMA</option>
              <option value="73873000">VILLARRICA - TOLIMA</option>
              <option value="73873002">LA COLONIA - VILLARRICA - TOLIMA</option>
              <option value="73873003">LOS ALPES - VILLARRICA - TOLIMA</option>
              <option value="73873004">PUERTO LLERAS - VILLARRICA - TOLIMA</option>
              <option value="76001000">SANTIAGO DE CALI, DISTRITO ESPECIAL, DEPORTIVO, CULTURAL, TURISTICO, EMPRESARIAL Y DE SERVICIOS - CALI - VALLE DEL CAUCA</option>
              <option value="76001001">EL SALADITO - CALI - VALLE DEL CAUCA</option>
              <option value="76001002">FELIDIA - CALI - VALLE DEL CAUCA</option>
              <option value="76001003">GOLONDRINAS - CALI - VALLE DEL CAUCA</option>
              <option value="76001004">EL HORMIGUERO - CALI - VALLE DEL CAUCA</option>
              <option value="76001005">LA BUITRERA - CALI - VALLE DEL CAUCA</option>
              <option value="76001006">LA CASTILLA - CALI - VALLE DEL CAUCA</option>
              <option value="76001007">LA ELVIRA - CALI - VALLE DEL CAUCA</option>
              <option value="76001008">LA LEONERA - CALI - VALLE DEL CAUCA</option>
              <option value="76001009">LA PAZ - CALI - VALLE DEL CAUCA</option>
              <option value="76001010">LOS ANDES - CALI - VALLE DEL CAUCA</option>
              <option value="76001012">NAVARRO - CALI - VALLE DEL CAUCA</option>
              <option value="76001013">PANCE - CALI - VALLE DEL CAUCA</option>
              <option value="76001014">PICHINDE - CALI - VALLE DEL CAUCA</option>
              <option value="76001016">MONTEBELLO - CALI - VALLE DEL CAUCA</option>
              <option value="76001019">CASCAJAL II - CALI - VALLE DEL CAUCA</option>
              <option value="76001020">VILLACARMELO - CALI - VALLE DEL CAUCA</option>
              <option value="76001022">BRISAS DE MONTEBELLO - CALI - VALLE DEL CAUCA</option>
              <option value="76001023">CAMPO ALEGRE - CALI - VALLE DEL CAUCA</option>
              <option value="76001024">CASCAJAL I - CALI - VALLE DEL CAUCA</option>
              <option value="76001025">CRUCERO ALTO DE LOS MANGOS - CALI - VALLE DEL CAUCA</option>
              <option value="76001026">EL FILO - CALI - VALLE DEL CAUCA</option>
              <option value="76001027">EL PORTENTO - CALI - VALLE DEL CAUCA</option>
              <option value="76001028">LA FRAGUA - CALI - VALLE DEL CAUCA</option>
              <option value="76001029">LA VORAGINE - CALI - VALLE DEL CAUCA</option>
              <option value="76001030">LAS PALMAS - CALI - VALLE DEL CAUCA</option>
              <option value="76001031">LOS CERROS - CALI - VALLE DEL CAUCA</option>
              <option value="76001032">MONTAÑITAS - CALI - VALLE DEL CAUCA</option>
              <option value="76001034">PIZAMOS - CALI - VALLE DEL CAUCA</option>
              <option value="76001035">PUEBLO NUEVO - CALI - VALLE DEL CAUCA</option>
              <option value="76001036">SAN FRANCISCO - CALI - VALLE DEL CAUCA</option>
              <option value="76001037">SAN ISIDRO - CALI - VALLE DEL CAUCA</option>
              <option value="76001038">VILLA FLAMENCO - CALI - VALLE DEL CAUCA</option>
              <option value="76001041">CASCAJAL III - CALI - VALLE DEL CAUCA</option>
              <option value="76001042">EL ESTERO - CALI - VALLE DEL CAUCA</option>
              <option value="76001043">LA LUISA - CALI - VALLE DEL CAUCA</option>
              <option value="76001044">LA SIRENA - CALI - VALLE DEL CAUCA</option>
              <option value="76001045">LAS PALMAS - LA CASTILLA - CALI - VALLE DEL CAUCA</option>
              <option value="76001046">SILOE - CALI - VALLE DEL CAUCA</option>
              <option value="76001048">LOS LIMONES - CALI - VALLE DEL CAUCA</option>
              <option value="76001050">CAUCA VIEJO - CALI - VALLE DEL CAUCA</option>
              <option value="76001051">CONDOMINIO MARAÑON - CALI - VALLE DEL CAUCA</option>
              <option value="76001052">CHORRO DE PLATA - CALI - VALLE DEL CAUCA</option>
              <option value="76001053">PARCELACION CANTACLARO 1 - CALI - VALLE DEL CAUCA</option>
              <option value="76001054">PARCELACION CANTACLARO 2 - CALI - VALLE DEL CAUCA</option>
              <option value="76001055">PARCELACION LA TRINIDAD - CALI - VALLE DEL CAUCA</option>
              <option value="76001056">PIAMONTE - CALI - VALLE DEL CAUCA</option>
              <option value="76001057">CALLEJON TABARES - CALI - VALLE DEL CAUCA</option>
              <option value="76001058">DUQUELANDIA - CALI - VALLE DEL CAUCA</option>
              <option value="76001059">LA COLINA - CALI - VALLE DEL CAUCA</option>
              <option value="76001060">LOS GIRASOLES - CALI - VALLE DEL CAUCA</option>
              <option value="76001061">PILAS DEL CABUYAL - CALI - VALLE DEL CAUCA</option>
              <option value="76001062">VILLA DEL ROSARIO - CALI - VALLE DEL CAUCA</option>
              <option value="76020000">ALCALA - VALLE DEL CAUCA</option>
              <option value="76020006">LA FLORESTA - ALCALA - VALLE DEL CAUCA</option>
              <option value="76020007">LA POLONIA - ALCALA - VALLE DEL CAUCA</option>
              <option value="76036000">ANDALUCIA - VALLE DEL CAUCA</option>
              <option value="76036002">CAMPOALEGRE - ANDALUCIA - VALLE DEL CAUCA</option>
              <option value="76036003">EL SALTO - ANDALUCIA - VALLE DEL CAUCA</option>
              <option value="76036006">TAMBORAL - ANDALUCIA - VALLE DEL CAUCA</option>
              <option value="76036007">ZANJON DE PIEDRA - ANDALUCIA - VALLE DEL CAUCA</option>
              <option value="76036009">MONTE HERMOSO - ANDALUCIA - VALLE DEL CAUCA</option>
              <option value="76036010">MADRE VIEJA - ANDALUCIA - VALLE DEL CAUCA</option>
              <option value="76036011">LA PAZ - ANDALUCIA - VALLE DEL CAUCA</option>
              <option value="76041000">ANSERMANUEVO - VALLE DEL CAUCA</option>
              <option value="76041001">ANACARO - ANSERMANUEVO - VALLE DEL CAUCA</option>
              <option value="76041003">EL BILLAR - ANSERMANUEVO - VALLE DEL CAUCA</option>
              <option value="76041006">EL VERGEL - ANSERMANUEVO - VALLE DEL CAUCA</option>
              <option value="76041013">GRAMALOTE - ANSERMANUEVO - VALLE DEL CAUCA</option>
              <option value="76041021">SALAZAR - ANSERMANUEVO - VALLE DEL CAUCA</option>
              <option value="76054000">ARGELIA - VALLE DEL CAUCA</option>
              <option value="76054004">EL RAIZAL - ARGELIA - VALLE DEL CAUCA</option>
              <option value="76054005">LA AURORA - ARGELIA - VALLE DEL CAUCA</option>
              <option value="76100000">BOLIVAR - VALLE DEL CAUCA</option>
              <option value="76100001">BETANIA - BOLIVAR - VALLE DEL CAUCA</option>
              <option value="76100002">CERRO AZUL - BOLIVAR - VALLE DEL CAUCA</option>
              <option value="76100006">LA HERRADURA - BOLIVAR - VALLE DEL CAUCA</option>
              <option value="76100007">LA TULIA - BOLIVAR - VALLE DEL CAUCA</option>
              <option value="76100008">NARANJAL - BOLIVAR - VALLE DEL CAUCA</option>
              <option value="76100009">PRIMAVERA - BOLIVAR - VALLE DEL CAUCA</option>
              <option value="76100010">RICAURTE - BOLIVAR - VALLE DEL CAUCA</option>
              <option value="76100012">AGUAS LINDAS - BOLIVAR - VALLE DEL CAUCA</option>
              <option value="76100014">SAN FERNANDO - BOLIVAR - VALLE DEL CAUCA</option>
              <option value="76109000">BUENAVENTURA, DISTRITO ESPECIAL, INDUSTRIAL, PORTUARIO, BIODIVERSO Y ECOTURISTICO - BUENAVENTURA - VALLE DEL CAUCA</option>
              <option value="76109001">AGUACLARA - BUENAVENTURA - VALLE DEL CAUCA</option>
              <option value="76109002">BARCO - BUENAVENTURA - VALLE DEL CAUCA</option>
              <option value="76109003">LA BOCANA - BUENAVENTURA - VALLE DEL CAUCA</option>
              <option value="76109006">BAJO CALIMA - BUENAVENTURA - VALLE DEL CAUCA</option>
              <option value="76109009">CORDOBA - BUENAVENTURA - VALLE DEL CAUCA</option>
              <option value="76109012">PITAL - BUENAVENTURA - VALLE DEL CAUCA</option>
              <option value="76109017">TRIANA - BUENAVENTURA - VALLE DEL CAUCA</option>
              <option value="76109018">CONCEPCION - BUENAVENTURA - VALLE DEL CAUCA</option>
              <option value="76109019">LA PLATA - BUENAVENTURA - VALLE DEL CAUCA</option>
              <option value="76109021">LADRILLEROS - BUENAVENTURA - VALLE DEL CAUCA</option>
              <option value="76109022">LLANO BAJO - BUENAVENTURA - VALLE DEL CAUCA</option>
              <option value="76109024">BOCAS DE MAYORQUIN - BUENAVENTURA - VALLE DEL CAUCA</option>
              <option value="76109028">PUERTO MERIZALDE - BUENAVENTURA - VALLE DEL CAUCA</option>
              <option value="76109030">PUNTA SOLDADO - BUENAVENTURA - VALLE DEL CAUCA</option>
              <option value="76109031">SAN ANTONIO (YURUMANGUI) - BUENAVENTURA - VALLE DEL CAUCA</option>
              <option value="76109032">SAN FRANCISCO DE NAYA - BUENAVENTURA - VALLE DEL CAUCA</option>
              <option value="76109033">SAN FRANCISCO JAVIER - BUENAVENTURA - VALLE DEL CAUCA</option>
              <option value="76109034">SAN ISIDRO - BUENAVENTURA - VALLE DEL CAUCA</option>
              <option value="76109036">SAN LORENZO - BUENAVENTURA - VALLE DEL CAUCA</option>
              <option value="76109037">SAN PEDRO - BUENAVENTURA - VALLE DEL CAUCA</option>
              <option value="76109038">SILVA - BUENAVENTURA - VALLE DEL CAUCA</option>
              <option value="76109039">TAPARAL - BUENAVENTURA - VALLE DEL CAUCA</option>
              <option value="76109040">VENERAL - BUENAVENTURA - VALLE DEL CAUCA</option>
              <option value="76109041">SAN JOSE - BUENAVENTURA - VALLE DEL CAUCA</option>
              <option value="76109042">SABALETAS - BUENAVENTURA - VALLE DEL CAUCA</option>
              <option value="76109043">ZACARIAS - BUENAVENTURA - VALLE DEL CAUCA</option>
              <option value="76109044">CABECERA RIO SAN JUAN - BUENAVENTURA - VALLE DEL CAUCA</option>
              <option value="76109045">LA BARRA - BUENAVENTURA - VALLE DEL CAUCA</option>
              <option value="76109046">JUANCHACO - BUENAVENTURA - VALLE DEL CAUCA</option>
              <option value="76109047">PIANGUITA - BUENAVENTURA - VALLE DEL CAUCA</option>
              <option value="76109053">CHAMUSCADO - BUENAVENTURA - VALLE DEL CAUCA</option>
              <option value="76109058">EL BARRANCO - BUENAVENTURA - VALLE DEL CAUCA</option>
              <option value="76109061">GUAIMIA - BUENAVENTURA - VALLE DEL CAUCA</option>
              <option value="76109062">JUNTAS - BUENAVENTURA - VALLE DEL CAUCA</option>
              <option value="76109064">BARTOLA - BUENAVENTURA - VALLE DEL CAUCA</option>
              <option value="76109065">LA BREA - BUENAVENTURA - VALLE DEL CAUCA</option>
              <option value="76109069">PAPAYAL - BUENAVENTURA - VALLE DEL CAUCA</option>
              <option value="76109071">SAN CIPRIANO - BUENAVENTURA - VALLE DEL CAUCA</option>
              <option value="76109074">SAN JOSE DE NAYA - BUENAVENTURA - VALLE DEL CAUCA</option>
              <option value="76109076">SAN MARCOS - BUENAVENTURA - VALLE DEL CAUCA</option>
              <option value="76109077">SANTA CRUZ - BUENAVENTURA - VALLE DEL CAUCA</option>
              <option value="76109078">ZARAGOZA - BUENAVENTURA - VALLE DEL CAUCA</option>
              <option value="76109079">AGUAMANSA - BUENAVENTURA - VALLE DEL CAUCA</option>
              <option value="76109080">CASCAJITA - BUENAVENTURA - VALLE DEL CAUCA</option>
              <option value="76109081">PUNTA BONITA - BUENAVENTURA - VALLE DEL CAUCA</option>
              <option value="76109082">HORIZONTE - BUENAVENTURA - VALLE DEL CAUCA</option>
              <option value="76109083">BENDICIONES - BUENAVENTURA - VALLE DEL CAUCA</option>
              <option value="76109084">EL CACAO - BUENAVENTURA - VALLE DEL CAUCA</option>
              <option value="76109085">CALLE LARGA - AEROPUERTO - BUENAVENTURA - VALLE DEL CAUCA</option>
              <option value="76109086">CAMINO VIEJO - KM 40 - BUENAVENTURA - VALLE DEL CAUCA</option>
              <option value="76109087">CAMPO HERMOSO - BUENAVENTURA - VALLE DEL CAUCA</option>
              <option value="76109088">EL CRUCERO - BUENAVENTURA - VALLE DEL CAUCA</option>
              <option value="76109089">EL ENCANTO - BUENAVENTURA - VALLE DEL CAUCA</option>
              <option value="76109090">EL LLANO - BUENAVENTURA - VALLE DEL CAUCA</option>
              <option value="76109091">EL SALTO - BUENAVENTURA - VALLE DEL CAUCA</option>
              <option value="76109092">GUADUALITO - BUENAVENTURA - VALLE DEL CAUCA</option>
              <option value="76109093">JOAQUINCITO RESGUARDO INDIGENA - BUENAVENTURA - VALLE DEL CAUCA</option>
              <option value="76109095">LA BALASTRERA - BUENAVENTURA - VALLE DEL CAUCA</option>
              <option value="76109096">LA COMBA - BUENAVENTURA - VALLE DEL CAUCA</option>
              <option value="76109097">LA CONTRA - BUENAVENTURA - VALLE DEL CAUCA</option>
              <option value="76109098">LA FRAGUA - BUENAVENTURA - VALLE DEL CAUCA</option>
              <option value="76109099">PRIMAVERA - BUENAVENTURA - VALLE DEL CAUCA</option>
              <option value="76109100">LA VUELTA - BUENAVENTURA - VALLE DEL CAUCA</option>
              <option value="76109102">LIMONES - BUENAVENTURA - VALLE DEL CAUCA</option>
              <option value="76109104">PAPAYAL 2 - BUENAVENTURA - VALLE DEL CAUCA</option>
              <option value="76109106">SAGRADA FAMILIA - BUENAVENTURA - VALLE DEL CAUCA</option>
              <option value="76109107">SAN ANTONIO - BUENAVENTURA - VALLE DEL CAUCA</option>
              <option value="76109108">SAN ANTOÑITO (YURUMANGUI) - BUENAVENTURA - VALLE DEL CAUCA</option>
              <option value="76109109">SAN ISIDRO (CAJAMBRE) - BUENAVENTURA - VALLE DEL CAUCA</option>
              <option value="76109110">SANTA MARIA - BUENAVENTURA - VALLE DEL CAUCA</option>
              <option value="76109111">SECADERO - BUENAVENTURA - VALLE DEL CAUCA</option>
              <option value="76109112">UMANE - BUENAVENTURA - VALLE DEL CAUCA</option>
              <option value="76109113">VILLA ESTELA - BUENAVENTURA - VALLE DEL CAUCA</option>
              <option value="76109115">ALTO ZARAGOZA - BUENAVENTURA - VALLE DEL CAUCA</option>
              <option value="76109116">BARRIO BUENOS AIRES - BUENAVENTURA - VALLE DEL CAUCA</option>
              <option value="76109118">BRISAS - BUENAVENTURA - VALLE DEL CAUCA</option>
              <option value="76109121">EL PALITO - BUENAVENTURA - VALLE DEL CAUCA</option>
              <option value="76109122">JUAQUINCITO - BUENAVENTURA - VALLE DEL CAUCA</option>
              <option value="76109123">LA BOCANA (VISTA HERMOSA) - BUENAVENTURA - VALLE DEL CAUCA</option>
              <option value="76109124">LA CAUCANA - BUENAVENTURA - VALLE DEL CAUCA</option>
              <option value="76109125">LA LAGUNA - BUENAVENTURA - VALLE DEL CAUCA</option>
              <option value="76109127">SAN ANTONIO 1 - BUENAVENTURA - VALLE DEL CAUCA</option>
              <option value="76109128">SAN ANTONIO 2 - BUENAVENTURA - VALLE DEL CAUCA</option>
              <option value="76109129">ZARAGOZA ALTO 1 - BUENAVENTURA - VALLE DEL CAUCA</option>
              <option value="76109130">ZARAGOZA PUENTE SAN MARTIN 1 - BUENAVENTURA - VALLE DEL CAUCA</option>
              <option value="76109131">ZARAGOZA PUENTE SAN MARTIN 2 - BUENAVENTURA - VALLE DEL CAUCA</option>
              <option value="76111000">GUADALAJARA DE BUGA - VALLE DEL CAUCA</option>
              <option value="76111001">LA CAMPIÑA - GUADALAJARA DE BUGA - VALLE DEL CAUCA</option>
              <option value="76111002">EL PLACER - GUADALAJARA DE BUGA - VALLE DEL CAUCA</option>
              <option value="76111005">EL VINCULO - GUADALAJARA DE BUGA - VALLE DEL CAUCA</option>
              <option value="76111006">LA HABANA - GUADALAJARA DE BUGA - VALLE DEL CAUCA</option>
              <option value="76111007">LA MARIA - GUADALAJARA DE BUGA - VALLE DEL CAUCA</option>
              <option value="76111012">QUEBRADASECA - GUADALAJARA DE BUGA - VALLE DEL CAUCA</option>
              <option value="76111014">ZANJON HONDO - GUADALAJARA DE BUGA - VALLE DEL CAUCA</option>
              <option value="76111016">EL PORVENIR - GUADALAJARA DE BUGA - VALLE DEL CAUCA</option>
              <option value="76111018">PUEBLO NUEVO - GUADALAJARA DE BUGA - VALLE DEL CAUCA</option>
              <option value="76111020">LA MAGDALENA - GUADALAJARA DE BUGA - VALLE DEL CAUCA</option>
              <option value="76111021">EL MANANTIAL - GUADALAJARA DE BUGA - VALLE DEL CAUCA</option>
              <option value="76111022">ALASKA - GUADALAJARA DE BUGA - VALLE DEL CAUCA</option>
              <option value="76111024">LA PALOMERA - GUADALAJARA DE BUGA - VALLE DEL CAUCA</option>
              <option value="76111025">LA UNIDAD - GUADALAJARA DE BUGA - VALLE DEL CAUCA</option>
              <option value="76111026">PUERTO BERTIN - GUADALAJARA DE BUGA - VALLE DEL CAUCA</option>
              <option value="76111027">SAN ANTONIO - GUADALAJARA DE BUGA - VALLE DEL CAUCA</option>
              <option value="76111029">GUADUALEJO - GUADALAJARA DE BUGA - VALLE DEL CAUCA</option>
              <option value="76111030">LA GRANJITA - GUADALAJARA DE BUGA - VALLE DEL CAUCA</option>
              <option value="76113000">BUGALAGRANDE - VALLE DEL CAUCA</option>
              <option value="76113001">CEILAN - BUGALAGRANDE - VALLE DEL CAUCA</option>
              <option value="76113004">EL OVERO (SECTOR POBLADO) - BUGALAGRANDE - VALLE DEL CAUCA</option>
              <option value="76113008">GALICIA - BUGALAGRANDE - VALLE DEL CAUCA</option>
              <option value="76113010">MESTIZAL - BUGALAGRANDE - VALLE DEL CAUCA</option>
              <option value="76113011">PAILA ARRIBA - BUGALAGRANDE - VALLE DEL CAUCA</option>
              <option value="76113013">URIBE URIBE - BUGALAGRANDE - VALLE DEL CAUCA</option>
              <option value="76113016">EL OVERO (SECTOR LA MARIA) - BUGALAGRANDE - VALLE DEL CAUCA</option>
              <option value="76122000">CAICEDONIA - VALLE DEL CAUCA</option>
              <option value="76122006">SAMARIA - CAICEDONIA - VALLE DEL CAUCA</option>
              <option value="76122009">BARRAGAN - CAICEDONIA - VALLE DEL CAUCA</option>
              <option value="76122010">LAS DELICIAS - CAICEDONIA - VALLE DEL CAUCA</option>
              <option value="76122011">VILLA AURES - CAICEDONIA - VALLE DEL CAUCA</option>
              <option value="76126000">DARIEN - CALIMA - VALLE DEL CAUCA</option>
              <option value="76126007">LA GAVIOTA - CALIMA - VALLE DEL CAUCA</option>
              <option value="76126018">LA PLAYA - CALIMA - VALLE DEL CAUCA</option>
              <option value="76130000">CANDELARIA - VALLE DEL CAUCA</option>
              <option value="76130001">BUCHITOLO - CANDELARIA - VALLE DEL CAUCA</option>
              <option value="76130002">EL ARENAL - CANDELARIA - VALLE DEL CAUCA</option>
              <option value="76130003">EL CABUYAL - CANDELARIA - VALLE DEL CAUCA</option>
              <option value="76130004">EL CARMELO - CANDELARIA - VALLE DEL CAUCA</option>
              <option value="76130005">EL LAURO - CANDELARIA - VALLE DEL CAUCA</option>
              <option value="76130006">EL TIPLE - CANDELARIA - VALLE DEL CAUCA</option>
              <option value="76130007">JUANCHITO - CANDELARIA - VALLE DEL CAUCA</option>
              <option value="76130008">VILLA GORGONA - CANDELARIA - VALLE DEL CAUCA</option>
              <option value="76130009">LA REGINA - CANDELARIA - VALLE DEL CAUCA</option>
              <option value="76130011">MADRE VIEJA - CANDELARIA - VALLE DEL CAUCA</option>
              <option value="76130012">SAN JOAQUIN - CANDELARIA - VALLE DEL CAUCA</option>
              <option value="76130014">EL OTOÑO - CANDELARIA - VALLE DEL CAUCA</option>
              <option value="76130015">EL GUALI - CANDELARIA - VALLE DEL CAUCA</option>
              <option value="76130016">EL POBLADO CAMPESTRE - CANDELARIA - VALLE DEL CAUCA</option>
              <option value="76130017">BRISAS DEL FRAILE - CANDELARIA - VALLE DEL CAUCA</option>
              <option value="76130018">CANTALOMOTA - CANDELARIA - VALLE DEL CAUCA</option>
              <option value="76130019">CAUCASECO - CANDELARIA - VALLE DEL CAUCA</option>
              <option value="76130020">DOMINGO LARGO - CANDELARIA - VALLE DEL CAUCA</option>
              <option value="76130021">LA ALBANIA - CANDELARIA - VALLE DEL CAUCA</option>
              <option value="76130022">LA GLORIA - CANDELARIA - VALLE DEL CAUCA</option>
              <option value="76130023">TRES TUSAS - CANDELARIA - VALLE DEL CAUCA</option>
              <option value="76130027">EL SILENCIO - CANDELARIA - VALLE DEL CAUCA</option>
              <option value="76130038">PATIO BONITO - CANDELARIA - VALLE DEL CAUCA</option>
              <option value="76130039">SAN ANDRESITO - CANDELARIA - VALLE DEL CAUCA</option>
              <option value="76147000">CARTAGO - VALLE DEL CAUCA</option>
              <option value="76147005">MODIN - CARTAGO - VALLE DEL CAUCA</option>
              <option value="76147006">PIEDRA DE MOLER - CARTAGO - VALLE DEL CAUCA</option>
              <option value="76147012">GUANABANO - CARTAGO - VALLE DEL CAUCA</option>
              <option value="76147013">GUAYABITO - CARTAGO - VALLE DEL CAUCA</option>
              <option value="76147014">ZANJON CAUCA - CARTAGO - VALLE DEL CAUCA</option>
              <option value="76233000">DAGUA - VALLE DEL CAUCA</option>
              <option value="76233001">ATUNCELA - DAGUA - VALLE DEL CAUCA</option>
              <option value="76233002">BORRERO AYERBE - DAGUA - VALLE DEL CAUCA</option>
              <option value="76233004">CISNEROS - DAGUA - VALLE DEL CAUCA</option>
              <option value="76233005">EL CARMEN - DAGUA - VALLE DEL CAUCA</option>
              <option value="76233007">EL LIMONAR - DAGUA - VALLE DEL CAUCA</option>
              <option value="76233008">EL NARANJO - DAGUA - VALLE DEL CAUCA</option>
              <option value="76233009">EL PALMAR - DAGUA - VALLE DEL CAUCA</option>
              <option value="76233010">EL PIÑAL - DAGUA - VALLE DEL CAUCA</option>
              <option value="76233011">EL QUEREMAL - DAGUA - VALLE DEL CAUCA</option>
              <option value="76233012">EL SALADO - DAGUA - VALLE DEL CAUCA</option>
              <option value="76233017">LOBO GUERRERO - DAGUA - VALLE DEL CAUCA</option>
              <option value="76233019">SAN BERNARDO - DAGUA - VALLE DEL CAUCA</option>
              <option value="76233020">SAN VICENTE - DAGUA - VALLE DEL CAUCA</option>
              <option value="76233021">SANTA MARIA - DAGUA - VALLE DEL CAUCA</option>
              <option value="76233023">TOCOTA - DAGUA - VALLE DEL CAUCA</option>
              <option value="76233025">ZABALETAS - DAGUA - VALLE DEL CAUCA</option>
              <option value="76233027">JUNTAS - DAGUA - VALLE DEL CAUCA</option>
              <option value="76233033">PUEBLO NUEVO - DAGUA - VALLE DEL CAUCA</option>
              <option value="76233035">EL CHILCAL - DAGUA - VALLE DEL CAUCA</option>
              <option value="76233036">KILOMETRO 26 - DAGUA - VALLE DEL CAUCA</option>
              <option value="76233039">LA VIRGEN - DAGUA - VALLE DEL CAUCA</option>
              <option value="76233041">LAS CAMELIAS - DAGUA - VALLE DEL CAUCA</option>
              <option value="76233044">VERGEL - DAGUA - VALLE DEL CAUCA</option>
              <option value="76233045">EL GALPON - DAGUA - VALLE DEL CAUCA</option>
              <option value="76233046">EL RODEO - DAGUA - VALLE DEL CAUCA</option>
              <option value="76233047">KATANGA - DAGUA - VALLE DEL CAUCA</option>
              <option value="76233048">EL CEDRO - DAGUA - VALLE DEL CAUCA</option>
              <option value="76233049">LA DELFINA - DAGUA - VALLE DEL CAUCA</option>
              <option value="76233050">EL EDEN - DAGUA - VALLE DEL CAUCA</option>
              <option value="76233051">PLAYA LARGA - DAGUA - VALLE DEL CAUCA</option>
              <option value="76243000">EL AGUILA - VALLE DEL CAUCA</option>
              <option value="76243002">LA ESPARTA - EL AGUILA - VALLE DEL CAUCA</option>
              <option value="76243005">LA MARIA - QUEBRADAGRANDE - EL AGUILA - VALLE DEL CAUCA</option>
              <option value="76243006">SAN JOSE - EL AGUILA - VALLE DEL CAUCA</option>
              <option value="76243008">CAÑAVERAL - VILLANUEVA - EL AGUILA - VALLE DEL CAUCA</option>
              <option value="76243030">LA QUIEBRA DE SAN PABLO - EL AGUILA - VALLE DEL CAUCA</option>
              <option value="76243031">EL GUAYABO - EL AGUILA - VALLE DEL CAUCA</option>
              <option value="76246000">EL CAIRO - VALLE DEL CAUCA</option>
              <option value="76246001">ALBAN - EL CAIRO - VALLE DEL CAUCA</option>
              <option value="76248000">EL CERRITO - VALLE DEL CAUCA</option>
              <option value="76248003">EL CASTILLO - EL CERRITO - VALLE DEL CAUCA</option>
              <option value="76248005">EL PLACER - EL CERRITO - VALLE DEL CAUCA</option>
              <option value="76248006">EL POMO - EL CERRITO - VALLE DEL CAUCA</option>
              <option value="76248008">SAN ANTONIO - EL CERRITO - VALLE DEL CAUCA</option>
              <option value="76248009">SANTA ELENA - EL CERRITO - VALLE DEL CAUCA</option>
              <option value="76248010">SANTA LUISA - EL CERRITO - VALLE DEL CAUCA</option>
              <option value="76248011">TENERIFE - EL CERRITO - VALLE DEL CAUCA</option>
              <option value="76248014">CAMPOALEGRE - EL CERRITO - VALLE DEL CAUCA</option>
              <option value="76248018">LA HONDA - EL CERRITO - VALLE DEL CAUCA</option>
              <option value="76250000">EL DOVIO - VALLE DEL CAUCA</option>
              <option value="76250003">BITACO - EL DOVIO - VALLE DEL CAUCA</option>
              <option value="76250007">LITUANIA - EL DOVIO - VALLE DEL CAUCA</option>
              <option value="76250013">LA PRADERA - EL DOVIO - VALLE DEL CAUCA</option>
              <option value="76250015">MATECAÑA - EL DOVIO - VALLE DEL CAUCA</option>
              <option value="76250016">CAJAMARCA - EL DOVIO - VALLE DEL CAUCA</option>
              <option value="76275000">FLORIDA - VALLE DEL CAUCA</option>
              <option value="76275004">CHOCOCITO - FLORIDA - VALLE DEL CAUCA</option>
              <option value="76275006">LA DIANA - FLORIDA - VALLE DEL CAUCA</option>
              <option value="76275010">REMOLINO - FLORIDA - VALLE DEL CAUCA</option>
              <option value="76275011">SAN ANTONIO DE LOS CABALLEROS - FLORIDA - VALLE DEL CAUCA</option>
              <option value="76275012">SAN FRANCISCO (EL LLANITO) - FLORIDA - VALLE DEL CAUCA</option>
              <option value="76275014">TARRAGONA - FLORIDA - VALLE DEL CAUCA</option>
              <option value="76275015">EL PEDREGAL - FLORIDA - VALLE DEL CAUCA</option>
              <option value="76275019">LAS GUACAS - FLORIDA - VALLE DEL CAUCA</option>
              <option value="76275021">LOS CALEÑOS - FLORIDA - VALLE DEL CAUCA</option>
              <option value="76275024">EL INGENIO - FLORIDA - VALLE DEL CAUCA</option>
              <option value="76275025">EL TAMBORAL - FLORIDA - VALLE DEL CAUCA</option>
              <option value="76275028">SIMON BOLIVAR - FLORIDA - VALLE DEL CAUCA</option>
              <option value="76306000">GINEBRA - VALLE DEL CAUCA</option>
              <option value="76306001">COSTA RICA - GINEBRA - VALLE DEL CAUCA</option>
              <option value="76306002">LA FLORESTA - GINEBRA - VALLE DEL CAUCA</option>
              <option value="76306005">SABALETAS - GINEBRA - VALLE DEL CAUCA</option>
              <option value="76306008">VILLA VANEGAS - GINEBRA - VALLE DEL CAUCA</option>
              <option value="76318000">GUACARI - VALLE DEL CAUCA</option>
              <option value="76318003">GUABAS - GUACARI - VALLE DEL CAUCA</option>
              <option value="76318004">GUABITAS - GUACARI - VALLE DEL CAUCA</option>
              <option value="76318006">PICHICHI - GUACARI - VALLE DEL CAUCA</option>
              <option value="76318007">SANTA ROSA DE TAPIAS - GUACARI - VALLE DEL CAUCA</option>
              <option value="76318008">SONSO - GUACARI - VALLE DEL CAUCA</option>
              <option value="76318009">ALTO DE GUACAS - GUACARI - VALLE DEL CAUCA</option>
              <option value="76318010">PUENTE ROJO - GUACARI - VALLE DEL CAUCA</option>
              <option value="76318011">CANANGUA - GUACARI - VALLE DEL CAUCA</option>
              <option value="76318012">EL PLACER - GUACARI - VALLE DEL CAUCA</option>
              <option value="76318013">EL TRIUNFO - GUACARI - VALLE DEL CAUCA</option>
              <option value="76318014">GUACAS - GUACARI - VALLE DEL CAUCA</option>
              <option value="76364000">JAMUNDI - VALLE DEL CAUCA</option>
              <option value="76364001">AMPUDIA - JAMUNDI - VALLE DEL CAUCA</option>
              <option value="76364002">BOCAS DEL PALO - JAMUNDI - VALLE DEL CAUCA</option>
              <option value="76364003">GUACHINTE - JAMUNDI - VALLE DEL CAUCA</option>
              <option value="76364004">LA LIBERIA - JAMUNDI - VALLE DEL CAUCA</option>
              <option value="76364005">PASO DE LA BOLSA - JAMUNDI - VALLE DEL CAUCA</option>
              <option value="76364006">POTRERITO - JAMUNDI - VALLE DEL CAUCA</option>
              <option value="76364008">PUENTE VELEZ - JAMUNDI - VALLE DEL CAUCA</option>
              <option value="76364009">QUINAMAYO - JAMUNDI - VALLE DEL CAUCA</option>
              <option value="76364010">ROBLES - JAMUNDI - VALLE DEL CAUCA</option>
              <option value="76364011">SAN ANTONIO - JAMUNDI - VALLE DEL CAUCA</option>
              <option value="76364012">SAN VICENTE - JAMUNDI - VALLE DEL CAUCA</option>
              <option value="76364013">TIMBA - JAMUNDI - VALLE DEL CAUCA</option>
              <option value="76364014">VILLA COLOMBIA - JAMUNDI - VALLE DEL CAUCA</option>
              <option value="76364015">VILLAPAZ - JAMUNDI - VALLE DEL CAUCA</option>
              <option value="76364016">LA ESTRELLA - JAMUNDI - VALLE DEL CAUCA</option>
              <option value="76364018">LA MESETA - JAMUNDI - VALLE DEL CAUCA</option>
              <option value="76364019">LA VENTURA - JAMUNDI - VALLE DEL CAUCA</option>
              <option value="76364021">SAN ISIDRO - JAMUNDI - VALLE DEL CAUCA</option>
              <option value="76364022">EL GUAVAL - JAMUNDI - VALLE DEL CAUCA</option>
              <option value="76364023">EL TRIUNFO - JAMUNDI - VALLE DEL CAUCA</option>
              <option value="76364024">CASCARILLAL - JAMUNDI - VALLE DEL CAUCA</option>
              <option value="76364026">GATO DE MONTE - JAMUNDI - VALLE DEL CAUCA</option>
              <option value="76364032">PUEBLO NUEVO - JAMUNDI - VALLE DEL CAUCA</option>
              <option value="76364036">CONDOMINIO - JAMUNDI - VALLE DEL CAUCA</option>
              <option value="76377000">LA CUMBRE - VALLE DEL CAUCA</option>
              <option value="76377001">BITACO - LA CUMBRE - VALLE DEL CAUCA</option>
              <option value="76377002">LA MARIA - LA CUMBRE - VALLE DEL CAUCA</option>
              <option value="76377003">LOMITAS - LA CUMBRE - VALLE DEL CAUCA</option>
              <option value="76377004">PAVAS - LA CUMBRE - VALLE DEL CAUCA</option>
              <option value="76377005">PUENTE PALO - LA CUMBRE - VALLE DEL CAUCA</option>
              <option value="76377008">ARBOLEDAS - LA CUMBRE - VALLE DEL CAUCA</option>
              <option value="76377009">JIGUALES - LA CUMBRE - VALLE DEL CAUCA</option>
              <option value="76377010">PAVITAS - LA CUMBRE - VALLE DEL CAUCA</option>
              <option value="76377011">TRES ESQUINAS - LA CUMBRE - VALLE DEL CAUCA</option>
              <option value="76377012">LA VENTURA - LA CUMBRE - VALLE DEL CAUCA</option>
              <option value="76400000">LA UNION - VALLE DEL CAUCA</option>
              <option value="76400005">QUEBRADA GRANDE - LA UNION - VALLE DEL CAUCA</option>
              <option value="76400006">SAN LUIS - LA UNION - VALLE DEL CAUCA</option>
              <option value="76400008">SABANAZO - LA UNION - VALLE DEL CAUCA</option>
              <option value="76400009">CAMPO ALEGRE - LA UNION - VALLE DEL CAUCA</option>
              <option value="76400010">EL GUASIMO - LA UNION - VALLE DEL CAUCA</option>
              <option value="76400011">EL LUCERO - LA UNION - VALLE DEL CAUCA</option>
              <option value="76400012">LA CAMPESINA - LA UNION - VALLE DEL CAUCA</option>
              <option value="76400013">PAJARO DE ORO - LA UNION - VALLE DEL CAUCA</option>
              <option value="76403000">LA VICTORIA - VALLE DEL CAUCA</option>
              <option value="76403003">HOLGUIN - LA VICTORIA - VALLE DEL CAUCA</option>
              <option value="76403004">MIRAVALLES - LA VICTORIA - VALLE DEL CAUCA</option>
              <option value="76403005">RIVERALTA - LA VICTORIA - VALLE DEL CAUCA</option>
              <option value="76403006">SAN JOSE - LA VICTORIA - VALLE DEL CAUCA</option>
              <option value="76403007">SAN PEDRO - LA VICTORIA - VALLE DEL CAUCA</option>
              <option value="76403009">TAGUALES - LA VICTORIA - VALLE DEL CAUCA</option>
              <option value="76497000">OBANDO - VALLE DEL CAUCA</option>
              <option value="76497001">CRUCES - OBANDO - VALLE DEL CAUCA</option>
              <option value="76497002">EL CHUZO - OBANDO - VALLE DEL CAUCA</option>
              <option value="76497003">JUAN DIAZ - OBANDO - VALLE DEL CAUCA</option>
              <option value="76497005">PUERTO MOLINA - OBANDO - VALLE DEL CAUCA</option>
              <option value="76497006">PUERTO SAMARIA - OBANDO - VALLE DEL CAUCA</option>
              <option value="76497007">SAN ISIDRO - OBANDO - VALLE DEL CAUCA</option>
              <option value="76497008">VILLA RODAS - OBANDO - VALLE DEL CAUCA</option>
              <option value="76520000">PALMIRA - VALLE DEL CAUCA</option>
              <option value="76520001">AGUACLARA - PALMIRA - VALLE DEL CAUCA</option>
              <option value="76520002">AMAIME - PALMIRA - VALLE DEL CAUCA</option>
              <option value="76520004">BARRANCAS - PALMIRA - VALLE DEL CAUCA</option>
              <option value="76520005">BOLO ALIZAL - PALMIRA - VALLE DEL CAUCA</option>
              <option value="76520006">BOLO LA ITALIA - PALMIRA - VALLE DEL CAUCA</option>
              <option value="76520007">BOLO SAN ISIDRO - PALMIRA - VALLE DEL CAUCA</option>
              <option value="76520008">BOYACA - PALMIRA - VALLE DEL CAUCA</option>
              <option value="76520009">CALUCE - PLAN DE VIVIENDA LOS GUAYABOS - PALMIRA - VALLE DEL CAUCA</option>
              <option value="76520010">CAUCASECO - PALMIRA - VALLE DEL CAUCA</option>
              <option value="76520011">COMBIA - PALMIRA - VALLE DEL CAUCA</option>
              <option value="76520013">CHONTADURO - PALMIRA - VALLE DEL CAUCA</option>
              <option value="76520014">GUANABANAL - PALMIRA - VALLE DEL CAUCA</option>
              <option value="76520015">GUAYABAL - PALMIRA - VALLE DEL CAUCA</option>
              <option value="76520016">JUANCHITO - PALMIRA - VALLE DEL CAUCA</option>
              <option value="76520017">LA ACEQUIA - PALMIRA - VALLE DEL CAUCA</option>
              <option value="76520018">LA HERRADURA - PALMIRA - VALLE DEL CAUCA</option>
              <option value="76520019">LA QUISQUINA - PALMIRA - VALLE DEL CAUCA</option>
              <option value="76520020">LA TORRE - PALMIRA - VALLE DEL CAUCA</option>
              <option value="76520022">MATAPALO - PALMIRA - VALLE DEL CAUCA</option>
              <option value="76520023">OBANDO - PALMIRA - VALLE DEL CAUCA</option>
              <option value="76520024">PALMASECA - PALMIRA - VALLE DEL CAUCA</option>
              <option value="76520025">POTRERILLO - PALMIRA - VALLE DEL CAUCA</option>
              <option value="76520026">ROZO - PALMIRA - VALLE DEL CAUCA</option>
              <option value="76520027">TABLONES - PALMIRA - VALLE DEL CAUCA</option>
              <option value="76520028">TENJO - PALMIRA - VALLE DEL CAUCA</option>
              <option value="76520029">TIENDA NUEVA - PALMIRA - VALLE DEL CAUCA</option>
              <option value="76520032">LA BUITRERA - PALMIRA - VALLE DEL CAUCA</option>
              <option value="76520033">LA PAMPA - PALMIRA - VALLE DEL CAUCA</option>
              <option value="76520035">LA BOLSA - PALMIRA - VALLE DEL CAUCA</option>
              <option value="76520038">LA DOLORES - PALMIRA - VALLE DEL CAUCA</option>
              <option value="76520039">LA CASCADA - PALMIRA - VALLE DEL CAUCA</option>
              <option value="76520041">BOLO BARRIO NUEVO - PALMIRA - VALLE DEL CAUCA</option>
              <option value="76520043">BOLOMADRE VIEJA - PALMIRA - VALLE DEL CAUCA</option>
              <option value="76520044">LA UNION - PALMIRA - VALLE DEL CAUCA</option>
              <option value="76520045">PILES - PALMIRA - VALLE DEL CAUCA</option>
              <option value="76520047">SAN ANTONIO DE LAS PALMAS - PALMIRA - VALLE DEL CAUCA</option>
              <option value="76520048">TRES TUSAS - PALMIRA - VALLE DEL CAUCA</option>
              <option value="76520049">BOLO ITALIA 1 - PALMIRA - VALLE DEL CAUCA</option>
              <option value="76520050">BOLO ITALIA 2 - PALMIRA - VALLE DEL CAUCA</option>
              <option value="76520051">CONDOMINIO CAMPESTRE LA GONZALEZ - PALMIRA - VALLE DEL CAUCA</option>
              <option value="76520052">LA BUITRERA 1 - PALMIRA - VALLE DEL CAUCA</option>
              <option value="76520053">PUEBLO NUEVO - PALMIRA - VALLE DEL CAUCA</option>
              <option value="76563000">PRADERA - VALLE DEL CAUCA</option>
              <option value="76563011">LA GRANJA - PRADERA - VALLE DEL CAUCA</option>
              <option value="76563013">LA TUPIA - PRADERA - VALLE DEL CAUCA</option>
              <option value="76563014">LOMITAS - PRADERA - VALLE DEL CAUCA</option>
              <option value="76563018">POTRERITO - PRADERA - VALLE DEL CAUCA</option>
              <option value="76563024">EL RECREO - PRADERA - VALLE DEL CAUCA</option>
              <option value="76563025">LA FERIA - PRADERA - VALLE DEL CAUCA</option>
              <option value="76563028">LA CRUZ - PRADERA - VALLE DEL CAUCA</option>
              <option value="76606000">RESTREPO - VALLE DEL CAUCA</option>
              <option value="76606008">SAN SALVADOR - RESTREPO - VALLE DEL CAUCA</option>
              <option value="76606016">BARRIO LA INDEPENDENCIA - RESTREPO - VALLE DEL CAUCA</option>
              <option value="76616000">RIOFRIO - VALLE DEL CAUCA</option>
              <option value="76616002">FENICIA - RIOFRIO - VALLE DEL CAUCA</option>
              <option value="76616003">PALMA - LA CUCHILLA - RIOFRIO - VALLE DEL CAUCA</option>
              <option value="76616005">LA ZULIA - RIOFRIO - VALLE DEL CAUCA</option>
              <option value="76616006">MADRIGAL - RIOFRIO - VALLE DEL CAUCA</option>
              <option value="76616007">PORTUGAL DE PIEDRAS - RIOFRIO - VALLE DEL CAUCA</option>
              <option value="76616009">SALONICA - RIOFRIO - VALLE DEL CAUCA</option>
              <option value="76616010">EL JAGUAL - RIOFRIO - VALLE DEL CAUCA</option>
              <option value="76616013">PUERTO FENICIA - RIOFRIO - VALLE DEL CAUCA</option>
              <option value="76616014">LOS ALPES - RIOFRIO - VALLE DEL CAUCA</option>
              <option value="76616015">LA SULTANA - RIOFRIO - VALLE DEL CAUCA</option>
              <option value="76616016">LAS BRISAS - RIOFRIO - VALLE DEL CAUCA</option>
              <option value="76616017">LOS ESTRECHOS - RIOFRIO - VALLE DEL CAUCA</option>
              <option value="76622000">ROLDANILLO - VALLE DEL CAUCA</option>
              <option value="76622002">EL RETIRO - ROLDANILLO - VALLE DEL CAUCA</option>
              <option value="76622003">HIGUERONCITO - ROLDANILLO - VALLE DEL CAUCA</option>
              <option value="76622007">MORELIA - ROLDANILLO - VALLE DEL CAUCA</option>
              <option value="76622009">SANTA RITA - ROLDANILLO - VALLE DEL CAUCA</option>
              <option value="76622020">PALMAR GUAYABAL - ROLDANILLO - VALLE DEL CAUCA</option>
              <option value="76622022">TIERRA BLANCA - ROLDANILLO - VALLE DEL CAUCA</option>
              <option value="76670000">SAN PEDRO - VALLE DEL CAUCA</option>
              <option value="76670002">BUENOS AIRES - SAN PEDRO - VALLE DEL CAUCA</option>
              <option value="76670007">PRESIDENTE - SAN PEDRO - VALLE DEL CAUCA</option>
              <option value="76670008">SAN JOSE - SAN PEDRO - VALLE DEL CAUCA</option>
              <option value="76670009">TODOS LOS SANTOS - SAN PEDRO - VALLE DEL CAUCA</option>
              <option value="76670010">GUAYABAL - SAN PEDRO - VALLE DEL CAUCA</option>
              <option value="76670011">MONTE GRANDE - SAN PEDRO - VALLE DEL CAUCA</option>
              <option value="76736000">SEVILLA - VALLE DEL CAUCA</option>
              <option value="76736004">COROZAL - SEVILLA - VALLE DEL CAUCA</option>
              <option value="76736005">CUMBARCO - SEVILLA - VALLE DEL CAUCA</option>
              <option value="76736008">LA CUCHILLA - SEVILLA - VALLE DEL CAUCA</option>
              <option value="76736014">SAN ANTONIO - SEVILLA - VALLE DEL CAUCA</option>
              <option value="76736018">QUEBRADANUEVA - SEVILLA - VALLE DEL CAUCA</option>
              <option value="76736030">BUENOS AIRES - SEVILLA - VALLE DEL CAUCA</option>
              <option value="76823000">TORO - VALLE DEL CAUCA</option>
              <option value="76823001">BOHIO - TORO - VALLE DEL CAUCA</option>
              <option value="76823006">SAN ANTONIO - TORO - VALLE DEL CAUCA</option>
              <option value="76823007">SAN FRANCISCO - TORO - VALLE DEL CAUCA</option>
              <option value="76823008">LA QUIEBRA - TORO - VALLE DEL CAUCA</option>
              <option value="76828000">TRUJILLO - VALLE DEL CAUCA</option>
              <option value="76828002">ANDINAPOLIS - TRUJILLO - VALLE DEL CAUCA</option>
              <option value="76828003">CRISTALES - TRUJILLO - VALLE DEL CAUCA</option>
              <option value="76828004">DOS QUEBRADAS - TRUJILLO - VALLE DEL CAUCA</option>
              <option value="76828006">HUASANO - TRUJILLO - VALLE DEL CAUCA</option>
              <option value="76828007">ROBLEDO - TRUJILLO - VALLE DEL CAUCA</option>
              <option value="76828008">SAN ISIDRO - TRUJILLO - VALLE DEL CAUCA</option>
              <option value="76828010">VENECIA - TRUJILLO - VALLE DEL CAUCA</option>
              <option value="76828013">LA SONORA - TRUJILLO - VALLE DEL CAUCA</option>
              <option value="76834000">TULUA - VALLE DEL CAUCA</option>
              <option value="76834001">AGUACLARA - TULUA - VALLE DEL CAUCA</option>
              <option value="76834003">BARRAGAN - TULUA - VALLE DEL CAUCA</option>
              <option value="76834004">BOCAS DE TULUA - TULUA - VALLE DEL CAUCA</option>
              <option value="76834005">EL PICACHO - TULUA - VALLE DEL CAUCA</option>
              <option value="76834007">PUERTO FRAZADAS - TULUA - VALLE DEL CAUCA</option>
              <option value="76834009">LA IBERIA - TULUA - VALLE DEL CAUCA</option>
              <option value="76834010">LA MARINA - TULUA - VALLE DEL CAUCA</option>
              <option value="76834011">LA MORALIA - TULUA - VALLE DEL CAUCA</option>
              <option value="76834012">LA PALMERA - TULUA - VALLE DEL CAUCA</option>
              <option value="76834015">MONTELORO - TULUA - VALLE DEL CAUCA</option>
              <option value="76834016">NARIÑO - TULUA - VALLE DEL CAUCA</option>
              <option value="76834021">SANTA LUCIA - TULUA - VALLE DEL CAUCA</option>
              <option value="76834023">TRES ESQUINAS - TULUA - VALLE DEL CAUCA</option>
              <option value="76834025">CAMPOALEGRE - TULUA - VALLE DEL CAUCA</option>
              <option value="76834026">LA RIVERA - TULUA - VALLE DEL CAUCA</option>
              <option value="76834029">CIENEGUETA - TULUA - VALLE DEL CAUCA</option>
              <option value="76834030">GATO NEGRO - TULUA - VALLE DEL CAUCA</option>
              <option value="76834032">PALOMESTIZO - TULUA - VALLE DEL CAUCA</option>
              <option value="76834033">LA COLINA - TULUA - VALLE DEL CAUCA</option>
              <option value="76845000">ULLOA - VALLE DEL CAUCA</option>
              <option value="76845002">MOCTEZUMA - ULLOA - VALLE DEL CAUCA</option>
              <option value="76845005">DINAMARCA - ULLOA - VALLE DEL CAUCA</option>
              <option value="76863000">VERSALLES - VALLE DEL CAUCA</option>
              <option value="76863001">CAMPOALEGRE - VERSALLES - VALLE DEL CAUCA</option>
              <option value="76863002">EL BALSAL - VERSALLES - VALLE DEL CAUCA</option>
              <option value="76863007">LA FLORIDA - VERSALLES - VALLE DEL CAUCA</option>
              <option value="76863012">PUERTO NUEVO - VERSALLES - VALLE DEL CAUCA</option>
              <option value="76863014">MURRAPAL - VERSALLES - VALLE DEL CAUCA</option>
              <option value="76863017">LA PLAYA - VERSALLES - VALLE DEL CAUCA</option>
              <option value="76863019">LA CABAÑA - VERSALLES - VALLE DEL CAUCA</option>
              <option value="76869000">VIJES - VALLE DEL CAUCA</option>
              <option value="76869001">CACHIMBAL - VIJES - VALLE DEL CAUCA</option>
              <option value="76869003">EL PORVENIR - VIJES - VALLE DEL CAUCA</option>
              <option value="76869004">LA FRESNEDA - VIJES - VALLE DEL CAUCA</option>
              <option value="76869007">LA RIVERA - VIJES - VALLE DEL CAUCA</option>
              <option value="76869008">EL TAMBOR - VIJES - VALLE DEL CAUCA</option>
              <option value="76869010">VIDAL - VIJES - VALLE DEL CAUCA</option>
              <option value="76890000">YOTOCO - VALLE DEL CAUCA</option>
              <option value="76890001">EL CANEY - YOTOCO - VALLE DEL CAUCA</option>
              <option value="76890003">JIGUALES - YOTOCO - VALLE DEL CAUCA</option>
              <option value="76890004">RAYITO - YOTOCO - VALLE DEL CAUCA</option>
              <option value="76890005">LAS DELICIAS - YOTOCO - VALLE DEL CAUCA</option>
              <option value="76890006">MEDIACANOA - YOTOCO - VALLE DEL CAUCA</option>
              <option value="76890007">MIRAVALLE - YOTOCO - VALLE DEL CAUCA</option>
              <option value="76890008">PUENTETIERRA - YOTOCO - VALLE DEL CAUCA</option>
              <option value="76890009">SAN ANTONIO DE PIEDRAS - YOTOCO - VALLE DEL CAUCA</option>
              <option value="76890011">CAMPOALEGRE - YOTOCO - VALLE DEL CAUCA</option>
              <option value="76890013">LOS PLANES - YOTOCO - VALLE DEL CAUCA</option>
              <option value="76890014">PUNTA BRAVA - YOTOCO - VALLE DEL CAUCA</option>
              <option value="76892000">YUMBO - VALLE DEL CAUCA</option>
              <option value="76892002">DAPA LA VEGA - YUMBO - VALLE DEL CAUCA</option>
              <option value="76892004">MONTAÑITAS - YUMBO - VALLE DEL CAUCA</option>
              <option value="76892005">MULALO - YUMBO - VALLE DEL CAUCA</option>
              <option value="76892008">SAN MARCOS - YUMBO - VALLE DEL CAUCA</option>
              <option value="76892009">SANTA INES - YUMBO - VALLE DEL CAUCA</option>
              <option value="76892013">DAPA EL RINCON - YUMBO - VALLE DEL CAUCA</option>
              <option value="76892014">EL PEDREGAL - YUMBO - VALLE DEL CAUCA</option>
              <option value="76892015">MIRAVALLE NORTE - YUMBO - VALLE DEL CAUCA</option>
              <option value="76892017">ARROYOHONDO - YUMBO - VALLE DEL CAUCA</option>
              <option value="76892018">EL CHOCHO - YUMBO - VALLE DEL CAUCA</option>
              <option value="76892019">MANGA VIEJA - YUMBO - VALLE DEL CAUCA</option>
              <option value="76892020">MIRAVALLE DAPA - YUMBO - VALLE DEL CAUCA</option>
              <option value="76892021">PILAS DE DAPA - YUMBO - VALLE DEL CAUCA</option>
              <option value="76895000">ZARZAL - VALLE DEL CAUCA</option>
              <option value="76895002">LA PAILA - ZARZAL - VALLE DEL CAUCA</option>
              <option value="76895003">LIMONES - ZARZAL - VALLE DEL CAUCA</option>
              <option value="76895004">QUEBRADANUEVA - ZARZAL - VALLE DEL CAUCA</option>
              <option value="76895005">VALLEJUELO - ZARZAL - VALLE DEL CAUCA</option>
              <option value="76895008">ESTACION CAICEDONIA - ZARZAL - VALLE DEL CAUCA</option>
              <option value="81001000">ARAUCA - ARAUCA</option>
              <option value="81001001">CLARINETERO - ARAUCA - ARAUCA</option>
              <option value="81001017">EL CARACOL - ARAUCA - ARAUCA</option>
              <option value="81001020">MONSERRATE - ARAUCA - ARAUCA</option>
              <option value="81001021">LAS NUBES - ARAUCA - ARAUCA</option>
              <option value="81001022">MANHATAN - ARAUCA - ARAUCA</option>
              <option value="81065000">ARAUQUITA - ARAUCA</option>
              <option value="81065001">CARRETERO - ARAUQUITA - ARAUCA</option>
              <option value="81065002">EL TRONCAL - ARAUQUITA - ARAUCA</option>
              <option value="81065004">SAN LORENZO - ARAUQUITA - ARAUCA</option>
              <option value="81065005">LA PAZ - ARAUQUITA - ARAUCA</option>
              <option value="81065007">LA REINERA (GAVIOTA) - ARAUQUITA - ARAUCA</option>
              <option value="81065008">LA ESMERALDA (JUJUA) - ARAUQUITA - ARAUCA</option>
              <option value="81065009">AGUACHICA - ARAUQUITA - ARAUCA</option>
              <option value="81065010">EL CAUCHO - ARAUQUITA - ARAUCA</option>
              <option value="81065016">LOS CHORROS - ARAUQUITA - ARAUCA</option>
              <option value="81065017">PANAMA DE ARAUCA - ARAUQUITA - ARAUCA</option>
              <option value="81065019">BRISAS DEL CARANAL - ARAUQUITA - ARAUCA</option>
              <option value="81065020">EL OASIS - ARAUQUITA - ARAUCA</option>
              <option value="81065021">EL TRIUNFO - ARAUQUITA - ARAUCA</option>
              <option value="81065022">LA PESQUERA - ARAUQUITA - ARAUCA</option>
              <option value="81065023">EL CAMPAMENTO - ARAUQUITA - ARAUCA</option>
              <option value="81065025">ARENOSA - ARAUQUITA - ARAUCA</option>
              <option value="81065026">BOCAS DEL ELE - ARAUQUITA - ARAUCA</option>
              <option value="81065027">CAÑO HONDO - ARAUQUITA - ARAUCA</option>
              <option value="81065028">EL CAMPING - ARAUQUITA - ARAUCA</option>
              <option value="81065029">FILIPINAS - ARAUQUITA - ARAUCA</option>
              <option value="81065030">JARDINES - ARAUQUITA - ARAUCA</option>
              <option value="81065031">LOS COLONOS - ARAUQUITA - ARAUCA</option>
              <option value="81065032">MATECAÑA - ARAUQUITA - ARAUCA</option>
              <option value="81065033">PUEBLO NUEVO - ARAUQUITA - ARAUCA</option>
              <option value="81065034">SITIO NUEVO - ARAUQUITA - ARAUCA</option>
              <option value="81220000">CRAVO NORTE - ARAUCA</option>
              <option value="81300000">FORTUL - ARAUCA</option>
              <option value="81300002">CARANAL - FORTUL - ARAUCA</option>
              <option value="81300003">LA VEINTE - FORTUL - ARAUCA</option>
              <option value="81300006">EL MORDISCO - FORTUL - ARAUCA</option>
              <option value="81300007">PALMARITO - FORTUL - ARAUCA</option>
              <option value="81300009">SITIO NUEVO - FORTUL - ARAUCA</option>
              <option value="81300011">TOLUA - FORTUL - ARAUCA</option>
              <option value="81591000">PUERTO RONDON - ARAUCA</option>
              <option value="81591006">SAN IGNACIO - PUERTO RONDON - ARAUCA</option>
              <option value="81736000">SARAVENA - ARAUCA</option>
              <option value="81736002">LA YE DEL CHARO - SARAVENA - ARAUCA</option>
              <option value="81736006">PUERTO LLERAS - SARAVENA - ARAUCA</option>
              <option value="81736007">AGUA SANTA - SARAVENA - ARAUCA</option>
              <option value="81736008">PUERTO NARIÑO - SARAVENA - ARAUCA</option>
              <option value="81736009">BARRANCONES - SARAVENA - ARAUCA</option>
              <option value="81736010">BARRIO LOCO - SARAVENA - ARAUCA</option>
              <option value="81736011">CAÑO SECO - SARAVENA - ARAUCA</option>
              <option value="81736012">LA PAJUILA - SARAVENA - ARAUCA</option>
              <option value="81736013">LA YE DEL GARROTAZO - SARAVENA - ARAUCA</option>
              <option value="81736014">PUERTO CONTRERAS - SARAVENA - ARAUCA</option>
              <option value="81736017">REMOLINO - SARAVENA - ARAUCA</option>
              <option value="81794000">TAME - ARAUCA</option>
              <option value="81794001">BETOYES - TAME - ARAUCA</option>
              <option value="81794004">COROCITO - TAME - ARAUCA</option>
              <option value="81794006">PUERTO GAITAN - TAME - ARAUCA</option>
              <option value="81794009">PUERTO SAN SALVADOR - TAME - ARAUCA</option>
              <option value="81794014">LA HOLANDA - TAME - ARAUCA</option>
              <option value="81794015">PUENTE TABLA - TAME - ARAUCA</option>
              <option value="81794019">BOTALON - TAME - ARAUCA</option>
              <option value="81794020">PUERTO MIRANDA - TAME - ARAUCA</option>
              <option value="81794021">ALTO CAUCA - TAME - ARAUCA</option>
              <option value="81794022">FLOR AMARILLO - TAME - ARAUCA</option>
              <option value="81794024">LAS MALVINAS - TAME - ARAUCA</option>
              <option value="81794026">PUEBLO SECO - TAME - ARAUCA</option>
              <option value="81794027">SANTO DOMINGO - TAME - ARAUCA</option>
              <option value="81794028">PUEBLO SUCIO - TAME - ARAUCA</option>
              <option value="85001000">YOPAL - CASANARE</option>
              <option value="85001001">EL MORRO - YOPAL - CASANARE</option>
              <option value="85001002">LA CHAPARRERA - YOPAL - CASANARE</option>
              <option value="85001003">TILODIRAN - YOPAL - CASANARE</option>
              <option value="85001005">EL CHARTE - YOPAL - CASANARE</option>
              <option value="85001006">SANTAFE DE MORICHAL - YOPAL - CASANARE</option>
              <option value="85001007">QUEBRADA SECA - YOPAL - CASANARE</option>
              <option value="85001010">LA GUAFILLA - YOPAL - CASANARE</option>
              <option value="85001011">LA LLANERITA - YOPAL - CASANARE</option>
              <option value="85001012">LA NIATA - YOPAL - CASANARE</option>
              <option value="85001013">PUNTO NUEVO - YOPAL - CASANARE</option>
              <option value="85010000">AGUAZUL - CASANARE</option>
              <option value="85010001">CUPIAGUA - AGUAZUL - CASANARE</option>
              <option value="85010002">MONTERRALO - AGUAZUL - CASANARE</option>
              <option value="85010003">SAN BENITO - AGUAZUL - CASANARE</option>
              <option value="85010005">SAN JOSE - AGUAZUL - CASANARE</option>
              <option value="85010006">UNETE - AGUAZUL - CASANARE</option>
              <option value="85010010">PUENTE CUSIANA - AGUAZUL - CASANARE</option>
              <option value="85010014">LLANO LINDO - AGUAZUL - CASANARE</option>
              <option value="85010015">PLAN BRISAS - AGUAZUL - CASANARE</option>
              <option value="85015000">CHAMEZA - CASANARE</option>
              <option value="85125000">HATO COROZAL - CASANARE</option>
              <option value="85125002">CHIRE - HATO COROZAL - CASANARE</option>
              <option value="85125003">LA FRONTERA - LA CHAPA - HATO COROZAL - CASANARE</option>
              <option value="85125005">PUERTO COLOMBIA - HATO COROZAL - CASANARE</option>
              <option value="85125010">SANTA RITA - HATO COROZAL - CASANARE</option>
              <option value="85125011">SAN JOSE DE ARIPORO - HATO COROZAL - CASANARE</option>
              <option value="85125016">ROSA BLANCA - HATO COROZAL - CASANARE</option>
              <option value="85125017">PUEBLO NUEVO - HATO COROZAL - CASANARE</option>
              <option value="85136000">LA SALINA - CASANARE</option>
              <option value="85139000">MANI - CASANARE</option>
              <option value="85139001">GUAFALPINTADO - MANI - CASANARE</option>
              <option value="85139003">GAVIOTAS - MANI - CASANARE</option>
              <option value="85139005">SANTA HELENA DE CUSIVA - MANI - CASANARE</option>
              <option value="85139007">CHAVINAVE - MANI - CASANARE</option>
              <option value="85162000">MONTERREY - CASANARE</option>
              <option value="85162001">PALONEGRO - MONTERREY - CASANARE</option>
              <option value="85162002">BRISAS DE LLANO - MONTERREY - CASANARE</option>
              <option value="85162004">EL PORVENIR - MONTERREY - CASANARE</option>
              <option value="85162006">VILLA CAROLA - MONTERREY - CASANARE</option>
              <option value="85162007">LA HORQUETA - MONTERREY - CASANARE</option>
              <option value="85162008">LA ESTRELLA - MONTERREY - CASANARE</option>
              <option value="85225000">NUNCHIA - CASANARE</option>
              <option value="85225016">LA YOPALOSA - NUNCHIA - CASANARE</option>
              <option value="85230000">OROCUE - CASANARE</option>
              <option value="85230003">EL ALGARROBO - OROCUE - CASANARE</option>
              <option value="85230011">CARRIZALES - OROCUE - CASANARE</option>
              <option value="85230012">DUYA (RESGUARDO) - OROCUE - CASANARE</option>
              <option value="85250000">PAZ DE ARIPORO - CASANARE</option>
              <option value="85250001">BOCAS DE LA HERMOSA - PAZ DE ARIPORO - CASANARE</option>
              <option value="85250002">CENTRO GAITAN - PAZ DE ARIPORO - CASANARE</option>
              <option value="85250003">CAÑO CHIQUITO - PAZ DE ARIPORO - CASANARE</option>
              <option value="85250004">LA AGUADA - PAZ DE ARIPORO - CASANARE</option>
              <option value="85250006">MONTAÑA DEL TOTUMO - PAZ DE ARIPORO - CASANARE</option>
              <option value="85250007">LAS GUAMAS - PAZ DE ARIPORO - CASANARE</option>
              <option value="85250008">RINCON HONDO - PAZ DE ARIPORO - CASANARE</option>
              <option value="85263000">PORE - CASANARE</option>
              <option value="85263001">EL BANCO - PORE - CASANARE</option>
              <option value="85263002">LA PLATA - PORE - CASANARE</option>
              <option value="85279000">RECETOR - CASANARE</option>
              <option value="85279002">PUEBLO NUEVO - RECETOR - CASANARE</option>
              <option value="85300000">SABANALARGA - CASANARE</option>
              <option value="85300001">AGUACLARA - SABANALARGA - CASANARE</option>
              <option value="85300003">EL SECRETO - SABANALARGA - CASANARE</option>
              <option value="85315000">SACAMA - CASANARE</option>
              <option value="85325000">SAN LUIS DE PALENQUE - CASANARE</option>
              <option value="85325002">MIRAMAR DE GUANAPALO - SAN LUIS DE PALENQUE - CASANARE</option>
              <option value="85325004">EL PALMAR DE GUANAPALO - SAN LUIS DE PALENQUE - CASANARE</option>
              <option value="85325005">JAGUEYES - SAN LUIS DE PALENQUE - CASANARE</option>
              <option value="85400000">TAMARA - CASANARE</option>
              <option value="85400002">TABLON DE TAMARA - TAMARA - CASANARE</option>
              <option value="85400004">TEISLANDIA - TAMARA - CASANARE</option>
              <option value="85410000">TAURAMENA - CASANARE</option>
              <option value="85410003">CARUPANA - TAURAMENA - CASANARE</option>
              <option value="85410004">TUNUPE - TAURAMENA - CASANARE</option>
              <option value="85410005">PASO CUSIANA - TAURAMENA - CASANARE</option>
              <option value="85410006">RAIZAL - TAURAMENA - CASANARE</option>
              <option value="85430000">TRINIDAD - CASANARE</option>
              <option value="85430001">BOCAS DEL PAUTO - TRINIDAD - CASANARE</option>
              <option value="85430007">EL CONVENTO - TRINIDAD - CASANARE</option>
              <option value="85430008">SANTA IRENE - TRINIDAD - CASANARE</option>
              <option value="85440000">VILLANUEVA - CASANARE</option>
              <option value="85440001">CARIBAYONA - VILLANUEVA - CASANARE</option>
              <option value="85440002">SANTA HELENA DE UPIA - VILLANUEVA - CASANARE</option>
              <option value="85440003">SAN AGUSTIN - VILLANUEVA - CASANARE</option>
              <option value="86001000">MOCOA - PUTUMAYO</option>
              <option value="86001002">EL PEPINO - MOCOA - PUTUMAYO</option>
              <option value="86001003">PUEBLO VIEJO - MOCOA - PUTUMAYO</option>
              <option value="86001004">PUERTO LIMON - MOCOA - PUTUMAYO</option>
              <option value="86001006">SAN ANTONIO - MOCOA - PUTUMAYO</option>
              <option value="86001009">YUNGUILLO - MOCOA - PUTUMAYO</option>
              <option value="86001014">LA TEBAIDA - MOCOA - PUTUMAYO</option>
              <option value="86001016">ALTO AFAN - MOCOA - PUTUMAYO</option>
              <option value="86001017">BRISAS DEL SOL - MOCOA - PUTUMAYO</option>
              <option value="86001018">PLANADAS - MOCOA - PUTUMAYO</option>
              <option value="86001019">RUMIYACO - MOCOA - PUTUMAYO</option>
              <option value="86001020">SAN ANTONIO 2 - MOCOA - PUTUMAYO</option>
              <option value="86219000">COLON - PUTUMAYO</option>
              <option value="86219001">SAN PEDRO - COLON - PUTUMAYO</option>
              <option value="86219002">LAS PALMAS - COLON - PUTUMAYO</option>
              <option value="86219003">MICHUACAN - COLON - PUTUMAYO</option>
              <option value="86320000">ORITO - PUTUMAYO</option>
              <option value="86320001">TESALIA - ORITO - PUTUMAYO</option>
              <option value="86320004">LUCITANIA - ORITO - PUTUMAYO</option>
              <option value="86320008">BUENOS AIRES - ORITO - PUTUMAYO</option>
              <option value="86320009">SAN VICENTE DE LUZON - ORITO - PUTUMAYO</option>
              <option value="86320010">SIBERIA - ORITO - PUTUMAYO</option>
              <option value="86320011">SIMON BOLIVAR - ORITO - PUTUMAYO</option>
              <option value="86320013">EL ACHIOTE - ORITO - PUTUMAYO</option>
              <option value="86320014">EL LIBANO - ORITO - PUTUMAYO</option>
              <option value="86320015">EL PARAISO - ORITO - PUTUMAYO</option>
              <option value="86320016">EL YARUMO - ORITO - PUTUMAYO</option>
              <option value="86320017">MONSERRATE - ORITO - PUTUMAYO</option>
              <option value="86568000">PUERTO ASIS - PUTUMAYO</option>
              <option value="86568019">SANTANA - PUERTO ASIS - PUTUMAYO</option>
              <option value="86568020">PUERTO VEGA - PUERTO ASIS - PUTUMAYO</option>
              <option value="86568023">CAÑA BRAVA - PUERTO ASIS - PUTUMAYO</option>
              <option value="86568026">LA CARMELITA - PUERTO ASIS - PUTUMAYO</option>
              <option value="86568027">LA LIBERTAD - PUERTO ASIS - PUTUMAYO</option>
              <option value="86568029">CAMPO ALEGRE - PUERTO ASIS - PUTUMAYO</option>
              <option value="86568031">SINAI (ACHAPOS) - PUERTO ASIS - PUTUMAYO</option>
              <option value="86568032">BRISAS DEL HONG KONG - PUERTO ASIS - PUTUMAYO</option>
              <option value="86568033">LA CABAÑA - PUERTO ASIS - PUTUMAYO</option>
              <option value="86568034">PLANADAS - PUERTO ASIS - PUTUMAYO</option>
              <option value="86569000">PUERTO CAICEDO - PUTUMAYO</option>
              <option value="86569001">SAN PEDRO - PUERTO CAICEDO - PUTUMAYO</option>
              <option value="86569002">VILLA FLOR - PUERTO CAICEDO - PUTUMAYO</option>
              <option value="86569003">EL CEDRAL - PUERTO CAICEDO - PUTUMAYO</option>
              <option value="86569004">EL VENADO - PUERTO CAICEDO - PUTUMAYO</option>
              <option value="86571000">PUERTO GUZMAN - PUTUMAYO</option>
              <option value="86571001">EL CEDRO - PUERTO GUZMAN - PUTUMAYO</option>
              <option value="86571002">SANTA LUCIA - PUERTO GUZMAN - PUTUMAYO</option>
              <option value="86571003">JOSE MARIA - PUERTO GUZMAN - PUTUMAYO</option>
              <option value="86571004">MAYOYOGUE - PUERTO GUZMAN - PUTUMAYO</option>
              <option value="86571005">EL GALLINAZO - PUERTO GUZMAN - PUTUMAYO</option>
              <option value="86571006">SAN ROQUE - PUERTO GUZMAN - PUTUMAYO</option>
              <option value="86571007">EL JUANO - PUERTO GUZMAN - PUTUMAYO</option>
              <option value="86571008">PUERTO ROSARIO - PUERTO GUZMAN - PUTUMAYO</option>
              <option value="86571009">GALILEA - PUERTO GUZMAN - PUTUMAYO</option>
              <option value="86571010">EL RECREO - PUERTO GUZMAN - PUTUMAYO</option>
              <option value="86571011">EL BOMBON - PUERTO GUZMAN - PUTUMAYO</option>
              <option value="86571012">EL MUELLE - PUERTO GUZMAN - PUTUMAYO</option>
              <option value="86571013">LA PATRIA - PUERTO GUZMAN - PUTUMAYO</option>
              <option value="86571014">LOS GUADUALES - PUERTO GUZMAN - PUTUMAYO</option>
              <option value="86573000">PUERTO LEGUIZAMO - PUTUMAYO</option>
              <option value="86573001">LA TAGUA - PUERTO LEGUIZAMO - PUTUMAYO</option>
              <option value="86573002">PUERTO OSPINA - PUERTO LEGUIZAMO - PUTUMAYO</option>
              <option value="86573003">SENSELLA - PUERTO LEGUIZAMO - PUTUMAYO</option>
              <option value="86573005">EL MECAYA - PUERTO LEGUIZAMO - PUTUMAYO</option>
              <option value="86573009">PIÑUÑA NEGRO - PUERTO LEGUIZAMO - PUTUMAYO</option>
              <option value="86573010">NUEVA APAYA - PUERTO LEGUIZAMO - PUTUMAYO</option>
              <option value="86573011">PUERTO NARIÑO - PUERTO LEGUIZAMO - PUTUMAYO</option>
              <option value="86749000">SIBUNDOY - PUTUMAYO</option>
              <option value="86749008">SAGRADO CORAZON DE JESUS - SIBUNDOY - PUTUMAYO</option>
              <option value="86749009">LAS COCHAS - SIBUNDOY - PUTUMAYO</option>
              <option value="86749010">VILLA FLOR - SIBUNDOY - PUTUMAYO</option>
              <option value="86755000">SAN FRANCISCO - PUTUMAYO</option>
              <option value="86755001">SAN ANTONIO - SAN FRANCISCO - PUTUMAYO</option>
              <option value="86755003">SAN SILVESTRE - SAN FRANCISCO - PUTUMAYO</option>
              <option value="86755004">MINCHOY - SAN FRANCISCO - PUTUMAYO</option>
              <option value="86757000">LA DORADA - SAN MIGUEL - PUTUMAYO</option>
              <option value="86757001">PUERTO COLON - SAN MIGUEL - PUTUMAYO</option>
              <option value="86757004">AGUA BLANCA - SAN MIGUEL - PUTUMAYO</option>
              <option value="86757013">EL CHIGUACO - SAN MIGUEL - PUTUMAYO</option>
              <option value="86757015">EL MAIZAL - SAN MIGUEL - PUTUMAYO</option>
              <option value="86757018">JORDAN ORTIZ - SAN MIGUEL - PUTUMAYO</option>
              <option value="86757025">LA GUISITA - SAN MIGUEL - PUTUMAYO</option>
              <option value="86757028">MESAS DEL SABALITO - SAN MIGUEL - PUTUMAYO</option>
              <option value="86757032">NUEVA RISARALDA - SAN MIGUEL - PUTUMAYO</option>
              <option value="86757043">SAN LUIS DE LA FRONTERA - SAN MIGUEL - PUTUMAYO</option>
              <option value="86757049">EL PARAISO - SAN MIGUEL - PUTUMAYO</option>
              <option value="86757050">LA INVASION - SAN MIGUEL - PUTUMAYO</option>
              <option value="86757051">LOS UVOS - SAN MIGUEL - PUTUMAYO</option>
              <option value="86760000">SANTIAGO - PUTUMAYO</option>
              <option value="86760001">SAN ANDRES - SANTIAGO - PUTUMAYO</option>
              <option value="86865000">LA HORMIGA - VALLE DEL GUAMUEZ - PUTUMAYO</option>
              <option value="86865003">EL TIGRE - VALLE DEL GUAMUEZ - PUTUMAYO</option>
              <option value="86865004">EL PLACER - VALLE DEL GUAMUEZ - PUTUMAYO</option>
              <option value="86865005">SAN ANTONIO - VALLE DEL GUAMUEZ - PUTUMAYO</option>
              <option value="86865008">JORDAN DE GUISIA - VALLE DEL GUAMUEZ - PUTUMAYO</option>
              <option value="86865010">BRISAS DEL PALMAR - VALLE DEL GUAMUEZ - PUTUMAYO</option>
              <option value="86865011">EL CAIRO - VALLE DEL GUAMUEZ - PUTUMAYO</option>
              <option value="86865012">EL VENADO - VALLE DEL GUAMUEZ - PUTUMAYO</option>
              <option value="86865014">LA CONCORDIA - VALLE DEL GUAMUEZ - PUTUMAYO</option>
              <option value="86865018">NUEVA PALESTINA - VALLE DEL GUAMUEZ - PUTUMAYO</option>
              <option value="86865021">VILLADUARTE - VALLE DEL GUAMUEZ - PUTUMAYO</option>
              <option value="86865022">LA ISLA - VALLE DEL GUAMUEZ - PUTUMAYO</option>
              <option value="86885000">VILLAGARZON - PUTUMAYO</option>
              <option value="86885001">PUERTO UMBRIA - VILLAGARZON - PUTUMAYO</option>
              <option value="86885002">LA CASTELLANA - VILLAGARZON - PUTUMAYO</option>
              <option value="86885004">ALBANIA - VILLAGARZON - PUTUMAYO</option>
              <option value="86885007">KOFANIA - VILLAGARZON - PUTUMAYO</option>
              <option value="86885008">NARANJITO - VILLAGARZON - PUTUMAYO</option>
              <option value="86885009">PORVENIR - VILLAGARZON - PUTUMAYO</option>
              <option value="86885011">SANTA ROSA DE JUANAMBU - VILLAGARZON - PUTUMAYO</option>
              <option value="86885013">CANANGUCHO - VILLAGARZON - PUTUMAYO</option>
              <option value="86885014">RIO BLANCO - VILLAGARZON - PUTUMAYO</option>
              <option value="88001000">SAN ANDRES - ARCHIPIELAGO DE SAN ANDRES, PROVIDENCIA Y SANTA CATALINA</option>
              <option value="88001001">LA LOMA - SAN ANDRES - ARCHIPIELAGO DE SAN ANDRES, PROVIDENCIA Y SANTA CATALINA</option>
              <option value="88001002">SAN LUIS - SAN ANDRES - ARCHIPIELAGO DE SAN ANDRES, PROVIDENCIA Y SANTA CATALINA</option>
              <option value="88001005">PUNTA SUR - SAN ANDRES - ARCHIPIELAGO DE SAN ANDRES, PROVIDENCIA Y SANTA CATALINA</option>
              <option value="88564000">SANTA ISABEL - PROVIDENCIA - ARCHIPIELAGO DE SAN ANDRES, PROVIDENCIA Y SANTA CATALINA</option>
              <option value="88564001">FRESH WATER BAY - PROVIDENCIA - ARCHIPIELAGO DE SAN ANDRES, PROVIDENCIA Y SANTA CATALINA</option>
              <option value="88564002">SOUTH WEST BAY - PROVIDENCIA - ARCHIPIELAGO DE SAN ANDRES, PROVIDENCIA Y SANTA CATALINA</option>
              <option value="88564003">BOTTON HOUSE - PROVIDENCIA - ARCHIPIELAGO DE SAN ANDRES, PROVIDENCIA Y SANTA CATALINA</option>
              <option value="88564004">SAN FELIPE - PROVIDENCIA - ARCHIPIELAGO DE SAN ANDRES, PROVIDENCIA Y SANTA CATALINA</option>
              <option value="88564005">ROCKY POINT - PROVIDENCIA - ARCHIPIELAGO DE SAN ANDRES, PROVIDENCIA Y SANTA CATALINA</option>
              <option value="88564006">SANTA CATALINA - PROVIDENCIA - ARCHIPIELAGO DE SAN ANDRES, PROVIDENCIA Y SANTA CATALINA</option>
              <option value="91001000">LETICIA - AMAZONAS</option>
              <option value="91001001">COMUNIDAD INDIGENA SANTA SOFIA - LETICIA - AMAZONAS</option>
              <option value="91001002">COMUNIDAD INDIGENA NAZARETH - LETICIA - AMAZONAS</option>
              <option value="91001005">COMUNIDAD INDIGENA TIKUNA DE ARARA - LETICIA - AMAZONAS</option>
              <option value="91001007">COMUNIDAD INDIGENA SAN MARTIN DE AMACAYACU - LETICIA - AMAZONAS</option>
              <option value="91001009">COMUNIDAD INDIGENA ZARAGOZA - LETICIA - AMAZONAS</option>
              <option value="91001011">COMUNIDAD INDIGENA EL PROGRESO - LETICIA - AMAZONAS</option>
              <option value="91001012">COMUNIDAD INDIGENA EL VERGEL - LETICIA - AMAZONAS</option>
              <option value="91001013">COMUNIDAD INDIGENA PATIO DE CIENCIA DULCE KM 11 - LETICIA - AMAZONAS</option>
              <option value="91001014">KILOMETRO 6 - LETICIA - AMAZONAS</option>
              <option value="91001015">COMUNIDAD INDIGENA LA LIBERTAD - LETICIA - AMAZONAS</option>
              <option value="91001016">COMUNIDAD INDIGENA LA MILAGROSA - LETICIA - AMAZONAS</option>
              <option value="91001017">COMUNIDAD INDIGENA SECTOR LA PLAYA - LETICIA - AMAZONAS</option>
              <option value="91001018">COMUNIDAD INDIGENA MALOKA YAGUAS - LETICIA - AMAZONAS</option>
              <option value="91001019">COMUNIDAD INDIGENA LOMA LINDA - LETICIA - AMAZONAS</option>
              <option value="91001020">COMUNIDAD INDIGENA MACEDONIA - LETICIA - AMAZONAS</option>
              <option value="91001021">COMUNIDAD INDIGENA MOCAGUA - LETICIA - AMAZONAS</option>
              <option value="91001022">COMUNIDAD INDIGENA JUSSY MONILLA AMENA - LETICIA - AMAZONAS</option>
              <option value="91001023">ASENTAMIENTO HUMANO TAKANA KM 11 - LETICIA - AMAZONAS</option>
              <option value="91001024">COMUNIDAD INDIGENA NUEVO JARDIN - LETICIA - AMAZONAS</option>
              <option value="91001025">COMUNIDAD INDIGENA PALMERAS - LETICIA - AMAZONAS</option>
              <option value="91001026">COMUNIDAD INDIGENA PUERTO TRIUNFO - LETICIA - AMAZONAS</option>
              <option value="91001027">COMUNIDAD INDIGENA ISLA DE RONDA - LETICIA - AMAZONAS</option>
              <option value="91001028">COMUNIDAD INDIGENA SAN ANTONIO DE LOS LAGOS - LETICIA - AMAZONAS</option>
              <option value="91001029">COMUNIDAD INDIGENA ZIERA AMENA - LETICIA - AMAZONAS</option>
              <option value="91001030">BARRIO SAN MIGUEL - LETICIA - AMAZONAS</option>
              <option value="91001031">COMUNIDAD INDIGENA CANAAN - LETICIA - AMAZONAS</option>
              <option value="91001032">COMUNIDAD INDIGENA PICHUNA KM 18 - LETICIA - AMAZONAS</option>
              <option value="91001033">COMUNIDAD INDIGENA SAN JOSE DEL RIO - LETICIA - AMAZONAS</option>
              <option value="91001034">COMUNIDAD INDIGENA SAN JUAN DE LOS PARENTES - LETICIA - AMAZONAS</option>
              <option value="91001035">COMUNIDAD INDIGENA SAN PEDRO DE LOS LAGOS - LETICIA - AMAZONAS</option>
              <option value="91263000">EL ENCANTO - AMAZONAS</option>
              <option value="91405000">LA CHORRERA - AMAZONAS</option>
              <option value="91407000">LA PEDRERA - AMAZONAS</option>
              <option value="91430000">PACOA - LA VICTORIA - AMAZONAS</option>
              <option value="91460000">MIRITI - MIRITI - PARANA - AMAZONAS</option>
              <option value="91530000">PUERTO ALEGRIA - AMAZONAS</option>
              <option value="91536000">PUERTO ARICA - AMAZONAS</option>
              <option value="91540000">PUERTO NARIÑO - AMAZONAS</option>
              <option value="91540001">SAN JUAN DE ATACUARI - PUERTO NARIÑO - AMAZONAS</option>
              <option value="91540002">BOYAHUAZU - PUERTO NARIÑO - AMAZONAS</option>
              <option value="91540003">DOCE DE OCTUBRE - PUERTO NARIÑO - AMAZONAS</option>
              <option value="91540004">NARANJALES - PUERTO NARIÑO - AMAZONAS</option>
              <option value="91540005">PUERTO ESPERANZA - PUERTO NARIÑO - AMAZONAS</option>
              <option value="91540006">PUERTO RICO - PUERTO NARIÑO - AMAZONAS</option>
              <option value="91540007">SAN FRANCISCO - PUERTO NARIÑO - AMAZONAS</option>
              <option value="91540008">SAN JUAN DEL SOCO - PUERTO NARIÑO - AMAZONAS</option>
              <option value="91540009">SIETE DE AGOSTO - PUERTO NARIÑO - AMAZONAS</option>
              <option value="91540010">SAN PEDRO DE TIPISCA - PUERTO NARIÑO - AMAZONAS</option>
              <option value="91540011">VEINTE DE JULIO - PUERTO NARIÑO - AMAZONAS</option>
              <option value="91540012">NUEVO PARAISO - PUERTO NARIÑO - AMAZONAS</option>
              <option value="91540013">PATRULLEROS - PUERTO NARIÑO - AMAZONAS</option>
              <option value="91540014">SAN JOSE DE VILLA ANDREA - PUERTO NARIÑO - AMAZONAS</option>
              <option value="91540015">SANTA TERESITA - PUERTO NARIÑO - AMAZONAS</option>
              <option value="91540017">VALENCIA - PUERTO NARIÑO - AMAZONAS</option>
              <option value="91669000">PUERTO SANTANDER - AMAZONAS</option>
              <option value="91798000">TARAPACA - AMAZONAS</option>
              <option value="94001000">INIRIDA - GUAINIA</option>
              <option value="94001003">COCO VIEJO - INIRIDA - GUAINIA</option>
              <option value="94001009">RESGUARDO CACAHUAL RIO ATABAPO - INIRIDA - GUAINIA</option>
              <option value="94001010">COCO NUEVO - INIRIDA - GUAINIA</option>
              <option value="94001011">INSPECCION BARRANCO TIGRE - INIRIDA - GUAINIA</option>
              <option value="94001012">COAYARE - INIRIDA - GUAINIA</option>
              <option value="94001013">YURI - INIRIDA - GUAINIA</option>
              <option value="94001014">SANTA ROSA - INIRIDA - GUAINIA</option>
              <option value="94343000">BARRANCOMINAS - GUAINIA</option>
              <option value="94343001">MAPIRIPANA - BARRANCOMINAS - GUAINIA</option>
              <option value="94343002">ARRECIFAL - BARRANCOMINAS - GUAINIA</option>
              <option value="94343005">PUERTO ZANCUDO - BARRANCOMINAS - GUAINIA</option>
              <option value="94883000">SAN FELIPE - GUAINIA</option>
              <option value="94884000">PUERTO COLOMBIA - GUAINIA</option>
              <option value="94885000">GALILEA - LA GUADALUPE - GUAINIA</option>
              <option value="94886000">CACAHUAL - GUAINIA</option>
              <option value="94886003">MEREY - CACAHUAL - GUAINIA</option>
              <option value="94886004">SAN JUAN - CACAHUAL - GUAINIA</option>
              <option value="94887000">CAMPO ALEGRE - PANA PANA - GUAINIA</option>
              <option value="94887002">VENADO ISANA - PANA PANA - GUAINIA</option>
              <option value="94888000">MORICHAL - GARZA - MORICHAL - GUAINIA</option>
              <option value="95001000">SAN JOSE DEL GUAVIARE - GUAVIARE</option>
              <option value="95001001">RAUDAL DEL GUAYABERO - SAN JOSE DEL GUAVIARE - GUAVIARE</option>
              <option value="95001002">SABANAS DE LA FUGA - SAN JOSE DEL GUAVIARE - GUAVIARE</option>
              <option value="95001006">GUACAMAYAS - SAN JOSE DEL GUAVIARE - GUAVIARE</option>
              <option value="95001009">PUERTO NUEVO - SAN JOSE DEL GUAVIARE - GUAVIARE</option>
              <option value="95001010">PUERTO ARTURO - SAN JOSE DEL GUAVIARE - GUAVIARE</option>
              <option value="95001012">CACHICAMO - SAN JOSE DEL GUAVIARE - GUAVIARE</option>
              <option value="95001016">EL CAPRICHO - SAN JOSE DEL GUAVIARE - GUAVIARE</option>
              <option value="95001017">CHARRAS - SAN JOSE DEL GUAVIARE - GUAVIARE</option>
              <option value="95001018">CARACOL - SAN JOSE DEL GUAVIARE - GUAVIARE</option>
              <option value="95001019">TOMACHIPAN - SAN JOSE DEL GUAVIARE - GUAVIARE</option>
              <option value="95001020">MOCUARE - SAN JOSE DEL GUAVIARE - GUAVIARE</option>
              <option value="95001023">LA CARPA - SAN JOSE DEL GUAVIARE - GUAVIARE</option>
              <option value="95001024">BOQUERON - SAN JOSE DEL GUAVIARE - GUAVIARE</option>
              <option value="95001027">LAS ACACIAS - SAN JOSE DEL GUAVIARE - GUAVIARE</option>
              <option value="95001029">RESBALON - SAN JOSE DEL GUAVIARE - GUAVIARE</option>
              <option value="95001030">CAÑO BLANCO II - SAN JOSE DEL GUAVIARE - GUAVIARE</option>
              <option value="95001031">CERRO AZUL - SAN JOSE DEL GUAVIARE - GUAVIARE</option>
              <option value="95001032">EL DIAMANTE - SAN JOSE DEL GUAVIARE - GUAVIARE</option>
              <option value="95001034">EL REFUGIO - SAN JOSE DEL GUAVIARE - GUAVIARE</option>
              <option value="95001035">EL TRIUNFO - SAN JOSE DEL GUAVIARE - GUAVIARE</option>
              <option value="95001036">LA ESMERALDA - SAN JOSE DEL GUAVIARE - GUAVIARE</option>
              <option value="95001037">PICALOJO - SAN JOSE DEL GUAVIARE - GUAVIARE</option>
              <option value="95001039">SANTO DOMINGO - SAN JOSE DEL GUAVIARE - GUAVIARE</option>
              <option value="95001042">EL MORRO - SAN JOSE DEL GUAVIARE - GUAVIARE</option>
              <option value="95001043">VILLA ALEJANDRA - SAN JOSE DEL GUAVIARE - GUAVIARE</option>
              <option value="95001044">VILLA ALEJANDRA 2 - SAN JOSE DEL GUAVIARE - GUAVIARE</option>
              <option value="95001045">MIRALINDO - SAN JOSE DEL GUAVIARE - GUAVIARE</option>
              <option value="95001046">LA CATALINA - SAN JOSE DEL GUAVIARE - GUAVIARE</option>
              <option value="95015000">CALAMAR - GUAVIARE</option>
              <option value="95015003">LAS DAMAS - CALAMAR - GUAVIARE</option>
              <option value="95025000">EL RETORNO - GUAVIARE</option>
              <option value="95025001">LA LIBERTAD - EL RETORNO - GUAVIARE</option>
              <option value="95025002">EL UNILLA - EL RETORNO - GUAVIARE</option>
              <option value="95025003">CERRITOS - EL RETORNO - GUAVIARE</option>
              <option value="95025004">MORICHAL VIEJO - EL RETORNO - GUAVIARE</option>
              <option value="95025005">SAN LUCAS - EL RETORNO - GUAVIARE</option>
              <option value="95025006">LA FORTALEZA - EL RETORNO - GUAVIARE</option>
              <option value="95025010">LA PAZ - EL RETORNO - GUAVIARE</option>
              <option value="95025011">PUEBLO NUEVO - EL RETORNO - GUAVIARE</option>
              <option value="95200000">MIRAFLORES - GUAVIARE</option>
              <option value="95200001">BARRANQUILLITA - MIRAFLORES - GUAVIARE</option>
              <option value="95200002">LAGOS DEL DORADO - MIRAFLORES - GUAVIARE</option>
              <option value="95200003">LAS PAVAS CAÑO TIGRE - MIRAFLORES - GUAVIARE</option>
              <option value="95200004">BUENOS AIRES - MIRAFLORES - GUAVIARE</option>
              <option value="95200005">LA YE - MIRAFLORES - GUAVIARE</option>
              <option value="95200006">LAGOS DEL PASO - MIRAFLORES - GUAVIARE</option>
              <option value="95200009">PUERTO NARE - MIRAFLORES - GUAVIARE</option>
              <option value="95200010">PUERTO SANTANDER - MIRAFLORES - GUAVIARE</option>
              <option value="95200014">LA HACIENDA - MIRAFLORES - GUAVIARE</option>
              <option value="95200015">PUERTO CORDOBA - MIRAFLORES - GUAVIARE</option>
              <option value="95200016">PUERTO MANDU - MIRAFLORES - GUAVIARE</option>
              <option value="97001000">MITU - VAUPES</option>
              <option value="97001002">CAMANAOS - MITU - VAUPES</option>
              <option value="97001005">ACARICUARA - MITU - VAUPES</option>
              <option value="97001006">VILLAFATIMA - MITU - VAUPES</option>
              <option value="97001008">PIRAMIRI - MITU - VAUPES</option>
              <option value="97001009">YAPU - MITU - VAUPES</option>
              <option value="97001010">YURUPARI - MITU - VAUPES</option>
              <option value="97001011">MARGEN IZQUIERDO - MITU - VAUPES</option>
              <option value="97001012">12 DE OCTUBRE - MITU - VAUPES</option>
              <option value="97001013">TAPURUCUARA - MITU - VAUPES</option>
              <option value="97161000">CARURU - VAUPES</option>
              <option value="97511000">PACOA - VAUPES</option>
              <option value="97666000">TARAIRA - VAUPES</option>
              <option value="97777000">PUERTO SOLANO (PAPUNAHUA) - PAPUNAHUA - VAUPES</option>
              <option value="97889000">YAVARATE - VAUPES</option>
              <option value="99001000">PUERTO CARREÑO - VICHADA</option>
              <option value="99001001">LA VENTUROSA - PUERTO CARREÑO - VICHADA</option>
              <option value="99001002">CASUARITO - PUERTO CARREÑO - VICHADA</option>
              <option value="99001003">PUERTO MURILLO - PUERTO CARREÑO - VICHADA</option>
              <option value="99001004">ACEITICO - PUERTO CARREÑO - VICHADA</option>
              <option value="99001005">GARCITAS - PUERTO CARREÑO - VICHADA</option>
              <option value="99001006">GUARIPA - PUERTO CARREÑO - VICHADA</option>
              <option value="99001007">MORICHADA - PUERTO CARREÑO - VICHADA</option>
              <option value="99524000">LA PRIMAVERA - VICHADA</option>
              <option value="99524001">NUEVA ANTIOQUIA - LA PRIMAVERA - VICHADA</option>
              <option value="99524002">SANTA BARBARA - LA PRIMAVERA - VICHADA</option>
              <option value="99524007">SAN TEODORO (LA PASCUA) - LA PRIMAVERA - VICHADA</option>
              <option value="99624000">SANTA ROSALIA - VICHADA</option>
              <option value="99624001">GUACACIAS - SANTA ROSALIA - VICHADA</option>
              <option value="99773000">CUMARIBO - VICHADA</option>
              <option value="99773001">PALMARITO - CUMARIBO - VICHADA</option>
              <option value="99773002">EL VIENTO - CUMARIBO - VICHADA</option>
              <option value="99773003">TRES MATAS - CUMARIBO - VICHADA</option>
              <option value="99773004">AMANAVEN - CUMARIBO - VICHADA</option>
              <option value="99773005">CHUPAVE - CUMARIBO - VICHADA</option>
              <option value="99773008">GUANAPE - CUMARIBO - VICHADA</option>
              <option value="99773010">PUERTO PRINCIPE - CUMARIBO - VICHADA</option>
              <option value="99773013">PUERTO NARIÑO - CUMARIBO - VICHADA</option>
              <option value="99773015">SANTA RITA - CUMARIBO - VICHADA</option>
              <option value="99773017">CHAPARRAL - CUMARIBO - VICHADA</option>
              <option value="99773020">EL PROGRESO - CUMARIBO - VICHADA</option>
              <option value="99773024">BRISA - CUMARIBO - VICHADA</option>
              <option value="99773025">GUATURIBA - CUMARIBO - VICHADA</option>
              <option value="99773026">MATSULDANI - CUMARIBO - VICHADA</option>
              <option value="99773027">REMANSO - CUMARIBO - VICHADA</option>
            </Select>
          </Form.Item>

          <Form.Item
            name="notes"
            label="Notas para el Proveedor"
            rules={[{ required: true, message: 'Por favor ingrese alguna nota' }]}
          >
            <Input.TextArea rows={4}/>
          </Form.Item>

            <Button type="primary" onClick={handleCotizarClick} block>
              Cotizar
            </Button>
          </Form>
        </div>

        <div className="transportadora">
          {images.map((image, index) => {
            const cotizacion = cotizaciones[envios[index]];
            const comisionInterna = cotizacion.comision_interna || cotizacion.valor_interna || 0;
            const totalCotizacion = cotizacion.valor + cotizacion.valor_contrapago + comisionInterna + cotizacion.sobreflete;

          return (
            <div
              key={index}
              className="flete"
              style={{
                border: selectedImage === index ? '1px solid rgba(247, 118, 67, 0.5)' : '1px solid rgba(128, 128, 128, 0.5)',
                cursor: 'pointer'
              }}
              onClick={() => handleImageClick(index)}
            >
              <img src={image} alt={`Imagen ${index + 1}`} />
              <p>{formatCurrency(totalCotizacion)}</p>
            </div>
          );
        })}
      </div>

        <div className="pedidoEnvio">
          <Form form={form} layout="vertical" onValuesChange={onValuesChangePedido}>
            <Form.Item
              label="Producto"
              name="product"
              rules={[{ required: true, message: 'Por favor selecciona un producto' }]}
            >
              <Select placeholder="Selecciona un producto">
                <Option value={producto.nombre_producto}>{producto.nombre_producto}</Option>
              </Select>
            </Form.Item>

            {/* Select para Variaciones */}
            {producto.variaciones.length > 0 && (
              <Form.Item
                name="variation"
                label="Selecciona una variación"
                rules={[{ required: true, message: 'Por favor selecciona una variación' }]}
              >
                <Select placeholder="Selecciona una variación" onChange={handleVariationChange}>
                  {producto.variaciones.map(variation => (
                    <Option key={variation.id} value={variation.id}>
                      {`ID: ${variation.id}, Talla: ${variation.talla}, Color: ${variation.color}, Stock: ${variation.cantidad_disponible}`}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            )}

            <Text>Precio sugerido: ${costo}</Text>
            <Form.Item
              label="Precio de venta"
              name="salePrice"
              initialValue={producto.precio_sugerido}
              rules={[{ required: true, message: 'Por favor ingresa el precio de venta' }]}
            >
              <Input type="number" />
            </Form.Item>

            <Text>Existencia: {producto.cantidad_disponible}</Text>
            <Form.Item
              label="Cantidad"
              name="quantity"
              initialValue="1"
              rules={[
                {
                  required: true,
                  message: 'Por favor ingresa la cantidad',
                },
                {
                  validator: (_, value) => {
                    if (value < 1 || value > producto.cantidad_disponible) {
                      return Promise.reject(`La cantidad debe estar entre 1 y ${producto.cantidad_disponible}`);
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input
                type="number"
                value={inputValue}
                onChange={(e) => handleChange(e.target.value)}
              />
            </Form.Item>

            <Form.Item
              name="aplicaContrapago"
              label="¿Aplica Contrapago?"
              initialValue={true}
            >
              <Radio.Group disabled>
                <Radio value={true}>Sí</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </Form.Item>

          </Form>
          <div className="order-summary">
            <div className="order-summary-header">Resumen de la orden</div>
            <div className="order-summary-content">
              <div className="order-summary-item">
                <div className="item-description">{formValues.product}</div>
                <div className="item-price">{formatCurrency(formValues.salePrice)} x {formValues.quantity}</div>
              </div>
              <div className="order-summary-total">
                <div className="total-label">Sub Total :</div>
                <div className="total-amount">{formatCurrency(formValues.salePrice * formValues.quantity)}</div>
              </div>
              {/* precio provedor */}
              <div className="order-summary-total">
                <div className="total-label">Precio Proveedor :</div>
                <div className="total-amount">{formatCurrency(precioProveedor)}</div>
              </div>
              {/* precio de envio con las sumas */}

              <div className="order-summary-total">
                <div className="total-label">Precio de Envío :</div>
                <div className="total-amount">{formatCurrency(transportadora)}</div>
              </div>

              <div className="order-summary-total">
                <div className="total-label">Comisión de la plataforma :</div>
                <div className="total-amount">$ 0</div>
              </div>

              <div className="order-summary-total">
                <div className="total-label">Monto a ganar cuando esté entregado :</div>
                <div className="total-amount" style={{ color: calculateTotal(formValues) < 0 ? 'red' : 'black' }}>
                  {formatCurrency(calculateTotal(formValues))}
                </div>
              </div>
              {calculateTotal(formValues) < 0 && (
                <div style={{ color: 'red', marginTop: '10px' }}>
                  <strong>Advertencia:</strong> El monto a ganar es negativo. No puedes confirmar y enviar el pedido.
                </div>
              )}

            <div className="order-summary-total">
              <div className="total-label">Total:</div>
              <div className="total-amount">{formatCurrency(formValues.salePrice * formValues.quantity)}</div>
            </div>
            <div className="order-summary-footer">
              <Button
                type="primary"
                onClick={handleConfirmarClick}
                block
                disabled={calculateTotal(formValues) < 0} // Deshabilita el botón si el total es negativo
              >
                Confirmar y Enviar Pedido
              </Button>
            </div>
          </div>
        </div>
      </div>
      <Modal
        title={<span><CheckCircleOutlined style={{ color: 'green' }} /> Confirmación de Orden</span>}
        visible={isModalVisible}
        onOk={handleModalOk}
        onCancel={handleModalOk}
        footer={[
          <Button key="ok" type="primary" onClick={handleModalOk}>
            Confirmar
          </Button>,

        ]}
        centered
        width={600}
        bodyStyle={{
          padding: '20px',
          backgroundColor: '#f7f7f7',
          borderRadius: '8px',
        }}
      >
        {orderResponse && (
          <div style={{ lineHeight: '1.6', fontSize: '16px' }}>
            <div style={{ marginBottom: '16px', textAlign: 'center' }}>
              <strong style={{ fontSize: '18px', textDecoration: 'underline' }}>Resumen de la Orden</strong>
            </div>
            <div style={{ marginBottom: '16px' }}>
              <p><strong>Mensaje:</strong> {orderResponse.mensaje}</p>
              <p><strong>Número de Orden:</strong> {orderResponse.numero_orden}</p>
            </div>
            <div style={{
              padding: '10px',
              backgroundColor: '#fff',
              borderRadius: '8px',
              boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
              marginBottom: '16px'
            }}>
              <p><strong>Valor de Venta:</strong> {formatCurrency(orderResponse.valor_venta)}</p>
              <p><strong>Valor de Proveedor:</strong> {formatCurrency(orderResponse.valor_proveedro)}</p>
              <p><strong>Valor de Envío:</strong> {formatCurrency(transportadora)}</p>
              <p><strong>Valor de Comisión:</strong> $ 0</p>
              <p><strong>Valor de Utilidad menos 4x1000:</strong> {formatCurrency(calculateTotal(formValues))}</p>
            </div>
            <p><strong>Éxito:</strong> <span style={{ color: orderResponse.exito ? 'green' : 'red' }}>{orderResponse.exito ? 'Sí' : 'No'}</span></p>
          </div>
        )}
      </Modal>



      </div>
    </Spin>
  );
};

export default SendClientPage;
