import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { message } from 'antd';
import './navbarDropshipping.css';

const NavbarDropshipping = ({ title }) => {
  const [isMenuOpen, setMenuOpen] = useState(true);
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    const storedId = localStorage.getItem('usuario_id');
    if (storedId) {
      setUserId(storedId);
    }
  }, []);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('usuario_id');
    localStorage.removeItem('rol_id');
    localStorage.removeItem('email');
    window.location.href = '/';
    message.success('Sesión cerrada exitosamente');
  };

    return (
        <>
          <div className="NavbarDropshipping-top">
            <div className={`hamburger-menu ${isMenuOpen ? 'open' : ''}`} onClick={toggleMenu}>
              <div className="bar1"></div>
              <div className="bar2"></div>
              <div className="bar3"></div>
            </div>
            <div className="NavbarDropshipping-tittle">
              <p>{title}</p>
            </div>
            <div className="NavbarDropshipping-userInfo">
              <p>Hola, Enviador Estrella</p>
              <p className="NavbarDropshipping-panelLink">99Panel</p>
              <div className="NavbarDropshipping-userAvatar">
                <img src="/Images/avatar-repartidor.jpg" alt="Avatar" />
              </div>
            </div>
          </div>
          <div className={`NavbarDropshipping-container ${isMenuOpen ? 'show' : 'hide'}`}>
            <div className="close-btn" onClick={toggleMenu}>X</div>
            <div className="NavbarDropshipping-title">
              <img src="/Images/download.png" alt="Download" className="NavbarDropshipping-logo" style={{ marginTop: '-5%' }} />
            </div>
            <div className="NavbarDropshipping-menu">
              <ul>
              <li>
                <NavLink to="/dashboardMex/" className={({ isActive }) => isActive ? "NavbarDropshipping-active" : ""}>
                  <span className="material-icons">dashboard</span>Volver a Dashboard
                </NavLink>
              </li>
              <li>
                <NavLink to="/dropshippingGlobal" className={({ isActive }) => isActive ? "NavbarDropshipping-active" : ""}>
                  <span className="material-icons">public</span>Dropshipping General
                </NavLink>
              </li>
              <li>
                <NavLink to="/inventarioDrop" className={({ isActive }) => isActive ? "NavbarDropshipping-active" : ""}>
                  <span className="material-icons">inventory</span>Inventario
                </NavLink>
              </li>
              <li>
                <NavLink to="/misPedidos" className={({ isActive }) => isActive ? "NavbarDropshipping-active" : ""}>
                  <span className="material-icons">shopping_cart</span>Envíos Dropshipping
                </NavLink>
              </li>
              <li>
                <NavLink to="/sucursalPedidos/" className={({ isActive }) => isActive ? "NavbarDropshipping-active" : ""}>
                  <span className="material-icons">business</span>Órdenes y Envíos Bodega
                </NavLink>
              </li>
              <li>
                <NavLink to="/" className={({ isActive }) => isActive ? "NavbarDropshipping-active" : ""}>
                  <span className="material-icons" onClick={handleLogout}>exit_to_app</span>Logout
                </NavLink>
              </li>
              </ul>
            </div>
          </div>
        </>
    );
}

export default NavbarDropshipping;