import { useState } from 'react';
import axios from 'axios';

const useFileUpload = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const uploadFile = async (formData) => {
    setLoading(true);
    setError(null);
    setSuccess(false);

    try {
      const response = await axios.post(
        'https://integration.99envios.app/api/sucursal/importar_cotizar/21119',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      setSuccess(true);
      return response.data;
    } catch (err) {
      const errorMessage = err.response?.data?.mensaje_completo
        ? err.response.data.mensaje_completo.join(' ')
        : err.response?.data?.mensaje || err.message;
      setError(errorMessage);
      throw new Error(`Error en la carga del archivo: ${errorMessage}`);
    } finally {
      setLoading(false);
    }
  };

  return { uploadFile, loading, error, success };
};

export default useFileUpload;
