import React, { useState } from 'react';
import { Button, message, Spin, Table, Modal } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import axios from 'axios';
import * as XLSX from 'xlsx';
import './masivoDrop.css'; // Asegúrate de tener este archivo si usas estilos personalizados

const MasivoDrop = () => {
  const [excelData, setExcelData] = useState([]);
  const [cotizaciones, setCotizaciones] = useState([]);
  const [orderResponse, setOrderResponse] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  // Subida de archivo y conversión a JSON
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet);

      setExcelData(jsonData);
    };

    reader.readAsArrayBuffer(file);
  };

  // Función para formatear las monedas
  const formatCurrency = (value, locale = 'es-CO', currency = 'COP') => {
    return new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }).format(value);
  };

  // Manejo de cotización
  const handleCotizarClick = async () => {
    setLoading(true);
    try {
      if (excelData.length === 0) {
        message.error('Por favor, sube un archivo Excel.');
        return;
      }

      const cotizacionesList = [];

      for (const item of excelData) {
        const {
          'Nombre Destinatario': firstName,
          'Apellido Destinatario': lastName,
          'Teléfono Destinatario': phone,
          'Email Destinatario': email,
          'Dirección Destinatario': address,
          'Ciudad Destinatario': city,
          'Producto': product,
          'Cantidad': quantity,
          'Precio Venta': salePrice,
          'Peso Producto': pesoProducto,
          'Aplica Contrapago': aplicaContrapago,
        } = item;

        if (!firstName || !lastName || !city || !product || !quantity || !salePrice || !pesoProducto) {
          message.error(`Faltan datos en el Excel para el producto ${product}`);
          continue;
        }

        // Preparar datos para la API de cotización
        const cotizacionData = {
          destino: { codigo: city },
          origen: { codigo: "11001" }, // Código de ejemplo para la ciudad de origen
          IdTipoEntrega: 1,
          IdServicio: 1,
          peso: pesoProducto * quantity,
          largo: 1,
          ancho: 1,
          alto: 1,
          fecha: "28-06-2024",
          AplicaContrapago: aplicaContrapago,
          valorDeclarado: salePrice * quantity,
        };

        try {
          const codigo_sucursal = localStorage.getItem('codigoSucursal');
          const response = await axios.post(
            `https://integration.99envios.app/api/sucursal/cotizar/${codigo_sucursal}`,
            cotizacionData
          );

          const transportadoraSeleccionada = 'coordinadora';
          const cotizacionSeleccionada = response.data[transportadoraSeleccionada];

          if (!cotizacionSeleccionada) {
            throw new Error('No se encontró la cotización para la transportadora seleccionada');
          }

          const comisionInterna = cotizacionSeleccionada.comision_interna || 0;
          const totalCotizacion = cotizacionSeleccionada.valor + cotizacionSeleccionada.valor_contrapago + comisionInterna + cotizacionSeleccionada.sobreflete;

          cotizacionesList.push({
            itemData: item,
            cotizacion: totalCotizacion,
            cotizacionDetalle: cotizacionSeleccionada,
          });
        } catch (error) {
          console.error(`Error al cotizar para el producto ${product}:`, error);
          cotizacionesList.push({
            itemData: item,
            cotizacion: null,
            error: 'Error al cotizar',
          });
        }
      }

      setCotizaciones(cotizacionesList);
      message.success('Cotizaciones generadas con éxito');
    } catch (error) {
      message.error('Error al generar cotizaciones');
    } finally {
      setLoading(false);
    }
  };

  // Manejo de confirmación de pedidos
  const handleConfirmarClick = async () => {
    setLoading(true);
    try {
      if (cotizaciones.length === 0) {
        message.error('Primero genera las cotizaciones.');
        return;
      }

      const ordenesList = [];

      for (const cotizacionItem of cotizaciones) {
        const { itemData, cotizacion } = cotizacionItem;

        if (!cotizacion) {
          ordenesList.push({
            itemData,
            orden: null,
            error: 'No se pudo realizar la cotización',
          });
          continue;
        }

        const totalGanar = (itemData['Precio Venta'] * itemData['Cantidad']) - cotizacion;

        if (totalGanar < 0) {
          ordenesList.push({
            itemData,
            orden: null,
            error: 'El monto a ganar es negativo. No se puede confirmar y enviar el pedido.',
          });
          continue;
        }

        const orderData = {
          IdProducto: itemData['ID Producto'],
          IdVariacion: itemData['ID Variación'] || '',
          Cantidad: itemData['Cantidad'],
          AplicaContrapago: itemData['Aplica Contrapago'],
          valorDeclarado: itemData['Precio Venta'],
          Destinatario: {
            nombre: itemData['Nombre Destinatario'],
            primerApellido: itemData['Apellido Destinatario'].split(' ')[0] || '',
            segundoApellido: itemData['Apellido Destinatario'].split(' ')[1] || '',
            telefono: itemData['Teléfono Destinatario'],
            direccion: itemData['Dirección Destinatario'],
            idLocalidad: itemData['Ciudad Destinatario'],
            correo: itemData['Email Destinatario'],
          },
          Observaciones: itemData['Notas Proveedor'],
          transportadora: {
            pais: 'colombia',
            nombre: 'coordinadora',
          },
          origenCreacion: 1,
        };

        const id = localStorage.getItem('usuario_id');
        const token = localStorage.getItem('token');

        const config = {
          headers: {
            'Authorization': token ? `Bearer ${token}` : '',
            'Content-Type': 'application/json',
          },
        };

        try {
          const response = await axios.post(`https://99envios.app/api/orden_save/${id}`, orderData, config);

          if (response.status === 200) {
            ordenesList.push({
              itemData,
              orden: response.data,
              totalGanar,
              error: null,
            });
            message.success(`Pedido para ${itemData['Producto']} confirmado y enviado con éxito`);
          } else {
            throw new Error('Error al enviar el pedido');
          }
        } catch (error) {
          console.error(`Error al enviar el pedido para ${itemData['Producto']}:`, error);
          ordenesList.push({
            itemData,
            orden: null,
            error: 'Error al enviar el pedido',
          });
          message.error(`Error al enviar el pedido para ${itemData['Producto']}`);
        }
      }

      setOrderResponse(ordenesList);
      setIsModalVisible(true);
    } catch (error) {
      message.error('Error al enviar las órdenes');
    } finally {
      setLoading(false);
    }
  };

  const handleModalOk = () => {
    setIsModalVisible(false);
    setOrderResponse([]);
  };

  return (
    <Spin spinning={loading}>
      <div className="envios">
        <h1>Masivo Drop</h1>
        <input type="file" accept=".xlsx, .xls" onChange={handleFileUpload} style={{ marginBottom: '20px' }} />
        <Button type="primary" onClick={handleCotizarClick} disabled={loading || excelData.length === 0}>
          Cotizar Masivamente
        </Button>
        <Button
          type="primary"
          onClick={handleConfirmarClick}
          disabled={loading || cotizaciones.length === 0}
          style={{ marginLeft: '10px' }}
        >
          Confirmar y Enviar Pedidos
        </Button>

        {/* Mostrar cotizaciones */}
        {cotizaciones.length > 0 && (
          <div style={{ marginTop: '20px' }}>
            <h2>Cotizaciones</h2>
            <Table
              dataSource={cotizaciones}
              columns={[
                {
                  title: 'Producto',
                  dataIndex: ['itemData', 'Producto'],
                  key: 'producto',
                },
                {
                  title: 'Cotización',
                  dataIndex: 'cotizacion',
                  key: 'cotizacion',
                  render: (text) => formatCurrency(text),
                },
                {
                  title: 'Estado',
                  dataIndex: 'error',
                  key: 'error',
                  render: (text) => (text ? 'Error' : 'OK'),
                },
              ]}
              rowKey={(record) => record.itemData['Producto']}
            />
          </div>
        )}

        {/* Modal de confirmación de órdenes */}
        <Modal
          title={<span><CheckCircleOutlined style={{ color: 'green' }} /> Confirmación de Órdenes</span>}
          visible={isModalVisible}
          onOk={handleModalOk}
          onCancel={handleModalOk}
          footer={[
            <Button key="ok" type="primary" onClick={handleModalOk}>
              Confirmar
            </Button>,
          ]}
          centered
          width={600}
        >
          {orderResponse.map((item, index) => (
            <div key={index} style={{ marginBottom: '20px' }}>
              <h4>Producto: {item.itemData['Producto']}</h4>
              {item.orden ? (
                <div>
                  <p><strong>Mensaje:</strong> {item.orden.mensaje}</p>
                  <p><strong>Número de Orden:</strong> {item.orden.numero_orden}</p>
                  <p><strong>Utilidad:</strong> {formatCurrency(item.totalGanar)}</p>
                </div>
              ) : (
                <p>Error: {item.error}</p>
              )}
            </div>
          ))}
        </Modal>
      </div>
    </Spin>
  );
};

export default MasivoDrop;
